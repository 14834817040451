import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useVerifyPassword, verifyPassword] = createAsyncAction(
  "auth/verifyPassword",
  async (password: string) => {
    const response = await API.call<string>({
      endpoint: "me/password/verify/",
      method: "POST",
      data: { password },
    });
    return response;
  }
);

export default useVerifyPassword;
export { verifyPassword };
