import { Asset, Item, Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  kitId: string;
};

export type KitAssetResult = {
  asset: Asset;
  id: Item["id"];
  sectionUuid: string;
  dateAdded: string;
  usage: {
    totalUses: number;
    memberUses: number;
    guestUses: number;
    sectionName: string;
    portalName: string;
  };
};

type KitAssetTableData = {
  items: KitAssetResult[];
  total: number;
};

const [useKitAssetPerformance, fetchKitAssetPerformance] = createQueryAction<
  Args,
  KitAssetTableData
>(
  {
    entity: "analytics",
    action: "queryKitAssetPerformance",
    denormalize: false,
    pagingKey: "items",
  },
  async ({ args, paging }) => {
    const reports = {
      assets: {
        id: "assets",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        kit_uuid: args.kitId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ assets: KitAssetTableData }>(args.spaceId, reports)).assets;
  }
);

export default useKitAssetPerformance;
export { fetchKitAssetPerformance };
