import React, { Fragment, useState, useCallback } from "react";
import _debounce from "lodash/debounce";
import { Input, Tag, useBoolean } from "@thenounproject/lingo-core";

import useInspectableActions from "@features/context-menus/hooks/useInspectableActions";

import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import ModalBody from "../../../components/ModalBody";
import useNotifications from "@actions/useNotifications";
import useShowModal from "@redux/actions/useModals";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";

type Props = {
  tag: Tag;
};

const EditTagModal: React.FC<Props> = ({ tag }) => {
  const { deselectItems } = useSelectedItemsContext();
  const { editTag } = useInspectableActions([{ tag }]);
  const [loading, setLoading, setNotLoading] = useBoolean(false);
  const [value, setValue] = useState(tag.value);
  const [error, setError] = useState(null);
  const { showNotification } = useNotifications();
  const { dismissModal } = useShowModal();

  const buttonText = loading ? "Updating..." : "Update";
  const disabled = value.trim() === tag.value.trim() || loading || !value.trim();

  const onSubmit = useCallback(
    async (e?: React.FormEvent) => {
      if (e) e.preventDefault();
      if (disabled) return;
      setLoading();
      try {
        const result = await editTag(value);
        if (result.error) {
          setError(result.error.message || "Failed to update tag");
          return;
        } else {
          showNotification({ message: `Tag updated` });
          deselectItems({ type: "tagLibrary", ids: [tag.value] });
          dismissModal();
        }
      } finally {
        setNotLoading();
      }
    },
    [
      deselectItems,
      disabled,
      dismissModal,
      editTag,
      setLoading,
      setNotLoading,
      showNotification,
      tag.value,
      value,
    ]
  );

  return (
    <Fragment>
      <ModalHeader title={"Rename tag"} />
      <ModalBody>
        <Input
          autoFocus
          autoSelect
          label="Tag name"
          value={value}
          inputStyle={error ? "error" : undefined}
          message={error}
          onChange={e => {
            setError(null);
            setValue(e.target.value);
          }}
          onSubmit={onSubmit}
        />
      </ModalBody>
      <ModalFooter
        primary={{
          text: buttonText,
          onClick: onSubmit,
          disabled,
        }}
      />
    </Fragment>
  );
};

export default EditTagModal;
