import React, { useCallback, useState, Fragment, useEffect } from "react";
import { Input, Asset } from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import useCreateLinkAsset from "@redux/actions/items/useCreateLinkAsset";
import useSaveAssetMetdata from "@redux/actions/assets/useSaveAssetMetadata";
import useShowModal from "@redux/actions/useModals";
import useNotifications from "@actions/useNotifications";
import { type InsertPosition } from "@actions/uploads";

type Props = {
  insertPosition?: InsertPosition;
  asset?: Asset;
};

const textMap = {
  create: {
    title: "Add a link",
    buttonText: "Add link",
    processingButtonText: "Adding link...",
    successMessage: "Link created successfully",
  },
  edit: {
    title: "Edit Link",
    buttonText: "Edit link",
    processingButtonText: "Updating link...",
    successMessage: "Link updated successfully",
  },
};

const CreateLinkModal: React.FC<Props> = ({ insertPosition, asset }) => {
  const mode = asset ? "edit" : "create";
  const [createLinkAsset] = useCreateLinkAsset();
  const [saveAssetMetadata] = useSaveAssetMetdata();
  const { showNotification } = useNotifications();
  const [error, setError] = useState(null),
    [processing, setProcessing] = useState(false),
    [link, setLink] = useState(asset?.meta?.content?.url || ""),
    { dismissModal } = useShowModal();

  useEffect(() => {
    if (link.length && !link.startsWith("http://") && !link.startsWith("https://")) {
      setError("Link must start with http or https");
    } else {
      setError(null);
    }
  }, [link]);

  const submit = useCallback(async () => {
    setError(null);
    setProcessing(true);
    if (asset) {
      const { error: saveError } = await saveAssetMetadata({
        assetId: asset.id,
        data: { url: link },
      });
      if (saveError) {
        setError(saveError.message || "Something went wrong");
        setProcessing(false);
      } else {
        showNotification({
          message: textMap[mode].successMessage,
        });
        dismissModal();
      }
    } else {
      void createLinkAsset({ link, insertPosition });
      dismissModal();
    }
  }, [
    asset,
    createLinkAsset,
    dismissModal,
    insertPosition,
    link,
    mode,
    saveAssetMetadata,
    showNotification,
  ]);

  return (
    <Fragment>
      <ModalHeader title={asset ? textMap[mode].title : textMap[mode].title} />
      <ModalBody>
        <Input
          data-testid="link-input"
          value={link}
          label="URL"
          onChange={e => setLink(e.target.value)}
          onSubmit={submit}
          placeholder="Paste a link"
          message={error}
          inputStyle={error ? "error" : undefined}
          autoFocus
        />
      </ModalBody>
      <ModalFooter
        primary={{
          text: !processing ? textMap[mode].buttonText : textMap[mode].processingButtonText,
          onClick: submit,
          disabled: !link.length || processing || error === "Link must start with http or https",
        }}
      />
    </Fragment>
  );
};

export default CreateLinkModal;
