import { API, camelize, ChargePreview, Plan } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

type Result = {
  chargePreview: ChargePreview;
  plan: Plan;
};

type Args = {
  spaceId: number;
  planIdentifier: string;
  interval: string;
};

const [useChargePreview, fetchChargePreview] = createQueryAction<Args, Result, Result>(
  {
    entity: "spaces",
    action: "fetchChargePreview",
    denormalize: false,
  },
  async ({ args }) => {
    const res = await API.call<{ charge_preview: ChargePreview; plan: Plan }>({
      endpoint: `spaces/${args.spaceId}/plan/preview`,
      query: {
        plan_identifier: args.planIdentifier,
        interval: args.interval,
      },
      method: "GET",
    });
    return {
      result: {
        chargePreview: camelize(res.result.charge_preview) as ChargePreview,
        plan: camelize(res.result.plan) as Plan,
      },
    };
  }
);

export default useChargePreview;
export { fetchChargePreview };
