import React, { useMemo, useCallback } from "react";
import {
  Flex,
  PortalMigratePreview,
  Table,
  ActivityIndicator,
  Box,
  Button,
  SpaceMember,
  ImageView,
  EmptyState,
} from "@thenounproject/lingo-core";
import { TableColumn } from "../../table";
import ModalHeader from "../../ModalHeader";
import ModalBody from "../../ModalBody";
import ModalFooter from "../../ModalFooter";
import usePreviewMigratePortals from "@redux/actions/portals/usePreviewPortals";
import { TableCellText } from "@features/library/components/SharedElements";

import ReportTitle from "../../report/ReportTitle";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

type Props = {
  spaceId: number;
};

export default function MigrateToPortalsModal({ spaceId }: Props) {
  const { showModal, dismissModal } = useShowModal();

  const { data, isLoading, error } = usePreviewMigratePortals(
    { spaceId },
    { refetchOnMount: true }
  );

  const { previewPortals } = data || {},
    isEmpty = !isLoading && !error && !previewPortals?.length;
  const handleContinue = useCallback(() => {
    dismissModal();
    showModal(ModalTypes.CONFIRM_MIGRATION, { spaceId });
  }, [dismissModal, showModal, spaceId]);

  const handleMemberClick = useCallback(
    (member: SpaceMember) => {
      showModal(ModalTypes.SPACE_MEMBER_PERMISSIONS, {
        spaceId,
        userIds: [member.userId],
      });
    },
    [showModal, spaceId]
  );

  if (!spaceId)
    // this error should never happen but including so we get notified if it does
    throw new Error("Space ID is required for Portal Migration");

  const tableColumns = useMemo(() => {
    const cols: TableColumn<PortalMigratePreview>[] = [
      {
        name: "Name",
        minWidth: 360,
        flexGrow: 1,
        renderer: result => {
          return (
            <Flex alignItems="center" gap="8px" pl="40px">
              <ImageView
                height={64}
                width={64}
                icon="content.portal"
                alt="Portal"
                aspectRatio={63}
                background="grayLighter"
                border="default"
                borderRadius="small"
              />
              <TableCellText>{result.name}</TableCellText>
            </Flex>
          );
        },
      },
      {
        name: "Kits",
        minWidth: 360,
        renderer: result => (
          <Flex flexDirection="column" p="20px 0">
            {result.kits.map(kit => (
              <TableCellText key={kit.kitId}>{kit.name}</TableCellText>
            ))}
          </Flex>
        ),
      },
      {
        name: "Current Limited Members",
        minWidth: 360,
        renderer: result => {
          const members = result.members;
          if (!members.length) {
            return <TableCellText p="20px 0">No limited members</TableCellText>;
          }
          return (
            <Flex flexDirection="column" alignItems="flex-start" p="20px 0">
              {result.members.map(member => (
                <Button
                  text={member.user.name}
                  buttonStyle="tertiary"
                  truncate
                  key={member.id}
                  onClick={() => handleMemberClick(member)}
                />
              ))}
            </Flex>
          );
        },
      },
    ];
    return cols;
  }, [handleMemberClick]);

  function renderTable() {
    return (
      <Table
        alignItems="flex-start"
        padding="8px 0"
        tableData={{ data: previewPortals, isLoading, error }}
        columns={tableColumns}
        hideFinalBottomBorder
        dynamicHeight
      />
    );
  }

  const renderContent = () => {
    if (isEmpty) {
      return (
        <Box>
          <EmptyState styleOverrides={{ py: "xxl" }} title="No data" />
        </Box>
      );
    } else if (isLoading && !previewPortals?.length) {
      return (
        <Flex justifyContent="center">
          <ActivityIndicator />
        </Flex>
      );
    } else if (error && !previewPortals?.length) {
      return (
        <EmptyState
          title="Unable to load data"
          subtitle={error?.message}
          iconProps={{ iconId: "error", fill: "error" }}
        />
      );
    } else {
      return renderTable();
    }
  };

  return (
    <>
      <ModalHeader title="Migrate to portal based sharing" />
      <ModalBody px="0px" py="0px">
        <Box>
          <ReportTitle
            title="Portal migration tool"
            subtitle="In the chart below is a list of all the portals that will be created for you based on your existing user permissions. This is because in Lingo's new portals based sharing architecture members are joined to specific portals that contain kits. Automatically creating these portals will ensure your members continue to have access to the same set of kits as they did before. If you want to make changes to your user permissions in order to adjust the number of portals, you can do this below by clicking on the name of a user. For more information please read our help center article on the Portals Migration Tool."
          />
          <Flex width="100%" flexDirection="column" minHeight="375px">
            {renderContent()}
          </Flex>
        </Box>
      </ModalBody>
      <ModalFooter primary={{ text: "Continue", onClick: handleContinue }} />
    </>
  );
}
