import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { PersonId } from "../types";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  dateOperators?: OperatorValues;
  personId: PersonId;
};

type OverviewData = {
  result: Array<{
    date: string;
    guestViews: number;
    memberViews: number;
    totalViews: number;
    uniqueGuests: number;
    uniqueMembers: number;
    uniquePeople: number;
  }>;
};

const [useKitViewsOverviewByUser, fetchKitViewsOverviewByUser] = createQueryAction<
  Args,
  OverviewData
>(
  {
    entity: "analytics",
    action: "queryKitViewsOverviewByUser",
    denormalize: { entity: null },
  },
  async ({ args }) => {
    const reports = {
      kit_view_intervals: {
        id: "intervals",
        entity: "kit_views",
        person_id: args.personId,
        date_operators: args.dateOperators,
      },
    };

    return {
      result: (await fetchAnalytics<{ kitViewIntervals: OverviewData }>(args.spaceId, reports))
        .kitViewIntervals,
    };
  }
);

export default useKitViewsOverviewByUser;
export { fetchKitViewsOverviewByUser };
