import styled from "styled-components";
import { Button, utils } from "@thenounproject/lingo-core";

const { getColor } = utils;

const TextLink = styled(Button).attrs(props => {
  return {
    buttonStyle: "outline",
    fontStyle: "marketing.regularBold",
    icon: props.icon === null ? null : (props.icon ?? "arrow-right"),
    p: "none",
    borderBottom: "1px solid primary",
  };
})`
  border: none;
  height: unset;
  border-radius: 0;
  &:hover {
    background: transparent;
  }
  span {
    transition: 0.4s all ease;
    padding: 0 2px;
    border: 1px solid transparent;
    border-bottom: 1px solid ${getColor("primary")};
    color: ${getColor("primary")};
  }

  &:hover {
    span {
      color: ${getColor("primary")};
    }
  }
  &:focus,
  &:active,
  &:focus-within {
    span {
      border: 1px solid ${getColor("primary")};
      color: black;
    }
  }
  &:visited {
    span {
      border-bottom: 1px solid ${getColor("grayDarker")};
      color: ${getColor("grayDarker")};
    }
  }
`;

export default TextLink;
