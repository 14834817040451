import { Asset, PortalItem, Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  portalId: string;
};

export type PortalAssetResult = {
  asset: Asset;
  id: PortalItem["id"];
  sectionUuid: string;
  dateAdded: string;
  usage: {
    totalUses: number;
    kitName: string;
    sectionName: string;
    lastDownloaded: string;
  };
};

type PortalAssetTableData = {
  items: PortalAssetResult[];
  total: number;
};

const [usePortalAssetPerformance, fetchPortalAssetPerformance] = createQueryAction<
  Args,
  PortalAssetTableData
>(
  {
    entity: "analytics",
    action: "queryPortalAssetPerformance",
    denormalize: false,
    pagingKey: "items",
  },
  async ({ args, paging }) => {
    const reports = {
      assets: {
        id: "assets",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        portal_uuid: args.portalId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ assets: PortalAssetTableData }>(args.spaceId, reports)).assets;
  }
);

export default usePortalAssetPerformance;
export { fetchPortalAssetPerformance };
