import React from "react";

function isDragEvent(e: React.SyntheticEvent): e is React.DragEvent {
  return (e as React.DragEvent).dataTransfer !== undefined;
}

const getFilesFromEvent = (e: React.SyntheticEvent): File[] => {
  // Returns local file from either file input change event or drag & drop event
  if (isDragEvent(e)) {
    // Drag & drop event
    return Array.from(e.dataTransfer.files);
  } else if ((e.target as HTMLInputElement)?.files)
    // File input change event
    return Array.from((e.target as HTMLInputElement).files);
};
export default getFilesFromEvent;
