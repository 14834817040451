import { base64, SearchFilterInput, API } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";
import getSearchContext from "./getSearchContext";

type Query = { kitFinder: SearchFilterInput[] };
type Data = { queries: Query };
type Args = { spaceId: number; searchText: string };

const [useKitSearchSuggestions, fetchKitSearchSuggestions] = createQueryAction<
  Args,
  SearchFilterInput[]
>(
  {
    entity: "search",
    action: "kitSearchSuggestions",
    denormalize: false,
    debounce: true,
    cacheKey: args => {
      return { spaceId: args.spaceId };
    },
  },
  async ({ args }, thunkApi) => {
    const res = await API.call<Data>({
      endpoint: `search/spaces/${args.spaceId}`,
      method: "GET",
      entity: API.Entity.search,
      query: {
        query: base64.encode({
          queries: {
            kitFinder: {
              type: "suggestion",
              filter_type: "kit",
              value: args.searchText,
              ...getSearchContext(thunkApi.getState()),
            },
          },
        }),
      },
    });

    return { result: Object.values(res.result.queries.kitFinder) };
  }
);

export { fetchKitSearchSuggestions };
export default useKitSearchSuggestions;
