/**
 * Modal that allows creating a new version of a kit with form that accepts name, notes.
 */

import React, { Fragment, useCallback, useState } from "react";
import { Notice, Input, Textarea, LingoError } from "@thenounproject/lingo-core";
import _maxBy from "lodash/maxBy";
import useShowModal from "@redux/actions/useModals";
import useCreateKitVersion from "@redux/actions/kitVersions/useCreateKitVersion";
import useUpdateRecommendedVersion from "@redux/actions/kits/useUpdateRecommendedVersion";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import useKit from "@redux/actions/kits/useKit";
import useKitVersions from "@redux/actions/kitVersions/useKitVersions";
import useKitVersion from "@redux/actions/kitVersions/useKitVersion";

// Seconds to poll for new version before showing pending message
// const VERSION_POLLING_LIMIT = 10;

type Props = {
  kitId: string;
  spaceId: number;
};

const CreateKitVersionModal: React.FC<Props> = ({ kitId, spaceId }) => {
  const [error, setError] = useState<LingoError>(null),
    [nameError, setNameError] = useState<string>(null),
    [processing, setProcessing] = useState(false),
    [pendingIdentifier, setPendingIdentifier] = useState(""),
    [releaseNotes, setReleaseNotes] = useState(""),
    [createdVersion, setCreatedVersion] = useState<number>(null);
  const { dismissModal } = useShowModal();

  const { data: _versions } = useKitVersions({ kitId: kitId, spaceId });
  const { data: kit } = useKit({ kitId: kitId, spaceId });

  const versions = _versions?.filter(v => v.version !== 0).sort((a, b) => b.version - a.version);

  const { data: newVersion } = useKitVersion(
    { kitId: kitId, spaceId, version: createdVersion },
    { skip: !createdVersion }
  );
  const [createKitVersion] = useCreateKitVersion();
  const [updateRecommendedVersion] = useUpdateRecommendedVersion();
  const highestVersion = _maxBy(versions, "version");

  const createVersionSelected = useCallback(async () => {
    if (!pendingIdentifier) {
      return setNameError("Please enter a version name");
    }

    setError(null);
    setNameError(null);
    setProcessing(true);
    const { error: responseError, response } = await createKitVersion({
      kitId,
      spaceId,
      name: pendingIdentifier,
      notes: releaseNotes,
    });
    setProcessing(false);
    if (responseError) {
      setError(responseError);
    } else {
      const newVersion = response.entities.kitVersions[response.result];
      setCreatedVersion(newVersion.version);
    }
  }, [createKitVersion, kitId, pendingIdentifier, releaseNotes, spaceId]);

  const setRecommendedVersionSelected = useCallback(async () => {
    setProcessing(true);
    setError(null);
    setNameError(null);
    const result = await updateRecommendedVersion({
      kitId,
      spaceId,
      version: highestVersion.version,
    });
    setProcessing(false);
    if (result.error) {
      setError(result.error);
    } else {
      dismissModal();
    }
  }, [updateRecommendedVersion, kitId, spaceId, highestVersion?.version, dismissModal]);

  if (!kit) return null;

  if (newVersion?.status === "active") {
    return (
      <Fragment>
        <ModalHeader title={`“${newVersion.versionIdentifier}” created. Recommend it?`} />
        <ModalBody>
          <p>
            “{newVersion.versionIdentifier}” will become the recommended version for use. All legacy
            versions except the Shared Draft will continue to be accessible to all users. Users
            using them will be able to switch to the recommended version at their convenience.
          </p>
          {error && <Notice noticeStyle="error" message={error.message} />}
        </ModalBody>
        <ModalFooter
          primary={{
            text: processing ? "Recommending..." : "Yes, recommend",
            disabled: processing,
            onClick: setRecommendedVersionSelected,
          }}
        />
      </Fragment>
    );
  } else if (createdVersion) {
    return (
      <Fragment>
        <ModalHeader title={"We’re working on it"} />
        <ModalBody>
          <p>
            {pendingIdentifier ? `“${pendingIdentifier}”` : "A new version"} is being prepared.
            We’ll show a notification when it’s ready, or you can check the kit’s version history
            page to see the status.
          </p>
        </ModalBody>
        <ModalFooter
          primary={{
            text: "Dismiss",
            onClick: dismissModal,
          }}
        />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <ModalHeader title={`Create new version of “${kit.name}”`} />
        <ModalBody>
          <Input
            id="kit-name"
            type="text"
            label="Version name"
            value={pendingIdentifier}
            onChange={e => {
              setNameError(null);
              setPendingIdentifier(e.target.value);
            }}
            inputStyle={nameError ? "error" : null}
            message={nameError}
            placeholder={
              highestVersion?.version > 0
                ? `Last version: ${highestVersion.versionIdentifier}`
                : "v 1.0.0"
            }
          />
          <Textarea
            mt="m"
            label="Release notes"
            value={releaseNotes}
            onChange={e => setReleaseNotes(e.target.value)}
            placeholder="Here’s what changed in this version..."
          />

          {error && <Notice noticeStyle="error" message={error.message} />}
        </ModalBody>
        <ModalFooter
          primary={{
            text: processing ? "Preparing release..." : "Release",
            disabled: processing,
            onClick: createVersionSelected,
          }}
        />
      </Fragment>
    );
  }
};

export default CreateKitVersionModal;
