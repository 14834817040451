import { useCallback } from "react";
import { PortalPermission, Portal } from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";

export enum AccessStatus {
  canAccess = "canAccess",
  insufficientPlan = "insufficientPlan",
  insufficientRole = "insufficientRole",
}

type PortalUpsellProps = {
  portal: Portal;
  permission: PortalPermission;
};

export default function usePortalUpsell({ portal, permission }: PortalUpsellProps) {
  const { showModal } = useShowModal();

  const canAccess = portal.access.permissions.includes(permission);
  const requiredPlan = portal.access.upgradePermissions[permission];

  let accessStatus = AccessStatus.insufficientRole;
  if (canAccess) {
    accessStatus = AccessStatus.canAccess;
  } else if (requiredPlan) {
    accessStatus = AccessStatus.insufficientPlan;
  }

  const openUpgradeModal = useCallback(() => {
    showModal(ModalTypes.SELECT_PLAN, {
      recommendedPlan: requiredPlan,
    });
  }, [requiredPlan, showModal]);

  return {
    openUpgradeModal,
    accessStatus,
  };
}
