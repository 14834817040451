import { AssetType, Asset, API } from "@thenounproject/lingo-core";
import QueryString from "query-string";
import { createQueryAction } from "@actions/actionCreators";

const [useAssetOriginalUrl, fetchAssetOriginalUrl] = createQueryAction<Asset, string, string>(
  {
    entity: "assets",
    action: "fetchOriginalUrl",
    denormalize: false,
  },
  async ({ args }) => {
    if (!args) return null;
    const uuid = args.id,
      token = args.shareToken,
      fileType = args.type;
    if (fileType === AssetType.color) {
      return;
    }
    let queryString: string;
    if (AssetType.sketchTypes.has(fileType)) {
      queryString = QueryString.stringify({
        asset_token: token,
        type: "png",
        disposition: "inline",
      });
    } else {
      queryString = QueryString.stringify({
        asset_token: token,
        response: "json",
        disposition: "inline",
      });
    }
    const endpoint = `assets/${uuid}/download?${queryString}`;

    const res = await API.call<{ url: string }>({
      endpoint,
      method: "GET",
    });

    return { result: res.result.url, entities: res.entities };
  }
);

export default useAssetOriginalUrl;
export { fetchAssetOriginalUrl };

// export const useAssetOriginalUrl = (asset: Asset) => {
//   const endpoint = useMemo(() => {
//     if (!asset) return null;
//     const uuid = asset.id,
//       token = asset.shareToken,
//       fileType = asset.type;
//     if (fileType === AssetType.color) {
//       return;
//     }
//     let queryString;
//     if (AssetType.sketchTypes.has(fileType)) {
//       queryString = QueryString.stringify({
//         asset_token: token,
//         type: "png",
//         disposition: "inline",
//       });
//     } else {
//       queryString = QueryString.stringify({
//         asset_token: token,
//         response: "json",
//         disposition: "inline",
//       });
//     }
//     return `assets/${uuid}/download?${queryString}`;
//   }, [asset]);

//   return useQuery<{ url: string }>(endpoint, { revalidateOnFocus: false });
// };
