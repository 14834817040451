import useNavPoint from "@hooks/useNavPoint";
import React from "react";
import ModalBody from "../ModalBody";
import KitNav from "./KitNav";

const KitNavModal = () => {
  const navPoint = useNavPoint();
  if (!navPoint.kit) return null;

  return (
    <ModalBody>
      <KitNav
        space={navPoint.space}
        portal={navPoint.portal}
        kit={navPoint.kit}
        sectionId={navPoint.sectionId}
        kitVersion={navPoint.kitVersion}
      />
    </ModalBody>
  );
};

export default KitNavModal;
