import React, { useCallback } from "react";
import { PublicAccessApprovalMode } from "@thenounproject/lingo-core";

import useShowModal from "@redux/actions/useModals";

import ConfigureRequestsModalContent from "./ConfigureRequestsModalContent";
import usePortal from "@redux/actions/portals/usePortal";
import useUpdatePortal from "@redux/actions/portals/useUpdatePortal";

type Props = {
  portalId: string;
};

const ConfigurePortalRequestsModal = ({ portalId }: Props) => {
  const { data: portal } = usePortal({ portalId });
  const { dismissModal } = useShowModal();
  // TODO: Connect to portal version of this hook
  const [updatePortal, { error, isProcessing }] = useUpdatePortal();

  const [approveMode, setApproveMode] = React.useState<PublicAccessApprovalMode>(
    portal?.downloadsRestrictedApproveMode ?? "approve_all_automatically"
  );

  const canSubmit = React.useMemo(() => {
    if (!portal || isProcessing) return false;

    return approveMode !== portal.downloadsRestrictedApproveMode;
  }, [approveMode, isProcessing, portal]);

  const onSubmit = useCallback(async () => {
    if (!canSubmit) return;
    const result = await updatePortal({
      id: portal.id,
      downloadsRestrictedApproveMode: approveMode,
    });
    if (result.isSuccess) {
      dismissModal();
    }
  }, [approveMode, canSubmit, dismissModal, portal.id, updatePortal]);

  return (
    <ConfigureRequestsModalContent
      approvalSetting={approveMode}
      canSubmit={canSubmit}
      error={error}
      onChange={setApproveMode}
      onSubmit={onSubmit}
    />
  );
};

export default ConfigurePortalRequestsModal;
