import React, { ReactNode } from "react";
import { OperatorValues } from "@thenounproject/lingo-core";

import { useObject } from "@hooks/useObject";
import { formatCustomInterval, periodOptions } from "./InsightsContext";

import { initialState, InsightsContext, IntervalOptions } from "./InsightsContext";

interface Props {
  children: ReactNode;
}

export default function InsightsProvider({ children }: Props) {
  const [interval, setInterval] = React.useState<IntervalOptions>(initialState.interval);
  const [dateOperators, setDateOperators] = React.useState<OperatorValues>(
    initialState.dateOperators
  );
  const [customInterval, setCustomInterval] = React.useState<[Date, Date]>();

  function updateInterval(interval: IntervalOptions, customInterval?: [Date, Date]) {
    if (interval === IntervalOptions.custom) {
      setInterval(interval);
      setCustomInterval(customInterval);
      setDateOperators(formatCustomInterval(...customInterval));
    } else {
      setDateOperators(periodOptions[interval].dateOperators);
      setInterval(interval);
      setCustomInterval(undefined);
    }
  }

  const context = useObject({ interval, dateOperators, updateInterval, customInterval });

  return <InsightsContext.Provider value={context}>{children}</InsightsContext.Provider>;
}

export const useInsightsContext = () => React.useContext(InsightsContext);
