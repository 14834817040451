/**
 * Handles image display with some fanciness
 *  - Shows loading state while browser is fetching image
 *  - Maintains aspect ratio as image is resized
 */

import React, { useRef, useState, useLayoutEffect, Fragment } from "react";
import styled from "styled-components";
import { ActivityIndicator, Box, BoxProps } from "@thenounproject/lingo-core";

const ImageCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const ParentContainerCover = styled.div`
  height: 100%;
  width: 100%;
`;

type Props = {
  src: string;
  aspectRatio?: string;
  background?: string;
  style?: BoxProps;
  displayStyle?: "cover" | "contain";
  spinner?: boolean;
  spinnerCentered?: boolean;
  spinnerCoverParent?: boolean;
  isEnlargedAsset?: boolean;
};

const SmartImage: React.FC<Props> = ({
  src,
  aspectRatio = "1:1",
  background = "grayLighter",
  style: customStyle = {},
  displayStyle = "cover",
  spinner = true,
  spinnerCentered = true,
  spinnerCoverParent = false,
  isEnlargedAsset = false,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false),
    [imageWidth, setImageWidth] = useState(0),
    [imageHeight, setImageHeight] = useState(0),
    loadingImage = useRef<HTMLImageElement>(null),
    imgDiv = useRef<HTMLImageElement>(),
    srcChanged = !loadingImage.current || loadingImage.current.src !== src;

  /**
   * Start loading image, and when the onload event fires, update state to indicate that the image
   * is ready to be rendered. At this point, we can hide the spinner and fade in the image.
   */
  useLayoutEffect(() => {
    if (loadingImage.current) {
      loadingImage.current.onload = null;
      loadingImage.current.onerror = null;
      loadingImage.current = null;
    }

    setImageLoaded(false);
    loadingImage.current = new Image();
    loadingImage.current.onload = handleImageLoaded;
    loadingImage.current.src = src;
  }, [src]);

  /**
   * When browser has fully fetched image, update state.
   */
  function handleImageLoaded() {
    setImageLoaded(true);
    setImageWidth(loadingImage.current.width);
    setImageHeight(loadingImage.current.height);
  }

  function renderImage() {
    return (
      <ImageCover
        ref={imgDiv}
        style={{
          background: getBackgroundStyle(),
          opacity: imageLoaded ? 1 : 0,
          transition: "opacity .3s ease",
        }}
      />
    );
  }

  /**
   * Return background style property for rendering image. Any image larger than container div
   * will have background-size contain, anything smaller will use the size of the image itself.
   */
  function getBackgroundStyle() {
    let imageBackgroundSize: string = displayStyle;
    if (imgDiv.current && imageLoaded) {
      const divHeight = imgDiv.current.offsetHeight,
        divWidth = imgDiv.current.offsetWidth;

      if (!isEnlargedAsset && divHeight > imageHeight && divWidth > imageWidth) {
        imageBackgroundSize = `${imageWidth}px ${imageHeight}px`;
      }
    }

    return src && imageLoaded
      ? `center ${imageBackgroundSize ? `/ ${imageBackgroundSize}` : ""} no-repeat url(${src})`
      : background;
  }

  function renderActivityIndicator() {
    return (
      <ParentContainerCover>
        <ActivityIndicator center={spinnerCentered} color="grayDark" />
      </ParentContainerCover>
    );
  }

  const ratio = aspectRatio.split(":").map(v => parseInt(v)),
    activityIndicator = (srcChanged || !imageLoaded) && spinner ? renderActivityIndicator() : null,
    style = {
      position: "relative",
      borderRadius: "4px",
      width: "100%",
      paddingBottom: displayStyle === "cover" ? `${(ratio[1] / ratio[0]) * 100}%` : null,
      height: displayStyle === "contain" ? "100%" : null,
      background,
      ...customStyle,
    };

  return (
    <>
      {spinnerCoverParent ? (
        activityIndicator || <Box {...style}>{renderImage()}</Box>
      ) : (
        <Box {...style}>
          {activityIndicator}
          {renderImage()}
        </Box>
      )}
    </>
  );
};

export default SmartImage;
