import React from "react";
import {
  PopupMenu,
  Flex,
  Input,
  CustomField,
  Button,
  formatDate,
  SearchFilterInput,
  SearchFilterTypes,
  SearchOperators,
} from "@thenounproject/lingo-core";

import { SearchQueryFilterOption } from "../types";
import { CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP } from "./AssetLibraryFilterBar";
import { formatSearchFilterDate } from "../utils";
import DateRangePicker from "./DateRangePicker";
import { sanitizeNumberInput } from "@helpers/sanitizeNumberInput";

type Props = {
  option: SearchQueryFilterOption;
  onSelectFilter: (filter: SearchFilterInput) => void;
  activeFilterValues: number[];
  field: CustomField;
  operator?: SearchOperators;
  dateOption?: "exact" | "range";
};

const MAP_OPERATOR_TO_LABEL: Record<SearchOperators, string> = {
  eq: " equals",
  lt: " is less than",
  gt: " is greater than",
  gte: " is greater than or equal to",
  lte: " is less than or equal to",
};

const MAP_DATE_OPERATOR_TO_LABEL: Record<SearchOperators, string> = {
  eq: "",
  lt: " is before",
  gt: " is after",
  gte: " is on or after",
  lte: " is on or before",
};

const TYPE_TO_OPERATOR_MAP: Record<string, Record<SearchOperators, string>> = {
  [SearchFilterTypes["field-date"]]: MAP_DATE_OPERATOR_TO_LABEL,
  [SearchFilterTypes["field-number"]]: MAP_OPERATOR_TO_LABEL,
};

const applyButtonEnabledTypes = [
  SearchFilterTypes["field-text"],
  SearchFilterTypes["field-number"],
  SearchFilterTypes["field-date"],
];

const inputEnabledTypes = [SearchFilterTypes["field-text"], SearchFilterTypes["field-number"]];

const MAP_TYPE_TO_PLACEHOLDER: Record<string, string> = {
  [SearchFilterTypes["field-text"]]: "Contains...",
  [SearchFilterTypes["field-number"]]: "Enter a number",
  [SearchFilterTypes["field-date"]]: "mm / dd / yyyy",
};

const AssetLibraryFieldFilterMenu: React.FC<Props> = ({
  option,
  onSelectFilter,
  activeFilterValues,
  field,
  operator,
  dateOption,
}) => {
  const [value, setValue] = React.useState("");
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  const isExactDate = dateOption === "exact";
  const isRangeDate = dateOption === "range";

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    switch (option.type) {
      case SearchFilterTypes["field-number"]:
      case SearchFilterTypes["field-text"]:
        {
          if (!value.trim()) return;
          onSelectFilter({
            type: CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP[field.type],
            field_id: field.id,
            display: value.trim(),
            ...(operator
              ? { operator_values: { [operator]: value.trim() } }
              : { value: value.trim() }),
          });
        }
        break;
      case SearchFilterTypes["field-date"]: {
        if (!startDate && !endDate) return;
        onSelectFilter({
          type: SearchFilterTypes["field-date"],
          field_id: field.id,
          display: isExactDate
            ? formatDate(startDate.toISOString(), "MM/dd/yyyy")
            : `${formatDate(startDate.toISOString(), "MM/dd/yyyy")} - ${formatDate(
                endDate.toISOString(),
                "MM/dd/yyyy"
              )}`,
          operator_values: {
            ...(isExactDate ? { eq: formatSearchFilterDate(startDate.toISOString()) } : {}),
            ...(isRangeDate
              ? {
                  gte: formatSearchFilterDate(startDate.toISOString()),
                  lte: formatSearchFilterDate(endDate.toISOString()),
                }
              : {}),
          },
        });
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let sanitizedValue = e.target.value;
    if (option.type === SearchFilterTypes["field-number"]) {
      sanitizedValue = sanitizeNumberInput(e.target.value);
    }
    setValue(sanitizedValue);
  };

  return (
    <PopupMenu.Section
      title={field.name.concat(TYPE_TO_OPERATOR_MAP[option.type]?.[operator] || "")}>
      {inputEnabledTypes.includes(option.type) && (
        <Flex
          p="8px 12px"
          background="grayLightest"
          borderBottom="default"
          as="form"
          onSubmit={onSubmit}>
          <Input
            placeholder={MAP_TYPE_TO_PLACEHOLDER[option.type]}
            onChange={handleChange}
            value={value}
            size="small"
            autoFocus
          />
        </Flex>
      )}
      {dateOption === "range" && (
        <Flex
          p="8px 12px"
          width={"100%"}
          background="grayLightest"
          borderBottom="default"
          as="form"
          position="relative"
          zIndex={1}
          onSubmit={onSubmit}>
          <DateRangePicker
            startDate={startDate ? new Date(startDate) : undefined}
            endDate={endDate ? new Date(endDate) : undefined}
            onChange={dates => {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }}
            openByDefault
            withPortal
          />
        </Flex>
      )}
      {field.options?.map(option => (
        <PopupMenu.Item
          title={option.name}
          key={option.id}
          disabled={activeFilterValues.includes(option.id)}
          onClick={() =>
            onSelectFilter({
              type: CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP[field.type],
              field_id: field.id,
              display: option.name,
              value: String(option.id),
            })
          }
          truncate
        />
      ))}
      {applyButtonEnabledTypes.includes(option.type) && (
        <Flex p="6px 12px" background="grayLightest">
          <Button
            text="Apply"
            onClick={() => onSubmit()}
            fullWidth
            size="small"
            disabled={!value.trim() && !startDate && !endDate}
          />
        </Flex>
      )}
    </PopupMenu.Section>
  );
};

export default AssetLibraryFieldFilterMenu;
