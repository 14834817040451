/**
 * Space owner uses this component to transfer a space to another user.
 */

import React, { useEffect, useCallback, useState, Fragment } from "react";

import {
  Button,
  Text,
  Box,
  OptionCard,
  Repo,
  Input,
  ActivityIndicator,
  useNavigation,
  buildURL,
} from "@thenounproject/lingo-core";

import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";

import useNotifications from "@actions/useNotifications";
import useFetchSpaceTransferCandidates from "@redux/actions/spaces/useFetchSpaceTransferCandidates";
import useTransferSpace from "@redux/actions/spaces/useTransferSpace";
import useShowModal from "@redux/actions/useModals";

import { useSelectSpace } from "@selectors/entities/spaces";

function TransferSpaceModal() {
  const { dismissModal } = useShowModal();
  const navigation = useNavigation();
  const space = useSelectSpace();
  const { showNotification } = useNotifications();
  const [fetchSpaceTransferCandidates] = useFetchSpaceTransferCandidates();
  const [transferSpace] = useTransferSpace();

  const [selectedUser, setSelectedUser] = useState(null),
    [members, setMembers] = useState([]),
    [confirmationInput, setConfirmationInput] = useState(""),
    [error, setError] = useState(null),
    [processing, setProcessing] = useState(false);
  useEffect(() => {
    void fetchSpaceTransferCandidates({ spaceId: space.id }).then(
      ({ error: responseError, response }) => {
        if (responseError) {
          setError(responseError.message);
        } else {
          const repo = new Repo(response);
          const { result } = response;
          const candidates = result.members.map(id => repo.getSpaceMember(id).user);
          setMembers(candidates);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSpaceTransferCandidates, space.id]);

  const openMembers = useCallback(() => {
    const membersUrl = buildURL("/members/", { space });
    dismissModal();
    navigation.push(membersUrl);
  }, [dismissModal, navigation, space]);

  const submit = useCallback(async () => {
    if (!selectedUser) {
      return setError("Please select a new owner.");
    } else if (confirmationInput.toLowerCase() !== "transfer") {
      return setError('Please type "transfer" to confirm.');
    }
    setProcessing(true);
    const { error: responseError } = await transferSpace({
      spaceId: space.id,
      newOwnerId: selectedUser.id,
    });
    setProcessing(false);
    if (responseError) {
      setError(responseError.message || "Oops! That didn’t work.");
    } else {
      showNotification({ message: `${space.name} transferred to ${selectedUser.name}.` });
      dismissModal();
    }
  }, [
    confirmationInput,
    dismissModal,
    selectedUser,
    showNotification,
    space.id,
    space.name,
    transferSpace,
  ]);

  function renderConfirmationInput() {
    return (
      <Input
        id="confirmation-input"
        styleOverrides={{ mt: "xl" }}
        value={confirmationInput}
        label='Confirm by typing "transfer"'
        type="text"
        onChange={e => setConfirmationInput(e.target.value)}
        onSubmit={submit}
        placeholder="Transfer"
        message={error}
        inputStyle={error ? "error" : null}
      />
    );
  }

  function renderMemberList() {
    if (!members) return <ActivityIndicator center height="200" />;
    if (!members.length) {
      return (
        <Box textAlign="center">
          <Text font="ui.regularBold">No admins available for transfer</Text>
          <Button
            onClick={openMembers}
            buttonStyle="tertiary"
            size="small"
            text="Make one of the space members an admin"
          />
        </Box>
      );
    }

    const selectedUserId = selectedUser ? selectedUser.id : null,
      items = members.map(member => (
        <OptionCard
          key={member.id}
          title={member.name}
          detail={member.email}
          cardStyle="radio"
          selected={selectedUserId === member.id}
          onClick={() => setSelectedUser(member)}
          image={{ style: "circle", src: member.avatar }}
        />
      ));

    return (
      <Box mb="l">
        <Text as="label" mb="xs">
          Select an admin
        </Text>
        <Box textAlign="left">{items}</Box>
        <Text font="ui.small" mt="m">
          Don’t see who you’re looking for?{" "}
          <Button
            onClick={openMembers}
            buttonStyle="tertiary"
            size="small"
            text="Make them an admin."
          />
        </Text>

        {renderConfirmationInput()}
      </Box>
    );
  }

  return (
    <Fragment>
      <ModalHeader title={`Transfer ownership of “${space.name}”`} />
      <ModalBody>
        <Text mb="l">
          You can transfer the ownership of this space to another admin. Please note that the credit
          card associated with this space will remain unchanged.
        </Text>
        {renderMemberList()}
      </ModalBody>
      <ModalFooter
        primary={{
          text: processing ? "Transferring..." : "Transfer space",
          onClick: submit,
          disabled: processing,
        }}
      />
    </Fragment>
  );
}

export default TransferSpaceModal;
