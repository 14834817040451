/* eslint-disable react/prop-types */

import React, { useEffect, useMemo, MouseEvent } from "react";
import {
  Flex,
  Box,
  openIntercom,
  Icon,
  Text,
  Button,
  Asset,
  AssetType,
  formatDate,
  SearchFilterInput,
  TableCell,
  EmptyState,
} from "@thenounproject/lingo-core";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import InspectorView from "../../../components/inspector/InspectorView";
import { InspectorSource } from "@constants/Inspector";
import AssetLibraryHeader from "../components/AssetLibraryHeader";
import { useAssetLibraryContext } from "../../../contexts/AssetLibraryProvider";
import { canCreateAssets, canViewAssetLibrary } from "../utils";
import { useSelectSpace } from "@selectors/entities/spaces";
import AssetLibrarySelectAll from "../components/AssetLibrarySelectAll";
import { NavPointTypes, NavPoint } from "@redux/legacy-actions/navPoints";
import DataTable, { dataTableRowHeights } from "../../../components/table/DataTable";
import useLibraryAssets from "@redux/actions/search/useLibraryAssets";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";

import { SearchSortTypes } from "../types";
import LibraryAssetDetail from "../pages/LibraryAssetDetail";
import formatBytes from "@helpers/bytesToSize";
import { TableColumn } from "../../../components/table";
import { InspectableContextMenuItems } from "@features/context-menus/GalleryContextMenu";
import { AssetPolling } from "../../../components/kits/GalleryAssetContent/PollingWrappers";
import assetIconMap from "../../../components/kits/GalleryAssetContent/assetIconMap";
import getAssetBackground from "@helpers/getAssetBackground";
import { useAssetSelection } from "@redux/actions/assets/useAssetSelection";

const ScrollContainer = styled(Box).attrs<{ rowSize?: number; height?: string }>(
  ({ rowSize, height }) => ({
    height: height ? height : `calc(100% + ${rowSize})px`,
  })
)<{ rowSize?: number }>`
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
`;

export function renderEmptyState(appliedFilters: SearchFilterInput[]) {
  if (!appliedFilters.length) {
    return (
      <EmptyState
        title="Your asset library is empty"
        subtitle="Click the add button or add assets to a kit"
        iconProps={{ iconId: "empty-state.assets", size: "60", fill: "gray" }}
      />
    );
  }
  return (
    <EmptyState
      title="No assets match your query"
      subtitle="Click the active filters to remove them"
      iconProps={{ iconId: "empty-state.assets", size: "60", fill: "gray" }}
    />
  );
}

const AssetLibraryView = ({ navPoint }: { navPoint?: NavPoint }) => {
  const [sort, setSort] = React.useState<SearchSortTypes>(SearchSortTypes.recentDesc);
  const [rowSize, setRowSize] = React.useState<number>(0);

  const space = useSelectSpace(),
    { selectionState } = useSelectedItemsContext(),
    { appliedFilters, setFilters, assetRowSize } = useAssetLibraryContext(),
    { data, isLoading, fetchNextPage, error } = useLibraryAssets(
      { spaceId: space.id, filters: appliedFilters, sort },
      { refetchOnMount: true }
    );

  const hasNextPage = data?.assets?.length < data?.total;

  const selectedLibraryAssets = useMemo(() => {
    return data?.assets.filter(asset => selectionState.assetLibrary.includes(asset?.id)) ?? [];
  }, [data, selectionState.assetLibrary]);

  const inspectables = React.useMemo(
    () =>
      selectedLibraryAssets?.map(asset => ({
        asset: asset,
      })),
    [selectedLibraryAssets]
  );

  const tableColumns = useMemo(() => {
    const cols: TableColumn<Asset>[] = [
      {
        name: "Name",
        minWidth: 350,
        renderer: asset => {
          return (
            <AssetPolling asset={asset}>
              <TableCell
                data={asset}
                space={space}
                rowSize={assetRowSize}
                icon={assetIconMap[asset.type]}
                background={getAssetBackground(asset, space.theme).background}
                insights={false}
              />
            </AssetPolling>
          );
        },
        sortKey: "alpha",
      },
      {
        name: "Type",
        minWidth: 100,
        renderer: asset => <Text>{AssetType.displayName(asset?.type)}</Text>,
        sortKey: "type",
      },
      {
        name: "File Size",
        minWidth: 100,
        renderer: asset => <Text>{formatBytes(asset.size)}</Text>,
        sortKey: "size",
      },
      {
        name: "Resolution",
        minWidth: 100,
        renderer: asset => <Text>{asset.dimensions}</Text>,
        sortKey: "resolution",
      },
      {
        name: "Date Created",
        minWidth: 100,
        renderer: asset => <Text>{formatDate(asset.dateAdded, "MMM do, yyyy")}</Text>,
        sortKey: "recent",
      },
    ];
    return cols;
  }, [space, assetRowSize]);

  const selectionProps = useAssetSelection({ assets: data?.assets });

  /**
   * Clear filters when view unmounts
   * */
  useEffect(() => {
    return () => {
      setFilters([]);
    };
  }, [setFilters]);

  useEffect(() => {
    setRowSize(dataTableRowHeights[assetRowSize]);
  }, [assetRowSize]);

  if (!canViewAssetLibrary(space)) {
    return (
      <Flex
        height="100vh"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p="20px"
        data-testid="asset-library-forbidden">
        <Icon {...{ iconId: "info.lock-closed", size: "80", fill: "grayDark" }} />
        <Text {...{ as: "h2", font: "ui.title", mb: "xxs", color: "grayDarker" }}>Admin-only</Text>
        <Text
          {...{
            as: "h3",
            font: "ui.regular",
            color: "grayDarker",
            maxWidth: "400px",
          }}>
          You don&apos;t have access to the asset library for this space
        </Text>
        <Button
          {...{
            text: "Get help",
            onClick: () => openIntercom(),
            mt: "12px",
          }}
        />
      </Flex>
    );
  }

  function renderAssetOverlay() {
    if (navPoint?.type === NavPointTypes.LibraryAsset) {
      return (
        <LibraryAssetDetail
          navPoint={navPoint}
          queryData={{ data, isLoading, fetchNextPage }}
          hasNextPage={hasNextPage}
        />
      );
    }
  }

  const renderContextMenuItems = () => {
    return (
      <InspectableContextMenuItems inspectables={inspectables} canEdit={canCreateAssets(space)} />
    );
  };

  return (
    <>
      <Helmet>
        <title>{space.name} Library</title>
      </Helmet>
      {renderAssetOverlay()}
      <Flex
        flexDirection="column"
        width="calc(100vw - 312px)" // Width of screen minus supernav and side nav
        height="100%"
        overflow="hidden"
        variations={{ "mq.s": { width: "100%" } }}>
        <AssetLibraryHeader />
        <ScrollContainer height="100%">
          <InspectorView
            inspectables={inspectables}
            canEdit={canCreateAssets(space)}
            source={InspectorSource.library}>
            <ScrollContainer key={rowSize} rowSize={rowSize}>
              <Flex
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                top="0"
                px="xl"
                py="m"
                data-inspector-clear="true">
                <AssetLibrarySelectAll data={data} />
              </Flex>
              <DataTable<Asset>
                columns={tableColumns}
                data={data?.assets || []}
                isLoading={isLoading}
                hasMorePages={hasNextPage}
                fetchNextPage={fetchNextPage}
                tableError={error}
                selectionProps={selectionProps}
                emptyState={renderEmptyState(appliedFilters)}
                sort={sort}
                onSort={(sortKey: string) => setSort(sortKey as SearchSortTypes)}
                onRowClick={(asset: Asset, e: MouseEvent) => selectionProps.selectAsset(asset, e)}
                renderContextMenuItems={renderContextMenuItems}
                isLibraryView={true}
              />
            </ScrollContainer>
          </InspectorView>
        </ScrollContainer>
      </Flex>
    </>
  );
};

export default React.memo(AssetLibraryView);
