/**
 * Asset for displaying text styles.
 *
 * This is a work in progress component, not totally sure what the props will look like ultimately.
 */

import React from "react";
import styled from "styled-components";
import { Box, Text, Asset } from "@thenounproject/lingo-core";

import { AssetActionButton } from "./AssetActionButton";
import FontSample from "./FontSample";

const AssetContainer = styled(Box).attrs({
  position: "relative",
  color: "grayDarkest",
  border: "2px solid grayLight",
  borderRadius: "4px",
  background: "grayLightest",
})`
  &:hover ${AssetActionButton} {
    opacity: 1;
  }
`;

type Props = {
  asset: Asset;
  isThumbnail?: boolean;
  downloadButton?: React.ReactNode;
};

export default function AssetFont({ isThumbnail, downloadButton, asset }: Props) {
  const styleMeta = asset.meta.textStyle || {},
    displayAsFont = Object.values(styleMeta).filter(m => m).length === 0;

  return (
    <AssetContainer className="asset-parent asset-parent-file">
      {asset.name && isThumbnail && !displayAsFont ? (
        <Text as="h1" ml="m" mt="m" font="ui.regular">
          {asset.name}
        </Text>
      ) : null}
      <Box p="m">
        <FontSample asset={asset} isThumbnail={isThumbnail} />
      </Box>
      {isThumbnail && downloadButton ? downloadButton : null}
    </AssetContainer>
  );
}
