import React from "react";
import { TabBar, Space } from "@thenounproject/lingo-core";

import ModalHeader from "../../ModalHeader";
import InsightsKitViewTab from "./InsightsKitViewTab";
import InsightsKitAssetsTab from "./InsightsKitAssetsTab";
import InsightsKitPortalTab from "./InsightsKitPortalTab";
import { InsightsPageWrapper } from "../ReportElements";

type Props = {
  kitId: string;
  kitName: string;
  space: Space;
};

enum Tab {
  views = "views",
  assets = "assets",
  portals = "portals",
}

const InsightsKitModal = ({ kitId, kitName, space }: Props) => {
  const [selectedTab, setSelectedTab] = React.useState<string>(Tab.views);
  const hasPortalsFeature = space?.features?.includes("portals");

  function renderTab() {
    switch (selectedTab) {
      case Tab.views:
        return <InsightsKitViewTab kitId={kitId} space={space} />;
      case Tab.assets:
        return <InsightsKitAssetsTab kitId={kitId} space={space} />;
      case Tab.portals:
        return <InsightsKitPortalTab kitId={kitId} space={space} />;
      default:
        return null;
    }
  }

  return (
    <>
      <ModalHeader title={kitName || "Kit"}>
        <TabBar>
          <TabBar.Item
            text="Views"
            selected={selectedTab === Tab.views}
            onClick={() => setSelectedTab(Tab.views)}
          />
          <TabBar.Item
            text="Assets"
            selected={selectedTab === Tab.assets}
            onClick={() => setSelectedTab(Tab.assets)}
          />
          {hasPortalsFeature && (
            <TabBar.Item
              text="Portals"
              selected={selectedTab === Tab.portals}
              onClick={() => setSelectedTab(Tab.portals)}
            />
          )}
        </TabBar>
      </ModalHeader>
      <InsightsPageWrapper>{renderTab()}</InsightsPageWrapper>
    </>
  );
};

export default InsightsKitModal;
