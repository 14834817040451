import { useRef, useEffect, MouseEvent, useCallback } from "react";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";
import { useShiftKey } from "@helpers/selection";
import { handleWithModifiers, useKeyboard } from "@helpers/useKeyboard";
import { Asset } from "@thenounproject/lingo-core";
import { handleArrowSelection } from "@features/library/handleArrowSelection";

export function useAssetSelection(data: { assets?: Asset[] }) {
  const { selectionState, selectItems, deselectItems, clearSelection } = useSelectedItemsContext();
  const cachedSelectedItems = useRef<string[]>(null);
  const shiftDown = useShiftKey();

  useEffect(() => {
    if (!shiftDown) cachedSelectedItems.current = null;
  }, [shiftDown]);

  const selectAsset = useCallback(
    (asset: Asset, event: MouseEvent) => {
      const currentSelection = selectionState.assetLibrary;
      const allAssets = data?.assets || [];
      let selectedBeforeClick = currentSelection;
      document.getSelection().removeAllRanges();

      handleWithModifiers(event, {
        shiftAction: () => {
          if (currentSelection.length > 0) {
            if (!cachedSelectedItems.current || cachedSelectedItems.current.length === 0) {
              cachedSelectedItems.current = currentSelection;
            }
            selectedBeforeClick = cachedSelectedItems.current;
            const lastSelectedAssetId = selectedBeforeClick[selectedBeforeClick.length - 1];
            const anchorIndex = allAssets.findIndex(a => a.id === lastSelectedAssetId);
            const clickedIndex = allAssets.findIndex(a => a.id === asset.id);
            const [start, end] = [anchorIndex, clickedIndex].sort((a, b) => a - b);
            const itemsInRange = allAssets.slice(start, end + 1).map(a => a.id);

            selectItems({ type: "assetLibrary", ids: itemsInRange });
          }
        },
        metaAction: () => {
          if (currentSelection.includes(asset?.id)) {
            deselectItems({ type: "assetLibrary", ids: [asset?.id] });
          } else {
            selectItems({ type: "assetLibrary", ids: [...currentSelection, asset?.id] });
          }
        },
        default: () => {
          clearSelection({ type: "assetLibrary" });
          selectItems({ type: "assetLibrary", ids: [asset.id] });
        },
      });
    },
    [selectionState.assetLibrary, selectItems, deselectItems, clearSelection, data]
  );

  const handleArrowKey = useCallback(
    (e: KeyboardEvent) => {
      const currentSelection = selectionState.assetLibrary;
      handleArrowSelection(e, currentSelection, data?.assets || [], selectItems, deselectItems);
    },
    [data?.assets, selectItems, deselectItems, selectionState.assetLibrary]
  );

  useKeyboard({ onArrowKey: handleArrowKey });

  return {
    isSelected: (asset: Asset) => selectionState.assetLibrary.includes(asset?.id),
    onClick: (asset: Asset) => {
      if (selectionState.assetLibrary.includes(asset?.id)) {
        deselectItems({ type: "assetLibrary", ids: [asset?.id] });
      } else {
        selectItems({ type: "assetLibrary", ids: [...selectionState.assetLibrary, asset?.id] });
      }
    },
    selectAsset,
    disabled: () => false,
    type: "assetLibrary",
    selection: selectionState.assetLibrary,
  };
}
