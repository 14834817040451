import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  dateOperators?: OperatorValues;
  kitId: string;
};

type OverviewData = {
  result: Array<{
    date: string;
    guestViews: number;
    memberViews: number;
    totalViews: number;
    uniqueGuests: number;
    uniqueMembers: number;
    uniquePeople: number;
  }>;
};

const [useKitViewIntervals, fetchKitViewIntervals] = createQueryAction<Args, OverviewData>(
  {
    entity: "analytics",
    action: "queryKitViews",
    denormalize: { entity: null },
  },
  async ({ args }) => {
    const reports = {
      kit_view_intervals: {
        id: "intervals",
        entity: "kit_views",
        kit_uuid: args.kitId,
        date_operators: args.dateOperators,
      },
    };

    return { result: (await fetchAnalytics(args.spaceId, reports)).kitViewIntervals };
  }
);

export default useKitViewIntervals;
export { fetchKitViewIntervals };
