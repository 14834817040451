import React, { useCallback, useState } from "react";
import styled from "styled-components";

import FilePicker from "../../FilePicker";
import {
  Text,
  Button,
  Flex,
  Icon,
  Notice,
  pluralize,
  ItemType,
  PortalItemType,
} from "@thenounproject/lingo-core";
import ModalBody from "../../ModalBody";
import { getFileExtension, typeErrorMessage } from "@helpers/files";

import useModals from "@redux/actions/useModals";
import useContentTypes from "@redux/actions/useFetchContentTypes";

const DropZone = styled(Flex).attrs({
  position: "relative",
  border: "grayDashed",
  borderRadius: "default",
  p: "xxl",
  flexDirection: "column",
  alignItems: "center",
  mx: "auto",
})``;

const FileError = styled(Notice).attrs({
  noticeStyle: "error",
  mt: "m",
  mb: "-m",
})`
  opacity: ${props => (props.message ? null : 0)};
`;

type Props = {
  itemType: ItemType | PortalItemType;
  assetType?: string;
  onUploadFiles: (files: File[]) => void;
  prompt?: string;
  multiple?: boolean;
};

const PickFileModal = ({ onUploadFiles, prompt, itemType, assetType, multiple = false }: Props) => {
  const { dismissModal } = useModals();
  const { data: fileTypes, error: fileTypesError } = useContentTypes({
    itemType,
    assetType,
  });

  const [error, setError] = useState(""),
    fileExtensions = fileTypes?.reduce((acc, type) => {
      const extensions = [type.fileExtension, ...type.aliases].filter(t => t);
      return acc.concat(extensions);
    }, []);

  const onPickFiles = useCallback(
    files => {
      function isValidFile(file) {
        const ext = getFileExtension(file.name);
        return fileExtensions.some(t => t.toLowerCase() === ext);
      }
      // Stop the process if there are no valid files.
      // Currently this only checks for valid types
      if (fileTypes?.length && !files.some(isValidFile)) {
        setError(typeErrorMessage(fileTypes));
      } else {
        dismissModal();
        return onUploadFiles(files);
      }
    },
    [dismissModal, fileExtensions, fileTypes, onUploadFiles]
  );

  return (
    <ModalBody pt="xxl" pb="xl">
      <FilePicker onPickFiles={onPickFiles} fileExtensions={fileExtensions} multiple={multiple}>
        {({ openNativeFilePicker, dropZoneProps }) => (
          <DropZone {...dropZoneProps}>
            <Icon iconId="empty-state.assets" fill="gray" size="120" flex="1 1 auto" />
            <Text>
              Drag & drop your new {pluralize("file", multiple ? 0 : 1)} here or click below
            </Text>
            <Button
              mt="m"
              flex="0 0 auto"
              text={prompt || pluralize("Choose file", multiple ? 0 : 1)}
              onClick={openNativeFilePicker}
            />
          </DropZone>
        )}
      </FilePicker>
      <FileError message={error || fileTypesError?.message} />
    </ModalBody>
  );
};

export default PickFileModal;
