import { KitCollectionItem } from "@thenounproject/lingo-core";
import { createSelector } from "reselect";

import { getKitCollectionItems } from "../getters";

export const selectSpaceKitCollectionItems = createSelector(
  [getKitCollectionItems],
  (kitCollectionItems: Record<string, KitCollectionItem>) => {
    return (
      Object.keys(kitCollectionItems)
        ?.map(id => kitCollectionItems[id])
        .filter(k => k.status === "active") || null
    );
  }
);
