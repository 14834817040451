import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  kitCollectionId: string;
};

const [useAddKitToCollection, addKitToCollection] = createAsyncAction(
  "kitCollections/addKit",
  async ({ kitId, kitCollectionId }: Args) => {
    return await API.call<string>({
      endpoint: `kit_collections/${kitCollectionId}/kits`,
      method: "POST",
      entity: API.Entity.kitCollection,
      data: {
        kit_uuid: kitId,
      },
    });
  }
);

export default useAddKitToCollection;
export { addKitToCollection };
