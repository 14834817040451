import React, { useCallback } from "react";
import { Input, Flex, Button } from "@thenounproject/lingo-core";

import { CustomFieldInputProps } from ".";
import { CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP } from "@features/library/components/AssetLibraryFilterBar";

const TextInput: React.FC<CustomFieldInputProps> = ({ field, toggleFilter }) => {
  const [value, setValue] = React.useState<string>("");

  const handleSave = useCallback(
    (e?: React.FormEvent) => {
      e?.preventDefault();
      if (!value.trim()) return;
      toggleFilter({
        type: CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP[field.type],
        field_id: field.id,
        display: value.trim(),
        value: value.trim(),
        id: `field-${field.id}_${value.trim()}`,
      });
      setValue("");
    },
    [field, toggleFilter, value]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  return (
    <Flex flexDirection="column" mt="4px">
      <Input
        data-testid="search-custom-text-field"
        size="small"
        placeholder={"Enter a keyword"}
        value={value}
        onChange={handleChange}
        onSubmit={handleSave}
        type={"text"}
      />
      <Button
        onClick={handleSave}
        buttonStyle="secondary"
        text="Apply"
        size="small"
        fullWidth
        mt="8px"
        disabled={!value.trim()}
      />
    </Flex>
  );
};

export default TextInput;
