import useKitUpsell, { AccessStatus } from "@hooks/useKitUpsell";
import useUpdateKitPrivacy from "@redux/actions/kits/useUpdateKitPrivacy";
import { Flex, Kit, KitPermission, PrivacyLevel } from "@thenounproject/lingo-core";
import React, { useCallback, useEffect } from "react";
import UpsellTooltip from "../../../components/spaces/settings/UpsellTooltip";
import PasswordCheckbox from "./PasswordCheckbox";

type Props = {
  kit: Kit;
};

const KitPasswordCheckbox: React.FC<Props> = ({ kit }) => {
  const kitId = kit.kitId,
    spaceId = kit.spaceId,
    hasPassword = kit.privacy === PrivacyLevel.password;

  const { openUpgradeModal, accessStatus } = useKitUpsell(KitPermission.managePasswordAccess, kit);

  const [editKitPrivacy, { error, result, reset }] = useUpdateKitPrivacy(),
    makePublic = useCallback(
      () => editKitPrivacy({ kitId, spaceId, privacy: PrivacyLevel.public }),
      [editKitPrivacy, kitId, spaceId]
    ),
    setPassword = useCallback(
      (password: string) =>
        editKitPrivacy({ kitId, spaceId, privacy: PrivacyLevel.password, password }),
      [editKitPrivacy, kitId, spaceId]
    );

  useEffect(() => {
    // The result is used to reset the password form but we need to revert that for future updates
    if (result) reset();
  }, [reset, result]);

  if (accessStatus === AccessStatus.insufficientRole) return null;

  return (
    <Flex
      data-tooltip-source="kit-password-checkbox"
      // Dynamic width allows the input not to get squished while also not causing
      // the tooltip to get offset when the feature is not avialable
      width={accessStatus === AccessStatus.canAccess ? "100%" : null}>
      {accessStatus === AccessStatus.insufficientPlan && (
        <UpsellTooltip
          onClick={openUpgradeModal}
          source="kit-password-checkbox"
          featureName="Password protected kits"
        />
      )}
      <PasswordCheckbox
        hasPassword={hasPassword}
        setPassword={setPassword}
        removePassword={makePublic}
        success={Boolean(result)}
        error={error?.message}
        disabled={accessStatus !== AccessStatus.canAccess}
      />
    </Flex>
  );
};

export default KitPasswordCheckbox;
