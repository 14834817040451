import React from "react";
import styled from "styled-components";
import {
  Text,
  Button,
  Box,
  useClipboard,
  Notice,
  ActivityIndicator,
} from "@thenounproject/lingo-core";

import useFetchRecoveryCodes from "@redux/actions/auth/useFetchRecoveryCodes";

import ModalHeader from "../../ModalHeader";
import ModalBody from "../../ModalBody";

export const CodesContainer = styled(Box).attrs({
  px: "m",
  py: "s",
  background: "grayLightest",
  border: "default",
  borderRadius: "4",
  mb: "m",
  width: "fit-content",
})`
  display: grid;
  gap: 8px 40px;
  grid-template-columns: repeat(2, max-content);
`;

const TFACodesModal = () => {
  const { status, codes, error } = useFetchRecoveryCodes();

  const { onCopy, hasCopied } = useClipboard(codes?.map(code => code.code).join(" "));

  function renderCodes() {
    switch (status) {
      case "loading":
        return <ActivityIndicator size="small" />;
      case "error":
        return <Notice noticeStyle="error" message={error.message} />;
      default:
        return (
          <>
            <CodesContainer>
              {codes?.map(codeObject => {
                return (
                  <Text
                    key={codeObject.code}
                    font="ui.regularSemiBold"
                    textDecoration={codeObject.dateUsed ? "line-through" : "none"}>
                    {codeObject.code}
                  </Text>
                );
              })}
            </CodesContainer>
            <Button text={hasCopied ? "Copied" : "Copy codes"} onClick={onCopy} />
          </>
        );
    }
  }

  return (
    <>
      <ModalHeader title="Two-factor recovery codes" />
      <ModalBody>
        <Text>
          In case you lose access to your mobile device, use these recovery codes when prompted for
          an “Authentication code” to log in. Each code can only be used once.
        </Text>
        <Box mt="m">{renderCodes()}</Box>
      </ModalBody>
    </>
  );
};

export default TFACodesModal;
