// Kit collection view

import React, { useMemo } from "react";
import {
  Box,
  Button,
  Text,
  buildPath,
  ActivityIndicator,
  Kit,
  KitCollectionItem,
  PagingIndicator,
  Redirect,
  EmptyState,
} from "@thenounproject/lingo-core";
import Helmet from "react-helmet";
import KitList from "./KitList";
import SpaceKitsSectionTitle from "./SpaceKitsSectionTitle";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useKitCollection from "@redux/actions/kitCollections/useKitCollection";
import useNavPoint from "@hooks/useNavPoint";

const KitCollectionView: React.FC = () => {
  const { showModal } = useShowModal();

  // The nav point should at least have the name of the collection.
  // Using that allows us to show the collection name for a smoother loading UX
  const { space, kitCollection: { name } = {}, kitCollectionId } = useNavPoint();

  const {
    data: collection,
    isLoading,
    error,
    fetchNextPage,
  } = useKitCollection({ collectionId: kitCollectionId });

  const kits = useMemo(() => {
    const _items = (collection?.items as KitCollectionItem[]) ?? [];
    _items.sort((a, b) => a.displayOrder - b.displayOrder);
    return _items.map(item => item.kit as Kit);
  }, [collection?.items]);

  const hasMore = collection?.counts?.kits > kits.length,
    canEdit = space?.access.permissions.includes("edit");

  const openCollectionKitsModal = React.useCallback(
    () =>
      showModal(ModalTypes.MANAGE_COLLECTION_KITS, {
        kitCollectionId,
      }),
    [showModal, kitCollectionId]
  );

  function renderContent() {
    if (isLoading && !kits?.length) {
      return <ActivityIndicator mt="xxl" center />;
    } else if (error && !kits?.length) {
      return (
        <EmptyState
          title="Unable to load collection"
          subtitle={error.message}
          iconProps={{ iconId: "error", fill: "error" }}
          button={{ text: "Retry", onClick: fetchNextPage }}
        />
      );
    }

    return !kits?.length ? (
      <Box mt="xxl" width="100%">
        <Box m="auto" width="100%" maxWidth="380px" textAlign="center">
          <Text font="ui.title" color="grayDarker">
            Add Kits
          </Text>
          <Text color="grayDarker" mb="l">
            Add kits to your collection by clicking the button below.
          </Text>
          <Button text="Add kits" onClick={openCollectionKitsModal} />
        </Box>
      </Box>
    ) : (
      <>
        <KitList
          kits={kits}
          kitCardSize="normal"
          columns={3}
          gap="40px 20px"
          kitCollectionId={canEdit ? collection?.uuid : undefined}
        />
        <PagingIndicator
          hasMore={hasMore}
          data={kits}
          error={error}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
        />
      </>
    );
  }

  if (["trashed", "deleted"].includes(collection?.status)) {
    return <Redirect to={buildPath("/", { space })} />;
  }

  return (
    <>
      <Helmet>
        <title>{collection?.name || "Unnamed"} collection</title>
      </Helmet>
      <Box minHeight="50vh">
        <SpaceKitsSectionTitle name={name} kitCollectionId={kitCollectionId} />
        {renderContent()}
      </Box>
    </>
  );
};

export default KitCollectionView;
