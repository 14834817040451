import React, { useCallback } from "react";
import { Flex, Select, Button, formatDate, SearchFilterTypes } from "@thenounproject/lingo-core";

import { CustomFieldInputProps } from ".";
import { CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP } from "@features/library/components/AssetLibraryFilterBar";
import { getDaysAgoRange, getTodayDateRange, getYesterdayDateRange } from "@helpers/dates";
import { formatSearchFilterDate } from "@features/library/utils";
import DateRangePicker from "@features/library/components/DateRangePicker";

const DateInput: React.FC<CustomFieldInputProps> = ({ field, toggleFilter, activeFilters }) => {
  const existingFilter = activeFilters.find(f => f.field_id === field.id);
  const [dateType, setDateType] = React.useState<"exact" | "range">();
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  const isExactDate = dateType === "exact";
  const isRangeDate = dateType === "range";

  const options = [
    { label: "All Time", value: "All Time" },
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "Last 7 Days", value: "Last 7 Days" },
    { label: "Last 30 Days", value: "Last 30 Days" },
    { label: "Custom Date", value: "Custom Date" },
  ];

  const handleChange = useCallback(
    (val: string) => {
      if (val === "All Time" && existingFilter) return toggleFilter(existingFilter);
      if (existingFilter) toggleFilter(existingFilter);
      if (val !== "Custom Date") {
        setDateType(undefined);
        toggleFilter({
          type: CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP[field.type],
          field_id: field.id,
          display: val,
          id: `field-${field.id}_${val}`,
          operator_values: {
            ...(val === "Today" && getTodayDateRange()),
            ...(val === "Yesterday" && getYesterdayDateRange()),
            ...(val === "Last 7 Days" && getDaysAgoRange(7)),
            ...(val === "Last 30 Days" && getDaysAgoRange(30)),
          },
        });
      } else setDateType("range");
    },
    [existingFilter, field.id, field.type, toggleFilter]
  );

  const submitCustomDate = useCallback(() => {
    if (!startDate && !endDate) return;
    if (existingFilter) toggleFilter(existingFilter);

    toggleFilter({
      type: SearchFilterTypes["field-date"],
      field_id: field.id,
      display: isExactDate
        ? formatDate(startDate.toISOString(), "MM/dd/yyyy")
        : `${formatDate(startDate.toISOString(), "MM/dd/yyyy")} - ${formatDate(
            endDate.toISOString(),
            "MM/dd/yyyy"
          )}`,
      id: `field-${field.id}_range_${
        isExactDate
          ? startDate.toISOString()
          : `${startDate.toISOString()}_${endDate.toISOString()}`
      }`,
      operator_values: {
        ...(isExactDate ? { eq: formatSearchFilterDate(startDate.toISOString()) } : {}),
        ...(isRangeDate
          ? {
              gte: formatSearchFilterDate(startDate.toISOString()),
              lte: formatSearchFilterDate(endDate.toISOString()),
            }
          : {}),
      },
    });
  }, [endDate, existingFilter, field.id, isExactDate, isRangeDate, startDate, toggleFilter]);

  const getSelectValue = () => {
    if (isRangeDate || existingFilter?.id.includes("range")) return "Custom Date";
    if (existingFilter) return existingFilter.display;
    return "All Time";
  };

  return (
    <Flex flexDirection="column" mt="4px" gap="4px">
      <Select
        value={getSelectValue()}
        options={options}
        size="small"
        buttonStyle="dropdown"
        onChange={handleChange}
        width="100%"
      />
      {isRangeDate && (
        <>
          <DateRangePicker
            startDate={startDate ? new Date(startDate) : undefined}
            endDate={endDate ? new Date(endDate) : undefined}
            onChange={dates => {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }}
            popperProps={{ strategy: "fixed" }}
          />
          <Button
            onClick={submitCustomDate}
            buttonStyle="secondary"
            text="Apply"
            size="small"
            fullWidth
            mt="4px"
            disabled={!startDate && !endDate}
          />
        </>
      )}
    </Flex>
  );
};

export default DateInput;
