import React from "react";
import { PopupMenu } from "@thenounproject/lingo-core";

type Props = {
  menuItems: React.ComponentProps<typeof PopupMenu.MenuItemRenderer>["menuItems"];
};

const MenuModal: React.FC<Props> = ({ menuItems }) => {
  return (
    <>
      <PopupMenu.MenuItemRenderer menuItems={menuItems} />
    </>
  );
};

export default MenuModal;
