import { Portal, Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  kitId: string;
};

export type KitPortalResult = Portal & {
  dateAdded: string;
  privacy: string;
  views: {
    totalViews: number;
    memberViews: number;
    guestViews: number;
    lastViewed: string;
    uniquePeople: number;
    uniqueMembers: number;
    uniqueGuests: number;
  };
};

type KitPortalTableData = {
  portals: KitPortalResult[];
  total: number;
};

const [useKitPortalPerformance, fetchKitPortalPerformance] = createQueryAction<
  Args,
  KitPortalTableData
>(
  {
    entity: "analytics",
    action: "queryKitPortalPerformance",
    denormalize: false,
    pagingKey: "items",
  },
  async ({ args, paging }) => {
    const reports = {
      portals: {
        id: "portals",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        kit_uuid: args.kitId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ portals: KitPortalTableData }>(args.spaceId, reports)).portals;
  }
);

export default useKitPortalPerformance;
export { fetchKitPortalPerformance };
