/* eslint-disable no-alert */
import React, { Fragment, useState, useCallback, ChangeEvent } from "react";
import { TinyColor, RGBA, HSVA } from "@ctrl/tinycolor";
import styled from "styled-components";
import {
  Input,
  Text,
  Flex,
  Icon,
  PopupMenu,
  Notice,
  utils,
  Asset,
  AnyObject,
  Color,
  RequireAtLeastOne,
} from "@thenounproject/lingo-core";

// Color name generation config
import nearestColor from "nearest-color";
import colorNameList from "color-name-list/dist/colornames.bestof.json";

import useSaveAssetMetdata from "@redux/actions/assets/useSaveAssetMetadata";
import useCreateColorAsset from "@redux/actions/items/useCreateColorAsset";
import { type InsertPosition } from "@actions/uploads";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";

import transparentBackground from "../../../images/web-access/pattern@2x.png";
import useModals, { ModalTypes } from "@actions/useModals";

const allColors = colorNameList.reduce((o, { name, hex }) => Object.assign(o, { [name]: hex }), {});
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const findNearestColorName = nearestColor.from(allColors);

type WrapperProps = {
  error?: boolean;
};
const GroupedInputWrapper = styled(Flex).attrs<WrapperProps>(props => {
  return {
    width: "100%",
    height: "36px",
    border: props.error ? "1px solid error" : "1px solid grayLight",
    borderRadius: "default",
    flexShrink: 1,
    overflow: "hidden",
  };
})<WrapperProps>``;

const GroupedInputField = styled(Flex).attrs<AnyObject>(props => {
  return {
    p: props.p || "s",
    width: props.width || "20%",
    background: props.background || "white",
    height: "100%",
    flexShrink: props.flexShrink || 0,
    borderRight: props.borderRight || "1px solid grayLight",
  };
})``;

const GroupedInput = styled.input.attrs(() => {
  return { onFocus: e => e.target.select() };
})`
  border: none;
  padding: 0 6px;
  ${props => utils.getFont("ui.regular", null, props)}
`;

const DigitalInputSample = styled(Flex).attrs(() => {
  return {
    width: "36px",
    height: "36px",
    border: "1px solid grayLight",
    borderRadius: "default",
    flexShrink: 0,
    ml: "xs",
    position: "relative",
    overflow: "hidden",
  };
})`
  background: url(${transparentBackground});
  .swatch {
    background: ${props => props.background};
    transition: 0.4s background ease;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;

const ClearButton = styled.button`
  padding: 0;
  height: 20px;
  width: 20px;
`;

const formatCmykObjectFromString = (colorObject: Color) => {
  if (!colorObject.cmyk) {
    return null;
  }
  const valueArray = colorObject.cmyk.replace("cmyk(", "").replace(")", "").split(",");

  return {
    c: Number(valueArray[0]),
    m: Number(valueArray[1]),
    y: Number(valueArray[2]),
    k: Number(valueArray[3]),
  };
};

enum DigitalColorType {
  HEX = "HEX",
  RGB = "RGB",
  HSB = "HSB",
}
type Values = {
  name: string;
  placeholder: string;
  percentage: string;
  [DigitalColorType.HEX]: string;
  [DigitalColorType.RGB]: RGBA;
  [DigitalColorType.HSB]: HSVA;
};

// MARK : Component
// -------------------------------------------------------------------------------
type Props = RequireAtLeastOne<{
  asset?: Asset;
  insertPosition?: InsertPosition;
}>;

const CreateEditColorModal: React.FC<Props> = ({ insertPosition, asset }) => {
  const colorToEdit = asset?.colors[0] ?? null,
    defaultValues: Values = colorToEdit
      ? {
          // noop vars for editing
          name: "",
          placeholder: "",

          percentage: `${Number(colorToEdit.alpha)}%`,
          [DigitalColorType.HEX]: `#${new TinyColor(colorToEdit.hex8).toHex().toUpperCase()}`,
          [DigitalColorType.RGB]: new TinyColor(colorToEdit.hex8).toRgb(),
          [DigitalColorType.HSB]: new TinyColor(colorToEdit.hex8).toHsv(),
        }
      : {
          name: "",
          placeholder: "Silver",
          percentage: "100%",
          [DigitalColorType.HEX]: "#AAAAAA",
          [DigitalColorType.RGB]: new TinyColor("#AAAAAA").toRgb(),
          [DigitalColorType.HSB]: new TinyColor("#AAAAAA").toHsv(),
        },
    defaultCmykValues = colorToEdit ? formatCmykObjectFromString(colorToEdit) : null,
    defaultPantoneValue = colorToEdit ? (colorToEdit.pms ? colorToEdit.pms : "") : "";

  const [cmykValues, setCmykValues] = useState(defaultCmykValues);
  const [pantone, setPantone] = useState(defaultPantoneValue);
  const [digitalColorValues, setDigitalColorValues] = useState(defaultValues);
  const [digitalColorError, setDigitalColorError] = useState(false);
  const [digitalColorType, setDigitalColorType] = useState(DigitalColorType.HEX);
  const [menuOpen, setMenuOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveAssetMetadata] = useSaveAssetMetdata();
  const [createColorAsset] = useCreateColorAsset();
  const { dismissModal } = useModals();

  const onSaveColor = useCallback(async () => {
    setLoading(true);
    setError(null);
    const hsb = digitalColorValues[DigitalColorType.HSB] as HSVA;
    let colorData = {
      name: null,
      hue: Number(hsb.h),
      saturation: Number(hsb.s) * 100,
      brightness: Number(hsb.v) * 100,
      alpha: parseInt(digitalColorValues.percentage),
      cmyk: cmykValues
        ? `cmyk(${cmykValues.c},${cmykValues.m},${cmykValues.y},${cmykValues.k})`
        : undefined,
      pms: pantone || undefined,
      coverage: 100,
    };

    // If we're not editing, include the name & fire createColorAsset
    if (!colorToEdit) {
      colorData = {
        ...colorData,
        name: digitalColorValues.name || digitalColorValues.placeholder,
      };

      await createColorAsset({ color: colorData, insertPosition });
      dismissModal(ModalTypes.CREATE_EDIT_COLOR);
      // if (reqError) {
      //   setError(reqError.message);
      //   setLoading(false);
      // } else {

      // }
    } else {
      // Else, fire saveAssetMetaData with the updated values
      void saveAssetMetadata({
        assetId: asset.id,
        data: { colors: [colorData] },
      });
      dismissModal();
    }
  }, [
    digitalColorValues,
    cmykValues,
    pantone,
    colorToEdit,
    createColorAsset,
    insertPosition,
    dismissModal,
    saveAssetMetadata,
    asset?.id,
  ]);

  const previewColor = new TinyColor(digitalColorValues.HEX)
    .setAlpha(parseInt(digitalColorValues.percentage) / 100)
    .toHex8String();

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  const onHexChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let val = e.currentTarget.value
        .replace(/[^a-fA-F0-9]/g, "")
        .trim()
        .toUpperCase();
      val = `#${val}`;

      const valid = [4, 7].includes(val.length);
      setDigitalColorError(!valid);

      const newValues = {
          ...digitalColorValues,
          HEX: val,
        },
        newName = valid ? findNearestColorName(val) : null;

      if (valid) {
        newValues.HSB = new TinyColor(val).toHsv();
        newValues.RGB = new TinyColor(val).toRgb();
      }

      if (newName) {
        newValues.placeholder = newName.name;
      }

      setDigitalColorValues(newValues);
    },
    [digitalColorValues]
  );

  /**
   * @param {String} type digital color type
   * @param {String} key key value of digital color type
   * @param {Number} max max number value of input
   * @param {*} e synthetic event
   *
   * Wrapping this in useCallback doesn't really do much right now since we pass the
   * arguments inline. We should probably add additional funcitons for each type.
   */
  const onOtherDigitalValueChange = useCallback(
    (type: DigitalColorType, key: string, max: number, e: ChangeEvent<HTMLInputElement>) => {
      let val = Number(e.target.value.replace(/\D/g, "")) || 0;

      if (val > max) val = max;
      if (val < 0) val = 0;

      /**
       * Tinycolor & the library in Mac App process HSV/HSB values
       * differently, so we have to divide by 100 here, and multiply
       * by 100 when displaying in the value of the inputs for S, V
       */
      if (type === DigitalColorType.HSB && ["s", "v"].includes(key)) val /= 100;

      const newValues = { ...digitalColorValues };
      newValues[type][key] = val;

      const newColor = new TinyColor(newValues[type]);

      if (type === DigitalColorType.HSB) {
        newValues[DigitalColorType.RGB] = newColor.toRgb();
      } else if (type === DigitalColorType.RGB) {
        newValues[DigitalColorType.HSB] = newColor.toHsv();
      }

      newValues[DigitalColorType.HEX] = newColor.toHexString().toUpperCase();
      newValues.placeholder = findNearestColorName(newValues[DigitalColorType.HEX]).name;

      setDigitalColorValues(newValues);
    },
    [digitalColorValues]
  );

  /**
   * @param {String} key key value of CMYK color
   * @param {*} e synthetic event
   */
  const onCmykChange = useCallback(
    (key: string, e: ChangeEvent<HTMLInputElement>) => {
      let val = Number(e.target.value.replace(/\D/g, "")) || 0;

      if (val > 100) val = 100;
      if (val < 0) val = 0;

      const newValues = cmykValues ? { ...cmykValues } : { c: 0, m: 0, y: 0, k: 0 };
      newValues[key] = val;

      setCmykValues(newValues);
    },
    [cmykValues]
  );

  const onPercentageChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let val = Number(e.target.value.replace(/[^0-9]+/g, ""));
      if (!val && val !== 0) val = 100;
      if (val > 100) val = 100;
      setDigitalColorValues({
        ...digitalColorValues,
        percentage: `${val}%`,
      });
    },
    [digitalColorValues]
  );

  function getDigitalColorInputs() {
    if (digitalColorType === DigitalColorType.HEX) {
      return (
        <GroupedInputField p="none" width="100%" flexShrink={1}>
          <GroupedInput
            data-testid="color-modal-hex"
            onChange={onHexChange}
            value={digitalColorValues.HEX}
            type="text"
            maxLength={7}
          />
        </GroupedInputField>
      );
    }
    if (digitalColorType === DigitalColorType.RGB) {
      return (
        <Fragment>
          <GroupedInputField p="none">
            <GroupedInput
              onChange={e => onOtherDigitalValueChange(DigitalColorType.RGB, "r", 255, e)}
              value={digitalColorValues?.RGB?.r || ""}
              type="text"
            />
          </GroupedInputField>
          <GroupedInputField p="none">
            <GroupedInput
              onChange={e => onOtherDigitalValueChange(DigitalColorType.RGB, "g", 255, e)}
              value={digitalColorValues?.RGB?.g || ""}
              type="text"
            />
          </GroupedInputField>
          <GroupedInputField p="none">
            <GroupedInput
              onChange={e => onOtherDigitalValueChange(DigitalColorType.RGB, "b", 255, e)}
              value={digitalColorValues?.RGB?.b || ""}
              type="text"
            />
          </GroupedInputField>
        </Fragment>
      );
    }
    if (digitalColorType === DigitalColorType.HSB) {
      return (
        <Fragment>
          <GroupedInputField p="none">
            <GroupedInput
              onChange={e => onOtherDigitalValueChange(DigitalColorType.HSB, "h", 360, e)}
              value={digitalColorValues.HSB ? Math.round(Number(digitalColorValues.HSB.h)) : ""}
              type="text"
            />
          </GroupedInputField>
          <GroupedInputField p="none">
            <GroupedInput
              onChange={e => onOtherDigitalValueChange(DigitalColorType.HSB, "s", 100, e)}
              value={
                digitalColorValues.HSB ? Math.round(Number(digitalColorValues.HSB.s) * 100) : ""
              }
              type="text"
            />
          </GroupedInputField>
          <GroupedInputField p="none">
            <GroupedInput
              onChange={e => onOtherDigitalValueChange(DigitalColorType.HSB, "v", 100, e)}
              value={
                digitalColorValues.HSB ? Math.round(Number(digitalColorValues.HSB.v) * 100) : ""
              }
              type="text"
            />
          </GroupedInputField>
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <ModalHeader title={colorToEdit ? "Edit color" : "Add a color"} />
      <ModalBody>
        {error && <Notice mb="m" message={error} noticeStyle="error" />}
        {!colorToEdit && (
          <Fragment>
            <Text font="ui.regularBold" mb="xs" htmlFor="create-color-name" as="label">
              Name
            </Text>
            <Input
              data-testid="create-color-name-input"
              onChange={e =>
                setDigitalColorValues({
                  ...digitalColorValues,
                  name: e.target.value,
                })
              }
              placeholder={digitalColorValues.placeholder}
              value={digitalColorValues.name}
              id="create-color-name"
              type="text"
              autoFocus
            />
          </Fragment>
        )}
        <Text font="ui.regularBold" mt={colorToEdit ? "none" : "l"} mb="xs" as="label">
          Digital
        </Text>
        <Flex>
          <GroupedInputWrapper error={digitalColorError} data-testid="digital-color-wrapper">
            <GroupedInputField
              onClick={toggleMenu}
              data-popup-source="digital-type-menu"
              alignItems="center"
              justifyContent="space-between"
              background="grayLightest">
              <Text color="grayDarker">{digitalColorType}</Text>
              <Icon iconId="triangle-double" fill="grayDarker" />
            </GroupedInputField>
            {menuOpen ? (
              <PopupMenu source="digital-type-menu" close={toggleMenu}>
                {Object.keys(DigitalColorType).map(type => {
                  return (
                    <PopupMenu.Item
                      key={`type-opt-${DigitalColorType[type]}`}
                      title={DigitalColorType[type]}
                      onClick={() => setDigitalColorType(DigitalColorType[type])}
                    />
                  );
                })}
              </PopupMenu>
            ) : null}
            {getDigitalColorInputs()}
            <GroupedInputField p="none" borderRight="none">
              <GroupedInput
                data-testid="color-modal-percentage"
                onChange={onPercentageChange}
                value={digitalColorValues.percentage}
                type="text"
                maxLength={4}
              />
            </GroupedInputField>
          </GroupedInputWrapper>
          <DigitalInputSample background={previewColor}>
            <div className="swatch" />
          </DigitalInputSample>
        </Flex>
        <Text font="ui.regularBold" mt="l" mb="xs" as="label">
          Print (optional)
        </Text>
        <Flex>
          <GroupedInputWrapper>
            <GroupedInputField
              alignItems="center"
              justifyContent="space-between"
              background="grayLightest"
              flexShrink={0}>
              <Text color="grayDarker">CMYK</Text>
              {cmykValues && (
                <ClearButton onClick={() => setCmykValues(null)}>
                  <Icon size="20" iconId="close" fill="grayDarker" />
                </ClearButton>
              )}
            </GroupedInputField>
            <GroupedInputField p="none" flexShrink={1}>
              <GroupedInput
                onChange={e => onCmykChange("c", e)}
                value={cmykValues ? cmykValues.c : ""}
                placeholder="C"
                type="text"
              />
            </GroupedInputField>
            <GroupedInputField p="none">
              <GroupedInput
                onChange={e => onCmykChange("m", e)}
                value={cmykValues ? cmykValues.m : ""}
                placeholder="M"
                type="text"
              />
            </GroupedInputField>
            <GroupedInputField p="none">
              <GroupedInput
                onChange={e => onCmykChange("y", e)}
                value={cmykValues ? cmykValues.y : ""}
                placeholder="Y"
                type="text"
              />
            </GroupedInputField>
            <GroupedInputField p="none" borderRight="none">
              <GroupedInput
                onChange={e => onCmykChange("k", e)}
                value={cmykValues ? cmykValues.k : ""}
                placeholder="K"
                type="text"
              />
            </GroupedInputField>
          </GroupedInputWrapper>
        </Flex>
        <Flex mt="s">
          <GroupedInputWrapper>
            <GroupedInputField
              alignItems="center"
              justifyContent="space-between"
              background="grayLightest">
              <Text color="grayDarker">Pantone</Text>
              {pantone && (
                <ClearButton onClick={() => setPantone("")}>
                  <Icon size="20" iconId="close" fill="grayDarker" />
                </ClearButton>
              )}
            </GroupedInputField>
            <GroupedInputField p="none" width="100%" flexShrink={1} borderRight="none">
              <GroupedInput
                onChange={e => setPantone(e.target.value)}
                value={pantone}
                placeholder="Pantone"
                type="text"
              />
            </GroupedInputField>
          </GroupedInputWrapper>
        </Flex>
      </ModalBody>
      <ModalFooter
        primary={{
          text: colorToEdit ? "Save color" : "Add color",
          onClick: onSaveColor,
          disabled: digitalColorError || loading,
        }}
      />
    </Fragment>
  );
};

export default CreateEditColorModal;
