import React, { useCallback, useState } from "react";
import ModalHeader from "../../ModalHeader";
import ModalBody from "../../ModalBody";
import { Text, Input, Flex, Notice, Box } from "@thenounproject/lingo-core";
import ModalFooter from "../../ModalFooter";
import useCreateSupportRequest, {
  RequestType,
} from "@redux/actions/billing/useCreateSupportRequest";
import useCurrentUser from "@queries/useCurrentUser";
import useNavPoint from "@hooks/useNavPoint";
import useModals from "@redux/actions/useModals";
import useNotifications from "@redux/actions/useNotifications";

const IncreaseUsageModal: React.FC = ({ title }: { title: string }) => {
  const [error, setError] = useState<string>("");
  const [consumerEstimate, setConsumerEstimate] = useState("");
  const [requestEstimate, setRequestEstimate] = useState("");
  const [bandwidthEstimate, setBandwidthEstimate] = useState("");
  const [storageEstimate, setStorageEstimate] = useState("");
  const { dismissModal } = useModals();
  const { showNotification } = useNotifications();

  const {
    user: { name, email, id: userId },
  } = useCurrentUser();
  const {
    space: { id: spaceId, name: spaceName },
  } = useNavPoint();

  const [submitSupportRequest, { isProcessing }] = useCreateSupportRequest();

  const handleSubmit = useCallback(async () => {
    const messageMap: Record<string, string> = {
      consumer: `Consumer limit change request from ${spaceName} (${spaceId}). Submitted by ${name} (${userId}). \n\n Consumers: ${consumerEstimate}`,
      "direct link": `Direct link usage change request from ${spaceName} (${spaceId}). Submitted by ${name} (${userId}). \n\n Estimate: ${requestEstimate} requests, ${bandwidthEstimate} GB bandwidth.`,
      storage: `Storage change request from ${spaceName} (${spaceId}). Submitted by ${name} (${userId}). \n\n Estimate: ${storageEstimate} GB storage.`,
    };
    const args = {
      email,
      name,
      requestType: "quota" as RequestType,
      message: messageMap[title],
    };

    const isEmpty = [consumerEstimate, requestEstimate, bandwidthEstimate, storageEstimate].every(
      item => item === ""
    );

    if (isEmpty) {
      setError("Please provide an estimate for the usage you are requesting.");
      return;
    }

    const { error } = await submitSupportRequest(args);
    if (error) {
      setError(error.message);
    } else {
      setError("");
      showNotification({
        message: "A member of our sales team will contact you soon to update your plan.",
      });
      dismissModal();
    }
  }, [
    consumerEstimate,
    requestEstimate,
    bandwidthEstimate,
    storageEstimate,
    email,
    spaceId,
    spaceName,
    userId,
    name,
    title,
    dismissModal,
    submitSupportRequest,
    showNotification,
  ]);

  const getBodyText = () => {
    switch (title) {
      case "consumer":
        return `To request an increase in your consumer usage cap, please provide us a rough estimate
          below. Our sales team will reach out via email to confirm the updated rate and address any
          questions. No charges will be applied until then.`;
      case "direct link":
        return `To request an increase in your direct link usage cap, please provide us a rough estimate below. Our sales team will reach out via email to confirm the updated rate and address any questions. No charges will be applied until then.`;
      case "storage":
        return `To request an increase in your storage, please provide us a rough estimate below. Our sales team will reach out via email to confirm the updated rate and address any questions. No charges will be applied until then.`;
    }
  };

  const getInputs = () => {
    switch (title) {
      case "storage":
        return (
          <Input
            label="Estimated storage"
            type="number"
            placeholder="200"
            value={storageEstimate}
            onChange={e => setStorageEstimate(e.target.value)}
          />
        );
      case "consumer":
        return (
          <>
            <Input
              label="Estimated number of total monthly consumers"
              type="number"
              placeholder="200"
              value={consumerEstimate}
              onChange={e => setConsumerEstimate(e.target.value)}
            />
          </>
        );
      case "direct link":
        return (
          <Flex flexDirection="column" gap="24px">
            <Input
              label="Estimated number of requests made from Lingo"
              type="number"
              placeholder="50000"
              value={requestEstimate}
              onChange={e => setRequestEstimate(e.target.value)}
            />
            <Input
              label="Estimated bandwidth (in GB)"
              type="number"
              placeholder="50"
              value={bandwidthEstimate}
              onChange={e => setBandwidthEstimate(e.target.value)}
            />
          </Flex>
        );
    }
  };
  return (
    <>
      <ModalHeader title={`Increase ${title} usage`} />
      <ModalBody>
        <Text mb="l">{getBodyText()}</Text>
        {getInputs()}
        {error && (
          <Box mt="m">
            <Notice message={error} noticeStyle="error" />
          </Box>
        )}
      </ModalBody>
      <ModalFooter
        primary={{ text: "Submit request", onClick: handleSubmit, disabled: isProcessing }}
      />
    </>
  );
};

export default IncreaseUsageModal;
