import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  domain: string;
};

const [useDeleteSpaceEmailDomain, deleteSpaceEmailDomain] = createAsyncAction(
  "spaces/deleteSpaceEmailDomain",
  async ({ spaceId, domain }: Args) => {
    return await API.call<string>({
      endpoint: `spaces/${spaceId}/domains`,
      method: "DELETE",
      entity: API.Entity.space,
      data: {
        domain,
      },
    });
  }
);

export default useDeleteSpaceEmailDomain;
export { deleteSpaceEmailDomain };
