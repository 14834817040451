import React, { useCallback, useState, Fragment } from "react";
import { Input, buildURL, Space, useNavigation } from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import useShowModal from "@redux/actions/useModals";
import useCreateSection from "@redux/actions/sections/useCreateSection";
import useNavPoint from "@hooks/useNavPoint";

type Props = {
  kitId: string;
  space: Pick<Space, "id">;
  onCreateSection?: (sectionId: string) => void;
};

const CreateSectionModal: React.FC<Props> = ({ kitId, space, onCreateSection }) => {
  const [sectionName, setSectionName] = useState(""),
    navigation = useNavigation(),
    { dismissModal } = useShowModal(),
    { portal } = useNavPoint(),
    [createSection, { error, isProcessing }] = useCreateSection();

  const submit = useCallback(async () => {
    const res = await createSection({ kitId, name: sectionName });

    if (res.isSuccess) {
      const section = res.response.entities.sections[res.response.result];
      const { shortId, id } = section;
      if (onCreateSection) {
        return onCreateSection(id);
      }
      navigation.push(buildURL(`/s/${shortId}`, { space, portal }));
      dismissModal();
    }
  }, [createSection, kitId, sectionName, onCreateSection, navigation, space, portal, dismissModal]);

  return (
    <Fragment>
      <ModalHeader title="Add a Section" />
      <ModalBody>
        <Input
          id="section-name-input"
          value={sectionName}
          label="Name"
          onChange={e => setSectionName(e.target.value)}
          onSubmit={submit}
          placeholder="e.g. Logos, Colors, Icons"
          message={error?.message}
          inputStyle={error ? "error" : undefined}
          autoFocus
        />
      </ModalBody>
      <ModalFooter
        primary={{
          text: "Add Section",
          onClick: submit,
          disabled: isProcessing,
        }}
      />
    </Fragment>
  );
};

export default CreateSectionModal;
