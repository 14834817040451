import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  kitId: string;
};

export type KitUserResult = {
  avatar?: string;
  lastViewed: string;
  role: string;
  name?: string;
  totalViews: number;
};

type KitTableData = {
  rows: KitUserResult[];
  total: number;
};

const [useKitViewsByUser, fetchKitViewsByUser] = createQueryAction<Args, KitTableData>(
  {
    entity: "analytics",
    action: "queryKitViewsByUser",
    denormalize: false,
    pagingKey: "rows",
  },
  async ({ args, paging }) => {
    const reports = {
      user_kit_usage: {
        id: "user_kit_usage",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        kit_uuid: args.kitId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ user_kit_usage: KitTableData }>(args.spaceId, reports))
      .userKitUsage;
  }
);

export default useKitViewsByUser;
export { fetchKitViewsByUser };
