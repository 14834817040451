import { createAsyncAction } from "../actionCreators";
import { API, AnyObject } from "@thenounproject/lingo-core";

type Args = {
  url: string;
  spaceId: number;
};

const [useFigmaFileContents, fetchFigmaFileContents] = createAsyncAction(
  "figma/fetchFigmaFileContents",
  async ({ url, spaceId }: Args) => {
    return await API.call<{ content: AnyObject; selected_node: AnyObject }>({
      endpoint: `integrations/figma/file`,
      method: "POST",
      data: { url, space_id: spaceId },
    });
  }
);

export default useFigmaFileContents;
export { fetchFigmaFileContents };
