import React from "react";
import {
  PopupMenu,
  Flex,
  Input,
  Button,
  formatDate,
  SearchFilterInput,
  SearchFilterTypes,
} from "@thenounproject/lingo-core";
import { capitalize } from "lodash";

import { SearchQueryFilterOption } from "../types";
import { humanizedFilterNames, filterPlaceholderMap } from "../consts";
import useAssetLibrarySuggestions from "../queries/assetLibrarySuggestions";
import { useSelectSpace } from "@selectors/entities/spaces";
import DatePickerComponent from "./DatePicker";
import DateRangePicker from "./DateRangePicker";
import { formatSearchFilterDate } from "../utils";

type Props = {
  option: SearchQueryFilterOption;
  onSelectFilter: (filter: SearchFilterInput) => void;
  activeFilterValues: string[];
  dateOption?: "exact" | "range";
};

const fuzzySearchEnabledTypes = [SearchFilterTypes.name, SearchFilterTypes["field-text"]];

const AssetLibraryFilterMenu: React.FC<Props> = ({
  option,
  onSelectFilter,
  activeFilterValues,
  dateOption,
}) => {
  const [value, setValue] = React.useState("");
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();

  const space = useSelectSpace();
  const { data: suggestionsData } = useAssetLibrarySuggestions({
    spaceId: space.id,
    type: option.type,
    value,
  });
  const filters = suggestionsData?.queries.suggestions;
  const isExactDate = dateOption === "exact";
  const isRangeDate = dateOption === "range";
  const isDateType = isExactDate || isRangeDate;

  const getImage = (filter: SearchFilterInput) => {
    switch (filter.type) {
      case SearchFilterTypes.kit: {
        return {
          src: filter.image || "#",
          iconId: "kit",
          size: 24,
          width: 24,
          height: 24,
        };
      }
      case SearchFilterTypes.uploader: {
        return {
          src: filter.avatar || "#",
          iconId: "navigation.user",
          size: 24,
          width: 24,
          height: 24,
        };
      }
    }
    return null;
  };

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (isDateType) {
      if (!startDate && !endDate) return;
      onSelectFilter({
        type: SearchFilterTypes.date,
        display: isExactDate
          ? formatDate(startDate.toISOString(), "MM/dd/yyyy")
          : `${formatDate(startDate.toISOString(), "MM/dd/yyyy")} - ${formatDate(
              endDate.toISOString(),
              "MM/dd/yyyy"
            )}`,
        operator_values: {
          ...(isExactDate ? { eq: formatSearchFilterDate(startDate.toISOString()) } : {}),
          ...(isRangeDate
            ? {
                gte: formatSearchFilterDate(startDate.toISOString()),
                lte: formatSearchFilterDate(endDate.toISOString()),
              }
            : {}),
        },
      });
    }

    if (!fuzzySearchEnabledTypes.includes(option.type)) return;
    if (!value.trim()) return;

    onSelectFilter({
      type: option.type,
      value: value.trim(),
      display: value.trim(),
      id: `type:${value.trim().replace(/\s/g, "_")}`,
    });
  };

  return (
    <PopupMenu.Section title={humanizedFilterNames[option.type] || capitalize(option.type)}>
      {!isDateType && (
        <Flex
          p="8px 12px"
          background="grayLightest"
          borderBottom="default"
          as="form"
          onSubmit={onSubmit}>
          <Input
            placeholder={filterPlaceholderMap[option.type] || "Search..."}
            onChange={e => setValue(e.target.value)}
            value={value}
            size="small"
            autoFocus
          />
        </Flex>
      )}
      {isExactDate && (
        <Flex
          p="8px 12px"
          width={"100%"}
          background="grayLightest"
          borderBottom="default"
          as="form"
          position="relative"
          zIndex={1}
          onSubmit={onSubmit}>
          <DatePickerComponent
            selected={startDate}
            onChange={date => setStartDate(date)}
            withPortal
          />
        </Flex>
      )}
      {isRangeDate && (
        <Flex
          p="8px 12px"
          width={"100%"}
          background="grayLightest"
          borderBottom="default"
          as="form"
          position="relative"
          zIndex={1}
          onSubmit={onSubmit}>
          <DateRangePicker
            openByDefault
            startDate={startDate ? new Date(startDate) : undefined}
            endDate={endDate ? new Date(endDate) : undefined}
            onChange={dates => {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }}
            withPortal
          />
        </Flex>
      )}
      {filters?.map(f => (
        <PopupMenu.Item
          title={f.display}
          key={f.display}
          image={getImage(f)}
          disabled={activeFilterValues.includes(f.id)}
          onClick={() => onSelectFilter(f)}
          truncate
        />
      ))}
      {isDateType && (
        <Flex p="6px 12px" background="grayLightest">
          <Button
            text="Apply"
            onClick={() => onSubmit()}
            fullWidth
            size="small"
            disabled={!value.trim() && !startDate && !endDate}
          />
        </Flex>
      )}
    </PopupMenu.Section>
  );
};

export default AssetLibraryFilterMenu;
