import React, { useCallback, useState, useEffect, Fragment } from "react";
import {
  Notice,
  Flex,
  Icon,
  Text,
  Box,
  Button,
  ImageView,
  ActivityIndicator,
  Asset,
} from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import FilePicker from "../../FilePicker";
import styled from "styled-components";
import { loadFile } from "@helpers/files";
import SmartImage from "../../SmartImage";

import useFetchMotionAssetThumbnails, {
  MotionAssetPreview,
} from "@actions/assets/useFetchMotionAssetThumbnails";
import useUpdateMotionAssetThumbnails from "@actions/assets/useUpdateMotionAssetThumbnail";
import useShowModal from "@redux/actions/useModals";

type PickerProps = {
  uploaded?: boolean;
  selected?: boolean;
};
const CustomThumbnailPicker = styled(Flex).attrs<PickerProps>(props => {
  return {
    height: "135px",
    width: "135px",
    border: props.uploaded
      ? `2px solid ${props.selected ? "primary" : "white"}`
      : "1px dashed grayLight",
    borderRadius: "default",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
})<PickerProps>`
  cursor: pointer;
`;

const CustomThumbnailPickerCopy = styled(Text).attrs({
  textAlign: "center",
  alignSelf: "stretch",
  color: "grayDarker",
})``;

type WrapperProps = {
  selectable?: boolean;
  selected?: boolean;
};
const ThumbnailImageWrapper = styled(Flex).attrs<WrapperProps>({
  height: "135px",
  width: "135px",
  borderRadius: "default",
  background: "grayLighter",
})<WrapperProps>`
  border: 2px solid ${props => (props.selected ? props.theme.primaryColor : "white")};
  cursor: ${props => (props.selectable ? "pointer" : "default")};
`;

const thumbnailFileTypes = ["image/jpg", "image/jpeg", "image/png"];

type Props = {
  asset: Asset;
};

const EditMotionAssetThumbnailModal: React.FC<Props> = ({ asset }) => {
  const [error, setError] = useState(null),
    [processing, setProcessing] = useState(false),
    [loadingCover, setLoadingCover] = useState(false),
    [loadingThumbnails, setLoadingThumbnails] = useState(true),
    [customThumbnailData, setCustomThumbnailData] = useState<string | ArrayBuffer>(null),
    [customThumbnailFile, setCustomThumbnailFile] = useState<File>(null),
    [selectedThumbnail, setSelectedThumbnail] = useState<File | MotionAssetPreview>(null),
    [fetchedThumbnails, setFetchedThumbnails] = useState([]);

  const { dismissModal } = useShowModal();
  const [fetchMotionAssetThumbnails] = useFetchMotionAssetThumbnails();
  const [updateMotionAssetThumbnail] = useUpdateMotionAssetThumbnails();

  useEffect(() => {
    async function fetchThumbnailsOnMount() {
      setLoadingThumbnails(true);
      const { response, error: responseError } = await fetchMotionAssetThumbnails({
        assetId: asset.id,
      });
      setLoadingThumbnails(false);
      if (responseError) {
        setError(responseError.message || "Failed to fetch generated thumbnails.");
      } else {
        setFetchedThumbnails(response.result.previews);
      }
    }
    void fetchThumbnailsOnMount();
  }, [fetchMotionAssetThumbnails, asset.id]);

  const submit = useCallback(async () => {
    setError(null);
    setProcessing(true);
    const { error: responseError } = await updateMotionAssetThumbnail({
      assetId: asset.id,
      thumbnail: selectedThumbnail,
    });
    setProcessing(false);
    if (responseError) setError(responseError.message || "Oops! That didn’t work.");
    else {
      dismissModal();
    }
  }, [updateMotionAssetThumbnail, asset.id, selectedThumbnail, dismissModal]);

  const onNewCustomThumbnail = useCallback((files: File[]) => {
    if (!files.length) return;
    const file = files[0];
    setLoadingCover(true);
    if (!isFileValid(file)) return fileErrorHandler(file);
    loadFile(file)
      .then(data => {
        setLoadingCover(false);
        setError(null);
        setCustomThumbnailData(data);
        setCustomThumbnailFile(file);
        setSelectedThumbnail(file);
      })
      .catch(err => {
        setLoadingCover(false);
        setError(err.message);
      });
  }, []);

  function isFileValid(file) {
    if (!file || !thumbnailFileTypes.includes(file.type)) {
      return false;
    } else {
      return true;
    }
  }

  function fileErrorHandler(file) {
    setLoadingCover(false);
    if (!file) {
      setError("Unable to read file");
    }
    if (!thumbnailFileTypes.includes(file.type)) {
      setError("The thumbnail must be a PNG or JPG.");
    }
  }

  function renderCustomThumbnailPicker() {
    return (
      <Box>
        <FilePicker onPickFiles={onNewCustomThumbnail} fileExtensions={["PNG", "JPG"]}>
          {({ openNativeFilePicker, dragging, dropZoneProps }) => {
            if (loadingCover) {
              return (
                <CustomThumbnailPicker>
                  <ActivityIndicator center color="primary" />
                </CustomThumbnailPicker>
              );
            } else if (customThumbnailData && !dragging) {
              return (
                <Flex flexDirection="column" justifyContent="center">
                  <CustomThumbnailPicker
                    onClick={() => setSelectedThumbnail(customThumbnailFile)}
                    uploaded={Boolean(customThumbnailFile)}
                    selected={selectedThumbnail === customThumbnailFile}
                    mb="xs"
                    {...dropZoneProps}>
                    <ImageView
                      src={customThumbnailData as string}
                      height="100%"
                      width="100%"
                      borderRadius="default"
                      overflow="hidden"
                    />
                  </CustomThumbnailPicker>
                  <Button
                    buttonStyle="tertiary"
                    text="Change"
                    size="small"
                    onClick={openNativeFilePicker}
                  />
                </Flex>
              );
            } else {
              return (
                <Flex flexDirection="column" justifyContent="center">
                  <CustomThumbnailPicker
                    selected={selectedThumbnail === customThumbnailFile}
                    onClick={openNativeFilePicker}
                    {...dropZoneProps}>
                    <Icon iconId="plus" width="28" height="28" fill="grayDarker" mb="m" />
                    <CustomThumbnailPickerCopy font="ui.smallBold">
                      {dragging ? "Drop image here" : "Upload an image"}
                    </CustomThumbnailPickerCopy>
                  </CustomThumbnailPicker>
                </Flex>
              );
            }
          }}
        </FilePicker>
      </Box>
    );
  }

  function renderLoadingThumbnails() {
    return new Array(3).fill(null).map((_item, index) => (
      <Flex
        key={index}
        id={`loading-thumbnail-${index}`}
        ml="m"
        flexDirection="column"
        alignItems="center">
        <ThumbnailImageWrapper>
          <ActivityIndicator center color="grayDark" />
        </ThumbnailImageWrapper>
      </Flex>
    ));
  }

  return (
    <Fragment>
      <ModalHeader title="Change thumbnail" />
      <ModalBody px="xxl">
        <Text as="h2" font="ui.regularBold" mb="m">
          Upload or select a thumbnail
        </Text>
        <Flex>
          {renderCustomThumbnailPicker()}
          {!loadingThumbnails && fetchedThumbnails
            ? fetchedThumbnails.map((preview, index) => {
                return (
                  <Flex ml="m" flexDirection="column" alignItems="center" key={preview.filename}>
                    <ThumbnailImageWrapper
                      data-testid={`generated-thumbnail-${index}`}
                      selected={
                        preview.filename === (selectedThumbnail as MotionAssetPreview)?.filename
                      }
                      selectable
                      mb="xs"
                      onClick={() => setSelectedThumbnail(preview)}>
                      <SmartImage
                        src={preview.url}
                        // TODO: Fix this
                        // height="100%"
                        // width="100%"
                        // borderRadius="default"
                        // overflow="hidden"
                      />
                    </ThumbnailImageWrapper>
                    <Text font="ui.small">{preview.position}</Text>
                  </Flex>
                );
              })
            : renderLoadingThumbnails()}
        </Flex>
        {error && <Notice noticeStyle="error" message={error} />}
      </ModalBody>
      <ModalFooter
        primary={{
          id: "save-thumbnail-button",
          text: processing ? "Saving..." : "Save",
          onClick: submit,
          disabled: processing || !selectedThumbnail,
        }}
        styleOverrides={{ px: "xl" }}
      />
    </Fragment>
  );
};

export default EditMotionAssetThumbnailModal;
