/**
 * Allows user to pick a file either by clicking an element (typically a link or
 * button) or by simply dragging a file onto a drop zone.
 *
 * This component uses the "render prop" approach. The following props are passed to the child,
 * which should be a function:
 *
 * openNativeFilePicker: Call to open the native file picker dialog
 * processing: Boolean for whether the aftereffects of picking a file have finished
 * dragging: Boolean for whether a file is currently being dragged (i.e., to highlight drop zone)
 * dropZoneProps: An element where a user can drag and drop their file to should have these props
 *
 * <FilePicker onPickFiles={...} onError={...} maxFileSize={...} fileExtensions={...}>
 *   {({ openNativeFilePicker, processing, dragging, dropZoneProps }) => {
 *     if (dragging) {
 *       return <div {...dropZoneProps}>Drop here!</div>;
 *     }
 *     else {
 *       return (
 *           <button onClick={openNativeFilePicker} disabled={processing}>Choose File...</button>
 *       );
 *     }
 *   }}
 * </FilePicker>;
 */

import React, { useCallback, useState, useRef, useEffect, Fragment } from "react";
import mime from "mime-types";
import getFilesFromEvent from "@helpers/getFilesFromEvent";

type Props = {
  onPickFiles: (files: File[]) => void;
  multiple?: boolean;
  fileExtensions?: string[];
  children: (props: {
    openNativeFilePicker: (e: React.MouseEvent) => void;
    dragging: boolean;
    dropZoneProps: {
      onDrop: (e: React.DragEvent) => void;
      onDragOver: (e: React.DragEvent) => void;
    };
  }) => React.ReactNode;
};

const FilePicker: React.FC<Props> = ({
  onPickFiles,
  fileExtensions = [],
  multiple = false,
  children,
}) => {
  const [dragging, setDragging] = useState(false),
    inputRef = useRef<HTMLInputElement>(null),
    mimeTypes = fileExtensions.map(ext => mime.lookup(ext)),
    acceptsString = [...fileExtensions.map(ext => `.${ext}`), ...mimeTypes].join(",");

  // Dragging
  const draggingNodes = useRef(new Set());
  const handleDragOver = useCallback(
    (e: DragEvent) => {
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
      if (!dragging) setDragging(true);
    },
    [dragging]
  );

  const handleDragEnter = useCallback(
    (e: DragEvent) => {
      e.stopPropagation();
      handleDragOver(e);
      draggingNodes.current.add(e.target);
    },
    [handleDragOver]
  );

  const handleDragLeave = useCallback(
    (e: DragEvent) => {
      e.stopPropagation();
      e.preventDefault();
      draggingNodes.current.delete(e.target);
      if (dragging && draggingNodes.current.size === 0) setDragging(false);
    },
    [dragging]
  );

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.stopPropagation();
      e.preventDefault();
      draggingNodes.current.clear();
      if (dragging) setDragging(false);
    },
    [dragging]
  );

  useEffect(() => {
    const dragBody = document.body;
    dragBody.addEventListener("dragenter", handleDragEnter, false);
    dragBody.addEventListener("dragover", handleDragOver, false);
    dragBody.addEventListener("dragleave", handleDragLeave, false);
    dragBody.addEventListener("drop", handleDrop, false);
    return () => {
      dragBody.removeEventListener("dragenter", handleDragEnter, false);
      dragBody.removeEventListener("dragover", handleDragOver, false);
      dragBody.removeEventListener("dragleave", handleDragLeave, false);
      dragBody.removeEventListener("drop", handleDrop, false);
    };
  }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

  const openNativeFilePicker = useCallback((e: React.MouseEvent) => {
    e?.preventDefault();
    // Clicking input brings up file selector dialog
    // TODO: This doesn't seem to work in chrome...
    inputRef.current.click();
  }, []);

  const loadSelectedFile = useCallback(
    (e: React.ChangeEvent | React.DragEvent) => {
      setDragging(false);
      e.stopPropagation();
      e.preventDefault();
      e.persist();

      let files = getFilesFromEvent(e);
      if (!multiple) files = [files[0]];
      onPickFiles(files);
    },
    [multiple, onPickFiles]
  );

  const clearDefault = useCallback((e: React.DragEvent) => {
    e.preventDefault();
  }, []);

  const childProps = {
    openNativeFilePicker,
    dragging,
    dropZoneProps: {
      onDrop: loadSelectedFile,
      onDragOver: clearDefault,
    },
  };

  return (
    <Fragment>
      {children({ ...childProps })}
      <input
        type="file"
        ref={inputRef}
        onChange={loadSelectedFile}
        accept={acceptsString}
        style={{ display: "none" }}
        multiple={multiple}
      />
    </Fragment>
  );
};

export default FilePicker;
