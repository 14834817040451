/* eslint-disable jsx-a11y/aria-role */
import React from "react";
import styled from "styled-components";
import { Flex, Button, ButtonProps, Text, utils } from "@thenounproject/lingo-core";

import { Container, InnerMedium } from "../Elements";

import background from "../../data/assets/CTA-Background.png";
import { MarketingButtons } from "../MarketingButtons";

const { mq } = utils;

type Props = {
  size?: "small" | "medium" | "large";
  title?: string;
  body?: string;
  button1?: ButtonProps;
  button2?: ButtonProps;
};

const ButtonWrapper = styled(Flex).attrs({
  mt: "xl",
  alignItems: "center",
  gap: "16px",
})`
  ${mq.xs` 
    width: 100%;
    text-align: center;
    flex-direction: column;
    a {
      width: 100%;
    }
  `}
`;

const CtaBlock: React.FC<Props> = ({
  title = "Build your brand on Lingo",
  body = "Fully featured 30-day trial. No credit card required.",
  button1 = MarketingButtons.getStarted,
  button2 = MarketingButtons.bookDemo,
  ...rest
}) => {
  const InnerElement = InnerMedium;

  return (
    <Container pt="xl" {...rest}>
      <InnerElement
        first
        boxShadow="card"
        borderRadius="default"
        alignItems="left"
        flexDirection="column"
        justifyContent="space-between"
        px="xxl"
        py="xxxl"
        backgroundImage={`url(${background})`}
        backgroundSize="cover"
        backgroundPosition="center">
        <Flex alignItems="flex-start" flexDirection="column">
          <Text font="marketing.h3" mb="s">
            {title}
          </Text>
          {body && <Text font="marketing.regular">{body}</Text>}
        </Flex>
        <ButtonWrapper>
          <Button
            buttonStyle="outline"
            themeOverrides={{ outlineStyle: "light" }}
            {...button1}
            variations={{ "mq.xs": { width: "100%" } }}
          />
          <Button
            buttonStyle="outline"
            themeOverrides={{ outlineStyle: "light" }}
            {...button2}
            variations={{ "mq.xs": { width: "100%" } }}
          />
        </ButtonWrapper>
      </InnerElement>
    </Container>
  );
};

export default CtaBlock;
