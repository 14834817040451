import React, { Fragment, useEffect, useCallback, useMemo } from "react";
import {
  OptionCard,
  Box,
  Flex,
  Text,
  Notice,
  Button,
  ActivityIndicator,
  User,
  ToggleBox,
  SpacePermission,
  pluralize,
} from "@thenounproject/lingo-core";
import _uniq from "lodash/uniq";
import _isArray from "lodash/isArray";

import KitSelectList from "../../../kits/KitSelectList";

import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";

import useUpdateSpaceMember from "@redux/actions/spaceMembers/useUpdateSpaceMember";
import useBatchUpdateSpaceMembers from "@redux/actions/spaceMembers/useBatchUpdateSpaceMembers";
import useBatchUpdateKitMembers from "@redux/actions/kitMembers/useBatchUpdateKitMembers";
import useAddPortalMembers from "@redux/actions/portalMembers/useAddPortalMembers";
import useRemovePortalMembers from "@redux/actions/portalMembers/useRemovePortalMembers";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";

import useKits from "@redux/actions/kits/useKits";
import usePortals from "@redux/actions/portals/usePortals";
import useSpaceMembersById, {
  PopulatedSpaceMember,
} from "@redux/actions/spaceMembers/useFetchSpaceMembersById";
import { useSelectSpace } from "@redux/selectors/entities/spaces";
import PortalSelectList from "../../../portals/PortalSelectList";
import ModalFooter from "../../../ModalFooter";

const ROLES_COPY = [
  {
    name: "admin",
    label: "Admin",
    description:
      "Admins can access all kits and manage user permissions. They can also access space settings and insights.",
  },
  {
    name: "member",
    label: "Member",
    description:
      "Members can access all Kits. They can only edit Kits they are allowed to by an Admin or Owner.",
  },
  {
    name: "limited_member",
    label: "Limited Member",
    description:
      "Limited members can only access or edit Kits they are allowed to by an Admin or Owner.",
  },
];

const ROLES_COPY_MANAGERS = [
  ROLES_COPY[0],
  {
    name: "member",
    label: "Content Manager",
    description:
      "Content Managers can access and edit all content. They can also share kits with existing team members.",
  },
  {
    name: "limited_member",
    label: "Member",
    description: "Members can only access or edit Kits they are allowed to by an Admin or Manager.",
  },
];

type Props = {
  spaceId: number;
  userIds: number[];
};

type PortalAccessMap = Record<string, Set<number>>;

const getPortalAccessMap = (members: PopulatedSpaceMember[]): PortalAccessMap => {
  const map: PortalAccessMap = {};
  members.forEach(member => {
    member?.portalAccess?.forEach(access => {
      const portalId = access.portalId;
      if (!map[portalId]) {
        map[portalId] = new Set();
      }
      if (access.permissions.includes("view")) {
        map[portalId].add(member.id);
      }
    });
  });

  return map;
};

function SpaceMemberPermissions({ spaceId, userIds }: Props) {
  const space = useSelectSpace();
  const { showModal, dismissModal } = useShowModal();
  const { plan } = space,
    isTrial = plan && plan?.planIdentifier.startsWith("trial"),
    hasEditorQuota = plan?.usage.editors.quota > 0,
    showNotice = plan && !isTrial && !hasEditorQuota;

  const hasPortalsFeature = space.features.includes("portals");
  const canChangeRole = space.access.permissions.includes(SpacePermission.changeUserRole);

  const { data: portalsData, isLoading: loadingPortals } = usePortals(
    { spaceId },
    { skip: !hasPortalsFeature }
  );
  const { portals = [] } = portalsData || {};
  const { data, isLoading: loadingKits } = useKits({ spaceId });
  const { kits: spaceKits = [] } = data || {};
  const {
    data: memberData,
    refresh,
    isLoading: membersLoading,
  } = useSpaceMembersById({ spaceId, userIds }, { refetchOnMount: true });
  const { members = [] } = memberData ?? {};
  const isMembersInitialLoading = membersLoading && !members.length;
  const isLoading = loadingKits || loadingPortals || isMembersInitialLoading;

  const { selectedPortals, variedPortals } = useMemo(() => {
    const portalAccessMap = getPortalAccessMap(members);
    const memberCount = members.length;

    const selectedPortals: string[] = [];
    const variedPortals: string[] = [];

    portals?.forEach(portal => {
      const accessSet = portalAccessMap[portal.id];
      if (accessSet) {
        selectedPortals.push(portal.id);
        if (accessSet.size > 0 && accessSet.size < memberCount) {
          variedPortals.push(portal.id);
        }
      }
    });

    return { selectedPortals, variedPortals };
  }, [members, portals]);

  const { currentRole, users, multipleUsers, kitRoles } = useMemo(() => {
    const roles = new Set(members.map(m => m.role));

    const currentRole = roles.size === 1 ? members[0].role : null,
      users = members.map(m => m.user);
    const multipleUsers = users.length > 1;

    // Map kitID -> set of roles
    const kitRoles = {} as Record<string, string[]>;
    spaceKits.forEach(kit => {
      kitRoles[kit.kitId] = [];
    });
    members.forEach(spaceMember => {
      const memberMap = spaceMember.kitAccess.reduce((res, kitMember) => {
        res[kitMember.kitId] = kitMember.role;
        return res;
      }, {});
      spaceKits.forEach(kit => {
        const roles = kitRoles[kit.kitId];
        const role = memberMap[kit.kitId] ?? "revoked";
        if (!roles.includes(role)) roles.push(role);
      });
    });

    return { currentRole, users, multipleUsers, kitRoles };
  }, [members, spaceKits]);

  const [updateSpaceMember, spaceMemberStatus] = useUpdateSpaceMember();
  const [batchUpdateSpaceMembers, batchSpaceStatus] = useBatchUpdateSpaceMembers();
  const [batchUpdateKitMembers, batchKitStatus] = useBatchUpdateKitMembers();
  const [updatePortalMembers] = useAddPortalMembers();
  const [removePortalMembers] = useRemovePortalMembers();

  const error = spaceMemberStatus.error || batchSpaceStatus.error || batchKitStatus.error;

  const isProcessing =
    spaceMemberStatus.isProcessing || batchSpaceStatus.isProcessing || batchKitStatus.isProcessing;

  useEffect(() => {
    if (isProcessing) {
      spaceMemberStatus.reset();
      batchSpaceStatus.reset();
      batchKitStatus.reset();
    }
  });

  const removeUser = useCallback(
    (userId: number) =>
      showModal(
        ModalTypes.SPACE_MEMBER_PERMISSIONS,
        {
          spaceId,
          userIds: userIds.filter(id => id !== userId),
        },
        true
      ),
    [showModal, spaceId, userIds]
  );

  const togglePortal = useCallback(
    async (uuid: string) => {
      if (selectedPortals.includes(uuid)) {
        await removePortalMembers({ userIds, portalId: uuid });
      } else {
        await updatePortalMembers({ portalId: uuid, userIds, role: "guest" });
        refresh();
      }
    },
    [selectedPortals, refresh, removePortalMembers, userIds, updatePortalMembers]
  );

  const readOnlyMembers = useMemo(() => {
    return (
      memberData?.members.filter(m => {
        if (m.role !== "limited_member") return false;
        return m.kitAccess.every(k => k.role === "guest");
      }) ?? []
    );
  }, [memberData?.members]);

  const newEditorPrompt = useCallback(
    (onConfirm: () => void) => {
      const count = readOnlyMembers.length;
      const message = `This change will add ${count} additional ${pluralize("editor", count)} to your space and update your subscription. Are you sure you want to continue?`;
      showModal(ModalTypes.CONFIRMATION, {
        title: "Confirm billing update",
        message,
        onConfirm,
        buttonText: "Continue",
        buttonProcessingText: "Continue",
        secondaryButtonText: "Cancel",
      });
    },
    [readOnlyMembers.length, showModal]
  );

  const _changeSpaceMemberRole = useCallback(
    async (role: string) => {
      if (isProcessing) return;
      const userId = users?.[0]?.id;
      if (["admin", "member"].includes(role) && readOnlyMembers.length && showNotice) {
        return newEditorPrompt(async () => {
          await updateSpaceMember({ userId, spaceId, role });
          refresh();
        });
      }

      await updateSpaceMember({ userId: userId, spaceId, role });
      refresh();
    },
    [
      isProcessing,
      newEditorPrompt,
      readOnlyMembers.length,
      refresh,
      spaceId,
      updateSpaceMember,
      users,
      showNotice,
    ]
  );

  const _changeMultipleSpaceMemberRoles = useCallback(
    async (role: string) => {
      if (isProcessing) return;

      if (["admin", "member"].includes(role) && readOnlyMembers.length && showNotice) {
        return newEditorPrompt(async () => {
          await batchUpdateSpaceMembers({ userIds, spaceId, role });
        });
      }

      await batchUpdateSpaceMembers({ userIds, spaceId, role });
    },
    [
      batchUpdateSpaceMembers,
      isProcessing,
      newEditorPrompt,
      readOnlyMembers.length,
      spaceId,
      userIds,
      showNotice,
    ]
  );

  const changeKitRoles = async (kitIds, role) => {
    if (isProcessing) return;

    if (role === "collaborator" && readOnlyMembers.length && showNotice) {
      return newEditorPrompt(() => {
        void batchUpdateKitMembers({ userIds, spaceId, kitIds, role });
      });
    }

    await batchUpdateKitMembers({ userIds, spaceId, kitIds, role });
  };

  const multiSelectPortalRoles = async (portalId: string, role: string) => {
    if (role === "guest") {
      await updatePortalMembers({ portalId, userIds, role });
      refresh();
    } else {
      await removePortalMembers({ userIds, portalId });
    }
  };

  function renderSelectList() {
    if (
      !currentRole ||
      currentRole === "admin" ||
      (currentRole === "member" && space.features.includes("content_managers")) ||
      !spaceKits.length
    )
      return null;
    if (currentRole === "member" || !hasPortalsFeature) {
      const description =
        currentRole === "member"
          ? "Members have view access to all kits"
          : "Limited members must be given permission to view or edit a kit";
      const revokable = !hasPortalsFeature && currentRole === "limited_member";
      return (
        <Box>
          <Text font="ui.regularBold" mb="s">
            Change Kit Access
          </Text>
          <ToggleBox
            title="All Kits"
            description={description}
            isOpen
            control="none"
            estimatedHeight={"initial"}>
            <KitSelectList
              kits={spaceKits}
              selectedKitRoles={kitRoles}
              editable
              revokable={revokable}
              selectRole={changeKitRoles}
            />
          </ToggleBox>
        </Box>
      );
    }
    if (hasPortalsFeature) {
      return (
        <Box>
          <Text font="ui.regularBold" mb="s">
            Portal Access
          </Text>
          <PortalSelectList
            portals={portals}
            variedPortals={variedPortals}
            selectedPortals={selectedPortals}
            selectPortal={togglePortal}
            selectKitRoles={changeKitRoles}
            selectedKitRoles={kitRoles}
            multiSelectPortalRoles={multiSelectPortalRoles}
          />
        </Box>
      );
    }
  }

  if (isLoading) {
    return <ActivityIndicator height="400px" center size="large" />;
  }

  const roleCopy = space.features.includes("content_managers") ? ROLES_COPY_MANAGERS : ROLES_COPY;
  return (
    <Fragment>
      <ModalHeader
        title={`Change ${canChangeRole ? "role and " : ""}access for ${
          multipleUsers ? `${users?.length} Users` : users[0]?.name
        }`}
      />
      <ModalBody>
        {error ? (
          <Notice flexShrink="0" noticeStyle="error" mb="xl" message={error.message} />
        ) : null}
        {multipleUsers && !currentRole ? (
          <Notice flexShrink="0" noticeStyle="info" mb="xl" message="Select a role to continue." />
        ) : null}
        {multipleUsers ? (
          <Flex mb="xl" flexWrap="wrap">
            {users.map(user => (
              <UserPill key={user.id} user={user} onRemoveUser={removeUser} />
            ))}
          </Flex>
        ) : null}
        {canChangeRole && (
          <Box mb="xl">
            <Text font="ui.regularBold" mb="m">
              Choose user role
            </Text>
            {roleCopy.map(r => {
              const isSelected = r.name === currentRole;

              return (
                <Box key={`role-${r.name}`} textAlign="left">
                  <OptionCard
                    cardStyle="radio"
                    selected={isSelected}
                    title={r.label}
                    detail={r.description}
                    onClick={() =>
                      (multipleUsers ? _changeMultipleSpaceMemberRoles : _changeSpaceMemberRole)(
                        r.name
                      )
                    }
                  />
                </Box>
              );
            })}
          </Box>
        )}
        {renderSelectList()}
      </ModalBody>
      <ModalFooter
        primary={{
          text: "Done",
          onClick: dismissModal,
        }}
      />
    </Fragment>
  );
}

export default SpaceMemberPermissions;

type UserPillProps = {
  user: User;
  onRemoveUser: (userId: number) => void;
};

function UserPill({ user, onRemoveUser }: UserPillProps) {
  return (
    <Flex background="grayLight" borderRadius="default" mr="s" mb="s">
      <Text my="xxs" mx="xs">
        {user.name}
      </Text>
      <Button
        size="small"
        icon="action.close"
        onClick={() => onRemoveUser(user.id)}
        buttonStyle="tertiary"
        p="6px"
      />
    </Flex>
  );
}
