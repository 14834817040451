import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  itemId: string;
  version?: number;
};

export type AssetKitResult = {
  kitCover: string;
  kitName: string;
  sectionName: string;
  portalName: string;
  lastDownloaded: string;
  dateAdded: string;
  dateUpdated: string;
  totalDownloads: number;
  kitId: string;
};

type KitTableData = {
  rows: AssetKitResult[];
  total: number;
};

const [useAssetKitUsage, fetchAssetKitUsage] = createQueryAction<Args, KitTableData>(
  {
    entity: "analytics",
    action: "queryAssetKitUsage",
    denormalize: false,
    pagingKey: "rows",
  },
  async ({ args, paging }) => {
    const reports = {
      asset_kit_usage: {
        id: "asset_kit_usage",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        item_uuid: args.itemId,
        version: args.version,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ asset_kit_usage: KitTableData }>(args.spaceId, reports))
      .assetKitUsage;
  }
);

export default useAssetKitUsage;
export { fetchAssetKitUsage };
