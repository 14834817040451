import styled from "styled-components";
import { Flex, Button } from "@thenounproject/lingo-core";

export const SettingsButton = styled(Button).attrs({
  minWidth: "130",
  ml: "16px",
  flexShrink: 0,
})``;

export const SettingOption = styled(Flex).attrs(props => {
  return {
    mb: "8px",
    alignItems: "center",
    variations: {
      ":last-child": {
        mb: "none",
      },
    },
    ...props,
  };
})`
  p:first-child,
  input:first-child,
  div:first-child {
    flex: 1;
  }
`;
