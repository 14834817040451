import { API, Space } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = { email: string; spaceId: Space["id"] };

const [useCreatePublicAccessGuest, createPublicAccessGuest] = createAsyncAction(
  "auth/createPublicAccessGuest",
  async ({ email, spaceId }: Args) => {
    const response = await API.call<string>({
      endpoint: `spaces/${spaceId}/guest`,
      method: "POST",
      data: { email },
    });
    return response;
  }
);

export default useCreatePublicAccessGuest;
export { createPublicAccessGuest };
