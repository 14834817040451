import { createAsyncAction } from "../actionCreators";

import { API, Space, CustomField } from "@thenounproject/lingo-core";

type Args = {
  spaceId: Space["id"];
  field: CustomField;
  confirmDelete?: boolean;
};

const [useUpdateCustomField, updateCustomField] = createAsyncAction(
  "fields/updateCustomField",
  async ({ spaceId, field, confirmDelete = false }: Args) => {
    return await API.call<CustomField>({
      endpoint: `/spaces/${spaceId}/fields/${field.id}`,
      method: "PUT",
      data: { ...field, confirm_delete: confirmDelete },
      entity: API.Entity.customField,
    });
  }
);

export default useUpdateCustomField;
export { updateCustomField };
