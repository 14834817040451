import React, { useCallback, useEffect } from "react";
import usePortalUpsell, { AccessStatus } from "@hooks/usePortalUpsell";
import { Flex, Portal, PrivacyLevel, PortalPermission } from "@thenounproject/lingo-core";
import UpsellTooltip from "../../../components/spaces/settings/UpsellTooltip";
import PasswordCheckbox from "./PasswordCheckbox";
import useUpdatePortal from "@redux/actions/portals/useUpdatePortal";

type Props = {
  portal: Portal;
};
const PortalPasswordCheckbox: React.FC<Props> = ({ portal }) => {
  const { accessStatus, openUpgradeModal } = usePortalUpsell({
    permission: PortalPermission.managePasswordAccess,
    portal,
  });

  const hasPassword = portal.privacy === PrivacyLevel.password;
  const [updatePortal, { error, result, reset, isProcessing }] = useUpdatePortal(),
    removePassword = useCallback(() => {
      void updatePortal({ id: portal.id, privacy: PrivacyLevel.public });
    }, [portal.id, updatePortal]),
    setPassword = useCallback(
      (password: string) => {
        void updatePortal({
          id: portal.id,
          privacy: PrivacyLevel.password,
          password,
        });
      },
      [portal.id, updatePortal]
    );

  useEffect(() => {
    // The result is used to reset the password form but we need to revert that for future updates
    if (result) reset();
  }, [reset, result]);

  // Hide from users who don't have permission to manage password access
  if (accessStatus === AccessStatus.insufficientRole) return null;

  return (
    <Flex
      data-tooltip-source="portal-password-checkbox"
      // Dynamic width allows the input not to get squished while also not causing
      // the tooltip to get offset when the feature is not avialable
      width={accessStatus === AccessStatus.canAccess ? "100%" : null}>
      {accessStatus === AccessStatus.insufficientPlan && (
        <UpsellTooltip
          onClick={openUpgradeModal}
          source="portal-password-checkbox"
          featureName="Password protected Portals"
        />
      )}
      <PasswordCheckbox
        hasPassword={hasPassword}
        setPassword={setPassword}
        removePassword={removePassword}
        success={Boolean(result)}
        error={error?.message}
        disabled={isProcessing || accessStatus === AccessStatus.insufficientPlan}
      />
    </Flex>
  );
};

export default PortalPasswordCheckbox;
