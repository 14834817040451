import { base64, SearchFilterInput, SearchFilterTypes, API } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

type Query = { suggestions: SearchFilterInput[] };
type Data = { queries: Query };
type Args = { spaceId: number; type: SearchFilterTypes; value: string };

const [useAssetLibrarySuggestions, fetchAssetLibrarySuggestions] = createQueryAction<Args, Data>(
  {
    entity: "search",
    action: "fetchAssetLibrarySuggestions",
    denormalize: false,
    debounce: true,
    cacheKey: args => ({ spaceId: args.spaceId, type: args.type }),
  },
  async ({ args }) => {
    return await API.call<Data>({
      endpoint: `search/spaces/${args.spaceId}`,
      method: "GET",
      query: {
        query: base64.encode({
          queries: {
            suggestions: {
              context: "library",
              type: "suggestion",
              filter_type: args.type,
              value: args.value,
            },
          },
        }),
      },
    });
  }
);

export default useAssetLibrarySuggestions;
export { fetchAssetLibrarySuggestions };
