/**
 * A prebuilt Notice, alerting user that no more members can be invited to space.
 *
 * Includes a button to upgrade space subscription to allow more members.
 */

import React, { useCallback } from "react";
import { Notice, BoxProps, FlexProps } from "@thenounproject/lingo-core";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

type Props = {
  spaceId: number;
  permissions: string[];
  styleOverrides?: BoxProps & FlexProps;
};

const SpaceFullMessage = ({ spaceId, permissions, styleOverrides }: Props) => {
  const { showModal } = useShowModal();
  const openUpgradeFlow = useCallback(
    e => {
      e.preventDefault();
      showModal(ModalTypes.SELECT_PLAN, { spaceId });
    },
    [showModal, spaceId]
  );

  const canEdit = permissions.includes("manage_billing");
  const msg = `Looks like there aren’t any spots left. ${
      canEdit ? "Upgrade plan" : "Contact space owner"
    } to add more users.`,
    btn = canEdit && {
      text: "Upgrade",
      onClick: openUpgradeFlow,
    };

  return <Notice noticeStyle="warning" message={msg} button={btn} mb="m" {...styleOverrides} />;
};

export default SpaceFullMessage;
