import React, { useCallback } from "react";
import {
  Box,
  Text,
  Button,
  PublicAccessApprovalMode,
  buildPath,
  Kit,
  Portal,
} from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useSelectSpace } from "@redux/selectors/entities/spaces";

export const mapDownloadsRestrictedApproveModeToLabel: Record<PublicAccessApprovalMode, string> = {
  approve_all_automatically: "No approval required",
  approve_once_for_entity: "Admin approval grants access to entire kit/portal",
  approve_every_asset: "Admin approval grants access to individual assets",
};
type Props = {
  kit?: Kit;
  portal?: Portal;
};

const ConfigureRequestsToolbar: React.FC<Props> = ({ kit, portal }) => {
  const space = useSelectSpace(kit?.spaceId ?? portal?.spaceId);
  const { showModal, dismissModal } = useShowModal();

  let mode: PublicAccessApprovalMode;
  if (portal) {
    mode = portal?.downloadsRestrictedApproveMode ?? "approve_all_automatically";
  } else {
    mode =
      kit?.downloadsRestrictedApproveMode ??
      space?.downloadsRestrictedApproveMode ??
      "approve_all_automatically";
  }

  const openConfigureModal = useCallback(() => {
    showModal(ModalTypes.CONFIGURE_REQUESTS_MODAL, {
      kitId: kit?.kitId,
      spaceId: space?.id,
      portalId: portal?.id,
    });
  }, [kit?.kitId, portal?.id, showModal, space?.id]);

  return (
    <Box>
      <Text font="ui.small" color="grayDarkest">
        {mapDownloadsRestrictedApproveModeToLabel[mode]}.{" "}
        <Button
          buttonStyle="tertiary"
          fontStyle="ui.small"
          text="Configure"
          onClick={openConfigureModal}
        />{" "}
        •{" "}
        <Button
          buttonStyle="tertiary"
          fontStyle="ui.small"
          text="View Requests"
          link={buildPath("/members/requests", { space })}
          onClick={dismissModal}
        />
      </Text>
    </Box>
  );
};

export default React.memo(ConfigureRequestsToolbar);
