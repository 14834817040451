import React, { useCallback, useRef, useEffect, useMemo, ComponentProps, useState } from "react";
import {
  buildURL,
  SearchFilterTypes,
  SearchFilterInput,
  useNavigation,
  KitPermission,
} from "@thenounproject/lingo-core";

import ModalBody from "../ModalBody";
import SearchModalInput from "./SearchModalInput";
import suggestionTypes from "../../constants/suggestionTypes";

import { stripFilterTypeFromQuery } from "@helpers/search";
import SearchResultsTab from "./SearchResultsTab";
import { useSelectSpace } from "@selectors/entities/spaces";
import type { RootState } from "@redux/store";
import useReplaceQueryFilters from "@actions/search/useReplaceQueryFilters";
import useAddQueryFilter from "@actions/search/useAddQueryFilter";
import useRemoveQueryFilter from "@actions/search/useRemoveQueryFilter";
import useChangeKeywordFilter from "@actions/search/useChangeKeywordFilter";
import { useAppSelectorV1 } from "@redux/hooks";
import useShowModal from "@redux/actions/useModals";
import useNavPoint from "@hooks/useNavPoint";
import useSearchMeta from "@redux/actions/search/useSearchMeta";
import useSearchSuggestions from "@redux/actions/search/useSearchSuggestions";
import useSetSearchContext, { SearchContext } from "@redux/actions/search/useSetSearchContext";
import useChangeSort from "@redux/actions/search/useChangeSort";
import useClearQueryFilter from "@redux/actions/search/useClearQueryFilter";
export interface Query {
  matched?: SearchFilterInput;
  keyword?: SearchFilterInput;
}
export interface SuggestionQuery {
  tokenFilters: SearchFilterInput[];
  queries: Query;
}

const SearchModal: React.FC = () => {
  const [submitted, setSubmitted] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigation = useNavigation();
  const { dismissModal } = useShowModal();
  const navPoint = useNavPoint();
  const { data: prefixes = [] } = useSearchMeta();

  const { filters, searchHistory, context } = useAppSelectorV1(
    (state: RootState) => state.entities.search.objects
  );

  const space = useSelectSpace(),
    replaceQueryFilters = useReplaceQueryFilters(),
    addQueryFilter = useAddQueryFilter(),
    removeQueryFilter = useRemoveQueryFilter(),
    changeKeywordFilter = useChangeKeywordFilter(),
    setSearchContext = useSetSearchContext(),
    changeSort = useChangeSort(),
    clearQueryFilter = useClearQueryFilter();

  const localQuery = useMemo(() => filters.find(f => f.type === "keyword")?.value || "", [filters]);
  const tokenFilters = useMemo(() => filters.filter(f => f.type !== "keyword"), [filters]);

  const getFilterMatch = useCallback(
    (val: string) => {
      const filterInputRegex = new RegExp(`^(${prefixes.join("|")}):`);
      const filterMatch = val.match(filterInputRegex);
      return filterMatch?.[1] || null;
    },
    [prefixes]
  );

  const suggestionQuery = useMemo(() => {
    if (!localQuery) return;
    const matchedFilterType = getFilterMatch(localQuery);
    if (matchedFilterType) {
      return {
        type: SearchFilterTypes.suggestion,
        filter_type: matchedFilterType,
        value: stripFilterTypeFromQuery(localQuery, matchedFilterType),
      };
    } else {
      return {
        type: SearchFilterTypes.suggestion,
        value: localQuery,
      };
    }
  }, [getFilterMatch, localQuery]);

  useEffect(() => {
    setSubmitted(false);
  }, [localQuery]);

  const { data: suggestions } = useSearchSuggestions(
    { spaceId: space.id, query: suggestionQuery },
    { skip: !suggestionQuery }
  );

  useEffect(() => {
    // Always blur the input when the modal first opens
    inputRef.current?.blur();
    // If there are no filters, add the current kit as a filter
    const { portal, space, kit, version } = navPoint ?? {};

    if (portal) {
      setSearchContext({ context: SearchContext.portal, portalId: portal.id });
    } else if (kit?.access?.isPublic) {
      setSearchContext({ context: SearchContext.kit, kitId: kit.kitId });
    } else {
      setSearchContext({ context: SearchContext.global });
    }

    const canSearchSpace = space?.access?.permissions?.includes("search_assets");

    if (
      kit?.access.permissions.includes(KitPermission.searchAssets) &&
      (portal || canSearchSpace)
    ) {
      if (filters.find(f => f.kit_uuid === kit.kitId)) return;
      filters
        .filter(f => f.type === SearchFilterTypes.kit)
        .forEach(f => removeQueryFilter({ filterId: f.id }));
      let v = version;
      // If you follow a public asset link it is possible to end up with version 0
      // set in the nav point even if the user can't view the containing kit.
      // In this case, we should default to the recommended version.
      if (
        v !== kit.recommendedVersion &&
        v === 0 &&
        !kit.access.permissions.includes(KitPermission.viewAllContent)
      ) {
        v = kit.recommendedVersion;
      }
      addQueryFilter({
        id: `kit:${kit.kitId}`,
        type: SearchFilterTypes.kit,
        display: kit.name,
        name: kit.name,
        kit_uuid: kit.kitId,
        version: v,
      });
    }

    return () => {
      changeKeywordFilter("");
      clearQueryFilter();
      setSubmitted(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSearchValue = useCallback(
    (value: string) => {
      changeKeywordFilter(value || "");
    },
    [changeKeywordFilter]
  );

  const showResults = useCallback(
    (e?: React.FormEvent) => {
      if (e) {
        e.preventDefault();
      }
      setSubmitted(true);
      changeSort("relevance");
      inputRef.current.blur();
    },
    [changeSort]
  );

  const onSelectSuggestion: ComponentProps<typeof SearchModalInput>["onSelectSuggestion"] =
    useCallback(
      (type, data) => {
        setSubmitted(true);
        switch (type) {
          case suggestionTypes.filter: {
            const suggestion = data as SearchFilterInput;
            changeKeywordFilter("");
            addQueryFilter(suggestion);
            return inputRef.current?.focus();
          }
          case suggestionTypes.jump: {
            const suggestion = data as SearchFilterInput;
            const search = { v: undefined };
            let prefix,
              value,
              hash = "";
            switch (suggestion.object_type) {
              case "kit":
                prefix = "k";
                value = suggestion.kit.short_id;
                break;
              case "section":
                prefix = "s";
                value = suggestion.section.short_id;
                search.v = suggestion.section.version;
                break;
              default:
                prefix = "s";
                value = suggestion.section.short_id;
                hash = suggestion.item.short_id;
                search.v = suggestion.item.version;
                break;
            }
            navigation.push(buildURL(`/${prefix}/${value}/`, { space }, search, hash));
            dismissModal();
            break;
          }
          case suggestionTypes.default: {
            if (Array.isArray(data)) return;
            addQueryFilter(data.data as SearchFilterInput);
            showResults();
            break;
          }
          case suggestionTypes.recent: {
            const suggestions = data as SearchFilterInput[];
            replaceQueryFilters(suggestions);
            showResults();
            break;
          }
          default: {
            const suggestion = data as SearchFilterInput;
            changeKeywordFilter(suggestion.display);
            showResults();
          }
        }
      },
      [
        addQueryFilter,
        changeKeywordFilter,
        dismissModal,
        navigation,
        replaceQueryFilters,
        showResults,
        space,
      ]
    );

  return (
    <ModalBody px="none" py="none" style={{ overflow: "hidden" }}>
      <SearchModalInput
        localQuery={localQuery}
        suggestions={suggestions}
        matchedFilterType={getFilterMatch(localQuery)}
        recentSearches={searchHistory}
        onSubmit={showResults}
        onSelectSuggestion={onSelectSuggestion}
        inputRef={inputRef}
        context={context}
        onChange={onChangeSearchValue}
      />
      <SearchResultsTab
        space={space}
        submitted={submitted}
        onRemoveFilter={removeQueryFilter}
        filters={tokenFilters}
      />
    </ModalBody>
  );
};

export default SearchModal;
