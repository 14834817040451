import React from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  ButtonProps,
  FlexProps,
  BoxProps,
  Tooltip,
} from "@thenounproject/lingo-core";

export type ReportTitleProps = {
  title?: string;
  subtitle?: string;
  subtitleButton?: ButtonProps;
  tooltip?: string;
  headerExtra?: React.ReactNode;
  styleOverrides?: BoxProps & FlexProps;
};

export function ReportTitle({
  title,
  subtitle,
  tooltip,
  headerExtra,
  subtitleButton,
  styleOverrides,
}: ReportTitleProps) {
  if (!title && !subtitle) return null;
  return (
    <Flex
      alignItems="center"
      borderBottom="default"
      borderRadius="4px 4px 0 0"
      background="grayLightest"
      px="24px"
      py="16px"
      {...styleOverrides}>
      <Box flex="1">
        {title && (
          <Text mb="xxs" font="ui.regularBold">
            {title}
          </Text>
        )}

        {subtitle && (
          <Flex alignItems="center">
            <Text as="div" color="grayDarkest">
              {subtitle}
            </Text>
            {subtitleButton && <Button buttonStyle="tertiary" ml="xs" {...subtitleButton} />}
            {tooltip && (
              <Tooltip.InfoTip direction="bottom" fill="grayDarkest">
                {tooltip}
              </Tooltip.InfoTip>
            )}
          </Flex>
        )}
      </Box>
      {headerExtra && <Box>{headerExtra}</Box>}
    </Flex>
  );
}
export default ReportTitle;
