/**
 * Any component that can be shown in a modal is imported here, and exported with the name that
 * should be passed into the modal component to render it.
 */

import AddJoinDomainModal from "../components/spaces/settings/modals/AddJoinDomainModal";
import cancelSubscriptionModal from "../components/billing/CancelSubscriptionModal";
import changeCardModal from "../components/billing/ChangeCardModal";
import changeSubscriptionModal from "../components/billing/ChangeSubscriptionModal";
import confirmationModal from "../components/ConfirmationModal";
import createApiTokenModal from "../components/spaces/settings/modals/CreateApiTokenModal";
import createEditColorModal from "../components/kits/modals/CreateEditColorModal";
import createFontModal from "../components/kits/modals/CreateFontModal";
import CreateEditKitCollectionModal from "@features/space-kits/modals/CreateEditKitCollectionModal";
import CreateEditLinkModal from "../components/kits/modals/CreateEditLinkModal";
import createKitModal from "../components/kits/modals/CreateKitModal";
import createKitVersionModal from "../components/kits/modals/CreateKitVersionModal";
import createSectionModal from "../components/kits/modals/CreateSectionModal";
import createTemplateKitModal from "../components/kits/modals/CreateTemplateKitModal";
import CreateGalleryItemModal from "../components/kits/modals/CreateGalleryItemModal";
import deleteKitModal from "../components/kits/modals/DeleteKitModal";
import deleteSpace from "../components/spaces/settings/modals/DeleteSpaceModal";
import deleteSpaceUserModal from "../components/spaces/members/modals/DeleteSpaceUserModal";
import editInvoiceModal from "../components/billing/EditInvoiceModal";
import newUserAccessModal from "../components/spaces/settings/modals/NewUserAccessModal";
import editMotionAssetThumbnailModal from "../components/kits/modals/EditMotionAssetThumbnailModal";
import editKitModal from "../components/kits/modals/EditKitModal";
import editKitVersion from "../components/kits/modals/EditKitVersionModal";
import inviteSpaceMembers from "../components/spaces/invitations/InviteSpaceMembers";
import leaveSpaceModal from "../components/spaces/members/modals/LeaveSpaceModal";
import ManageCollectionKitsModal from "@features/space-kits/modals/ManageCollectionKitsModal";
import shareKitModal from "../components/kits/modals/ShareKitModal/ShareKitModal";
import manageFilecuts from "../components/kits/modals/ManageFilecuts";
import managePortalKits from "../components/spaces/settings/modals/ManagePortalKits";
import pickFileModal from "../components/kits/modals/PickFileModal";
import MoveCopyToSectionModal from "../features/content-management/modals/MoveCopyToSectionModal";
import MoveCopyToKitModal from "../features/content-management/modals/MoveCopyToKitModal";
import publishedKitMeta from "../components/kits/modals/PublishedKitMeta";
import { RequestEnterpriseModal } from "../components/billing/RequestEnterpriseForm";
import selectPlanModal from "../components/billing/SelectPlanModal";
import spaceMemberPermissions from "../components/spaces/members/modals/SpaceMemberPermissions";
import transferSpaceModal from "../components/billing/TransferSpaceModal";
import uploadDetailsModal from "../components/UploadDetailsModal";
import searchModal from "../components/search/SearchModal";
import figmaSetupModal from "../components/spaces/settings/modals/FigmaIntegrationSetupModal";
import figmaLinkModal from "../components/figma/FigmaLinkModal";
import figmaFramePickerModal from "../components/figma/FigmaFramePickerModal";
import ManageKitCollectionsModal from "@features/space-kits/modals/ManageKitCollectionsModal";
import AddAssetsToKitModal from "@features/library/modals/AddAssetsToKitModal";
import AssetLibraryPickerModal from "@features/library/components/AssetLibraryPickerModal";
import EditTagModal from "@features/library/modals/EditTagModal";
import CreateEditCustomFieldModal from "@features/library/modals/CreateEditCustomFieldModal";
import TFAPasswordVerifyModal from "../components/settings/modals/TFAPasswordVerifyModal";
import TFASetupModal from "../components/settings/modals/TFASetupModal";
import TFADisableModal from "../components/settings/modals/TFADisableModal";
import TFACodesModal from "../components/settings/modals/TFACodesModal";
import TFASuccessModal from "../components/settings/modals/TFASuccessModal";
import InsightsPortalModal from "../components/insights/modals/InsightsPortalModal";
import InsightsKitModal from "../components/insights/modals/InsightsKitModal";
import InsightsAssetModal from "../components/insights/modals/InsightsAssetModal";
import InsightsUserModal from "../components/insights/modals/InsightsUserModal";
import InsightsDownloadModal from "../components/insights/modals/InsightsDownloadModal";

import { ModalTypes } from "@redux/actions/useModals";
import CreateEditAssetViewModal from "@features/library/modals/CreateEditAssetViewModal";
import MenuModal from "../components/MenuModal";
import KitNavModal from "../components/navigation/KitNavModal";
import InfoPromptModal from "../components/settings/modals/InfoPromptModal";
import ConfigureRequestsModal from "@features/access-settings/downloadRequetSettings/ConfigureRequestsModal";
import SpaceRequestEditModal from "../components/spaces/members/modals/SpaceRequestEditModal";
import SpaceRequestDenyModal from "../components/spaces/members/modals/SpaceRequestDenyModal";
import EditAssetThumbnailModal from "../components/kits/modals/EditAssetThumbnailModal";
import DeletePortalModal from "../features/dashboard/modals/DeletePortalModal";
import CreatePortalModal from "@features/dashboard/modals/CreatePortalModal";
import EditPortalModal from "@features/dashboard/modals/EditPortalModal";
import AddExistingKitModal from "../components/portals/modals/AddExisitingKitModal";
import SharePortalModal from "../components/portals/modals/SharePortalModal";
import PortalDomainModal from "../components/portals/modals/PortalDomainModal";
import CreateDirectLinkModal from "../components/inspector/modals/CreateDirectLinkModal";
import UpdateDirectLinkModal from "../components/inspector/modals/UpdateDirectLinkModal";
import InsightsDirectLinksModal from "../components/insights/modals/InsightsDirectLinksModal";
import PortalMigrationModal from "../components/spaces/settings/modals/PortalMigrationModal";
import MigrateToPortalsModal from "../components/settings/modals/MigrateToPortalsModal";
import ConfirmMigrationModal from "../components/settings/modals/ConfirmMigrationModal";
import IncreaseUsageModal from "../components/billing/modals/IncreaseUsageModal";

export const modalTypes = {
  [ModalTypes.ADD_EXISTING_KIT]: AddExistingKitModal,
  [ModalTypes.ADD_JOIN_DOMAIN]: AddJoinDomainModal,
  [ModalTypes.ADD_ASSETS_TO_KIT]: AddAssetsToKitModal,
  [ModalTypes.CANCEL_SUBSCRIPTION]: cancelSubscriptionModal,
  [ModalTypes.CHANGE_CARD]: changeCardModal,
  [ModalTypes.CHANGE_SUBSCRIPTION]: changeSubscriptionModal,
  [ModalTypes.CONFIGURE_REQUESTS_MODAL]: ConfigureRequestsModal,
  [ModalTypes.CONFIRMATION]: confirmationModal,
  [ModalTypes.CONFIRM_MIGRATION]: ConfirmMigrationModal,
  [ModalTypes.CREATE_API_TOKEN]: createApiTokenModal,
  [ModalTypes.CREATE_DIRECT_LINK]: CreateDirectLinkModal,
  [ModalTypes.CREATE_EDIT_ASSET_VIEW]: CreateEditAssetViewModal,
  [ModalTypes.CREATE_EDIT_COLOR]: createEditColorModal,
  [ModalTypes.CREATE_EDIT_CUSTOM_FIELD]: CreateEditCustomFieldModal,
  [ModalTypes.CREATE_EDIT_LINK]: CreateEditLinkModal,
  [ModalTypes.CREATE_FONT]: createFontModal,
  [ModalTypes.CREATE_EDIT_KIT_COLLECTION]: CreateEditKitCollectionModal,
  [ModalTypes.CREATE_PORTAL]: CreatePortalModal,
  [ModalTypes.CREATE_KIT]: createKitModal,
  [ModalTypes.CREATE_KIT_VERSION]: createKitVersionModal,
  [ModalTypes.CREATE_SECTION]: createSectionModal,
  [ModalTypes.CREATE_TEMPLATE_KIT]: createTemplateKitModal,
  [ModalTypes.CREATE_GALLERY_ITEM]: CreateGalleryItemModal,
  [ModalTypes.DELETE_PORTAL]: DeletePortalModal,
  [ModalTypes.DELETE_KIT]: deleteKitModal,
  [ModalTypes.DELETE_SPACE]: deleteSpace,
  [ModalTypes.DELETE_SPACE_USER]: deleteSpaceUserModal,
  [ModalTypes.EDIT_PORTAL]: EditPortalModal,
  [ModalTypes.EDIT_INVOICE]: editInvoiceModal,
  [ModalTypes.EDIT_NEW_USER_ACCESS]: newUserAccessModal,
  [ModalTypes.EDIT_MOTION_ASSET_THUMBNAIL]: editMotionAssetThumbnailModal,
  [ModalTypes.EDIT_ASSET_THUMBNAIL]: EditAssetThumbnailModal,
  [ModalTypes.EDIT_KIT]: editKitModal,
  [ModalTypes.EDIT_DIRECT_LINK]: UpdateDirectLinkModal,
  [ModalTypes.EDIT_KIT_VERSION]: editKitVersion,
  [ModalTypes.EDIT_TAG]: EditTagModal,
  [ModalTypes.ENTERPRISE_REQUEST]: RequestEnterpriseModal,
  [ModalTypes.FIGMA_FRAME_PICKER_MODAL]: figmaFramePickerModal,
  [ModalTypes.FIGMA_LINK_MODAL]: figmaLinkModal,
  [ModalTypes.FIGMA_SETUP]: figmaSetupModal,
  [ModalTypes.INFO_PROMPT]: InfoPromptModal,
  [ModalTypes.INVITE_SPACE_MEMBERS]: inviteSpaceMembers,
  [ModalTypes.LEAVE_SPACE]: leaveSpaceModal,
  [ModalTypes.MANAGE_COLLECTION_KITS]: ManageCollectionKitsModal,
  [ModalTypes.MANAGE_KIT_COLLECTIONS]: ManageKitCollectionsModal,
  [ModalTypes.SHARE_KIT]: shareKitModal,
  [ModalTypes.SHARE_PORTAL]: SharePortalModal,
  [ModalTypes.MANAGE_FILECUTS]: manageFilecuts,
  [ModalTypes.MANAGE_PORTAL_KITS]: managePortalKits,
  [ModalTypes.MIGRATE_TO_PORTALS]: MigrateToPortalsModal,
  [ModalTypes.MOVE_COPY_TO_KIT]: MoveCopyToKitModal,
  [ModalTypes.MOVE_COPY_TO_SECTION]: MoveCopyToSectionModal,
  [ModalTypes.MENU_MODAL]: MenuModal,
  [ModalTypes.PICK_FILE]: pickFileModal,
  [ModalTypes.PORTAL_DOMAIN]: PortalDomainModal,
  [ModalTypes.PORTAL_MIGRATION]: PortalMigrationModal,
  [ModalTypes.PUBLISHED_KIT_META]: publishedKitMeta,
  [ModalTypes.SEARCH_MODAL]: searchModal,
  [ModalTypes.SELECT_PLAN]: selectPlanModal,
  [ModalTypes.SPACE_REQUEST_DENY]: SpaceRequestDenyModal,
  [ModalTypes.SPACE_REQUEST_EDIT]: SpaceRequestEditModal,
  [ModalTypes.SPACE_MEMBER_PERMISSIONS]: spaceMemberPermissions,
  [ModalTypes.INCREASE_USAGE]: IncreaseUsageModal,
  [ModalTypes.TRANSFER_SPACE]: transferSpaceModal,
  [ModalTypes.UPLOAD_DETAILS]: uploadDetailsModal,
  [ModalTypes.LIBRARY_PICKER]: AssetLibraryPickerModal,
  [ModalTypes.TFA_PASSWORD_VERIFY]: TFAPasswordVerifyModal,
  [ModalTypes.TFA_SETUP]: TFASetupModal,
  [ModalTypes.TFA_DISABLE]: TFADisableModal,
  [ModalTypes.TFA_CODES]: TFACodesModal,
  [ModalTypes.TFA_SUCCESS]: TFASuccessModal,
  [ModalTypes.KIT_NAVIGATION]: KitNavModal,
  [ModalTypes.INSIGHTS_PORTAL]: InsightsPortalModal,
  [ModalTypes.INSIGHTS_KIT]: InsightsKitModal,
  [ModalTypes.INSIGHTS_ASSET]: InsightsAssetModal,
  [ModalTypes.INSIGHTS_USER]: InsightsUserModal,
  [ModalTypes.INSIGHTS_DOWNLOAD]: InsightsDownloadModal,
  [ModalTypes.INSIGHTS_DIRECT_LINKS]: InsightsDirectLinksModal,
} as const;
