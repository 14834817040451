import React, { useCallback, useState, Fragment, useRef } from "react";
import {
  SearchField,
  TabBar,
  Box,
  Flex,
  Text,
  Button,
  Notice,
  OptionCard,
  ActivityIndicator,
  EmptyState,
} from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import FilePicker from "../../FilePicker";

import { fontFileExtensions } from "../../../constants/fontFileExtensions";
import useGoogleFonts, { Font } from "@redux/actions/kits/useGoogleFonts";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { AsyncResponse } from "@actions/actionCreators/createAsyncAction";

type FontCardArgs = {
  font: Font;
  setFont: (font) => void;
};

const FontCard = React.memo(
  Object.assign(
    ({ font, setFont }: FontCardArgs) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const onClick = useCallback(() => setFont(font), [font, setFont]);
      return (
        <OptionCard
          title=""
          detail={font.family}
          cardStyle="disclosure"
          onClick={onClick}
          size="small"
          flex="0 0 auto"
        />
      );
    },
    { displayName: "FontCard" }
  ),
  (prevProps, nextProps) =>
    prevProps.font.family === nextProps.font.family && prevProps.setFont === nextProps.setFont
);

type Props = {
  onPickFontFiles: (files: File[]) => void;
  onPickGoogleFont: (fontNames: string[]) => AsyncResponse;
  replace?: boolean;
};

function CreateFontModal({ onPickFontFiles, onPickGoogleFont, replace = false }: Props) {
  const { dismissModal } = useShowModal();
  const [error, setError] = useState<string>(null),
    [processing, setProcessing] = useState<boolean>(false),
    [fontFamily, setFontFamily] = useState<Font>(null),
    [fontNames, setFontNames] = useState<string[]>([]),
    [query, setQuery] = useState<string>(""),
    scrollRef = useRef<HTMLDivElement>();
  const {
    data: { fonts: allFonts = [] } = {},
    isLoading: loading,
    error: googleFontError,
  } = useGoogleFonts({ query });

  const onSelectFontFamily = useCallback(font => {
    setFontFamily(font);
    if (scrollRef.current && scrollRef.current.scrollTo) scrollRef.current.scrollTo(0, 0);
  }, []);

  const onUnselectFontFamily = useCallback(() => {
    setFontFamily(null);
    setFontNames([]);
  }, []);

  const toggleFontName = fontName => {
    if (replace) {
      setFontNames([fontName]);
      return;
    }

    const newFontNames = fontNames.filter(f => f !== fontName);
    if (fontNames.length !== newFontNames.length) setFontNames(newFontNames);
    else setFontNames([...fontNames, fontName]);
  };

  const onPickFiles = useCallback(
    files => {
      dismissModal();
      return onPickFontFiles(files);
    },
    [dismissModal, onPickFontFiles]
  );

  const _onPickFont = useCallback(() => {
    setProcessing(true);
    return onPickGoogleFont(fontNames).then(response => {
      setProcessing(false);
      if (response?.error?.message) {
        setError(response.error.message);
      } else dismissModal(ModalTypes.CREATE_FONT);
    });
  }, [dismissModal, fontNames, onPickGoogleFont]);

  function renderModalInterstitial() {
    return (
      <Fragment>
        <Flex
          px="xxl"
          py="l"
          background="grayLightest"
          borderBottom="1px solid grayLight"
          justifyContent="space-between"
          flex="0 0 auto">
          {fontFamily ? (
            <Fragment>
              <Text font="ui.regular" height="35px" lineHeight="35px">
                <Text as="span" font="ui.regularBold">
                  Font:
                </Text>{" "}
                {fontFamily.family}
              </Text>
              <Button
                buttonStyle="tertiary"
                size="small"
                text="Change"
                onClick={onUnselectFontFamily}
              />
            </Fragment>
          ) : (
            <SearchField
              background="white"
              placeholder="Filter"
              fetchSuggestions={setQuery}
              submitSearch={setQuery}
              clearSearch={() => setQuery("")}
              autoFocus
            />
          )}
        </Flex>
        {error ||
          (googleFontError && (
            <Notice
              message={error || googleFontError.message}
              noticeStyle="error"
              mx="xxl"
              mt="m"
              mb="m"
              flex="0 0 auto"
            />
          ))}
      </Fragment>
    );
  }

  function renderModalBody() {
    if (!allFonts.length) {
      return (
        <EmptyState
          iconProps={{
            iconId: "search",
            size: "60",
            fill: "grayDark",
            flex: "0 0 auto",
          }}
          title="No Results"
          styleOverrides={{ my: "0", py: "0", justifySelf: "stretch" }}
        />
      );
    } else if (fontFamily) {
      return fontFamily.variants.map(font => (
        <OptionCard
          key={font.font_name}
          title=""
          detail={font.display_name}
          cardStyle="checkbox"
          selected={fontNames.includes(font.font_name)}
          onClick={() => toggleFontName(font.font_name)}
          size="small"
          flex="0 0 auto"
        />
      ));
    } else {
      return allFonts.map(font => (
        <FontCard key={font.family} font={font} setFont={onSelectFontFamily} />
      ));
    }
  }

  return (
    <Fragment>
      <ModalHeader title="Add a Font" styleOverrides={{ borderBottom: "none" }}>
        <TabBar separator styleOverrides={{ mt: "m" }}>
          <TabBar.Item text="Google Font" selected={true} />
          <FilePicker onPickFiles={onPickFiles} fileExtensions={fontFileExtensions} multiple>
            {({ openNativeFilePicker }) => (
              <TabBar.Item text="Choose File" selected={false} onClick={openNativeFilePicker} />
            )}
          </FilePicker>
        </TabBar>
      </ModalHeader>
      {renderModalInterstitial()}
      <ModalBody pt="m" ref={scrollRef} flex="0 1 100%">
        {loading ? (
          <Box height="150">
            <ActivityIndicator center />
          </Box>
        ) : (
          renderModalBody()
        )}
      </ModalBody>
      {fontFamily && (
        <ModalFooter
          primary={{
            text: processing ? "Saving..." : replace ? "Replace font" : "Add font",
            onClick: _onPickFont,
            disabled: fontNames.length === 0 || processing,
          }}
        />
      )}
    </Fragment>
  );
}

export default CreateFontModal;
