import React from "react";
import { Box, ActivityIndicator, Input, Button, EmptyState } from "@thenounproject/lingo-core";

import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";

import { validateFigmaLink } from "@helpers/figma";
import { type InsertPosition } from "@actions/uploads";

import useCreateAssetsFromSource, {
  AssetSourceVendors,
} from "@redux/actions/items/useCreateAssetsFromSource";
import { useSelectSpace } from "@selectors/entities/spaces";
import useIdentifyFigmaType from "@actions/figma/useIdentifyFigmaType";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

const strings = {
  label: "Share link",
  linkError: "Invalid Figma link",
  genericError: "Something went wrong",
  helper: "You can use a link to a frame, figma file or a specific page in a figma file",
  placeholder: "Paste a share link to a Figma file here",
  title: "Import Figma Assets",
  button: "Continue",
};

type Props = {
  insertPosition: InsertPosition;
  preloadedState?: { url?: string };
};

const FigmaLinkModal = ({ insertPosition, preloadedState }: Props) => {
  const { showModal, dismissModal } = useShowModal();
  const space = useSelectSpace();
  const [error, setError] = React.useState(""),
    [loading, setLoading] = React.useState(false),
    [notSetup, setNotSetup] = React.useState(false),
    [url, setUrl] = React.useState(preloadedState?.url || ""),
    invalidLink = Boolean(url && !validateFigmaLink(url));

  const [createAssetsFromSource] = useCreateAssetsFromSource();
  const [identifyFigmaType] = useIdentifyFigmaType();

  const isButtonDisabled = React.useMemo(() => {
    if (loading) return true;
    if (!url) return true;
    if (invalidLink) return true;
    return false;
  }, [url, invalidLink, loading]);

  const inputHasError = React.useMemo(() => {
    if (error) return true;
    if (invalidLink) return true;
    return false;
  }, [error, invalidLink]);

  const inputMessage = React.useMemo(() => {
    if (invalidLink) return strings.linkError;
    if (error) return error;
    return strings.helper;
  }, [error, invalidLink]);

  // MARK : Handlers
  // -------------------------------------------------------------------------------

  const onSelectContinue = React.useCallback(async () => {
    if (invalidLink || loading) return;

    try {
      setLoading(true);
      const figmaTypeResponse = await identifyFigmaType({ url, spaceId: space.id });
      if (figmaTypeResponse.error) {
        setLoading(false);
        if (figmaTypeResponse.error.code === 10404) return setNotSetup(true);
        setError(figmaTypeResponse.error.message);
        return;
      } else {
        dismissModal();
        if (figmaTypeResponse.response.result.importable) {
          return createAssetsFromSource({
            sources: [
              {
                source: "figma" as AssetSourceVendors.Figma,
                url,
                name: figmaTypeResponse.response.result.name || "Figma asset",
              },
            ],
            insertPosition,
          });
        } else {
          return showModal(ModalTypes.FIGMA_FRAME_PICKER_MODAL, { url, insertPosition });
        }
      }
    } catch {
      setLoading(false);
      setError(strings.genericError);
    }
  }, [
    invalidLink,
    loading,
    identifyFigmaType,
    url,
    space.id,
    dismissModal,
    createAssetsFromSource,
    insertPosition,
    showModal,
  ]);

  // MARK : Effects
  // -------------------------------------------------------------------------------

  React.useEffect(() => {
    if (preloadedState?.url && !notSetup) {
      void onSelectContinue();
    }
  }, [notSetup, onSelectContinue, preloadedState]);

  // MARK : Render
  // -------------------------------------------------------------------------------

  function renderLoadingState() {
    return (
      <Box height="84">
        <ActivityIndicator center />
      </Box>
    );
  }

  function renderSubmitForm() {
    return (
      <Input
        id="figma-link-modal-input"
        autoFocus
        value={url}
        label={strings.label}
        placeholder={strings.placeholder}
        message={inputMessage}
        inputStyle={inputHasError ? "error" : undefined}
        onChange={e => {
          setError("");
          setUrl(e.target.value);
        }}
        onSubmit={onSelectContinue}
      />
    );
  }

  function renderModalBody() {
    if (loading) return renderLoadingState();
    return renderSubmitForm();
  }

  return (
    <React.Fragment>
      <ModalHeader title={strings.title} />
      {notSetup ? (
        <EmptyState
          subtitle="Your Figma integration hasn’t been set up yet"
          iconProps={{ iconId: "info.figma", size: "60", fill: "gray" }}
          extra={
            <Button
              mt="m"
              buttonStyle="tertiary"
              text="Set it up now"
              onClick={() => showModal(ModalTypes.FIGMA_SETUP, { spaceId: space.id })}
            />
          }
          styleOverrides={{ pt: "s", pb: "xl" }}
        />
      ) : (
        <>
          <ModalBody>{renderModalBody()}</ModalBody>
          <ModalFooter
            primary={{
              text: strings.button,
              onClick: onSelectContinue,
              disabled: isButtonDisabled,
            }}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default FigmaLinkModal;
