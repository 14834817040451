import React from "react";

import { BoxProps, Button, ButtonProps, Flex, ImageView, Text } from "@thenounproject/lingo-core";
import { Container, Inner } from "../Elements";
import MarketingText from "../MarketingText";

type Props = {
  title: string;
  subtitle: string;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  image: { src: string; alt: string };
  layoutDirection?: "vertical" | "horizontal";
  style?: "light" | "dark" | "darkGradient";
  position?: BoxProps;
};

const HeroSection: React.FC<Props> = ({
  title,
  subtitle,
  image,
  primaryButton,
  secondaryButton,
  layoutDirection = "horizontal",
  style = "light",
  position = {},
}) => {
  const isDark = style?.startsWith("dark");
  const textColor = isDark ? "white" : "black";
  const textAlign = layoutDirection === "vertical" ? "center" : "left";
  const flexDirection = layoutDirection === "vertical" ? "column" : "row";

  function renderButtons() {
    if (primaryButton || secondaryButton) {
      const outlineStyle = isDark ? "dark" : "light";

      return (
        <Flex mt="xl" justifyContent={flexDirection} gap="16px">
          {primaryButton && (
            <Button
              themeOverrides={
                isDark
                  ? {
                      primaryColor: "white",
                      primaryColorDark: "gray",
                      primaryColorTint: "black",
                    }
                  : null
              }
              buttonStyle={"primary"}
              {...primaryButton}
            />
          )}
          {secondaryButton && (
            <Button themeOverrides={{ outlineStyle }} buttonStyle="outline" {...secondaryButton} />
          )}
        </Flex>
      );
    }
  }

  let backgroundColor = null;
  // 72 is derived from HEADER_HEIGHT in Header.tsx
  if (style === "darkGradient") {
    backgroundColor = "linear-gradient(103deg, #4349DC 18.01%, #181C85 89.28%);";
    position.mt = "-72px";
    position.pt = 80 + 72;
  } else if (style === "dark") {
    backgroundColor = "primary";
    position.mt = "-72px";
    position.pt = 80 + 72;
  }
  return (
    <Container
      background={backgroundColor}
      mt="none"
      my="none"
      pt="xxl"
      pb={layoutDirection === "vertical" ? "none" : "xxl"}
      {...position}>
      <Inner
        mt="none"
        flexDirection={flexDirection}
        gap="80px"
        alignItems="center"
        variations={{
          "mq.m": { flexDirection: "column" },
        }}>
        <Flex
          flexDirection="column"
          flex="1"
          alignItems="center"
          textAlign={"center"}
          maxWidth="960"
          variations={{
            "mq.m_up": {
              textAlign,
              maxWidth: layoutDirection === "horizontal" ? 540 : 960,
              alignItems: layoutDirection === "vertical" ? "center" : "flex-start",
            },
          }}>
          <Text as="h1" font="marketing.h1" color={textColor}>
            {title}
          </Text>
          {subtitle ? (
            <MarketingText mt="l" font="marketing.bodyLarge" color={textColor}>
              {subtitle}
            </MarketingText>
          ) : null}
          {renderButtons()}
        </Flex>

        <ImageView
          width={layoutDirection === "vertical" ? "100%" : null}
          variations={{
            "mq.m": {
              width: "100%",
            },
          }}
          aspectRatio={layoutDirection === "vertical" ? 42 : 66}
          flex="1"
          {...image}
        />
      </Inner>
    </Container>
  );
};

export default HeroSection;
