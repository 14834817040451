import React, { useState, Fragment, useCallback } from "react";
import {
  Flex,
  Text,
  Textarea,
  Notice,
  Checkbox,
  formatDate,
  DownloadRequest,
} from "@thenounproject/lingo-core";

import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";
import ModalFooter from "../../../ModalFooter";

import { useSelectSpace } from "@selectors/entities/spaces";
import useModals from "@redux/actions/useModals";
import useUpdateDownloadRequest from "@redux/actions/downloadRequests/updateDownloadRequests";
import useNotifications from "@redux/actions/useNotifications";

type Props = {
  request: DownloadRequest;
};

function SpaceRequestDenyModal({ request }: Props) {
  const space = useSelectSpace();
  const { showNotification } = useNotifications(),
    { dismissModal } = useModals();
  const [message, setMessage] = useState<string>(""),
    [sendEmail, setSendEmail] = useState<boolean>(false);
  const [updateRequest, { error, isProcessing }] = useUpdateDownloadRequest();

  const handleDeny = useCallback(async () => {
    const res = await updateRequest({
      id: request.id,
      status: "denied",
      expiresAt: null,
      spaceId: space.id,
      sendEmail,
      message,
    });
    if (res.isSuccess) {
      showNotification({ message: `Request denied` });
      dismissModal();
    }
  }, [dismissModal, message, request.id, sendEmail, showNotification, space.id, updateRequest]);

  return (
    <Fragment>
      <ModalHeader title="Deny Request" />
      <ModalBody>
        <Flex flexDirection="column" gap="24px">
          <Text>
            Deny the request made by <strong>{request.email}</strong> on{" "}
            <strong>{formatDate(request.dateRequested, "MMM do, yyyy")}</strong>
          </Text>
          <Textarea
            label="Reason for Denying Access (optional)"
            value={message}
            onChange={e => setMessage(e.target.value)}
            placeholder="Your request for access has been denied... "
          />
          <Checkbox
            isSelected={sendEmail}
            onClick={() => setSendEmail(!sendEmail)}
            label={
              <Text font="ui.small">
                Email your response to <strong>{request.email}</strong>
              </Text>
            }
          />
        </Flex>

        {error && <Notice noticeStyle="error" mt="m" message={error.message} />}
      </ModalBody>
      <ModalFooter
        primary={{
          text: "Deny",
          disabled: isProcessing,
          onClick: handleDeny,
        }}
      />
    </Fragment>
  );
}

export default React.memo(SpaceRequestDenyModal);
