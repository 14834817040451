import { merge, noop, cloneDeep, isEqual } from "lodash";
import React from "react";
import {
  Kit,
  Portal,
  Space,
  SpaceTheme,
  ThemeHeaderTitleOptions,
  SpaceThemeNames,
  utils,
} from "@thenounproject/lingo-core";
import { NewFiles } from "@features/theming/types";

const { getColor } = utils;

export type ThemeDataContextType = {
  theme: SpaceTheme;
  editingTheme: SpaceTheme;
  existingTheme: SpaceTheme;
  updateEditingTheme: (theme: Partial<SpaceTheme>) => void;
  endEditing: () => void;
  themeChanged: boolean;
  newFiles: NewFiles;
  updateFiles: (data: Partial<NewFiles>) => void;
  filesChanged: boolean;
};

export const DEFAULT_THEME: SpaceTheme = {
  primaryColor: getColor("primary"),
  primaryColorDark: getColor("primaryDark"),
  primaryColorLight: getColor("primaryLight"),
  primaryColorTint: getColor("white"),
  themeName: SpaceThemeNames.classic,
  headerBackgroundColor: getColor("grayLightest"),
  headerBackgroundImage: null,
  headerTitleStyle: ThemeHeaderTitleOptions.name,
  headerTitleColor: getColor("black"),
  headerDescriptionColor: getColor("black"),
  headerLogoImage: null,
  active: false,
  noteSuccessColor: getColor("noteSuccess"),
  noteInfoColor: getColor("noteInfo"),
  noteWarningColor: getColor("noteWarning"),
  assetLightColor: getColor("grayLighter"),
  assetDarkColor: getColor("grayDark"),
};

export const ThemeDataContext = React.createContext<ThemeDataContextType>({
  theme: cloneDeep(DEFAULT_THEME),
  existingTheme: null,
  editingTheme: null,
  updateEditingTheme: noop,
  endEditing: noop,
  themeChanged: false,
  newFiles: null,
  updateFiles: noop,
  filesChanged: false,
});

ThemeDataContext.displayName = "ThemeData";

//Remove null keys except for image options
function removeNullKeys(obj: Partial<SpaceTheme>) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && !["headerBackgroundImage", "headerLogoImage"].includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {});
}

export function getTheme(
  space: Pick<Space, "theme"> & { features?: string[] },
  portal?: Pick<Portal, "theme">,
  kit?: Kit
) {
  let spaceTheme = {};
  if (space?.theme?.active) {
    // Unset space level header data if portals are enabled
    // Maybe we will do this as part of the API migration
    spaceTheme = space.features?.includes("portals")
      ? {
          ...space.theme,
          description: "A description of your portal.",
          headerBackgroundColor: "#FAFAFA",
          headerBackgroundImage: null,
          headerTitleStyle: null,
          headerTitleColor: null,
          headerDescriptionColor: null,
          headerLogoImage: null,
          active: true,
        }
      : space.theme;
  }

  if (portal) {
    const portalTheme = {
      description: "",
      headerBackgroundColor: DEFAULT_THEME.headerBackgroundColor,
      headerBackgroundImage: null,
      headerTitleStyle: ThemeHeaderTitleOptions.name,
      headerTitleColor: DEFAULT_THEME.headerTitleColor,
      headerDescriptionColor: DEFAULT_THEME.headerDescriptionColor,
      headerLogoImage: null,
      ...portal.theme,
    };
    removeNullKeys(portalTheme);

    return merge({}, DEFAULT_THEME, spaceTheme, portalTheme);
  } else if (kit?.theme?.active) {
    return merge({}, DEFAULT_THEME, spaceTheme, kit.theme || {});
  } else {
    return merge({}, DEFAULT_THEME, spaceTheme);
  }
}

/**
 * A helper function to get the overrides to save to a portal or kit.
 * Returns only the values that have changed from the base theme.
 */
export function getThemeOverrides(newTheme: Partial<SpaceTheme>, baseTheme: Partial<SpaceTheme>) {
  return {
    noteSuccessColor:
      newTheme.noteSuccessColor !== baseTheme.noteSuccessColor ? newTheme.noteSuccessColor : null,
    noteInfoColor:
      newTheme.noteInfoColor !== baseTheme.noteInfoColor ? newTheme.noteInfoColor : null,
    noteWarningColor:
      newTheme.noteWarningColor !== baseTheme.noteWarningColor ? newTheme.noteWarningColor : null,
    assetLightColor:
      newTheme.assetLightColor !== baseTheme.assetLightColor ? newTheme.assetLightColor : null,
    assetDarkColor:
      newTheme.assetDarkColor !== baseTheme.assetDarkColor ? newTheme.assetDarkColor : null,
    primaryColor: newTheme.primaryColor !== baseTheme.primaryColor ? newTheme.primaryColor : null,
    themeName: newTheme.themeName !== baseTheme.themeName ? newTheme.themeName : null,
    fonts: !isEqual(newTheme.fonts, baseTheme.fonts) ? newTheme.fonts : null,
  };
}
