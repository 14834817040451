import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  spaceId: number;
  name: string;
  description?: string;
  coverImage?: File;
  removeCoverImage?: boolean;
};

const [useUpdateKit, updateKit] = createAsyncAction("kits/update", async (args: Args) => {
  let formData: FormData,
    data = {
      name: args.name,
      description: args.description,
      cover_reset: false,
    };
  if (args.removeCoverImage) {
    data.cover_reset = true;
  }
  if (args.coverImage) {
    formData = new FormData();
    formData.append("cover", args.coverImage);
    formData.append("json", JSON.stringify(data));
    data = undefined;
  }
  return await API.call<string>({
    endpoint: `kits/${args.spaceId}/${args.kitId}`,
    method: "PUT",
    formData,
    data,
    entity: API.Entity.kit,
  });
});

export default useUpdateKit;
export { updateKit };
