import React from "react";
import styled from "styled-components";

import { Flex, BoxProps } from "@thenounproject/lingo-core";

import { Container, Inner } from "../Elements";
import content from "../../data/customerLogos";

const Logos = styled(Flex).attrs({
  width: "100%",
  justifyContent: "center",
  gap: "48px",
  flexWrap: "wrap",
})`
  img {
    width: 100%;
  }
`;

type Props = {
  position?: BoxProps;
};

const CustomerLogosSection: React.FC<Props> = ({ position }) => {
  return (
    <Container {...position}>
      <Inner
        first
        flexWrap="wrap"
        background="white"
        borderRadius="8px"
        boxShadow="panel"
        p="xl"
        px="xl">
        <Logos>
          {content.brands.map((b, i) => (
            <Flex
              key={i}
              px="m"
              mb="s"
              height={60}
              width={120}
              variations={{
                "mq.l": {
                  display: i >= 8 ? "none !important" : "flex",
                },
                "mq.m": {
                  display: i >= 6 ? "none !important" : "flex",
                },
              }}>
              <img src={b.image as unknown as string} alt={b.alt} />
            </Flex>
          ))}
        </Logos>
      </Inner>
    </Container>
  );
};

export default CustomerLogosSection;
