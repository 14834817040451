import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useMigrateSpace, migrateSpace] = createAsyncAction(
  "spaces/createSpace",
  async (spaceId: number) => {
    return await API.call<number>({
      endpoint: `spaces/${spaceId}/migrate`,
      method: "POST",
      entity: API.Entity.space,
    });
  }
);

export default useMigrateSpace;
export { migrateSpace };
