import React, { useCallback, useState } from "react";
import styled from "styled-components";

import FilePicker from "../../FilePicker";
import { Text, Button, Flex, Icon, Notice, Asset } from "@thenounproject/lingo-core";
import ModalBody from "../../ModalBody";
import useUpdateAssetThumbnail from "@redux/actions/assets/useUpdateAssetThumbnail";
import useShowModal from "@redux/actions/useModals";

const DropZone = styled(Flex).attrs({
  position: "relative",
  border: "grayDashed",
  borderRadius: "default",
  p: "xxl",
  flexDirection: "column",
  alignItems: "center",
  mx: "auto",
})``;

const FileError = styled(Notice).attrs({
  noticeStyle: "error",
  mt: "m",
  mb: "-m",
})`
  opacity: ${props => (props.message ? null : 0)};
`;

type Props = {
  asset: Asset;
};

const thumbnailFileTypes = ["image/jpg", "image/jpeg", "image/png"];

const EditAssetThumbnailModal = ({ asset }: Props) => {
  const { dismissModal } = useShowModal();
  const [error, setError] = useState("");
  const [updateAssetThumbnail] = useUpdateAssetThumbnail();

  const onSelectThumbnail = useCallback(
    (files: File[]) => {
      function isValidFile(file: File) {
        if (!file) return false;
        return thumbnailFileTypes.includes(file.type);
      }
      if (files.length > 1) {
        setError("You can only upload one thumbnail at a time.");
      } else if (!files.some(isValidFile)) {
        setError("The thumbnail must be a PNG or JPG.");
      } else {
        dismissModal();
        return updateAssetThumbnail({ assetId: asset.id, data: files[0] });
      }
    },
    [dismissModal, asset, updateAssetThumbnail]
  );

  return (
    <ModalBody pt="xxl" pb="xl">
      <FilePicker onPickFiles={onSelectThumbnail} fileExtensions={["PNG", "JPG"]} multiple={false}>
        {({ openNativeFilePicker, dropZoneProps }) => (
          <DropZone {...dropZoneProps}>
            <Icon iconId="empty-state.assets" fill="gray" size="120" flex="1 1 auto" />
            <Text>Drag & drop your new file here or click below</Text>
            <Button mt="m" flex="0 0 auto" text="Choose file" onClick={openNativeFilePicker} />
          </DropZone>
        )}
      </FilePicker>
      <FileError message={error} />
    </ModalBody>
  );
};

export default EditAssetThumbnailModal;
