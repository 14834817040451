import { createAsyncAction } from "../actionCreators";
import { API } from "@thenounproject/lingo-core";

type Args = {
  spaceId: number;
};

const [useCancelSubscription, cancelSubscription] = createAsyncAction(
  "billing/cancelSubscription",
  async ({ spaceId }: Args) => {
    return await API.call<string>({
      endpoint: `spaces/${spaceId}/plan/`,
      method: "DELETE",
      data: {
        space_id: spaceId,
      },
      entity: API.Entity.space,
    });
  }
);

export default useCancelSubscription;
export { cancelSubscription };
