import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  directLinkId: number;
};

export type TopRegionsResult = {
  country: string;
  city: string;
  totalBandwidth: number;
  totalRequests: number;
};

type TopRegionsData = {
  rows: TopRegionsResult[];
  total: number;
};

const [useTopRegions, fetchTopRegions] = createQueryAction<Args, TopRegionsData>(
  {
    entity: "analytics",
    action: "queryTopRegions",
    denormalize: false,
    pagingKey: "rows",
  },
  async ({ args, paging }) => {
    const reports = {
      asset_usage: {
        id: "direct_link_geography",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        direct_link_id: args.directLinkId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ asset_usage: TopRegionsData }>(args.spaceId, reports))
      .assetUsage;
  }
);

export default useTopRegions;
export { fetchTopRegions };
