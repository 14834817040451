import React, { MouseEvent, useMemo } from "react";
import styled from "styled-components";
import { Flex, Box, AssetType, Button } from "@thenounproject/lingo-core";
import { Inspectable } from "@constants/Inspector";
import FontSample from "../FontSample";
import AssetDownloadPopup, {
  AssetDownloadPopupRenderProps,
} from "../../inspector/use/AssetDownloadPopup";
import ColorAssetCopyPopup, { ColorCopyRenderProps } from "../../inspector/use/ColorAssetCopyPopup";
import ColorThumbnail from "./GalleryAssetThumbnail/ColorThumbnail";
import ImageThumbnail from "./GalleryAssetThumbnail/ImageThumbnail";
import { getAssetError } from "@helpers/getAssetError";
import GalleryAssetErrorButton from "../GalleryAssetErrorButton";
import LinkMeta from "./GalleryAssetMeta/LinkMeta";
import DefaultMeta from "./GalleryAssetMeta/DefaultMeta";

type Props = {
  inspectable: Inspectable;
  selected: boolean;
  canEdit: boolean;
};

const AssetWrapper = styled(Flex).attrs({
  width: "100%",
  pb: "94px",
  position: "relative",
  borderRadius: "2px",
  overflow: "hidden",
})`
  .action-button {
    opacity: 0;
  }

  &:hover .action-button {
    opacity: 1;
  }
`;

type AssetWrapperInnerProps = {
  selected: boolean;
};

const AssetWrapperInner = styled(Flex).attrs<AssetWrapperInnerProps>(props => {
  return {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    border: props.selected ? `2px solid ${props.theme.primaryColor}` : "2px solid #EEEFF0",
    borderRadius: "default",
    alignItems: "center",
    p: "16px",
    gap: "16px",
    overflow: "hidden",
  };
})<AssetWrapperInnerProps>``;

const DownloadWrapper = styled(Flex).attrs({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  right: 16,
  zIndex: 10,
})``;

function GalleryAssetContentFull({ inspectable, selected, canEdit }: Props) {
  const { item, asset } = inspectable;

  const thumbnailElement = useMemo(() => {
    switch (asset?.type) {
      case AssetType.textStyle: {
        return <FontSample key="font-thumb" asset={asset} isThumbnail={false} fontSize={"32px"} />;
      }
      case AssetType.color: {
        return <ColorThumbnail key="color-thumb" asset={asset} />;
      }
      default: {
        return <ImageThumbnail key="image-thumb" asset={asset} item={item} />;
      }
    }
  }, [asset, item]);

  const metaElement = useMemo(() => {
    switch (inspectable.asset?.type) {
      case AssetType.textStyle: {
        return null;
      }
      case AssetType.URL: {
        return <LinkMeta key="meta" inspectable={inspectable} />;
      }
      default: {
        return <DefaultMeta key="meta" inspectable={inspectable} />;
      }
    }
  }, [inspectable]);

  const popupElement = useMemo(() => {
    const error = getAssetError(item?.asset);

    const assetErrorButtonProps = {
      itemId: item.id,
      assetId: item.asset.id,
      error,
      compact: false,
      inline: true,
      canEdit,
    };

    if (error)
      return (
        <Box>
          <GalleryAssetErrorButton {...assetErrorButtonProps} />
        </Box>
      );

    const sharedButtonProps = {
      className: "action-button",
      size: "small" as const,
      themeOverrides: {
        primaryColor: "black",
        primaryColorDark: "black",
        primaryColorTint: "white",
      },
    };
    function renderAssetDownloadButtonElement(
      buttonProps: AssetDownloadPopupRenderProps["buttonProps"]
    ) {
      const assetButtonProps = {
        id: "asset-download-button",
        icon: "action.download",
        onClick: (e: MouseEvent) => {
          if (e) e.stopPropagation();
          buttonProps.onClick(e);
        },
        ...buttonProps,
        ...sharedButtonProps,
      };
      return <Button {...assetButtonProps} />;
    }

    function renderColorCopyButtonElement(buttonProps: ColorCopyRenderProps) {
      const sharedProps = {
        id: "color-copy-button",
        icon: "action.copy",
        onClick: (e: MouseEvent) => {
          if (e) e.stopPropagation();
          buttonProps.onClick(e);
        },
        ...buttonProps,
        ...sharedButtonProps,
      };
      return <Button {...sharedProps} />;
    }

    const popupProps = {
      inspectable: { item, asset: item.asset },
      popupVPos: "alignTop" as const,
      popupHPos: "floatLeft" as const,
    };
    if (inspectable.asset.type === AssetType.color) {
      return (
        <ColorAssetCopyPopup {...popupProps}>
          {buttonProps => renderColorCopyButtonElement(buttonProps)}
        </ColorAssetCopyPopup>
      );
    }
    if (inspectable.asset.type === AssetType.URL) {
      return (
        <Button
          icon="action.external-link"
          onClick={(e: MouseEvent) => {
            const url = inspectable.asset.meta.content?.url;
            if (e) e.stopPropagation();
            if (url) window.open(url, "_blank");
          }}
          {...sharedButtonProps}
        />
      );
    }

    return (
      <AssetDownloadPopup {...popupProps}>
        {({ buttonProps }) => renderAssetDownloadButtonElement(buttonProps)}
      </AssetDownloadPopup>
    );
  }, [canEdit, inspectable.asset.meta.content?.url, inspectable.asset.type, item]);

  const inlineElements = [thumbnailElement, metaElement].filter(Boolean);

  return (
    <AssetWrapper data-testid="gallery-asset-content-full">
      <AssetWrapperInner selected={selected} background="grayLightest">
        {inlineElements}
      </AssetWrapperInner>
      <DownloadWrapper>{popupElement}</DownloadWrapper>
    </AssetWrapper>
  );
}

export default React.memo(GalleryAssetContentFull);
