import React from "react";
import _get from "lodash/get";
import { Box, Table } from "@thenounproject/lingo-core";
import styled from "styled-components";

import ReportTitle from "../report/ReportTitle";
import { InfiniteTableProps } from "./types";
import { useAssetLibraryContext } from "@contexts/AssetLibraryProvider";

const TableContainer = styled(Box).attrs({
  borderTop: "default",
  pb: "100",
})``;

export const dataTableRowHeights = { small: 40, medium: 80, large: 136 };

function DataTable<DataType>({
  columns,
  data,
  isLoading,
  hasMorePages,
  sort,
  onSort,
  reportTitleProps,
  fetchNextPage,
  onRowClick,
  emptyState,
  selectionProps,
  tableError,
  renderContextMenuItems,
  isLibraryView = false,
}: InfiniteTableProps<DataType>) {
  const { assetRowSize } = useAssetLibraryContext();

  return (
    <TableContainer>
      {Boolean(reportTitleProps) && (
        <ReportTitle {...reportTitleProps} styleOverrides={{ px: "40px" }} />
      )}
      <div style={{ position: "relative", width: "100%" }}>
        <Table
          rowSize={isLibraryView ? assetRowSize : "medium"}
          hasMorePages={hasMorePages}
          tableData={{ data, isLoading, error: tableError, fetchNextPage }}
          onRowClick={onRowClick}
          columns={columns}
          checkboxProps={selectionProps}
          renderContextMenuItems={renderContextMenuItems}
          sort={sort}
          onSort={onSort}
          emptyState={
            emptyState ?? { subtitle: "There is nothing to show for the current period." }
          }
        />
      </div>
    </TableContainer>
  );
}

export default React.memo(DataTable) as typeof DataTable;
