import React, { useCallback, useState, Fragment } from "react";
import { Input, KitVersion, Notice, Textarea } from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import useNotifications from "@actions/useNotifications";
import useShowModal from "@redux/actions/useModals";
import useUpdateKitVersion from "@redux/actions/kitVersions/useUpdateKitVersion";

type Props = {
  kitVersion: Pick<KitVersion, "kitId" | "version" | "versionIdentifier" | "notes">;
};

const EditKitVersionModal: React.FC<Props> = ({ kitVersion }) => {
  const { showNotification } = useNotifications();
  const { dismissModal } = useShowModal();
  const [updateKitVersion, { error, isProcessing }] = useUpdateKitVersion();

  const [nameError, setNameError] = useState<string>(null),
    [newName, setNewName] = useState(kitVersion.versionIdentifier || ""),
    [newNotes, setNewNotes] = useState(kitVersion.notes || "");

  const submit = useCallback(async () => {
    if (newName.trim().length === 0) {
      setNameError("That is not a valid name.");
      return;
    }

    setNameError(null);

    const result = await updateKitVersion({
      kitId: kitVersion.kitId,
      version: kitVersion.version,
      name: newName,
      notes: newNotes,
    });
    if (result.isSuccess) {
      showNotification({ message: "Version info updated.", level: "info" });
      dismissModal();
    }
  }, [
    dismissModal,
    updateKitVersion,
    kitVersion.kitId,
    kitVersion.version,
    newName,
    newNotes,
    showNotification,
  ]);

  return (
    <Fragment>
      <ModalHeader title="Edit version info" />
      <ModalBody>
        <Input
          id="version-name-input"
          styleOverrides={{ mb: "m" }}
          label="Version name"
          value={newName}
          placeholder="v 1.0.0"
          onChange={e => setNewName(e.target.value)}
          name="name"
          message={nameError}
          inputStyle={nameError ? "error" : null}
        />
        <Textarea
          mt="m"
          label="Release notes"
          onChange={e => setNewNotes(e.target.value)}
          value={newNotes}
          placeholder="Here’s what changed in this version..."
        />

        {error ? (
          <Notice textAlign="left" mt="l" noticeStyle="error" message={error?.message} />
        ) : null}
      </ModalBody>
      <ModalFooter
        primary={{
          id: "edit-version-submit-button",
          text: isProcessing ? "Saving..." : "Save",
          onClick: submit,
          disabled: isProcessing,
        }}
      />
    </Fragment>
  );
};

export default EditKitVersionModal;
