import { AnyObject, API } from "@thenounproject/lingo-core";
import { MotionAssetPreview } from "./useFetchMotionAssetThumbnails";
import { createAsyncAction } from "../actionCreators";

type Args = {
  assetId: string;
  thumbnail: File | MotionAssetPreview;
};

const isFile = (obj: unknown): obj is File => {
  const f = obj as File;
  return (
    obj instanceof File &&
    f.name !== undefined &&
    f.size !== undefined &&
    f.type !== undefined &&
    f.stream !== undefined
  );
};

const [useUpdateMotionAssetThumbnails, updateMotionAssetThumbnails] = createAsyncAction(
  "assets/updateMotionAssetThumbnails",
  async ({ assetId, thumbnail }: Args) => {
    //use FormData if uploading custom thumbnail
    let imageData: AnyObject;
    if (isFile(thumbnail)) {
      const type = thumbnail.type.split("/")[1].toUpperCase();
      const formData = new FormData();
      formData.append("asset", thumbnail as File);
      formData.append("json", JSON.stringify({ type }));
      imageData = { formData };
    } else {
      imageData = { data: { filename: (thumbnail as { filename: string }).filename } };
    }
    const response = await API.call<string>({
      endpoint: `/assets/${assetId}/preview`,
      method: "PUT",
      entity: API.Entity.asset,
      ...imageData,
    });
    return response;
  }
);
export default useUpdateMotionAssetThumbnails;
export { updateMotionAssetThumbnails };
