import React from "react";
import { TabBar, Space } from "@thenounproject/lingo-core";

import ModalHeader from "../../ModalHeader";
import { UserTableResult } from "../queries/useRecentUserActivity";
import InsightsUserViewTab from "./InsightsUserViewsTab";
import InsightsUserAssetsTab from "./InsightsUserAssetsTab";
import { InsightsPageWrapper } from "../ReportElements";

type Props = {
  user: UserTableResult;
  space: Space;
};

enum Tab {
  views = "views",
  assets = "assets",
}

const InsightsUserModal = ({ user, space }: Props) => {
  const [selectedTab, setSelectedTab] = React.useState<string>(Tab.views);

  function renderTab() {
    switch (selectedTab) {
      case Tab.views:
        return <InsightsUserViewTab user={user} space={space} />;
      case Tab.assets:
        return <InsightsUserAssetsTab user={user} space={space} />;
      default:
        return null;
    }
  }

  return (
    <>
      <ModalHeader title={user.name || "User"}>
        <TabBar>
          <TabBar.Item
            text="Views"
            selected={selectedTab === Tab.views}
            onClick={() => setSelectedTab(Tab.views)}
          />
          <TabBar.Item
            text="Assets"
            selected={selectedTab === Tab.assets}
            onClick={() => setSelectedTab(Tab.assets)}
          />
        </TabBar>
      </ModalHeader>
      <InsightsPageWrapper>{renderTab()}</InsightsPageWrapper>
    </>
  );
};

export default InsightsUserModal;
