import { AssetType, Asset, utils, SpaceTheme } from "@thenounproject/lingo-core";
import transparentBackground from "../images/web-access/pattern@2x.png";
import { TinyColor } from "@ctrl/tinycolor";

export default function getAssetBackground(asset: Asset, theme: SpaceTheme) {
  if (!asset || AssetType.textStyle === asset.type) return { background: "grayLighter" };
  if (AssetType.color === asset.type) {
    const color = asset.colors?.[0],
      c = new TinyColor(
        `hsva(${color.hue}, ${color.saturation}%, ${color.brightness}%, ${color.alpha / 100})`
      );
    return { background: asset.colors?.[0]?.hex8 || c.toRgbString() };
  }
  const activeCustomTheme = theme?.active;
  const darkColor =
    activeCustomTheme && theme.assetDarkColor ? theme.assetDarkColor : utils.getColor("grayDark");
  const lightColor =
    activeCustomTheme && theme.assetLightColor
      ? theme.assetLightColor
      : utils.getColor("grayLighter");

  const { backgroundColor } = asset.meta;
  if (backgroundColor && backgroundColor.startsWith("#")) {
    return { background: backgroundColor };
  } else if (backgroundColor === "light") {
    return { background: lightColor };
  } else if (backgroundColor === "dark") {
    return { background: darkColor };
  } else if (backgroundColor === "clear") {
    return { background: "rgba(255, 255, 255, 0)" };
  } else if (backgroundColor === "checkered") {
    return {
      backgroundSize: "32px",
      backgroundImage: `url(${transparentBackground})`,
    };
  }
  return { background: lightColor };
}
