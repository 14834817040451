import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  assetId: string;
  name: string;
  linkId: number;
};

const [useUpdateAssetDirectLink, updateAssetDirectLink] = createAsyncAction(
  "assets/updateDirectLink",
  async ({ assetId, name, linkId }: Args) => {
    const response = await API.call<number>({
      endpoint: `assets/${assetId}/direct_links/${linkId}`,
      method: "PUT",
      entity: API.Entity.directLink,
      data: { name },
    });
    return response;
  }
);

export default useUpdateAssetDirectLink;
export { updateAssetDirectLink };
