import { useEffect } from "react";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useSearchParams } from "react-router-dom";

export default function () {
  const [params, setParams] = useSearchParams();
  const { showModal } = useShowModal();
  useEffect(() => {
    const plan = params.get("upgrade");
    if (plan) {
      params.delete("upgrade");
      setParams(params, { replace: true });
      showModal(ModalTypes.SELECT_PLAN, {
        recommendedPlan: plan,
      });
    }
  }, [showModal, params, setParams]);
}
