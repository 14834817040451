import React from "react";
import styled from "styled-components";
import { Flex, utils, Text, BoxProps } from "@thenounproject/lingo-core";

type ToggleOption<ValueType> = {
  label: string;
  value: ValueType;
};

export type ToggleProps<ValueType> = {
  options: ToggleOption<ValueType>[];
  selected: string;
  onToggle: (value: ValueType) => void;
} & BoxProps;

const ToggleWrapper = styled(Flex)`
  width: fit-content;
  height: 40px;
  background-color: white;
  padding: 4px;
  border: 1px solid ${utils.getColor("primary")};
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  gap: 8px;
`;

const ToggleButton = styled.button<{ isSelected: boolean }>`
  padding: 4px 16px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? utils.getColor("primary") : "white")};
  transition:
    background-color 0.3s,
    color 0.3s;
  border-radius: 16px;
  width: 136px;
`;

const Toggle = <ValueType extends string>({
  options,
  selected,
  onToggle,
  ...rest
}: ToggleProps<ValueType>) => {
  return (
    <ToggleWrapper {...rest}>
      {options.map(option => (
        <ToggleButton
          key={option.value}
          isSelected={selected === option.value}
          onClick={() => onToggle(option.value)}>
          <Text
            flex-shrink="0"
            font={selected === option.value ? "ui.regularSemiBold" : "ui.regular"}
            color={selected === option.value ? "white" : utils.getColor("primary")}>
            {option.label}
          </Text>
        </ToggleButton>
      ))}
    </ToggleWrapper>
  );
};

export default Toggle;
