import React, { Fragment, useState, useCallback } from "react";
import _debounce from "lodash/debounce";
import {
  OptionCard,
  Box,
  Text,
  Flex,
  Button,
  pluralize,
  Asset,
  Kit,
  Section,
  ActivityIndicator,
} from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useNotifications from "@actions/useNotifications";

import useKits from "@actions/kits/useKits";
import useKitOutline from "@redux/actions/kitVersions/useKitOutline";
import { useSelectSpace } from "@selectors/entities/spaces";
import useNavPoint from "@hooks/useNavPoint";

import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import ModalBody from "../../../components/ModalBody";
import useCreateItemsFromAssets from "@redux/actions/items/useCreateItemsFromAssets";

type Props = {
  assetIds: Asset["id"][];
  kitId?: Kit["kitId"];
  toSectionId?: Section["id"];
};

const AddAssetsToKitModal: React.FC<Props> = ({ assetIds, kitId, toSectionId }) => {
  const { showNotification } = useNotifications();
  const { showModal, dismissModal } = useShowModal();

  const [selectedKitId, setSelectedKitId] = useState(kitId);
  const [selectedSectionId, setSelectedSectionId] = useState(toSectionId);

  const navPoint = useNavPoint();
  const space = useSelectSpace();
  const { data: kitsData, isLoading: loadingKits } = useKits({ spaceId: space.id });
  const { kits = [] } = kitsData || {};
  const { data: kitOutline, isLoading: loadingOutline } = useKitOutline(
    {
      spaceId: navPoint.spaceId,
      kitId: selectedKitId,
      version: 0,
    },
    { skip: !selectedKitId }
  );

  const [createItems, { isProcessing }] = useCreateItemsFromAssets();

  const selectedKitSections = kitOutline?.sections ?? [];
  const selectedKit = kits.find(k => k.kitId === selectedKitId);
  const selectedSection = selectedKitSections?.find(f => f.id === selectedSectionId);

  const buttonText = isProcessing ? "Adding" : "Add";

  const clearSelection = React.useCallback(() => {
    setSelectedKitId(null);
    setSelectedSectionId(null);
  }, []);

  const createSectionCallback = React.useCallback(() => {
    showModal(ModalTypes.CREATE_SECTION, {
      kitId: selectedKitId,
      space,
      onCreateSection: (toSectionId: string) => {
        setSelectedSectionId(toSectionId);
        dismissModal(ModalTypes.CREATE_SECTION);
      },
    });
  }, [dismissModal, selectedKitId, showModal, space]);

  const handleAddItemsToKit = useCallback(async () => {
    const result = await createItems({
      assetIds,
      kitId: selectedKitId,
      sectionId: selectedSectionId,
    });
    if (result.error) {
      showNotification({
        message: result.error.message,
        level: "error",
      });
    } else {
      const errors = result.response.result.items.filter(f => !f.success);
      const successCount = assetIds.length - errors.length;

      if (successCount) {
        showNotification({
          message: `Added ${successCount} ${pluralize("asset", successCount)} to ${
            selectedKit.name
          }: ${selectedSection.name || "Unnamed section"}`,
        });
      }
      if (errors.length) {
        errors.forEach(err => {
          showNotification({ message: err.error.message, level: "error" });
        });
      }
      dismissModal();
    }
  }, [
    assetIds,
    createItems,
    dismissModal,
    selectedKit?.name,
    selectedKitId,
    selectedSection?.name,
    selectedSectionId,
    showNotification,
  ]);

  const renderListHeader = () => {
    if (!selectedKitId) {
      return <Text font="ui.regularSemiBold">All kits</Text>;
    }

    return (
      <Flex width="100%" justifyContent="space-between">
        <Button
          text={selectedKit.name}
          buttonStyle="tertiary"
          fontStyle="ui.regularSemiBold"
          themeOverrides={{ primaryColor: "black" }}
          iconBefore="navigation.chevron-left"
          onClick={clearSelection}
        />
        <Button
          text="Create section"
          buttonStyle="tertiary"
          icon="action.add"
          onClick={createSectionCallback}
        />
      </Flex>
    );
  };

  function renderKits() {
    if (loadingKits) {
      return <ActivityIndicator height="100px" center />;
    }
    return kits?.map(k => (
      <OptionCard
        key={k.kitId}
        size="small"
        onClick={() => setSelectedKitId(k.kitId)}
        title={k.name || "Untitled kit"}
        cardStyle="disclosure"
        image={{
          src: k.images.cover,
          icon: "content.kit",
          style: "rounded",
        }}
      />
    ));
  }

  function renderSections() {
    if (loadingOutline) {
      return <ActivityIndicator height="100px" center />;
    }
    return selectedKitSections?.map(s => {
      return (
        <OptionCard
          key={s.id}
          size="small"
          onClick={() => setSelectedSectionId(s.id)}
          title={s.name || "Untitled section"}
          cardStyle="radio"
          selected={selectedSectionId === s.id}
        />
      );
    });
  }
  return (
    <Fragment>
      <ModalHeader title={"Add to"} />
      <ModalBody>
        <Flex mb="m" justifyContent="space-between" alignItems="center">
          <Text font="ui.regularBold">
            Adding {assetIds.length} {pluralize("asset", assetIds.length)}
          </Text>
        </Flex>
        <Box border="default" borderRadius="default">
          <Box px="m" py="s" borderBottom="default">
            {renderListHeader()}
          </Box>
          <Box p="m">{!selectedKitId ? renderKits() : renderSections()}</Box>
        </Box>
      </ModalBody>
      <ModalFooter
        primary={{
          text: buttonText,
          onClick: handleAddItemsToKit,
          disabled: !selectedSectionId || isProcessing,
        }}
      />
    </Fragment>
  );
};

export default AddAssetsToKitModal;
