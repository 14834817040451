/**
 * Asset for displaying generic file uploads.
 */

import React from "react";
import styled from "styled-components";
import { AssetType, Box, Flex, Text, Asset, ImageView } from "@thenounproject/lingo-core";

import bytesToSize from "@helpers/bytesToSize";

const AssetContainer = styled(Flex).attrs({
  p: "m",
  borderRadius: "default",
  background: "grayLightest",
  alignItems: "center",
})`
  &:hover .action-button {
    opacity: 1;
  }
`;

const DescriptionBox = styled(Flex).attrs({
  flexDirection: "column",
  ml: "m",
})``;

type Props = {
  asset: Asset;
  isThumbnail: boolean;
  downloadButton?: React.ReactNode;
};

export default function AssetGeneric({ isThumbnail, downloadButton, asset }: Props) {
  let assetIcon: string;
  if (AssetType.textTypes.has(asset.type)) {
    assetIcon = "text-file";
  } else if (AssetType.audioTypes.has(asset.type)) {
    assetIcon = "content.audio";
  } else {
    assetIcon = "graphic-file";
  }
  const previewUrl = asset.meta.assetProcessing === "complete" ? asset.thumbnails[480] : null;

  function renderPreview() {
    return (
      <Box p="xs" background="white" border="default" borderRadius="default">
        <ImageView
          borderRadius="default"
          width="48"
          height="48"
          icon={assetIcon}
          cover
          src={previewUrl}
        />
      </Box>
    );
  }

  return (
    <AssetContainer className="asset-parent asset-parent-file">
      {renderPreview()}
      <DescriptionBox>
        <Text as="h1" font="ui.regular">
          {asset.name}
        </Text>
        <Text font="ui.small" color="grayDarker">
          {AssetType.displayName(asset.type)} &bull; {bytesToSize(asset.size)}
        </Text>
      </DescriptionBox>

      {isThumbnail && downloadButton ? downloadButton : null}
    </AssetContainer>
  );
}
