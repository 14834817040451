import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  itemId: string;
};

export type AssetUserResult = {
  name: string;
  avatar: string;
  role: string;
  totalDownloads: number;
  lastDownloaded: string;
};

type KitTableData = {
  rows: AssetUserResult[];
  total: number;
};

const [useAssetDownloadsByUser, fetchAssetDownloadsByUser] = createQueryAction<Args, KitTableData>(
  {
    entity: "analytics",
    action: "queryAssetDownloadsByUser",
    denormalize: false,
    pagingKey: "rows",
  },
  async ({ args, paging }) => {
    const reports = {
      user_asset_usage: {
        id: "user_asset_usage",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        item_uuid: args.itemId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ user_asset_usage: KitTableData }>(args.spaceId, reports))
      .userAssetUsage;
  }
);

export default useAssetDownloadsByUser;
export { fetchAssetDownloadsByUser };
