/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useCallback, useMemo, ComponentProps } from "react";

import { ToggleBox, Space, Text, Flex, Icon, Button, Tooltip } from "@thenounproject/lingo-core";

import useNotifications from "@actions/useNotifications";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useDeleteSpaceEmailDomain from "@redux/actions/spaces/useDeleteSpaceEmailDomain";

type Props = {
  space: Space;
};

const SettingsEmailDomains: React.FC<Props> = ({ space }) => {
  const { showModal } = useShowModal();

  if (!space) return null;

  const allowedDomains = useMemo(
    () => space.joinSettings.allowedDomains || [],
    [space.joinSettings.allowedDomains]
  );

  const isOpen = Boolean(allowedDomains.length);

  const addDomain = useCallback(() => {
    showModal(ModalTypes.ADD_JOIN_DOMAIN, { spaceId: space.id });
  }, [showModal, space.id]);

  function renderDomains() {
    const domains = space.joinSettings?.allowedDomains?.map(domain => {
      if (domain.startsWith("verified")) {
        return { domain: domain.replace("verified:", ""), verified: true };
      } else {
        return { domain, verified: false };
      }
    });
    return domains?.map(domain => (
      <JoinDomain
        key={domain.domain}
        spaceId={space.id}
        domain={domain.domain}
        verified={domain.verified}
      />
    ));
  }

  const toggleBoxProps: ComponentProps<typeof ToggleBox> = {
    id: "settings-email-domains",
    title: "Join by company email",
    description: "Allow anyone with an approved email to join your space without an invite.",
    isOpen,
    styleOverrides: { mb: "l" },
    control: "button",
    buttonProps: {
      onClick: addDomain,
      text: "Add domain",
    },
  };

  return <ToggleBox {...toggleBoxProps}>{renderDomains()}</ToggleBox>;
};

export default SettingsEmailDomains;

// MARK : JoinDomain
// -------------------------------------------------------------------------------
// Row component for join domains

type DomainProps = {
  spaceId: number;
  domain: string;
  verified: boolean;
};
const JoinDomain: React.FC<DomainProps> = ({ spaceId, domain, verified }) => {
  const { showNotification } = useNotifications(),
    [_deleteDomain] = useDeleteSpaceEmailDomain(),
    [processing, setProcessing] = useState(false),
    deleteDomain = useCallback(async () => {
      setProcessing(true);
      const { error: responseError } = await _deleteDomain({ spaceId, domain });
      setProcessing(false);
      if (responseError) {
        showNotification({ message: responseError.message, level: "error" });
      }
    }, [_deleteDomain, domain, showNotification, spaceId]);

  const tooltipSource = `${domain}-tooltip`;
  return (
    <Flex key={domain} width="100%" justifyContent="space-between">
      {!verified && (
        <Tooltip source={tooltipSource} direction={Tooltip.Direction.Top}>
          Unverified domain.{" "}
          <Button
            buttonStyle="tertiary"
            size="small"
            link="https://help.lingoapp.com/en/articles/4232606-how-to-add-team-members-to-your-space#allowed-email-domains"
            text="Learn more."
            fontStyle="ui.smallBold"
            newWindow
            themeOverrides={{
              primaryColor: "white",
              primaryColorDark: "white",
            }}
          />
        </Tooltip>
      )}
      <Flex alignItems="center">
        <Icon
          mr="xs"
          iconId={verified ? "checkmark" : "warning"}
          fill={verified ? "green" : "warning"}
          data-tooltip-source={tooltipSource}
        />
        <Text>@{domain}</Text>
      </Flex>
      <Button
        size="small"
        justifySelf="flex-end"
        buttonStyle="tertiary"
        onClick={deleteDomain}
        text="Remove domain"
        icon="trash"
        disabled={processing}
      />
    </Flex>
  );
};
