import React, { useEffect, useMemo } from "react";
import {
  DropdownButton,
  useBoolean,
  PopupMenu,
  Box,
  Button,
  OperatorValues,
  formatDate,
} from "@thenounproject/lingo-core";
import DateRangePicker from "@features/library/components/DateRangePicker";
import { useInsightsContext } from "@contexts/InsightsProvider";
import { periodOptions } from "@contexts/InsightsContext";
import { IntervalOptions } from "@contexts/InsightsContext";

export type OnChangeConfig = {
  period?: IntervalOptions;
  dateRange?: OperatorValues;
};

interface Props {
  // Required for rendering multiple pickers on the same page
  id: string;
}

const InsightsIntervalPicker = ({ id }: Props) => {
  const { interval, dateOperators, customInterval, updateInterval } = useInsightsContext();
  const isCustomDate = Boolean(customInterval);

  const [menuOpen, setMenuOpen, setMenuClosed] = useBoolean(false);
  const [datePickerShown, showDatePicker, hideDatePicker] = useBoolean(false);
  const [startDate, setStartDate] = React.useState<Date>(
    isCustomDate && dateOperators?.gte ? customInterval[0] : undefined
  );
  const [endDate, setEndDate] = React.useState<Date>(
    isCustomDate && dateOperators?.lte ? customInterval[1] : undefined
  );

  useEffect(() => {
    if (!menuOpen && datePickerShown) hideDatePicker();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuOpen]);

  const source = id.concat("-interval-picker");

  const handleSelection = (option: IntervalOptions) => {
    if (option === IntervalOptions.custom) {
      showDatePicker();
    } else {
      updateInterval(option);
      setMenuClosed();
    }
  };

  const handleDateSubmit = () => {
    updateInterval(IntervalOptions.custom, [startDate, endDate]);
    setMenuClosed();
  };

  const valueText = useMemo(() => {
    if (isCustomDate && customInterval) {
      return `${formatDate(customInterval[0].toISOString(), "MMMM d, yyyy")} - ${formatDate(
        customInterval[1].toISOString(),
        "MMMM d, yyyy"
      )}`;
    }

    if (periodOptions[interval]) {
      return periodOptions[interval].label;
    }

    return "Custom date";
  }, [customInterval, interval, isCustomDate]);

  const renderOptions = () => {
    if (datePickerShown) {
      return (
        <Box background="grayLightest">
          <Box p="8px">
            <DateRangePicker
              openByDefault
              startDate={startDate ? new Date(startDate) : undefined}
              endDate={endDate ? new Date(endDate) : undefined}
              onChange={dates => {
                setStartDate(dates[0]);
                setEndDate(dates[1]);
              }}
              withPortal
            />
          </Box>
          <Box p="8px" borderTop="default">
            <Button
              disabled={!startDate || !endDate}
              onClick={handleDateSubmit}
              text="Apply"
              fullWidth
              size="small"
            />
          </Box>
        </Box>
      );
    }

    return [
      ...Object.entries(periodOptions).map(([key, value]) => (
        <PopupMenu.Item
          key={key}
          onClick={() => handleSelection(key as IntervalOptions)}
          title={value.label}
          checked={key === interval}
        />
      )),
      <PopupMenu.Item
        key={"custom"}
        onClick={() => handleSelection(IntervalOptions.custom)}
        title={"Custom"}
        checked={Boolean(customInterval)}
      />,
    ];
  };

  return (
    <>
      <DropdownButton
        text={valueText}
        data-popup-source={source}
        onClick={setMenuOpen}
        size="regular"
      />
      <PopupMenu
        width={datePickerShown ? 526 : 180}
        stayOpenOnClick
        open={menuOpen}
        close={setMenuClosed}
        source={source}
        vPos="floatBelow"
        hPos="alignRight"
        maxHeight="none">
        <PopupMenu.Section title="All times in GMT">{renderOptions()}</PopupMenu.Section>
      </PopupMenu>
    </>
  );
};

export default React.memo(InsightsIntervalPicker);
