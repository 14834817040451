import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import {
  Box,
  Button,
  Checkbox,
  DropdownButton,
  Flex,
  Text,
  useBoolean,
  SearchFilterInput,
  SearchFilterTypes,
  useWindowSize,
} from "@thenounproject/lingo-core";
import KitFilterMenu from "./KitFilterMenu";
import CustomFieldsFilters from "./CustomFieldsFilters";
import CreatedDateFilter from "./CreatedDateFilter";
import useAddQueryFilter from "@actions/search/useAddQueryFilter";
import useRemoveQueryFilter from "@actions/search/useRemoveQueryFilter";
import SearchFilterDropdown, { DropdownType } from "./SearchFilterDropdown";
import DualColumnCheckboxes from "./DualColumnCheckboxes";
import { TabletUp } from "@features/media-queries";

const assetTypeFilters = [
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Images",
      display: "Images",
      value: "Images",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Documents",
      display: "Documents",
      value: "Documents",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Fonts",
      display: "Fonts",
      value: "Fonts",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Colors",
      display: "Colors",
      value: "Colors",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Videos",
      display: "Videos",
      value: "Videos",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Animations",
      display: "Animations",
      value: "Animations",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Notes",
      display: "Notes",
      value: "Notes",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Sketch Assets",
      display: "Sketch Assets",
      value: "Sketch Assets",
    },
  ],
  jumpToTypeFilters = [
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Kit",
      display: "Kits",
      value: "Kit",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Sections",
      display: "Sections",
      value: "Section",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Headings",
      display: "Headings",
      value: "Heading",
    },
    {
      type: SearchFilterTypes.type,
      style: "token",
      id: "type:Galleries",
      display: "Galleries",
      value: "Gallery",
    },
  ],
  jumpToTypeFilterIds = new Set(jumpToTypeFilters.map(f => f.id)),
  orientationFilters = [
    {
      type: SearchFilterTypes.orientation,
      style: "token",
      id: "orientation:horizontal",
      display: "Horizontal",
      value: "horizontal",
    },
    {
      type: SearchFilterTypes.orientation,
      style: "token",
      id: "orientation:vertical",
      display: "Vertical",
      value: "vertical",
    },
    {
      type: SearchFilterTypes.orientation,
      style: "token",
      id: "orientation:square",
      display: "Square",
      value: "square",
    },
  ];

export const SectionTitle = styled(Text).attrs({
  mb: "xs",
  mt: "l",
  font: "ui.smallBold",
})``;

type Props = {
  type: "assets" | "jumpTo";
  activeFilters: SearchFilterInput[];
  aggregations: {
    kit: SearchFilterInput[];
    orientation: SearchFilterInput[];
    type: SearchFilterInput[];
  };
};

const SearchResultsFilters = ({ type, activeFilters, aggregations }: Props) => {
  const addQueryFilter = useAddQueryFilter(),
    removeQueryFilter = useRemoveQueryFilter();
  const { isMobile } = useWindowSize();

  const activeFilterSet = useMemo(() => new Set(activeFilters.map(f => f.id)), [activeFilters]);

  const toggleFilter = useCallback(
    filter => {
      if (activeFilterSet.has(filter.id)) return removeQueryFilter({ filterId: filter.id });
      else return addQueryFilter(filter);
    },
    [activeFilterSet, addQueryFilter, removeQueryFilter]
  );

  return (
    <Flex
      width={isMobile ? "100%" : "200px"}
      flexDirection="column"
      justifyContent="space-between"
      background={isMobile ? "white" : "grayLightest"}
      alignItems="stretch"
      borderLeft="1px solid gray">
      <Box
        style={{ overflowY: "auto" }}
        px="m"
        pt={isMobile ? "0" : "s"}
        pb={isMobile ? "16px" : "m"}>
        <TabletUp>
          <Text mb="m" font="ui.regularBold">
            Filters
          </Text>
        </TabletUp>
        {isMobile && (
          <SearchFilterDropdown sortingMenuID="mobile-filters" type={DropdownType.mobile} />
        )}

        {/* Asset only filters */}
        {type === "assets" && (
          <>
            <AssetTypeFilter activeFilters={activeFilters} toggleFilter={toggleFilter} />
            <OrientationFilter activeFilters={activeFilters} toggleFilter={toggleFilter} />
          </>
        )}
        {/* Kit only filters */}
        {type === "jumpTo" && (
          <JumpToTypeFilter activeFilters={activeFilters} toggleFilter={toggleFilter} />
        )}
        {/* Shared filters */}
        <KitFilter
          activeFilters={activeFilters}
          toggleFilter={toggleFilter}
          aggregations={aggregations}
        />
        <CreatedDateFilter activeFilters={activeFilters} toggleFilter={toggleFilter} />
        {type === "assets" && (
          <CustomFieldsFilters activeFilters={activeFilters} toggleFilter={toggleFilter} />
        )}
      </Box>
    </Flex>
  );
};

export const SearchAssetFilters = props => <SearchResultsFilters type="assets" {...props} />;
export const SearchJumpToFilters = props => <SearchResultsFilters type="jumpTo" {...props} />;

// MARK : Filter Checkbox
// -------------------------------------------------------------------------------

type FilterCheckboxProps = {
  filter: SearchFilterInput;
  isSelected: boolean;
  toggleFilter: (filter: SearchFilterInput) => void;
};

export const FilterCheckbox = ({ filter, isSelected, toggleFilter }: FilterCheckboxProps) => {
  const onClick = useCallback(() => toggleFilter(filter), [filter, toggleFilter]);
  return (
    <Checkbox
      truncate
      label={filter.display}
      isSelected={isSelected}
      onClick={onClick}
      styleOverrides={{ mb: "xs", pl: "none" }}
    />
  );
};

// MARK : Asset Type Filter
// -------------------------------------------------------------------------------

type AssetTypeFilterProps = {
  activeFilters: SearchFilterInput[];
  toggleFilter: (filter: SearchFilterInput) => void;
};

function AssetTypeFilter({ activeFilters, toggleFilter }: AssetTypeFilterProps) {
  const [expandedTypes, setTypesExpanded, setTypesCondensed] = useBoolean(false);

  const { isMobile } = useWindowSize();

  const [filters, selected, hasMore, allFilters] = useMemo(() => {
    const active = activeFilters.filter(f => f.type === "type" && !jumpToTypeFilterIds.has(f.id));
    const activeIds = new Set(active.map(f => f.id));
    const other = assetTypeFilters.filter(f => !activeIds.has(f.id));
    const all = active.concat(other);
    const length = expandedTypes ? all.length : Math.max(4, active.length);

    return [all.slice(0, length), activeIds, all.length > 4, all];
  }, [activeFilters, expandedTypes]);

  function renderDesktopFilterArrangement() {
    return (
      <>
        {filters.map(f => (
          <FilterCheckbox
            key={f.id}
            filter={f}
            isSelected={selected.has(f.id)}
            toggleFilter={toggleFilter}
          />
        ))}
        {hasMore && (
          <Button
            buttonStyle="tertiary"
            size="small"
            text={expandedTypes ? "Show less" : "Show all types"}
            onClick={expandedTypes ? setTypesCondensed : setTypesExpanded}
          />
        )}
      </>
    );
  }

  function renderMobileFilterArrangement() {
    return (
      <DualColumnCheckboxes filters={allFilters} selected={selected} toggleFilter={toggleFilter} />
    );
  }

  return (
    <>
      <SectionTitle mt="m">Type</SectionTitle>
      {isMobile ? renderMobileFilterArrangement() : renderDesktopFilterArrangement()}
    </>
  );
}

type JumpToTypeFilterProps = {
  activeFilters: SearchFilterInput[];
  toggleFilter: (filter: SearchFilterInput) => void;
};

function JumpToTypeFilter({ activeFilters, toggleFilter }: JumpToTypeFilterProps) {
  const [filters, selected] = useMemo(() => {
    const allowed = jumpToTypeFilterIds;
    const active = activeFilters.filter(f => f.type === "type" && allowed.has(f.id));
    const activeIds = new Set(active.map(f => f.id));

    return [jumpToTypeFilters, activeIds];
  }, [activeFilters]);

  return (
    <>
      <SectionTitle mt="m">Type</SectionTitle>
      {filters.map(f => (
        <FilterCheckbox
          key={f.id}
          filter={f}
          isSelected={selected.has(f.id)}
          toggleFilter={toggleFilter}
        />
      ))}
    </>
  );
}

// MARK : Orientation Filter
// -------------------------------------------------------------------------------

type OrientationFilterProps = {
  activeFilters: SearchFilterInput[];
  toggleFilter: (filter: SearchFilterInput) => void;
};

function OrientationFilter({ activeFilters, toggleFilter }: OrientationFilterProps) {
  const { isMobile } = useWindowSize();
  const [filters, selected] = useMemo(() => {
    const active = activeFilters.filter(f => f.type === "orientation");
    const activeIds = new Set(active.map(f => f.id));
    const other = orientationFilters.filter(f => !activeIds.has(f.id));

    return [active.concat(other), activeIds];
  }, [activeFilters]);

  return (
    <>
      <SectionTitle>Orientation</SectionTitle>
      {isMobile ? (
        <DualColumnCheckboxes filters={filters} selected={selected} toggleFilter={toggleFilter} />
      ) : (
        filters.map(f => (
          <FilterCheckbox
            key={f.id}
            filter={f}
            isSelected={selected.has(f.id)}
            toggleFilter={toggleFilter}
          />
        ))
      )}
    </>
  );
}

// MARK : Kit Filter
// -------------------------------------------------------------------------------
type KitFilterProps = {
  activeFilters: SearchFilterInput[];
  toggleFilter: (filter: SearchFilterInput) => void;
  aggregations: {
    kit: SearchFilterInput[];
    orientation: SearchFilterInput[];
    type: SearchFilterInput[];
  };
};

function KitFilter({ activeFilters, toggleFilter, aggregations }: KitFilterProps) {
  const kitsMenuID = "kit-filter-popup";
  const [kitsMenuShown, showKitsMenu, hideKitsMenu] = useBoolean(false);
  const { isMobile } = useWindowSize();

  const [shownFilters, selected, inMenuKits, allKits] = useMemo(() => {
    const availableKits = aggregations?.kit ?? [];
    const active = activeFilters.filter(f => f.type === "kit");
    const activeIds = new Set(active.map(f => f.id));
    const other = availableKits.filter(f => !activeIds.has(f.id));
    const all = active.concat(other);
    const length = Math.max(3, active.length);
    const exposedKits = all.slice(0, length);
    const remainingKits = all.slice(length);

    return [exposedKits, activeIds, remainingKits, all];
  }, [activeFilters, aggregations?.kit]);
  if (allKits.length === 0) return;
  return (
    <>
      <SectionTitle>In Kit</SectionTitle>
      {isMobile ? (
        <DualColumnCheckboxes filters={allKits} selected={selected} toggleFilter={toggleFilter} />
      ) : (
        <>
          {shownFilters.map(f => (
            <FilterCheckbox
              key={f.id}
              filter={f}
              isSelected={selected.has(f.id)}
              toggleFilter={toggleFilter}
            />
          ))}
          <DropdownButton
            data-popup-source={kitsMenuID}
            text={"More Kits"}
            onClick={showKitsMenu}
            styleOverrides={{ mt: "xs" }}
          />
          {kitsMenuShown && (
            <KitFilterMenu
              popupMenuID={kitsMenuID}
              activeKits={selected}
              defaultKitList={inMenuKits}
              isOpen={kitsMenuShown}
              onClose={hideKitsMenu}
              toggleFilter={toggleFilter}
            />
          )}
        </>
      )}
    </>
  );
}
