import React, { useState, useCallback } from "react";
import { Text } from "@thenounproject/lingo-core";

import useDeleteTOTP from "@redux/actions/auth/useDeleteTOTP";
import useNotifications from "@actions/useNotifications";
import useShowModal from "@redux/actions/useModals";

import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import ModalBody from "../../ModalBody";

type Props = {
  password: string;
};

const TFADisableModal = ({ password }: Props) => {
  const [processing, setProcessing] = useState(false);

  const [deleteTOTP] = useDeleteTOTP();
  const { showNotification } = useNotifications();
  const { dismissModal } = useShowModal();

  const submit = useCallback(async () => {
    setProcessing(true);
    const { error } = await deleteTOTP(password);
    if (error) {
      showNotification({ message: error.message || "An error occurred.", level: "error" });
      setProcessing(false);
    } else {
      showNotification({ message: "Authenticator app disabled", level: "info" });
      dismissModal();
    }
  }, [password, deleteTOTP, showNotification, dismissModal]);

  return (
    <>
      <ModalHeader title="Disable authenticator app?" />
      <ModalBody alignItems="center">
        <Text mb="4">This will disable authentication codes when logging in. Are you sure?</Text>
      </ModalBody>
      <ModalFooter
        primary={{
          text: "Disable",
          onClick: submit,
          disabled: processing,
        }}
      />
    </>
  );
};

export default TFADisableModal;
