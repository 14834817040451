import React, { useState, useCallback } from "react";
import { Box, Flex, Text, Input, Button, ActivityIndicator } from "@thenounproject/lingo-core";
import QRCode from "react-qr-code";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useCreateTOTP from "@redux/actions/auth/useCreateTOTP";
import useVerifyTOTP from "@redux/actions/auth/useVerifyTOTP";

import ModalHeader from "../../ModalHeader";
import ModalBody from "../../ModalBody";

type Props = {
  password: string;
};

const TFASetupModal = ({ password }: Props) => {
  const [verificationCode, setVerificationCode] = useState("");

  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  const { status, uri, secret, error: TOTPError } = useCreateTOTP();
  const [verifyTOTP] = useVerifyTOTP();
  const { showModal, dismissModal } = useShowModal();

  const submit = useCallback(async () => {
    setProcessing(true);
    const { error } = await verifyTOTP({ password, otp: verificationCode });
    if (error) {
      setError(error.message || "An error occurred.");
      setProcessing(false);
    } else {
      dismissModal();
      showModal(ModalTypes.TFA_SUCCESS, {});
    }
  }, [verificationCode, password, verifyTOTP, dismissModal, showModal]);

  function renderTOTP() {
    switch (status) {
      case "loading":
        return <ActivityIndicator size="small" />;
      case "error":
        return <Text>{TOTPError.message}</Text>;
      default:
        return (
          <>
            <Box p={11}>{uri && <QRCode value={uri} size={158} data-testid="qr-code" />}</Box>
            <Text mb="xs">If you need to use manual entry in your app, use the code below: </Text>
            <Text>{secret}</Text>
          </>
        );
    }
  }

  return (
    <>
      <ModalHeader title="Two-factor authentication" />
      <ModalBody>
        <Box mb="l">
          <Text font="ui.regularBold" mb="xs">
            1. Download an authenticator app
          </Text>
          <Text>
            You’ll need to download an authenticator app such as{" "}
            <Button link="https://authy.com/download/" text="Authy" buttonStyle="tertiary" />,{" "}
            <Button
              link="https://support.google.com/accounts/answer/1066447/"
              text="Google Authenticator"
              buttonStyle="tertiary"
            />
            , or{" "}
            <Button
              link="https://www.microsoft.com/en-us/p/microsoft-authenticator/9nblgggzmcj6?activetab=pivot:overviewtab"
              text="Microsoft Authenticator"
              buttonStyle="tertiary"
            />{" "}
            for your phone or tablet.
          </Text>
        </Box>
        <Box mb="l" pt="l" borderTop="default">
          <Text font="ui.regularBold" mb="xs">
            2. Scan the QR code
          </Text>
          <Text mb="s">
            Open your authenticator app, add a new account and scan the QR code below.
          </Text>
          {renderTOTP()}
        </Box>
        <Box pt="l" borderTop="default">
          <Text font="ui.regularBold" mb="xs">
            3. Enter the verification code
          </Text>
          <Text mb="s">Enter the 6-digit verification code generated by your app.</Text>
          <Flex>
            <Input
              placeholder="000000"
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
              message={error}
              inputStyle={error ? "error" : null}
            />
            <Button
              ml="m"
              text="Verify"
              onClick={submit}
              disabled={processing || verificationCode.length !== 6}
            />
          </Flex>
        </Box>
      </ModalBody>
    </>
  );
};

export default TFASetupModal;
