import React, { ComponentProps, useCallback } from "react";
import { BigNumber, Box, Flex, EmptyState } from "@thenounproject/lingo-core";
import styled from "styled-components";
import _isFinite from "lodash/isFinite";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";

export const DisplayPrivacy = {
  private: "Private",
  public: "Public",
  password: "Password",
};

export const ReportContainer = styled(Box).attrs({
  width: "100%",
  borderRadius: "default",
  border: "default",
})`
  .learn-more {
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    .learn-more {
      opacity: 1;
    }
  }
`;

export function InsightsHeader({ stats }: { stats: Array<ComponentProps<typeof BigNumber>> }) {
  function renderStats() {
    if (!stats.length) return null;
    const stack = stats.length > 3;
    return (
      <Flex
        flexWrap={stack ? "wrap" : null}
        width="100%"
        gap="8px"
        variations={{
          "mq.xs": {
            flexDirection: "column",
          },
        }}>
        {stats.map(s => (
          <Box
            key={s.title}
            flex={"1"}
            variations={{
              "mq.m": stack ? { minWidth: "40%" } : {},
              "mq.xs": { minWidth: "100%" },
            }}>
            <BigNumber {...s} />
          </Box>
        ))}
      </Flex>
    );
  }

  return (
    <Flex minHeight="100%" alignItems="stretch">
      {renderStats()}
    </Flex>
  );
}

export function LoadFailure() {
  return (
    <EmptyState
      title="Failed to load"
      subtitle="Something went wrong trying to load your data. Try refreshing the page."
      iconProps={{ iconId: "info.error", size: "80", fill: "error" }}
    />
  );
}

export function Upsell({ insightType, spaceId }: { insightType: string; spaceId: number }) {
  const { showModal } = useShowModal();
  const openModal = useCallback(() => {
    showModal(ModalTypes.ENTERPRISE_REQUEST, {
      spaceId,
      preselectFeatures: ["Advanced Insights"],
    });
  }, [spaceId, showModal]);
  return (
    <EmptyState
      title="Enterprise-only"
      subtitle={`${insightType} insights are included in the Enterprise Plan. Upgrade to measure the performance of your ${insightType.toLowerCase()}s.`}
      iconProps={{ iconId: "info.lock-closed", size: "80", fill: "grayDark" }}
      button={{ text: "Contact sales", onClick: openModal }}
    />
  );
}

export const InsightsPageWrapper = styled(Flex).attrs({
  flexDirection: "column",
  gap: "24px",
})``;

export const InsightsPageSection = styled(Box).attrs({
  px: "40px",
  variations: { "mq.s": { px: "24px" } },
  as: "section",
})``;
