import React, { ComponentProps, useCallback } from "react";
import { Button, Tooltip, Text, Flex, Box } from "@thenounproject/lingo-core";

import { LibraryAssetResults } from "@redux/actions/search/useLibraryAssets";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";

type Props = {
  data: LibraryAssetResults;
};
const AssetLibrarySelectAll: React.FC<Props> = ({ data }) => {
  const { clearSelection, selectItems, selectionState } = useSelectedItemsContext();

  const selectionCount = selectionState.assetLibrary.length;

  const _clearSelection = useCallback(
    () => clearSelection({ type: "assetLibrary" }),
    [clearSelection]
  );
  const _selectAll = useCallback(() => {
    const assetIds = data?.assets?.map(asset => asset.id);
    selectItems({ type: "assetLibrary", ids: assetIds });
  }, [data, selectItems]);

  const totalCount = data?.total || 0;
  const totalLoaded = data?.assets?.length || 0;
  const allLoaded = totalCount > 0 && totalCount === totalLoaded;

  const countLabel =
    selectionCount === 0
      ? `${totalCount} assets`
      : `${selectionCount} of ${totalCount} assets selected`;
  const warning =
    totalLoaded > 0 && selectionCount >= totalLoaded && !allLoaded
      ? {
          text: `Why are only ${selectionCount} assets selected?`,
          tooltip:
            "You can only select assets that have been loaded. Scroll to the bottom to load the next page of assets.",
        }
      : null;
  let buttonProps: Partial<ComponentProps<typeof Button>>;
  if (totalLoaded === 0) {
    // Nothing to select
    buttonProps = null;
  } else if (selectionCount >= totalLoaded) {
    // Everything is selected
    buttonProps = {
      text: "Clear selection",
      onClick: _clearSelection,
    };
  } else {
    // No or partial selection
    buttonProps = {
      text: "Select all",
      onClick: _selectAll,
    };
  }

  function renderWarning() {
    if (!warning) return null;
    return (
      <>
        <Tooltip direction={Tooltip.Direction.Top} source="selection-warning">
          {warning.tooltip}
        </Tooltip>
        <Text data-tooltip-source="selection-warning" color="grayDarker">
          {warning.text}
        </Text>
      </>
    );
  }

  return (
    <Flex gap="12px" alignItems="center">
      <Text font="ui.regularBold">{countLabel}</Text>
      <Box background="gray" width="1" height="20" />
      {renderWarning()}
      <Button {...buttonProps} buttonStyle="tertiary" />
    </Flex>
  );
};

export default AssetLibrarySelectAll;
