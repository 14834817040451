/*
 * Wraps other components in a modal. Valid child components for Modal are defined in modalTypes.
 * When another component wants to trigger a modal, it calls showModal action to update state,
 * declaring modal type and any props that should be passed down. In addition to those declared
 * props, all modals receive router, and dismissModal.
 *
 * A basic modal component should look like:
 * ```
 * <Fragment>
 *    <ModalHeader title="My Modal" />
 *    <ModalBody>
 *        ...content
 *    </ModalBody>
 *    <ModalFooter primary={{...buttonProps}}
 * </Fragment>
 * ```
 * The wrapping element is important because the modal itself which this will all be embedded in uses flex to get the header, body, and footer to lay out properly. If you need to wrap anything in an actual div, it must be a Flex.
 */

import React, { ComponentProps, MouseEvent, useCallback, useEffect } from "react";
import styled from "styled-components";

import { Box, Flex } from "@thenounproject/lingo-core";
import { useSearchParams } from "react-router-dom";

import { modalTypes } from "../constants/modalTypes";
import ErrorBoundary from "./ErrorBoundary";

import KeyCode from "../constants/keyCodes";
import { useAppSelectorV1 } from "@redux/hooks";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

const modalsWithoutDismiss = ["MANAGE_FILECUTS", "CONFIRM_MIGRATION"];

const modalTypeStyles = {
  SELECT_PLAN: {
    maxWidth: "1200px",
    width: "100%",
  },
  EDIT_KIT: {
    width: "400px",
    maxWidth: "100%",
    overflow: "hidden",
  },
  EDIT_PORTAL: {
    width: "400px",
    maxWidth: "100%",
    overflow: "hidden",
  },
  SEARCH_MODAL: {
    width: "100%",
    maxWidth: "750px",
    overflow: "hidden",
    minHeight: "unset",
  },

  LIBRARY_PICKER: {
    width: "100vw",
    height: "100vh",
    maxWidth: "100vw",
    overflow: "hidden",
    maxHeight: "100vh",
    borderRadius: "0",
    position: "fixed",
    top: "0",
    left: "0",
  },
  MENU_MODAL: {
    width: "100%",
    maxWidth: "440px",
  },
  KIT_NAVIGATION: {
    width: "100%",
    maxWidth: "440px",
  },
  INSIGHTS_ASSET: {
    maxWidth: "1200px",
  },
  INSIGHTS_KIT: {
    maxWidth: "1200px",
  },
  INSIGHTS_USER: {
    maxWidth: "1200px",
  },
  INSIGHTS_PORTAL: {
    maxWidth: "1200px",
  },
  MIGRATE_TO_PORTALS: {
    maxWidth: "1200px",
  },
};

const modalTypeMobileStyles = {
  LIBRARY_PICKER: {
    top: "0px !important",
  },
  EDIT_KIT: {
    width: "100% !important",
  },
};

type WrapperProps = { shown: boolean };
const Wrapper = styled(Box).attrs(() => {
  return {
    background: "modalOverlay",
    display: "block",
    height: "100vh",
    width: "100%",
    m: 0,
    p: 0,
    overflow: "auto",
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: "modalWrapper",
  };
})<WrapperProps>`
  visibility: ${props => (props.shown ? "visible" : "hidden")};
  opacity: ${props => (props.shown ? 1 : 0)};
  transition: opacity 0.25s;
`;

const BackgroundClickHandler = styled.button`
  background: transparent;
  display: block;
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ModalCard = styled(Flex).attrs(() => {
  return {
    background: "white",
    borderRadius: "default",
    overflow: "auto",
    boxShadow: "panel",
    height: "auto",
    m: "0 auto",
    position: "relative",
    width: "100%",
    maxWidth: "750px",
    flexDirection: "column",
    top: 80,
    zIndex: "modal",
    maxHeight: "calc(100vh - 160px)",
  };
})``;

type ModalProps<T extends ModalTypes = ModalTypes> = {
  component: T;
  props: ComponentProps<(typeof modalTypes)[T]>;
};

export function Modal() {
  const modals: Array<ModalProps> = useAppSelectorV1(state => state.modals);
  const { showModal, dismissModal } = useShowModal();
  const [params, setParams] = useSearchParams();

  const closeModal = useCallback(
    (e: MouseEvent | KeyboardEvent) => {
      const currentModal = modals[modals.length - 1];
      if (!currentModal || modalsWithoutDismiss.includes(currentModal.component)) return;
      if (e) e.preventDefault();
      if (params.get("modal") || params.get("invite")) {
        params.delete("modal");
        params.delete("invite");
        setParams(params);
      }

      dismissModal();
    },
    [dismissModal, params, setParams, modals]
  );

  useEffect(() => {
    function escKeyListener(e: KeyboardEvent) {
      if (e.key === KeyCode.escape) {
        const currentModal = modals[modals.length - 1];
        if (!currentModal || modalsWithoutDismiss.includes(currentModal.component)) return;
        if (["input", "textarea"].includes((e.target as HTMLElement).tagName.toLowerCase())) return;
        closeModal(e);
      }
    }
    document.addEventListener("keyup", escKeyListener, false);
    return () => {
      document.removeEventListener("keyup", escKeyListener, false);
    };
  }, [closeModal, modals]);

  function renderModal(modal: ModalProps, isCurrent) {
    const { component, props: modalProps } = modal;

    // Rendering
    const Component = modalTypes[component];

    if (component && !Component) {
      console.warn(`Modal component ${component} not found`);
      return null;
    }
    const componentProps = Object.assign({}, modalProps, {
      dismissModal: closeModal,
      showModal,
    });
    return (
      <Box key={modal.component} display={isCurrent ? "visible" : "none"} px="16px">
        <BackgroundClickHandler onClick={closeModal} />
        <ModalCard
          role="dialog"
          style={modalTypeStyles[component]}
          variations={
            modalTypeMobileStyles[component]
              ? { "mq.s": modalTypeMobileStyles[component] }
              : undefined
          }>
          <ErrorBoundary>
            <Component {...componentProps} />
          </ErrorBoundary>
        </ModalCard>
      </Box>
    );
  }

  // Rendering all of the modals means we can maintain state in modals lower in the stack
  const components = modals.map((m, idx) => renderModal(m, idx === modals.length - 1));
  return <Wrapper shown={Boolean(components.length)}>{components}</Wrapper>;
}

export default Modal;
