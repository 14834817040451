/* eslint-disable react/jsx-key */
import React from "react";
import { getCustomDomainComponents, Redirect } from "@thenounproject/lingo-core";
import { Route } from "react-router-dom";

import { lazyWithReload } from "@helpers/lazyWithReload";

const AuthLayout = lazyWithReload(() => import("../components/layouts/AuthLayout"));
const AppLayout = lazyWithReload(() => import("../components/layouts/AppLayout"));

import {
  createItemNavPoint,
  createLibraryAssetNavPoint,
  createSectionNavPoint,
  createKitNavPoint,
  createUserSettingsNavPoint,
  createSpaceSettingsNavPoint,
  createNewSpaceNavPoint,
  createSpaceKitsNavPoint,
  createDefaultSpaceNavPoint,
  createInsightsNavPoint,
  createSpaceUsersNavPoint,
  createSpaceBillingNavPoint,
  createSpaceSearchNavPoint,
  createKitCollectionNavPoint,
  createAssetLibraryNavPoint,
  createAssetTagManagementNavPoint,
  createCustomFieldsNavPoint,
  createDashboardNavPoint,
  createPortalNavPoint,
} from "@redux/legacy-actions/navPoints";
import AuthMac from "../components/auth/AuthMac";
import Auth from "../components/auth/Auth";
import AuthGuestLogin from "../components/auth/AuthGuestLogin";
import JoinSpaces from "../components/auth/JoinSpaces";
import ErrorView from "../components/auth/Error";
import Logout from "../components/auth/Logout";
import ExtendTrial from "../components/billing/ExtendTrial";
import AuthVerify from "../components/auth/AuthVerify";
import { AuthType, SignupStep } from "../components/auth/useAuthState";

export default function AppRoutes() {
  const { identifier: subdomain } = getCustomDomainComponents();

  function getPath(path: string): string {
    return subdomain ? path : "/:spaceId" + path;
  }

  return [
    <Route key="1" path="/login" element={<AuthLayout />}>
      <Route index element={<Auth authType={AuthType.login} />} />
      <Route path="sso" element={<Auth authType={AuthType.sso} />} />
      <Route path=":token" element={<Auth authType={AuthType.login} />} />
      <Route path="mac" element={<AuthMac />} />
    </Route>,
    <Route key="1" path="/guest-login" element={<AuthLayout />}>
      <Route index element={<AuthGuestLogin />} />
      <Route path=":token" element={<AuthGuestLogin />} />
    </Route>,
    <Route key="1" path="get-started" element={<Redirect to="/signup" replace />} />,
    <Route key="1" path="/signup" element={<AuthLayout />}>
      <Route index element={<Auth authType={AuthType.signup} />} />
      <Route
        path="verification-sent"
        element={<Auth authType={AuthType.signup} signupStep={SignupStep.verificationSent} />}
      />
    </Route>,
    <Route key="1" path="/link-account/*" element={<AuthLayout />}>
      <Route index element={<Auth authType={AuthType.linkAccount} />} />
      <Route path=":token/*" element={<Auth authType={AuthType.linkAccount} />} />
      <Route path="google/:token" element={<Auth authType={AuthType.linkAccount} />} />
    </Route>,
    <Route key="1" path="/join" element={<AuthLayout />}>
      <Route path=":slug/:token" element={<Auth authType={AuthType.join} />} />
    </Route>,
    <Route key="1" path="/invitation/:token" element={<AuthLayout />}>
      <Route index element={<Auth authType={AuthType.invitation} />} />
    </Route>,
    <Route key="1" path="/password-reset" element={<AuthLayout />}>
      <Route index element={<Auth authType={AuthType.passwordReset} />} />
      <Route path=":token" element={<Auth authType={AuthType.passwordReset} />} />
    </Route>,
    <Route key="1" path="/join-spaces/" element={<AuthLayout />}>
      <Route index element={<JoinSpaces />} />
    </Route>,
    <Route key="1" path="/join-space" element={<AuthLayout />}>
      <Route index element={<Auth authType={AuthType.joinSpace} />} />
    </Route>,
    <Route key="1" path="/error" element={<AuthLayout />}>
      <Route index element={<ErrorView />} />
    </Route>,
    <Route key="1" path="/logout" element={<AuthLayout />}>
      <Route index element={<Logout />} />
    </Route>,
    <Route key="1" path="/extend-trial" element={<AuthLayout />}>
      <Route index element={<ExtendTrial />} />
    </Route>,
    <Route key="1" path="/enter-password/" element={<AuthLayout />}>
      <Route index element={<Auth authType={AuthType.enterPassword} />} />
    </Route>,
    <Route key="1" path="/enter-email" element={<AuthLayout />}>
      <Route index element={<Auth authType={AuthType.enterEmail} />} />
    </Route>,
    <Route key="1" path="/verify" element={<AuthLayout />}>
      <Route index element={<AuthVerify />} />
      <Route path=":verifyType/:token/" element={<AuthVerify />} />
    </Route>,
    <Route
      key="2"
      path="/space/"
      element={<AppLayout createNavPoint={createDefaultSpaceNavPoint} />}
    />,
    <Route
      key="2"
      path="/space/new"
      element={<AppLayout createNavPoint={createNewSpaceNavPoint} />}
    />,

    // User settings

    <Route
      key="2"
      path="/settings/profile"
      element={<AppLayout createNavPoint={createUserSettingsNavPoint} />}
    />,
    <Route
      key="2"
      path="/settings/password"
      element={<AppLayout createNavPoint={createUserSettingsNavPoint} />}
    />,
    <Route
      key="2"
      path="/settings/email"
      element={<AppLayout createNavPoint={createUserSettingsNavPoint} />}
    />,
    <Route
      key="2"
      path="/settings/privacy"
      element={<AppLayout createNavPoint={createUserSettingsNavPoint} />}
    />,

    // Space settings
    <Route
      key="2"
      path={getPath("/settings/:settingsPage?/")}
      element={<AppLayout createNavPoint={createSpaceSettingsNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/search/")}
      element={<AppLayout createNavPoint={createSpaceSearchNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/billing/")}
      element={<AppLayout createNavPoint={createSpaceBillingNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/members/:role?/*")}
      element={<AppLayout createNavPoint={createSpaceUsersNavPoint} />}
    />,
    // Insights
    <Route
      key="2"
      path={getPath("/insights/:insightsPage?/")}
      element={<AppLayout createNavPoint={createInsightsNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/p/:portalId/k/:kitUuid/:kitPage?")}
      element={<AppLayout createNavPoint={createKitNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/p/:portalId/s/:sectionUuid")}
      element={<AppLayout createNavPoint={createSectionNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/p/:portalId/a/:itemUuid")}
      element={<AppLayout createNavPoint={createItemNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/p/:portalId/")}
      element={<AppLayout createNavPoint={createPortalNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/k/:kitUuid/:kitPage?")}
      element={<AppLayout createNavPoint={createKitNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/a/:itemUuid/")}
      element={<AppLayout createNavPoint={createItemNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/s/:sectionUuid/")}
      element={<AppLayout createNavPoint={createSectionNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/c/:kitCollectionId/")}
      element={<AppLayout createNavPoint={createKitCollectionNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/dashboard/")}
      element={<AppLayout createNavPoint={createDashboardNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/library/")}
      element={<AppLayout createNavPoint={createAssetLibraryNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/library/tags/")}
      element={<AppLayout createNavPoint={createAssetTagManagementNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/library/fields/")}
      element={<AppLayout createNavPoint={createCustomFieldsNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/library/a/:assetUuid")}
      element={<AppLayout createNavPoint={createLibraryAssetNavPoint} />}
    />,
    <Route
      key="2"
      path={getPath("/")}
      element={<AppLayout createNavPoint={createSpaceKitsNavPoint} />}
    />,
  ];
}
