/**
 * Component for displaying a figma frame, group, component in the context of a selection menu.
 * Has the capability of recursively rendering nodes that have children.
 */

import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { Box, Icon, Flex, Text, Button } from "@thenounproject/lingo-core";
import { isArraySubset } from "shared";

import { figmaNodeIconMap, figmaNodeTypes, getFigmaNodeDepthPadding } from "@helpers/figma";

type Props = {
  onSelect: (node_id: string) => void;
  onSelectChildren: (nodes: string[], shouldRemoveNodes: boolean) => void;
  onToggleNodeExpanded?: (node_id: string) => void;
  selectedNodes: string[];
  expandedNodes: string[];
  data: FigmaNode;
  depth?: number;
};

const ParentBox = styled(Box).attrs({ width: "100%", mb: "s" })`
  .nested-children-button {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    .nested-children-button {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

type ToggleButtonProps = {
  hasChildren: boolean;
};

const ToggleButton = styled(Button).attrs(({ hasChildren }: ToggleButtonProps) => {
  return {
    mr: "s",
    buttonStyle: "tertiary",
    size: "small",
    themeOverrides: { primaryColor: "grayDarkest", primaryColorDark: "grayDarkest" },
    style: { opacity: hasChildren ? 1 : 0, pointerEvents: hasChildren ? "all" : "none" },
  };
})<ToggleButtonProps>``;

const childBoxProps = {
  width: "100%",
  px: "m",
  justifyContent: "space-between",
  alignItems: "center",
  height: "56px",
  borderRadius: "default",
  border: "1px solid gray",
};

const separatorBoxProps = {
  width: "1px",
  background: "gray",
  mx: "s",
};

const FigmaNodeResult: React.FC<Props> = ({
  onSelect,
  onSelectChildren,
  onToggleNodeExpanded,
  expandedNodes,
  selectedNodes,
  depth,
  data: { type, name, node_id, children, importable },
}) => {
  const expanded = expandedNodes && expandedNodes.includes(node_id);

  /**
   * Memoized variables
   */
  const selected = useMemo(() => selectedNodes.includes(node_id), [selectedNodes, node_id]);
  const hasChildren = useMemo(() => Boolean(children?.length > 0), [children]);
  const paddingLeft = useMemo(() => getFigmaNodeDepthPadding(depth), [depth]);

  const checkBoxIcon = useMemo(() => {
    return selected ? "action.checkbox-checked" : "action.checkbox-unchecked";
  }, [selected]);

  const expandIcon = useMemo(() => {
    return expanded ? "navigation.chevron-down" : "navigation.chevron-right";
  }, [expanded]);

  const allChildrenSelected = useMemo(() => {
    if (!children?.length) return false;
    if (!selectedNodes.length) return false;
    return isArraySubset(
      selectedNodes,
      children.map(s => s.node_id)
    );
  }, [children, selectedNodes]);

  const selectionText = useMemo(() => {
    const verb = allChildrenSelected ? "Deselect" : "Select";
    const noun = type === figmaNodeTypes.COMPONENT_SET ? "components" : "frames";
    return `${verb} nested ${noun}`;
  }, [allChildrenSelected, type]);

  /**
   * Memoized callback functions
   */
  const _onSelect = useCallback(() => {
    onSelect(node_id);
  }, [node_id, onSelect]);

  const _toggleExpanded = useCallback(() => {
    onToggleNodeExpanded(node_id);
  }, [node_id, onToggleNodeExpanded]);

  const _onSelectChildren = useCallback(() => {
    onSelectChildren(
      children.map(s => s.node_id),
      allChildrenSelected
    );
    // If user selects all & they arent visible, expand the children
    // If user deselects & they are visible, hide the children
    if ((expanded && allChildrenSelected) || (!expanded && !allChildrenSelected)) _toggleExpanded();
  }, [_toggleExpanded, allChildrenSelected, children, expanded, onSelectChildren]);

  /**
   * Rendering
   */
  function renderFrameNodesRecursive(nodes: FigmaNode[], depth: number) {
    const localDepth = depth + 1;
    return nodes.map(node => {
      const nodeProps = {
        onSelect,
        onSelectChildren,
        onToggleNodeExpanded,
        expandedNodes,
        selectedNodes,
        depth: localDepth,
        data: node,
      };
      return <FigmaNodeResult key={node.node_id} {...nodeProps} />;
    });
  }

  return (
    <>
      <ParentBox>
        <Flex {...childBoxProps}>
          <Flex alignItems="center" pl={paddingLeft}>
            {hasChildren ? (
              <ToggleButton hasChildren={hasChildren} icon={expandIcon} onClick={_toggleExpanded} />
            ) : (
              <Box width="16px" mr="s" />
            )}
            <Icon iconId={figmaNodeIconMap[type]} />
            <Text pl="xs" maxWidth="260px" truncate>
              {name}
            </Text>
          </Flex>
          <Flex flexShrink="0">
            {hasChildren && (
              <>
                <Button
                  className="nested-children-button"
                  onClick={_onSelectChildren}
                  buttonStyle="tertiary"
                  size="small"
                  text={selectionText}
                />
                {importable && <Box {...separatorBoxProps} />}
              </>
            )}
            {importable && (
              <Button
                id={`figma-node-button-${name}`}
                onClick={_onSelect}
                buttonStyle="tertiary"
                icon={checkBoxIcon}
              />
            )}
          </Flex>
        </Flex>
      </ParentBox>
      {Boolean(expanded && hasChildren) && renderFrameNodesRecursive(children, depth || 0)}
    </>
  );
};

export default FigmaNodeResult;
