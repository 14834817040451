import React, { useState, useContext, useCallback, useEffect } from "react";
import { BannerContext, BannerContextType, initialState, Alert } from "./BannerContext";
import { useObject } from "@hooks/useObject";
import { useSelectSpace } from "@selectors/entities/spaces";

export default function BannerProvider({ children }: { children: React.ReactNode }) {
  const [activeSpaceAlert, setActiveSpaceAlert] = useState<boolean>(initialState.activeSpaceAlert);
  const [alert, setAlert] = useState<Alert>(initialState.alert);

  const space = useSelectSpace();

  const dismissAlert = useCallback(() => {
    if (!alert) return;
    const dismissed = JSON.parse(localStorage.getItem("bannerDismissed") || "[]") as string[];
    localStorage.setItem("bannerDismissed", JSON.stringify([...dismissed, alert.id]));
    setAlert(null);
    setActiveSpaceAlert(false);
  }, [alert, setActiveSpaceAlert]);

  const hasActiveSpaceAlert = useCallback(
    (alert: Alert) => {
      if (!alert) return null;
      const dismissed = JSON.parse(localStorage.getItem("bannerDismissed") || "[]") as string[];
      const { id } = alert;
      if (!dismissed.includes(id)) {
        setActiveSpaceAlert(true);
        return alert;
      }
      return null;
    },
    [setActiveSpaceAlert]
  );

  useEffect(() => {
    setAlert(hasActiveSpaceAlert(space?.alert));
  }, [space?.alert, hasActiveSpaceAlert]);

  useEffect(() => {
    if (!alert) setActiveSpaceAlert(false);
  }, [alert]);

  const context: BannerContextType = useObject({
    activeSpaceAlert: activeSpaceAlert,
    setActiveSpaceAlert: setActiveSpaceAlert,
    alert: alert,
    setAlert: setAlert,
    dismissAlert: dismissAlert,
  });

  return <BannerContext.Provider value={context}>{children}</BannerContext.Provider>;
}

export const useBannerContext = () => useContext(BannerContext);
