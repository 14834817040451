import React, { useCallback } from "react";

import {
  Box,
  Flex,
  Kit,
  KitPermission,
  PrivacyLevel,
  Button,
  useClipboard,
  ToggleBox,
  buildURL,
} from "@thenounproject/lingo-core";

import useUpdateKitPrivacy from "@redux/actions/kits/useUpdateKitPrivacy";
import useKitUpsell, { AccessStatus } from "@hooks/useKitUpsell";

import UpsellTooltip from "../../../spaces/settings/UpsellTooltip";
import KitDownloadRequestsCheckbox from "@features/access-settings/downloadRequetSettings/KitDownloadRequestsCheckbox";
import KitPasswordCheckbox from "@features/access-settings/passwordSettings/KitPasswordCheckbox";
import KitSeoCheckbox from "@features/access-settings/seoSettings/KitSeoCheckbox";
import { showNotification } from "@redux/actions/useNotifications";
import useSpace from "@redux/actions/spaces/useSpace";

type Props = {
  kit: Kit;
};
const KitLinkSettings: React.FC<Props> = ({ kit }) => {
  const { openUpgradeModal, accessStatus } = useKitUpsell(KitPermission.managePublicAccess, kit);
  const { data: space } = useSpace({ spaceId: kit.spaceId });

  const [updateKitPrivacy, { isProcessing }] = useUpdateKitPrivacy();

  const url = buildURL(`/k/${kit.urlId}`, { space, isPublic: true });
  const copyShareLink = useClipboard(url);

  const togglePublicAccess = useCallback(async () => {
    const privacy =
      kit.privacy === PrivacyLevel.private ? PrivacyLevel.public : PrivacyLevel.private;
    const res = await updateKitPrivacy({ kitId: kit.kitId, spaceId: kit.spaceId, privacy });
    if (res.error) {
      showNotification({ level: "error", message: res.error.message });
    }
  }, [kit.kitId, kit.privacy, kit.spaceId, updateKitPrivacy]);

  // The link to dropdown to choose a kit
  function renderDropdown() {
    return (
      <Flex flexDirection="column">
        {/*
        // Leaving this in for now until we wrap up portals and decide if we want to bring it back or not.
        <Text font="ui.smallBold" mb="xs">
          {" "}
          Link to
        </Text> */}
        {/* <Flex> */}
        {/* <Select
            value={""}
            options={[{ label: kit.name, value: "" }]}
            flex="1"
            width="100%"
            pr="s"
            disabled
          /> */}
        <Button
          width="100%"
          text={copyShareLink.hasCopied ? "Copied" : "Copy share link"}
          onClick={copyShareLink.onCopy}
        />
        {/* </Flex> */}
        {/* <Text mt="xs" font="ui.small">
          {url}
        </Text> */}
      </Flex>
    );
  }

  // The public access settings
  function renderPublicAccessSettings() {
    const msg =
      kit.privacy === PrivacyLevel.private
        ? "Only members of your team can access the Kit."
        : "Anyone with the link can access this Kit. No account required";

    return (
      <>
        {accessStatus === AccessStatus.insufficientPlan && (
          <UpsellTooltip
            source="settings-brand-portal-togglebox"
            onClick={openUpgradeModal}
            featureName="Public kit access"
          />
        )}
        <Box data-tooltip-source="settings-brand-portal-togglebox">
          <ToggleBox
            titleBadge={accessStatus === AccessStatus.insufficientPlan ? "Upgrade" : undefined}
            styleOverrides={{ mt: "l" }}
            title="Public access"
            description={msg}
            isOpen={accessStatus === AccessStatus.canAccess && kit.privacy !== PrivacyLevel.private}
            onToggle={togglePublicAccess}
            disabled={accessStatus !== AccessStatus.canAccess || isProcessing}
            control={accessStatus === AccessStatus.insufficientRole ? "none" : "switch"}>
            <Flex flexDirection="column" alignItems="flex-start" gap="8px">
              <KitPasswordCheckbox kit={kit} />
              <KitSeoCheckbox kit={kit} />
              <KitDownloadRequestsCheckbox kit={kit} />
            </Flex>
          </ToggleBox>
        </Box>
      </>
    );
  }

  return (
    <Box>
      {renderDropdown()}
      {renderPublicAccessSettings()}
    </Box>
  );
};

export default KitLinkSettings;
