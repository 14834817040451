import React, { Fragment, useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  Flex,
  Text,
  Icon,
  Button,
  ActivityIndicator,
  Tooltip,
  Kit,
  Space,
  SearchFilterTypes,
  useNavigation,
  Portal,
  buildURL,
} from "@thenounproject/lingo-core";

import formatBytes from "@helpers/bytesToSize";

import { type Upload } from "@actions/uploads";
import ModalBody from "./ModalBody";
import ModalHeader from "./ModalHeader";
import { useAssetLibraryContext } from "@contexts/AssetLibraryProvider";
import { SearchSortTypes } from "@features/library/types";
import {
  useGetKits,
  useGetSpaces,
  useGetUploads,
  useGetUser,
  useGetPortals,
} from "@selectors/getters";
import useShowModal from "@redux/actions/useModals";
import ModalFooter from "./ModalFooter";
import useNavPoint from "@hooks/useNavPoint";

const InfoIcon = styled(Icon).attrs({
  iconId: "info.info",
  fill: "grayDarkest",
  size: "16",
  mx: "xs",
})``;

// Helper Component

type DetailProps = {
  upload: Upload;
  kit?: Kit;
  portal?: Portal;
  space?: Space;
  dismissModal: () => void;
};
const UploadDetailLine: React.FC<DetailProps> = ({
  upload: {
    id: uploadId,
    name: fileName,
    type: fileType,
    size: fileSize,
    status,
    error: { message: errorMessage } = {},
  },
  kit,
  portal,
  space,
  dismissModal,
}) => {
  const navigation = useNavigation();
  const navPoint = useNavPoint();
  const { setFilters, setSortBy } = useAssetLibraryContext();

  const openAsset = useCallback(() => {
    dismissModal();
    // If inside manual gallery, just close modal
    if (navPoint?.itemId && navPoint?.name) return;
    if (kit) navigation.push(buildURL(`k/${kit.urlId}`, { space }));
    if (space) {
      setFilters([]);
      setSortBy(SearchSortTypes.recentDesc);
      navigation.push(buildURL("library", { space }));
    }
  }, [dismissModal, navigation, kit, setFilters, setSortBy, space, navPoint]);

  const renderIcon = () => {
    switch (status) {
      case "pending":
        return <ActivityIndicator color="gray" size="small" mx="xs" />;
      case "started":
        return <ActivityIndicator size="small" mx="xs" />;
      case "success":
        return <Icon iconId="info.checkmark" fill="success" size="24" mx="xs" />;
      case "failed":
        return <Icon iconId="info.error" fill="error" size="24" mx="xs" />;
      default:
        return null;
    }
  };

  const renderAddedToText = useMemo(() => {
    if (navPoint?.itemId && navPoint?.name) return navPoint.name;
    if (kit) return kit.name;
    if (portal) return portal.name;
    return "Library";
  }, [navPoint?.itemId, navPoint?.name, kit, portal]);

  const renderDetailText = () => {
    switch (status) {
      case "failed":
        return (
          <Flex data-tooltip-trigger={uploadId}>
            <Text as="span" color="error" font="ui.small">
              Upload failed
            </Text>
            <InfoIcon data-tooltip-source={uploadId} />
            <Tooltip source={uploadId} direction={Tooltip.Direction.Top}>
              {errorMessage}{" "}
              <Button
                buttonStyle="tertiary"
                themeOverrides={{ primaryColor: "white" }}
                fontStyle="ui.smallBold"
                text="Learn more."
                newWindow
                link="https://help.lingoapp.com/en/articles/1862530-what-types-of-assets-does-lingo-support"
              />
            </Tooltip>
          </Flex>
        );
      case "success":
        return (
          <Fragment>
            <Text as="span" color="grayDarker" font="ui.small">
              Added to{" "}
              <Button
                text={renderAddedToText}
                onClick={openAsset}
                buttonStyle="tertiary"
                size="small"
              />
            </Text>
          </Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <Flex data-testid={uploadId} alignItems="center" borderBottom="1px solid grayLight" py="m">
      <Flex alignItems="center" width="100%" gap="8px" height="24px">
        <Text flex="240px 0 1" width="100%" truncate font="ui.regular">
          {fileName}
        </Text>
        <Flex flex="60px 0 0">
          <Text font="ui.small" color="grayDarker" truncate>
            {fileType}
          </Text>
        </Flex>
        <Flex flex="60px 0 0">
          <Text font="ui.small" color="grayDarker" truncate>
            {fileSize ? formatBytes(fileSize).replace(" ", "") : ""}
          </Text>
        </Flex>
        <Flex flex="120px 1 1">{renderDetailText()}</Flex>
        <Flex flex={"24px 0 0"}>{renderIcon()}</Flex>
      </Flex>
    </Flex>
  );
};

// Main Component
const UploadDetailsModal = () => {
  const spaces = useGetSpaces();
  const kits = useGetKits();
  const portals = useGetPortals();

  const uploads = useGetUploads();
  const { dismissModal } = useShowModal();
  const { setFilters, setSortBy } = useAssetLibraryContext();
  const navigation = useNavigation();
  const currentUser = useGetUser();

  const recentUploads = uploads.recentUploads.map(id => uploads.uploads[id]);
  const footerButtonProps = useMemo(() => {
    const allUploads = Object.values(uploads.uploads);
    const isAssetsOnly = allUploads.every(upload => upload.upload.isAsset);
    if (!isAssetsOnly) return null;

    // Disabled if any are pending OR if none of them successfully uploaded
    const disabled =
      allUploads.some(upload => ["pending", "started"].includes(upload.upload.status)) ||
      !allUploads.some(upload => upload.upload.status === "success");

    const onClick = () => {
      const successful = Object.values(uploads.uploads)
        .filter(upload => upload.upload.status === "success")
        .sort((a, b) => a.upload.dateAdded.getDate() - b.upload.dateAdded.getDate());

      const first = successful[0];
      const last = successful[successful.length - 1];
      const space = spaces[first.upload.insertPosition.spaceId];
      setFilters([
        {
          type: SearchFilterTypes.date,
          display: "Recent uploads",
          operator_values: {
            gte: first.upload.dateAdded.toISOString(),
            lte: last.upload.dateAdded.toISOString(),
          },
        },
        {
          type: SearchFilterTypes.uploader,
          display: "Me",
          id: `uploader:${currentUser?.id}`,
          value: String(currentUser?.id),
          user_id: currentUser?.id,
        },
      ]);
      setSortBy(SearchSortTypes.recentDesc);
      navigation.push(buildURL("library", { space }));
      dismissModal();
    };

    return {
      text: "View assets in library",
      disabled,
      onClick,
    };
  }, [currentUser?.id, dismissModal, navigation, setFilters, setSortBy, spaces, uploads]);

  return (
    <Fragment>
      <ModalHeader title="Upload Details" />
      <ModalBody>
        {recentUploads.map(({ upload }) => {
          return (
            <UploadDetailLine
              key={upload.id}
              upload={upload}
              kit={kits[upload.insertPosition.kitId]}
              portal={portals[upload.insertPosition.portalId]}
              space={spaces[upload.insertPosition.spaceId]}
              dismissModal={dismissModal}
            />
          );
        })}
      </ModalBody>
      {footerButtonProps && <ModalFooter primary={footerButtonProps} />}
    </Fragment>
  );
};

export default UploadDetailsModal;
