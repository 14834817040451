/* eslint-disable no-alert */
import React, { useState, useEffect, Fragment, useCallback, ComponentProps, useRef } from "react";
import styled from "styled-components";
import { Box, Flex, Button, utils, SearchFilterInput, Icon } from "@thenounproject/lingo-core";

import suggestionTypes from "../../constants/suggestionTypes";
import SearchModalSuggestion from "./SearchModalSuggestion";
import SearchModalSuggestionHeader from "./SearchModalSuggestionHeader";
import constructKeywordFilter from "@helpers/constructKeywordFilter";

import KeyCode from "../../constants/keyCodes";
import useClearQueryFilter from "@actions/search/useClearQueryFilter";
import useClearRecentSearches from "@actions/search/useClearRecentSearches";
import useShowModal from "@redux/actions/useModals";
import useSetSearchContext, {
  SearchContext,
  SearchContextState,
} from "@redux/actions/search/useSetSearchContext";

const Wrapper = styled(Flex).attrs({
  flexWrap: "wrap",
  as: "form",
  alignItems: "center",
  flex: "0",
})`
  input {
    flex-shrink: 1;
    flex-grow: 1;
    width: unset;
    min-width: 40px;
    border: none;
    padding: 0;
    margin: 0;
  }
`;

const InputElement = styled(Flex).attrs({
  flexWrap: "wrap",
  alignItems: "center",
  borderBottom: "1px solid gray",
  width: "100%",
  position: "relative",
  p: "14px",
})``;

const InputContentWrapper = styled(Flex).attrs({
  flexWrap: "wrap",
  alignItems: "center",
  width: "100%",
  flex: 1,
  position: "relative",
  mr: "xs",
  gap: "8px",
})`
  ${props => utils.getFont("ui.regular", null, props)}
`;

const SuggestionContainer = styled(Box).attrs({
  width: "100%",
  overflow: "auto",
  maxHeight: "calc(100vh - 230px)",
  position: "absolute",
  backgroundColor: "white",
  zIndex: 100,
  top: 57, // height of search input
  boxShadow: "panel",
})``;

// this makes it so that when you click away from the input/suggestions box, you're not accidentally clicking on search results
const SuggestionsOpenOverlay = styled(Box).attrs({
  width: "100%",
  height: "423px",
  position: "absolute",
  backgroundColor: "transparent",
  zIndex: 99,
  top: 57,
})``;

/**
 *  A constant defining the 3 states the suggestion UI can be in
 *
 * recent: Shows prebuilt queries and recent searches
 * auto: The default suggestions when typing text (includes a mix of filters and jump to suggestions)
 * filter: Suggestions for a specific filter type
 */

const SuggestionState = Object.freeze({
  recent: 0,
  auto: 1,
  filter: 2,
});

type Props = {
  localQuery: string;
  context: SearchContextState;
  suggestions: SearchFilterInput[];
  matchedFilterType: string;
  recentSearches: SearchFilterInput[][];
  onChange: (value: string) => void;
  onSubmit: React.FormEventHandler<HTMLDivElement>;
  onSelectSuggestion: ComponentProps<typeof SearchModalSuggestion>["onSelectSuggestion"];
  inputRef: React.MutableRefObject<HTMLInputElement>;
};

export default function SearchModalInput({
  localQuery,
  suggestions,
  matchedFilterType,
  recentSearches,
  onChange,
  onSubmit,
  onSelectSuggestion,
  inputRef,
  context,
}: Props) {
  const { dismissModal } = useShowModal();
  const [focusIndex, setFocusIndex] = useState(null);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  const allSuggestions = suggestions || [];
  const jumpSuggestions = allSuggestions.filter(s => s.type === suggestionTypes.jump);
  const autoSuggestions = allSuggestions.filter(s => s.type !== suggestionTypes.jump);
  const matchedSuggestions = allSuggestions;

  const hasQuery = Boolean(localQuery);

  const setSearchContext = useSetSearchContext();

  const clearQueryFilter = useClearQueryFilter(),
    clearRecentSearches = useClearRecentSearches();

  /**
   * The length of the currently presented suggestions needs to be
   * calculated so that the focus index stays in sync.
   */
  const suggestionState = (() => {
    if (matchedFilterType) return SuggestionState.filter;
    if (hasQuery) return SuggestionState.auto;
    return SuggestionState.recent;
  })();

  const currentSuggestionLength = (() => {
    switch (suggestionState) {
      case SuggestionState.filter:
        return matchedSuggestions.length + 1;
      case SuggestionState.auto:
        return autoSuggestions.length + jumpSuggestions.length;
      case SuggestionState.recent:
      default:
        return recentSearches.length;
    }
  })();

  /**
   * Listen for up & down arrows and manipulate the
   * focus index based on how many suggestions are
   * currently being displayed.  There may be a better way
   * to do this than putting the event on the window.  TBD.
   */
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.metaKey && e.key === KeyCode.backspace) {
        clearQueryFilter();
      } else if (
        !e.metaKey &&
        !e.shiftKey &&
        [KeyCode.upArrow, KeyCode.downArrow].includes(e.key)
      ) {
        e.preventDefault();
        const up = e.key === KeyCode.upArrow;
        if (focusIndex === null) {
          return setFocusIndex(!up ? 0 : currentSuggestionLength - 1);
        }
        if ((focusIndex === currentSuggestionLength - 1 && !up) || (focusIndex === 0 && up)) {
          inputRef.current?.focus();
          return setFocusIndex(null);
        }

        setFocusIndex(up ? focusIndex - 1 : focusIndex + 1);
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    clearQueryFilter,
    currentSuggestionLength,
    focusIndex,
    inputRef,
    setSearchContext,
    autoSuggestions,
    matchedSuggestions,
    onChange,
    onSubmit,
    recentSearches,
  ]);

  // Whenever the input is refocused, the focus index is reset
  const handleInputFocus = useCallback(() => {
    setFocusIndex(null);
    setSuggestionsVisible(true);
  }, []);

  const handleInputBlur = () => {
    setTimeout(() => {
      if (!suggestionsRef.current || !suggestionsRef.current.contains(document.activeElement)) {
        setSuggestionsVisible(false);
      }
    }, 100);
  };
  // Remove the last filter if the input is empty and the user hits backspace
  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace" && !localQuery && !e.currentTarget.value) {
        if (context.context === SearchContext.portal) {
          setSearchContext({ context: SearchContext.global });
        }
      }
    },
    [context, localQuery, setSearchContext]
  );

  const handleSuggestionSelect = useCallback(
    (type, data) => {
      setSuggestionsVisible(false);
      inputRef.current?.blur();
      onSelectSuggestion(type, data);
    },
    [onSelectSuggestion, inputRef]
  );

  /**
   * TODO: There's probably a way to compose these render functions
   *  in a way thats more DRY
   */
  function renderRecentSuggestions() {
    return (
      <Fragment>
        {Boolean(recentSearches.length) && (
          <Fragment>
            <SearchModalSuggestionHeader
              label="Recent Searches"
              actionText="Clear all"
              onClick={clearRecentSearches}
            />
            {recentSearches.map((suggestion, i) => {
              return (
                <SearchModalSuggestion
                  key={`recentSuggestion-${i}`}
                  shouldFocus={focusIndex === i}
                  type={suggestionTypes.recent}
                  data={suggestion}
                  onSelectSuggestion={handleSuggestionSelect}
                />
              );
            })}
          </Fragment>
        )}
      </Fragment>
    );
  }

  function renderAutoSuggestions() {
    return (
      <Fragment>
        {autoSuggestions.map((suggestion, i) => {
          return (
            <SearchModalSuggestion
              key={`${suggestion.id}-${suggestion.display}`}
              shouldFocus={focusIndex === i}
              type={suggestion.style === "token" ? suggestionTypes.filter : suggestionTypes.keyword}
              data={suggestion}
              onSelectSuggestion={handleSuggestionSelect}
            />
          );
        })}
        {Boolean(jumpSuggestions.length) && (
          <Fragment>
            <SearchModalSuggestionHeader label="Jump to" />
            {jumpSuggestions.map((suggestion, i) => {
              const perceivedIndex = i + autoSuggestions.length;
              return (
                <SearchModalSuggestion
                  key={`${suggestion.id}-${suggestion.display}`}
                  shouldFocus={focusIndex === perceivedIndex}
                  type={suggestionTypes.jump}
                  data={suggestion}
                  onSelectSuggestion={handleSuggestionSelect}
                />
              );
            })}
          </Fragment>
        )}
      </Fragment>
    );
  }

  function renderFilterMatchSuggestions() {
    return (
      <Fragment>
        <SearchModalSuggestion
          key="fiter-match-default"
          shouldFocus={focusIndex === 0}
          type={suggestionTypes.keyword}
          data={constructKeywordFilter(localQuery)}
          onSelectSuggestion={handleSuggestionSelect}
        />
        <Fragment>
          <SearchModalSuggestionHeader label={`Choose ${matchedFilterType}`} />
          {matchedSuggestions.map((suggestion, i) => {
            const perceivedIndex = i + 1;
            return (
              <SearchModalSuggestion
                key={`${suggestion.id}-${suggestion.display}`}
                shouldFocus={focusIndex === perceivedIndex}
                type={suggestionTypes.filter}
                data={suggestion}
                onSelectSuggestion={handleSuggestionSelect}
              />
            );
          })}
        </Fragment>
      </Fragment>
    );
  }

  function renderSuggestions() {
    switch (suggestionState) {
      case SuggestionState.filter:
        return renderFilterMatchSuggestions();
      case SuggestionState.auto:
        return renderAutoSuggestions();
      case SuggestionState.recent:
        return renderRecentSuggestions();
      default:
        return null;
    }
  }

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <Wrapper onSubmit={onSubmit}>
      <InputElement onClick={focusInput} ref={inputWrapperRef}>
        <InputContentWrapper>
          <Icon iconId="search" fill="black" />
          <input
            ref={inputRef}
            type="text"
            placeholder="Search Lingo"
            autoComplete="off"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontSize: "14px",
              lineHeight: "24px",
            }}
            value={localQuery}
            onChange={e => {
              onChange(e.target.value);
            }}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
          />
        </InputContentWrapper>
        <Flex>
          {hasQuery && (
            <Button
              pr="s"
              tintColor="grayDarkest"
              buttonStyle="tertiary"
              size="small"
              text="Clear"
              onClick={() => {
                clearQueryFilter();
              }}
            />
          )}
          <Button
            buttonStyle="tertiary"
            icon="action.close"
            themeOverrides={{ primaryColor: "black" }}
            onClick={dismissModal}
            height={"28px"}
            width={"28px"}
          />
        </Flex>
      </InputElement>

      {suggestionsVisible && (
        <>
          <SuggestionContainer ref={suggestionsRef}>{renderSuggestions()}</SuggestionContainer>
          <SuggestionsOpenOverlay onClick={() => setSuggestionsVisible(false)} />
        </>
      )}
    </Wrapper>
  );
}
