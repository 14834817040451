/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useCallback } from "react";

import {
  ToggleBox,
  slugifyName,
  Button,
  Input,
  useClipboard,
  Space,
} from "@thenounproject/lingo-core";

import useRegenerateJoinLink from "@redux/actions/spaces/useRegenerateJoinLink";
import useDeleteJoinLink from "@redux/actions/spaces/useDeleteJoinLink";
import useNotifications from "@actions/useNotifications";

import { SettingOption, SettingsButton } from "./SettingsElements";

type Props = {
  space: Space;
};

const SettingsJoinLink: React.FC<Props> = ({ space }) => {
  const [regenerateJoinLink] = useRegenerateJoinLink(),
    [deleteJoinLink] = useDeleteJoinLink(),
    { showNotification } = useNotifications();

  if (!space) return null;
  const {
    joinSettings: { token: joinToken },
  } = space;
  const url = `${window.location.origin}/join/${slugifyName(space.name)}/${joinToken}/`;

  const [isOpen, setIsOpen] = useState(Boolean(joinToken));
  const [loading, setLoading] = useState(false);

  const onToggle = useCallback(async () => {
    const wasOpen = isOpen;
    const action = wasOpen ? deleteJoinLink : regenerateJoinLink;
    setLoading(true);
    const { error } = await action(space.id);
    setLoading(false);
    if (error) {
      showNotification({ message: error.message, level: "error" });
    } else {
      setIsOpen(!wasOpen);
    }
  }, [isOpen, deleteJoinLink, regenerateJoinLink, space.id, showNotification]);

  const handleRegenerate = useCallback(async () => {
    if (loading) return null;
    setLoading(true);
    const { error } = await regenerateJoinLink(space.id);
    setLoading(false);
    if (error) {
      showNotification({ message: error.message, level: "error" });
    } else {
      showNotification({
        message: "Link regenerated. The old link is now unusable.",
        level: "info",
      });
    }
  }, [loading, regenerateJoinLink, showNotification, space.id]);

  const { onCopy, hasCopied, onClickInputToCopy } = useClipboard(url);

  function renderLink() {
    if (!joinToken) return null;
    return (
      <SettingOption>
        <Input
          readOnly
          type="text"
          id="join-link"
          value={url}
          message={regenerateText()}
          onClick={onClickInputToCopy}>
          <SettingsButton text={hasCopied ? "Copied" : "Copy"} onClick={onCopy} />
        </Input>
      </SettingOption>
    );
  }

  function regenerateText() {
    return (
      <>
        <Button
          id="regen-join-token-button"
          text="Regenerate link"
          buttonStyle="tertiary"
          size="small"
          onClick={handleRegenerate}
        />{" "}
        to invalidate the current join link. This cannot be undone.
      </>
    );
  }

  const toggleBoxProps = {
    id: "settings-join-link-toggle",
    title: "Join link",
    description: "Allow anyone to join your space with this link.",
    isOpen,
    onToggle,
    disabled: loading,
    styleOverrides: { mb: "l" },
  };

  return <ToggleBox {...toggleBoxProps}>{renderLink()}</ToggleBox>;
};

export default SettingsJoinLink;
