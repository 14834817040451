import React, { useState } from "react";
import { TabBar, Space } from "@thenounproject/lingo-core";

import ModalHeader from "../../ModalHeader";
import InsightsPortalViewTab from "./InsightsPortalViewTab";
import InsightsPortalAssetsTab from "./InsightsPortalAssetsTab";
import InsightsPortalKitsTab from "./InsightsPortalKitsTab";
import { InsightsPageWrapper } from "../ReportElements";

type Props = {
  portalId: string;
  portalName: string;
  space: Space;
};

enum Tab {
  views = "views",
  kits = "kits",
  assets = "assets",
}

const InsightsPortalModal = ({ portalId, portalName, space }: Props) => {
  const [selectedTab, setSelectedTab] = useState<string>(Tab.views);

  function renderTab() {
    switch (selectedTab) {
      case Tab.views:
        return <InsightsPortalViewTab portalId={portalId} space={space} />;
      case Tab.kits:
        return <InsightsPortalKitsTab portalId={portalId} space={space} />;
      case Tab.assets:
        return <InsightsPortalAssetsTab portalId={portalId} space={space} />;
      default:
        return null;
    }
  }

  return (
    <>
      <ModalHeader title={portalName || "Portal"}>
        <TabBar>
          <TabBar.Item
            text="Views"
            selected={selectedTab === Tab.views}
            onClick={() => setSelectedTab(Tab.views)}
          />
          <TabBar.Item
            text="Kits"
            selected={selectedTab === Tab.kits}
            onClick={() => setSelectedTab(Tab.kits)}
          />
          <TabBar.Item
            text="Assets"
            selected={selectedTab === Tab.assets}
            onClick={() => setSelectedTab(Tab.assets)}
          />
        </TabBar>
      </ModalHeader>
      <InsightsPageWrapper>{renderTab()}</InsightsPageWrapper>
    </>
  );
};

export default InsightsPortalModal;
