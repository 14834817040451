import { v4 as genUuid } from "uuid";

import { enqueueUpload } from "@actions/uploads/enqueueUpload";
import { getItemData, type UploadAction, type InsertPosition } from "../uploads";
import { createAsyncAction } from "../actionCreators";
import { AssetType } from "@thenounproject/lingo-core";

type Args = {
  link: string;
  insertPosition: InsertPosition;
};
const [useCreateLinkAsset, createLinkAsset] = createAsyncAction<void, Args>(
  "assets/createLink",
  ({ link, insertPosition }: Args, thunkApi) => {
    const { spaceId } = insertPosition;

    const { entity, item } = getItemData(insertPosition),
      uploadAction: UploadAction = {
        upload: {
          id: genUuid().toUpperCase(),
          status: "pending",
          name: link,
          type: "url",
          insertPosition,
          isAsset: true,
        },
        requestData: {
          entity,
          endpoint: "assets",
          method: "POST",
          data: {
            space_id: spaceId,
            type: AssetType.URL,
            url: link,
            item,
          },
        },
      };

    thunkApi.dispatch(enqueueUpload(uploadAction));
    return Promise.resolve();
  }
);

export default useCreateLinkAsset;
export { createLinkAsset };
