import styled from "styled-components";
import { Box, Flex, BoxProps, Text } from "@thenounproject/lingo-core";

export const HeaderWrapper = styled(Flex).attrs({
  position: "sticky",
  flexDirection: "column",
  top: 0,
  width: "100%",
  background: "white",
  zIndex: 10,
})``;

export const HeaderInner = styled(Box).attrs((props: BoxProps) => {
  return {
    flexDirection: "column",
    width: "100%",
    py: props.py || "24px",
    px: props.px || "40px",
    borderBottom: "default",
  };
})``;

export const TableHeadWrapper = styled(Flex).attrs<{ top: string }>(() => {
  return {
    height: "44px",
    borderBottom: "default",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: "60",
    background: "grayLightest",
    position: "sticky",
    top: 0,
  };
})`
  overflow: auto;
`;

export const TableCellText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;

export const TableRowWrapper = styled(Flex).attrs({
  height: "100%",
  borderBottom: "default",
  alignItems: "center",
  variations: {
    "&:hover": {
      background: "grayLighter",
    },
  },
})`
  .asset_row_hover {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  &:hover {
    .asset_row_hover {
      opacity: 1;
    }
  }
`;
