import { v4 as genUuid } from "uuid";

import { AssetType } from "@thenounproject/lingo-core";

import { InsertPosition, UploadAction, getItemData } from "@actions/uploads";
import { enqueueUpload } from "../uploads/enqueueUpload";
import { createAsyncAction } from "../actionCreators";

type Color = {
  name?: string;
  hue: number;
  saturation: number;
  brightness: number;
  alpha: number;
  cmyk: string;
  pms: string;
  coverage: number;
};

type Args = {
  color: Color;
  insertPosition: InsertPosition;
};
const [useCreateColorAsset, createColorAsset] = createAsyncAction(
  "assets/createColor",
  ({ color: providedColor, insertPosition }: Args, thunkApi) => {
    const { spaceId } = insertPosition;
    const { name, ...color } = providedColor;
    const { entity, item } = getItemData(insertPosition),
      uploadAction: UploadAction = {
        upload: {
          id: genUuid().toUpperCase(),
          status: "pending",
          name,
          type: AssetType.color,
          insertPosition,
          isAsset: true,
        },
        requestData: {
          entity,
          endpoint: "assets",
          method: "POST",
          data: {
            space_id: spaceId,
            type: AssetType.color,
            name,
            colors: [color],
            item,
          },
        },
      };

    thunkApi.dispatch(enqueueUpload(uploadAction));
    return Promise.resolve();
  }
);

export default useCreateColorAsset;
export { createColorAsset };
