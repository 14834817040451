/*
 * Wrapper component that manages overlays.
 *
 * Overlay is similar to Modal, in that it is displayed on top of other content
 */

import React from "react";
import styled from "styled-components";

import { Box, KitPermission } from "@thenounproject/lingo-core";
import KitAssetDetail from "./kits/KitAssetDetail";
import { NavPoint, NavPointTypes } from "@redux/legacy-actions/navPoints";
import { SUPER_NAV_WIDTH } from "@constants/layouts";

type ContainerProps = {
  showSuperNav: boolean;
};

export const AssetOverlayContainer = styled(Box).attrs<ContainerProps>(props => {
  return {
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0,
    left: props.showSuperNav ? SUPER_NAV_WIDTH : 0,
    right: 0,
    height: "100%",
    background: "white",
    zIndex: "assetOverlay",
    borderRadius: props.showSuperNav ? `4px 0 0 4px` : "0",
    variations: {
      "mq.s": {
        left: 0,
      },
    },
  };
})<ContainerProps>`
  transition: opacity 1s ease;
`;

type Props = {
  navPoint: NavPoint;
  showSuperNav: boolean;
};

function AssetOverlay({ navPoint, showSuperNav }: Props) {
  if (!navPoint) return null;

  const { error, type, kit, kitVersion } = navPoint;
  if (error) return null;
  const canViewKit = kit?.access?.permissions?.includes(KitPermission.view) === true && kitVersion;
  if (type !== NavPointTypes.Item || !canViewKit) return null;

  return (
    <AssetOverlayContainer data-testid="asset-overlay" showSuperNav={showSuperNav}>
      <KitAssetDetail />
    </AssetOverlayContainer>
  );
}

export default AssetOverlay;
