import React, { useMemo, useCallback } from "react";
import {
  formatDate,
  Flex,
  Button,
  Space,
  Box,
  TableCellText,
  TableCell,
} from "@thenounproject/lingo-core";

import InsightsIntervalPicker from "../InsightsIntervalPicker";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import useKitAssetPerformance, { KitAssetResult } from "../queries/useKitAssetPerformance";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";
import assetIconMap from "../../kits/GalleryAssetContent/assetIconMap";
import getAssetBackground from "@helpers/getAssetBackground";

type Props = {
  kitId: string;
  space: Space;
};

const InsightsKitAssetsTab = ({ kitId, space }: Props) => {
  const [sort, setSort] = React.useState<string>("-total_uses");
  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const hasPortalsFeature = space?.features?.includes("portals");
  const { dateOperators } = useInsightsContext();

  const {
    data,
    isLoading: tableIsLoading,
    fetchNextPage,
    error: tableError,
  } = useKitAssetPerformance({
    spaceId: space.id,
    sort,
    dateOperators,
    kitId,
  });

  const { items: tableData, total } = data || {};

  const hasMorePages = tableData?.length < total;

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "assets",
      sort,
      dateOperators,
      additionalParams: { ["kit_uuid"]: kitId },
    });
  }, [dateOperators, generateInsightsCSV, kitId, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Asset Performance",
      subtitle: "Breakdown of how specific assets in the kit were downloaded.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"user-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<KitAssetResult>[] = [
      {
        sortKey: "name",
        name: "Asset Name",
        minWidth: 280,
        flexGrow: 1,
        renderer: i => {
          return (
            <TableCell
              data={i.asset}
              space={space}
              icon={assetIconMap[i.asset.type]}
              background={getAssetBackground(i.asset, space.theme).background}
            />
          );
        },
      },
      {
        name: "Section",
        minWidth: 140,
        renderer: i => <TableCellText>{i.usage.sectionName || "Unnamed section"}</TableCellText>,
      },
      {
        sortKey: "total_uses",
        name: "Total Downloads",
        minWidth: 140,
        justify: "flex-end",
        renderer: i => <TableCellText>{i?.usage?.totalUses}</TableCellText>,
      },
      {
        sortKey: "member_uses",
        name: "User Downloads",
        minWidth: 140,
        justify: "flex-end",
        renderer: i => <TableCellText>{i?.usage?.memberUses}</TableCellText>,
      },
      {
        sortKey: "guest_uses",
        name: "Visitor Downloads",
        minWidth: 150,
        justify: "flex-end",
        renderer: i => <TableCellText>{i?.usage?.guestUses}</TableCellText>,
      },
      {
        sortKey: "asset_type",
        name: "Type",
        minWidth: 90,
        renderer: i => <TableCellText>{i?.asset.type}</TableCellText>,
      },
      {
        name: "Date Added",
        minWidth: 120,
        renderer: i => <TableCellText>{formatDate(i.dateAdded, "MMM d, yyyy")}</TableCellText>,
      },
    ];

    if (hasPortalsFeature) {
      cols.splice(2, 0, {
        name: "Portals",
        minWidth: 160,
        renderer: i => <TableCellText>{i.usage.portalName}</TableCellText>,
      });
    }
    return cols;
  }, [hasPortalsFeature, space]);

  return (
    <Box mt="l">
      <DataTable<KitAssetResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </Box>
  );
};

export default InsightsKitAssetsTab;
