/* eslint-disable jsx-a11y/aria-role */

import React from "react";

import content, { Plan, Feature } from "../data/pricing_SELF_SERVE";

import { Text, Flex, Box, Icon, Tooltip, utils } from "@thenounproject/lingo-core";

export default function PlanComparisonTable({
  period,
  features,
  name,
}: {
  period: "monthly" | "yearly";
  features: Feature[];
  name: string;
  subtext?: string;
}) {
  type FeatureState = string | number | boolean | { text: string; subtext: string };
  const plans = content.plans;
  function ComparisonRow({
    title,
    tooltip,
    values,
  }: {
    title: string;
    tooltip?: string;
    values: FeatureState[];
  }) {
    function renderColumn(state: FeatureState) {
      if (state === 1 || state === true)
        return (
          <Flex
            width="20px"
            height="20px"
            alignItems="center"
            justifyContent="center"
            borderRadius="10px"
            background="primary">
            <Icon iconId="checkmark" fill="white" size="16" />
          </Flex>
        );
      if (!state) return <Text color="grayDark">—</Text>;
      if (typeof state === "object")
        return (
          <Flex flexDirection="column">
            <Text font="ui.regular">{state.text}</Text>
            <Text color="grayDarkest" font="ui.small">
              {state.subtext}
            </Text>
          </Flex>
        );
      return <Text font="ui.regular">{state}</Text>;
    }
    const id = encodeURI(title);
    return (
      <Flex
        width="100%"
        height="100%"
        flex="1"
        justifyContent="space-around"
        alignItems="center"
        textAlign="center"
        borderBottom="default"
        variations={{ "mq.m": { flexWrap: "wrap" } }}>
        {tooltip && (
          <Tooltip source={id} direction={Tooltip.Direction.Right}>
            {tooltip}
          </Tooltip>
        )}
        <Flex
          flex="1"
          width="100%"
          height="100%"
          justifyContent="space-between"
          alignItems="center"
          px="m"
          py="l"
          variations={{
            "mq.m": {
              flexBasis: `100%`,
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              px: "s",
              py: "m",
            },
          }}>
          <Text textAlign="left" position="relative" font="ui.regularBold">
            {title}
          </Text>
          <Icon iconId="info.info" data-tooltip-source={id} size="16" />
        </Flex>
        {values.map((state, idx) => (
          <Flex
            key={`${title}-${idx}`}
            flex="1"
            justifyContent="center"
            alignItems="center"
            minHeight="80px"
            px="m"
            py="l"
            background={idx === 1 ? `${utils.getColor("bestValueBackground")}` : "white"}
            variations={{
              "mq.m": {
                flexBasis: `33%`,
                backgroundColor: "white",
                minHeight: "0",
              },
            }}>
            {renderColumn(state)}
          </Flex>
        ))}
      </Flex>
    );
  }

  function renderPlanHeader(plan: Plan) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        height="100%"
        flexDirection="column"
        flex="1"
        key={`${plan.name}-header`}
        variations={{
          "mq.xs": {
            width: "25%",
            textAlign: "center",
          },
        }}>
        <Text as="h3" font="marketing.subtitle1" truncate>
          {plan.name}
        </Text>
        {plan.id === "enterprise" ? (
          <Text>Custom pricing</Text>
        ) : (
          <>
            <Text font="ui.regular" variations={{ "mq.s": { textAlign: "center" } }}>
              ${plan[period].base_price}/mo + ${plan[period].editor_cost}/editor/mo
            </Text>
            <Text color="grayDarkest" font="ui.small">
              billed {period === "yearly" ? "annually" : "monthly"}
            </Text>
          </>
        )}
      </Flex>
    );
  }

  return (
    <Box mt="xl">
      <Flex
        width="100%"
        mb="m"
        alignItems="center"
        variations={{ "mq.m": { flexWrap: "wrap", gap: "24px", alignItems: "flex-start" } }}>
        <Flex
          flex="1.01"
          alignItems="center"
          height="100%"
          variations={{ "mq.m": { flex: "100%", justifyContent: "center", textAlign: "center" } }}>
          <Text font="marketing.h4">{name}</Text>
        </Flex>
        {plans.map(renderPlanHeader)}
      </Flex>
      <Flex border="default" borderBottom="none" borderRadius="4px" flexDirection="column">
        {features.map((f: Feature) => (
          <ComparisonRow
            key={f.id}
            title={f.name}
            tooltip={f.description}
            values={plans.map(p => p.features[f.id])}
          />
        ))}
      </Flex>

      <Flex textAlign="center" mt="m">
        <Box flex="24" width="20%"></Box>
        <Box flex="16px" width="16px"></Box>
      </Flex>
    </Box>
  );
}
