import React, { useState, useCallback } from "react";
import { Box, Text, Input } from "@thenounproject/lingo-core";

import useVerifyPassword from "@redux/actions/auth/useVerifyPassword";
import useShowModal from "@redux/actions/useModals";

import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import ModalBody from "../../ModalBody";

type Props = {
  onSuccess: (password: string) => void;
};

const TFAPasswordVerifyModal = ({ onSuccess }: Props) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [verifyPassword, { isProcessing }] = useVerifyPassword();
  const { dismissModal } = useShowModal();

  const submit = useCallback(async () => {
    const { error } = await verifyPassword(password);
    if (error) {
      setError(error.message);
    } else {
      dismissModal();
      onSuccess(password);
    }
  }, [password, verifyPassword, onSuccess, dismissModal]);

  return (
    <>
      <ModalHeader title="Two-factor authentication" />
      <ModalBody>
        <Box>
          <Text font="ui.smallBold" mb="4">
            For security, enter your password to continue
          </Text>
          <Input
            type="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="•••••••"
            message={error}
            inputStyle={error ? "error" : null}
          />
        </Box>
      </ModalBody>
      <ModalFooter
        primary={{
          text: "Continue",
          onClick: submit,
          disabled: !password.length || isProcessing,
        }}
      />
    </>
  );
};

export default TFAPasswordVerifyModal;
