import React, { ComponentProps, Fragment, useMemo } from "react";
import { Box, GlobalStyle, openIntercom } from "@thenounproject/lingo-core";
import { getMetaForPath } from "shared";

import SEO from "../../components/SEO";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Modal from "../../../components/modal";
import CookieConsent from "../../components/CookieConsent";
import Alerts from "../../../components/Alerts";
import CardSection from "../../components/sections/CardSection";
import HeroSection from "../../components/sections/HeroSection";
import MediaSection from "../../components/sections/MediaSection";
import TitleSection from "../../components/sections/TitleSection";
import CtaSection from "../../components/sections/CtaSection";
import ShowcaseSection from "../../components/sections/ShowcaseSection";
import TextGridSection from "../../components/sections/TextGridSection";
import CustomerLogosSection from "../../components/sections/CustomerLogosSection";
import FaqSection from "../../components/FaqSection";
import FormSection from "../../components/sections/FormSection";
import ScrollToTop from "../../../components/ScrollToTopMarketing";

export type SectionContent =
  | ({ type: "CardSection" } & ComponentProps<typeof CardSection>)
  | ({ type: "HeroSection" } & ComponentProps<typeof HeroSection>)
  | ({ type: "MediaSection" } & ComponentProps<typeof MediaSection>)
  | ({ type: "TitleSection" } & ComponentProps<typeof TitleSection>)
  | ({ type: "CtaSection" } & ComponentProps<typeof CtaSection>)
  | ({ type: "ShowcaseSection" } & ComponentProps<typeof ShowcaseSection>)
  | ({ type: "TextGridSection" } & ComponentProps<typeof TextGridSection>)
  | ({ type: "FaqSection" } & ComponentProps<typeof FaqSection>)
  | ({ type: "CustomerLogosSection" } & ComponentProps<typeof CustomerLogosSection>)
  | ({ type: "FormSection" } & ComponentProps<typeof FormSection>);

export type PageContent = {
  style: "light" | "dark" | "darkGradient" | "lightGradient";
  sections?: SectionContent[];
};

export default function PageLayout({
  style,
  sections = [],
  children,
}: PageContent & { children?: React.ReactNode }) {
  const path = window.location.pathname;
  /**
   * We now hide Intercom chat bubble by default.
   * Show it on marketing pages by default without opening the dialog.
   */
  React.useEffect(() => {
    const tryOpenIntercom = setTimeout(() => {
      openIntercom({ launchChat: false });
    }, 2000);
    return () => clearTimeout(tryOpenIntercom);
  }, []);

  const sectionComponents = useMemo(
    () =>
      sections.map((s, idx) => {
        switch (s.type) {
          case "CardSection":
            return <CardSection key={`${idx}`} {...s} />;
          case "HeroSection":
            return <HeroSection key={`${idx}`} {...s} />;
          case "MediaSection":
            return <MediaSection key={`${idx}`} {...s} />;
          case "TitleSection":
            return <TitleSection key={`${idx}`} {...s} />;
          case "CtaSection":
            return <CtaSection key={`${idx}`} {...s} />;
          case "ShowcaseSection":
            return <ShowcaseSection key={`${idx}`} {...s} />;
          case "TextGridSection":
            return <TextGridSection key={`${idx}`} {...s} />;
          case "CustomerLogosSection":
            return <CustomerLogosSection key={`${idx}`} {...s} />;
          case "FaqSection":
            return <FaqSection key={`${idx}`} {...s} />;
          case "FormSection":
            return <FormSection key={`${idx}`} {...s} />;
          default:
            return null;
        }
      }),
    [sections]
  );

  return (
    <Fragment>
      <Alerts />
      <GlobalStyle />
      <Box>
        <SEO {...getMetaForPath(path)} />
        <ScrollToTop />
        <Header headerTheme={style} />
        {sectionComponents}
        {children}
        <Footer />
        <CookieConsent />
        <Modal />
      </Box>
    </Fragment>
  );
}
