import React, { useCallback, useState } from "react";
import { Box, Input, Space, useNavigation, buildURL } from "@thenounproject/lingo-core";

import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import ModalBody from "../../../components/ModalBody";

import useShowModal from "@redux/actions/useModals";
import useCreatePortal from "@redux/actions/kits/useCreatePortal";

type Props = {
  space: Space;
};

const CreatePortalModal: React.FC<Props> = ({ space }) => {
  const [portalName, setPortalName] = useState(""),
    { dismissModal } = useShowModal(),
    navigation = useNavigation();

  const [createPortal, { isProcessing, error }] = useCreatePortal();

  const submit = useCallback(async () => {
    const res = await createPortal({ spaceId: space.id, name: portalName });

    if (res.isSuccess) {
      const portals = res.response.entities?.portals;
      const portalId = portals[res.response.result].shortId;
      dismissModal();
      const portalUrl = buildURL(`/p/${portalId}`, { space });
      navigation.push(portalUrl);
    }
  }, [dismissModal, portalName, space, createPortal, navigation]);

  return (
    <>
      <ModalHeader title="Create a Portal" />
      <ModalBody>
        <Box mb="m">
          <Input
            id="portal-name"
            label="Portal Name"
            value={portalName}
            onChange={e => setPortalName(e.target.value)}
            onSubmit={submit}
            placeholder="Enter a name"
            autoFocus
            message={error?.message}
            inputStyle={error ? "error" : null}
          />
        </Box>
      </ModalBody>
      <ModalFooter
        primary={{
          text: isProcessing ? "Creating Portal..." : "Create Portal",
          onClick: submit,
          disabled: isProcessing || !portalName.trim().length,
        }}
      />
    </>
  );
};

export default CreatePortalModal;
