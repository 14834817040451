import React, { Fragment, useCallback, useEffect } from "react";
import Cookie from "js-cookie";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { Box, Text, Button, Flex, useBoolean, utils } from "@thenounproject/lingo-core";

type WrapperProps = {
  open: boolean;
};
const ConsentWrapper = styled(Box).attrs<WrapperProps>(props => {
  return {
    boxShadow: "card",
    background: "white",
    borderRadius: "default",
    border: props.open ? "2px solid grayLight" : "none",
    width: props.open ? "360px" : "unset",
    maxWidth: props.open ? "calc(100% - 32px);" : "unset",
    p: props.open ? "l" : "none",
  };
})<WrapperProps>`
  position: fixed;
  bottom: -24px;
  left: 16px;
  z-index: 999999999999999;
  transition: bottom 0.4s ease;

  a {
    text-decoration: underline;
    color: ${utils.getColor("primary")};

    &:hover {
      text-decoration: underline;
    }
  }

  ${props =>
    props.open &&
    `
    bottom: 16px;
  `}

  ${props =>
    !props.open &&
    `
    &:hover {
      bottom: -4px;
    }
  `}
`;

const ButtonCss = css`
  flex-shrink: 1;
`;

export default function CookieConsent() {
  const currentStatus = Cookie.get("cookieconsent_status"),
    hasMadeSelection = ["allow", "deny"].includes(currentStatus),
    [isOpen, setOpen, setClosed] = useBoolean(!hasMadeSelection),
    allowCookies = useCallback(() => {
      Cookie.set("cookieconsent_status", "allow", { expires: 365 });
      setClosed();
      window.gtag("consent", "update", {
        ad_user_data: "granted",
        ad_personalization: "granted",
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    }, [setClosed]),
    denyCookies = useCallback(() => {
      Cookie.set("cookieconsent_status", "denu", { expires: 365 });
      setClosed();
      window.gtag("consent", "update", {
        ad_user_data: "denied",
        ad_personalization: "denied",
        ad_storage: "denied",
        analytics_storage: "denied",
      });
    }, [setClosed]);

  useEffect(() => {
    window.gtag("consent", "default", {
      ad_user_data: "denied",
      ad_personalization: "denied",
      ad_storage: "denied",
      analytics_storage: "denied",
    });
  }, []);

  return (
    <ConsentWrapper open={isOpen}>
      {isOpen ? (
        <Fragment>
          <Text font="ui.regularSemiBold" mb="s">
            We use third-party cookies to personalize content, ads, and analyze site traffic.
          </Text>
          <Link to="/legal#privacy-policy">Learn more</Link>
          <Flex mt="m">
            <Button
              text="Decline"
              buttonStyle="outline"
              onClick={denyCookies}
              fullWidth
              css={ButtonCss}
              mr="s"
              mb="s"
              flexShrink="1"
            />
            <Button
              text="Allow Cookies"
              buttonStyle="primary"
              onClick={allowCookies}
              fullWidth
              flexShrink="1"
            />
          </Flex>
        </Fragment>
      ) : (
        <Button text="Cookie Policy" buttonStyle="secondary" onClick={setOpen} />
      )}
    </ConsentWrapper>
  );
}
