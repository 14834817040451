/* eslint-disable react/jsx-key */
import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "@thenounproject/lingo-core";
import { lazyWithReload } from "@helpers/lazyWithReload";

import homepageContent from "./data/homepage";
import enterpriseContent from "./data/enterprise";
import product_kitsContent from "./data/product/kits";
import product_libraryContent from "./data/product/library";
import product_insightsContent from "./data/product/insights";
import product_customBrandingContent from "./data/product/customBranding";
import product_integrationsContent from "./data/product/integrations";

import solutions_brandGuidelinesContent from "./data/solutions/brandGuidelines";
import solutions_digitalAssetManagementContent from "./data/solutions/digitalAssetManagement";
import solutions_digitalBrandHubContent from "./data/solutions/digitalBrandHub";
import solutions_brandTeamsContent from "./data/solutions/brandTeams";
import solutions_agenciesContent from "./data/solutions/agencies";

import aboutContent from "./data/about";
import requestDemoContent from "./data/contact/bookDemo";
import requestQuoteContent from "./data/contact/requestQuote";
import requestTrialContent from "./data/contact/requestTrial";
import contactSalesContent from "./data/contact/contactSales";
import contactContent from "./data/contact/contact";
import Pricing from "./pages/Pricing";

const PageLayout = lazyWithReload(() => import("./pages/templates/PageLayout"));

const AllFeatures = lazyWithReload(() => import("./pages/AllFeatures"));
const PricingCalculator = lazyWithReload(() => import("./pages/PricingCalculator"));
const IntegrationDetail = lazyWithReload(() => import("./pages/templates/IntegrationDetail"));
const NotFound = lazyWithReload(() => import("./pages/NotFound"));
const StoreFront = lazyWithReload(() => import("./pages/StoreFront"));
const Legal = lazyWithReload(() => import("./pages/Legal"));
const Accessibility = lazyWithReload(() => import("./pages/Accessibility"));
const OpenMacApp = lazyWithReload(() => import("./pages/OpenMacApp"));
const BlogRoot = lazyWithReload(() => import("./pages/blog/BlogRoot"));
const Showcase = lazyWithReload(() => import("./pages/Showcase"));
const BlogHome = lazyWithReload(() => import("./pages/blog/BlogHome"));
const BlogCollection = lazyWithReload(() => import("./pages/blog/BlogCollection"));
const BlogPreview = lazyWithReload(() => import("./pages/blog/BlogPreview"));
const BlogPost = lazyWithReload(() => import("./pages/templates/BlogPost"));

export default function MarketingRoutes() {
  return [
    <Route key="101" path="/" element={<PageLayout {...homepageContent} />} />,
    <Route key="102" path="/pricing" element={<Pricing />} />,
    <Route key="103" path="/pricing/calculator" element={<PricingCalculator />} />,
    <Route key="104" path="/about" element={<PageLayout {...aboutContent} />} />,

    <Route key="108" path="/enterprise" element={<PageLayout {...enterpriseContent} />} />,
    <Route key="110" path="/legal" element={<Legal />} />,
    <Route key="111" path="/accessibility" element={<Accessibility />} />,

    <Route key="115" path="/showcase/:page" element={<Showcase />} />,

    <Route
      key="117"
      path="/security"
      element={<Redirect to="https://trust-report.lingoapp.com" />}
    />,
    <Route key="120" path="/store" component={StoreFront} />,
    <Route key="116" path="/open" component={OpenMacApp} />,
    <Route key="114" path="/api" element={<Redirect to="https://developer.lingoapp.com" />} />,
    <Route key="115" path="/help" render={<Redirect to="https://help.lingoapp.com" />} />,

    // Contact pages
    <Route key="130" path="/contact" element={<PageLayout {...contactContent} />} />,
    <Route key="131" path="/contact-sales" element={<PageLayout {...contactSalesContent} />} />,
    <Route key="132" path="/request-quote" element={<PageLayout {...requestQuoteContent} />} />,
    <Route key="133" path="/request-trial" element={<PageLayout {...requestTrialContent} />} />,
    <Route key="134" path="/book-demo" element={<PageLayout {...requestDemoContent} />} />,

    // Product (200)
    <Route key="200" path="/product/kits" element={<PageLayout {...product_kitsContent} />} />,
    <Route
      key="201"
      path="/product/library"
      element={<PageLayout {...product_libraryContent} />}
    />,
    <Route
      key="202"
      path="/product/insights"
      element={<PageLayout {...product_insightsContent} />}
    />,
    <Route
      key="203"
      path="/product/custom-branding"
      element={<PageLayout {...product_customBrandingContent} />}
    />,
    <Route
      key="204"
      path="/product/integrations"
      element={<PageLayout {...product_integrationsContent} />}
    />,
    <Route key="205" path="/integrations/:integrationId" element={<IntegrationDetail />} />,
    <Route key="206" path="/product/features" element={<AllFeatures />} />,

    // Solutions (300)
    <Route
      key="301"
      path="/solutions/brand-guidelines"
      element={<PageLayout {...solutions_brandGuidelinesContent} />}
    />,
    <Route
      key="302"
      path="/solutions/digital-asset-management"
      element={<PageLayout {...solutions_digitalAssetManagementContent} />}
    />,
    <Route
      key="303"
      path="/solutions/digital-brand-hub"
      element={<PageLayout {...solutions_digitalBrandHubContent} />}
    />,
    <Route
      key="304"
      path="/solutions/brand-teams"
      element={<PageLayout {...solutions_brandTeamsContent} />}
    />,
    <Route
      key="305"
      path="/solutions/agencies"
      element={<PageLayout {...solutions_agenciesContent} />}
    />,

    // Blog (500)
    <Route key="500" path="/blog" element={<BlogRoot />}>
      <Route index element={<BlogHome />} />
      <Route path="preview" element={<BlogPreview />} />
      <Route path="collection/:collectionId" element={<BlogCollection />} />
      <Route path=":postId" element={<BlogPost />} />
    </Route>,
    <Route path="*" key="404" element={<NotFound />} />,
  ];
}
