import { Space, OperatorValues, Kit } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { PersonId } from "../types";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  personId: PersonId;
};

export type UserViewsResult = Kit & {
  views: {
    totalViews: number;
    lastViewed: string;
  };
};

type KitTableData = {
  kits: UserViewsResult[];
  total: number;
};

const [useMostViewedKitsByUser, fetchMostViewedKitsByUser] = createQueryAction<Args, KitTableData>(
  {
    entity: "analytics",
    action: "queryMostViewedKitsByUser",
    denormalize: false,
    pagingKey: "kits",
  },
  async ({ args, paging }) => {
    const reports = {
      kits: {
        id: "kits",
        include: ["views"],
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        date_operators: args.dateOperators,
        person_id: args.personId,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ kits: KitTableData }>(args.spaceId, reports)).kits;
  }
);

export default useMostViewedKitsByUser;
export { fetchMostViewedKitsByUser };
