import { API } from "@thenounproject/lingo-core";

import { createAsyncAction } from "../actionCreators";

type Args = {
  userId: number;
  spaceId: number;
  kitId: string;
  role: string;
};

const [useUpdateKitMember, updateKitMember] = createAsyncAction(
  "kitMembers/update",
  async ({ userId, spaceId, kitId, role }: Args) => {
    return await API.call<string>({
      endpoint: `kits/${spaceId}/${kitId}/members/${userId}`,
      method: "PUT",
      entity: API.Entity.kitMember,
      data: {
        role,
      },
    });
  }
);

export default useUpdateKitMember;
export { updateKitMember };
