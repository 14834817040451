import React, { Fragment } from "react";
import {
  Box,
  Select,
  PublicAccessApprovalMode,
  Notice,
  LingoError,
} from "@thenounproject/lingo-core";

import ModalBody from "../../../components/ModalBody";
import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import { mapDownloadsRestrictedApproveModeToLabel } from "./ConfigureRequestsToolbar";

type Props = {
  approvalSetting: PublicAccessApprovalMode;
  canSubmit: boolean;
  error?: LingoError;
  onChange: (value: PublicAccessApprovalMode) => void;
  onSubmit: () => void;
};

const ConfigureRequestsModalContent = ({
  error,
  canSubmit,
  approvalSetting,
  onChange,
  onSubmit,
}: Props) => {
  return (
    <Fragment>
      <ModalHeader title={"Configure Download Requests"} />
      <ModalBody>
        {error && <Notice noticeStyle="error" message={error.message} />}
        <Box>
          <Select<PublicAccessApprovalMode>
            options={[
              "approve_all_automatically" as const,
              "approve_once_for_entity" as const,
              "approve_every_asset" as const,
            ].map(value => ({
              label: mapDownloadsRestrictedApproveModeToLabel[value],
              value,
            }))}
            value={approvalSetting}
            onChange={value => onChange(value)}
            label="Request Settings"
            width="100%"
          />
        </Box>
      </ModalBody>
      <ModalFooter
        primary={{
          disabled: !canSubmit,
          text: "Save",
          onClick: onSubmit,
        }}
      />
    </Fragment>
  );
};

export default React.memo(ConfigureRequestsModalContent);
