type ExtraData = {
  name: string;
  email: string;
  message: string;
};

function trackPageView(_page: string, _data?: ExtraData) {
  // Disabling this for now
  // sendEvent("Page View", label, user);
}

function trackFormSubmission(form: string, data?: ExtraData) {
  sendEvent("Form Submit", form, data);
}

function trackSignup(data?: ExtraData) {
  sendEvent("Account", "created", data);
}

function sendEvent(category: string, label: string, data: ExtraData) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (!window.$wc_leads?.track) {
    if (_lingoConfig.environment !== "production") {
      // eslint-disable-next-line no-console
      console.debug(`WC not loaded:: Track ${category}: ${label}`);
    }
    return;
  }

  const _data = data
    ? {
        "Contact Name": data.name,
        "Email Address": data.email,
        Message: data.message,
      }
    : null;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  window.$wc_leads?.track.event("Lead", category, label, null, _data);
}

export default {
  trackPageView,
  trackFormSubmission,
  trackSignup,
};
