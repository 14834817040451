import React, { useMemo, useCallback } from "react";
import {
  formatDate,
  Flex,
  Button,
  Space,
  Box,
  ActivityIndicator,
  TableCellText,
  TableCell,
} from "@thenounproject/lingo-core";

import Chart from "../Chart";
import InsightsIntervalPicker from "../InsightsIntervalPicker";
import useKitViewsOverviewByUser from "../queries/useKitViewsOverviewByUser";
import { UserTableResult } from "../queries/useRecentUserActivity";
import useMostViewedKitsByUser, { UserViewsResult } from "../queries/useMostViewedKitsByUser";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";

import { LoadFailure } from "../ReportElements";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";
import { formatInsightsDate } from "../utils";

type Props = { user: UserTableResult; space: Space };

const InsightsUserViewTab = ({ user, space }: Props) => {
  const [sort, setSort] = React.useState<string>("-total_views");

  const [generateInsightsCSV] = useGenerateInsightsCSV();
  const { dateOperators } = useInsightsContext();

  const userIsGuest = user?.personId?.includes?.("guest") || user.personId === "anonymous";

  const { data: overviewData, error: dataFetchingError } = useKitViewsOverviewByUser({
    spaceId: space.id,
    personId: user.personId,
    dateOperators,
  });

  const { result: kitViewData } = overviewData ?? {};

  const {
    data,
    isLoading: tableIsLoading,
    fetchNextPage,
    error: tableError,
  } = useMostViewedKitsByUser({
    spaceId: space.id,
    sort,
    dateOperators,
    personId: user.personId,
  });

  const { kits: tableData, total } = data ?? {};

  const hasMorePages = tableData?.length < total;

  const { kitViewLabels, kitViewMembers, kitViewGuests } = useMemo(
    () =>
      (kitViewData || []).reduce(
        (acc, item, i) => {
          acc.kitViewLabels[i] = formatInsightsDate(item.date);
          acc.kitViewMembers[i] = item.memberViews;
          acc.kitViewGuests[i] = item.guestViews;
          return acc;
        },
        { kitViewLabels: [], kitViewMembers: [], kitViewGuests: [] }
      ),
    [kitViewData]
  );

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "kits",
      sort,
      dateOperators,
      additionalParams: { ["person_id"]: user.personId, include: ["views"] },
    });
  }, [dateOperators, generateInsightsCSV, sort, space.id, user]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Most viewed kits",
      subtitle: "Detailed breakdowns of kit view data.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"user-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<UserViewsResult>[] = [
      {
        sortKey: "name",
        name: "Name",
        minWidth: 340,
        flexGrow: 1,
        renderer: k => {
          return <TableCell data={k} space={space} />;
        },
      },
      {
        sortKey: "total_views",
        name: "Total Views",
        minWidth: 160,
        justify: "flex-end",
        renderer: k => <TableCellText>{k?.views.totalViews}</TableCellText>,
      },
      {
        name: "Last Viewed",
        minWidth: 160,
        renderer: k => (
          <TableCellText>{formatDate(k.views.lastViewed, "MMM d, yyyy") || "N/A"}</TableCellText>
        ),
      },
    ];
    return cols;
  }, [space]);

  if (dataFetchingError) return <LoadFailure />;
  if (!overviewData) {
    return (
      <Box height="50vh">
        <ActivityIndicator size="large" center />
      </Box>
    );
  }

  return (
    <>
      <Box px="xl" pt="l">
        <Chart
          title="Total Views"
          subtitle="The number of times a user viewed a kit."
          error={!!dataFetchingError}
          headerExtra={<InsightsIntervalPicker id="kits-modal" />}
          labels={kitViewLabels}
          values={[
            {
              label: "Views by User",
              data: userIsGuest ? kitViewGuests : kitViewMembers,
              color: "primaryColorDark",
            },
          ]}
          Ylabel="# of Views"
        />
      </Box>
      <DataTable<UserViewsResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </>
  );
};

export default InsightsUserViewTab;
