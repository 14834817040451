export const humanizedFilterNames: { [key: string]: string } = {
  kit: "In Kit",
  type: "Asset Type",
  after: "Uploaded After",
  before: "Uploaded Before",
  uploader: "Uploaded By",
  date_created: "Date Created",
  date: "Date Created",
};

export const filterPlaceholderMap: { [key: string]: string } = {
  name: "Enter a keyword",
};
