import React, { useCallback, useState, Fragment } from "react";
import {
  buildURL,
  buildPath,
  Text,
  Input,
  getCustomDomainComponents,
  Space,
  useNavigation,
} from "@thenounproject/lingo-core";

import useNotifications from "@actions/useNotifications";

import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";
import ModalFooter from "../../../ModalFooter";
import useShowModal from "@redux/actions/useModals";
import useDeleteSpace from "@redux/actions/spaces/useDeleteSpace";
import useSpaces from "@redux/actions/spaces/useSpaces";

type Props = {
  space: Space;
};

const DeleteSpaceModal: React.FC<Props> = ({ space }) => {
  const { showNotification } = useNotifications(),
    { dismissModal } = useShowModal(),
    navigate = useNavigation();
  const [confirmationError, setConfirmationError] = useState<string>();
  const [deleteSpace, { isProcessing, error: requestError }] = useDeleteSpace();
  const [confirmation, setConfirmation] = useState("");

  const error = confirmationError || requestError?.message;

  const { data: spaces } = useSpaces();
  const onSpaceDeleted = useCallback(() => {
    if (!spaces) return;

    const domain = getCustomDomainComponents();
    let redirectTo = (domain.identifier ? buildURL : buildPath)("/space/new/");

    const nextSpace = spaces.reduce((next, s) => {
      if (space.id === s.id) return next;
      if (!next || s.id > next.id) return s;
      return next;
    }, null);

    if (nextSpace) {
      const builder =
        domain.identifier || nextSpace.subdomain || nextSpace.domain ? buildURL : buildPath;
      redirectTo = builder("/", { space: nextSpace });
    }
    if (redirectTo.startsWith("/")) navigate.replace(redirectTo);
    else window.location.replace(redirectTo);
  }, [navigate, space, spaces]);

  const submit = useCallback(async () => {
    if (confirmation.toLowerCase() !== "delete") {
      setConfirmationError("Please type “delete” to confirm.");
      return;
    }
    setConfirmationError(undefined);
    const res = await deleteSpace({ spaceId: space.id });
    if (res.isSuccess) {
      dismissModal();
      showNotification({
        message: `“${space.name}” has been permanently deleted.`,
        level: "info",
      });
      onSpaceDeleted();
    }
  }, [
    confirmation,
    deleteSpace,
    dismissModal,
    onSpaceDeleted,
    showNotification,
    space.id,
    space.name,
  ]);

  return (
    <Fragment>
      <ModalHeader title={`Delete “${space.name}”?`} />
      <ModalBody>
        <Text>
          {`Are you sure? This will delete the entire space and all assets contained within it. All space members (${space.counts.users} in total) will no longer have access to any content. This action cannot be undone.`}
        </Text>

        <Input
          id="confirmation-input"
          styleOverrides={{ mt: "l" }}
          value={confirmation}
          label="Confirm by typing “delete”"
          type="text"
          onChange={e => setConfirmation(e.target.value)}
          onSubmit={submit}
          placeholder="Delete"
          message={error}
          inputStyle={error ? "error" : null}
          autoFocus
        />
      </ModalBody>
      <ModalFooter
        primary={{
          id: "delete-space-submit-button",
          text: isProcessing ? "Deleting..." : "Delete Space",
          onClick: submit,
          disabled: isProcessing,
        }}
      />
    </Fragment>
  );
};

export default DeleteSpaceModal;
