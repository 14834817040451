"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.indexedPaths = exports.baseMetaTag = exports.metaTags = void 0;
exports.getMetaForPath = getMetaForPath;
exports.metaTags = {
    // Homepage
    "/": {
        title: "Lingo: Design Asset Library, Brand & System Management",
        description: "Lingo is the visual home for your brand. It is designed to help teams organize, manage and share their assets in one place.",
    },
    // Product
    "/product/kits": {
        title: "Kits: Digital Asset Management Templates | Lingo",
        description: "Content and context in the same place. Kits are part document part asset library. Document every asset and aspect of your brand.",
    },
    "/product/library": {
        title: "Digital Asset Library & Management Storage | Lingo",
        description: "Manage your assets with ease. The Library brings all your assets and metadata into a single view designed specifically for asset management.",
    },
    "/product/insights": {
        title: "Asset Management Analytics & Report Builder | Lingo",
        description: "Measure the performance and impact of your assets using Lingo's powerful asset analytics",
    },
    "/product/custom-branding": {
        title: "Custom Branding for Your Digital Brand Guidelines | Lingo",
        description: "Replace Lingo's brandng with your own to make your workspace feel on-brand and official for anyone using it",
    },
    "/product/features": {
        title: "DAM Capabilities & Management Features | Lingo",
        description: "Lingo combines the power of a digital asset manager with the context of a style guide creator where users can upload, search, manage and share digital assets.",
    },
    "/product/integrations": {
        title: "Digital Integration & Apps: Integrate Your Workflow | Lingo",
        description: "Work from the web or your desktop and connect Lingo to the tools you already use so you can supercharge your workflow.",
    },
    "/integrations/figma": {
        title: "Figma Integration: Sync Style Guides & Illustratios | Lingo",
        description: "Assets created in Figma can be synced to Lingo so you can build style guides, illustration libraries and design systems that can be used by your entire organization.",
    },
    "/integrations/dropbox": {
        title: "Dropbox Integration: Sync Dropbox Application | Lingo",
        description: "Import assets directly from your Dropbox account.",
    },
    "/integrations/google": {
        title: "Google Drive Integration  | Lingo",
        description: "Import assets directly from your Google account.",
    },
    "/integrations/api": {
        title: "Lingo Workflow API",
        description: "Build on top of our powerful platform to create customized workflows to suit your needs.",
    },
    // Solutions
    "/solutions/brand-guidelines": {
        title: "Digital Brand Guidelines: Top Rated Brand Platform | Lingo",
        description: "Lingo allows you to create a comprehensive brand guideline that empowers everyone on your team to help identify, build, and grow your brand",
    },
    "/solutions/digital-asset-management": {
        title: "Digital Asset Management Software & Systems | Lingo",
        description: "Store all your digital assets in a single location. Make them easy to organize, share and use.",
    },
    "/solutions/digital-brand-hub": {
        title: "Brand Hub: Centralize Digital Brand Assets | Lingo",
        description: "Create a single source of truth for your brand assets. Empower your team to create on their own while ensuring consistency at scale.",
    },
    "/solutions/brand-teams": {
        title: "Brand Management Software & Brand Team Tools | Lingo",
        description: "Use Lingo to create a single source of truth for your brand and ensure brand consistency at scale.",
    },
    "/solutions/agencies": {
        title: "Design Project Management Software for Graphic Designers | Lingo",
        description: "Use Lingo to build digital style guides for your clients. Save time and create more value for your clients.",
    },
    // Misc pages
    "/about": {
        title: "About | Lingo",
        description: "Looking for an affordable and easy to use digital asset manager? Lingo gives you the flexibility to manage all your digital assets in one place.",
    },
    "/pricing": {
        title: "Pricing & Plans for Every Business Size | Lingo",
        description: "Lingo offers an affordable design system pricing plan that scales with your organization. Book a demo or start your 30-day free trial today!",
    },
    "/pricing/calculator": {
        title: "Pricing Calculator | Lingo",
        description: "Lingo offers an affordable design system pricing plan that scales with your organization. Book a demo or start your 30-day free trial today!",
    },
    "/legal": {
        title: "Legal | Lingo",
        description: "Lingo's legal statements.",
    },
    "/security": {
        title: "Security | Lingo",
        description: "Learn about our security practices and how we keep your data safe.",
        url: "https://trust-report.lingoapp.com",
    },
    "/enterprise": {
        title: "Enterprise Digital Asset Management (DAM) Software | Lingo",
        description: "Looking for an affordable enterprise DAM system? With Lingo your team can maximize your brand's value with a fully customizable digital asset library.",
    },
    "/contact": {
        title: "Contact Us | Lingo",
        description: "Have a question about our products? Our Lingo team is happy to help you. Use the messenger to send us a message or visit our help center.",
    },
    "/contact-sales": {
        title: "Contact Sales | Lingo",
        description: "We're happy to answer your questions. Secure an appointment with one of our reps so we can learn more about your needs.",
    },
    "/book-demo": {
        title: "Book a demo - Contact us | Lingo",
        description: "Secure an appointment with a Lingo rep to see how Lingo can work for your specific needs.",
    },
    "/request-trial": {
        title: "Request a trial - Contact us | Lingo",
        description: "Take Lingo for a spin with a free, fully featured 30 day trial.",
    },
    "/request-quote": {
        title: "Request a quote - Contact us | Lingo",
        description: "Request a quote and see how Lingo's flexible pricing model can work for fortune 500 companies as well as small burgeoning businesses.",
    },
    "/accessibility": {
        title: "Accessibility | Lingo",
        description: "Lingo's accessibility statement.",
    },
    "/open": {
        title: "Open App | Lingo",
        description: "With Lingo you can build and share your digital assets and style guides quickly by downloading the Lingo Mac app and Sketch plugin. Get started today!",
        index: false,
    },
    "/blog": {
        title: "Blog | Lingo",
        description: "Blog posts by Lingo. Learn about new features and how companies are using Lingo to organize, manage and share their digital asset libraries.",
    },
    "/showcase/kits": {
        title: "Showcase | Lingo",
        description: "",
    },
    "/showcase/portals": {
        title: "Showcase | Lingo",
        description: "",
    },
};
exports.baseMetaTag = {
    description: "A modern digital asset manager for your brand and product",
    title: "Lingo — The visual home for your brand",
};
function getMetaForPath(path) {
    var p = path.toLowerCase();
    if (p !== "/" && p.endsWith("/")) {
        p = p.slice(0, -1);
    }
    if (exports.metaTags[p]) {
        return __assign({ url: p }, exports.metaTags[p]);
    }
    return exports.baseMetaTag;
}
exports.indexedPaths = Object.keys(exports.metaTags);
