import { Space, OperatorValues, Asset } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { PersonId } from "../types";

import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  personId: PersonId;
};

export type UserViewsResult = {
  asset: Asset;
  id: string;
  sectionId: string;
  usage: {
    totalUses: number;
    sectionName: string;
    kitName: string;
    lastDownloaded: string;
  };
  dateAdded: string;
  kitId: string;
};

type KitTableData = {
  items: UserViewsResult[];
  total: number;
};

const [useUserAssetUsage, fetchUserAssetUsage] = createQueryAction<Args, KitTableData>(
  {
    entity: "analytics",
    action: "queryUserAssetUsage",
    denormalize: false,
    pagingKey: "items",
  },
  async ({ args, paging }) => {
    const reports = {
      assets: {
        id: "assets",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        person_id: args.personId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ assets: KitTableData }>(args.spaceId, reports)).assets;
  }
);

export default useUserAssetUsage;
export { fetchUserAssetUsage };
