import usePortalUpsell, { AccessStatus } from "@hooks/usePortalUpsell";
import useUpdatePortal from "@redux/actions/portals/useUpdatePortal";
import useNotifications from "@redux/actions/useNotifications";
import {
  Button,
  Checkbox,
  Flex,
  Portal,
  PortalPermission,
  Tooltip,
  Text,
} from "@thenounproject/lingo-core";
import React, { useCallback } from "react";
import UpsellTooltip from "../../../components/spaces/settings/UpsellTooltip";

type Props = {
  portal: Portal;
};
const PortalSeoSettings: React.FC<Props> = ({ portal }) => {
  const { accessStatus, openUpgradeModal } = usePortalUpsell({
    permission: PortalPermission.manageSEO,
    portal,
  });
  const { showNotification } = useNotifications();

  const [updatePortal, { isProcessing }] = useUpdatePortal();
  const toggleSEO = useCallback(async () => {
    const res = await updatePortal({ id: portal.id, indexable: !portal.indexable });
    if (res.error) {
      showNotification({ level: "error", message: res.error.message });
    }
  }, [portal.indexable, portal.id, showNotification, updatePortal]);

  if (accessStatus === AccessStatus.insufficientRole) return null;

  // TODO: This may need to check the space domain.
  const hasCustomDomain = Boolean(portal.subdomain || portal.domain);
  const hasAccess = accessStatus === AccessStatus.canAccess;
  const isSelected = portal.indexable && hasAccess && hasCustomDomain;
  function renderUpsell() {
    return !hasAccess ? (
      <UpsellTooltip source="indexable-tooltip" onClick={openUpgradeModal} featureName="SEO" />
    ) : null;
  }

  function renderDomainTooltip() {
    if (!hasAccess) return null;
    return hasCustomDomain ? null : (
      <Tooltip source="indexable-tooltip" direction={Tooltip.Direction.Top}>
        <Text color="white" font="ui.small">
          Set up a custom domain to enable SEO.{" "}
          <Button
            buttonStyle="tertiary"
            tintColor="white"
            text="Learn more."
            fontStyle="ui.smallBold"
            size="small"
            link={"http://help.lingoapp.com/en/articles/7970185-seo"}
            newWindow
          />
        </Text>
      </Tooltip>
    );
  }
  return (
    <>
      {renderUpsell() ?? renderDomainTooltip()}
      <Flex alignItems="center">
        <Flex data-tooltip-trigger="indexable-tooltip">
          <Checkbox
            isSelected={isSelected}
            disabled={isProcessing || !(hasAccess && hasCustomDomain)}
            label={
              <Flex>
                <Text data-tooltip-source="indexable-tooltip" font="ui.small" color="grayDarkest">
                  Allow search engines to index your kit
                </Text>
              </Flex>
            }
            onClick={toggleSEO}
          />
        </Flex>
        <Tooltip.InfoTip fill="grayDarkest">
          <Text color="white" font="ui.small">
            When enabled, search engines (Google, Bing, etc.) can discover your kit and display it
            in search results.{" "}
            <Button
              buttonStyle="tertiary"
              tintColor="white"
              text="Learn more."
              fontStyle="ui.smallBold"
              size="small"
              link={"http://help.lingoapp.com/en/articles/7970185-seo"}
              newWindow
            />
          </Text>
        </Tooltip.InfoTip>
      </Flex>
    </>
  );
};

export default PortalSeoSettings;
