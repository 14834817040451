/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * Component that displays & allows changing avatar for user or space.
 */

import React, {
  useEffect,
  useState,
  useRef,
  DragEvent as ReactDragEvent,
  ChangeEvent,
  MouseEvent,
} from "react";
import styled from "styled-components";
import { Button, Box, Flex, utils, ActivityIndicator } from "@thenounproject/lingo-core";

import useNotifications from "@actions/useNotifications";
import getFilesFromEvent from "@helpers/getFilesFromEvent";
import { AsyncResponse } from "@redux/actions/actionCreators/createAsyncAction";

const { getColor, getFont } = utils;

type Props = {
  currentAvatar: string;
  uploadAction: (file: File) => AsyncResponse;
  isDragging?: boolean; // used to handle when EditAvatar is in component that handles multiple drag events
};

const ChangeAvatar = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & figure {
    margin-right: 12px;
    display: block;
    position: relative;
    width: 80px;
  }
`;
const AvatarPreview = styled(Box).attrs({
  background: "grayLightest",
  borderRadius: "50%",
  height: "80px",
  width: "80px",
  flexShrink: 0,
  overflow: "hidden",
})`
  cursor: pointer;
  background-position: center center;
  background-size: cover;
  background-image: ${props => props.backgroundImage};

  &.drag-over-avatar:before {
    ${getFont("ui.small", null)};
    background-color: ${getColor("grayLightest")};
    border-radius: 50%;
    border: ${getColor("grayDarkest")} 3px dashed;
    color: ${getColor("grayDarkest")};
    content: "Drop Zone";
    display: block;
    height: 82px;
    left: -1px;
    line-height: 1.25;
    padding: 24px;
    position: absolute;
    top: -1px;
    width: 82px;
  }
`;

const maxFileSize = 5000000,
  mimeTypes = ["image/jpg", "image/jpeg", "image/png", "image/gif"];

const EditAvatar: React.FC<Props> = ({ currentAvatar, uploadAction, isDragging }) => {
  const [processing, setProcessing] = useState(false),
    [dragOver, setDragOver] = useState(null),
    inputRef = useRef<HTMLInputElement>(null),
    { showNotification } = useNotifications();

  // Dragging
  const handleDragOver = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
    setDragOver(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setDragOver(false);
  };

  useEffect(() => {
    const dragBody = document.body;
    dragBody.addEventListener("dragover", handleDragOver, false);
    dragBody.addEventListener("dragleave", handleDragLeave, false);
    dragBody.addEventListener("drop", handleDrop, false);
    return () => {
      dragBody.removeEventListener("dragover", handleDragOver, false);
      dragBody.removeEventListener("dragleave", handleDragLeave, false);
      dragBody.removeEventListener("drop", handleDrop, false);
    };
  }, []);

  const avatarCanBeRemoved = () => {
      return (
        currentAvatar &&
        !currentAvatar.includes("/user-avatars/space.png") &&
        (!currentAvatar.includes("gravatar") ||
          currentAvatar.includes("/s/") ||
          currentAvatar.includes("@240") ||
          currentAvatar.includes("data:"))
      );
    },
    selectAvatar = (e: MouseEvent) => {
      if (e) e.preventDefault();
      // Clicking input brings up file selector dialog
      inputRef.current.click();
    },
    loadSelectedAvatar = (e: ReactDragEvent | ChangeEvent) => {
      setDragOver(false);
      e.stopPropagation();
      e.preventDefault();
      e.persist();
      const file = getFilesFromEvent(e)[0];

      if (!isFileValid(file)) return;

      const reader = new FileReader();
      reader.onload = (fileData => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return uploadAvatar(fileData) as any;
      })(file);
      reader.readAsDataURL(file);
    },
    isFileValid = (file: File) => {
      const err = (() => {
        if (!file) return "Unable to read file";
        if (file && file.size > maxFileSize) {
          return `Avatar file cannot be larger than ${maxFileSize / 1000000} MB`;
        }
        if (file && !mimeTypes.includes(file.type)) return "Avatar file must be JPG, PNG or GIF";
      })();
      if (err) {
        showNotification({ message: err, level: "error" });
        setDragOver(false);
        return false;
      }
      return true;
    },
    uploadAvatar = async (file: File) => {
      setProcessing(true);
      const { error } = await uploadAction(file);
      setProcessing(false);
      if (error) {
        showNotification({ message: error.message, level: "error" });
      } else {
        showNotification({ message: "Avatar successfully updated.", level: "info" });
      }
    },
    revertAvatar = () => {
      inputRef.current.value = null;
      void uploadAvatar(null);
    };

  /**
   * Rendering
   */

  function renderControls() {
    if (processing) return null;
    return (
      <Flex flexDirection="column" alignItems="flex-start">
        <Button text="Change avatar" buttonStyle="tertiary" onClick={selectAvatar} />
        {avatarCanBeRemoved() && (
          <Button text="Remove" buttonStyle="tertiary" size="small" onClick={revertAvatar} />
        )}
      </Flex>
    );
  }

  function renderImgDiv() {
    if (!currentAvatar) {
      return <AvatarPreview />;
    }
    const dragging = typeof isDragging === "boolean" ? isDragging : dragOver;
    return (
      <AvatarPreview
        data-test="space-settings-avatar"
        className={dragging && "drag-over-avatar"}
        onDrop={loadSelectedAvatar}
        backgroundImage={`url("${currentAvatar}")`}
        onClick={selectAvatar}>
        {processing && <ActivityIndicator background="modalOverlay" center color={"white"} />}
      </AvatarPreview>
    );
  }

  return (
    <ChangeAvatar>
      <figure title="Click to choose an image.">{renderImgDiv()}</figure>
      {renderControls()}
      <input
        type="file"
        ref={inputRef}
        className="avatar-input"
        onChange={loadSelectedAvatar}
        accept={mimeTypes.join(",")}
        style={{ display: "none" }}
      />
    </ChangeAvatar>
  );
};

export default EditAvatar;
