import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: string | number;
};

const [useExtendTrial, extendTrial] = createAsyncAction(
  "space/extendTrial",
  ({ spaceId }: Args) => {
    return API.call<string>({
      endpoint: `spaces/${spaceId}/plan/`,
      method: "POST",
      data: {
        plan_identifier: "trial-2",
        proration_date: null,
      },
      entity: API.Entity.space,
    });
  }
);

export default useExtendTrial;
export { extendTrial };
