import React from "react";
import { TabBar, Space, Box } from "@thenounproject/lingo-core";

import ModalHeader from "../../ModalHeader";
import { InsightsPageWrapper } from "../ReportElements";
import InsightsReferrersTab from "./InsightsReferrersTab";
import InsightsGeographyTab from "./InsightsGeographyTab";
import InsightsVisitorsTab from "./InsightsVisitorsTab";

type Props = {
  directLinkId: number;
  directLinkName: string;
  assetName: string;
  space: Space;
};

enum Tab {
  referrers = "Referrers",
  geography = "Geography",
  visitors = "Visitors / IPs",
}

const InsightsAssetModal = ({ directLinkId, directLinkName, assetName, space }: Props) => {
  const [selectedTab, setSelectedTab] = React.useState<string>(Tab.referrers);
  const title = `${assetName} - ${directLinkName ?? "Default"}`;
  function renderTab() {
    switch (selectedTab) {
      case Tab.referrers:
        return <InsightsReferrersTab directLinkId={directLinkId} space={space} />;
      case Tab.geography:
        return <InsightsGeographyTab directLinkId={directLinkId} space={space} />;
      case Tab.visitors:
        return <InsightsVisitorsTab directLinkId={directLinkId} space={space} />;
      default:
        return null;
    }
  }

  return (
    <>
      <ModalHeader title={title} styleOverrides={{ borderBottom: "none" }}></ModalHeader>
      <Box
        width="100%"
        borderBottom="default"
        position="sticky"
        top="0"
        background="white"
        zIndex={"100"}>
        <TabBar>
          <TabBar.Item
            text="Referrers"
            selected={selectedTab === Tab.referrers}
            onClick={() => setSelectedTab(Tab.referrers)}
          />
          <TabBar.Item
            text="Geography"
            selected={selectedTab === Tab.geography}
            onClick={() => setSelectedTab(Tab.geography)}
          />
          <TabBar.Item
            text="Visitors / IPs"
            selected={selectedTab === Tab.visitors}
            onClick={() => setSelectedTab(Tab.visitors)}
          />
        </TabBar>
      </Box>
      <InsightsPageWrapper>{renderTab()}</InsightsPageWrapper>
    </>
  );
};

export default React.memo(InsightsAssetModal);
