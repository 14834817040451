import React, { useMemo, useCallback } from "react";
import {
  formatDate,
  Flex,
  Button,
  Space,
  Box,
  ActivityIndicator,
  TableCellText,
  TableCell,
} from "@thenounproject/lingo-core";

import Chart from "../Chart";
import InsightsIntervalPicker from "../InsightsIntervalPicker";
import useTotalDownloadsByUser from "../queries/useTotalDownloadsByUser";
import useUserAssetUsage, { UserViewsResult } from "../queries/useUserAssetUsage";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import { UserTableResult } from "../queries/useRecentUserActivity";

import { LoadFailure } from "../ReportElements";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";
import { formatInsightsDate } from "../utils";
import getAssetBackground from "@helpers/getAssetBackground";
import assetIconMap from "../../kits/GalleryAssetContent/assetIconMap";

type Props = {
  user: UserTableResult;
  space: Space;
};

const InsightsUserAssetsTab = ({ user, space }: Props) => {
  const [sort, setSort] = React.useState<string>("-total_uses");

  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const { dateOperators } = useInsightsContext();

  const { data: overviewData, error: dataFetchingError } = useTotalDownloadsByUser({
    spaceId: space.id,
    personId: user.personId,
    dateOperators,
  });

  const { result: assetUsageData } = overviewData ?? {};

  const {
    data,
    isLoading: tableIsLoading,
    error: tableError,
    fetchNextPage,
  } = useUserAssetUsage({
    spaceId: space.id,
    sort,
    dateOperators,
    personId: user.personId,
  });

  const { items: tableData, total } = data || {};

  const hasMorePages = tableData?.length < total;

  const { assetUsageLabels, assetUsage } = useMemo(
    () =>
      (assetUsageData || []).reduce(
        (acc, item, i) => {
          acc.assetUsageLabels[i] = formatInsightsDate(item.date);
          acc.assetUsage[i] = item.totalUses;
          return acc;
        },
        { assetUsageLabels: [], assetUsage: [] }
      ),
    [assetUsageData]
  );

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "assets",
      sort,
      dateOperators,
      additionalParams: { ["person_id"]: user.personId },
    });
  }, [dateOperators, generateInsightsCSV, sort, space.id, user.personId]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "All Asset Usage",
      subtitle: "Detailed breakdowns of asset usage data.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"user-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<UserViewsResult>[] = [
      {
        sortKey: "name",
        name: "Asset Name",
        minWidth: 340,
        flexGrow: 1,
        renderer: i => {
          return (
            <TableCell
              data={i.asset}
              space={space}
              icon={assetIconMap[i.asset.type]}
              background={getAssetBackground(i.asset, space.theme).background}
            />
          );
        },
      },
      {
        name: "Section",
        minWidth: 160,
        renderer: i => <TableCellText>{i.usage.sectionName}</TableCellText>,
      },
      {
        sortKey: "",
        name: "In Kit",
        minWidth: 160,
        renderer: i => <TableCellText>{i?.usage.kitName}</TableCellText>,
      },
      {
        sortKey: "total_uses",
        name: "Total Downloads",
        minWidth: 160,
        justify: "flex-end",
        renderer: i => <TableCellText>{i?.usage?.totalUses}</TableCellText>,
      },
      {
        name: "Last Downloaded",
        minWidth: 160,
        renderer: i => (
          <TableCellText>{formatDate(i.usage.lastDownloaded, "MMM d, yyyy")}</TableCellText>
        ),
      },
    ];
    return cols;
  }, [space]);

  if (dataFetchingError) return <LoadFailure />;
  if (!assetUsageData) {
    return (
      <Box height="50vh">
        <ActivityIndicator size="large" center />
      </Box>
    );
  }

  return (
    <>
      <Box px="xl" pt="l">
        <Chart
          title="Total Downloads"
          subtitle="The number of times a user downloaded an asset."
          error={!!dataFetchingError}
          headerExtra={<InsightsIntervalPicker id="kits-modal" />}
          labels={assetUsageLabels}
          values={[
            {
              label: "Downloads by User",
              data: assetUsage,
              color: "primaryColorDark",
            },
          ]}
          Ylabel="# of Views"
        />
      </Box>
      <DataTable<UserViewsResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </>
  );
};

export default InsightsUserAssetsTab;
