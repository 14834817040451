import React from "react";
import ConfigureKitRequestsModal from "./ConfigureKitRequestsModal";
import ConfigurePortalRequestsModal from "./ConfigurePortalRequestsModal";
import ConfigureSpaceRequestsModal from "./ConfigureSpaceRequestsModal";

type Props = {
  spaceId?: number;
  kitId?: string;
  portalId?: string;
};

const ConfigureRequestsModal: React.FC<Props> = ({ spaceId, kitId, portalId }) => {
  if (portalId) {
    return <ConfigurePortalRequestsModal portalId={portalId} />;
  } else if (kitId && spaceId) {
    return <ConfigureKitRequestsModal spaceId={spaceId} kitId={kitId} />;
  } else if (spaceId) {
    return <ConfigureSpaceRequestsModal spaceId={spaceId} />;
  }
};

export default ConfigureRequestsModal;
