/**
 * Item reducer
 */
import _mergeWith from "lodash/mergeWith";
import _merge from "lodash/merge";
import _union from "lodash/union";
import _remove from "lodash/remove";

import { Item } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";
import { QueryData, invalidateQuery } from "@redux/actions/actionCreators/createQueryAction";

import { uploadFile } from "@redux/actions/uploads/uploadFile";
import { InsertPosition } from "@redux/actions/uploads";
import { fetchSectionItems } from "@redux/actions/items/useSectionItems";
import { batchSaveItems } from "@redux/actions/items/useBatchSaveItems";
import { fetchGalleryItems } from "@redux/actions/items/useGalleryItems";
import { createGallery } from "@redux/actions/items/useCreateGallery";
import { batchUpdateItemStatus } from "@redux/actions/items/useBatchUpdateItemStatus";
import { fetchRecentlyDeletedItems } from "@redux/actions/items/useRecentlyDeletedItems";
import { moveItems } from "@redux/actions/items/useMoveItems";
import { fetchRecoveredItems } from "@redux/actions/items/useRecoveredItems";
import { reorderSectionItems } from "@redux/actions/items/useReorderSectionItems";
import { clearRecentlyDeleted } from "@redux/actions/items/useClearRecentlyDeleted";
import { deleteAssets } from "@redux/actions/assets/useDeleteAssets";
import { deleteHeadingWithItems } from "@redux/actions/items/useDeleteHeadingWIthItems";
import { reorderHeading } from "@redux/actions/sections/useReorderHeading";
import { moveHeading } from "@redux/actions/sections/useMoveHeading";
import { duplicateHeading } from "@redux/actions/sections/useDuplicateHeading";
import { duplicateItems } from "@redux/actions/items/useDuplicateItems";
import { createItemsFromAssets } from "@redux/actions/items/useCreateItemsFromAssets";

type QueryState = Record<string, QueryData<unknown>>;

function insertItem(state: QueryState, item: Item, insertPosition: InsertPosition) {
  const itemId = `${item.id}-${item.version}`;
  // Inserted into a gallery
  if (item.itemId) {
    fetchGalleryItems.getQueryData(state, { itemId: item.itemId, version: 0 }).forEach(q => {
      q.data.total += 1;
      q.data.items.unshift(itemId);
    });
  } else {
    const { sectionId, insertIndex, displayOrder } = insertPosition;
    if (!displayOrder) {
      // There may not be a display order if an asset was added to a guide
      // If that is so, we don't need to insert it anywhere.
      return;
    }
    fetchSectionItems.getQueryData(state, { sectionId, version: 0 }).forEach(q => {
      const items = q.data.items;
      q.data.total += 1;

      if (displayOrder === "append") {
        if (items.length === q.data.total - 1) {
          items.push(itemId);
        }
      } else if (displayOrder === "prepend") {
        items.unshift(itemId);
        /**
         * If we're able to use a specific insertIndex value,
         * use that to avoid having to parse the index.
         */
      } else if (insertIndex !== undefined) {
        items.splice(insertIndex, 0, itemId);
      } else {
        /**
         * If no insertIndex is provided, parse the index from the
         * displayOrder sent to the API.
         * Display order may be null if it was a replace or an asset was added to a guide
         */
        const [direction, targetId] = displayOrder.split(":");
        let spliceIndex = items.indexOf(`${targetId}-0`);
        if (direction === "after") spliceIndex++;
        items.splice(spliceIndex, 0, itemId);
      }
    });
  }
}

function resetSection(state: QueryState, sectionId: string) {
  fetchSectionItems.getQueryData(state, { sectionId, version: 0 }).forEach(invalidateQuery);
}

export default createEntityReducer<Item>(
  "items",
  queryBuilder => {
    queryBuilder
      .addCase(duplicateHeading.fulfilled, (state, action) => {
        const { toSectionId } = action.meta.arg;
        resetSection(state, toSectionId);
      })
      .addCase(moveHeading.fulfilled, (state, action) => {
        const { fromSectionId, toSectionId } = action.meta.arg;
        resetSection(state, toSectionId);
        resetSection(state, fromSectionId);
      })
      .addCase(reorderHeading.fulfilled, (state, action) => {
        const { sectionId } = action.meta.arg;
        resetSection(state, sectionId);
      })
      .addCase(deleteHeadingWithItems.fulfilled, (state, action) => {
        const {
          entities: { items: deletedItems },
          result,
        } = action.payload;
        const { sectionId } = deletedItems[result.items[0]];
        fetchSectionItems.getQueryData(state, { sectionId, version: 0 }).forEach(q => {
          _remove(q.data.items, id => Boolean(deletedItems[id]));
          q.data.total -= result.items.length;
        });
      })
      .addCase(clearRecentlyDeleted.fulfilled, (state, action) => {
        const { kitId } = action.meta.arg;
        fetchRecentlyDeletedItems.getQueryData(state, { kitId }).forEach(q => {
          q.data = [];
        });
      })
      .addCase(reorderSectionItems.fulfilled, (state, action) => {
        const { sectionId } = action.meta.arg,
          { reorderedSectionItemIds } = action.payload;

        fetchSectionItems.getQueryData(state, { sectionId, version: 0 }).forEach(q => {
          q.data.items = reorderedSectionItemIds;
        });
      })
      .addCase(moveItems.fulfilled, (state, action) => {
        const {
          entities,
          result: { items: movedItems },
        } = action.payload;
        const { fromSectionId, toSectionId } = action.meta.arg;
        fetchSectionItems.getQueryData(state, { sectionId: toSectionId, version: 0 }).forEach(q => {
          if (q.data.items.length === q.data.total) {
            // Only add the items to the target section if all of it's items are already loaded.
            // Otherwise, we'll just wait for them to be fetched later.
            q.data.items.push(...movedItems);
          }
          q.data.total += movedItems.length;
        });
        if (fromSectionId) {
          fetchSectionItems
            .getQueryData(state, { sectionId: fromSectionId, version: 0 })
            .forEach(q => {
              _remove(q.data.items, id => movedItems.includes(id));
              q.data.total -= movedItems.length;
            });
        } else {
          // Moved from recovery
          const itemIds = action.meta.arg.itemIds.map(i => i.concat("-0"));
          const kitId = entities.items[itemIds[0]].kitId;
          fetchRecoveredItems.getQueryData(state, { kitId }).forEach(q => {
            q.data = q.data.filter(i => !itemIds.includes(i));
          });
        }
      })
      .addCase(batchUpdateItemStatus.fulfilled, (state, action) => {
        const { entities, result } = action.payload;
        const successfulItems = result.items
          .filter(i => i.success)
          .map(i => entities.items[i.result]);

        successfulItems.forEach((item: Item) => {
          const galleryId = item.itemId;
          const updatedItemId = `${item.id}-${item.version}`;

          // Updated the sections or gallery queries
          // only one of these should return a query
          const queries = [
            ...fetchGalleryItems.getQueryData(state, { itemId: galleryId, version: 0 }),
            ...fetchSectionItems.getQueryData(state, { sectionId: item.sectionId, version: 0 }),
          ];
          if (["trashed", "deleted"].includes(item.status)) {
            queries.forEach(q => {
              q.data.total -= 1;
              // using index is a slight optimization over filtering.
              const idx = q.data.items.indexOf(updatedItemId);
              if (idx > -1) q.data.items.splice(idx, 1);
            });
          } else if (item.status === "active") {
            queries.forEach(q => {
              q.data.total -= 1;

              // if it's not already in the data, insert it
              if (!q.data.items.includes(updatedItemId)) {
                // There used to be some code to try to insert the item based on display order.
                // It looks like it never worked because we don't have access to
                // the actual existing items, only the ids. If we end up building a
                // way to access the items from within the query reducer, we can revisit this.

                // const insertIndex = q.data.items.findIndex(i => i.displayOrder >= updatedItem.displayOrder);
                q.data.total += 1;
                q.data.items.push(updatedItemId);
              }
            });
          }
          // Update recently deleted
          if (item.status === "trashed") {
            fetchRecentlyDeletedItems
              .getQueryData(state, { kitId: item.kitId })
              .forEach(q => q.data.push(updatedItemId));
          } else {
            fetchRecentlyDeletedItems
              .getQueryData(state, { kitId: item.kitId })
              .forEach(q => _remove(q.data, i => i === updatedItemId));
          }
        });
      })
      .addCase(batchSaveItems.fulfilled, (state, action) => {
        const { newItems: newItemIds, insertIndex, entities } = action.payload;

        if (newItemIds.length === 0) return state;

        const newItems = newItemIds.map(item => entities.items[`${item.uuid}-0`]).filter(Boolean);

        if (newItems.length) {
          const sectionId = newItems[0].sectionId;
          if (sectionId) {
            let indexCount = insertIndex;
            fetchSectionItems.getQueryData(state, { sectionId, version: 0 }).forEach(q => {
              newItems.forEach(item => {
                q.data.items.splice(indexCount, 0, `${item.id}-0`);
                q.data.total += 1;
                indexCount++;
              });
            });
          } else {
            // if there's no sectionId, then it's a gallery
            fetchGalleryItems
              .getQueryData(state, { itemId: newItems[0].itemId, version: 0 })
              .forEach(q => {
                newItems.forEach(item => {
                  q.data.items.push(`${item.id}-0`);
                  q.data.total += 1;
                });
              });
          }
        }
      })
      .addCase(deleteAssets.fulfilled, (state, action) => {
        const { result, entities } = action.payload;
        const itemKits = result.items
          .filter(r => r.success)
          .reduce(
            (acc, r) => {
              const { sectionId } = entities.items[r.result];
              if (!acc[sectionId]) acc[sectionId] = new Set();
              acc[sectionId].add(r.result);
              return acc;
            },
            {} as Record<string, Set<string>>
          );

        Object.entries(itemKits).forEach(([sectionId, itemIds]) => {
          fetchSectionItems.getQueryData(state, { sectionId, version: 0 }).forEach(q => {
            const removed = _remove(q.data.items, i => itemIds.has(i));
            q.data.total -= removed.length;
          });
        });
      })
      .addCase(duplicateItems.fulfilled, (state, action) => {
        const {
          result: { items: newItems },
        } = action.payload;
        const { toSectionId } = action.meta.arg;
        fetchSectionItems.getQueryData(state, { sectionId: toSectionId, version: 0 }).forEach(q => {
          if (q.data.items.length === q.data.total) {
            // Only add the items to the target section if all of it's items are already loaded.
            // Otherwise, we'll just wait for them to be fetched later.
            q.data.items.push(...newItems);
          }
          q.data.total += newItems.length;
        });
      })
      .addCase(createItemsFromAssets.fulfilled, (state, action) => {
        const { entities, result } = action.payload;
        const items = result.items.filter(r => r.success).map(r => entities.items[r.result]);
        items.forEach(item =>
          insertItem(state, item, {
            sectionId: action.meta.arg.sectionId,
            displayOrder: "append",
          })
        );
      })
      .addCase(createGallery.fulfilled, (state, action) => {
        const { entities, result } = action.payload;
        const item = entities.items?.[result];
        if (item) insertItem(state, item, action.meta.arg.insertPosition);
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const { entities, result } = action.payload;
        const item = entities.items?.[result];
        // In some cases, we created an asset so there won't be an item
        if (item) insertItem(state, item, action.meta.arg.upload.insertPosition);
      });
  },
  objectBuilder => {
    objectBuilder.addDefaultCase((state, action: any) => {
      const items: Record<string, Item> =
        action?.response?.entities?.items || action?.payload?.entities?.items;
      if (items) {
        _mergeWith(state, items, (_objValue, _srcValue, key, obj, src) => {
          if (key === "asset" && !src.asset && src.assetId) {
            // When we create items, they may be returned without the asset included.
            // We need to populate the asset key with the assetId.
            return src.assetId;
          }
          return undefined;
        });
      }
    });
  }
);
