import React, { useCallback } from "react";
import {
  Flex,
  Checkbox,
  Text,
  Tooltip,
  Kit,
  SpacePermission,
  Button,
  Box,
} from "@thenounproject/lingo-core";

import { useSelectSpace } from "@selectors/entities/spaces";
import useUpdateKitPublicAccess from "@redux/actions/kits/useUpdateKitPublicAccess";
import useUpsell, { AccessStatus } from "@hooks/useUpsell";
import ConfigureRequestsToolbar from "./ConfigureRequestsToolbar";

function KitDownloadRequestsCheckbox({ kit }: { kit: Kit }) {
  const space = useSelectSpace();
  const [updatePublicAccess] = useUpdateKitPublicAccess();
  const downloadRequirementAccess = useUpsell(SpacePermission.manageDownloadRequirements);
  const insufficientPlan = downloadRequirementAccess.accessStatus === AccessStatus.insufficientPlan;
  const settingsAtPortalLevelOverrideKit = space.downloadsRestricted && kit.portalAccess;
  const disabled = insufficientPlan || settingsAtPortalLevelOverrideKit;

  const togglePublicAccess = useCallback(() => {
    return updatePublicAccess({
      kitId: kit.kitId,
      spaceId: space.id,
      downloadsRestricted: !kit.downloadsRestricted,
    });
  }, [updatePublicAccess, kit?.kitId, kit?.downloadsRestricted, space?.id]);

  const renderSpaceLevelRestrictionTooltip = () => {
    if (!settingsAtPortalLevelOverrideKit) return null;
    return (
      <Tooltip source="require-info-checkbox" direction="bottom">
        This kit is in your brand portal. Public access settings for the brand portal have already
        been configured in your space settings. All kits in the portal will use these settings.
      </Tooltip>
    );
  };

  const renderUpsellTooltip = () => {
    if (downloadRequirementAccess.accessStatus === AccessStatus.insufficientPlan) {
      return (
        <Tooltip source="require-info-checkbox" direction="bottom">
          Download requests are not available on your current plan.{" "}
          <Button
            buttonStyle="tertiary"
            tintColor="white"
            fontStyle="ui.smallBold"
            text="View plans."
            onClick={downloadRequirementAccess.openUpgradeModal}
          />
        </Tooltip>
      );
    }
  };

  if (downloadRequirementAccess.accessStatus === AccessStatus.insufficientRole) return null;

  return (
    <Flex flexDirection="column" width="100%">
      <Flex alignItems="center">
        {renderSpaceLevelRestrictionTooltip()}
        {renderUpsellTooltip()}
        <Flex data-tooltip-source="require-info-checkbox">
          <Checkbox
            onClick={togglePublicAccess}
            disabled={disabled}
            isSelected={
              settingsAtPortalLevelOverrideKit ? space.downloadsRestricted : kit.downloadsRestricted
            }
            label={
              <Flex>
                <Text font="ui.small" color="grayDarkest">
                  Enable download requests
                </Text>
              </Flex>
            }
          />
        </Flex>
        <Tooltip.InfoTip fill="grayDarkest">
          Visitors can browse but will be required to enter an email address before downloading
          assets. This data will be available in insights.{" "}
          <Button
            buttonStyle="tertiary"
            tintColor="white"
            fontStyle="ui.smallBold"
            text="Learn more."
            link="https://help.lingoapp.com/en/articles/8307735-download-requests"
            newWindow
          />
        </Tooltip.InfoTip>
      </Flex>
      {!settingsAtPortalLevelOverrideKit && kit.downloadsRestricted && (
        <Box mt="8px">
          <ConfigureRequestsToolbar kit={kit} />
        </Box>
      )}
    </Flex>
  );
}

export default React.memo(KitDownloadRequestsCheckbox);
