import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  subdomain: string;
};

const [useUpdateSpaceSubdomain, updateSpaceSubdomain] = createAsyncAction(
  "spaces/updateSpaceSubdomain",
  async ({ spaceId, subdomain }: Args) => {
    return await API.call<number>({
      endpoint: `spaces/${spaceId}`,
      method: "PUT",
      entity: API.Entity.space,
      data: { subdomain },
    });
  }
);

export default useUpdateSpaceSubdomain;
export { updateSpaceSubdomain };
