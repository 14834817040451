import React, { useMemo, Fragment } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import styled, { useTheme } from "styled-components";
import { Box, Flex, Text, utils, EmptyState } from "@thenounproject/lingo-core";
import { ReportContainer } from "./ReportElements";
import ReportTitle from "../report/ReportTitle";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const LegendBullet = styled(Box).attrs({
  borderRadius: "50%",
  p: "xs",
  mr: "s",
})``;

const LegendLabel = styled(Text).attrs({
  font: "ui.small",
})``;

ChartJS.defaults.font.family = "Inter";

type Props = {
  title: string;
  error: boolean;
  labels: string[];
  values: Array<{
    label: string;
    data: number[];
    color: string;
  }>;
  subtitle?: string;
  tooltip?: string;
  headerExtra?: React.ReactNode;
  Ylabel?: string;
  hideTooltipFooter?: boolean;
};

const Chart: React.FC<Props> = ({
  title,
  subtitle,
  tooltip,
  error,
  labels,
  values,
  headerExtra,
  hideTooltipFooter,
}) => {
  const theme = useTheme();
  const { getColor } = utils;

  const chartData = useMemo(() => {
    const datasets = values.map(v => ({
      label: v.label,
      data: v.data,
      backgroundColor: theme[v.color],
      borderColor: theme[v.color],
      borderWidth: 2,
      pointHoverRadius: 6,
      pointHoverBorderWidth: 2,
      pointHoverBorderColor: `${theme[v.color]}4d`,
      pointHitRadius: 30,
      fill: false,
    }));
    return {
      labels,
      datasets,
    };
  }, [labels, theme, values]);

  function renderChartLegend() {
    return (
      <Flex justifyContent="flex-end" mr="m" mt="s">
        <Box>
          {chartData.datasets.map(d => (
            <Flex key={d.label} alignItems="center">
              <LegendBullet background={d.borderColor as string} />
              <LegendLabel>{d.label}</LegendLabel>
            </Flex>
          ))}
        </Box>
      </Flex>
    );
  }

  function renderEmpty() {
    return (
      <Box>
        <EmptyState
          styleOverrides={{ py: "xxl" }}
          title="No data"
          subtitle="There is nothing to show for the current period."
        />
      </Box>
    );
  }

  function renderError() {
    return (
      <Box>
        <EmptyState
          iconProps={{ iconId: "error", size: 80, fill: "error" }}
          styleOverrides={{ py: "xxl" }}
          title="Failed to load"
          subtitle="Something went wrong trying to load your data. Try refreshing the page."
        />
      </Box>
    );
  }

  function renderChart() {
    return (
      <Fragment>
        {renderChartLegend()}
        <Box height="284px" pl="m" pb="m">
          <Line
            data={chartData}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: { display: false },
                tooltip: {
                  intersect: false,
                  mode: "index",
                  position: "nearest",
                  callbacks: {
                    title: () => "",
                    label: item => {
                      return ` ${item.dataset.label}: ${item.formattedValue}`;
                    },
                    footer: tti => {
                      if (hideTooltipFooter) return;
                      return `Total Uses: ${tti.reduce((sum, ds) => sum + (ds.raw as number), 0)}`;
                    },
                  },
                },
              },
              scales: {
                x: {
                  border: {
                    width: 1,
                    color: getColor("grayLight"),
                  },
                  grid: {
                    lineWidth: 0,
                    tickLength: 0,
                  },
                  ticks: { padding: 8 },
                },
                y: {
                  border: {
                    width: 1,
                    color: getColor("grayLight"),
                  },
                  grid: {
                    lineWidth: 0,
                    tickLength: 0,
                  },
                  min: 0,
                  ticks: {
                    stepSize: 1,
                    padding: 8,
                  },
                  suggestedMin: 0,
                },
              },
            }}
          />
        </Box>
      </Fragment>
    );
  }

  const isEmpty = !values?.length || !values.some(v => v?.data);

  return (
    <ReportContainer>
      <ReportTitle title={title} subtitle={subtitle} tooltip={tooltip} headerExtra={headerExtra} />
      {error ? renderError() : isEmpty ? renderEmpty() : renderChart()}
    </ReportContainer>
  );
};

export default React.memo(Chart);
