import React, { useCallback } from "react";
import { Flex, Select } from "@thenounproject/lingo-core";

import { CustomFieldInputProps } from ".";
import { CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP } from "@features/library/components/AssetLibraryFilterBar";

const SelectInput: React.FC<CustomFieldInputProps> = ({ field, toggleFilter, activeFilters }) => {
  const existingFilter = activeFilters.find(f => f.field_id === field.id);

  const options =
    field.options?.map(option => ({
      value: option.name,
      label: option.name,
    })) || [];

  const handleChange = useCallback(
    (val: string) => {
      if (existingFilter) toggleFilter(existingFilter);
      if (val !== "none")
        toggleFilter({
          type: CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP[field.type],
          field_id: field.id,
          display: val,
          id: `field-${field.id}_${val}`,
          value: field.options.find(option => option.name === val)?.id.toString(),
        });
    },
    [existingFilter, field.id, field.options, field.type, toggleFilter]
  );

  return (
    <Flex flexDirection="column" mt="4px">
      <Select
        value={existingFilter?.display || "none"}
        options={[{ value: "none", label: "None" }, ...options]}
        size="small"
        buttonStyle="dropdown"
        onChange={handleChange}
        width="100%"
      />
    </Flex>
  );
};

export default SelectInput;
