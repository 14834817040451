import React from "react";
import styled, { useTheme } from "styled-components";
import { AssetType, Flex, getApiServiceURL } from "@thenounproject/lingo-core";

import SmartImage from "../SmartImage";
import AssetColor from "./AssetColor";
import useAssetOriginalUrl from "./queries/useOriginalAssetUrl";
import { Inspectable } from "@constants/Inspector";
import getAssetBackground from "@helpers/getAssetBackground";

const AssetContainer = styled(Flex).attrs<{ verticalScroll: boolean }>(props => ({
  textAlign: "center",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: !props.verticalScroll && "center",
}))<{ verticalScroll: boolean }>`
  cursor: ${props => (props.onClick ? "zoom-out" : "initial")};
`;

export type Props = {
  inspectable: Inspectable;
  hide: any;
  imageContainer: HTMLDivElement;
};

const AssetEnlarged = ({ inspectable, hide, imageContainer }: Props) => {
  const { data } = useAssetOriginalUrl(inspectable.asset);

  const theme = useTheme();

  function renderAsset(verticalScroll) {
    const type = inspectable.asset.type;

    if ([AssetType.jpg, AssetType.png, AssetType.svg].includes(type)) {
      if (!data) return null;
      return (
        <SmartImage
          src={data}
          style={getImageStyle()}
          displayStyle="contain"
          spinnerCoverParent={verticalScroll}
          isEnlargedAsset
        />
      );
    } else if (AssetType.sketchTypes.has(type)) {
      return (
        <SmartImage
          src={getApiServiceURL(
            `/assets/${inspectable.asset.id}/download?asset_token=${inspectable.asset.shareToken}&disposition=inline&type=png`
          )}
          style={getImageStyle()}
          displayStyle="contain"
        />
      );
    } else if (type === AssetType.pdf) {
      if (!data) return null;
      return <embed src={data} type="application/pdf" style={{ width: "100%", height: "100%" }} />;
    } else if (type === AssetType.color) {
      return <AssetColor asset={inspectable.asset} isSquare={false} />;
    } else {
      return <SmartImage src={inspectable.asset.thumbnails["1232"]} displayStyle="contain" />;
    }
  }

  function getImageStyle() {
    return { ...getSizeStyle(), ...getBackgroundStyle() };
  }

  function getSizeStyle() {
    const [width, height] = inspectable.asset.dimensions.split("x").map(d => parseInt(d));

    const imageWidth = width,
      imageHeight = height,
      containerWidth = imageContainer.clientWidth,
      containerHeight = imageContainer.clientHeight,
      isLandscape = width >= height,
      isPortrait = height > width;

    if (isPortrait && imageWidth <= containerWidth && imageHeight <= containerHeight) {
      //when image is a portrait and smaller than the container div
      return { height: "100%" };
    } else if (isLandscape && imageWidth <= containerWidth && imageHeight <= containerHeight) {
      //when image is landscape and smaller than the container div
      return { width: "100%" };
    } else if (imageWidth <= containerWidth && imageHeight > containerHeight) {
      //when the image width is smaller than the container but the height is greater than (i.e. tall assets)
      return {
        height: `${height}px`,
      };
    } else {
      //when the image width is larger than the container width
      const scaleBy = containerWidth / imageWidth;
      return {
        height: `${height * scaleBy}px`,
        position: "absolute",
      };
    }
  }

  function getBackgroundStyle() {
    if (!inspectable.asset) return;
    return getAssetBackground(inspectable.asset, theme);
  }

  // This can only really happen in tests
  if (!imageContainer) return null;

  const [width, height] = inspectable.asset.dimensions.split("x"),
    verticalScroll =
      parseInt(height) > parseInt(width) && parseInt(height) > imageContainer.clientHeight,
    hideOnClick = !AssetType.videoTypes.has(inspectable.asset.type);

  return (
    <AssetContainer
      onClick={hideOnClick ? hide : undefined}
      verticalScroll={verticalScroll}
      aria-label="Hide enlarged asset">
      {renderAsset(verticalScroll)}
    </AssetContainer>
  );
};

export default React.memo(AssetEnlarged);
