import React, { useCallback, useState, Fragment, useMemo } from "react";
import { Text, Input } from "@thenounproject/lingo-core";

import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";
import ModalFooter from "../../../ModalFooter";
import useAddSpaceEmailDomain from "@redux/actions/spaces/useAddSpaceEmailDomain";
import useShowModal from "@redux/actions/useModals";

type Props = {
  spaceId: number;
};

enum ViewState {
  submitting = "submitting",
  pendingVerification = "pendingVerification",
  complete = "complete",
}

const AddJoinDomainModal: React.FC<Props> = ({ spaceId }) => {
  const [error, setError] = useState(null),
    [processing, setProcessing] = useState(false),
    [email, setEmail] = useState(""),
    [addSpaceEmailDomain] = useAddSpaceEmailDomain(),
    [state, setState] = useState(ViewState.submitting),
    { dismissModal } = useShowModal();

  const confimationMessage = useMemo(() => {
    let message =
      "Check your inbox. We’ve sent you an email with a link to finish adding an approved email.";
    try {
      const address = email.split("@").pop();
      if (address) {
        message = `Check your inbox. We’ve sent you an email with a link to finish adding @${address} as an approved email.`;
      }
    } catch {
      // okay
    }
    return message;
  }, [email]);

  const submit = useCallback(async () => {
    setProcessing(true);
    const { error: responseError } = await addSpaceEmailDomain({ spaceId, email });
    setProcessing(false);
    if (responseError) {
      setError(responseError.message);
    } else {
      setState(ViewState.pendingVerification);
    }
  }, [addSpaceEmailDomain, email, spaceId]);

  switch (state) {
    case ViewState.submitting:
      return (
        <Fragment>
          <ModalHeader title={"Add approved email domain"} />
          <ModalBody>
            <Text>
              Enter your email with the domain that you would like to approve. For exmple,
              me@acme.com will allow anyone with an @acme.com email to join your space.
            </Text>
            <Text mt="m">We will send you an email with a link to complete the setup.</Text>

            <Input
              id="confirmation-input"
              styleOverrides={{ mt: "l" }}
              value={email}
              label="Email"
              type="text"
              onChange={e => setEmail(e.target.value)}
              onSubmit={submit}
              placeholder="me@acme.com"
              message={error}
              inputStyle={error ? "error" : null}
              autoFocus
            />
          </ModalBody>
          <ModalFooter
            primary={{
              text: processing ? "Saving..." : "Continue",
              onClick: submit,
              disabled: processing,
            }}
          />
        </Fragment>
      );
    case ViewState.pendingVerification:
      return (
        <Fragment>
          <ModalHeader title={"Add approved email domain"} />
          <ModalBody>
            <Text>{confimationMessage}</Text>
          </ModalBody>
          <ModalFooter
            primary={{
              text: "Done",
              onClick: dismissModal,
            }}
          />
        </Fragment>
      );
    case ViewState.complete:
      return null;
  }
};

export default AddJoinDomainModal;
