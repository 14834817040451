// MARK : Generators
/* -------------------------------------------------------------------------------*/

export type Period = "monthly" | "yearly";

export type Plan = {
  id: string;
  name: string;
  subtitle: string;
  previous_plan?: string;
  monthly: {
    id: string;
    base_price: number;
    editor_cost: number;
    additional_users?: string;
  };
  yearly: {
    id: string;
    base_price: number;
    editor_cost: number;
    additional_users?: string;
  };
  storage: number;
  maxConsumers: number;
  additional_users_title: string;
  features: Record<string, boolean | string | number | { text: string; subtext: string }>;
  uniqueFeatures: PlanFeature[];
  bestValue?: boolean;
};

export type PlanFeature = {
  name: string;
  description: string;
  enabled: boolean;
};

export type Feature = {
  id: string;
  name: string;
  description: string;
};

export function feature(id: string, name: string, description?: string): Feature {
  return { id, name, description };
}

const contentBillingFeatures = [
  feature("payment_options", "Payment Options", "Flexible billing options to suit your needs."),
  feature(
    "contract_terms",
    "Contract Terms",
    "Use standard agreements or customize terms to fit your needs"
  ),
  feature(
    "security",
    "Security & Compliance",
    "Meet industry security standards with options for additional compliance reviews"
  ),
];
const contentManagementFeatures = [
  feature(
    "storage",
    "Storage",
    "The total storage needs in GB for all of the assets you store in Lingo."
  ),
  feature("custom_exports", "Custom Exports", "Adjust asset dimensions before downloading"),
  feature(
    "versioning",
    "Kit Versioning",
    "Control when changes to your kits are published with version management"
  ),
  feature(
    "tag_manager",
    "Tag Manager ",
    "View, edit, and manage all your tags from a single location"
  ),
  feature(
    "saved_views",
    "Saved Views",
    "Save filtered asset views for quick access and easy reference"
  ),
  feature("basic_insights", "Basic Insights", "Track basic usage stats for your assets"),
  feature(
    "advanced_insights",
    "Advanced Insights",
    "Gain deeper insights into asset performance with advanced analytics"
  ),
  feature(
    "export_controls",
    "Export Controls",
    "Manage and restrict export options for your assets"
  ),
  feature(
    "custom_fields",
    "Custom Fields",
    "Add personalized metadata to your assets for better organization"
  ),
  feature(
    "galleries",
    "Dynamic Galleries",
    "Create automatically updated galleries from your Library views."
  ),
  feature("brand_setup", "Brand Setup", "Let our team set up your Lingo workspace for you."),
];

const contentDistributionFeatures = [
  feature(
    "consumers",
    "Consumers",
    "People who can access and view your content but do not have editing permissions. This includes logged-in users and guests accessing public kits and portals"
  ),
  feature(
    "kit_access",
    "Public Kit Access",
    "Make a kit publicly accessible—no Lingo account required"
  ),
  feature(
    "portal_access",
    "Public Portal Access",
    "Make a portal publicly accessible—no Lingo account required"
  ),
  feature(
    "password_kit",
    "Password Protected Kits",
    "Restrict kit access to only those with the password"
  ),
  feature(
    "password_portal",
    "Password Protected Portals",
    "Restrict portal access to only those with the password"
  ),
  feature(
    "sso",
    "SSO ",
    "Simplify and secure access to your brand’s assets with SSO authentication"
  ),
  feature(
    "custom_themes",
    "Custom Branding",
    "Replace Lingo’s branding with your own for a fully branded experience"
  ),
  feature(
    "domains",
    "Custom Domain",
    "Use a custom domain to give your library a professional, branded URL"
  ),
  feature("api", "API Access", "Programmatically access and manage your content with our API"),
  feature(
    "download_requests",
    "Download Requests",
    "Require users to submit information before downloading assets. Approve or deny requests as needed"
  ),
  feature(
    "direct_links",
    "Direct Links",
    "Generate CDN-hosted URLs for fast, direct downloads—no authentication required"
  ),
];

const modalFeatures = [
  feature("roles", "User roles and permissions"),
  feature("team_access", "Share with users on your team"),
  feature("custom_exports", "Custom exports"),
  feature("advanced_branding", "Advanced branding"),
  feature("custom_subdomains", "Custom subdomains"),
  feature("protected_links", "Password Protected Links"),
  feature("custom_views", "Custom views"),
  feature("export_controls", "export controls"),
  feature("custom_setup", "Custom brand setup"),
  feature("custom_fonts", "Custom fonts"),
  feature("unlimited", "Unlimited brand portals"),
  feature("insights", "Insights"),
  feature("saml", "SAML SSO"),
  feature("priority", "Priority support"),
  feature("custom_domains", "Custom domains"),
];

const features = [
  ...contentManagementFeatures,
  ...contentDistributionFeatures,
  ...contentBillingFeatures,
  ...modalFeatures,
];

function generateFeatures(planFeatures: string[]): PlanFeature[] {
  return planFeatures.map(id => {
    const f = features.find(_f => _f.id === id);
    return {
      name: f.name,
      description: f.description,
      enabled: true,
    };
  });
}

const plans: Plan[] = [
  {
    name: "Starter",
    id: "starter",
    subtitle: "The basics needed for your team.",
    previous_plan: "Basic",
    monthly: {
      id: "tier-st-month-100",
      base_price: 100,
      editor_cost: 20,
    },
    yearly: {
      id: "tier-st-year-80",
      base_price: 80,
      editor_cost: 16,
    },
    storage: 100,
    maxConsumers: 50,
    additional_users_title: "Additional users",
    features: {
      storage: { text: "Usage based", subtext: "100 GB included" },
      consumers: { text: "Usage based", subtext: "50/mo included" },
      custom_exports: true,
      versioning: true,
      tag_manager: true,
      saved_views: true,
      brand_setup: "Optional for one time fee",
      basic_insights: true,
      custom_themes: "Basic",
      direct_links: { text: "Usage based", subtext: "1GB/1k requests/mo included" },
      kit_access: true,
      portal_access: true,
      payment_options: "Credit card only",
      contract_terms: "Standard terms",
      security: "Standard",
    },
    uniqueFeatures: generateFeatures(["roles", "team_access", "custom_exports"]),
  },
  {
    name: "Business",
    id: "business",
    subtitle: "Enhanced tools for growing teams.",
    bestValue: true,
    previous_plan: "Starter",
    monthly: {
      id: "tier-bus-month-200",
      base_price: 200,
      editor_cost: 30,
    },
    yearly: {
      id: "tier-bus-year-160",
      base_price: 160,
      editor_cost: 24,
    },
    storage: 200,
    maxConsumers: 100,
    additional_users_title: "Additional users (up to 50)",
    features: {
      storage: { text: "Usage based", subtext: "200 GB included" },
      consumers: { text: "Usage based", subtext: "100/mo included" },
      custom_exports: true,
      tag_manager: true,
      custom_fields: true,
      export_controls: true,
      versioning: true,
      custom_themes: "Advanced",
      domains: "Subdomain",
      api: true,
      saved_views: true,
      brand_setup: "Optional for one time fee",
      basic_insights: true,
      advanced_insights: true,
      galleries: true,
      direct_links: { text: "Usage based", subtext: "3GB/3k requests/mo included" },
      kit_access: true,
      portal_access: true,
      password_kit: true,
      password_portal: true,
      payment_options: "Credit card only",
      contract_terms: "Standard terms",
      security: "Standard",
    },
    uniqueFeatures: generateFeatures([
      "advanced_branding",
      "custom_subdomains",
      "protected_links",
      "tag_manager",
      "custom_views",
      "export_controls",
    ]),
  },
  {
    name: "Enterprise",
    id: "enterprise",
    subtitle: "Advanced features to scale your brand.",
    previous_plan: "Business",
    monthly: {
      id: "tier-ent-month",
      base_price: 0,
      editor_cost: 0,
      additional_users: "",
    },
    yearly: {
      id: "tier-ent-year",
      base_price: 0,
      editor_cost: 0,
      additional_users: "",
    },
    storage: 0,
    maxConsumers: null,
    additional_users_title: "Additional users (up to 50)",
    features: {
      storage: { text: "Usage based", subtext: "custom amount included" },
      consumers: { text: "Usage based", subtext: "custom amount included" },
      custom_exports: true,
      tag_manager: true,
      custom_fields: true,
      export_controls: true,
      versioning: true,
      custom_themes: "Advanced + Theming",
      domains: "Custom host",
      api: true,
      download_requests: 1,
      sso: 1,
      saved_views: true,
      brand_setup: "Optional for one time fee",
      basic_insights: true,
      advanced_insights: true,
      galleries: true,
      direct_links: { text: "Usage based", subtext: "5GB/5k requests/mo included" },
      kit_access: true,
      portal_access: true,
      password_kit: true,
      password_portal: true,
      payment_options: "Invoicing & purchase orders",
      contract_terms: "Custom agreements (MSA)",
      security: "Custom Security & Compliance Reviews",
    },
    uniqueFeatures: generateFeatures([
      "custom_setup",
      "custom_fonts",
      "custom_domains",
      "unlimited",
      "insights",
      "download_requests",
      "saml",
      "priority",
    ]),
  },
];

const pricingPagesSubtitle =
  "Streamline asset management and distribution for a fraction of the cost of traditional DAMs.";

const content = {
  title: "View our plans",
  subtitle: pricingPagesSubtitle,

  comparisonTitle: "Compare Features",
  comparisionSubtitle: "Content Distribution",

  faqTitle: "Frequently Asked Questions",
  faqSubtitle: "Pricing related questions",

  faqs: [
    {
      question: "What is an editor?",
      answer: "An editor is anyone that has edit access to your content.",
    },
    {
      question: "What is a consumer?",
      answer:
        "A consumer is anyone that accesses your content that is not an editor. This includes logged in users and guests accessing your content through public kits and portals. ",
    },
    {
      question: "If I invite my team to Lingo, will all users automatically count as consumers?",
      answer:
        "No. Just because a user has an account doesn’t mean they count as a consumer. A consumer is only counted when they actively access content, such as viewing kits, portals, or assets. If a user has an account but hasn’t accessed any content, they won’t be included in your consumer usage.",
    },
    {
      question: "What happens if I exceed the included consumer usage for my plan?",
      answer: `Consumer usage has a "soft limit." If you exceed the included consumer usage in your plan we'll simply reach out to learn more about your needs and discuss an upgrade that works for you—no surprise charges.`,
    },
    {
      question: "What happens if I add or remove editors in the middle of my billing cycle?",
      answer:
        "If you add or remove editors during your billing cycle, your charges will automatically adjust based on the time they had access. You'll be charged immediately for the prorated amount based on the remaining time on your current cycle.",
    },
  ],
  // Used by comparison tables
  featureGroups: [
    {
      name: "Content Distribution",
      features: contentDistributionFeatures,
    },
    {
      name: "Content Management",
      features: contentManagementFeatures,
    },
    {
      name: "Billing & Compliance",
      features: contentBillingFeatures,
    },
  ],
  plans,
};

export default content;
