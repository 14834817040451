import React from "react";
import { noop } from "lodash";
import { subDays, subYears, startOfToday } from "date-fns";

import { formatSearchFilterDate } from "@features/library/utils";
import { OperatorValues } from "@thenounproject/lingo-core";

export enum IntervalOptions {
  sevenDays = "sevenDays",
  thirtyDays = "thirtyDays",
  ninetyDays = "ninetyDays",
  oneYear = "oneYear",
  custom = "custom",
}

const today = startOfToday();
const now = new Date();

export const periodOptions = {
  [IntervalOptions.sevenDays]: {
    label: "7 days",
    dateOperators: {
      gte: formatSearchFilterDate(subDays(today, 6).toISOString()),
      lte: formatSearchFilterDate(now.toISOString()),
    },
  },
  [IntervalOptions.thirtyDays]: {
    label: "30 days",
    dateOperators: {
      gte: formatSearchFilterDate(subDays(today, 29).toISOString()),
      lte: formatSearchFilterDate(now.toISOString()),
    },
  },
  [IntervalOptions.ninetyDays]: {
    label: "90 days",
    dateOperators: {
      gte: formatSearchFilterDate(subDays(today, 89).toISOString()),
      lte: formatSearchFilterDate(now.toISOString()),
    },
  },
  [IntervalOptions.oneYear]: {
    label: "1 year",
    dateOperators: {
      gte: formatSearchFilterDate(subYears(today, 1).toISOString()),
      lte: formatSearchFilterDate(now.toISOString()),
    },
  },
};

export const formatCustomInterval = (start: Date, end: Date) => {
  return {
    gte: formatSearchFilterDate(start.toISOString()),
    lte: formatSearchFilterDate(end.toISOString()),
  };
};

type InsightsContextType = {
  interval: IntervalOptions;
  dateOperators: OperatorValues;
  updateInterval: (interval: IntervalOptions, customInterval?: [Date, Date]) => void;
  customInterval?: [Date, Date];
};

export const initialState: InsightsContextType = {
  interval: IntervalOptions.sevenDays,
  dateOperators: periodOptions[IntervalOptions.sevenDays].dateOperators,
  updateInterval: noop,
  customInterval: undefined,
};

export const InsightsContext = React.createContext<InsightsContextType>(initialState);

InsightsContext.displayName = "InsightsContext";
