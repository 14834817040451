import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  version: number;
  name?: string;
  notes?: string;
};

const [useUpdateKitVersion, updateKitVersion] = createAsyncAction(
  "kitVersions/update",
  async (args: Args) => {
    return await API.call<string>({
      endpoint: `kits/${args.kitId}`,
      method: "PUT",
      entity: API.Entity.kitVersion,
      data: {
        version: args.version,
        version_identifier: args.name,
        notes: args.notes,
      },
    });
  }
);

export default useUpdateKitVersion;
export { updateKitVersion };
