import React from "react";
import {
  Flex,
  CustomField,
  CustomFieldOption,
  Checkbox,
  useBoolean,
  Button,
} from "@thenounproject/lingo-core";

import { CustomFieldInputProps } from ".";
import { CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP } from "@features/library/components/AssetLibraryFilterBar";

const buildFilterFromOption = (field: CustomField, option: CustomFieldOption) => ({
  type: CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP[field.type],
  field_id: field.id,
  display: option.name,
  id: `field-${field.id}_${option.name}`,
  value: option.id.toString(),
});

const ChecklistInput: React.FC<CustomFieldInputProps> = ({
  field,
  toggleFilter,
  activeFilters,
}) => {
  const [showAll, setShowAll, setHide] = useBoolean(false);
  const existingFilters = activeFilters.filter(f => f.field_id === field.id);

  const renderCheckboxes = () => {
    const options = showAll ? field.options : field.options.slice(0, 4);
    return options.map(option => {
      return (
        <Checkbox
          styleOverrides={{ pl: "none" }}
          key={option.id}
          label={option.name}
          isSelected={Boolean(
            existingFilters.find(f => f.id === `field-${field.id}_${option.name}`)
          )}
          onClick={() => toggleFilter(buildFilterFromOption(field, option))}
        />
      );
    });
  };

  return (
    <Flex flexDirection="column" mt="4px">
      {renderCheckboxes()}
      {field.options.length > 4 && (
        <Flex mt="8px">
          <Button
            text={showAll ? "Show less" : "Show all"}
            onClick={() => (showAll ? setHide() : setShowAll())}
            buttonStyle="tertiary"
            size="small"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default ChecklistInput;
