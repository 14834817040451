import React, { useCallback, useState } from "react";
import {
  Flex,
  ActivityIndicator,
  Box,
  Text,
  Input,
  buildURL,
  Button,
} from "@thenounproject/lingo-core";
import styled from "styled-components";
import ModalHeader from "../../ModalHeader";
import ModalBody from "../../ModalBody";
import ModalFooter from "../../ModalFooter";
import useMigrateSpace from "@redux/actions/spaces/useMigrateSpace";
import useShowModal from "@redux/actions/useModals";

const Divider = styled(Box).attrs({
  borderTop: "default",
  mt: "l",
  mb: "m",
})``;

type Props = {
  spaceId: number;
};

export default function ConfirmMigrationModal({ spaceId }: Props) {
  const [inputText, setInputText] = useState<string>("");
  const [migrationComplete, setMigrationComplete] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [migrateSpace] = useMigrateSpace();
  const { dismissModal } = useShowModal();

  const handleRunMigration = useCallback(async () => {
    if (inputText.toLowerCase() !== "migrate") {
      setError("Please type 'migrate' to confirm");
      return;
    }

    setError(null);
    setProcessing(true);

    const res = await migrateSpace(spaceId);
    setProcessing(false);
    if (res.error) {
      setError(res.error.message);
    } else {
      setMigrationComplete(true);
    }
  }, [setError, inputText, migrateSpace, spaceId]);

  const handleCompleteMigration = useCallback(() => {
    window.location.href = buildURL("/dashboard", { space: { id: spaceId } });
    dismissModal();
  }, [spaceId, dismissModal]);

  return (
    <>
      <ModalHeader title={migrationComplete ? "Migration Successful" : "Confirm the Migration"}>
        {!processing && (
          <Button
            buttonStyle="tertiary"
            aria-label="Close"
            icon="action.close"
            onClick={dismissModal}
            width="36px"
            height="36px"
            themeOverrides={{ primaryColor: "black" }}
            position="absolute"
            top={0}
            right={0}
            zIndex={50}
          />
        )}
      </ModalHeader>
      <ModalBody>
        <Flex flexDirection="column">
          <Box mb="m">
            <Text>
              {migrationComplete
                ? "Your Lingo space has been successfully configured for multiple portals"
                : "Are you sure you're ready to migrate your space? This is irreversible."}
            </Text>
          </Box>
          {!migrationComplete && (
            <Input
              disabled={processing}
              label={`Confirm by typing "migrate"`}
              placeholder="migrate"
              value={inputText}
              onChange={e => setInputText(e.target.value)}
              inputStyle={error ? "error" : undefined}
              message={error}
            />
          )}
          {processing && (
            <Flex flexDirection="column">
              <Divider />
              <Flex gap="4px">
                <ActivityIndicator size="small" />
                <Text>Migration in progress. This may take up to one minute.</Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </ModalBody>
      <ModalFooter
        primary={{
          disabled: processing,
          text: migrationComplete ? "Go to Dashboard" : "Run the Migration",
          onClick: migrationComplete ? handleCompleteMigration : handleRunMigration,
        }}
      />
    </>
  );
}
