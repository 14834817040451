import React from "react";
import { Text, Grid, Flex } from "@thenounproject/lingo-core";
import { Container, InnerMedium } from "../Elements";
import MarketingText from "../MarketingText";

type Props = {
  title: string;
  subtitle?: string;
  items: {
    title: string;
    body: string;
  }[];
  first?: boolean;
};

const TextGridSection: React.FC<Props> = ({ title, subtitle, items }) => {
  return (
    <Container>
      <InnerMedium first>
        <Text font="marketing.h3">{title}</Text>
        {subtitle && (
          <MarketingText mt="m" font="marketing.bodyLarge">
            {subtitle}
          </MarketingText>
        )}
      </InnerMedium>

      <Grid
        m="0 auto"
        mt="xxl"
        maxWidth="930px"
        templateColumns="2"
        gap="40px"
        variations={{
          "mq.m": { templateColumns: "2" },
          "mq.xs": { templateColumns: "1" },
        }}>
        {items.map(item => (
          <Flex key={item.title} flexDirection="column">
            <Text font="marketing.subtitle1">{item.title}</Text>
            <MarketingText>{item.body}</MarketingText>
          </Flex>
        ))}
      </Grid>
    </Container>
  );
};

export default TextGridSection;
