import React, { Fragment, useMemo, useCallback } from "react";
import {
  buildPath,
  buildURL,
  Text,
  Notice,
  getCustomDomainComponents,
  Space,
  useNavigation,
} from "@thenounproject/lingo-core";

import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";
import ModalFooter from "../../../ModalFooter";
import useNotifications from "@actions/useNotifications";
import useLeaveSpace from "@redux/actions/spaceMembers/useLeaveSpace";
import { useGetUser } from "@selectors/getters";
import useShowModal from "@redux/actions/useModals";

type Props = {
  space: Space;
};

function LeaveSpaceModal({ space }: Props) {
  const user = useGetUser();
  const domain = getCustomDomainComponents();
  const redirectTo = useMemo(() => {
    return (domain.identifier ? buildURL : buildPath)("/space");
  }, [domain]);

  const { dismissModal } = useShowModal();
  const { showNotification } = useNotifications();
  const navigate = useNavigation();

  const [leaveSpace, { error, isProcessing }] = useLeaveSpace();

  const handleLeave = useCallback(async () => {
    const res = await leaveSpace({ spaceId: space.id, userId: user?.id });
    if (res.isSuccess) {
      dismissModal();
      showNotification({ message: `You’ve left “${space.name}”.`, level: "info" });
      if (redirectTo.match(/^https?:\/\//)) window.location.href = redirectTo;
      else navigate.push(redirectTo);
    }
  }, [
    dismissModal,
    navigate,
    leaveSpace,
    redirectTo,
    showNotification,
    space.id,
    space.name,
    user?.id,
  ]);

  return (
    <Fragment>
      <ModalHeader title={`Leave ${space.name}, are you sure?`} />
      <ModalBody>
        <Text>You will be removed from “{space.name}” and all of its kits.</Text>
        {error && <Notice noticeStyle="error" mt="m" message={error.message} />}
      </ModalBody>
      <ModalFooter
        primary={{
          text: isProcessing ? "…" : "Yes, leave",
          disabled: isProcessing,
          onClick: handleLeave,
        }}
      />
    </Fragment>
  );
}

export default LeaveSpaceModal;
