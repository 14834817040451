import React, { useCallback } from "react";
import { PublicAccessApprovalMode } from "@thenounproject/lingo-core";

import useUpdateKitPublicAccess from "@redux/actions/kits/useUpdateKitPublicAccess";
import useShowModal from "@redux/actions/useModals";
import { useSelectSpace } from "@selectors/entities/spaces";

import useKit from "@redux/actions/kits/useKit";
import ConfigureRequestsModalContent from "./ConfigureRequestsModalContent";

type Props = {
  spaceId: number;
  kitId: string;
};

const ConfigureKitRequestsModal = ({ spaceId, kitId }: Props) => {
  const space = useSelectSpace(spaceId);
  const { data: kit, error: fetchError } = useKit({ spaceId, kitId });
  const { dismissModal } = useShowModal();
  const [updateKitPublicAccess, { error, isProcessing }] = useUpdateKitPublicAccess();

  const [approveMode, setApproveMode] = React.useState<PublicAccessApprovalMode>(
    kit?.downloadsRestrictedApproveMode ?? "approve_all_automatically"
  );

  const canSubmit = React.useMemo(() => {
    if (!kit || isProcessing) return false;

    return approveMode !== kit.downloadsRestrictedApproveMode;
  }, [approveMode, isProcessing, kit]);

  const onSubmit = useCallback(async () => {
    if (!canSubmit) return;
    const result = await updateKitPublicAccess({
      kitId: kit.kitId,
      spaceId: space.id,
      downloadsRestrictedApproveMode: approveMode,
    });
    if (result.isSuccess) {
      dismissModal();
    }
  }, [approveMode, canSubmit, dismissModal, kit?.kitId, space.id, updateKitPublicAccess]);

  return (
    <ConfigureRequestsModalContent
      approvalSetting={approveMode}
      canSubmit={canSubmit}
      error={fetchError ?? error}
      onChange={setApproveMode}
      onSubmit={onSubmit}
    />
  );
};

export default ConfigureKitRequestsModal;
