import { API, base64 } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useFetchSpaceTransferCandidates, fetchSpaceTransferCandidates] = createAsyncAction(
  "spaces/fetchSpaceTransferCandidates",
  async ({ spaceId }: { spaceId: number }) => {
    const pageSize = 2000,
      role = "admin",
      sort = "alpha",
      query = {
        role,
        sort,
        limit: pageSize,
      };
    const queryString = `?query=${base64.encode(query)}`;
    return await API.call<{ members: string[] }>({
      endpoint: `spaces/${spaceId}/members${queryString}`,
      method: "GET",
      entity: API.Entity.spaceMember,
    });
  }
);

export default useFetchSpaceTransferCandidates;
export { fetchSpaceTransferCandidates };
