/* eslint-disable react/prop-types */
import useBatchSaveItems from "@redux/actions/items/useBatchSaveItems";
import useShowModal from "@redux/actions/useModals";
import useNotifications from "@actions/useNotifications";
import {
  Asset,
  AssetType,
  Box,
  Button,
  Flex,
  formatDate,
  ItemType,
  pluralize,
  Text,
  TableCell,
} from "@thenounproject/lingo-core";
import React, { useCallback, useEffect, useState, useMemo, MouseEvent } from "react";
import { useAppSelectorV1 } from "@redux/hooks";
import styled from "styled-components";
import { type InsertPosition } from "@actions/uploads";
import { SubNavContainer } from "../../../components/navigation/SubNav";
import { InsertType } from "../../../constants/InsertType";
import { useAssetLibraryContext } from "../../../contexts/AssetLibraryProvider";
import { getNavPoint } from "@selectors/getters";
import { useSelectSpace } from "@selectors/entities/spaces";
import AssetLibraryHeader from "./AssetLibraryHeader";
import AssetLibraryNav from "./AssetLibraryNav";
import AssetLibrarySelectAll from "./AssetLibrarySelectAll";
import useLibraryAssets from "@redux/actions/search/useLibraryAssets";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";
import DataTable from "../../../components/table/DataTable";
import { TableColumn } from "../../../components/table";
import formatBytes from "@helpers/bytesToSize";
import { SearchSortTypes } from "../types";
import { renderEmptyState } from "../views/AssetLibraryView";
import assetIconMap from "../../../components/kits/GalleryAssetContent/assetIconMap";
import getAssetBackground from "@helpers/getAssetBackground";
import { useAssetSelection } from "@redux/actions/assets/useAssetSelection";

const ScrollContainer = styled(Box).attrs({
  width: "100%",
  position: "relative",
})`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
`;

type Props = {
  insertPosition: InsertPosition;
};

const AssetLibraryPickerModal: React.FC<Props> = ({ insertPosition }) => {
  const [sort, setSort] = React.useState<SearchSortTypes>(SearchSortTypes.recentDesc);
  const navPoint = useAppSelectorV1(getNavPoint);
  const space = useSelectSpace(),
    { clearSelection, selectionState } = useSelectedItemsContext(),
    { dismissModal } = useShowModal(),
    { appliedFilters, setFilters } = useAssetLibraryContext(),
    { data, isLoading, error, fetchNextPage } = useLibraryAssets(
      {
        spaceId: space.id,
        filters: appliedFilters,
        sort,
      },
      { refetchOnMount: true }
    ),
    [batchSaveItems] = useBatchSaveItems(),
    { showNotification } = useNotifications();

  const hasNextPage = data?.assets?.length < data?.total;

  const selectionProps = useAssetSelection({ assets: data?.assets });

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // Reset library selection on load
    clearSelection({ type: "assetLibrary" });
  }, [clearSelection]);

  /**
   * Clear filters when modal unmounts
   * */
  useEffect(() => {
    return () => {
      setFilters([]);
    };
  }, [setFilters]);

  const selectedLibraryAssets = useMemo(() => {
    return data?.assets?.filter(asset => selectionState.assetLibrary.includes(asset.id)) ?? [];
  }, [data, selectionState.assetLibrary]);

  const selectionCount = selectedLibraryAssets.length;

  const confirmSelection = useCallback(async () => {
    setProcessing(true);

    let newItems;
    if (insertPosition.itemId) {
      newItems = selectedLibraryAssets.map(asset => ({
        type: ItemType.asset,
        asset_uuid: asset.id,
        kit_uuid: insertPosition.kitId,
        item_uuid: insertPosition.itemId,
      }));
    } else {
      newItems = selectedLibraryAssets.map(asset => ({
        type: ItemType.asset,
        asset_uuid: asset.id,
        kit_uuid: insertPosition.kitId,
        section_uuid: insertPosition.sectionId,
        display_order: insertPosition.displayOrder,
      }));
    }
    const res = await batchSaveItems({
      updatedItems: [],
      insertConfig: {
        newItems,
        insertIndex: insertPosition.insertIndex,
        insertType: InsertType.library,
        sectionId: insertPosition.sectionId,
        targetIndex: insertPosition.insertIndex,
      },
    });

    setProcessing(false);
    if (res.error) {
      showNotification({ message: res.error.message, level: "error" });
    } else {
      const errors = res.response?.result?.filter?.(f => !f.success) || [];
      if (errors.length) {
        showNotification({
          message: `${errors.length} ${pluralize("asset", errors.length)} could not be added.`,
          level: "error",
        });
      }
      dismissModal();
    }
  }, [
    batchSaveItems,
    dismissModal,
    insertPosition.displayOrder,
    insertPosition.insertIndex,
    insertPosition.kitId,
    insertPosition.sectionId,
    insertPosition.itemId,
    selectedLibraryAssets,
    showNotification,
  ]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<Asset>[] = [
      {
        name: "Name",
        minWidth: 350,
        renderer: asset => {
          return (
            <TableCell
              data={asset}
              space={space}
              icon={assetIconMap[asset.type]}
              background={getAssetBackground(asset, space.theme).background}
              insights={false}
            />
          );
        },
        sortKey: "alpha",
      },
      {
        name: "Type",
        minWidth: 100,
        renderer: asset => <Text>{AssetType.displayName(asset?.type)}</Text>,
        sortKey: "type",
      },
      {
        name: "File Size",
        minWidth: 100,
        renderer: asset => <Text>{formatBytes(asset.size)}</Text>,
        sortKey: "size",
      },
      {
        name: "Resolution",
        minWidth: 100,
        renderer: asset => <Text>{asset.dimensions}</Text>,
        sortKey: "resolution",
      },
      {
        name: "Date Created",
        minWidth: 100,
        renderer: asset => <Text>{formatDate(asset.dateAdded, "MMM do, yyyy")}</Text>,
        sortKey: "recent",
      },
    ];
    return cols;
  }, [space]);

  return (
    <Flex height="100%" width="100%" data-testid="asset-library" overflow="hidden">
      <SubNavContainer>
        <AssetLibraryNav navPoint={navPoint} isPicker={true} />
      </SubNavContainer>
      <Flex
        flexDirection="column"
        width="100%"
        height="100%"
        maxWidth={"calc(100% - 240px)"}
        variations={{ "mq.s": { maxWidth: "100%" } }}>
        <AssetLibraryHeader>
          <Button
            disabled={processing}
            size="small"
            buttonStyle="secondary"
            text="Cancel"
            onClick={dismissModal}
          />
          <Button
            size="small"
            text={selectionCount ? pluralize(`Add ${selectionCount} asset`, selectionCount) : "Add"}
            disabled={processing || selectionCount === 0}
            onClick={confirmSelection}
          />
        </AssetLibraryHeader>
        <ScrollContainer>
          <Flex
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            top="0"
            px="xl"
            py="m"
            data-inspector-clear="true">
            <AssetLibrarySelectAll data={data} />
          </Flex>
          <DataTable<Asset>
            columns={tableColumns}
            data={data?.assets || []}
            isLoading={isLoading}
            hasMorePages={hasNextPage}
            fetchNextPage={fetchNextPage}
            tableError={error}
            selectionProps={selectionProps}
            emptyState={renderEmptyState(appliedFilters)}
            sort={sort}
            isLibraryView={true}
            onSort={(sortKey: string) => setSort(sortKey as SearchSortTypes)}
            onRowClick={(asset: Asset, e: MouseEvent) => selectionProps.selectAsset(asset, e)}
          />
        </ScrollContainer>
      </Flex>
    </Flex>
  );
};

export default AssetLibraryPickerModal;
