/**
 * Invite people to space with Limited Member role.
 */

import React, { Fragment } from "react";
import {
  Box,
  Text,
  Notice,
  Textarea,
  Space,
  Kit,
  Portal,
  TokenInputToken,
  ToggleBox,
} from "@thenounproject/lingo-core";

import EmailInput from "../../forms/EmailInput";
import KitSelectList from "../../kits/KitSelectList";
import ModalBody from "../../ModalBody";
import ModalFooter from "../../ModalFooter";
import SpaceFullMessage from "./SpaceFullMessage";
import PortalSelectList from "../../portals/PortalSelectList";

type Props = {
  space: Space;
  emails: TokenInputToken[];
  addEmails: (email: TokenInputToken[]) => void;
  removeEmail: (index: number) => void;
  note: string;
  onNoteChanged: (note: string) => void;
  error: string;
  processing: boolean;
  sendInvitations: () => void;
  tooManyInvitations: boolean;
  kits: Kit[];
  portals: Portal[];
  selectedKitRoles: Record<string, string[]>;
  selectKitRoles: (kitIds: string[], role: string) => void;
  setDefaultKitRole: (kitIds: string[], role: string) => void;
  selectedPortals?: string[];
  selectPortal?: (id: string | string[]) => void;
  selectPortalKitRoles?: (kitIds: string[], role: string) => void;
  billingNotice: string;
};

const InviteLimitedMembers = ({
  space,
  kits,
  portals,
  selectedKitRoles,
  selectKitRoles,
  setDefaultKitRole,
  emails,
  addEmails,
  removeEmail,
  note,
  onNoteChanged,
  error,
  processing,
  sendInvitations,
  tooManyInvitations,
  selectedPortals,
  selectPortal,
  selectPortalKitRoles,
  billingNotice,
}: Props) => {
  const hasPortalsFeature = space.features.includes("portals");
  const hasInvalidEmails = emails.some(i => i.isValid === false);
  const sendButtonDisabled =
    emails.length === 0 ||
    (hasPortalsFeature
      ? Object.keys(selectedPortals).length === 0
      : Object.keys(selectedKitRoles).length === 0) ||
    processing;
  const hasEditPermissions = Object.values(selectedKitRoles).some((r: string[]) =>
    r.includes("collaborator")
  );
  function renderLimitWarning() {
    // Show a warning if we input more invitations than we have room for
    // If the space is completely full, a notice is shown in InviteSpaceMembers
    // and we won't be able to get to this view.

    return tooManyInvitations ? (
      <SpaceFullMessage spaceId={space.id} permissions={space.access.permissions} />
    ) : null;
  }

  function renderSelectList() {
    if (hasPortalsFeature) {
      return (
        <PortalSelectList
          portals={portals}
          selectedPortals={selectedPortals}
          selectPortal={selectPortal}
          selectedKitRoles={selectedKitRoles}
          selectKitRoles={selectPortalKitRoles}
          showEnableAllButton={true}
        />
      );
    } else {
      return (
        <ToggleBox
          title="All Kits"
          description="Members have view access to all kits"
          estimatedHeight="initial"
          isOpen
          control="none">
          <KitSelectList
            kits={kits}
            selectedKitRoles={selectedKitRoles}
            revokable={true}
            selectRole={selectKitRoles}
            defaultRole="guest"
            setDefaultKitRole={setDefaultKitRole}
          />
        </ToggleBox>
      );
    }
  }
  return (
    <Fragment>
      <ModalBody>
        <Box>
          {hasEditPermissions && billingNotice && (
            <Notice message={billingNotice} noticeStyle="warning" mb="m" />
          )}
          <Text font="ui.regularBold">Invite Members</Text>
          <Text>
            Members can only access or edit Kits they are allowed to by an Admin or Owner.
          </Text>
        </Box>

        {renderLimitWarning()}

        {!hasPortalsFeature && kits.length === 0 ? (
          <Notice message="This space has no Kits. Add at least one Kit to invite Limited Members." />
        ) : null}
        {hasPortalsFeature && portals.length === 0 ? (
          <Notice message="This space has no portals. Add at least one portal to invite Members." />
        ) : null}

        {/* Email input */}
        <Text as="label" mt="xl" mb="xs">
          Emails
        </Text>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <EmailInput
            onAddToken={addEmails}
            onRemoveToken={removeEmail}
            items={emails}
            inputProps={{ style: { maxWidth: "580px" } }}
          />
        </label>

        {/* Email validation error */}
        {hasInvalidEmails ? (
          <Text ml="xs" mt="xs" font="ui.small" color="error">
            Items in red will not be submitted.
          </Text>
        ) : null}
        {/* Note */}
        <Textarea
          mt="l"
          value={note}
          label="Note (optional)"
          placeholder="Add a personal note"
          onChange={e => onNoteChanged(e.target.value)}
        />
        <Box mt="l">{renderSelectList()}</Box>

        {error ? <Notice noticeStyle="error" message={error} /> : null}
      </ModalBody>
      <ModalFooter
        primary={{
          text: processing ? "Sending..." : "Send",
          disabled: sendButtonDisabled,
          onClick: sendInvitations,
        }}
      />
    </Fragment>
  );
};

export default InviteLimitedMembers;
