import React from "react";
import { PopupMenu, SearchFilterInput } from "@thenounproject/lingo-core";

import { SearchQueryFilterOption } from "../types";
import { getDaysAgoRange, getTodayDateRange, getYesterdayDateRange } from "@helpers/dates";

type Props = {
  filter: SearchQueryFilterOption;
  onSelectFilter: (filter: SearchFilterInput) => void;
  onSelectDateInputType: (type: "exact" | "range") => void;
  onSelectFilterOption?: (filter: SearchQueryFilterOption) => void;
};
const AssetLibraryDateFilterOptions: React.FC<Props> = ({
  filter,
  onSelectFilter,
  onSelectFilterOption,
  onSelectDateInputType,
}) => {
  return (
    <>
      <PopupMenu.Item
        title="Today"
        onClick={() => {
          onSelectFilter({
            type: filter.type,
            field_id: filter.fieldId,
            id: `${filter.type}-${filter.fieldId}-today`,
            display: "Today",
            operator_values: getTodayDateRange(),
          });
        }}
      />
      <PopupMenu.Item
        title="Yesterday"
        onClick={() => {
          onSelectFilter({
            type: filter.type,
            field_id: filter.fieldId,
            id: `${filter.type}-${filter.fieldId}-yesterday`,
            display: "Yesterday",
            operator_values: getYesterdayDateRange(),
          });
        }}
      />
      <PopupMenu.Item
        title="Last 7 Days"
        onClick={() => {
          onSelectFilter({
            type: filter.type,
            field_id: filter.fieldId,
            id: `${filter.type}-${filter.fieldId}-last-7-days`,
            display: "Last 7 Days",
            operator_values: getDaysAgoRange(7),
          });
        }}
      />
      <PopupMenu.Item
        title="Last 30 Days"
        onClick={() => {
          onSelectFilter({
            type: filter.type,
            field_id: filter.fieldId,
            id: `${filter.type}-${filter.fieldId}-last-30-days`,
            display: "Last 30 Days",
            operator_values: getDaysAgoRange(30),
          });
        }}
      />
      <PopupMenu.Item
        title="Custom Date"
        onClick={() => {
          onSelectFilterOption?.(filter);
          onSelectDateInputType("range");
        }}
      />
    </>
  );
};

export default AssetLibraryDateFilterOptions;
