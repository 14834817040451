// MARK : Fething helpers

import { API, base64, LingoError } from "@thenounproject/lingo-core";
import { CamelCase, SnakeCase } from "type-fest";

// -------------------------------------------------------------------------------
type AnalyticsReport<T = unknown> = {
  result: T;
  error: LingoError;
};

type ReportDef = {
  id: string;
  [x: string]: unknown;
};

type ReportResults<ReportDefinitions extends Record<string, unknown>> = {
  [K in CamelCase<keyof ReportDefinitions>]: AnalyticsReport<
    ReportDefinitions[keyof ReportDefinitions]
  >;
};

export async function fetchAnalytics<ReportTypes extends Record<string, unknown>>(
  spaceId: string | number,
  reports: Record<SnakeCase<keyof ReportTypes>, ReportDef>
): Promise<ReportResults<ReportTypes>> {
  const { result } = await API.call<ReportResults<ReportTypes>>({
    endpoint: `analytics/space/${spaceId}?reports=${base64.encode(reports)}`,
    method: "GET",
    entity: API.Entity.analytics,
  });

  return result;
}
