import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useRegenerateJoinLink, regenerateJoinLink] = createAsyncAction(
  "spaces/regenerateJoinLink",
  async (spaceId: number) => {
    return await API.call<number>({
      endpoint: `spaces/${spaceId}/join-link`,
      method: "PUT",
      entity: API.Entity.space,
    });
  }
);

export default useRegenerateJoinLink;
export { regenerateJoinLink };
