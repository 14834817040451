import React from "react";

import { type Portal, Text, Flex, Button, Box, SpacePermission } from "@thenounproject/lingo-core";
import useModals, { ModalTypes } from "@redux/actions/useModals";
import PortalMemberList from "./PortalMemberList";
import PortalMemberInput from "./PortalMemberInput";
import useSpace from "@redux/actions/spaces/useSpace";

type Props = {
  portal: Portal;
};

const PortalTeamAccess: React.FC<Props> = ({ portal }) => {
  const { showModal } = useModals();

  const { data: space } = useSpace({ spaceId: portal.spaceId });
  const canInviteNewUser = space?.access?.permissions?.includes(SpacePermission.inviteUser);

  return (
    <Box
      mt="l"
      as="section"
      position="relative"
      background="grayLightest"
      border="default"
      p="m"
      borderRadius="default">
      <Flex justifyContent="space-between" alignItems="center" mb="s" width="100%">
        <Text font="ui.regularBold">Team access</Text>
        {canInviteNewUser ? (
          <Button
            buttonStyle="tertiary"
            size="small"
            onClick={e => {
              e.preventDefault();
              showModal(ModalTypes.INVITE_SPACE_MEMBERS);
            }}
            text="Invite a new user"
          />
        ) : null}
      </Flex>
      <PortalMemberInput portal={portal} />
      <PortalMemberList portal={portal} />
    </Box>
  );
};

export default PortalTeamAccess;
