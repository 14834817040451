import { createAsyncAction } from "@actions/actionCreators";
import { API, ErrorCode } from "@thenounproject/lingo-core";

type Args = {
  id: string;
  name?: string;
  status?: string;
  display_order?: string;
};

const [useUpdateKitCollection] = createAsyncAction(
  "kitCollections/update",
  async ({ id, ...data }: Args) => {
    if (typeof data.name === "string" && !data.name.trim()) {
      throw { code: ErrorCode.invalidParams, message: "Name is required" };
    }
    return await API.call<string>({
      endpoint: `kit_collections/${id}`,
      method: "PUT",
      entity: API.Entity.kitCollection,
      data,
    });
  }
);
export default useUpdateKitCollection;
