import { API, base64, KitMember, SpaceMember, PortalMember } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: number;
  userIds: number[];
};
type NormalizedResult = {
  total: number;
  members: string[];
};

export type PopulatedSpaceMember = Omit<SpaceMember, "kitAccess" | "portalAccess"> & {
  kitAccess: KitMember[];
  portalAccess: PortalMember[];
};

const [useSpaceMembersById, fetchSpaceMembersById] = createQueryAction<
  Args,
  { total: number; members: PopulatedSpaceMember[] },
  NormalizedResult
>(
  {
    entity: "spaceMembers",
    action: "queryById",
    denormalize: {
      entity: null,
      members: {
        entity: "spaceMembers",
        user: "users",
        kitAccess: "kitMembers",
        portalAccess: "portalMembers",
      },
    },
  },
  async ({ args }) => {
    const query = base64.encode({
      user_ids: args.userIds,
      include: ["kits", "portals"],
    });
    const res = await API.call<NormalizedResult>({
      endpoint: `spaces/${args.spaceId}/members`,
      method: "GET",
      entity: API.Entity.spaceMember,
      query: { query },
    });
    const { members, total } = res.result;
    return { result: { members, total }, entities: res.entities };
  }
);

export default useSpaceMembersById;
export { fetchSpaceMembersById };
