import React, { useRef, useEffect, Fragment, useCallback, KeyboardEvent } from "react";
import styled from "styled-components";
import _isArray from "lodash/isArray";

import {
  Box,
  Flex,
  Icon,
  ImageView,
  Text,
  utils,
  SearchFilterInput,
  Item,
  AnyObject,
} from "@thenounproject/lingo-core";

import suggestionTypes from "../../constants/suggestionTypes";
import SearchBreadcrumb from "./SearchBreadcrumb";
import { useSelectSpaceFields } from "@selectors/entities/fields";

const Wrapper = styled(Flex).attrs({
  tabIndex: 1,
  px: "m",
  py: "s",
  alignItems: "center",
  width: "100%",
  variations: {
    "mq.s": {
      py: "m",
    },
  },
})`
  cursor: pointer;
  &:focus,
  &:hover {
    outline: none;
    background: ${utils.getColor("grayLighter")};
  }
`;

const IconWrapper = styled(Flex).attrs({
  height: "36px",
  width: "36px",
  flexShrink: 0,
  background: "grayLight",
  borderRadius: "default",
  mr: "s",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
})``;

const FilterPillWrapper = styled(Box).attrs(props => {
  return {
    flexShrink: 0,
    py: "xxs",
    px: "s",
    borderRadius: utils.getBorderRadius(props?.theme?.themeName, "default"),
    background: "grayLight",
  };
})`
  white-space: nowrap;
  ${props => utils.getFont("ui.small", null, props)}
`;

const StackedTextWrapper = styled(Flex).attrs({
  flex: 1,
  overflow: "hidden",
  flexDirection: "column",
})``;

type Suggestion = {
  id?: string;
  name?: string;
  display?: string;
  style?: string;
  type?: string;
  image?: string;
  object_type?: string;
  field_id?: number;
  kit?: SearchFilterInput["kit"];
  section?: SearchFilterInput["section"];
  item?: SearchFilterInput["item"];
  data?: AnyObject;
};

type Props<T> = {
  shouldFocus: boolean;
  type: string;
  data: T;
  // depending on type, data can be on of
  // SearchFilterInput
  // SearchFilterInput[]
  // { data: SearchFilterInput }
  // TODO: structure this so it can be typed better
  onSelectSuggestion: (type: string, data: T) => void;
};

export default function SearchModalSuggestion<T extends Suggestion | Suggestion[]>({
  shouldFocus,
  type,
  data,
  onSelectSuggestion,
}: Props<T>) {
  const elementRef = useRef<HTMLDivElement>(null);
  const fields = useSelectSpaceFields();

  useEffect(() => {
    if (!elementRef.current) return;
    if (shouldFocus) elementRef.current.focus();
    else elementRef.current.blur();
  }, [shouldFocus]);

  const iconMap = {
    [suggestionTypes.filter]: "navigation.search",
    [suggestionTypes.keyword]: "navigation.search",
    [suggestionTypes.default]: "navigation.search",
    [suggestionTypes.recent]: "info.history",
  };

  const isJumpTo = type === suggestionTypes.jump;

  const _onSelectSuggestion = useCallback(() => {
    onSelectSuggestion(type, data);
  }, [data, onSelectSuggestion, type]);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") _onSelectSuggestion();
    },
    [_onSelectSuggestion]
  );

  function renderSuggestionText(_data: Suggestion | Suggestion[]) {
    if (Array.isArray(_data)) return _data.map(sugg => renderSuggestionText(sugg));

    if (_data.style === "text") {
      return (
        <Text flex="1" key={_data.display} mr="s">
          {_data.display}
        </Text>
      );
    } else {
      const type =
        fields.find(field => field.id === _data.field_id)?.name?.toLowerCase() || _data.type;
      return (
        <FilterPillWrapper key={_data.id} mr="s">
          {type}:{_data.display}
        </FilterPillWrapper>
      );
    }
  }

  function renderJumpIcon() {
    if (Array.isArray(data)) return null;
    switch (data.object_type) {
      case "kit":
        return (
          <ImageView
            icon="content.kit"
            iconFill="black"
            src={data.image}
            height="36px"
            width="36px"
            cover
          />
        );
      case "section":
        return <Icon iconId="content.section" />;
      case "gallery":
        return <Icon iconId="content.grid" />;
      case "item":
        return <Icon iconId="content.heading" />;
      default:
        return null;
    }
  }

  function renderJumpTo() {
    if (Array.isArray(data)) return null;
    const { kit, section, item } = data ?? {};
    return (
      <>
        <IconWrapper>{renderJumpIcon()}</IconWrapper>
        <StackedTextWrapper>
          <Text truncate font="ui.regularBold">
            {data.name}
          </Text>
          <SearchBreadcrumb simple kit={kit} section={section} item={item as unknown as Item} />
        </StackedTextWrapper>
      </>
    );
  }

  return (
    <Wrapper ref={elementRef} onClick={_onSelectSuggestion} onKeyUp={handleKeyUp}>
      {isJumpTo ? (
        renderJumpTo()
      ) : (
        <Fragment>
          <Icon iconId={iconMap[type]} size="24" fill="black" mr="s" />
          {renderSuggestionText(data)}
        </Fragment>
      )}
    </Wrapper>
  );
}
