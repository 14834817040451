import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Button, Input, Flex, Checkbox, Text, utils } from "@thenounproject/lingo-core";

const AddPasswordInputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  ${utils.positioningCSS({ mr: "s" })};
`;

const AddPasswordInput = styled(Input).attrs({
  type: "password",
  styleOverrides: {
    mb: "none",
  },
})``;

type Props = {
  hasPassword: boolean;
  setPassword: (password: string) => void;
  removePassword: () => void;
  success: boolean;
  error: string;
  disabled?: boolean;
};
const PasswordCheckbox: React.FC<Props> = ({
  hasPassword,
  setPassword,
  removePassword,
  success,
  error,
  disabled,
}) => {
  const [passwordText, setPasswordText] = useState(""),
    [passwordInputVisible, setPasswordInputVisible] = useState(false);

  useEffect(() => {
    if (success) setPasswordInputVisible(false);
  }, [success]);

  const clickCheckbox = () => {
      if (passwordInputVisible) setPasswordInputVisible(false);
      else if (hasPassword) removePassword();
      else setPasswordInputVisible(true);
    },
    clickReset = () => {
      setPasswordInputVisible(true);
    },
    clickCancel = () => {
      setPasswordInputVisible(false);
    },
    submitPassword = () => {
      if (!passwordText) return;
      setPassword(passwordText);
    };

  return (
    <Flex flexDirection="column" width="100%">
      <Flex alignItems="center">
        <Flex>
          <Checkbox
            id="add_password"
            onClick={clickCheckbox}
            label={
              <Text font="ui.small" color="grayDarkest">
                Require a password
              </Text>
            }
            isSelected={passwordInputVisible || hasPassword}
            disabled={disabled}
          />
        </Flex>
        {hasPassword && (
          <Fragment>
            <Text font="ui.small" color="grayDarkest" mx="xs">
              &bull;
            </Text>
            {passwordInputVisible ? (
              <Button
                id="cancel-password"
                text="Cancel"
                buttonStyle="tertiary"
                size="small"
                onClick={clickCancel}
              />
            ) : (
              <Button
                id="reset-password"
                text="Reset"
                buttonStyle="tertiary"
                size="small"
                onClick={clickReset}
              />
            )}
          </Fragment>
        )}
      </Flex>
      {passwordInputVisible && (
        <Flex justifyContent="space-between">
          <AddPasswordInputWrapper>
            <AddPasswordInput
              id="add-password-field"
              value={passwordText}
              onSubmit={submitPassword}
              onChange={e => setPasswordText(e.target.value)}
              message={error}
              inputStyle={error ? "error" : null}
              placeholder="Enter a password">
              <Button ml="s" id="submit-password-button" text="Save" onClick={submitPassword} />
            </AddPasswordInput>
          </AddPasswordInputWrapper>
        </Flex>
      )}
    </Flex>
  );
};

export default PasswordCheckbox;
