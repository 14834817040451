import _merge from "lodash/merge";
import _unionBy from "lodash/unionBy";
import _mergeWith from "lodash/mergeWith";
import _remove from "lodash/remove";

import { APIResponse, SpaceMember } from "@thenounproject/lingo-core";
import createEntityReducer from "../helpers/createEntityReducer";

import { updateSpaceMember } from "@redux/actions/spaceMembers/useUpdateSpaceMember";
import { fetchSpaceMembers } from "@redux/actions/spaceMembers/useSpaceMembers";
import { batchUpdateSpaceMembers } from "@redux/actions/spaceMembers/useBatchUpdateSpaceMembers";
import { deleteSpaceMembers } from "@redux/actions/spaceMembers/useDeleteSpaceMembers";
import { batchUpdateKitMembers } from "@redux/actions/kitMembers/useBatchUpdateKitMembers";
import { addKitMembers } from "@redux/actions/kitMembers/useAddKitMembers";
import { removeKitMember } from "@redux/actions/kitMembers/useRemoveKitMember";
import { transferSpace } from "@redux/actions/spaces/useTransferSpace";
import { addPortalMembers } from "@redux/actions/portalMembers/useAddPortalMembers";
import { removePortalMember } from "@redux/actions/portalMembers/useRemovePortalMember";
import { removePortalMembers } from "@redux/actions/portalMembers/useRemovePortalMembers";
import { updateEditorStatus } from "@redux/actions/spaceMembers/useUpdateEditorStatus";

const getSpaceMembersFromAction = (action): APIResponse["entities"]["spaceMembers"] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return action?.response?.entities?.spaceMembers ?? action?.payload?.entities?.spaceMembers;
};

export default createEntityReducer<SpaceMember>(
  "spaceMembers",
  queryBuilder => {
    queryBuilder
      .addCase(updateSpaceMember.fulfilled, (state, action) => {
        const { result, entities, previousRole } = action.payload;
        const updatedMember = entities.spaceMembers[result];

        fetchSpaceMembers
          .getQueryData(state, { spaceId: updatedMember.spaceId, role: previousRole })
          .forEach(q => {
            const removed = _remove(q.data.members, m => m === result);
            q.data.total -= removed.length;
          });
        fetchSpaceMembers
          .getQueryData(state, { spaceId: updatedMember.spaceId, role: updatedMember.role })
          .forEach(q => {
            q.data.members.push(result);
            q.data.total += 1;
          });
      })
      .addCase(batchUpdateSpaceMembers.fulfilled, (state, action) => {
        const { role: newRole, spaceId } = action.meta.arg;
        const { result, previousRoles } = action.payload;
        const updatedMemberIds = result.members.filter(r => r.success).map(r => r.result);

        fetchSpaceMembers.getQueryData(state, { spaceId, role: newRole }).forEach(q => {
          updatedMemberIds.forEach(id => {
            if (!q.data.members.includes(id)) {
              q.data.members.push(id);
              q.data.total += 1;
            }
          });
        });

        Object.entries(previousRoles).forEach(([role, memberIds]) => {
          if (role === newRole) return;
          const successIds = memberIds.filter(id => updatedMemberIds.includes(id));
          fetchSpaceMembers.getQueryData(state, { spaceId, role }).forEach(q => {
            const removed = _remove(q.data.members, id => successIds.includes(id));
            q.data.total -= removed.length;
          });
        });
      })
      .addCase(deleteSpaceMembers.fulfilled, (state, action) => {
        const deletedIds = new Set(
          action.payload.result.members.filter(r => r.success).map(r => r.result)
        );

        const args = { spaceId: action.meta.arg.spaceId };
        fetchSpaceMembers.getQueryData(state, args).forEach(q => {
          const removed = _remove(q.data.members, id => deletedIds.has(id));
          q.data.total -= removed.length;
        });
      });
  },
  // MARK : Object Reducer
  // -------------------------------------------------------------------------------
  objectBuilder => {
    objectBuilder
      .addCase(transferSpace.fulfilled, (state, action) => {
        const spaceId = action.payload.result;
        Object.keys(state).forEach(key => {
          const member = state[key];
          if (member.spaceId === spaceId) {
            if (member.userId === action.meta.arg.newOwnerId) member.role = "owner";
            else if (member.role === "owner") member.role = "admin";
          }
        });
      })
      .addCase(updateSpaceMember.fulfilled, (state, action) => {
        return _mergeWith(state, getSpaceMembersFromAction(action), (objValue, srcValue, key) => {
          if (key === "kitAccess") {
            return srcValue;
          }
        });
      })
      .addCase(addPortalMembers.fulfilled, (state, action) => {
        const { entities, result } = action.payload;
        const updatedMembers = result.members
          .filter(r => r.success)
          .map(r => entities.portalMembers[r.result]);
        updatedMembers.forEach(updated => {
          const member = state[`${updated.spaceId}-${updated.userId}`];
          if (!member?.portalAccess) return;
          const memberAccess = member.portalAccess as string[];
          memberAccess.push(`${updated.portalId}-${updated.userId}`);
        });
      })
      .addCase(removePortalMember.fulfilled, (state, action) => {
        const { portalId, userId } = action.meta.arg;
        const member = state[`${portalId}-${userId}`];
        if (member) {
          _remove(member.portalAccess as string[], p => p === `${portalId}-${userId}`);
        }
      })
      .addCase(removePortalMembers.fulfilled, (state, action) => {
        const { entities, result } = action.payload;
        const updatedMembers = result.members
          .filter(r => r.success)
          .map(r => entities.portalMembers[r.result]);

        updatedMembers.forEach(updated => {
          const member = state[`${updated.spaceId}-${updated.userId}`];
          if (!member) return;
          _remove(
            member.portalAccess as string[],
            p => p === `${updated.portalId}-${updated.userId}`
          );
        });
      })
      .addCase(updateEditorStatus, (state, action) => {
        const { memberId, spaceId, editorStatus } = action.payload;
        const updatedMember = state[`${spaceId}-${memberId}`];
        if (updatedMember) {
          updatedMember.isEditor = editorStatus;
        }
      })
      .addCase(batchUpdateKitMembers.fulfilled, (state, action) => {
        const { entities, result } = action.payload;
        const updatedMembers = result.members
          .filter(r => r.success)
          .map(r => ({ key: r.result, kitMember: entities.kitMembers[r.result] }));

        updatedMembers.forEach(({ kitMember, key }) => {
          const member = state[`${kitMember.spaceId}-${kitMember.userId}`];
          if (!member) return;
          const kitAccess = member.kitAccess as string[];
          if (kitMember.status === "revoked") {
            const removed = _remove(kitAccess as string[], k => k === key);
            member.joinedKits -= removed.length;
          } else if (kitMember.status === "active" && !kitAccess.includes(key)) {
            kitAccess.push(key);
            member.joinedKits += 1;
          }
        });
      })
      .addCase(addKitMembers.fulfilled, (state, action) => {
        const { result, entities } = action.payload;
        result.members
          .filter(r => r.success)
          .forEach(r => {
            const kitMember = entities.kitMembers[r.result];
            const spaceMemberID = `${kitMember.spaceId}-${kitMember.userId}`;
            const member = state[spaceMemberID];
            if (member && member.kitAccess) {
              (member.kitAccess as string[]).push(r.result);
            }
            member.joinedKits += 1;
          });
      })
      .addCase(removeKitMember.fulfilled, (state, action) => {
        const { userId, spaceId } = action.meta.arg;
        const kitMemberId = action.payload.result;
        const spaceMemberID = `${spaceId}-${userId}`;

        const member = state[spaceMemberID];
        if (member) {
          _remove(member.kitAccess as string[], id => id === kitMemberId);
          member.joinedKits -= 1;
        } else {
          // Make sure the space member is in state so it can be
          // loaded in the unjoined members list in share kit modal.
          const kitMember = action.payload.entities.kitMembers[kitMemberId];
          state[spaceMemberID] = {
            id: 0,
            userId: kitMember.userId,
            spaceId: kitMember.spaceId,
            user: kitMember.user,
            role: "limited_member",
            status: "active",
            permissions: [],
            joinedKits: 0,
            kitAccess: [],
            portalAccess: [],
            dateAdded: new Date().toISOString(),
            dateUpdated: new Date().toISOString(),
            isEditor: member.isEditor,
          };
        }
      })
      .addDefaultCase((state, action) => {
        const spaceMembers = getSpaceMembersFromAction(action);
        if (spaceMembers) _merge(state, spaceMembers);
      });
  }
);
