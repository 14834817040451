import React, { useCallback } from "react";

import {
  KitPermission,
  PrivacyLevel,
  Tooltip,
  Text,
  Kit,
  Button,
  Flex,
  Checkbox,
} from "@thenounproject/lingo-core";

import useKitUpsell, { AccessStatus } from "@hooks/useKitUpsell";
import UpsellTooltip from "../../../components/spaces/settings/UpsellTooltip";
import { useSelectSpace } from "@redux/selectors/entities/spaces";
import useUpdateKitPrivacy from "@redux/actions/kits/useUpdateKitPrivacy";

type Props = {
  kit: Kit;
};

const KitSeoCheckbox: React.FC<Props> = ({ kit }) => {
  const seoAccess = useKitUpsell(KitPermission.manageSEO, kit);
  const space = useSelectSpace();
  const [editKitPrivacy] = useUpdateKitPrivacy();
  const toggleIndexable = useCallback(
    () =>
      editKitPrivacy({
        kitId: kit.kitId,
        spaceId: kit.spaceId,
        privacy: space.privacy as PrivacyLevel,
        indexable: !kit.indexable,
      }),
    [editKitPrivacy, kit.indexable, kit.kitId, kit.spaceId, space.privacy]
  );

  if (seoAccess.accessStatus === AccessStatus.insufficientRole) return null;

  const hasCustomDomain = Boolean(space.subdomain || space.domain);
  const hasAccess = seoAccess.accessStatus === AccessStatus.canAccess;
  const isSelected = kit.indexable && hasAccess && hasCustomDomain;
  function renderUpsell() {
    return !hasAccess ? (
      <UpsellTooltip
        source="indexable-tooltip"
        onClick={seoAccess.openUpgradeModal}
        featureName="SEO"
      />
    ) : null;
  }

  function renderDomainTooltip() {
    if (!hasAccess) return null;
    return hasCustomDomain ? null : (
      <Tooltip source="indexable-tooltip" direction={Tooltip.Direction.Top}>
        <Text color="white" font="ui.small">
          Set up a custom domain to enable SEO.{" "}
          <Button
            buttonStyle="tertiary"
            tintColor="white"
            text="Learn more."
            fontStyle="ui.smallBold"
            size="small"
            link={"http://help.lingoapp.com/en/articles/7970185-seo"}
            newWindow
          />
        </Text>
      </Tooltip>
    );
  }
  return (
    <>
      {renderUpsell() ?? renderDomainTooltip()}
      <Flex alignItems="center">
        <Flex data-tooltip-trigger="indexable-tooltip">
          <Checkbox
            isSelected={isSelected}
            disabled={!(hasAccess && hasCustomDomain)}
            label={
              <Flex>
                <Text data-tooltip-source="indexable-tooltip" font="ui.small" color="grayDarkest">
                  Allow search engines to index your kit
                </Text>
              </Flex>
            }
            onClick={toggleIndexable}
          />
        </Flex>
        <Tooltip.InfoTip fill="grayDarkest">
          <Text color="white" font="ui.small">
            When enabled, search engines (Google, Bing, etc.) can discover your kit and display it
            in search results.{" "}
            <Button
              buttonStyle="tertiary"
              tintColor="white"
              text="Learn more."
              fontStyle="ui.smallBold"
              size="small"
              link={"http://help.lingoapp.com/en/articles/7970185-seo"}
              newWindow
            />
          </Text>
        </Tooltip.InfoTip>
      </Flex>
    </>
  );
};

export default KitSeoCheckbox;
