import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

/**
 * Retry asset processing for a file
 */

type Args = {
  assetId: string;
};

export type MotionAssetPreview = {
  url: string;
  filename: string;
  // The time position of the thumbnail in the video
  position: string;
};

const [useFetchMotionAssetThumbnails, fetchMotionAssetThumbnails] = createAsyncAction(
  "assets/fetchMotionAssetThumbnails",
  async ({ assetId }: Args) => {
    const response = await API.call<{ previews: MotionAssetPreview[] }>({
      endpoint: `assets/${assetId}/previews`,
      method: "GET",
    });
    return response;
  }
);

export { fetchMotionAssetThumbnails };
export default useFetchMotionAssetThumbnails;
