import React from "react";
import styled from "styled-components";
import { Button, Flex, Text } from "@thenounproject/lingo-core";

const Wrapper = styled(Flex).attrs({
  px: "m",
  py: "xs",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  w: "100%",
})``;

interface Props {
  label: string;
  actionText?: string;
  onClick?: () => void;
}

export default function SearchModalSuggestionHeader({ label, actionText, onClick }: Props) {
  return (
    <Wrapper>
      <Text flex="1" font="ui.smallBold" color="grayDarkest">
        {label}
      </Text>
      {actionText && (
        <Button buttonStyle="tertiary" size="small" onClick={onClick} text={actionText} />
      )}
    </Wrapper>
  );
}
