import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useDeleteJoinLink, deleteJoinLink] = createAsyncAction(
  "spaces/deleteJoinLink",
  async (spaceId: number) => {
    return await API.call<number>({
      endpoint: `spaces/${spaceId}/join-link`,
      method: "DELETE",
      entity: API.Entity.space,
    });
  }
);

export default useDeleteJoinLink;
export { deleteJoinLink };
