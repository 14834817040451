import { createAsyncAction } from "@redux/actions/actionCreators";
import { useEffect, useState } from "react";
import { useAppDispatchV1 } from "@redux/hooks";
import { API, LingoError, camelize } from "@thenounproject/lingo-core";

type Status = "loading" | "success" | "error";

type RecoveryCode = {
  code: string;
  dateUsed: string;
};

type Response = {
  recovery_codes: {
    code: string;
    date_used: string;
  }[];
};

const [useRecoveryCodes] = createAsyncAction("auth/fetchRecoveryCodes", async () => {
  const response = await API.call<Response>({
    endpoint: "me/mfa/recovery/",
    method: "GET",
  });
  return response;
});

export default function useFetchRecoveryCodes() {
  const dispatch = useAppDispatchV1();
  const [status, setStatus] = useState<Status>("loading");
  const [error, setError] = useState<LingoError>(null);
  const [codes, setCodes] = useState<RecoveryCode[]>(null);
  const [recoveryCodes] = useRecoveryCodes();

  useEffect(() => {
    const result = recoveryCodes();
    result
      .then(result => {
        if (result.error) {
          setStatus("error");
          setError(result.error);
        } else {
          setStatus("success");
          const res = result.response.result.recovery_codes.map(codeObj =>
            camelize(codeObj)
          ) as RecoveryCode[];
          setCodes(res);
        }
      })
      .catch(() => {
        // nothing to do
      });
  }, [dispatch, recoveryCodes]);
  return { status, codes, error };
}
