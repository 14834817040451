/* eslint-disable jsx-a11y/aria-role */
import React from "react";
import { Flex, Box, Text, Icon, ImageView } from "@thenounproject/lingo-core";
import TextLink from "./TextLink";

type Props = {
  image: { src: string; alt: string };
  title: string;
  body: string;
  links?: { text: string; link: string }[];
  icon?: string;
  badge?: string;
};

const Card: React.FC<Props> = ({
  image,
  // placeholderSrc,
  title,
  body,
  links,
  icon,
  badge,
  ...rest
}) => {
  const contentPadding = image ? "m" : "l";
  return (
    <Flex flexDirection="column" pb="s" mb="l" width="100%" {...rest}>
      {image && (
        <ImageView
          alt={title}
          src={image.src}
          // placeholderSrc={image.placeholder ?? image.src}
          aspectRatio={56}
          borderRadius="4px 4px 0 0"
        />
      )}
      <Flex p={contentPadding} flexDirection="column" justifyContent="space-between">
        {icon && <Icon mb="s" iconId={icon} size={50} fill="grayDarker" />}
        <Box>
          <Flex alignItems="center">
            <Text as="h4" font="marketing.subtitle1" mb="s" mr="s">
              {title}{" "}
            </Text>
            {badge && (
              <Box background="info" px="s" py="xxs" borderRadius="default">
                <Text color="white" font="ui.small">
                  {badge}
                </Text>
              </Box>
            )}
          </Flex>
          <Text font="marketing.regular">{body}</Text>
        </Box>
        <Flex mt="m" flexWrap="wrap">
          {(links || []).map(link => (
            <TextLink
              key={link.text || "Learn more"}
              text={link.text || "Learn more"}
              link={link.link}
              mr="s"
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Card;
