import React, { useCallback, Fragment } from "react";
import { Text, Flex, Icon, Space, Box, buildURL, useNavigation } from "@thenounproject/lingo-core";
// import { useNavigate } from "react-router-dom";

import styleGuideImage from "../../../images/modals/TemplateInfoModal-BrandStyleGuide.png";
import uiLibraryImage from "../../../images/modals/TemplateInfoModal-UILibrary.png";
import assetLibraryImage from "../../../images/modals/TemplateInfoModal-AssetLibrary.png";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import LazyImage from "../../../marketing/components/LazyImage";
import getImageDimensionPadding from "@helpers/getImageDimensionPadding";
import useNotifications from "@actions/useNotifications";
import useCreateKit, { TemplateName } from "@actions/kits/useCreateKit";
import useShowModal from "@redux/actions/useModals";

const templateCopyCatalog = {
  styleGuide: {
    image: styleGuideImage,
    title: "Brand Style Guide",
    subtitle: "Create a living style guide and achieve brand consistency at scale",
    bulletPoints: [
      "Your logos, colors, fonts, photos and more in a single location.",
      "Your team can grab brand assets and see guidelines in the same spot.",
      "Any changes you make will always be available to everyone you share it with.",
    ],
    shortName: "Style Guide",
  },
  uiLibrary: {
    image: uiLibraryImage,
    title: "UI Component Library",
    subtitle: "A drag & drop hub for UI components to help you and your team design faster.",
    bulletPoints: [
      "Make it in Sketch, store it in Lingo. Symbols, Text Styles, Layers Styles, you name it.",
      "Provide documentation directly in-line with your components to ensure consistency.",
      "Lingo’s visually browsable interface makes finding your components a breeze.",
    ],
    shortName: "UI Library",
  },
  assetLibrary: {
    image: assetLibraryImage,
    title: "Digital Asset Library",
    subtitle: "Create digital asset libraries that empower your team to create on their own.",
    bulletPoints: [
      "Organize your product shots, editorial photos, and more in a single place.",
      "Tag your assets so they’re easily discoverable through search.",
      "Your team can find the assets they need in the format they need all on their own.",
    ],
    shortName: "Digital Asset Library",
  },
};

type Props = {
  templateName: TemplateName;
  space: Space;
};

function CreateTemplateKitModal({ templateName, space }: Props) {
  const { dismissModal } = useShowModal();
  const { showNotification } = useNotifications();
  const navigation = useNavigation(),
    [createKit, { isProcessing }] = useCreateKit();

  const submit = useCallback(() => {
    return createKit({ spaceId: space.id, name: null, templateName }).then(
      ({ error, response }) => {
        if (error) {
          showNotification({
            message: error.message || "Oops! That didn’t work.",
            level: "error",
          });
        } else {
          const kit = response.entities.kits[response.result];
          const kitId = kit.shortId;
          dismissModal();
          const kitUrl = buildURL(`/k/${kitId}`, { space });
          navigation.push(`${kitUrl}?template=${templateName}`);
        }
      }
    );
  }, [createKit, dismissModal, navigation, showNotification, space, templateName]);

  const templateCopy = templateCopyCatalog[templateName];
  return (
    <Fragment>
      <ModalHeader title={templateCopy.title} />
      <ModalBody style={{ display: "block" }}>
        <Box mb="l">
          <LazyImage
            src={templateCopy.image}
            alt={templateCopy.title}
            imagePadding={getImageDimensionPadding(1180, 490)}
          />
        </Box>
        <Text font="ui.regularBold" mb="m">
          {templateCopy.subtitle}
        </Text>
        {templateCopy.bulletPoints.map((bullet, i) => (
          <Flex key={i} my="s">
            <Icon iconId="checkmark" size="16" mt="xxs" mr="s" />
            <Text font="ui.regular">{bullet}</Text>
          </Flex>
        ))}
      </ModalBody>
      <ModalFooter
        primary={{
          text: isProcessing
            ? `Creating your ${templateCopy.shortName}`
            : `Create my ${templateCopy.shortName}`,
          onClick: submit,
          disabled: isProcessing,
        }}
      />
    </Fragment>
  );
}

export default CreateTemplateKitModal;
