import React, { useCallback, useState } from "react";
import styled from "styled-components";

import {
  Button,
  Flex,
  GalleryMarkdown,
  ItemType,
  utils,
  Text,
  useCodeHighlighting,
  Item,
  Box,
} from "@thenounproject/lingo-core";

const MAX_TEXT_CONTENT_HEIGHT = 66;
const MAX_CODE_CONTENT_HEIGHT = 73;

const CodeWrapper = styled(Flex).attrs({
  flexDirection: "column",
  border: "default",
  borderRadius: "default",
  background: "grayLightest",
})``;

const Pre = styled(Text).attrs({
  as: "pre",
  background: "transparent",
  p: "none",
})`
  overflow: hidden;
`;

const Code = styled(Text).attrs({
  as: "code",
  display: "block",
})`
  font-size: 12px;
  line-height: 18px;
`;

type ExpandingWrapperProps = {
  capped: boolean;
  isCodeSnippet: boolean;
};

const ExpandingWrapper = styled(Flex).attrs<ExpandingWrapperProps>(props => ({
  alignSelf: "stretch",
  maxHeight: props.capped
    ? props.isCodeSnippet
      ? `${MAX_CODE_CONTENT_HEIGHT}px`
      : `${MAX_TEXT_CONTENT_HEIGHT}px`
    : "1000px",
}))<ExpandingWrapperProps>`
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
`;

const TruncatedTextWrapper = styled(Box)<{ lines: number }>`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines || 2};
  -webkit-box-orient: vertical;
`;

const { getColor } = utils;

const MarkdownText = styled(GalleryMarkdown)`
  ${utils.positioningCSS({ mx: "none" })}
  * {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  blockquote {
    color: ${getColor("black")};
    padding-left: 16px;
  }
  a {
    color: ${getColor("black")};
    text-decoration: underline !important;
  }
  ul,
  ol {
    padding-left: 16px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  blockquote,
  ul,
  ol,
  pre {
    margin: 0;
  }
`;

type Props = {
  item: Item;
};

const SearchResultTextContent = ({ item }: Props) => {
  const isCodeSnippet = item?.type === ItemType.codeSnippet,
    isNote = item?.type === ItemType.note,
    isGuideline = item?.type === ItemType.guide,
    isHeading = item?.type === ItemType.heading,
    [needsCap, setNeedsCap] = useState(false),
    [capped, setCapped] = useState(false),
    toggleCapped = useCallback(
      e => {
        e.stopPropagation();
        setCapped(!capped);
      },
      [capped]
    );

  const checkNeedsCap = useCallback(
    node => {
      if (
        (!isCodeSnippet && node?.offsetHeight > MAX_TEXT_CONTENT_HEIGHT) ||
        (isCodeSnippet && node?.offsetHeight > MAX_CODE_CONTENT_HEIGHT)
      ) {
        setNeedsCap(true);
        setCapped(true);
      }
    },
    [isCodeSnippet]
  );

  const codeRef = useCodeHighlighting(item?.data?.content, item?.data?.codeLanguage);

  if (!item?.data?.content) return null;

  if (isCodeSnippet) {
    return (
      <CodeWrapper>
        <ExpandingWrapper
          capped={capped}
          isCodeSnippet={true}
          ref={checkNeedsCap}
          p="s"
          borderBottom="default">
          <Pre>
            <Code ref={codeRef}>{item.data.content}</Code>
          </Pre>
        </ExpandingWrapper>
        <Flex justifyContent={needsCap ? "space-between" : "flex-end"}>
          {needsCap && (
            <Button
              text={capped ? "Show more" : "Show less"}
              onClick={toggleCapped}
              buttonStyle="tertiary"
              size="small"
              alignSelf="flex-start"
              ml="s"
            />
          )}
          <Text font="ui.small" color="grayDarkest" mr="s" mb="xxs">
            {item.data.codeLanguage || "Auto"}
          </Text>
        </Flex>
      </CodeWrapper>
    );
  } else if (isNote || isGuideline) {
    return (
      <TruncatedTextWrapper lines={isNote ? 2 : 1}>
        <MarkdownText>{item.data.content}</MarkdownText>
      </TruncatedTextWrapper>
    );
  } else {
    return (
      <Flex flexDirection="column">
        <ExpandingWrapper capped={capped} isCodeSnippet={false} ref={checkNeedsCap}>
          {isHeading ? (
            <Text font="ui.regularBold">{item.data.content}</Text>
          ) : (
            <MarkdownText>{item.data.content}</MarkdownText>
          )}
        </ExpandingWrapper>
        {needsCap && (
          <Button
            text={capped ? "Show more" : "Show less"}
            onClick={toggleCapped}
            buttonStyle="tertiary"
            size="small"
            alignSelf="flex-start"
            position="relative"
            zIndex="5"
          />
        )}
      </Flex>
    );
  }
};

export default SearchResultTextContent;
