import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { PersonId } from "../types";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  dateOperators?: OperatorValues;
  personId: PersonId;
};

type OverviewData = {
  result: Array<{
    totalUses: number;
    date: string;
  }>;
};

const [useTotalDownloadsByUser, fetchTotalDownloadsByUser] = createQueryAction<Args, OverviewData>(
  {
    entity: "analytics",
    action: "queryTotalDownloadsByUser",
    denormalize: { entity: null },
  },
  async ({ args }) => {
    const reports = {
      asset_usage: {
        id: "intervals",
        entity: "asset_usage",
        person_id: args.personId,
        date_operators: args.dateOperators,
      },
    };

    return {
      result: (await fetchAnalytics<{ asset_usage: OverviewData }>(args.spaceId, reports))
        .assetUsage,
    };
  }
);

export default useTotalDownloadsByUser;
export { fetchTotalDownloadsByUser };
