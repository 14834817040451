import QueryString from "query-string";
import { API } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

export type Font = {
  family: string;
  variants: Array<{
    font_name: string;
    display_name: string;
  }>;
};

const [useGoogleFonts, fetchGoogleFonts] = createQueryAction<
  { query?: string },
  { fonts: Font[] },
  { fonts: Font[] }
>(
  {
    entity: "assets",
    action: "fetchGoogleFonts",
    debounce: true,
    denormalize: false,
    cacheKey: args => ({ filtered: args.query?.length ? true : false }),
  },
  async ({ args }) => {
    const queryString = QueryString.stringify({
      query: args.query,
    });
    const res = await API.call<{ fonts: Font[] }>({
      endpoint: `resources/fonts/google-fonts?${queryString}`,
      method: "GET",
    });
    return { result: res.result };
  }
);

export default useGoogleFonts;
export { fetchGoogleFonts };
