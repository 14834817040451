/* eslint-disable jsx-a11y/aria-role */
import React, { useState } from "react";
import styled from "styled-components";
import { Text, Flex, Box, Button, utils } from "@thenounproject/lingo-core";

import content, { Period, Plan } from "../data/pricing_SELF_SERVE";

import { Container, Inner } from "../components/Elements";
import PlanComparisonTable from "../components/PlanComparisonTable";
import FaqSection from "../components/FaqSection";
import PageLayout from "./templates/PageLayout";
import TitleSection from "../components/sections/TitleSection";
import Toggle from "../components/Toggle";
import { MarketingButtons } from "../components/MarketingButtons";

const PlanWrapper = styled(Flex).attrs({
  mt: "m",
  flex: "1 1 25%",
  variations: {
    "mq.m": { mt: "none", maxWidth: "640px" },
    "mq.s": { mt: "l" },
  },
})``;

const PlanInner = styled(Inner).attrs({
  flexDirection: "row",
  justifyContent: "center",
  variations: {
    "mq.m": { flexDirection: "column", m: "none", maxWidth: "640px", width: "100%" },
  },
})`
  text-decoration: none;
  gap: 16px;
`;

const BestValueBadge = styled(Box).attrs({
  position: "absolute",
  right: 0,
  top: 0,
  px: "s",
  py: "xs",
})`
  background: ${utils.getColor("primary")};
  border-radius: 0 4px;
`;

const options: {
  label: string;
  value: Period;
}[] = [
  { label: "Monthly", value: "monthly" },
  { label: "Annual (-20%)", value: "yearly" },
];

export default function Pricing() {
  const [period, setPeriod] = useState<Period>("yearly");

  const handleToggle = (value: Period) => {
    setPeriod(value);
  };

  const plans = content.plans;
  return (
    <PageLayout style="light">
      <TitleSection
        title={content.title}
        subtitle={content.subtitle}
        mb="none"
        pb="none"
        gap="24px"
      />
      <Container mt="none" mb="none" variations={{ "mq.xs": { px: "l" } }}>
        <Inner mt="l" alignItems="center">
          <Flex justifyContent="center" mb="l">
            <Toggle options={options} selected={period} onToggle={handleToggle} />
          </Flex>
          <PlanInner maxWidth="960px" mt="none">
            {plans.map(plan => (
              <PlanCard key={plan.id} {...{ plan, period }} />
            ))}
          </PlanInner>
        </Inner>
      </Container>
      <Container mt="none" mb="none" variations={{ "mq.xs": { px: "l" } }}>
        <TitleSection title={content.comparisonTitle} mb="none" pb="none" />
        <Inner mt="none">
          {content.featureGroups.map(group => (
            <PlanComparisonTable
              key={group.name}
              period={period}
              name={group.name}
              features={group.features}
            />
          ))}
        </Inner>
      </Container>
      <FaqSection faqs={content.faqs} title={content.faqTitle} />
      <Container>
        <Inner
          first
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          background={`linear-gradient(to right, #3534c4, #6066e3)`}
          borderRadius="8px"
          p="xxl"
          px="xxl"
          minHeight="292px">
          <Flex alignItems="center" flexDirection="column" gap="24px" maxWidth="428px">
            <Text as="h3" font="marketing.h3" color="white" textAlign="center">
              Customize your plan{" "}
              <span style={{ color: utils.getColor("primaryLightest") }}>
                and get an instant price estimate
              </span>
            </Text>
            <Box>
              <Button
                buttonStyle="outline"
                themeOverrides={{ outlineStyle: "dark" }}
                tintColor="white"
                background="none"
                {...MarketingButtons.calculatePrice}
              />
            </Box>
          </Flex>
        </Inner>
      </Container>
    </PageLayout>
  );
}

type CardProps = {
  plan: Plan;
  period: Period;
};
const PlanCard: React.FC<CardProps> = ({ plan, period }) => {
  const interval = plan[period];

  return (
    <PlanWrapper key={`${plan.id}-card`}>
      <Flex
        flexDirection="column"
        borderRadius="8px"
        width="100%"
        border={plan.bestValue ? "1px solid primaryDarkest" : "1px solid grayLight"}>
        {plan.bestValue && (
          <BestValueBadge>
            <Text font="ui.smallBold" color="white">
              Best value
            </Text>
          </BestValueBadge>
        )}
        <Box minHeight="220" p="l" textAlign="center">
          <Text mt="s" font="marketing.h4">
            {plan.name}
          </Text>
          <Text height="48px" mt="s" mb="l" color="grayDarkest" font="ui.regular">
            {plan.subtitle}
          </Text>
          {plan.id === "enterprise" ? (
            <div>
              <Text mt="xs" font="marketing.h1">
                Custom
              </Text>
              <Text font="ui.regularSemiBold">flexible plans</Text>
              <Text color="grayDarker" font="ui.small">
                billed {period === "yearly" ? "annually" : "monthly"}
              </Text>
            </div>
          ) : (
            <>
              <Flex justifyContent="center" mt="xs" alignItems="baseline">
                <Text font="marketing.h4" alignSelf="flex-start" mr="xs">
                  $
                </Text>
                <Text font="marketing.h1" mr="xxs">
                  {interval.base_price}
                </Text>
                <Text font="marketing.h4">/mo</Text>
              </Flex>
              <Text font="ui.regularSemiBold">platform fee</Text>
              <Text color="grayDarker" font="ui.small">
                billed {period === "yearly" ? "annually" : "monthly"}
              </Text>
              <Flex
                maxWidth={150}
                borderTop="1px solid grayLight"
                borderBottom="1px solid grayLight"
                py="s"
                my="m"
                mx="auto"
                alignItems="center"
                justifyContent="center"
                flexDirection="column">
                <Text font="ui.regularSemiBold">${interval.editor_cost}/editor/mo</Text>
                <Text color="grayDarkest" font="ui.regular">
                  billed {period === "yearly" ? "annually" : "monthly"}
                </Text>
              </Flex>
              <Flex gap="4px" flexDirection="column" alignItems="center">
                <Text font="ui.small">{plan.maxConsumers} consumers/mo included</Text>
                <Text font="ui.small">{plan.storage} GB included</Text>
              </Flex>
            </>
          )}
        </Box>
        <Box flex="10" />
        <Box p="l">
          {plan.id === "enterprise" ? (
            <Button buttonStyle="secondary" fullWidth {...MarketingButtons.bookDemo} />
          ) : (
            <Button
              buttonStyle={plan.id === "starter" ? "secondary" : "primary"}
              fullWidth
              {...MarketingButtons.calculatePrice}
            />
          )}
        </Box>
      </Flex>
    </PlanWrapper>
  );
};
