import styled from "styled-components";

// TODO: This seems like it could be a globally reused component.
// Just putting it here to minimize the refactor for now.

const BaseAvatar = styled.img`
  align-self: center;
  border-radius: 50%;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 8px;
  position: relative;
  transition: opacity 0.2s ease;
`;

export const AvatarLarge = styled(BaseAvatar)`
  flex-basis: 40px;
  width: 40px;
  height: 40px;
`;

export const AvatarSmall = styled(BaseAvatar)`
  flex-basis: 24px;
  width: 24px;
  height: 24px;
`;
