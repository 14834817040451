import { API, PrivacyLevel, ErrorCode } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: string | number;
  kitId: string;
  privacy: PrivacyLevel;
  password?: string;
  indexable?: boolean;
};

const [useUpdateKitPrivacy, updateKitPrivacy] = createAsyncAction(
  "kits/updatePrivacy",
  async ({ spaceId, kitId, privacy, password, indexable }: Args) => {
    const data = {
      privacy,
      indexable,
      password: undefined,
    };
    switch (privacy) {
      case PrivacyLevel.password: {
        if (!password) {
          throw {
            code: ErrorCode.invalidParams,
            error: "Password is required for password-protected kits.",
          };
        }
        data.password = password;
        break;
      }
      case PrivacyLevel.private:
      case PrivacyLevel.public:
        break;
      default:
        throw {
          code: ErrorCode.invalidParams,
          message: "Invalid privacy level",
        };
    }

    return await API.call<string>({
      endpoint: `kits/${spaceId}/${kitId}`,
      method: "PUT",
      data,
      entity: API.Entity.kit,
    });
  }
);

export default useUpdateKitPrivacy;
export { updateKitPrivacy };
