import React, { useCallback, useRef, useEffect } from "react";
import styled from "styled-components";
import _merge from "lodash/merge";
import useNavPoint from "@hooks/useNavPoint";

import usePortalItems from "@redux/actions/portalItems/usePortalItems";
import { Flex, SpaceThemeNames, Box, PortalItem } from "@thenounproject/lingo-core";

import PortalNav from "./nav/PortalNav";
import PortalNavMobile from "./nav/PortalNavMobile";
import { useInView } from "react-intersection-observer";
import { TabletDown, TabletUp } from "@features/media-queries";
import PortalHeader from "./PortalHeader";
import { useTheme } from "styled-components";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";
import { useGetDraggingState } from "@selectors/getters";

import PortalItems from "./PortalItems";
import PortalInsertPanel from "./PortalInsertPanel";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";
import { useClearInspectorOnClick, useShiftKey } from "@helpers/selection";
import { handleInspectorPortalItemClick } from "@helpers/portalItems";
import { OverflowWrapperPreview } from "@features/theming/pages/SpaceThemeEditor";
import PortalThemeControlPanel from "@features/theming/components/PortalThemeControlPanel";
import useConfigureMode from "../hooks/useConfigureMode";
import ScrollToTopOnMount from "../ScrollToTopOnMount";

export const sharedThemeVals = {
  [SpaceThemeNames.classic]: {
    contentContainer: { maxWidth: "960px" },
  },
  [SpaceThemeNames.scher]: {
    contentContainer: { maxWidth: "1000px" },
  },
  [SpaceThemeNames.wyman]: {
    contentContainer: { maxWidth: "1140px" },
  },
};

export const ThemePreviewContentWrapper = styled(Flex).attrs({
  flexDirection: "column",
  flexGrow: 1,
  mx: "auto",
  width: "100%",
})``;

export const ThemeEditorSidePanel = styled(Box).attrs({
  flex: "240px 1 0",
  height: "100%",
  background: "white",
  position: "sticky",
  overflow: "auto",
})``;

const Portal: React.FC = () => {
  const { configureMode } = useConfigureMode();
  const { portal, space } = useNavPoint(),
    queryResult = usePortalItems({ portalId: portal.id });
  const canEdit = portal?.access?.permissions?.includes("edit");

  const themeName: SpaceThemeNames = useTheme().themeName;
  const setDraggingEntity = useSetDraggingEntity();
  const portalDraggingActive = useGetDraggingState().entity === DragEntities.GALLERY_ITEM;

  const { ref: titleRef, inView: titleInView } = useInView({ initialInView: true });

  const {
    selectItems,
    clearSelection,
    selectionState: { portal: selectedItems },
  } = useSelectedItemsContext();

  const setInspectorItemIds = useCallback(
    (ids: string[]) => selectItems({ type: "portal", ids }),
    [selectItems]
  );
  const shiftDown = useShiftKey();
  const cachedSelectedItems = useRef<string[]>(null);

  useEffect(() => {
    if (!shiftDown) cachedSelectedItems.current = null;
  }, [shiftDown]);

  const onSelectItem = useCallback(
    (event: React.MouseEvent, portalItemId: string, portalItems: PortalItem[]) => {
      let selectedBeforeClick = selectedItems;
      if (event?.shiftKey) {
        if (!cachedSelectedItems.current || cachedSelectedItems.current.length === 0) {
          cachedSelectedItems.current = selectedItems;
        }
        selectedBeforeClick = cachedSelectedItems.current;
      }
      handleInspectorPortalItemClick(
        event,
        portalItemId,
        portalItems,
        selectedBeforeClick,
        setInspectorItemIds
      );
    },
    [selectedItems, setInspectorItemIds]
  );

  const setInspectorState = useCallback(
    state => {
      setInspectorItemIds(state);
      cachedSelectedItems.current = state;
    },
    [setInspectorItemIds]
  );

  const clearInspector = useCallback(() => {
    clearSelection({ type: "portal" });
    cachedSelectedItems.current = null;
  }, [clearSelection]);

  useClearInspectorOnClick(clearInspector);

  useEffect(() => {
    clearInspector();
  }, [portal.id, clearInspector]);

  function renderHeader() {
    return (
      <>
        <TabletUp>
          <PortalNav
            portal={portal}
            space={space}
            titleInView={titleInView}
            configureMode={configureMode}
          />
          <PortalHeader portal={portal} titleRef={titleRef} />
        </TabletUp>
        <TabletDown>
          <PortalNavMobile space={space} portal={portal} />
        </TabletDown>
      </>
    );
  }

  function renderContent() {
    return (
      <>
        {renderHeader()}
        <Flex
          mt="xl"
          mx="auto"
          width="100%"
          gap="8px"
          {...sharedThemeVals[themeName].contentContainer}>
          {canEdit && !configureMode && <PortalInsertPanel />}
          <Box
            width="100%"
            data-inspector-clear="true"
            {...sharedThemeVals[themeName].contentContainer}>
            <PortalItems
              queryResult={queryResult}
              space={space}
              portal={portal}
              setDraggingEntity={setDraggingEntity}
              portalDraggingActive={portalDraggingActive}
              canEditContent={canEdit}
              setInspectorState={setInspectorState}
              onSelectItem={onSelectItem}
              selectedItems={selectedItems}
            />
          </Box>
        </Flex>
      </>
    );
  }

  if (configureMode) {
    return (
      <>
        <Flex height="100%">
          <OverflowWrapperPreview>
            <ThemePreviewContentWrapper>{renderContent()}</ThemePreviewContentWrapper>
          </OverflowWrapperPreview>
          <ThemeEditorSidePanel>
            <PortalThemeControlPanel portal={portal} />
          </ThemeEditorSidePanel>
        </Flex>
      </>
    );
  }

  return (
    <Flex flexDirection="column" data-inspector-clear="true">
      <ScrollToTopOnMount />
      {renderContent()}
    </Flex>
  );
};

export default Portal;
