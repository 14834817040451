import { Kit, Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  portalId: string;
};

export type PortalTableResult = Kit & {
  views: {
    totalViews: number;
    memberViews: number;
    guestViews: number;
    uniqueGuests: number;
    uniqueMembers: number;
    uniquePeople: number;
  };
};

type PortalKitTableData = {
  kits: PortalTableResult[];
  total: number;
};

const [usePortalKitPerformance, fetchPortalKitPerformance] = createQueryAction<
  Args,
  PortalKitTableData
>(
  {
    entity: "analytics",
    action: "queryPortalKitPerformance",
    denormalize: false,
    pagingKey: "items",
  },
  async ({ args, paging }) => {
    const reports = {
      kits: {
        id: "kits",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        portal_uuid: args.portalId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ kits: PortalKitTableData }>(args.spaceId, reports)).kits;
  }
);

export default usePortalKitPerformance;
export { fetchPortalKitPerformance };
