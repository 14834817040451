/**
 * Simple component for displaying metadata for a published kit. Intended to be displayed within
 * modal.
 */

import React, { Fragment } from "react";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import { Box, Button, Flex, Kit, Text } from "@thenounproject/lingo-core";

type Props = {
  kit: Kit;
};

const PublishedKitMeta: React.FC<Props> = ({ kit }) => {
  const {
    name: kitName,
    description: notes,
    publisher: { name: publisherName = null } = {},
    purchase: { licenseName = null, licenseUrl = null } = {},
  } = kit;

  function renderDetail(key, value) {
    if (!value) return null;
    return (
      <Box mb="m">
        <Text font="ui.small" color="grayDarkest">
          {key}
        </Text>
        {typeof value == "string" ? <Text>{value}</Text> : value}
      </Box>
    );
  }

  return (
    <Fragment>
      <ModalHeader title={`${kitName} details`} />
      <ModalBody>
        <Flex flexDirection="column">
          {renderDetail("Name", kitName)}
          {renderDetail("Description", notes)}
          {renderDetail("Created by", publisherName)}
          {licenseName &&
            renderDetail(
              "License",
              licenseUrl?.trim() ? (
                <Button
                  text={licenseName}
                  link={licenseUrl.trim()}
                  buttonStyle="tertiary"
                  newWindow
                />
              ) : (
                licenseName
              )
            )}
        </Flex>
      </ModalBody>
    </Fragment>
  );
};

export default PublishedKitMeta;
