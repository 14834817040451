import React from "react";
import { Text, Box } from "@thenounproject/lingo-core";
import { Container, InnerMedium } from "../Elements";
import MarketingText from "../MarketingText";

type Props = {
  title: string;
  subtitle?: string;
  style?: "light" | "dark";
  gap?: string;
} & React.ComponentProps<typeof Box>;

const TitleSection: React.FC<Props> = ({ title, subtitle, style = "light", gap, ...rest }) => {
  const color = {
    dark: "white",
    light: "black",
  }[style];

  return (
    <Container background={style === "dark" ? "primary" : null} mt="none" py="xxl" {...rest}>
      <InnerMedium textAlign="center" first gap={gap}>
        <Text as="h1" color={color} font="marketing.h1">
          {title}
        </Text>
        <MarketingText color={color} font={"marketing.bodyLarge"}>
          {subtitle}
        </MarketingText>
      </InnerMedium>
    </Container>
  );
};
export default TitleSection;
