import styled from "styled-components";
import { MarkdownText } from "@thenounproject/lingo-core";
import TextLink from "./TextLink";

// A wrapper around markdown text with the marketing defaults
type Props = Omit<React.ComponentProps<typeof MarkdownText>, "buttonComponent">;

const MarketingText: React.FC<Props> = styled(MarkdownText).attrs(props => {
  return {
    buttonComponent: TextLink,
    font: props.font ?? "marketing.regular",
  };
})``;

export default MarketingText;
