import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  dateOperators?: OperatorValues;
  directLinkId: number;
};

type TotalReferrersData = {
  date: string;
  totalReferrers: number;
};

type ReferrersData = {
  result: TotalReferrersData[];
};

const [useUniqueDirectLinkReferrers, fetchUniqueDirectLinkReferrers] = createQueryAction<
  Args,
  ReferrersData
>(
  {
    entity: "analytics",
    action: "queryUniqueDirectLinkReferrers",
    denormalize: false,
    pagingKey: "result",
  },
  async ({ args }) => {
    const reports = {
      cdn_usage_intervals: {
        id: "intervals",
        entity: "cdn_referrers",
        date_operators: args.dateOperators,
        direct_link_id: args.directLinkId,
      },
    };

    const res = await fetchAnalytics<{ cdn_usage_intervals: ReferrersData }>(args.spaceId, reports);

    return { result: res.cdnUsageIntervals };
  }
);

export default useUniqueDirectLinkReferrers;
export { fetchUniqueDirectLinkReferrers };
