import React from "react";
import styled from "styled-components";
import { Box, Flex, Icon, Text, Space, EmptyState } from "@thenounproject/lingo-core";
import { connect } from "react-redux";

import { selectSpace } from "@selectors/entities/spaces";
import type { RootState } from "@redux/store";

import { storeV1Context } from "@redux/hooks";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

const TemplateCard = styled(Box).attrs({
  as: "button",
  width: "200px",
  py: "l",
  mx: "s",
  mb: "m",
  borderRadius: "default",
  alignItems: "center",
  border: "default",
  variations: {
    "&:hover": {
      background: "grayLighter",
    },
  },
  style: {
    transition: "background-color 0.4s",
    cursor: "pointer",
  },
})``;

type Props = { space?: Space };

const SpaceKitsEmptyView: React.FC<Props> = ({ space }) => {
  const { showModal } = useShowModal();
  const canCreate = space.access.permissions.includes("edit");

  function openTemplateModal(templateName) {
    showModal(ModalTypes.CREATE_TEMPLATE_KIT, { templateName, space });
  }

  function renderKitTemplateButtons() {
    return (
      <Flex flexWrap="wrap" justifyContent="center" mt="l">
        <Flex flexWrap="wrap" justifyContent="center">
          <TemplateCard id="style-guide-template" onClick={() => openTemplateModal("styleGuide")}>
            <Icon iconId="icon-style-guide" fill="grayDarkest" size="60" mb="s" />
            <Text>Brand Style Guide</Text>
          </TemplateCard>
          <TemplateCard id="ui-library-template" onClick={() => openTemplateModal("uiLibrary")}>
            <Icon iconId="icon-design-system" fill="grayDarkest" size="60" mb="s" />
            <Text>UI Component Library</Text>
          </TemplateCard>
        </Flex>
        <Flex flexWrap="wrap" justifyContent="center">
          <TemplateCard
            id="asset-library-template"
            onClick={() => openTemplateModal("assetLibrary")}>
            <Icon iconId="icon-asset-library" fill="grayDarkest" size="60" mb="s" />
            <Text>Digital Asset Library</Text>
          </TemplateCard>
          <TemplateCard
            id="no-template"
            onClick={() => showModal(ModalTypes.CREATE_KIT, { space, includeTemplates: false })}>
            <Icon iconId="kit" fill="grayDarkest" size="60" mb="s" />
            <Text>New Kit</Text>
          </TemplateCard>
        </Flex>
      </Flex>
    );
  }

  function renderEmptyState() {
    return canCreate ? (
      <EmptyState
        title="Choose the type of kit you want to build"
        subtitle="Kits are like asset libraries, they help you organize your visual language. You can create an unlimited number of kits."
        extra={renderKitTemplateButtons()}
      />
    ) : (
      <EmptyState
        title="No kits yet"
        subtitle="A Kit is a home for your visual language (e.g. Style Guide, UI Library)."
        iconProps={{ iconId: "kit", size: 90, fill: "gray" }}
      />
    );
  }

  return renderEmptyState();
};

const mapState = (state: RootState) => {
  return {
    space: selectSpace(state),
  };
};

export default connect(mapState, {}, null, {
  context: storeV1Context,
})(SpaceKitsEmptyView);
