import { API, camelize, Plan } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

export type PlanOverview = {
  month: Plan;
  year: Plan;
};

export type Response = {
  plans: {
    business: PlanOverview;
    starter: PlanOverview;
  };
  owner: { name: string; email: string };
};

const [useAvailablePlans, fetchAvailablePlans] = createQueryAction<
  { spaceId: number },
  Response,
  Response
>(
  {
    entity: "spaces",
    action: "fetchAvailablePlans",
    denormalize: false,
  },
  async ({ args }) => {
    const res = await API.call<Response>({
      endpoint: `spaces/${args.spaceId}/plans`,
      method: "GET",
    });

    return {
      result: {
        plans: camelize(res.result.plans),
        owner: res.result.owner,
      },
    };
  }
);

export default useAvailablePlans;
export { fetchAvailablePlans };
