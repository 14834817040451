import { fetchAsset } from "@redux/actions/assets/useAsset";
import { duplicateItems } from "@redux/actions/items/useDuplicateItems";
import { fetchGalleryItems } from "@redux/actions/items/useGalleryItems";
import { getEntities } from "@redux/reducers/helpers/getEntities";
import { AppStore } from "@redux/store/reduxStore";
import { Middleware, UnknownAction } from "@reduxjs/toolkit";
import { createKit } from "@redux/actions/kits/useCreateKit";
import { fetchKits } from "@redux/actions/kits/useKits";
import { pollForDuplicateKit } from "@redux/actions/kits/usePollForDuplicateKit";
import { deleteKit } from "@redux/actions/kits/useDeleteKit";
import { deletePortalItem } from "@redux/actions/portalItems/useDeletePortalItem";
import { removeSharedKit } from "@redux/actions/kits/useRemoveSharedKit";
import { createPortalItems } from "@redux/actions/portalItems/useCreatePortalItems";
import { queryPortalKits } from "@redux/actions/portals/usePortalKits";
import { PortalItem, PortalItemType } from "@thenounproject/lingo-core";
import { deletePortalItems } from "@redux/actions/portalItems/useDeletePortalItems";
import { fetchPreviewMigratePortals } from "@redux/actions/portals/usePreviewPortals";
import { updateSpaceMember } from "@redux/actions/spaceMembers/useUpdateSpaceMember";
import { batchUpdateKitMembers } from "@redux/actions/kitMembers/useBatchUpdateKitMembers";
import { updateEditorStatus } from "@redux/actions/spaceMembers/useUpdateEditorStatus";
import { removePortalMembers } from "@redux/actions/portalMembers/useRemovePortalMembers";
import { updateSubscription } from "@redux/actions/billing/useUpdateSubscription";
import { fetchSpacePlan } from "@redux/actions/billing/useSpacePlan";
import { fetchBillingData } from "@redux/actions/billing/useBillingData";

const mutations: Middleware = (store: AppStore) => next => (action: UnknownAction) => {
  processMutations(store, action);
  return next(action);
};

export default mutations;

function processMutations(store: AppStore, action: UnknownAction) {
  checkForUploadedGalleries(store, action);
  refetchAsset(store, action);
  refetchKit(store, action);
  refetchPreviewMigratePortals(store, action);
  refetchSpacePlan(store, action);
  onKitDeleted(store, action);
  onPortalItemCreated(store, action);
  onPortalItemDeleted(store, action);
  checkForUpdatedKitPermissions(store, action);
}

function checkForUploadedGalleries(store: AppStore, action: UnknownAction) {
  const entities = getEntities(action);
  if (!entities?.items) return;
  const galleries = Object.entries(entities.items).filter(
    item => item[1].version === 0 && item[1].type === "gallery"
  );
  const state = store.getState();
  galleries.forEach(gallery => {
    const galleryItem = gallery[1];
    const existingGallery = state.entities.items.objects[gallery[0]];

    if (!existingGallery) return;
    if (
      existingGallery.data.dateRefreshed !== galleryItem.data.dateRefreshed ||
      existingGallery.data.viewId !== galleryItem.data.viewId
    ) {
      // Clear the query for the gallery
      const invalidateAction = fetchGalleryItems.invalidateAction({
        itemId: galleryItem.id,
        version: galleryItem.version,
      });
      store.dispatch(invalidateAction);
    }
  });
}

function refetchAsset(store: AppStore, action: UnknownAction) {
  if (duplicateItems.fulfilled.match(action) && action.meta.arg.createReferences) {
    const {
      result: { items: newItems },
      entities,
    } = action.payload;
    const assetIds = newItems.map(i => entities.items[i].assetId);
    assetIds.forEach(assetId => store.dispatch(fetchAsset.invalidateAction({ assetId })));
  }
}

function refetchKit(store: AppStore, action: UnknownAction) {
  if (pollForDuplicateKit.fulfilled.match(action) || createKit.fulfilled.match(action)) {
    const { spaceId } = action.meta.arg;
    store.dispatch(fetchKits.invalidateAction({ spaceId }));
  }
}

function refetchSpacePlan(store: AppStore, action: UnknownAction) {
  if (updateSubscription.fulfilled.match(action)) {
    const { spaceId } = action.meta.arg;
    store.dispatch(fetchSpacePlan.invalidateAction({ spaceId }));
    store.dispatch(fetchBillingData.invalidateAction({ spaceId }));
  }
}

function refetchPreviewMigratePortals(store: AppStore, action: UnknownAction) {
  if (updateSpaceMember.fulfilled.match(action) || batchUpdateKitMembers.fulfilled.match(action)) {
    const { spaceId } = action.meta.arg;
    store.dispatch(fetchPreviewMigratePortals.invalidateAction({ spaceId }));
  }
}

function checkForUpdatedKitPermissions(store: AppStore, action: UnknownAction) {
  if (removePortalMembers.fulfilled.match(action)) {
    const { entities, result } = action.payload;
    const state = store.getState();
    const updatedMembers = result.members
      .filter(r => r.success)
      .map(r => entities.portalMembers[r.result]);
    updatedMembers.forEach(portalMember => {
      // Which kits does the user no longer have access to via other portals?
      const kitsInPortal = queryPortalKits.getQueryData(state.entities.kits.queries, {
        portalId: portalMember.portalId,
      })[0].data.kits;
      const otherPortalAccess = Object.values(state.entities.portalMembers.objects).filter(
        member =>
          member.userId === portalMember.userId &&
          member.spaceId === portalMember.spaceId &&
          member.status === "active" &&
          member.portalId !== portalMember.portalId
      );
      const kitsInOtherPortals = otherPortalAccess.reduce((acc, portalAccess) => {
        queryPortalKits
          .getQueryData(state.entities.kits.queries, {
            portalId: portalAccess.portalId,
          })[0]
          .data.kits.forEach(kitId => acc.add(kitId));
        return acc;
      }, new Set<string>());

      const removedKits = kitsInPortal.filter(kitId => !kitsInOtherPortals.has(kitId));
      // Nothing to do if all the kits in this portal are still accessible via other portals
      if (removedKits.length === 0) return;

      // Find all kit edit permissions for remaining kits
      const editPermissions = Object.values(state.entities.kitMembers.objects).filter(
        member =>
          member.userId === portalMember.userId &&
          member.spaceId === portalMember.spaceId &&
          member.status === "active" &&
          member.role === "collaborator" &&
          !removedKits.includes(member.kitId)
      );
      const hasEditPermission = Boolean(editPermissions.length);
      store.dispatch(
        updateEditorStatus({
          memberId: portalMember.userId,
          spaceId: portalMember.spaceId,
          editorStatus: hasEditPermission,
        })
      );
    });
  }

  if (batchUpdateKitMembers.fulfilled.match(action)) {
    const { entities, result } = action.payload;
    const state = store.getState();
    const updatedMembers = result.members
      .filter(r => r.success)
      .map(r => ({ key: r.result, kitMember: entities.kitMembers[r.result] }));

    updatedMembers.forEach(({ kitMember }) => {
      const member =
        state.entities.spaceMembers.objects[`${kitMember.spaceId}-${kitMember.userId}`];
      if (!member) return;

      const hasEditPermission = Object.values(state.entities.kitMembers.objects).find(member => {
        if (member.userId !== kitMember.userId) return false;
        if (member.spaceId !== kitMember.spaceId) return false;
        const role = member.kitId === kitMember.kitId ? kitMember.role : member.role;
        return role === "collaborator";
      });

      store.dispatch(
        updateEditorStatus({
          memberId: member.userId,
          spaceId: member.spaceId,
          editorStatus: Boolean(hasEditPermission),
        })
      );
    });
  }
}

function onKitDeleted(store: AppStore, action: UnknownAction) {
  if (deleteKit.fulfilled.match(action) || removeSharedKit.fulfilled.match(action)) {
    // a deleted kit will return the deleted version
    // a removed shared kit will return the kit
    const removedKit =
      action.payload.entities.kitVersions?.[action.payload.result] ??
      action.payload.entities.kits?.[action.payload.result];
    const portalItems = Object.values(store.getState().entities.portalItems.objects).filter(
      pi => pi.kitId === removedKit.kitId && pi.spaceId === removedKit.spaceId
    );
    portalItems.forEach(portalItem =>
      store.dispatch({
        type: deletePortalItem.fulfilled.type,
        meta: { arg: { portalItemId: portalItem.id, portalId: portalItem.portalId } },
        payload: {
          result: portalItem.id,
          entities: {
            portalItems: { [portalItem.id]: { status: "deleted" } },
          },
        },
      })
    );
  }
}

function onPortalItemCreated(store: AppStore, action: UnknownAction) {
  if (createPortalItems.fulfilled.match(action)) {
    const { result, entities } = action.payload;
    const newKits = result.portalItems
      .filter(res => res.success)
      .map(res => entities.portalItems[res.result])
      .filter(item => item.type === PortalItemType.kit);

    if (newKits.length) {
      const portalId = action.meta.arg.portalId;
      store.dispatch(queryPortalKits.invalidateAction({ portalId }));
    }
  }
}

function onPortalItemDeleted(store: AppStore, action: UnknownAction) {
  const deletedItems: PortalItem[] = [];
  if (deletePortalItem.fulfilled.match(action)) {
    const { result, entities } = action.payload;
    const item = entities.portalItems[result];
    deletedItems.push(item);
  } else if (deletePortalItems.fulfilled.match(action)) {
    const { result, entities } = action.payload;
    const items = result.portalItems
      .filter(res => res.success)
      .map(res => entities.portalItems[res.result]);
    deletedItems.push(...items);
  }
  new Set(
    deletedItems.filter(item => item.type === PortalItemType.kit).map(item => item.portalId)
  ).forEach(portalId => {
    store.dispatch(queryPortalKits.invalidateAction({ portalId }));
  });
}
