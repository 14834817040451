import React, { Fragment, ReactElement, useState } from "react";
import ModalHeader from "../../../ModalHeader";
import ModalBody from "../../../ModalBody";
import ModalFooter from "../../../ModalFooter";
import introducingMultiplePortals from "../../../../images/modals/portal-migration-modal/IntroducingMultiplePortals.png";
import newAdminDashboard from "../../../../images/modals/portal-migration-modal/NewAdminDashboard.png";
import portalSharing from "../../../../images/modals/portal-migration-modal/PortalSharing.png";
import portalCustomization from "../../../../images/modals/portal-migration-modal/PortalCustomization.png";
import LazyImage from "../../../../marketing/components/LazyImage";
import { Box, Text } from "@thenounproject/lingo-core";
import getImageDimensionPadding from "@helpers/getImageDimensionPadding";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import styled from "styled-components";

const PortalMigrationModalText = styled(Text)`
  li {
    list-style-type: disc;
  }
  ul {
    padding-left: 20px;
  }
`;

type Props = {
  spaceId: number;
};

export default function PortalMigrationModal({ spaceId }: Props) {
  const [step, setStep] = useState(0);
  const { showModal, dismissModal } = useShowModal();

  const title1 = "Migrate to portal based sharing";

  type ModalPage = {
    title: string;
    image?: string;
    heading?: string;
    body: string[] | ReactElement;
  };

  const pages: ModalPage[] = [
    {
      title: title1,
      image: introducingMultiplePortals,
      heading: "Introducing Lingo's new portal based sharing functionality",
      body: [
        "We've reimagined Lingo's sharing functionality to make it more powerful than ever. Below is a quick summary of the change. If you want to read a more in depth explanation please read this help center article.",
        "In Lingo's previous architecture, each workspace had a single portal—a home page that displayed selected kits to users. When inviting team members, you could choose which kits they could access within this single portal.",
        "With Lingo's new architecture, you can now create multiple portals in a workspace, and each portal is fully customizable. Instead of selecting kits for individual users, you now invite team members directly to specific portals that contain kits. This allows you to create custom tailored content environments for each of the different groups of people you need to distribute content to.",
      ],
    },
    {
      title: title1,
      image: newAdminDashboard,
      heading: "New Dashboard",
      body: [
        "If you're an admin in your workspace you'll see a newly designed dashboard that has two sections - one for kits, one for portals. Portals are customizable websites that contain kits. ",
      ],
    },
    {
      title: title1,
      image: portalCustomization,
      heading: "Portal customization",
      body: (
        <PortalMigrationModalText font="ui.regular">
          Each portal is fully customizable allowing you to arrange and display content the way you
          want. Inside a portal you can:
          <p>
            <ul>
              <li>Reorder kits</li>
              <li>Add contextual information alongside your kits</li>
              <li>Custom brand your portal by adding an accent color and header image</li>
            </ul>
          </p>
        </PortalMigrationModalText>
      ),
    },
    {
      title: title1,
      image: portalSharing,
      heading: "Portal sharing",
      body: [
        "Portals can be private, password protected or fully public. Instead of selecting kits for individual users, you now give Members access to specific portals that contain kits.",
      ],
    },
    {
      title: "What's Next?",
      body: [
        "In the next screen you'll see a preview of the portals that will be created for you based on your existing user permissions. If you want to make changes to your user permissions in order to adjust the number of portals you'll be able to do so directly from within the screen. ",
      ],
    },
  ];

  const currentPage = pages[step];

  const renderModalContent = () => (
    <Fragment key={step}>
      <ModalHeader title={currentPage.title} />
      <ModalBody>
        {currentPage.image && (
          <Box mb="l">
            <LazyImage
              src={currentPage.image}
              alt={"Portal Migration"}
              imagePadding={getImageDimensionPadding(1180, 490)}
              style={{
                borderRadius: "16px",
                border: "1px solid #E1E2E4",
                height: "260px",
              }}
            />
          </Box>
        )}
        {currentPage.heading && (
          <Text font="ui.title" mb="m">
            {currentPage.heading}
          </Text>
        )}
        {Array.isArray(currentPage.body)
          ? currentPage.body.map((text, index) => (
              <Text
                key={index}
                font="ui.regular"
                mb={
                  Array.isArray(currentPage.body) && index < currentPage.body.length - 1
                    ? "m"
                    : undefined
                }>
                {text}
              </Text>
            ))
          : currentPage.body}
      </ModalBody>
    </Fragment>
  );

  const handleNext = () => {
    if (currentPage === pages[pages.length - 1]) {
      dismissModal();
      showModal(ModalTypes.MIGRATE_TO_PORTALS, { spaceId });
    } else {
      setStep(prevStep => (prevStep + 1) % pages.length);
    }
  };

  const handleBack = () => {
    setStep(prevStep => prevStep - 1);
  };

  return (
    <Fragment>
      {renderModalContent()}
      <ModalFooter
        primary={{
          text: "Continue",
          onClick: handleNext,
        }}
        secondary={
          step > 0
            ? {
                text: "Back",
                onClick: handleBack,
              }
            : null
        }
      />
    </Fragment>
  );
}
