import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { isEqual } from "lodash";
import {
  Text,
  Flex,
  Checkbox,
  Select,
  Input,
  Button,
  Box,
  KitPermission,
  Notice,
  SpacePermission,
} from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";

import useSaveAssetMetdata from "@redux/actions/assets/useSaveAssetMetadata";
import { Inspectable } from "@constants/Inspector";
import useUpsell, { AccessStatus } from "@hooks/useUpsell";
import useKitUpsell from "@hooks/useKitUpsell";
import useNotifications from "@actions/useNotifications";
import useShowModal from "@redux/actions/useModals";
import { useSelectKit } from "@redux/selectors/entities/kits";

type WrapperProps = { isLoading: boolean };
const Wrapper = styled(Flex).attrs<WrapperProps>({
  height: "100%",
  minHeight: "300px",
  width: "100%",
  flexDirection: "column",
  borderRadius: "default",
  overflow: "hidden",
})<WrapperProps>`
  pointer-events: ${props => (props.isLoading ? "none" : "all")};
`;

const SelfServe = styled(Flex).attrs({
  flexWrap: "wrap",
  flexShrink: 0,
  my: "s",
})``;

type Props = {
  inspectable: Inspectable;
};

const ManageFilecuts: React.FC<Props> = ({ inspectable }) => {
  const { dismissModal } = useShowModal();
  const { showNotification } = useNotifications();
  const { item, asset } = inspectable;
  const {
    id: assetId,
    meta: {
      filecuts: { availableTypes, presets },
    },
  } = asset;

  const kit = useSelectKit(item?.kitId);

  const { openUpgradeModal, accessStatus: stat1 } = useUpsell(SpacePermission.manageExports);
  const { accessStatus: stat2 } = useKitUpsell(KitPermission.manageExports, kit);

  const canAccess = (item ? stat2 : stat1) === AccessStatus.canAccess;

  // Data hooks
  const [filecutPresets, setFilecutPresets] = useState(presets);
  const [selfServeTypes, setSelfServeTypes] = useState(availableTypes);
  const [saveAssetMetadata] = useSaveAssetMetdata();

  // Lifecycle hooks
  const [loading, setLoading] = useState(false);

  // Utils
  const resizableByType = availableTypes.reduce((reduced, typeObj) => {
    reduced[typeObj.type] = typeObj.resizable;
    return reduced;
  }, {});

  const handleFieldChange = (i: number, key: "type" | "size" | "description", val: string) => {
    const newPresets = [...filecutPresets],
      nonResizableTypeSelected = key === "type" && !resizableByType[val],
      newObj = {
        type: key === "type" ? val : newPresets[i].type,
        size: key === "size" ? val : nonResizableTypeSelected ? "1x" : newPresets[i].size,
        description: key === "description" ? val : newPresets[i].description,
      };
    newPresets.splice(i, 1, newObj);
    setFilecutPresets(newPresets);
  };

  const handleRemove = (i: number) => {
    const newPresets = [...filecutPresets];
    newPresets.splice(i, 1);
    setFilecutPresets(newPresets);
  };

  const handleAdd = () => {
    const newPresets = [...filecutPresets];
    newPresets.push({
      type: availableTypes[0].type,
      description: "",
      size: "1x",
    });
    setFilecutPresets(newPresets);
  };

  const handleToggle = (i: number) => {
    const newTypes = [...selfServeTypes];
    const newObj = {
      enabled: !newTypes[i].enabled,
      resizable: newTypes[i].resizable,
      type: newTypes[i].type,
    };
    newTypes.splice(i, 1, newObj);
    setSelfServeTypes(newTypes);
  };

  const handleSubmit = useCallback(async () => {
    const data = {
      meta: {
        filecuts: {
          presets: filecutPresets,
          available_types: selfServeTypes,
        },
      },
    };

    setLoading(true);

    const result = await saveAssetMetadata({ assetId, data });
    if (!result.error) {
      showNotification({ message: "Asset metadata saved successfully." });
      dismissModal();
    } else {
      setLoading(false);
      showNotification({ message: result.error.message, level: "error" });
    }
  }, [assetId, dismissModal, filecutPresets, saveAssetMetadata, selfServeTypes, showNotification]);

  function renderPresets() {
    const addDisabled = filecutPresets.length >= 10;
    const elements = filecutPresets.map((f, i) => (
      <Flex mb="xs" key={`${f.type}-${i}`} data-testid={`preset-${f.type}-${i}`}>
        <Select
          buttonStyle="dropdown"
          value={filecutPresets[i].type}
          onChange={v => handleFieldChange(i, "type", v)}
          size="regular"
          disabled={!canAccess}
          options={selfServeTypes.map(t => {
            return { value: t.type, label: t.type };
          })}
        />
        <Input
          id="size-input"
          styleOverrides={{ width: "90px", flexShrink: "0", ml: "xs" }}
          placeholder="2x, 300w"
          type="text"
          disabled={!canAccess || !resizableByType[f.type]}
          value={f.size}
          onChange={e => handleFieldChange(i, "size", e.target.value)}
        />
        <Input
          id="desc-input"
          placeholder="Description (optional)"
          styleOverrides={{ ml: "xs" }}
          type="text"
          value={f.description}
          onChange={e => handleFieldChange(i, "description", e.target.value)}
          disabled={!canAccess}
        />
        <Button
          text="Remove"
          buttonStyle="tertiary"
          size="small"
          onClick={() => handleRemove(i)}
          ml="s"
          flexShrink="0"
          disabled={!canAccess}
        />
      </Flex>
    ));
    return (
      <Box mb="xl">
        {elements}
        <Button
          buttonStyle="tertiary"
          size="small"
          data-test="add-new-preset"
          onClick={addDisabled ? null : handleAdd}
          text={addDisabled ? "You've reached the limit of 10 presets" : "Add preset"}
        />
      </Box>
    );
  }

  function renderSelfServe() {
    const elements = selfServeTypes.map((s, i) => (
      <Checkbox
        disabled={!canAccess}
        key={`${s}-${i}`}
        isSelected={s.enabled}
        label={s.type}
        onClick={() => handleToggle(i)}
        styleOverrides={{ mr: "m" }}
      />
    ));
    return <SelfServe>{elements}</SelfServe>;
  }

  const footerProps = {
    primary: {
      text: loading ? "Saving..." : "Save",
      onClick: handleSubmit,
      disabled:
        loading || (isEqual(filecutPresets, presets) && isEqual(selfServeTypes, availableTypes)),
    },
    secondary: {
      text: "Cancel",
      onClick: dismissModal,
      disabled: false,
    },
  };
  return (
    <Wrapper isLoading={loading}>
      <ModalHeader title="Manage exports" />
      <ModalBody>
        {!canAccess && (
          <Notice
            mb="l"
            message="Export management is not available on your current plan"
            button={{ text: "View plans", onClick: openUpgradeModal }}
            noticeStyle="warning"
          />
        )}

        <Text font="ui.regularBold">Presets</Text>
        <Text mb="s">Make the asset available as:</Text>
        {renderPresets()}
        <Text font="ui.regularBold">Custom export</Text>
        <Text mb="s">Allow people to export the asset at any size as:</Text>
        {renderSelfServe()}
        <Text mt="s" font="ui.small" color="grayDarkest">
          <strong>Note:</strong> vector formats (SVG, PDF, EPS) will be exportable at the original
          size only. Raster formats (PNG, JPG) will be exportable at any size.
        </Text>
      </ModalBody>
      <ModalFooter {...footerProps} />
    </Wrapper>
  );
};

export default ManageFilecuts;
