import React, { useCallback } from "react";
import { PublicAccessApprovalMode } from "@thenounproject/lingo-core";

import useUpdateSpacePublicAccess from "@redux/actions/spaces/useUpdateSpacePublicAccess";
import useShowModal from "@redux/actions/useModals";
import { useSelectSpace } from "@selectors/entities/spaces";
import ConfigureRequestsModalContent from "./ConfigureRequestsModalContent";

type Props = {
  spaceId: number;
};
const ConfigureSpaceRequestsModal: React.FC<Props> = ({ spaceId }) => {
  const space = useSelectSpace(spaceId);
  const { dismissModal } = useShowModal();
  const [updateSpacePublicAccess, { isProcessing, error }] = useUpdateSpacePublicAccess();

  const [approveMode, setApproveMode] = React.useState<PublicAccessApprovalMode>(
    space.downloadsRestrictedApproveMode
  );

  const canSubmit = React.useMemo(() => {
    return !isProcessing && approveMode !== space.downloadsRestrictedApproveMode;
  }, [approveMode, isProcessing, space.downloadsRestrictedApproveMode]);

  const onSubmit = useCallback(async () => {
    if (!canSubmit) return;
    const result = await updateSpacePublicAccess({
      spaceId: space.id,
      downloadsRestrictedApproveMode: approveMode,
    });
    if (result.isSuccess) {
      dismissModal();
    }
  }, [approveMode, canSubmit, dismissModal, space.id, updateSpacePublicAccess]);

  return (
    <ConfigureRequestsModalContent
      approvalSetting={approveMode}
      canSubmit={canSubmit}
      error={error}
      onChange={setApproveMode}
      onSubmit={onSubmit}
    />
  );
};

export default React.memo(ConfigureSpaceRequestsModal);
