import useCurrentUser from "@queries/useCurrentUser";
import useModals, { ModalTypes } from "@redux/actions/useModals";
import { buildURL, Button, Portal, Space } from "@thenounproject/lingo-core";
import React, { useCallback, useMemo } from "react";
import MobileSubNav from "../../navigation/MobileSubNav";
type Props = {
  portal: Portal;
  space: Space;
};
const PortalNavMobile: React.FC<Props> = ({ portal, space }) => {
  const { showModal } = useModals();

  const currentUser = useCurrentUser(),
    isLoggedIn = Boolean(currentUser?.user?.id);

  const canShare = !space.access.isPublic;
  const loginUrl = useMemo(() => {
    if (space.subdomain || space.domain) {
      return buildURL("/login", { space, isPublic: false });
    } else {
      return buildURL("/login", {});
    }
  }, [space]);

  const openShareModal = useCallback(() => {
    showModal(ModalTypes.SHARE_PORTAL, { portalId: portal.id });
  }, [portal.id, showModal]);

  function renderButton() {
    if (canShare) return <Button text="Share Portal" onClick={openShareModal} />;

    if (!isLoggedIn) return <Button buttonStyle="primary" text="Login" link={loginUrl} />;
  }

  return <MobileSubNav button={renderButton()} />;
};

export default PortalNavMobile;
