import React, { useCallback } from "react";

import usePortalUpsell, { AccessStatus } from "@hooks/usePortalUpsell";
import {
  Button,
  Checkbox,
  Flex,
  Portal,
  PortalPermission,
  Tooltip,
  Text,
  Box,
} from "@thenounproject/lingo-core";

import ConfigureRequestsToolbar from "./ConfigureRequestsToolbar";
import useUpdatePortal from "@redux/actions/portals/useUpdatePortal";
import useNotifications from "@redux/actions/useNotifications";

const PortalDownloadRequestsCheckbox: React.FC<{ portal: Portal }> = ({ portal }) => {
  const { showNotification } = useNotifications();

  const { accessStatus, openUpgradeModal } = usePortalUpsell({
    permission: PortalPermission.manageDownloadRequirements,
    portal,
  });
  const [updatePortal, { isProcessing }] = useUpdatePortal();

  const toggleDownloadRequests = useCallback(async () => {
    const res = await updatePortal({
      id: portal.id,
      downloadsRestricted: !portal.downloadsRestricted,
    });
    if (res.error) {
      showNotification({ level: "error", message: res.error.message });
    }
  }, [portal.downloadsRestricted, portal.id, showNotification, updatePortal]);

  if (accessStatus === AccessStatus.insufficientRole) return null;

  return (
    <Flex flexDirection="column">
      <Flex alignItems="center">
        <Flex data-tooltip-source="require-info-checkbox">
          <Checkbox
            isSelected={portal.downloadsRestricted}
            label={
              <Flex>
                <Text font="ui.small" color="grayDarkest">
                  Enable download requests
                </Text>
              </Flex>
            }
            disabled={isProcessing || accessStatus === AccessStatus.insufficientPlan}
            onClick={toggleDownloadRequests}
          />
        </Flex>
        {accessStatus === AccessStatus.insufficientPlan && (
          <Tooltip source="require-info-checkbox" direction="bottom">
            <Text color="white" font="ui.small">
              Download requests are not available on your current plan.{" "}
              <Button
                buttonStyle="tertiary"
                tintColor="white"
                fontStyle="ui.smallBold"
                text="View plans."
                onClick={openUpgradeModal}
              />
            </Text>
          </Tooltip>
        )}
        <Tooltip.InfoTip>
          <Text color="white" font="ui.small">
            Visitors can browse but will be required to enter an email address before downloading
            assets. This data will be available in insights.{" "}
            <Button
              buttonStyle="tertiary"
              tintColor="white"
              fontStyle="ui.smallBold"
              text="Learn more."
              link="https://help.lingoapp.com/en/articles/8307735-download-requests"
              newWindow
            />
          </Text>
        </Tooltip.InfoTip>
      </Flex>
      {portal.downloadsRestricted && (
        <Box mt="8px" ml="xs">
          <ConfigureRequestsToolbar portal={portal} />{" "}
        </Box>
      )}
    </Flex>
  );
};

export default PortalDownloadRequestsCheckbox;
