/**
 * Renders asset images within kit. Used for both thumbnail & asset detail.
 */

import React, {
  Fragment,
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  ComponentProps,
} from "react";
import styled, { useTheme } from "styled-components";
import { useInView } from "react-intersection-observer";
import {
  Box,
  Flex,
  Icon,
  AssetType,
  LottieAnimation,
  ActivityIndicator,
  ImageView,
} from "@thenounproject/lingo-core";

import AssetParent from "./AssetParent";
import PlayButtonOverlay from "./PlayButtonOverlay";

import { Inspectable } from "@constants/Inspector";
import getAssetBackground from "@helpers/getAssetBackground";

const PLAYABLE_ASSET_TYPES = [AssetType.gif, ...AssetType.videoTypes];

const BadgeContainer = styled(Flex).attrs({
  background: "white",
  height: "16px",
  width: "20px",
  position: "absolute",
  borderRadius: "default",
  justifyContent: "center",
  alignItems: "center",
})`
  top: 4px;
  left: 4px;
  box-shadow: 0px 0px 1px rgba(42, 42, 42, 0.25);
  pointer-events: none;
  overflow: hidden;
`;

const ImageContainer = styled.div<{ margin: string | number }>`
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 0.2s ease-in;
  margin: auto;
  margin: ${props => (props.margin ? props.margin : 0)};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

function splitDimensions(dimensions) {
  if (!dimensions) return {};
  return {
    width: parseInt(dimensions.split("x")[0]),
    height: parseInt(dimensions.split("x")[1]),
  };
}

type Props = {
  inspectable: Inspectable;
  isThumbnail: boolean;
  downloadButton?: React.ReactNode;
  onLoad?: ComponentProps<typeof ImageView>["onLoad"];
};

function AssetImage({ inspectable, isThumbnail, downloadButton, onLoad }: Props) {
  const theme = useTheme();
  const imageMargin = 8,
    parentPadding = imageMargin * 2;

  const assetDimensions = useMemo(
    () => splitDimensions(inspectable.asset.dimensions),
    [inspectable.asset.dimensions]
  );

  const assetParent = useRef(null);

  const [inViewRef, inView] = useInView({ triggerOnce: true });

  const [imageSrc, setImageSrc] = useState(null);
  const [renderHighResImage, setRenderHighResImage] = useState(false);
  const [highResImageLoaded, setHighResImageLoaded] = useState(false);

  const [minDimensions, setMinDimensions] = useState(assetDimensions);

  const assetImageStyle = getAssetImageStyle();

  const handleResize = useCallback(() => {
    if (isThumbnail) return;

    const parentDimensions = {
      width: assetParent.current?.offsetWidth,
      height: assetParent.current?.offsetHeight,
    };
    const minDimensions = {
      width: Math.min(assetDimensions.width, parentDimensions.width),
      height: Math.min(assetDimensions.height, parentDimensions.height),
    };
    setMinDimensions(minDimensions);
  }, [assetDimensions, isThumbnail]);

  useEffect(() => {
    if (inView && !renderHighResImage) setRenderHighResImage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    const { thumbnails, meta } = inspectable.asset;
    const imageSize =
      isThumbnail || (minDimensions.width <= 480 && minDimensions.height <= 480) ? "480" : "1232";
    setImageSrc(`${thumbnails?.[imageSize]}&p=${meta?.assetProcessing}`);
  }, [inspectable, minDimensions, isThumbnail]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [assetDimensions, isThumbnail, handleResize]);

  // Re-calculate dimensions if inspectable changes
  useEffect(() => {
    handleResize();
  }, [assetDimensions, handleResize]);

  /* Style getters / helper functions */
  /* -------------------------------------------------------------------------------*/

  function getPixelSize(backgroundSize) {
    if (backgroundSize !== "contain") {
      const dims = backgroundSize.split(" ");
      return { width: dims[0], height: dims[1] };
    }
    if (!assetParent.current) return { width: "0", height: "0" };

    const { width, height } = splitDimensions(inspectable.asset.dimensions),
      parentDim = {
        width: assetParent.current?.offsetWidth,
        height: assetParent.current?.offsetHeight,
      },
      scale = Math.min(
        (parentDim.width - parentPadding) / width,
        (parentDim.height - parentPadding) / height
      );

    return { width: `${width * scale}px`, height: `${height * scale}px` };
  }

  function getBackgroundSize() {
    if (!assetParent.current) return "contain";
    const parentDim = {
      width: assetParent.current?.offsetWidth,
      height: assetParent.current?.offsetHeight,
    };
    if (
      assetDimensions.width > parentDim.width - parentPadding ||
      assetDimensions.height > parentDim.height - parentPadding
    ) {
      return "contain";
    }
    return `${assetDimensions.width}px ${assetDimensions.height}px`;
  }

  function getAssetImageStyle() {
    let assetImageStyle = {
      backgroundSize: getBackgroundSize(),
      opacity: 1,
      minHeight: "inherit",
    };
    if (!isThumbnail) {
      let minHeight, displaySize;
      const dimensions = minDimensions;

      if (assetParent.current) {
        minHeight = assetParent.current?.offsetWidth - parentPadding;
        if (dimensions.width > 300) {
          if (assetParent.current?.offsetWidth > dimensions.width) {
            displaySize = `${dimensions.width}px`;
          } else {
            displaySize = `${assetParent.current?.offsetWidth - parentPadding}px`;
          }
        } else {
          displaySize = `${dimensions.width || 480}px`;
        }
      }
      assetImageStyle = Object.assign(assetImageStyle, {
        width: displaySize,
        height: displaySize,
        margin: `${imageMargin}px auto`,
        minHeight: minHeight,
      });
    }
    return assetImageStyle;
  }

  function getAssetParentStyle() {
    if (!inspectable.asset) return {};
    return getAssetBackground(inspectable.asset, theme);
  }

  /* Render functions */
  /* -------------------------------------------------------------------------------*/

  function renderBadge() {
    const isSketch = AssetType.sketchTypes.has(inspectable.asset.type);
    const isMotion = AssetType.motionTypes.has(inspectable.asset.type);
    if ((!isSketch && !isMotion) || !isThumbnail) {
      return null;
    }

    const iconId = isSketch ? "info.sketch" : "action.play";
    const width = 12;
    return (
      <BadgeContainer>
        <Icon {...{ width, iconId }} />
      </BadgeContainer>
    );
  }

  function renderDownloadButton() {
    return isThumbnail && downloadButton;
  }

  function renderSpinner() {
    if (highResImageLoaded) return;
    return (
      <Box position="absolute" top="0" left="0" m="8px" bottom="0" right="0">
        <ActivityIndicator center color="gray" />
      </Box>
    );
  }

  return (
    //Disable pointer events on images, since it interferes with gallery drag and drop
    <Box ref={inViewRef} style={{ pointerEvents: "none" }}>
      <AssetParent className="parent asset-parent" ref={assetParent} style={getAssetParentStyle()}>
        {inspectable.asset.type === AssetType.lottie && !isThumbnail ? (
          <LottieAnimation permalink={inspectable.asset.permalink} />
        ) : (
          <Fragment>
            {!isThumbnail && PLAYABLE_ASSET_TYPES.includes(inspectable.asset.type) && (
              <PlayButtonOverlay {...getPixelSize(getBackgroundSize())} />
            )}
            {renderHighResImage && (
              <ImageContainer
                style={assetImageStyle}
                title={inspectable.asset.name}
                margin={`${imageMargin}px`}>
                <ImageView
                  height="100%"
                  width="100%"
                  alt={inspectable.asset.name}
                  onLoad={res => {
                    onLoad?.(res);
                    setHighResImageLoaded(true);
                  }}
                  src={imageSrc}
                  srcFallback={inspectable.asset.thumbnailPlaceholders?.[isThumbnail ? 480 : 1232]}
                />
              </ImageContainer>
            )}
            {renderSpinner()}
            {renderBadge()}
            {renderDownloadButton()}
          </Fragment>
        )}
      </AssetParent>
    </Box>
  );
}

export default React.memo(AssetImage);
