import React from "react";
import { TabBar, Space, Box } from "@thenounproject/lingo-core";

import ModalHeader from "../../ModalHeader";
import InsightsAssetUsageTab from "./InsightsAssetUsageTab";
import InsightsAssetExportsTab from "./InsightsAssetExportsTab";
import InsightsAssetKitTab from "./InsightsAssetKitTab";
import InsightsAssetPortalTab from "./InsightsAssetPortalTab";
import { InsightsPageWrapper } from "../ReportElements";
import InsightsAssetDirectLinksTab from "./InsightsAssetDirectLinksTab";

type Props = {
  assetId: string;
  assetName: string;
  version?: number;
  space: Space;
};

enum Tab {
  usage = "usage",
  exports = "exports",
  kits = "kits",
  portals = "portals",
  directLinks = "directLinks",
}

const InsightsAssetModal = ({ assetId, assetName, version, space }: Props) => {
  const [selectedTab, setSelectedTab] = React.useState<string>(Tab.usage);
  const hasPortalsFeature = space?.features?.includes("portals");
  const hasDirectLinksFeature = space.features.includes("direct_links");

  function renderTab() {
    switch (selectedTab) {
      case Tab.usage:
        return <InsightsAssetUsageTab assetId={assetId} space={space} />;
      case Tab.exports:
        return <InsightsAssetExportsTab assetId={assetId} space={space} />;
      case Tab.kits:
        return <InsightsAssetKitTab assetId={assetId} space={space} version={version} />;
      case Tab.portals:
        return <InsightsAssetPortalTab assetId={assetId} space={space} />;
      case Tab.directLinks:
        return <InsightsAssetDirectLinksTab assetId={assetId} space={space} />;
      default:
        return null;
    }
  }

  return (
    <>
      <ModalHeader
        title={assetName || "Asset"}
        styleOverrides={{ borderBottom: "none" }}></ModalHeader>
      <Box
        width="100%"
        borderBottom="default"
        position="sticky"
        top="0"
        background="white"
        zIndex={"100"}>
        <TabBar>
          <TabBar.Item
            text="Downloads"
            selected={selectedTab === Tab.usage}
            onClick={() => setSelectedTab(Tab.usage)}
          />
          <TabBar.Item
            text="Exports"
            selected={selectedTab === Tab.exports}
            onClick={() => setSelectedTab(Tab.exports)}
          />
          <TabBar.Item
            text="Kits"
            selected={selectedTab === Tab.kits}
            onClick={() => setSelectedTab(Tab.kits)}
          />
          {hasPortalsFeature && (
            <TabBar.Item
              text="Portals"
              selected={selectedTab === Tab.portals}
              onClick={() => setSelectedTab(Tab.portals)}
            />
          )}
          {hasDirectLinksFeature && (
            <TabBar.Item
              text="Direct Links"
              selected={selectedTab === Tab.directLinks}
              onClick={() => setSelectedTab(Tab.directLinks)}
            />
          )}
        </TabBar>
      </Box>
      <InsightsPageWrapper>{renderTab()}</InsightsPageWrapper>
    </>
  );
};

export default React.memo(InsightsAssetModal);
