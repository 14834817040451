import React, { useMemo, useCallback, useState } from "react";
import {
  formatDate,
  Flex,
  Button,
  Space,
  Box,
  pluralize,
  TableCellText,
  TableCell,
} from "@thenounproject/lingo-core";

import InsightsIntervalPicker from "../InsightsIntervalPicker";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import usePortalKitPerformance, { PortalTableResult } from "../queries/usePortalKitPerformance";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";

type Props = {
  portalId: string;
  space: Space;
};

const InsightsPortalAssetsTab = ({ portalId, space }: Props) => {
  const [sort, setSort] = useState<string>("-total_views");
  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const { dateOperators } = useInsightsContext();

  const {
    data,
    isLoading: tableIsLoading,
    fetchNextPage,
    error: tableError,
  } = usePortalKitPerformance({
    spaceId: space.id,
    sort,
    dateOperators,
    portalId,
  });

  const { kits: tableData, total } = data || {};

  const hasMorePages = tableData?.length < total;

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "assets",
      sort,
      dateOperators,
      additionalParams: { ["portal_uuid"]: portalId },
    });
  }, [dateOperators, generateInsightsCSV, portalId, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Kit Performance",
      subtitle: "Breakdown of how specific kits in the portal were accessed.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"user-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<PortalTableResult>[] = [
      {
        sortKey: "name",
        name: "Kit Name",
        minWidth: 280,
        flexGrow: 1,
        renderer: i => {
          return <TableCell data={i} space={space} />;
        },
      },
      {
        sortKey: "total_views",
        name: "Total Views",
        minWidth: 160,
        renderer: i => (
          <Flex alignItems="center" flexWrap="wrap">
            <TableCellText font="ui.regular">
              {i.views.totalViews} {pluralize("view", i.views.totalViews)}
            </TableCellText>
            <TableCellText font="ui.small" color="grayDarker" ml="xs">
              {`/ ${i.views.uniquePeople} ${pluralize("user", i.views.uniquePeople)}`}
            </TableCellText>
          </Flex>
        ),
      },
      {
        sortKey: "member_views",
        name: "User Views",
        minWidth: 160,
        renderer: i => (
          <Flex alignItems="center" flexWrap="wrap">
            <TableCellText font="ui.regular">
              {i.views.memberViews} {pluralize("view", i.views.memberViews)}
            </TableCellText>
            <TableCellText font="ui.small" color="grayDarker" ml="xs">
              {`/ ${i.views.uniqueMembers} ${pluralize("user", i.views.uniqueMembers)}`}
            </TableCellText>
          </Flex>
        ),
      },
      {
        sortKey: "guest_views",
        name: "Visitor Views",
        minWidth: 160,
        renderer: i => (
          <Flex alignItems="center" flexWrap="wrap">
            <TableCellText font="ui.regular">
              {i.views.guestViews} {pluralize("view", i.views.guestViews)}
            </TableCellText>
            <TableCellText font="ui.small" color="grayDarker" ml="xs">
              {`/ ${i.views.uniqueGuests} ${pluralize("user", i.views.uniqueGuests)}`}
            </TableCellText>
          </Flex>
        ),
      },
      {
        sortKey: "privacy",
        name: "Privacy",
        minWidth: 120,
        renderer: i => (
          <TableCellText>{`${i.privacy.charAt(0).toUpperCase() + i.privacy.slice(1)}`}</TableCellText>
        ),
      },
      {
        name: "Date Created",
        minWidth: 160,
        renderer: i => <TableCellText>{formatDate(i.dateAdded, "MMM d, yyyy")}</TableCellText>,
      },
      {
        name: "Last Updated",
        minWidth: 160,
        renderer: i => <TableCellText>{formatDate(i.dateUpdated, "MMM d, yyyy")}</TableCellText>,
      },
    ];
    return cols;
  }, [space]);

  return (
    <Box mt="l">
      <DataTable<PortalTableResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </Box>
  );
};

export default InsightsPortalAssetsTab;
