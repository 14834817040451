import React from "react";
import styled from "styled-components";
import { Asset } from "@thenounproject/lingo-core";

import AssetGeneric from "./AssetGeneric";

const AudioPlayer = styled.audio`
  display: block;
  max-width: 500px;
  width: 100%;
  margin: 0px auto;
`;

type Props = {
  asset: Asset;
  isThumbnail: boolean;
  downloadButton: React.ReactNode;
};

const AssetAudio: React.FC<Props> = ({ asset, isThumbnail, downloadButton }) => {
  return (
    <>
      {isThumbnail ? (
        <AssetGeneric asset={asset} isThumbnail={isThumbnail} downloadButton={downloadButton} />
      ) : (
        <AudioPlayer src={asset.permalink} controls preload="none" controlsList="nodownload">
          Your browser does not support the audio tag.
        </AudioPlayer>
      )}
    </>
  );
};

export default AssetAudio;
