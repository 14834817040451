import { SelectionType } from "@contexts/SelectedItemsContext";
import { type Asset } from "@thenounproject/lingo-core";

type SelectionAction = ({ type, ids }: { type: SelectionType; ids: string[] }) => void;

function getNewIndex(currentIndex: number, key: string, maxLength: number): number | null {
  const newIndex = key === "ArrowUp" ? currentIndex - 1 : currentIndex + 1;
  if (newIndex < 0 || newIndex >= maxLength) return null;
  return newIndex;
}

/**
 * Handles selection change when shift is NOT also pressed
 */
function handleSimpleArrowSelection(
  key: string,
  selectedItems: string[],
  allItems: Asset[],
  setSelectedItems: SelectionAction
) {
  const lastSelectedId = selectedItems[selectedItems.length - 1];
  const lastSelectedIndex = allItems.findIndex(item => item.id === lastSelectedId);

  const newIndex = getNewIndex(lastSelectedIndex, key, allItems.length);
  if (newIndex === null) return;

  const newItemId = allItems[newIndex].id;
  setSelectedItems({ type: "assetLibrary", ids: [newItemId] });
}

/**
 * Handles selection update when shift is also pressed
 */
function handleShiftArrowSelection(
  key: string,
  selectedItems: string[],
  allItems: Asset[],
  setSelectedItems: SelectionAction,
  deselectItems: SelectionAction
) {
  const lastSelectedId = selectedItems[selectedItems.length - 1];
  const lastSelectedIndex = allItems.findIndex(item => item.id === lastSelectedId);

  const newIndex = getNewIndex(lastSelectedIndex, key, allItems.length);
  if (newIndex === null) return;

  const newItemId = allItems[newIndex].id;
  const newSelection = new Set(selectedItems);

  if (newSelection.has(newItemId)) {
    const itemToDeselectId =
      key === "ArrowUp" ? allItems[newIndex + 1]?.id : allItems[newIndex - 1]?.id;
    if (itemToDeselectId) {
      newSelection.delete(itemToDeselectId);
      deselectItems({ type: "assetLibrary", ids: [itemToDeselectId] });
    }
  } else {
    newSelection.add(newItemId);
    setSelectedItems({ type: "assetLibrary", ids: Array.from(newSelection) });
  }
}

export function handleArrowSelection(
  event: KeyboardEvent,
  selectedItems: string[],
  allItems: Asset[],
  setSelectedItems: SelectionAction,
  deselectItems: SelectionAction
) {
  const key = event.key;

  if (!["ArrowUp", "ArrowDown"].includes(key)) return;

  if (event.shiftKey) {
    handleShiftArrowSelection(key, selectedItems, allItems, setSelectedItems, deselectItems);
  } else {
    handleSimpleArrowSelection(key, selectedItems, allItems, setSelectedItems);
  }
}
