import { API, PublicAccessApprovalMode } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type updateSpacePublicAccessInput = {
  spaceId: number;
  downloadsRestricted?: boolean;
  downloadsRestrictedApproveMode?: PublicAccessApprovalMode;
};

const [useUpdateSpacePublicAccess, updateSpacePublicAccess] = createAsyncAction(
  "spaces/updateSpacePublicAccess",
  async ({
    spaceId,
    downloadsRestricted,
    downloadsRestrictedApproveMode,
  }: updateSpacePublicAccessInput) => {
    const data = {
      downloads_restricted: downloadsRestricted,
      downloads_restricted_approve_mode: downloadsRestrictedApproveMode,
    };
    Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);
    return await API.call<number>({
      endpoint: `spaces/${spaceId}`,
      method: "PUT",
      entity: API.Entity.space,
      data,
    });
  }
);

export default useUpdateSpacePublicAccess;
export { updateSpacePublicAccess };
