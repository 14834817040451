/**
 * Select a role to invite people to the space as.
 *
 * This component manages the selected role, inpuded emails, selected kits and the note
 * to send as invitiations.
 */

import React, { useState, useCallback, useMemo, ComponentProps } from "react";
import { Text, OptionCard, Space, TokenInputToken } from "@thenounproject/lingo-core";

import InviteMembers from "./InviteMembers";
import InviteAdmins from "./InviteAdmins";
import InviteLimitedMembers from "./InviteLimitedMembers";
import ModalBody from "../../ModalBody";
import useSendInvitations from "@redux/actions/invitations/useSendInvitations";
import useNotifications from "@actions/useNotifications";

import useShowModal from "@redux/actions/useModals";
import useKits from "@actions/kits/useKits";
import usePortals from "@redux/actions/portals/usePortals";
import useSpacePlan from "@redux/actions/billing/useSpacePlan";

type Props = {
  space: Space;
  prefilledEmails?: string[];
};

function InviteByEmail({ space, prefilledEmails = [] }: Props) {
  const hasPortalsFeature = space.features.includes("portals");
  const { data } = useKits({ spaceId: space.id });
  const { kits = [] } = data || {};
  const { data: portalsData } = usePortals({ spaceId: space.id }, { skip: !hasPortalsFeature });
  const { data: spacePlanData } = useSpacePlan({ spaceId: space.id }) || {};
  const { plan } = spacePlanData || {};
  const { portals = [] } = portalsData || {};
  const isTrial = plan?.planIdentifier.startsWith("trial");
  const hasEditorQuota = !!plan?.usage.editors.quota;

  const [selectedRole, selectRole] = useState(null);
  const { dismissModal } = useShowModal();
  const { showNotification } = useNotifications();

  const [emails, setEmails] = useState(
      prefilledEmails.map(e => ({ id: e, value: e, isValid: true }))
    ),
    [selectedKitRoles, setSelectedKitRoles] = useState({}),
    [selectedPortals, setSelectedPortals] = useState([]),
    [note, setNote] = useState(""),
    [error, setError] = useState(null);

  const [sendInvitations, { isProcessing }] = useSendInvitations();

  const spotsTaken = space.counts.users + space.counts.invitations,
    spotsAvailable = space.maxUsers === null ? Infinity : space.maxUsers - spotsTaken,
    tooManyInvitations = spotsAvailable - emails.length < 0;

  const validatedEmails: TokenInputToken[] = useMemo(() => {
    return emails.reduce((res, e, idx) => {
      let validation = {};
      if (idx >= spotsAvailable) {
        validation = { isValid: false, invalidReason: "too many emails entered" };
      }
      res.push({ ...e, ...validation });
      return res;
    }, []);
  }, [emails, spotsAvailable]);

  const _sendInvitations = useCallback(async () => {
    const res = await sendInvitations({
      spaceId: space.id,
      emails: validatedEmails.filter(e => e.isValid).map(e => e.value),
      role: selectedRole,
      kitRoles: selectedKitRoles,
      note,
      portals: hasPortalsFeature && selectedRole === "limited_member" ? selectedPortals : null,
    });
    if (res.error) {
      setError(res.error.message);
    } else {
      const { invitations } = res.response.result;
      const firstError = invitations.find(r => r.error);
      if (firstError) {
        const sentEmails = new Set(
          // TODO: We may need to type APIResultList with a success and error type.
          invitations.map(r => (r.result as any)?.email?.toLowerCase()).filter(e => e)
        );
        setError(firstError.error.message);
        const remainingEmails = emails.filter(e => !sentEmails.has(e.value.toLowerCase()));
        setEmails(remainingEmails);
      } else {
        showNotification({ message: "Invitations sent" });
        dismissModal();
      }
    }
  }, [
    sendInvitations,
    space.id,
    validatedEmails,
    selectedRole,
    selectedKitRoles,
    note,
    hasPortalsFeature,
    selectedPortals,
    emails,
    showNotification,
    dismissModal,
  ]);

  const addEmails = useCallback(
      (email: TokenInputToken[]) => {
        setEmails([...emails, ...email]);
      },
      [emails]
    ),
    removeEmail = useCallback(
      (idx: number) => {
        const newEmails = [...emails];
        newEmails.splice(idx, 1);
        setEmails(newEmails);
      },
      [emails]
    ),
    selectKitRoles = useCallback(
      (kitIds: string[], role: string) => {
        const newKits = { ...selectedKitRoles };
        kitIds.forEach(kId => (role === "revoked" ? delete newKits[kId] : (newKits[kId] = role)));
        setSelectedKitRoles(newKits);
      },
      [selectedKitRoles]
    ),
    selectPortalKitRoles = useCallback(
      (kitIds: string[], role: string) => {
        const newKits = { ...selectedKitRoles };
        // Only need to send kitId if user has edit permission
        kitIds.forEach(kId => (role === "guest" ? delete newKits[kId] : (newKits[kId] = role)));
        setSelectedKitRoles(newKits);
      },
      [selectedKitRoles]
    );
  const selectPortal = useCallback((uuidOrUuids: string | string[]) => {
      if (Array.isArray(uuidOrUuids)) {
        // If an array is passed, set all portals directly
        if (uuidOrUuids.length === 0) {
          setSelectedPortals([]); // Deselect all
        } else {
          setSelectedPortals(uuidOrUuids); // Select all
        }
      } else {
        // Otherwise, toggle individual selection
        setSelectedPortals(prevSelected =>
          prevSelected.includes(uuidOrUuids)
            ? prevSelected.filter(id => id !== uuidOrUuids)
            : [...prevSelected, uuidOrUuids]
        );
      }
    }, []),
    setDefaultKitRole = useCallback((kitIds: string[], role: string) => {
      setSelectedKitRoles(kitIds.reduce((acc, curr) => ((acc[curr] = role), acc), {}));
    }, []);

  const _selectedKitRoles = useMemo(
    () =>
      Object.keys(selectedKitRoles).reduce((acc, k) => {
        acc[k] = [selectedKitRoles[k]];
        return acc;
      }, {}),
    [selectedKitRoles]
  );

  const billingNotice = useMemo(() => {
    switch (selectedRole) {
      case "admin":
        return "Inviting additional Admin will incur billing charges for new editor seats.";
      case "member":
        return "Inviting additional Content Managers will incur billing charges for new editor seats.";
      case "limited_member":
        return "Inviting additional Members will incur billing charges for new editor seats, if they are given edit access to one or more kits.";
    }
  }, [selectedRole]);

  type FormProps = ComponentProps<typeof InviteMembers> &
    ComponentProps<typeof InviteAdmins> &
    ComponentProps<typeof InviteLimitedMembers>;
  const formProps: FormProps = {
    space,
    error,
    processing: isProcessing,
    emails: validatedEmails,
    kits,
    portals,
    selectedKitRoles: _selectedKitRoles,
    setDefaultKitRole,
    note,
    onNoteChanged: setNote,
    addEmails,
    removeEmail,
    selectKitRoles,
    sendInvitations: _sendInvitations,
    tooManyInvitations,
    billingNotice: !plan || hasEditorQuota || isTrial ? null : billingNotice,
  };

  function renderRoles() {
    const hasContentManagerRole = space.features.includes("content_managers");
    return (
      <ModalBody>
        <Text as="label" mb="m">
          Choose user role
        </Text>

        <OptionCard
          title="Admins"
          detail="Admins can access all kits and manage user permissions. They can also access space settings and insights."
          cardStyle="disclosure"
          onClick={() => selectRole("admin")}
        />
        <OptionCard
          title={hasContentManagerRole ? "Content Manager" : "Members"}
          detail={
            hasContentManagerRole
              ? "Content Managers can access and edit all content. They can also share kits with existing team members."
              : "Members can access all Kits. They can only edit Kits they are allowed to by an Admin or Owner."
          }
          cardStyle="disclosure"
          onClick={() => selectRole("member")}
        />
        <OptionCard
          title={hasContentManagerRole ? "Members" : "Limited Members"}
          detail={
            hasContentManagerRole
              ? "Members can only access or edit Kits they are allowed to by an Admin or Manager."
              : "Limited members can only access or edit Kits they are allowed to by an Admin or Owner."
          }
          cardStyle="disclosure"
          onClick={() => selectRole("limited_member")}
        />
      </ModalBody>
    );
  }

  switch (selectedRole) {
    case "admin":
      return <InviteAdmins {...formProps} />;
    case "member":
      return <InviteMembers {...formProps} />;
    case "limited_member":
      return (
        <InviteLimitedMembers
          selectedPortals={selectedPortals}
          selectPortal={selectPortal}
          selectPortalKitRoles={selectPortalKitRoles}
          {...formProps}
        />
      );
    default:
      return renderRoles();
  }
}

export default InviteByEmail;
