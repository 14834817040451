/**
 * Component for displaying a figma search result
 */

import React, { useCallback, useMemo } from "react";
import { Icon, Flex, Text, Button } from "@thenounproject/lingo-core";
import { figmaNodeIconMap } from "@helpers/figma";

type Props = {
  onSelect: (node_id: string) => void;
  selectedNodes: string[];
  data: FigmaNode;
};

const parentBoxProps = {
  width: "100%",
  mb: "s",
};

const childBoxProps = {
  width: "100%",
  px: "m",
  justifyContent: "space-between",
  alignItems: "center",
  height: "56px",
  borderRadius: "default",
  border: "1px solid gray",
};

const FigmaSearchResult: React.FC<Props> = ({
  onSelect,
  selectedNodes,
  data: { type, name, node_id },
}) => {
  /**
   * Memoized variables
   */
  const selected = useMemo(() => selectedNodes.includes(node_id), [selectedNodes, node_id]);

  const checkBoxIcon = useMemo(() => {
    return selected ? "action.checkbox-checked" : "action.checkbox-unchecked";
  }, [selected]);

  /**
   * Memoized callback functions
   */
  const _onSelect = useCallback(() => {
    onSelect(node_id);
  }, [node_id, onSelect]);

  return (
    <Flex {...parentBoxProps}>
      <Flex {...childBoxProps}>
        <Flex alignItems="center">
          <Icon iconId={figmaNodeIconMap[type]} size="24" />
          <Text pl="xs" maxWidth="260px" truncate>
            {name}
          </Text>
        </Flex>
        <Flex flexShrink="0">
          <Button onClick={_onSelect} buttonStyle="tertiary" icon={checkBoxIcon} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FigmaSearchResult;
