import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  assetId: string;
  version?: number;
};

export type AssetDirectLinksResult = {
  directLinkName: string;
  totalRequests: number;
  totalBandwidth: number;
};

type DirectLinkTableData = {
  items: AssetDirectLinksResult[];
  total: number;
};

const [useAssetDirectLinkUsage, fetchAssetDirectLinkUsage] = createQueryAction<
  Args,
  DirectLinkTableData
>(
  {
    entity: "analytics",
    action: "queryAssetKitUsage",
    denormalize: false,
    pagingKey: "rows",
  },
  async ({ args, paging }) => {
    const reports = {
      asset_direct_link_usage: {
        id: "direct_links",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        asset_uuid: args.assetId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (
      await fetchAnalytics<{ asset_direct_link_usage: DirectLinkTableData }>(args.spaceId, reports)
    ).assetDirectLinkUsage;
  }
);

export default useAssetDirectLinkUsage;
export { fetchAssetDirectLinkUsage };
