import React, { useState, Fragment, useCallback } from "react";
import { Notice, Space, User, Invitation } from "@thenounproject/lingo-core";

import ModalHeader from "../../../ModalHeader";
import ModalFooter from "../../../ModalFooter";
import useNotifications from "@actions/useNotifications";
import useDeleteSpaceMembers from "@redux/actions/spaceMembers/useDeleteSpaceMembers";
import useDeleteInvitations from "@redux/actions/invitations/useDeleteInvitations";
import useShowModal from "@redux/actions/useModals";

type Props = {
  space: Space;
  users?: User[];
  invitations?: Invitation[];
};

export function DeleteSpaceUserModal({ space, users, invitations }: Props) {
  const { dismissModal } = useShowModal();
  const { showNotification } = useNotifications();

  const [deleteSpaceMembers] = useDeleteSpaceMembers();
  const [deleteInvitations] = useDeleteInvitations();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const isMultiple = (users || invitations).length > 1;

  const onRequestCompleted = useCallback(
    (result, message) => {
      const { error: responseError } = result;
      setProcessing(false);
      if (responseError) {
        setError(responseError.message);
      } else {
        showNotification({ message, level: "info" });
        dismissModal();
      }
    },
    [dismissModal, showNotification]
  );

  const deleteSpaceUsers = useCallback(async () => {
    setError(null);
    setProcessing(true);
    const result = await deleteSpaceMembers({
      userIds: users.map(user => user.id),
      spaceId: space.id,
    });
    onRequestCompleted(result, isMultiple ? "Users removed." : "User removed.");
  }, [deleteSpaceMembers, isMultiple, onRequestCompleted, space.id, users]);

  const deleteSpaceInvitations = useCallback(async () => {
    setError(null);
    setProcessing(true);
    const result = await deleteInvitations({
      invitations: invitations.map(i => i.id),
      spaceId: space.id,
    });
    onRequestCompleted(result, isMultiple ? "Invitations revoked." : "Invitation revoked.");
  }, [deleteInvitations, invitations, isMultiple, onRequestCompleted, space.id]);

  function renderDeleteUserConfirmation() {
    return (
      <Fragment>
        <ModalHeader
          title={
            isMultiple ? `Remove ${users.length} users?` : `Remove ${users[0].name}, are you sure?`
          }
          message={`${
            isMultiple ? `The ${users.length} users you selected` : users[0].name
          } will be removed from ${space.name} and all of its kits.`}
          styleOverrides={{ borderBottom: "none", mb: "m" }}>
          {error && <Notice mx="xxl" mt="m" noticeStyle="error" message={error} />}
        </ModalHeader>
        <ModalFooter
          primary={{
            disabled: processing,
            onClick: deleteSpaceUsers,
            text: processing ? "Removing..." : "Yes, remove",
          }}
        />
      </Fragment>
    );
  }

  function renderDeleteInvitationConfirmation() {
    return (
      <Fragment>
        <ModalHeader
          title={
            isMultiple
              ? `Revoke ${invitations.length} invitations?`
              : `Revoke invitation for ${invitations[0].email}, are you sure?`
          }
          message={
            isMultiple
              ? "The invitations will no longer be able to be accepted."
              : "They will no longer be able to accept this invitation."
          }
          styleOverrides={{ borderBottom: "none", mb: "m" }}>
          {error && <Notice mx="xxl" mt="m" noticeStyle="error" message={error} />}
        </ModalHeader>
        <ModalFooter
          primary={{
            disabled: processing,
            onClick: deleteSpaceInvitations,
            text: processing ? "Revoking..." : "Yes, revoke",
          }}
        />
      </Fragment>
    );
  }

  return users ? renderDeleteUserConfirmation() : renderDeleteInvitationConfirmation();
}

export default DeleteSpaceUserModal;
