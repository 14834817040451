/* eslint-disable complexity */
/**
 * Kit section reducer
 */

import _merge from "lodash/merge";
import _remove from "lodash/remove";
import _cloneDeep from "lodash/cloneDeep";
import _union from "lodash/union";
import { HeadingItem, Item, ItemType, Section } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";
import * as actionTypes from "@redux/legacy-actions/actionTypes";

import { updateItem } from "@redux/actions/items/useUpdateItem";
import { fetchKitOutline } from "@redux/actions/kitVersions/useKitOutline";
import { batchSaveItems } from "@redux/actions/items/useBatchSaveItems";
import { batchUpdateItemStatus } from "@redux/actions/items/useBatchUpdateItemStatus";
import { reorderSectionItems } from "@redux/actions/items/useReorderSectionItems";
import { deleteHeadingWithItems } from "@redux/actions/items/useDeleteHeadingWIthItems";
import { reorderHeading } from "@redux/actions/sections/useReorderHeading";
import { moveHeading } from "@redux/actions/sections/useMoveHeading";
import { duplicateHeading } from "@redux/actions/sections/useDuplicateHeading";
import { moveItems } from "@redux/actions/items/useMoveItems";
import { duplicateItems } from "@redux/actions/items/useDuplicateItems";

export default createEntityReducer<Section>(
  "sections",
  _queryBuilder => {
    // nothing to do here
  },
  objectBuilder => {
    objectBuilder
      .addCase(duplicateHeading.fulfilled, (state, action) => {
        const { toSectionId } = action.meta.arg;
        const { result, entities } = action.payload;
        const newHeadingId = result.items.find(id => entities.items[id].type === ItemType.heading);
        const newHeading = entities.items[newHeadingId];
        const target = state[`${toSectionId}-0`];
        if (target) {
          if (!target.headers) target.headers = [];
          target.headers.push(formatHeader(newHeading));
        }
      })
      .addCase(moveHeading.fulfilled, (state, action) => {
        const { fromSectionId, toSectionId, headingId } = action.meta.arg;
        const { result, entities } = action.payload;
        const newHeadingId = result.items.find(id => entities.items[id].type === ItemType.heading);
        const newHeading = entities.items[newHeadingId];
        const source = state[`${fromSectionId}-0`];
        const target = state[`${toSectionId}-0`];
        _remove(source.headers, h => h.id === headingId);
        if (target) {
          if (!target.headers) target.headers = [];
          target.headers.push(formatHeader(newHeading));
        }
      })
      .addCase(reorderHeading.fulfilled, (state, action) => {
        const { displayOrder, sectionId, headingId } = action.meta.arg;
        const updatedItem = action.payload.entities.items[`${headingId}-0`];
        const headers = state[`${sectionId}-0`].headers;
        const movedHeading = _remove(headers, h => h.id === updatedItem.id)[0];

        const [direction, targetId] = displayOrder.split(":");
        let spliceIndex = headers.findIndex(h => h.id === targetId);
        if (direction === "after") spliceIndex++;
        headers.splice(spliceIndex, 0, movedHeading);
      })
      .addCase(deleteHeadingWithItems.fulfilled, (state, action) => {
        const {
          entities: { items: deletedItems },
          result,
        } = action.payload;
        const { sectionId } = deletedItems[result.items[0]];
        const versionedSectionId = `${sectionId}-0`;
        const section = state[versionedSectionId];
        section.counts.items -= result.items.length;
        _remove(section.headers, header => Boolean(deletedItems[`${header.id}-${header.version}`]));
      })
      .addCase(moveItems.fulfilled, (state, action) => {
        const {
          entities,
          result: { items: movedItems },
        } = action.payload;
        const { fromSectionId, toSectionId } = action.meta.arg;

        const sourceSection = state[`${fromSectionId}-0`];
        const targetSection = state[`${toSectionId}-0`];

        const headers = movedItems
          .map(id => entities.items[id])
          .filter(i => i.type === ItemType.heading);

        if (sourceSection) {
          _remove(sourceSection.headers, h => movedItems.includes(`${h.id}-0`));
        }
        if (!targetSection.headers) targetSection.headers = [];
        targetSection.headers.push(...headers.map(formatHeader));
      })
      .addCase(duplicateItems.fulfilled, (state, action) => {
        const {
          entities,
          result: { items: movedItems },
        } = action.payload;
        const { toSectionId } = action.meta.arg;
        const targetSection = state[`${toSectionId}-0`];

        const headers = movedItems
          .map(id => entities.items[id])
          .filter(i => i.type === ItemType.heading);

        if (!targetSection.headers) targetSection.headers = [];
        targetSection.headers.push(...headers.map(formatHeader));
      })
      .addCase(reorderSectionItems.fulfilled, (state, action) => {
        const { sectionId } = action.meta.arg,
          { entities } = action.payload;
        // Update and reorder the headers
        const headers = state[`${sectionId}-0`].headers;
        if (!headers) return;
        headers.forEach(header => {
          const reorderedItem = entities.items[`${header.id}-0`];
          if (reorderedItem) {
            header.displayOrder = reorderedItem.displayOrder;
          } else return header;
        });
        headers.sort((a, b) => a.displayOrder - b.displayOrder);
      })
      .addCase(batchSaveItems.fulfilled, (state, action) => {
        // Batch save items can be used to create items like headings
        // We need to insert headings and update sectoun counts here
        const { newItems: newItemIds, insertIndex, entities } = action.payload;

        if (newItemIds.length === 0 || insertIndex === null) return state;

        const newItems = newItemIds.map(item => entities.items[`${item.uuid}-0`]).filter(Boolean);
        const newHeaders = newItems.filter(item => item?.type === ItemType.heading);
        // If all the saves failed, we woucl end up with an empty array.
        if (!newItems.length) return;
        const versionedSection = `${newItems[0].sectionId}-0`;
        const section = state[versionedSection];

        if (newHeaders.length) {
          // If we just created the section, it's possible it won't have headers which are
          // usually returned in the kit outline
          if (!section.headers) section.headers = [];
          section.headers.push(...newHeaders.map(formatHeader));
          section.headers.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
        }
        section.counts.items += newItems.length;
      })
      .addCase(batchUpdateItemStatus.fulfilled, (state, action) => {
        // Remove headings from the section
        const { entities, result } = action.payload;
        if (!entities.items) return state;
        const updatedHeadings = result.items
          .filter(i => i.success)
          .map(i => entities.items[i.result])
          .filter(i => i.type === ItemType.heading);

        updatedHeadings.forEach(item => {
          const versionedSection = `${item.sectionId}-${item.version}`,
            itemSection = state[versionedSection];
          // If header, remove from header arr
          if (item.status === "deleted" && itemSection.headers) {
            _remove(itemSection.headers, h => h.id === item.id);
          }
        });
      })

      .addCase(updateItem.fulfilled, (state, action) => {
        // Update the heading value in kitNav when a user edits
        const item = action.payload.entities.items[action.payload.result];
        if (item.type === ItemType.heading) {
          const versionedSection = `${item.sectionId}-${item.version}`,
            headingIndex = state[versionedSection].headers.findIndex(
              h => h.shortId === item.shortId
            );
          state[versionedSection].headers[headingIndex].name = item.data.content;
        }
      })
      .addCase(fetchKitOutline.fulfilled, (state, action) => {
        const sections = action.payload.entities.sections;
        if (!sections) return;
        _merge(state, sections);
        // Force update of headers
        Object.keys(sections).forEach(sectionId => {
          state[sectionId].headers = sections[sectionId].headers;
        });
      })
      .addCase(actionTypes.SORT_SECTION_ITEMS, (state, action: any) => {
        return _merge(state, action.sections);
      })
      .addDefaultCase((state, action: any) => {
        const sections =
          action?.response?.entities?.sections || action?.payload?.entities?.sections;

        if (sections) {
          return _merge(state, sections);
        }
        return state;
      });
  }
);

function formatHeader(data: Item): HeadingItem {
  return {
    id: data.id,
    shortId: data.shortId,
    name: data.data.content,
    version: data.version,
    displayOrder: data.displayOrder,
  };
}
