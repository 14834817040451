import React, { useMemo, useCallback } from "react";
import {
  formatDate,
  Flex,
  Button,
  Space,
  Box,
  ActivityIndicator,
  TableCellText,
  TableCell,
} from "@thenounproject/lingo-core";

import Chart from "../Chart";
import InsightsIntervalPicker from "../InsightsIntervalPicker";
import useFetchKitViews from "../queries/useKitViewIntervals";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import useKitViewsByUser, { KitUserResult } from "../queries/useKitViewsByUser";
import { LoadFailure } from "../ReportElements";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";

import { useInsightsContext } from "@contexts/InsightsProvider";
import { formatInsightsDate } from "../utils";
import { getSpaceRoleDisplayName } from "../../../constants/displayUserRoles";

type Props = {
  kitId: string;
  space: Space;
};

const InsightsKitViewTab = ({ kitId, space }: Props) => {
  const [sort, setSort] = React.useState<string>("-last_viewed");

  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const { dateOperators } = useInsightsContext();

  const { data, error: dataFetchingError } = useFetchKitViews({
    spaceId: space.id,
    kitId,
    dateOperators,
  });
  const { result: kitViewData } = data || {};
  const {
    data: fetchedData,
    error: tableError,
    fetchNextPage,
    isLoading: tableIsLoading,
  } = useKitViewsByUser({
    spaceId: space.id,
    kitId,
    sort,
    dateOperators,
  });

  const { rows: tableData, total } = fetchedData ?? {};

  const hasMorePages = tableData?.length < total;

  const { kitViewLabels, kitViewMembers, kitViewVisitors } = useMemo(
    () =>
      (kitViewData || []).reduce(
        (acc, item, i) => {
          acc.kitViewLabels[i] = formatInsightsDate(item.date);
          acc.kitViewMembers[i] = item.memberViews;
          acc.kitViewVisitors[i] = item.guestViews;
          return acc;
        },
        { kitViewLabels: [], kitViewMembers: [], kitViewVisitors: [] }
      ),
    [kitViewData]
  );

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "user_kit_usage",
      sort,
      dateOperators,
      additionalParams: { ["kit_uuid"]: kitId },
    });
  }, [dateOperators, generateInsightsCSV, kitId, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Views by User",
      subtitle: "Which users and members viewed the kit.",
      tooltip:
        "Users include anyone joined to your space, Visitors include anyone not joined to your space.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"user-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<KitUserResult>[] = [
      {
        sortKey: "name",
        name: "Name",
        minWidth: 340,
        flexGrow: 1,
        renderer: u => {
          return <TableCell space={space} data={u} />;
        },
      },
      {
        sortKey: "role",
        name: "Role",
        minWidth: 160,
        renderer: u => <TableCellText>{getSpaceRoleDisplayName(u.role, space)}</TableCellText>,
      },
      {
        sortKey: "total_views",
        name: "Total Views",
        minWidth: 160,
        justify: "flex-end",
        renderer: u => <TableCellText>{u?.totalViews}</TableCellText>,
      },
      {
        sortKey: "last_viewed",
        name: "Last Viewed",
        minWidth: 160,
        renderer: u => <TableCellText>{formatDate(u.lastViewed, "MMM d, yyyy")}</TableCellText>,
      },
    ];
    return cols;
  }, [space]);

  if (dataFetchingError) return <LoadFailure />;
  if (!kitViewData) {
    return (
      <Box height="50vh">
        <ActivityIndicator size="large" center />
      </Box>
    );
  }

  return (
    <>
      <Box px="xl" pt="l">
        <Chart
          title="Kit Views"
          subtitle="The number of times your Kits were viewed by users and visitors."
          tooltip="Users include anyone joined to your space, Visitors include anyone not joined to your space."
          error={!!dataFetchingError}
          headerExtra={<InsightsIntervalPicker id="kits-modal" />}
          labels={kitViewLabels}
          values={[
            {
              label: "Views by Users",
              data: kitViewMembers,
              color: "primaryColorDark",
            },
            {
              label: "Views by Visitors",
              data: kitViewVisitors,
              color: "primaryColorLight",
            },
          ]}
          Ylabel="# of Views"
        />
      </Box>
      <DataTable<KitUserResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </>
  );
};

export default InsightsKitViewTab;
