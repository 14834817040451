import React from "react";
import {
  Text,
  Button,
  Box,
  useClipboard,
  Notice,
  ActivityIndicator,
} from "@thenounproject/lingo-core";

import useFetchRecoveryCodes from "@redux/actions/auth/useFetchRecoveryCodes";

import ModalHeader from "../../ModalHeader";
import ModalBody from "../../ModalBody";
import { CodesContainer } from "./TFACodesModal";

const TFASuccessModal = () => {
  const { status, codes, error } = useFetchRecoveryCodes();

  const { onCopy, hasCopied } = useClipboard(codes?.map(code => code.code).join(" "));

  return (
    <>
      <ModalHeader title="Two-factor authentication" />
      <ModalBody>
        <Text font="ui.regularBold" mb="4">
          Two-factor authentication is enabled!
        </Text>
        <Text mb="m">
          If you lose access to your authenticator app, you can use these recovery codes to access
          your account. Each code can only be used once!
        </Text>
        <Text>
          If you lose both, you’ll be locked out of your account so store them somewhere safe.
        </Text>
        <Box mt="m">
          {error ? (
            <Notice noticeStyle="error" message={error.message} />
          ) : (
            <>
              {status === "loading" ? (
                <ActivityIndicator size="small" />
              ) : (
                <>
                  <CodesContainer>
                    {codes?.map(codeObject => {
                      return (
                        <Text
                          key={codeObject.code}
                          font="ui.regularSemiBold"
                          textDecoration={codeObject.dateUsed ? "line-through" : "none"}>
                          {codeObject.code}
                        </Text>
                      );
                    })}
                  </CodesContainer>
                  <Button text={hasCopied ? "Copied" : "Copy codes"} onClick={onCopy} />
                </>
              )}
            </>
          )}
        </Box>
      </ModalBody>
    </>
  );
};

export default TFASuccessModal;
