/* eslint-disable @typescript-eslint/no-floating-promises */

/**
 *
 * Generic confirmation modal.
 *
 * Note - onConfirm is a function passed in via Redux state, which is not really desirable, since
 * it cannot be serialized. Leaving it this way as an experiment, but if this kind of generic modal
 * is desirable long-term we should probably move the modals away from the state-based props
 * transfer to a portal-based system, where the component that opens the modal manages the modal
 * as a child.
 */

import React, { useState, useCallback, Fragment, ReactNode } from "react";
import { Notice, LingoError, ErrorCode } from "@thenounproject/lingo-core";

import ModalHeader from "./ModalHeader";
import ModalFooter from "./ModalFooter";

import useNotifications, { NotificationArgs } from "@actions/useNotifications";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

type Props = {
  title: string;
  message?: string | ReactNode;
  notice?: React.ComponentProps<typeof Notice>;
  buttonText: string;
  buttonProcessingText: string;
  onConfirm: (() => Promise<any>) | (() => void);
  successNotification?: NotificationArgs;
  /**
   * If an error 444 is returned, the modal will show a secondary confirmation modal with the following props
   */
  secondaryConfirmation?: Pick<
    Props,
    "title" | "onConfirm" | "buttonText" | "buttonProcessingText"
  >;
  secondaryButtonText?: string;
};

function ConfirmationModal({
  title,
  message,
  notice,
  buttonText,
  onConfirm,
  buttonProcessingText,
  successNotification,
  secondaryConfirmation,
  secondaryButtonText,
}: Props) {
  const { showNotification } = useNotifications();
  const { showModal, dismissModal } = useShowModal();
  const [processing, setProcessing] = useState(false),
    [error, setError] = useState(null);

  const confirm = useCallback(() => {
    const confirmationResult = onConfirm?.();

    if (!confirmationResult) {
      return dismissModal();
    }

    setError(null);
    setProcessing(true);

    confirmationResult.then(response => {
      const responseError = response?.error as LingoError;
      setProcessing(false);

      if (responseError?.code === ErrorCode.needsAdditionalConfirmation && secondaryConfirmation) {
        dismissModal();
        return showModal(ModalTypes.CONFIRMATION, {
          ...secondaryConfirmation,
          message: responseError.message,
        });
      }
      if (responseError) {
        setError(responseError.message || "Oops! That didn’t work.");
      } else {
        if (successNotification) {
          showNotification({ ...successNotification });
        }
        dismissModal();
      }
    });
  }, [
    onConfirm,
    dismissModal,
    secondaryConfirmation,
    showModal,
    successNotification,
    showNotification,
  ]);

  return (
    <Fragment>
      <ModalHeader
        title={title}
        message={message}
        styleOverrides={{ mb: "m", borderBottom: "none" }}>
        {error && <Notice textAlign="left" mt="m" mx="xxl" noticeStyle="error" message={error} />}
        {notice && <Notice textAlign="left" mt="m" mx="xxl" noticeStyle="info" {...notice} />}
      </ModalHeader>
      <ModalFooter
        primary={{
          disabled: processing,
          text: processing ? buttonProcessingText : buttonText,
          onClick: confirm,
        }}
        secondary={
          secondaryButtonText
            ? {
                text: secondaryButtonText,
                onClick: dismissModal,
              }
            : undefined
        }
      />
    </Fragment>
  );
}

export default ConfirmationModal;
