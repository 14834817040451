import { createAsyncAction } from "../actionCreators";
import { API, Space, CustomField } from "@thenounproject/lingo-core";

type Args = {
  spaceId: Space["id"];
  field: CustomField;
  confirmDelete?: boolean;
};

const [useDeleteCustomField, deleteCustomField] = createAsyncAction(
  "fields/deleteCustomField",
  async ({ spaceId, field, confirmDelete = false }: Args) => {
    return await API.call<CustomField>({
      endpoint: `/spaces/${spaceId}/fields/${field.id}`,
      method: "DELETE",
      data: { ...field, confirm_delete: confirmDelete },
      entity: API.Entity.customField,
    });
  }
);

export default useDeleteCustomField;
export { deleteCustomField };
