import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  itemId: string;
};

export type AssetExportResult = {
  type: string;
  totalDownloads: number;
  dimensions: string;
};

type KitTableData = {
  rows: AssetExportResult[];
  total: number;
};

const [useAssetExports, fetchAssetExports] = createQueryAction<Args, KitTableData>(
  {
    entity: "analytics",
    action: "queryAssetExports",
    denormalize: false,
    pagingKey: "asset_exports",
  },
  async ({ args, paging }) => {
    const reports = {
      asset_exports: {
        id: "asset_exports",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        date_operators: args.dateOperators,
        item_uuid: args.itemId,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ asset_exports: KitTableData }>(args.spaceId, reports))
      .assetExports;
  }
);

export default useAssetExports;
export { fetchAssetExports };
