import React, { useCallback, useState, Fragment } from "react";
import {
  Input,
  Notice,
  Flex,
  Icon,
  Text,
  Box,
  Button,
  ImageView,
  Textarea,
  ActivityIndicator,
  Kit,
} from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import FilePicker from "../../FilePicker";
import styled from "styled-components";
import { loadFile } from "@helpers/files";
import useShowModal from "@redux/actions/useModals";
import useUpdateKit from "@redux/actions/kits/useUpdateKit";

type CoverPickerProps = {
  dashed?: boolean;
};

const KitCoverPicker = styled(Flex).attrs<CoverPickerProps>(props => {
  return {
    width: "100%",
    height: "200px",
    border: props.dashed ? "1px dashed grayLight" : undefined,
    borderRadius: "default",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
})<CoverPickerProps>``;

const KitCoverCopy = styled(Text).attrs({
  textAlign: "center",
  alignSelf: "stretch",
  color: "grayDarker",
})``;

type Props = {
  kit: Pick<Kit, "kitId" | "spaceId" | "name" | "description" | "images">;
};

const EditKitModal: React.FC<Props> = ({
  kit: {
    kitId,
    spaceId,
    name: kitName,
    description: kitDescription = "",
    images: { cover: kitCover = null },
  },
}) => {
  const [error, setError] = useState(null),
    [loadingCover, setLoadingCover] = useState(false),
    [newName, setNewName] = useState(kitName),
    [newDescription, setNewDescription] = useState(kitDescription),
    [newKitCover, setNewKitCover] = useState<string | ArrayBuffer>(kitCover),
    [kitCoverFile, setKitCoverFile] = useState();

  const { dismissModal } = useShowModal();
  const [updateKit, { isProcessing }] = useUpdateKit();

  const clearKitCover = useCallback(() => {
    setNewKitCover(null);
    setKitCoverFile(null);
  }, []);
  const valid = newName.trim().length > 0,
    removeCoverImage = kitCover && !newKitCover;

  const submit = useCallback(async () => {
    setError(null);
    const result = await updateKit({
      kitId,
      spaceId,
      name: newName,
      description: newDescription,
      coverImage: kitCoverFile,
      removeCoverImage,
    });
    if (result.error) setError(result.error.message || "Oops! That didn’t work.");
    else {
      dismissModal();
    }
  }, [
    dismissModal,
    updateKit,
    kitCoverFile,
    kitId,
    newDescription,
    newName,
    removeCoverImage,
    spaceId,
  ]);

  const onNewKitCover = useCallback(files => {
    if (!files.length) return;
    const file = files[0];
    setLoadingCover(true);
    loadFile(file)
      .then(data => {
        setNewKitCover(data);
        setLoadingCover(false);
        setKitCoverFile(file);
      })
      .catch(err => {
        setLoadingCover(false);
        setError(err.message);
      });
    return Promise.resolve(null);
  }, []);

  function renderCoverPicker() {
    return (
      <Box>
        <Text as="label" display="block" color="black" mb="xs">
          Cover Image
        </Text>
        <FilePicker onPickFiles={onNewKitCover}>
          {({ openNativeFilePicker, dragging, dropZoneProps }) => {
            if (loadingCover) {
              return (
                <KitCoverPicker>
                  <ActivityIndicator center />
                </KitCoverPicker>
              );
            } else if (newKitCover && !dragging) {
              return (
                <Fragment>
                  <KitCoverPicker onClick={openNativeFilePicker} {...dropZoneProps}>
                    <ImageView
                      src={newKitCover as string}
                      aspectRatio={63}
                      height="100%"
                      width="100%"
                      borderRadius="default"
                      overflow="hidden"
                      cover
                    />
                  </KitCoverPicker>
                  <Button
                    buttonStyle="tertiary"
                    text="Change"
                    size="small"
                    onClick={openNativeFilePicker}
                  />
                  <Button
                    buttonStyle="tertiary"
                    text="Remove"
                    size="small"
                    onClick={clearKitCover}
                    mx="m"
                  />
                </Fragment>
              );
            } else {
              return (
                <Fragment>
                  <KitCoverPicker dashed={true} onClick={openNativeFilePicker} {...dropZoneProps}>
                    <Icon iconId="plus" width="32" height="32" fill="grayDarker" mb="m" />
                    <KitCoverCopy font="ui.smallBold">
                      Drag & drop or click to add a cover
                    </KitCoverCopy>
                    <KitCoverCopy font="ui.small">Recommended size: 640 x 400</KitCoverCopy>
                  </KitCoverPicker>
                  <Button
                    buttonStyle="tertiary"
                    size="small"
                    text="Change"
                    onClick={openNativeFilePicker}
                  />
                </Fragment>
              );
            }
          }}
        </FilePicker>
      </Box>
    );
  }

  return (
    <Fragment>
      <ModalHeader title="Edit Kit" />
      <ModalBody px="xl">
        <Input
          id="kit-name-input"
          value={newName}
          label="Name"
          onChange={e => setNewName(e.target.value)}
          onFocus={e => {
            e.target.select();
          }}
          onSubmit={submit}
          placeholder="e.g. Style Guide, UI Library"
          autoFocus
        />
        <Textarea
          id="kit-description-input"
          value={newDescription}
          label="Description"
          onChange={e => setNewDescription(e.target.value)}
          onFocus={e => {
            e.target.select();
          }}
          placeholder="Add a description"
          my="l"
        />
        {renderCoverPicker()}
        {error && <Notice noticeStyle="error" message={error} />}
      </ModalBody>
      <ModalFooter
        primary={{
          id: "edit-kit-submit-button",
          text: isProcessing ? "Updating..." : "Update",
          onClick: submit,
          disabled: isProcessing || !valid,
        }}
        styleOverrides={{ px: "xl" }}
      />
    </Fragment>
  );
};

export default EditKitModal;
