import React, { Fragment, ReactNode, useCallback, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import {
  Icon,
  Text,
  Flex,
  Button,
  pluralize,
  Box,
  ActivityIndicator,
  usePrevious,
} from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useDismissUploads from "@actions/useDismissUploads";
import { useGetModals, useGetUploads } from "@selectors/getters";

const showUpload = keyframes`
  0% {
    margin-bottom: 0;
    max-height: 0;
    opacity: 0;
    top: 32px;
  }
  100% {
    max-height: 250px;
    opacity: 1;
    top: 0;
  }
`;

const hideUpload = keyframes`
  0% {
    max-height: 250px;
    opacity: 1;
    top: 0;
  }
  100% {
    margin-bottom: 0;
    max-height: 0;
    opacity: 0;
    top: 32px;
  }
`;

type AnimatingWrapperProps = {
  hide: boolean;
};

const AnimatingWrapper = styled(Box).attrs<AnimatingWrapperProps>({
  position: "relative",
  mb: "l",
  ml: "-200px",
  width: "400px",
})<AnimatingWrapperProps>`
  animation: ${props => (props.hide ? hideUpload : showUpload)} 0.25s ease-out forwards;
`;

const UploadContainer = styled(Flex).attrs({
  p: "m",
  color: "black",
  background: "grayLightest",
  borderRadius: "default",
  boxShadow: "panel",
  textAlign: "left",
  alignItems: "center",
})``;

const SuccessIcon = styled(Icon).attrs({
  iconId: "checkmark",
  fill: "success",
  size: 16,
})``;

const FailedIcon = styled(Icon).attrs({
  iconId: "error",
  fill: "error",
  size: 16,
})``;

const UploadBar = () => {
  const { showModal } = useShowModal();
  const dismissUploads = useDismissUploads();
  const uploadData = useGetUploads();
  const modals = useGetModals();
  const anyModalOpen = modals.length > 0;
  const dismissTimeout = useRef<NodeJS.Timeout>();
  const uploadModalOpen = modals.some(modal => modal.component === ModalTypes.UPLOAD_DETAILS);
  const uploadModalOpenPrevious = usePrevious(uploadModalOpen);

  const uploads = uploadData.recentUploads.map(id => uploadData.uploads[id]);
  const inProgress = uploads.filter(upload =>
      ["pending", "started"].includes(upload.upload.status)
    ),
    failed = uploads.filter(upload => upload.upload.status === "failed"),
    { status } = (inProgress[0] || failed[0] || uploads[0] || {}).upload ?? {};

  const showUploadDetails = useCallback(() => {
    showModal(ModalTypes.UPLOAD_DETAILS, {});
  }, [showModal]);

  const killDismissal = () => {
    clearTimeout(dismissTimeout.current);
    dismissTimeout.current = null;
  };

  const scheduleDismissal = useCallback(
    (timer: number) => {
      dismissTimeout.current = setTimeout(() => {
        dismissTimeout.current = null;
        dismissUploads();
      }, timer);
    },
    [dismissUploads]
  );

  const previousStatus = usePrevious(status);
  useEffect(() => {
    if (
      status === "started" &&
      !previousStatus &&
      !uploadModalOpen &&
      uploads.every(
        upload => !upload.upload.insertPosition.kitId && !upload.upload.insertPosition.portalId
      )
    ) {
      showUploadDetails();
    }
  }, [previousStatus, showUploadDetails, status, uploadModalOpen, uploads]);

  useEffect(() => {
    if (uploadModalOpen || status !== "success") {
      if (dismissTimeout.current) killDismissal();
      return;
    } else if (!dismissTimeout.current) {
      scheduleDismissal(uploadModalOpenPrevious ? 2000 : 8000);
    }
    return killDismissal;
  }, [scheduleDismissal, status, uploadModalOpen, uploadModalOpenPrevious]);

  useEffect(() => {
    if (inProgress.length === 0) return;

    const promptUser = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      return true;
    };
    window.addEventListener("beforeunload", promptUser);

    return () => window.removeEventListener("beforeunload", promptUser);
  }, [inProgress.length]);

  if (uploads.length === 0) return null;

  const renderContent = (icon: ReactNode, textNode: ReactNode, dismissButton: boolean) => {
    return (
      <AnimatingWrapper hide={anyModalOpen}>
        <UploadContainer>
          {icon}
          <Text px="s" font="ui.small" flex="1">
            {textNode}
          </Text>
          <Button
            buttonStyle="tertiary"
            size="small"
            onClick={showUploadDetails}
            text="View details"
          />
          {dismissButton && (
            <Button
              buttonStyle="tertiary"
              size="small"
              onClick={dismissUploads}
              icon="close"
              my="-xs"
              ml="m"
            />
          )}
        </UploadContainer>
      </AnimatingWrapper>
    );
  };

  switch (status) {
    case "started": {
      // If all of the pending uploads are colors, don't show the upload bar
      return renderContent(
        <ActivityIndicator size="small" />,
        <Fragment>
          Uploading {uploads.length} {pluralize("file", uploads.length)}
          <Text key="0" font="ui.small" color="grayDarker" as="span">
            - {inProgress.length} {pluralize("file", inProgress.length)} left
          </Text>
        </Fragment>,
        false
      );
    }
    case "failed": {
      const succeeded = uploads.length - failed.length;
      return renderContent(
        <FailedIcon />,
        `${succeeded} ${pluralize("file", succeeded)} uploaded. ${failed.length} failed to upload.`,
        true
      );
    }
    case "success":
      return renderContent(
        <SuccessIcon />,
        `${uploads.length} ${pluralize("file", uploads.length)} uploaded`,
        true
      );
    default:
      return null;
  }
};

export default UploadBar;
