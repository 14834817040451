import styled from "styled-components";
import { Box, BoxProps, Flex, utils, markdownStyle } from "@thenounproject/lingo-core";

export interface ContainerProps extends BoxProps {
  first?: boolean;
  filled?: boolean;
}

export const Container = styled(Box).attrs((props: ContainerProps) => {
  return {
    my: props.my || "xxxl",
    mt: props.first ? "none" : "xxxl",
    px: "xxl",
    width: "100%",
    background: props.filled && "grayLightest",
    py: props.filled && "xxl",
    ...props,
  };
})`
  font-feature-settings: "zero" off;
  /* first-child {
    margin-top: 0;
  } */
`;

const innerProps = (props: ContainerProps, maxWidth: string | number) => ({
  m: "0 auto",
  mt: props.first ? "none" : "xxl",
  flexDirection: "column",
  maxWidth,
  ...props,
});
export const Inner = styled(Flex).attrs((props: ContainerProps) => innerProps(props, "1200px"))``;
export const InnerMedium = styled(Flex).attrs((props: ContainerProps) =>
  innerProps(props, "930px")
)``;
export const InnerSmall = styled(Flex).attrs((props: ContainerProps) =>
  innerProps(props, "680px")
)``;

export const StyledHTML = styled(Box)`
  ${markdownStyle}

  a {
    color: ${utils.getColor("primary")};
    text-decoration: underline;
  }

  ol,
  ul {
    margin-bottom: 20px !important;
  }
`;
