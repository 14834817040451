import React, { useMemo, useCallback } from "react";
import { Flex, Button, Space, Box, TableCellText } from "@thenounproject/lingo-core";

import InsightsIntervalPicker from "../InsightsIntervalPicker";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";

import useAssetExports, { AssetExportResult } from "../queries/useAssetExports";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";

type Props = {
  assetId: string;
  space: Space;
};

const InsightsAssetExportsTab = ({ assetId, space }: Props) => {
  const [sort, setSort] = React.useState<string>("-total_downloads");

  const { dateOperators } = useInsightsContext();

  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const {
    data,
    isLoading: tableIsLoading,
    error: tableError,
    fetchNextPage,
  } = useAssetExports({
    spaceId: space.id,
    sort,
    dateOperators,
    itemId: assetId,
  });

  const { rows: tableData, total } = data || {};

  const hasMorePages = tableData?.length < total;

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "asset_exports",
      sort,
      dateOperators,
      additionalParams: { ["item_uuid"]: assetId },
    });
  }, [assetId, dateOperators, generateInsightsCSV, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "File Cuts",
      subtitle: "The formats in which the asset was downloaded",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"user-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<AssetExportResult>[] = [
      {
        name: "Type",
        minWidth: 160,
        flexGrow: 1,
        renderer: e => <TableCellText pl="40px">{e?.type}</TableCellText>,
      },
      {
        sortKey: "total_downloads",
        name: "Downloads",
        minWidth: 160,
        justify: "flex-end",
        renderer: e => <TableCellText>{e?.totalDownloads}</TableCellText>,
      },
      {
        name: "Dimensions",
        minWidth: 160,
        renderer: e => <TableCellText>{e?.dimensions}</TableCellText>,
      },
    ];
    return cols;
  }, []);

  return (
    <Box mt="l">
      <DataTable<AssetExportResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </Box>
  );
};

export default React.memo(InsightsAssetExportsTab);
