import React, { useMemo, useCallback } from "react";
import { Box, Button, Flex, Space, TableCellText } from "@thenounproject/lingo-core";

import InsightsIntervalPicker from "../InsightsIntervalPicker";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";
import formatBytes from "@helpers/bytesToSize";
import useTopRegions, { TopRegionsResult } from "../queries/useTopRegions";

type Props = {
  directLinkId: number;
  space: Space;
};

function InsightsGeographyTab({ directLinkId, space }: Props) {
  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const [sort, setSort] = React.useState<string>("-total_requests");

  const { dateOperators } = useInsightsContext();

  const {
    data,
    isLoading: tableIsLoading,
    error: tableError,
    fetchNextPage,
  } = useTopRegions({
    spaceId: space.id,
    sort,
    dateOperators,
    directLinkId,
  });

  const { rows: tableData, total } = data ?? {};

  const hasMorePages = tableData?.length < total;

  const tableColumns = useMemo(() => {
    const cols: TableColumn<TopRegionsResult>[] = [
      {
        sortKey: "country",
        name: "Country",
        minWidth: 240,
        flexGrow: 1,
        renderer: k => {
          return <TableCellText>{k.country}</TableCellText>;
        },
      },
      {
        sortKey: "city",
        name: "City",
        minWidth: 160,
        renderer: k => <TableCellText>{k.city}</TableCellText>,
      },
      {
        sortKey: "total_requests",
        name: "Total Requests",
        minWidth: 160,
        renderer: k => <TableCellText>{k.totalRequests}</TableCellText>,
      },
      {
        sortKey: "total_bandwidth",
        name: "Bandwidth",
        minWidth: 160,
        renderer: k => <TableCellText>{formatBytes(k.totalBandwidth)}</TableCellText>,
      },
    ];

    return cols;
  }, []);

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "direct_link_geography",
      sort,
      dateOperators,
      additionalParams: { ["direct_link_id"]: directLinkId },
    });
  }, [directLinkId, dateOperators, generateInsightsCSV, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Top Regions",
      subtitle:
        "A breakdown of the countries generating the highest number of requests to the CDN.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"top-regions"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  return (
    <Box mt="l">
      <DataTable<TopRegionsResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </Box>
  );
}

export default React.memo(InsightsGeographyTab);
