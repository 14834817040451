import React, { useEffect, useState } from "react";

import { Button, ButtonProps, Flex, Icon, ImageView, Text } from "@thenounproject/lingo-core";
import { Container, Inner } from "../Elements";
import MarketingText from "../MarketingText";

type Image = {
  src: string;
  alt: string;
};

type Props = {
  title: string;
  subtitle?: string;
  body?: {
    style: "iconList" | "tabList";
    items: ListProps["items"];
  };
  layoutDirection?: "image-left" | "image-right";
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  image?: Image;
};

const MediaSection: React.FC<Props> = ({
  title,
  subtitle,
  body,
  primaryButton,
  secondaryButton,
  image: initialImage,
  layoutDirection,
}) => {
  function renderButtons() {
    if (primaryButton || secondaryButton) {
      return (
        <Flex mt="xl" gap="16px">
          {primaryButton && <Button buttonStyle="primary" {...primaryButton} />}
          {secondaryButton && <Button buttonStyle="outline" {...secondaryButton} />}
        </Flex>
      );
    }
  }

  const [image, setImage] = useState(initialImage ?? body?.items[0]?.image);

  useEffect(() => {
    setImage(initialImage ?? body?.items[0]?.image);
  }, [body, initialImage]);

  const direction =
    {
      "image-left": "row-reverse",
      "image-right": "row",
    }[layoutDirection] ?? "row";

  return (
    <Container mt="none" my="xxxl" p="none">
      <Inner
        flexDirection={direction}
        gap="80px"
        alignItems="flex-start"
        variations={{
          "mq.m": { flexDirection: "column-reverse" },
        }}>
        <Flex
          alignSelf="center"
          flexDirection="column"
          flex="1"
          variations={{
            "mq.m_up": { maxWidth: 400 },
            "mq.m": { width: "100%" },
          }}>
          <Text font="marketing.h3">{title}</Text>
          {subtitle ? (
            <Text mt="m" font="marketing.regular">
              {subtitle}
            </Text>
          ) : null}
          {body &&
            (body.style === "iconList" ? (
              <IconList items={body.items} />
            ) : (
              <TabList items={body.items} setImage={setImage} />
            ))}
          {renderButtons()}
        </Flex>
        <ImageView
          variations={{
            "mq.m": {
              width: "100%",
            },
          }}
          aspectRatio={66}
          flex="1"
          {...image}
        />
      </Inner>
    </Container>
  );
};

export default MediaSection;

type ListProps = {
  items: {
    title: string;
    body: string;
    image?: Image;
  }[];
};

const TabList: React.FC<ListProps & { setImage: (Image: Image) => void }> = ({
  items,
  setImage,
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    setSelectedTab(0);
  }, [items]);

  function handleTabClick(index: number) {
    if (items[index].image) {
      setImage(items[index].image);
    }
    setSelectedTab(index);
  }
  return (
    <Flex flexDirection="column" gap="16px" mt="xl">
      {items.map((item, index) => {
        const isSelected = index === selectedTab;
        return (
          <Flex
            style={{ cursor: "pointer", transition: "border-left 0.4s ease-in-out" }}
            flexDirection="column"
            pl="m"
            borderLeft={`4px solid ${isSelected ? "primary" : "border"}`}
            key={item.title}
            onClick={() => handleTabClick(index)}
            aria-selected={isSelected}>
            <Text font="marketing.subtitle1">{item.title}</Text>
            {item.body && (
              <Text
                style={{ transition: "all 0.25s ease-out", opacity: isSelected ? 1 : 0 }}
                overflow="hidden"
                maxHeight={isSelected ? 90 : 0}
                mt="xs">
                {item.body}
              </Text>
            )}
          </Flex>
        );
      })}
    </Flex>
  );
};

const IconList: React.FC<ListProps> = ({ items }) => {
  return (
    <Flex flexDirection="column" gap="16px" mt="xl">
      {items.map(item => {
        return (
          <Flex key={item.title}>
            <Icon size="24" flexShrink="0" mr="m" fill="primary" iconId="info.check-circle" />
            <Flex flexDirection="column">
              <Text font="marketing.subtitle1">{item.title}</Text>
              {item.body && (
                <MarketingText mt="s" font="marketing.regular">
                  {item.body}
                </MarketingText>
              )}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
