import React, { useCallback, useRef } from "react";
import {
  CustomField,
  CustomFieldOption,
  Flex,
  Box,
  Text,
  Button,
  Icon,
  Input,
  useDrag,
  FlexProps,
} from "@thenounproject/lingo-core";

import styled from "styled-components";
import { TinyColor } from "@ctrl/tinycolor";

type Props = {
  fieldData: Partial<CustomField>;
  setFieldData: React.Dispatch<React.SetStateAction<Partial<CustomField>>>;
};

const buildBlankOption = (): CustomFieldOption => ({
  name: "",
  status: "active",
});

export const SelectOptionsForm: React.FC<Props> = ({ fieldData, setFieldData }) => {
  const addNewOption = useCallback(() => {
    setFieldData(prev => ({
      ...prev,
      options: [...prev.options, buildBlankOption()],
    }));
  }, [setFieldData]);

  const removeOptionByIndex = useCallback(
    (index: number) => {
      setFieldData(prev => ({
        ...prev,
        options: prev.options.filter((_, i) => i !== index) || [],
      }));
    },
    [setFieldData]
  );

  const updateOptionNameByIndex = useCallback(
    (index: number) => {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        const newOptions = [...fieldData.options];
        newOptions[index].name = e.target.value;
        setFieldData(prev => ({ ...prev, options: newOptions }));
      };
    },
    [fieldData.options, setFieldData]
  );

  const moveOptionToIndex = useCallback(
    (initialIndex: number, newIndex: number) => {
      const newOptions = [...fieldData.options];
      const [removed] = newOptions.splice(initialIndex, 1);
      newOptions.splice(newIndex, 0, removed);
      setFieldData(prev => ({ ...prev, options: newOptions }));
    },
    [fieldData.options, setFieldData]
  );

  return (
    <Flex flexDirection="column" mb="32px">
      <Text font="ui.smallBold">Options</Text>
      <Flex flexDirection="column" mt="12px">
        {fieldData.options?.map((option, index) => {
          const handleChange = updateOptionNameByIndex(index);
          return (
            <SelectOption
              key={option.id || `option-${index}`}
              option={option}
              optionIndex={index}
              onRemove={() => removeOptionByIndex(index)}
              onChange={handleChange}
              moveOptionToIndex={moveOptionToIndex}
            />
          );
        })}
      </Flex>
      <Flex mt="16px">
        <Button buttonStyle="tertiary" onClick={addNewOption} text="Add option" />
      </Flex>
    </Flex>
  );
};

type DragDirection = ReturnType<typeof useDrag>["dragDirection"];

const SelectOptionWrapper = styled(Flex).attrs<FlexProps & { dragDirection: DragDirection }>(
  props => {
    const hoverBg = new TinyColor(props.theme.primaryColor).setAlpha(0.5).toRgbString();
    const hoverBgEmpty = new TinyColor(props.theme.primaryColor).setAlpha(0).toRgbString();
    return {
      alignItems: "center",
      position: "relative",
      p: "4px",
      borderRadius: "default",
      background: props.dragDirection ? hoverBg : hoverBgEmpty,
    };
  }
)`
  transition: background 0.1s ease;
`;

type OptionProps = {
  option: CustomFieldOption;
  optionIndex: number;
  onRemove: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  moveOptionToIndex: (initialIndex: number, newIndex: number) => void;
};

const SelectOption: React.FC<OptionProps> = ({
  option,
  optionIndex,
  onRemove,
  onChange,
  moveOptionToIndex,
}) => {
  const dragRef = useRef();
  const { dragDirection, dragProps } = useDrag({
    dragRef,
    doOnDragStart: e => {
      e.dataTransfer.setData("optionIndex", String(optionIndex));
    },
    doOnDrop: e => {
      const initialIndex = Number(e.dataTransfer.getData("optionIndex"));
      if (initialIndex !== optionIndex) {
        moveOptionToIndex(initialIndex, optionIndex);
      }
    },
  });

  return (
    <SelectOptionWrapper {...{ ...dragProps, dragDirection }}>
      <Box style={{ cursor: "grab" }}>
        <Icon iconId="action.drag-handler" size={16} mr="4px" />
      </Box>
      <Input
        id={`select-option-${optionIndex}`}
        value={option.name}
        placeholder="Option name"
        onChange={onChange}
      />
      <Flex flexShrink="0">
        <Button buttonStyle="tertiary" onClick={onRemove} text="Remove" ml="16px" />
      </Flex>
    </SelectOptionWrapper>
  );
};
