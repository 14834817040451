import React, { useCallback, useState, Fragment } from "react";
import { Input, Text } from "@thenounproject/lingo-core";

import useCancelSubscription from "@actions/billing/useCancelSubscription";
import useShowModal from "@redux/actions/useModals";
import useNotifications from "@actions/useNotifications";

import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import { useSelectSpace } from "@redux/selectors/entities/spaces";

type Props = {
  spaceId: number;
};

const CancelSubscriptionModal: React.FC<Props> = ({ spaceId }) => {
  const [error, setError] = useState(null),
    [inputText, setInputText] = useState("");

  const space = useSelectSpace(spaceId);

  const [cancelSubscription, cancelSubscriptionState] = useCancelSubscription(),
    processing = cancelSubscriptionState.isProcessing,
    { showNotification } = useNotifications(),
    { dismissModal } = useShowModal();

  const submit = useCallback(async () => {
    const confirmed = inputText.toLowerCase() === "cancel";
    if (!confirmed) {
      setError("Please type “Cancel” to confirm.");
      return;
    }
    setError(null);
    const { error: responseError } = await cancelSubscription({ spaceId: space.id });
    if (responseError) {
      setError(responseError.message);
    } else {
      showNotification({
        message: "Plan canceled. Space will remain active until billing cycle ends.",
      });
      dismissModal();
    }
  }, [cancelSubscription, dismissModal, inputText, showNotification, space.id]);

  return (
    <Fragment>
      <ModalHeader title={`Cancel plan for ${space.name}`} />
      <ModalBody>
        <Text mb="xl">Your space will remain active until billing cycle ends. Are you sure?</Text>
        <Input
          label='Confirm by typing "Cancel"'
          value={inputText}
          onChange={e => setInputText(e.target.value)}
          onSubmit={submit}
          placeholder={"Cancel"}
          inputStyle={error ? "error" : null}
          message={error}
          disabled={processing}
          autoFocus
        />
      </ModalBody>
      <ModalFooter
        primary={{
          disabled: processing,
          text: processing ? "Cancelling plan..." : "Yes, cancel plan",
          onClick: submit,
        }}
      />
    </Fragment>
  );
};

export default CancelSubscriptionModal;
