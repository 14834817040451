import { useEffect, useState } from "react";
import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Response = {
  totp_config: {
    uri: string;
    secret: string;
  };
};

const [useGenerateTOTP] = createAsyncAction("auth/createTOTP", async () => {
  const response = await API.call<Response>({
    endpoint: `me/mfa/totp/`,
    method: "POST",
  });
  return response;
});

export default function useCreateTOTP() {
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState(null);
  const [uri, setUri] = useState<string>("");
  const [secret, setSecret] = useState<string>("");
  const [generateTOTP] = useGenerateTOTP();

  useEffect(() => {
    const result = generateTOTP();
    result
      .then(result => {
        if (result.error) {
          setStatus("error");
          setError(result.error);
        } else {
          setStatus("success");
          setUri(result.response.result.totp_config.uri);
          setSecret(result.response.result.totp_config.secret);
        }
      })
      .catch(() => {
        // nothing to do
      });
  }, [generateTOTP]);

  return { status, uri, secret, error };
}
