/*
 * Container component for authentication.
 */

import React, { Fragment } from "react";
import Helmet from "react-helmet";

import styled, { DefaultTheme, ThemeProvider } from "styled-components";
import { Button, Text, Box, Flex, ErrorCode, EmptyState } from "@thenounproject/lingo-core";

import Themer from "../Themer";
import Footer from "../Footer";
import AuthFormTitle from "./AuthFormTitle";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import JoinSpaceForm from "./JoinSpaceForm";
import PasswordEntry from "./PasswordEntry";
import RequestInviteForm from "./RequestInviteForm";
import SSOLogin from "./SSOLogin";
import AccountLinkConfirmation from "./AccountLinkConfirmation";
import RedeemJoinToken from "./RedeemJoinToken";
import { AuthContainer, AuthContent } from "./AuthElements";
import PasswordReset from "./PasswordReset";
import { AuthHeader } from "./AuthHeader";

import useCurrentUser from "@queries/useCurrentUser";
import useAuthState, { AuthType, SignupStep } from "./useAuthState";
import useAuthHandler from "./useAuthHandler";
import SignupVerificationConfirmation from "./SignupVerificationConfirmation";
import { getTheme } from "@contexts/ThemeDataContext";
import { useCustomFont } from "@hooks/useCustomFont";

const LoadingWrapper = styled.div<{ isLoading: boolean }>`
  opacity: ${props => (props.isLoading ? 0 : 1)};
  transition: opacity 1s;
`;

type Props = {
  authType: AuthType;
  signupStep?: SignupStep;
};

const Auth: React.FC<Props> = props => {
  const { user } = useCurrentUser();

  const {
    authType,
    signupStep,
    nextSpaceIdentifier,
    tokenError,
    token,
    autoPassword,
    redirectUrl,
    portalId,
    kitId,
    spacePreview,
    portalPreview,
    previewIsLoading,
  } = useAuthState(props);

  const spaceTheme = getTheme(spacePreview, portalPreview);
  const themeActive = spaceTheme?.active;
  useCustomFont({
    url: spaceTheme?.fonts?.css,
    family: spaceTheme?.fonts?.system?.family,
  });

  const responseHandler = useAuthHandler({
    token,
    authType,
    redirectUrl,
    space: spacePreview,
  });

  // MARK : Rendering
  // -------------------------------------------------------------------------------
  return (
    <ThemeProvider theme={spaceTheme as DefaultTheme}>
      <LoadingWrapper data-testid="loading-wrapper" isLoading={previewIsLoading}>
        {!themeActive ? <AuthHeader /> : null}
        <AuthContainer>
          <AuthContent withHeader={!themeActive}>
            <Themer data-test="auth-themer" theme={spaceTheme as DefaultTheme} />
            {tokenError ? renderTokenError(tokenError) : renderAuthForm()}
          </AuthContent>
        </AuthContainer>
        <Footer showPoweredBy={themeActive} />
      </LoadingWrapper>
    </ThemeProvider>
  );

  function renderTokenError(errorCode: number) {
    let title = "Invalid invitation",
      object = "invitation";

    if (errorCode === ErrorCode.invitationAlreadyAccepted) {
      title = "Invation already accepted";
    } else if (errorCode === ErrorCode.invitationNotFound) {
      title = "Invation not found";
    } else if (errorCode === ErrorCode.joinLinkNotValid || errorCode === ErrorCode.spaceNotFound) {
      title = "Invalid join link";
      object = "join link";
    }
    const message = `Contact an admin of ${
      spacePreview ? spacePreview.name : "the space"
    } for a new ${object}.`;

    const contactMessage = (
      <Text color="grayDarkest" mt="xxl">
        If the problem continues,{" "}
        <Button text="contact support." buttonStyle="tertiary" link="mailto:info@lingoapp.com" />
      </Text>
    );

    return (
      <Flex justifyContent="center" alignItems="center">
        <Box p="xxl" my="l">
          {spacePreview && <AuthFormTitle space={spacePreview} title={""} />}
          <EmptyState title={title} subtitle={message} extra={contactMessage} size="regular" />
        </Box>
      </Flex>
    );
  }

  function renderAuthForm() {
    if (previewIsLoading || user?.isFetching) return null;
    switch (authType) {
      case AuthType.login:
        return renderLoginForm();
      case AuthType.signup:
        return renderSignupForm();
      case AuthType.enterPassword:
        return renderPasswordEntry();
      case AuthType.enterEmail:
        return renderRequestInviteForm();
      case AuthType.joinSpace:
        return renderJoinSpace();
      case AuthType.sso:
        return renderSSOLogin();
      case AuthType.linkAccount:
        return renderAccountLinkConfirmation();
      case AuthType.join:
      case AuthType.invitation:
        return renderRedeemToken();
      case AuthType.passwordReset:
        return renderPasswordReset();

      default:
        throw new Error(`Invalid authentication type: ${authType}`);
    }
  }

  function renderLoginForm() {
    return (
      <Fragment>
        <Helmet title="Log in to Lingo" />
        <LoginForm
          responseHandler={responseHandler}
          isAccessingSpace={Boolean(nextSpaceIdentifier)}
          token={token}
          space={spacePreview}
        />
      </Fragment>
    );
  }

  function renderSignupForm() {
    switch (signupStep) {
      case SignupStep.verificationSent: {
        return <SignupVerificationConfirmation space={spacePreview} user={user} />;
      }
      default: {
        return (
          <>
            <Helmet title="Sign up for Lingo" />
            <SignupForm responseHandler={responseHandler} token={token} space={spacePreview} />
          </>
        );
      }
    }
  }

  function renderSSOLogin() {
    return (
      <Fragment>
        <Helmet title="Sign up for Lingo" />
        <SSOLogin space={spacePreview} />
      </Fragment>
    );
  }

  function renderAccountLinkConfirmation() {
    return (
      <Fragment>
        <Helmet title="Sign up for Lingo" />
        <AccountLinkConfirmation
          space={spacePreview}
          user={user}
          token={token}
          responseHandler={responseHandler}
        />
      </Fragment>
    );
  }

  function renderRedeemToken() {
    if (!token) return null;
    return <RedeemJoinToken responseHandler={responseHandler} space={spacePreview} token={token} />;
  }

  function renderPasswordEntry() {
    return (
      <PasswordEntry
        portalId={portalId}
        kitId={kitId}
        responseHandler={responseHandler}
        space={spacePreview}
        portal={portalPreview}
        spaceIdentifier={nextSpaceIdentifier}
        autoPassword={autoPassword}
      />
    );
  }

  function renderJoinSpace() {
    return <JoinSpaceForm spaceIdentifier={nextSpaceIdentifier} space={spacePreview || null} />;
  }

  function renderRequestInviteForm() {
    return <RequestInviteForm space={spacePreview} spaceIdentifier={nextSpaceIdentifier} />;
  }

  function renderPasswordReset() {
    return <PasswordReset token={token} space={spacePreview} />;
  }
};

export default Auth;
