/**
 * User invitation modal including tabs for
 * invite by email and self serve options
 */

import React, { useState, Fragment } from "react";
import { TabBar, Flex } from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";

import InviteSelfServe from "./InviteSelfServe";
import InviteByEmail from "./InviteByEmail";
import SpaceFullMessage from "./SpaceFullMessage";
import styled from "styled-components";

import { useSelectSpace } from "@selectors/entities/spaces";

// These allow disabling interaction with modal content when the space is full
// Inner and outer are needed to set the not-allowed cursor and also disabled cursor-events

type DisabledBodyProps = {
  disabled: boolean;
};

const DisabledBodyWrapper = styled(Flex).attrs<DisabledBodyProps>({
  flexDirection: "column",
  overflow: "auto",
  flex: 1,
})<DisabledBodyProps>`
  opacity: ${props => (props.disabled ? "50%" : "100%")};
  cursor: ${props => (props.disabled ? "not-allowed" : null)};
`;
const DisabledBodyInner = styled(Flex).attrs<DisabledBodyProps>({
  flexDirection: "column",
  overflow: "auto",
  flex: 1,
})<DisabledBodyProps>`
  pointer-events: ${props => (props.disabled ? "none" : null)};
`;

type Props = {
  prefilledEmails?: string[];
};

function InviteSpaceMembers({ prefilledEmails }: Props) {
  const space = useSelectSpace();
  const [tab, setTab] = useState<"email" | "self-serve" | "">("email");

  // Calculate if the space is full
  const spotsTaken = space.counts.users + space.counts.invitations,
    spotsAvailable = space.maxUsers === null ? Infinity : space.maxUsers - spotsTaken,
    spaceFull = spotsAvailable < 1;

  return (
    <Fragment>
      <ModalHeader
        title={`Invite users ${space ? `to ${space.name}` : ""}`}
        styleOverrides={{ borderBottom: "none" }}>
        <TabBar separator styleOverrides={{ mt: "m" }}>
          <TabBar.Item
            text="Invite by email"
            selected={tab === "email"}
            onClick={() => {
              // A little hackery to force the inviteByEmail to reset and show the roles
              setTab("");
              setTimeout(() => setTab("email"));
            }}
          />
          <TabBar.Item
            text="Other ways to invite"
            selected={tab === "self-serve"}
            onClick={() => setTab("self-serve")}
          />
        </TabBar>
        {spaceFull ? (
          <ModalBody pb="none">
            <SpaceFullMessage
              spaceId={space.id}
              permissions={space.access.permissions}
              styleOverrides={{ mb: "none" }}
            />
          </ModalBody>
        ) : null}
      </ModalHeader>

      <DisabledBodyWrapper disabled={spaceFull}>
        <DisabledBodyInner disabled={spaceFull}>
          {tab === "email" && <InviteByEmail space={space} prefilledEmails={prefilledEmails} />}
          {tab === "self-serve" && <InviteSelfServe space={space} />}
        </DisabledBodyInner>
      </DisabledBodyWrapper>
    </Fragment>
  );
}

export default InviteSpaceMembers;
