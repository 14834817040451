import React, { useEffect, useMemo, useState } from "react";
import { buildPath, ActivityIndicator, useNavigation } from "@thenounproject/lingo-core";

import AssetDetail from "../../../components/kits/AssetDetail";
import { AssetOverlayContainer } from "../../../components/AssetOverlay";
import { canViewAssetLibrary } from "../utils";
import { NavPoint } from "@redux/legacy-actions/navPoints";
import { useAssetLibraryContext } from "@contexts/AssetLibraryProvider";
import useLibraryAssets from "@redux/actions/search/useLibraryAssets";

type Props = {
  navPoint: NavPoint;
  queryData: Pick<ReturnType<typeof useLibraryAssets>, "data" | "fetchNextPage" | "isLoading">;
  hasNextPage: boolean;
};

const LibraryAssetDetail: React.FC<Props> = ({ navPoint, queryData, hasNextPage }) => {
  const navigate = useNavigation();
  const { space, asset, assetId } = navPoint;

  const { viewId } = useAssetLibraryContext();

  const inspectable = useMemo(() => (asset ? { asset } : null), [asset]);

  const [assetIndex, setAssetIndex] = React.useState(-1);
  const [prevAssetUrl, setPrevAssetUrl] = useState(null);
  const [nextAssetUrl, setNextAssetUrl] = useState(null);
  const libraryAssets = queryData.data?.assets;

  useEffect(() => {
    if (!canViewAssetLibrary) navigate.push(buildPath("/", { space }));
  }, [navigate, space]);

  useEffect(() => {
    if (!asset) return;
    // Fetch the array of assets in order to determine the previous and next assets.
    // Accounts for when a user navigates to the asset detail page directly,
    // and the Library assets array does not already contain the currently inspected asset.

    const assetIdx = libraryAssets?.findIndex(a => a.id === assetId);
    if (assetIdx > -1) {
      const prevAsset = libraryAssets[assetIdx - 1],
        nextAsset = libraryAssets[assetIdx + 1];
      setAssetIndex(assetIdx);
      if (prevAsset)
        setPrevAssetUrl(
          buildPath(`/library/a/${prevAsset?.id}`, { space }, { view: viewId || undefined })
        );
      if (nextAsset)
        setNextAssetUrl(
          buildPath(`/library/a/${nextAsset?.id}`, { space }, { view: viewId || undefined })
        );
      if (!prevAsset) setPrevAssetUrl(null);
      if (!nextAsset) setNextAssetUrl(null);
    }
  }, [assetIndex, libraryAssets, space, viewId, asset, queryData, assetId]);

  function shouldLoadNextPage() {
    if (!hasNextPage || assetIndex < 0) return false;
    const assetsFromEnd = libraryAssets.length - assetIndex - 1;
    if (assetsFromEnd < 4) return true;
  }
  return (
    <AssetOverlayContainer showSuperNav>
      {!inspectable ? (
        <ActivityIndicator size="large" center />
      ) : (
        inspectable && (
          <AssetDetail
            inspectable={inspectable}
            canEdit={true}
            prevAssetUrl={prevAssetUrl}
            nextAssetUrl={nextAssetUrl}
            closeUrl={buildPath("/library", { space }, { view: viewId || undefined })}
            shouldLoadNextPage={shouldLoadNextPage()}
            fetchNextPage={queryData.fetchNextPage}
          />
        )
      )}
    </AssetOverlayContainer>
  );
};

export default LibraryAssetDetail;
