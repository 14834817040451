import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";
import { INSIGHTS_PAGE_LIMIT } from "../consts";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  directLinkId: number;
};

export type ReferrersResult = {
  referrer: string;
  totalRequests: number;
  totalBandwidth: number;
};

type ReferrersTableData = {
  total: number;
  rows: ReferrersResult[];
};

const [useDirectLinkReferrers, fetchDirectLinkReferrers] = createQueryAction<
  Args,
  ReferrersTableData
>(
  {
    entity: "analytics",
    action: "queryDirectLinkReferrers",
    denormalize: false,
  },
  async ({ args, paging }) =>
    (
      await fetchAnalytics<{ asset_usage: ReferrersTableData }>(args.spaceId, {
        asset_usage: {
          id: "direct_link_referrers",
          limit: INSIGHTS_PAGE_LIMIT,
          sort: args.sort,
          date_operators: args.dateOperators,
          direct_link_id: args.directLinkId,
          page: paging.page,
        },
      })
    ).assetUsage
);

export default useDirectLinkReferrers;
export { fetchDirectLinkReferrers };
