import React, { useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";
import {
  Flex,
  Text,
  Box,
  SpaceTheme,
  SpaceThemeNames,
  Button,
  Portal,
  Space,
  buildURL,
  PortalPermission,
} from "@thenounproject/lingo-core";

import SearchBarButton from "../../search/SearchBarButton";

import { SUPER_NAV_WIDTH } from "../../../constants/layouts";
import { sharedThemeVals } from "../Portal";
import useCurrentUser from "@queries/useCurrentUser";
import { useBannerContext } from "@contexts/BannerProvider";
import useModals, { ModalTypes } from "@redux/actions/useModals";
import useConfigureMode from "../../hooks/useConfigureMode";

const MOBILE_HEADER_HEIGHT = 70;

type TitleInView = {
  titleInView: boolean;
};

const fixedWrapperThemeVals = {
  [SpaceThemeNames.classic]: {
    px: "m",
  },
  [SpaceThemeNames.scher]: { px: "m" },
  [SpaceThemeNames.wyman]: { px: "xxl" },
};
const FixedWrapper = styled(Flex).attrs(
  (
    props: {
      top: number;
      theme?: SpaceTheme;
      fullWidth: boolean;
      configureMode: boolean;
    } & TitleInView
  ) => {
    const getWidth = () => {
      if (props.fullWidth) {
        return "100%";
      }
      if (props.configureMode && props.theme.themeName === SpaceThemeNames.wyman) {
        return "unset";
      }
      return `calc(100% - ${SUPER_NAV_WIDTH}px)`;
    };
    const getRightOffset = () => {
      if (props.configureMode && props.theme.themeName === SpaceThemeNames.wyman) {
        return 240;
      }
      if (props.configureMode) {
        return 260;
      }
      return props.titleInView && props.theme.themeName !== SpaceThemeNames.wyman ? 20 : 0;
    };
    return {
      width: getWidth(),
      borderRadius: "4px 0 0 0",
      justifyContent: "center",
      position: "fixed",
      top: `${props.top}px`,
      right: getRightOffset(),
      background: props.titleInView ? "transparent" : "toolbarBackground",
      borderBottom: props.titleInView ? "1px solid transparent" : "default",
      px: fixedWrapperThemeVals[props?.theme?.themeName].px,
      py: "m",
      style: {
        zIndex: 10,
        transition: "0.3s ease all",
      },
      variations: {
        "mq.s": {
          width: "100%",
          top: `${MOBILE_HEADER_HEIGHT + props.top}px`,
        },
      },
    };
  }
)``;

const Inner = styled(Flex).attrs<TitleInView>(props => {
  const sharedVals = sharedThemeVals[props.theme.themeName || "classic"];

  let maxWidth = sharedVals.contentContainer.maxWidth;
  if (props.theme.themeName !== SpaceThemeNames.wyman) {
    maxWidth = props.titleInView ? "100%" : sharedVals.contentContainer.maxWidth;
  }
  return {
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth,
    style: {
      transition: "0.3s ease all",
    },
  };
})<TitleInView>``;

const TitleBox = styled(Box).attrs<TitleInView>(props => {
  return { style: { opacity: props.titleInView ? 0 : 1, transition: "0.3s ease all" } };
})<TitleInView>``;

type Props = {
  portal: Portal;
  space: Space;
  titleInView: boolean;
  configureMode: boolean;
};

const PortalFixedNav = ({ portal, space, titleInView = true }: Props) => {
  const themeName = useTheme().themeName;
  const { showModal } = useModals();
  const { activeSpaceAlert } = useBannerContext();
  const { configureMode, toggleConfigureMode } = useConfigureMode();

  const currentUser = useCurrentUser();
  const perms = portal.access?.permissions || [],
    canShare = !space.access.isPublic,
    canConfigure = perms.includes(PortalPermission.manageCustomTheme),
    canSearch = perms.includes(PortalPermission.searchAssets);
  const loginUrl = useMemo(() => {
    if (space.subdomain || space.domain) {
      return buildURL("/login", { space, isPublic: false });
    } else {
      return buildURL("/login", {});
    }
  }, [space]);

  const openShareModal = useCallback(() => {
    showModal(ModalTypes.SHARE_PORTAL, { portalId: portal.id });
  }, [portal.id, showModal]);

  const top = activeSpaceAlert ? 60 : 0;
  const isLoggedIn = Boolean(currentUser?.user?.id);
  const fullWidth = !isLoggedIn;

  return (
    <FixedWrapper
      top={top}
      titleInView={titleInView}
      fullWidth={fullWidth}
      configureMode={configureMode}>
      <Inner titleInView={titleInView}>
        <TitleBox titleInView={titleInView}>
          <Text font="ui.title">{portal.name}</Text>
        </TitleBox>
        <Flex alignItems="center" gap="8px">
          {canSearch && (!titleInView || themeName === SpaceThemeNames.wyman) ? (
            <SearchBarButton
              mr="s"
              size={themeName === SpaceThemeNames.wyman ? "small" : "regular"}
            />
          ) : null}
          {canConfigure && !configureMode && (
            <Button text="Configure" buttonStyle="secondary" onClick={toggleConfigureMode} />
          )}
          {canShare && <Button text="Share Portal" onClick={openShareModal} />}
          {!isLoggedIn && <Button buttonStyle="primary" text="Login" link={loginUrl} />}
        </Flex>
      </Inner>
    </FixedWrapper>
  );
};

export default PortalFixedNav;
