import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  dateOperators?: OperatorValues;
  itemId: string;
};

type UsageData = {
  date: string;
  guestUses: number;
  memberUses: number;
  totalUses: number;
  uniqueGuests: number;
  uniqueMembers: number;
  uniquePeople: number;
};

type AssetData = {
  result: UsageData[];
};

const [useUserAssetDownloads, fetchUserAssetDownloads] = createQueryAction<Args, AssetData>(
  {
    entity: "analytics",
    action: "queryAssetUserUsage",
    denormalize: false,
    pagingKey: "result",
  },
  async ({ args }) => {
    const reports = {
      asset_usage: {
        id: "intervals",
        entity: "asset_usage",
        item_uuid: args.itemId,
        date_operators: args.dateOperators,
      },
    };

    return {
      result: (await fetchAnalytics<{ asset_usage: AssetData }>(args.spaceId, reports)).assetUsage,
    };
  }
);

export default useUserAssetDownloads;
export { fetchUserAssetDownloads };
