import React, { useState, useCallback, Fragment } from "react";
import {
  Text,
  Notice,
  Textarea,
  formatDate,
  AccountCharge,
  Tooltip,
} from "@thenounproject/lingo-core";

import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import useEditInvoice from "@actions/billing/useEditInvoice";
import useNotifications from "@actions/useNotifications";
import useShowModal from "@redux/actions/useModals";

type Props = {
  spaceId: number;
  charge: AccountCharge;
};

const EditInvoiceModal: React.FC<Props> = ({ charge, spaceId }) => {
  const [error, setError] = useState(null),
    [receiptExtra, setReceiptExtra] = useState(charge.receiptExtra ?? "");

  const [editInvoice, editInvoiceStatus] = useEditInvoice(),
    { showNotification } = useNotifications(),
    { dismissModal } = useShowModal();

  const submit = useCallback(async () => {
    setError(null);

    const { error: responseError } = await editInvoice({
      chargeId: charge.id,
      spaceId,
      receiptExtra,
    });
    if (responseError) {
      setError(responseError.message || "Oops! That didn’t work.");
    } else {
      showNotification({ message: "Invoice updated." });
      return dismissModal();
    }
  }, [charge.id, dismissModal, editInvoice, receiptExtra, showNotification, spaceId]);

  return (
    <Fragment>
      <ModalHeader
        title={`Edit receipt information for ${formatDate(charge.date, "MMMM d yyyy")}`}
      />

      <ModalBody>
        <Text as="label" mb="xs">
          Extra receipt information{" "}
          <Tooltip.InfoTip>
            Extra receipt info shows up on your invoices. Use this for VAT numbers or anything else
            you may need for tax purposes.
          </Tooltip.InfoTip>
        </Text>
        <Textarea
          id="text-input"
          onChange={e => setReceiptExtra(e.target.value)}
          value={receiptExtra}
          placeholder="VAT No., Address, Job No., etc"
        />
        {error && <Notice mt="m" noticeStyle="error" message={error} />}
      </ModalBody>
      <ModalFooter
        primary={{
          text: "Update",
          disabled: editInvoiceStatus.isProcessing,
          onClick: submit,
          id: "submit-button",
        }}
      />
    </Fragment>
  );
};

export default EditInvoiceModal;
