import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  directLinkId: number;
};

export type DirectLinkVisitorsResult = {
  ipAddress: string;
  totalBandwidth: number;
  totalRequests: number;
};

type DirectLinkVisitorsData = {
  rows: DirectLinkVisitorsResult[];
  total: number;
};

const [useDirectLinkVisitors, fetchDirectLinkVisitors] = createQueryAction<
  Args,
  DirectLinkVisitorsData
>(
  {
    entity: "analytics",
    action: "queryDirectLinkVisitors",
    denormalize: false,
    pagingKey: "rows",
  },
  async ({ args, paging }) => {
    const reports = {
      asset_usage: {
        id: "direct_link_visitors",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        direct_link_id: args.directLinkId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ asset_usage: DirectLinkVisitorsData }>(args.spaceId, reports))
      .assetUsage;
  }
);

export default useDirectLinkVisitors;
export { fetchDirectLinkVisitors };
