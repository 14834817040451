import React, { useCallback, useState, useEffect } from "react";
import { Text, Input, Flex } from "@thenounproject/lingo-core";
import ModalHeader from "../../ModalHeader";
import ModalBody from "../../ModalBody";
import ModalFooter from "../../ModalFooter";

import useShowModal from "@redux/actions/useModals";
import useCreateDirectLink from "@redux/actions/assets/useCreateAssetDirectLink";

type Props = {
  assetId: string;
};

const CreateDirectLinkModal: React.FC = ({ assetId }: Props) => {
  const [linkName, setLinkName] = useState("");
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [createAssetDirectLink] = useCreateDirectLink();
  const { dismissModal } = useShowModal();

  const handleCreateLink = useCallback(async () => {
    if (!linkName.trim().length) {
      setError("A link name is required");
      return;
    }
    setProcessing(true);

    const res = await createAssetDirectLink({ assetId, name: linkName });
    if (res.isSuccess) {
      dismissModal();
    } else if (res.error) {
      setError(res.error.message);
    }
    setProcessing(false);
  }, [linkName, createAssetDirectLink, assetId, dismissModal]);

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        await handleCreateLink();
      }
    },
    [handleCreateLink]
  );

  useEffect(() => {
    if (linkName.trim().length) {
      setError("");
    }
  }, [linkName]);

  return (
    <>
      <ModalHeader title="Create Direct Link" />
      <ModalBody>
        <Flex gap="16px" flexDirection="column">
          <Text>
            Publish your asset on a website, ecommerce site, or social media platform with a direct
            link. The asset will be accessible to anyone.
          </Text>
          <Input
            label="Name"
            placeholder="Enter a link name"
            value={linkName}
            onChange={e => setLinkName(e.target.value)}
            inputStyle={error ? "error" : null}
            onKeyDown={handleKeyDown}
            message={
              error
                ? error
                : "The link name will be visible in both the asset inspector and insights"
            }
          />
        </Flex>
      </ModalBody>
      <ModalFooter
        primary={{
          text: "Create Link",
          onClick: handleCreateLink,
          disabled: processing,
        }}
      />
    </>
  );
};

export default CreateDirectLinkModal;
