import { AnyObject, API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type RequestType = "sales" | "demo" | "quote" | "trial" | "general" | "quota";

type Args = {
  email: string;
  name: string;
  message: string;
  requestType: RequestType;
};

const [useCreateSupportRequest, createSupportRequest] = createAsyncAction(
  "support/createRequest",
  async ({ name, email, message, requestType }: Args) => {
    return await API.call<AnyObject>({
      endpoint: "support/requests/",
      method: "POST",
      data: {
        name,
        email,
        message,
        request_type: requestType,
      },
    });
  }
);

export default useCreateSupportRequest;
export { createSupportRequest };
