import React, { useCallback, useState } from "react";

import { Textarea } from "@thenounproject/lingo-core";

import useReplaceCreditCard from "@actions/billing/useReplaceCreditCard";
import useNotifications from "@actions/useNotifications";
import useShowModal from "@redux/actions/useModals";

import CreditCardInput from "./CreditCardInput";
import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import { useSelectSpace } from "@redux/selectors/entities/spaces";

type Props = {
  spaceId: number;
};

const ChangeCardModal: React.FC<Props> = ({ spaceId }) => {
  const [error, setError] = useState(null),
    [processing, setProcessing] = useState(false),
    [cardComplete, setCardComplete] = useState(false),
    [stripeToken, setStripeToken] = useState(null),
    [receiptExtra, setReceiptExtra] = useState("");

  const [replaceCreditCard] = useReplaceCreditCard(),
    { showNotification } = useNotifications(),
    { dismissModal } = useShowModal(),
    space = useSelectSpace(spaceId);

  // Handlers for stripe input
  const onCardChange = useCallback(isComplete => {
    setCardComplete(isComplete);
    setError(null);
    setStripeToken(null);
  }, []);

  const onCardError = useCallback(cardError => {
    setError(cardError.message);
  }, []);

  const onTokenCreated = useCallback(token => {
    setError(null);
    setStripeToken(token);
  }, []);

  // Submit handler
  const replaceCard = useCallback(async () => {
    if (!stripeToken) return;

    setProcessing(true);
    setError(null);

    const { error: responseError } = await replaceCreditCard({
      spaceId: space.id,
      stripeToken,
      receiptExtra,
    });
    setProcessing(false);
    if (responseError) {
      return setError(responseError.message || "Oops! That didn’t work.");
    } else {
      showNotification({ message: "Your credit card has been updated." });
      dismissModal();
    }
  }, [dismissModal, receiptExtra, replaceCreditCard, showNotification, space.id, stripeToken]);

  return (
    <div>
      <ModalHeader title={`Change card for ${space.name}`} />

      <ModalBody>
        <CreditCardInput
          onChange={onCardChange}
          onTokenCreated={onTokenCreated}
          onCardError={onCardError}
          error={error}
        />

        <Textarea
          mt="m"
          label="Extra receipt info"
          onChange={e => setReceiptExtra(e.target.value)}
          value={receiptExtra}
          placeholder="VAT No., Address, Job No., etc"
        />
      </ModalBody>
      <ModalFooter
        primary={{
          disabled: processing || !cardComplete,
          onClick: replaceCard,
          text: processing ? "Saving..." : "Save",
        }}
      />
    </div>
  );
};

export default ChangeCardModal;
