import React, { useCallback, useEffect, useState } from "react";
import {
  Input,
  Notice,
  Flex,
  Icon,
  Text,
  Box,
  Button,
  ImageView,
  Textarea,
  ActivityIndicator,
  LingoError,
} from "@thenounproject/lingo-core";

import ModalBody from "../../../components/ModalBody";
import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import FilePicker from "../../../components/FilePicker";
import styled from "styled-components";
import { loadFile } from "@helpers/files";
import useShowModal from "@redux/actions/useModals";
import useUpdatePortal from "@redux/actions/portals/useUpdatePortal";
import EditAvatar from "../../../components/EditAvatar";
import usePortal from "@redux/actions/portals/usePortal";

type CoverPickerProps = {
  dashed?: boolean;
};

const PortalCoverPicker = styled(Flex).attrs<CoverPickerProps>(props => {
  return {
    width: "100%",
    minHeight: "106px",
    border: props.dashed ? "1px dashed grayLight" : undefined,
    borderRadius: "default",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
})<CoverPickerProps>``;

const PortalCoverCopy = styled(Text).attrs({
  textAlign: "center",
  alignSelf: "stretch",
  color: "grayDarker",
})``;

type Props = {
  portalId: string;
};

const EditPortalModal: React.FC<Props> = ({ portalId }) => {
  const { data: portal } = usePortal({ portalId });
  const [error, setError] = useState(null),
    [loadingCover, setLoadingCover] = useState(false),
    [newName, setNewName] = useState(""),
    [newDescription, setNewDescription] = useState(""),
    [newPortalCover, setNewPortalCover] = useState<string | ArrayBuffer>(),
    [portalCoverFile, setPortalCoverFile] = useState<File>(),
    [isDragging, setIsDragging] = useState(false);

  const handleDragOver = useCallback((e: DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);
  const handleDragEnter = useCallback(
    (e: DragEvent) => {
      handleDragOver(e);
    },
    [handleDragOver]
  );
  const handleDragLeave = useCallback((e: DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((e: DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener("dragenter", handleDragEnter, { capture: true });
    document.body.addEventListener("dragover", handleDragOver, { capture: true });
    document.body.addEventListener("drop", handleDrop, { capture: true });
    document.body.addEventListener("dragleave", handleDragLeave, { capture: true });
    return () => {
      document.body.removeEventListener("dragenter", handleDragEnter, { capture: true });
      document.body.removeEventListener("dragover", handleDragOver, { capture: true });
      document.body.removeEventListener("drop", handleDrop, { capture: true });
      document.body.removeEventListener("dragleave", handleDragLeave, { capture: true });
    };
  }, [handleDragEnter, handleDragLeave, handleDragOver, handleDrop]);

  useEffect(() => {
    setNewName(portal?.name ?? "");
    setNewDescription(portal?.description ?? "");
    setNewPortalCover(portal?.images?.cover);
  }, [portal?.description, portal?.images?.cover, portal?.name]);

  const { dismissModal } = useShowModal();
  const [updatePortal, { isProcessing }] = useUpdatePortal();

  const clearPortalCover = useCallback(() => {
    setNewPortalCover(null);
    setPortalCoverFile(null);
  }, []);
  const valid = newName.trim().length > 0,
    removeCoverImage = portal?.images?.cover && !newPortalCover,
    generatedCover = portal?.images?.cover?.includes("generated");

  const submit = useCallback(async () => {
    if (!portal) return;
    setError(null);
    const result = await updatePortal({
      id: portalId,
      name: newName,
      description: newDescription,
      coverImage: portalCoverFile,
      removeCoverImage,
    });
    if (result.error) setError(result.error.message || "Oops! That didn’t work.");
    else {
      dismissModal();
    }
  }, [
    portal,
    updatePortal,
    portalId,
    newName,
    newDescription,
    portalCoverFile,
    removeCoverImage,
    dismissModal,
  ]);

  const uploadAvatar = useCallback(
    (file: File) => {
      return updatePortal({ id: portalId, avatar: file });
    },
    [portalId, updatePortal]
  );

  const onNewPortalCover = useCallback((files: File[]) => {
    if (!files.length) return;
    const file = files[0];
    setLoadingCover(true);
    loadFile(file)
      .then(data => {
        setNewPortalCover(data);
        setLoadingCover(false);
        setPortalCoverFile(file);
      })
      .catch((err: LingoError) => {
        setLoadingCover(false);
        setError(err.message);
      });
    return Promise.resolve(null);
  }, []);

  function renderCoverPicker() {
    return (
      <Box>
        <Text id="cover-image" as="label" display="block" color="black" mb="xs">
          Cover Image
        </Text>
        <FilePicker onPickFiles={onNewPortalCover}>
          {({ openNativeFilePicker, dropZoneProps }) => {
            if (loadingCover) {
              return (
                <PortalCoverPicker>
                  <ActivityIndicator center />
                </PortalCoverPicker>
              );
            } else if (newPortalCover && !isDragging) {
              return (
                <>
                  <PortalCoverPicker onClick={openNativeFilePicker} {...dropZoneProps}>
                    <ImageView
                      src={newPortalCover as string}
                      aspectRatio={33}
                      height="100%"
                      width="100%"
                      borderRadius="default"
                      overflow="hidden"
                      cover
                    />
                  </PortalCoverPicker>
                  <Button
                    buttonStyle="tertiary"
                    text="Change"
                    size="small"
                    onClick={openNativeFilePicker}
                  />
                  {!generatedCover && (
                    <Button
                      buttonStyle="tertiary"
                      text="Remove"
                      size="small"
                      onClick={clearPortalCover}
                      mx="m"
                    />
                  )}
                </>
              );
            } else {
              return (
                <>
                  <PortalCoverPicker
                    dashed={true}
                    onClick={openNativeFilePicker}
                    {...dropZoneProps}>
                    <Icon iconId="plus" width="32" height="32" fill="grayDarker" mb="s" />
                    <PortalCoverCopy font="ui.smallBold">
                      Drag & drop or click to add a cover
                    </PortalCoverCopy>
                    <PortalCoverCopy font="ui.small">Recommended size: 960 x 320</PortalCoverCopy>
                  </PortalCoverPicker>
                  <Button
                    buttonStyle="tertiary"
                    size="small"
                    text="Change"
                    onClick={openNativeFilePicker}
                  />
                </>
              );
            }
          }}
        </FilePicker>
      </Box>
    );
  }

  function renderContent() {
    if (!portal) {
      return <ActivityIndicator height="100" center />;
    }
    return (
      <>
        <Box mb="l">
          <EditAvatar
            currentAvatar={
              portal?.images?.avatars?.avatar ?? portal?.images?.avatars?.defaultAvatar
            }
            uploadAction={uploadAvatar}
            isDragging={isDragging}
          />
        </Box>
        <Input
          id="portal-name-input"
          value={newName}
          label="Name"
          onChange={e => setNewName(e.target.value)}
          onFocus={e => {
            e.target.select();
          }}
          onSubmit={submit}
          placeholder="Enter a name"
          autoFocus
        />
        <Textarea
          id="portal-description-input"
          value={newDescription}
          label="Description"
          onChange={e => setNewDescription(e.target.value)}
          onFocus={e => {
            e.target.select();
          }}
          placeholder="Add a description"
          my="l"
        />
        {renderCoverPicker()}
        {error && <Notice noticeStyle="error" message={error} />}
      </>
    );
  }

  return (
    <>
      <ModalHeader title="Edit Portal" />
      <ModalBody px="xl">{renderContent()}</ModalBody>
      <ModalFooter
        primary={{
          id: "edit-portal-submit-button",
          text: isProcessing ? "Updating..." : "Update",
          onClick: submit,
          disabled: isProcessing || !valid,
        }}
        styleOverrides={{ px: "xl" }}
      />
    </>
  );
};

export default EditPortalModal;
