import React, { useCallback, useState, Fragment } from "react";
import {
  Input,
  Flex,
  Text,
  OptionCard,
  Space,
  PortalItemType,
  useNavigation,
  buildURL,
} from "@thenounproject/lingo-core";

import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import ModalBody from "../../ModalBody";

import useCreateKit, { TemplateName } from "@actions/kits/useCreateKit";
import useShowModal from "@redux/actions/useModals";
import useCreatePortalItems, {
  ItemData,
  InsertPosition,
} from "@redux/actions/portalItems/useCreatePortalItems";

type Props = {
  space: Space;
  includeTemplates?: boolean;
  portalId?: string;
  insertPosition?: InsertPosition;
};

const CreateKitModal: React.FC<Props> = ({
  space,
  includeTemplates = true,
  portalId,
  insertPosition,
}) => {
  const [kitName, setKitName] = useState(""),
    [templateType, setTemplateType] = useState<TemplateName>(),
    navigation = useNavigation(),
    { dismissModal } = useShowModal();

  const [createKit, { isProcessing, error }] = useCreateKit();

  const [addKitToPortal, { isProcessing: isProcessingPortal, error: portalError }] =
    useCreatePortalItems();

  const onKitCreated = useCallback(
    async (newKit: ItemData[]) => {
      const res = await addKitToPortal({ items: newKit, portalId, insertPosition });
      if (res.isSuccess) {
        dismissModal();
      }
    },
    [addKitToPortal, dismissModal, insertPosition, portalId]
  );

  const submit = useCallback(async () => {
    const res = await createKit({
      spaceId: space.id,
      name: kitName,
      templateName: templateType,
    });
    if (!res.isSuccess) return;

    const kits = res.response.entities?.kits;
    let kitId: string;
    if (portalId) {
      kitId = kits[res.response.result].kitId;
      const item: ItemData[] = [
        {
          type: PortalItemType.kit,
          kitId,
        },
      ];
      await onKitCreated(item);
    } else {
      kitId = kits[res.response.result].shortId;
      const kitUrl = buildURL(`/k/${kitId}`, { space }, { template: templateType });
      navigation.push(kitUrl);
      dismissModal();
    }
  }, [createKit, dismissModal, navigation, kitName, space, templateType, portalId, onKitCreated]);

  function renderKitTemplateButtons() {
    if (!includeTemplates) return null;

    return (
      <Flex mt="xl" flexDirection="column">
        <Text font="ui.smallBold" mb="4px">
          What type of Kit do you want to create?
        </Text>
        <OptionCard
          id="no-template"
          title="New Kit"
          cardStyle="radio"
          image={{ icon: "kit", style: "iconOnly" }}
          selected={!templateType}
          onClick={() => setTemplateType(undefined)}
        />
        <OptionCard
          id="style-guide-template"
          title="Brand Style Guide"
          cardStyle="radio"
          image={{ icon: "icon-style-guide", style: "iconOnly" }}
          selected={templateType === "styleGuide"}
          onClick={() => setTemplateType("styleGuide")}
        />
        <OptionCard
          id="ui-library-template"
          title="UI Component Library"
          cardStyle="radio"
          image={{ icon: "icon-design-system", style: "iconOnly" }}
          selected={templateType === "uiLibrary"}
          onClick={() => setTemplateType("uiLibrary")}
        />
        <OptionCard
          id="asset-library-template"
          title="Digital Asset Library"
          cardStyle="radio"
          image={{ icon: "icon-asset-library", style: "iconOnly" }}
          selected={templateType === "assetLibrary"}
          onClick={() => setTemplateType("assetLibrary")}
        />
      </Flex>
    );
  }

  return (
    <Fragment>
      <ModalHeader title="Create Kit" />
      <ModalBody>
        <Input
          id="kit-name-input"
          value={kitName}
          label="Kit Name"
          onChange={e => setKitName(e.target.value)}
          onSubmit={submit}
          placeholder="e.g. Style Guide, UI Library"
          message={error?.message || portalError?.message}
          inputStyle={error ? "error" : null}
          autoFocus
        />
        {renderKitTemplateButtons()}
      </ModalBody>
      <ModalFooter
        primary={{
          text: isProcessing || isProcessingPortal ? "Creating Kit..." : "Create Kit",
          onClick: submit,
          disabled: isProcessing || isProcessingPortal,
        }}
      />
    </Fragment>
  );
};

export default CreateKitModal;
