import { API } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

const [useSearchMeta, searchMeta] = createQueryAction<void, string[], string[]>(
  {
    entity: "search",
    action: "fetchSearchMeta",
    denormalize: false,
  },
  async () => {
    const res = await API.call<{ filters: { prefix: string; type: string }[] }>({
      endpoint: `search/meta`,
      method: "GET",
    });

    const result = res.result.filters.reduce((acc, cur) => {
      if (cur.prefix) acc.push(cur.prefix);
      return acc;
    }, [] as string[]);

    return { result, entities: {} };
  }
);

export default useSearchMeta;
export { searchMeta };
