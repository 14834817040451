import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  role: string;
  kits?: string[];
  portals?: string[];
};

const [useUpdateSpaceJoinWith, updateSpaceJoinWith] = createAsyncAction(
  "spaces/updateSpaceJoinWith",
  async ({ spaceId, role, kits, portals }: Args) => {
    return await API.call<number>({
      endpoint: `spaces/${spaceId}`,
      method: "PUT",
      entity: API.Entity.space,
      data: {
        join_settings: {
          join_with: {
            role: role,
            portals: [...new Set(portals)].map(uuid => {
              return { uuid: uuid };
            }),
            kits: [...new Set(kits)].map(uuid => {
              return { uuid: uuid, role: "guest" };
            }),
          },
        },
      },
    });
  }
);

export default useUpdateSpaceJoinWith;
export { updateSpaceJoinWith };
