import React from "react";
import { Asset } from "@thenounproject/lingo-core";

import AssetParent from "./AssetParent";
import ColorDisplay from "./ColorDisplay";

type Props = {
  asset: Asset;
  isSquare?: boolean;
};

export default function AssetColor({ asset, isSquare = true }: Props) {
  return (
    <AssetParent className="parent asset-parent-color" square={isSquare}>
      <ColorDisplay asset={asset} />
    </AssetParent>
  );
}
