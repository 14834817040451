/**
 * Removes filter identification strings from a query string
 * @param {String} query string to filter
 * @param {Object} filter filter object
 */
export function stripFilterFromQuery(query: string, filter: { entity: string }) {
  /**
   * If the query doesn't contain the identifier, it came as a
   * suggestion, rather than a token match.  In which case, clear the query.
   */
  if (!query.includes(`${filter.entity}:`)) return "";

  /**
   * Otherwise, filter out parts that include the filter prefix
   */
  return query
    .split(" ")
    .filter(part => !part.includes(`${filter.entity}:`))
    .join(" ");
}

/**
 * Removes filter identification strings from a query string
 * @param {String} query string to filter
 * @param {Object} filterType filter object
 */
export function stripFilterTypeFromQuery(query: string, filterType: string) {
  return query.replace(`${filterType}:`, "");
}
