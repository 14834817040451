import React, { useMemo, useCallback } from "react";
import {
  Box,
  Button,
  Flex,
  Space,
  formatDate,
  TableCellText,
  TableCell,
} from "@thenounproject/lingo-core";

import useAssetPortalUsage, { AssetPortalResult } from "../queries/useAssetPortalUsage";
import InsightsIntervalPicker from "../InsightsIntervalPicker";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";

type Props = {
  assetId: string;
  space: Space;
  version?: number;
};

function InsightsAssetPortalsTab({ assetId, version, space }: Props) {
  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const [sort, setSort] = React.useState<string>("-total_downloads");

  const { dateOperators } = useInsightsContext();

  const {
    data,
    isLoading: tableIsLoading,
    error: tableError,
    fetchNextPage,
  } = useAssetPortalUsage({
    spaceId: space.id,
    sort,
    dateOperators,
    itemId: assetId,
    version,
  });

  const { rows: tableData, total } = data ?? {};

  const hasMorePages = tableData?.length < total;

  const tableColumns = useMemo(() => {
    const cols: TableColumn<AssetPortalResult>[] = [
      {
        sortKey: "portal_name",
        name: "Portal Name",
        minWidth: 340,
        flexGrow: 1,
        renderer: k => {
          return <TableCell space={space} data={k} />;
        },
      },
      {
        sortKey: "kit_name",
        name: "Kit",
        minWidth: 140,
        renderer: k => <TableCellText>{k.kitName}</TableCellText>,
      },
      {
        sortKey: "section_name",
        name: "Section",
        minWidth: 140,
        renderer: k => <TableCellText>{k.sectionName}</TableCellText>,
      },
      {
        sortKey: "total_downloads",
        name: "Downloads",
        minWidth: 140,
        justify: "flex-end",
        renderer: k => <TableCellText>{k.totalDownloads}</TableCellText>,
      },
      {
        sortKey: "last_downloaded",
        name: "Last Downloaded",
        minWidth: 140,
        renderer: k => <TableCellText>{formatDate(k.lastDownloaded, "MMM d, yyyy")}</TableCellText>,
      },
      {
        sortKey: "date_added",
        name: "Date Added",
        minWidth: 140,
        renderer: k => <TableCellText>{formatDate(k.dateAdded, "MMM d, yyyy")}</TableCellText>,
      },
    ];
    return cols;
  }, [space]);

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "asset_portal_usage",
      sort,
      dateOperators,
      additionalParams: { ["item_uuid"]: assetId },
    });
  }, [assetId, dateOperators, generateInsightsCSV, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Portal Usage",
      subtitle: "The specific portals from which the asset was downloaded.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"portal-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  return (
    <Box mt="l">
      <DataTable<AssetPortalResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </Box>
  );
}

export default React.memo(InsightsAssetPortalsTab);
