import React, { useCallback } from "react";
import { Input, Flex, Button, Select, SearchOperators } from "@thenounproject/lingo-core";

import { CustomFieldInputProps } from ".";
import { CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP } from "@features/library/components/AssetLibraryFilterBar";
import { sanitizeNumberInput } from "@helpers/sanitizeNumberInput";

const valueMap: { [key: string]: string } = {
  [SearchOperators.eq]: "",
  [SearchOperators.gt]: "greater than ",
  [SearchOperators.lt]: "less than ",
};

const NumberInput: React.FC<CustomFieldInputProps> = ({ field, toggleFilter }) => {
  const [value, setValue] = React.useState<string>("");
  const [operator, setOperator] = React.useState<SearchOperators>(SearchOperators.eq);

  const handleSave = useCallback(
    (e?: React.FormEvent) => {
      e?.preventDefault();
      if (!value.trim()) return;
      toggleFilter({
        type: CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP[field.type],
        field_id: field.id,
        display: valueMap[operator].concat(value.trim()),
        id: `field-${field.id}_${operator}_${value.trim()}`,
        operator_values: {
          [operator]: value.trim(),
        },
      });
      setValue("");
    },
    [field.id, field.type, operator, toggleFilter, value]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(sanitizeNumberInput(e.target.value));
  }, []);

  return (
    <Flex flexDirection="column" mt="4px">
      <Input
        data-testid="search-custom-number-field"
        size="small"
        placeholder={"Enter a number"}
        value={value}
        onChange={handleChange}
        onSubmit={handleSave}
        type={"text"}
        styleOverrides={{ mb: "4px" }}
      />
      <Select
        value={operator}
        options={[
          { value: SearchOperators.eq, label: "Equal to" },
          { value: SearchOperators.gt, label: "Greater than" },
          { value: SearchOperators.lt, label: "Less than" },
        ]}
        size="small"
        buttonStyle="dropdown"
        onChange={val => setOperator(val as SearchOperators)}
        width="100%"
      />
      <Button
        onClick={handleSave}
        buttonStyle="secondary"
        text="Apply"
        size="small"
        fullWidth
        mt="8px"
        disabled={!value.trim()}
      />
    </Flex>
  );
};

export default NumberInput;
