import React, { ComponentProps } from "react";
import { Text, Grid, Button } from "@thenounproject/lingo-core";
import { Container, InnerSmall } from "../Elements";
import Card from "../Card";
import MarketingText from "../MarketingText";

type Props = {
  title: string;
  subtitle?: string;
  items: ComponentProps<typeof Card>[];
  footerButton?: Partial<ComponentProps<typeof Button>>;
};

const CardSection: React.FC<Props> = ({ title, subtitle, items, footerButton }) => {
  return (
    <Container>
      <InnerSmall textAlign="center" first>
        <Text font="marketing.h3">{title}</Text>
        {subtitle && (
          <MarketingText mt="m" font="marketing.bodyLarge">
            {subtitle}
          </MarketingText>
        )}
      </InnerSmall>

      <Grid
        m="0 auto"
        mt="xxl"
        maxWidth="1200"
        templateColumns="3"
        gap="24px"
        variations={{
          "mq.m": { templateColumns: "2" },
          "mq.xs": { templateColumns: "1" },
        }}>
        {items.map(item => (
          <Card key={item.title} {...item} />
        ))}
      </Grid>
      <InnerSmall mt="l" alignItems="center">
        {footerButton && <Button buttonStyle="outline" {...footerButton} />}
      </InnerSmall>
    </Container>
  );
};

export default CardSection;
