import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useCreateSpace, createSpace] = createAsyncAction(
  "spaces/createSpace",
  async ({ name, legacy }: { name: string; legacy?: boolean }) => {
    const endpoint = legacy ? "admin/spaces" : "spaces";
    return await API.call<number>({
      endpoint,
      method: "POST",
      entity: API.Entity.space,
      data: {
        name,
        legacy,
      },
    });
  }
);

export default useCreateSpace;
export { createSpace };
