import usePortal from "@redux/actions/portals/usePortal";
import { ActivityIndicator, PortalPermission, EmptyState } from "@thenounproject/lingo-core";
import React from "react";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import PortalLinkSettings from "./PortalLinkSettings";
import PortalTeamAccess from "./PortalTeamAccess";

type Props = {
  portalId: string | number;
  kitId?: string;
};

const SharePortalModal: React.FC<Props> = ({ portalId, kitId }) => {
  const { data: portal, isLoading, error, refresh } = usePortal({ portalId });
  const canViewMembers = portal?.access?.permissions.includes(PortalPermission.viewMembers);

  function renderContent() {
    if (isLoading) {
      return <ActivityIndicator center height="200px" />;
    } else if (error) {
      return (
        <EmptyState
          title="Error"
          subtitle="An error occurred while loading the portal"
          button={{ text: "Retry", onClick: refresh }}
        />
      );
    }
    if (portal) {
      return (
        <>
          <PortalLinkSettings portal={portal} kitId={kitId} />
          {canViewMembers && <PortalTeamAccess portal={portal} />}
        </>
      );
    }
  }

  return (
    <>
      <ModalHeader title={`Share ${portal?.name ?? "Portal"}`} />
      <ModalBody>{renderContent()}</ModalBody>
    </>
  );
};

export default SharePortalModal;
