import React, { useCallback, useState } from "react";

import { Flex, Kit, PopupMenu, Text, Button } from "@thenounproject/lingo-core";

import useChangeKitMemberRole from "@redux/actions/kitMembers/useUpdateKitMember";
import useRemoveKitMember from "@redux/actions/kitMembers/useRemoveKitMember";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useGetUser } from "@selectors/getters";

import { getSpaceRoleDisplayName } from "../../../../constants/displayUserRoles";
import { AvatarLarge } from "./Avatars";
import useKitMembers from "@redux/actions/kitMembers/useKitMembers";
import { useSelectSpace } from "@redux/selectors/entities/spaces";

type KitMember = ReturnType<typeof useKitMembers>["data"]["members"][0];
type Props = {
  kit: Kit;
  members: KitMember[];
};
const KitMembersList: React.FC<Props> = ({ members, kit }) => {
  const currentUser = useGetUser();
  const space = useSelectSpace(kit.spaceId);
  const [memberMenuVisible, setActiveMemberMenu] = useState(null);
  const { showModal } = useShowModal();
  const [changeKitMemberRole] = useChangeKitMemberRole();
  const [_removeKitMember] = useRemoveKitMember();
  // MARK : Actions
  // -------------------------------------------------------------------------------
  const removeKitMember = useCallback(
    async (userId: number) => {
      await _removeKitMember({
        userId,
        spaceId: kit.spaceId,
        kitId: kit.kitId,
      });
    },
    [_removeKitMember, kit.kitId, kit.spaceId]
  );

  function changeMemberRole(userId, role) {
    void changeKitMemberRole({ userId, spaceId: kit.spaceId, kitId: kit.kitId, role });
  }

  function showMemberMenu(id) {
    setActiveMemberMenu(memberMenuVisible === id ? null : id);
  }

  // MARK : Rendering
  // -------------------------------------------------------------------------------
  function renderMemberMenu(m: KitMember) {
    const canChangeKitRole = ["member", "limited_member"].includes(m.spaceRole);

    return (
      <PopupMenu source={`${m.id}-menu`} close={() => showMemberMenu(m.userId)}>
        <PopupMenu.Item
          title="Can view"
          onClick={() => changeMemberRole(m.userId, "guest")}
          checked={m.role === "guest"}
          disabled={!canChangeKitRole}
        />
        <PopupMenu.Item
          title="Can edit"
          onClick={() => changeMemberRole(m.userId, "collaborator")}
          checked={["admin", "collaborator"].includes(m.role)}
          disabled={!canChangeKitRole}
          bordered
        />
        <PopupMenu.Item
          title="Change role & access"
          disabled={m.spaceRole === "owner"}
          onClick={() =>
            showModal(ModalTypes.SPACE_MEMBER_PERMISSIONS, {
              spaceId: kit.spaceId,
              userIds: [m.userId],
            })
          }
        />
        <PopupMenu.Item
          title="Remove from Kit"
          onClick={() => removeKitMember(m.userId)}
          disabled={m.spaceRole !== "limited_member"}
        />
      </PopupMenu>
    );
  }

  function renderMember(m: KitMember) {
    const canEditMembers = kit.access?.permissions?.includes("change_member_role"),
      menuVisible = memberMenuVisible === m.userId,
      isRevoked = m.status === "revoked",
      { user, spaceRole } = m;

    return (
      <Flex id="test" key={`member-${m.id}`} py="s" alignItems="center">
        <AvatarLarge
          src={user.avatar}
          alt={`Avatar for ${user.name}`}
          style={isRevoked ? { opacity: ".25" } : {}}
        />
        <Flex flex="2" flexDirection="column">
          <Text truncate textDecoration={isRevoked ? "line-through" : null}>
            {user.name}
          </Text>
          <Text
            font="ui.small"
            color="grayDarkest"
            truncate
            textDecoration={isRevoked ? "line-through" : null}>
            {user.email}
          </Text>
        </Flex>
        {space && canEditMembers && (
          <Text flex="1" color="grayDarkest" textDecoration={isRevoked ? "line-through" : null}>
            {getSpaceRoleDisplayName(spaceRole, space)}
          </Text>
        )}
        {canEditMembers && (
          <Flex flex="1" alignItems="center" justifyContent="flex-end">
            <Button
              disabled={spaceRole === "owner" || m.userId === currentUser?.id}
              data-popup-source={`${m.id}-menu`}
              text={m.role === "guest" ? "Can view" : "Can edit"}
              buttonStyle="tertiary"
              size="small"
              icon="triangle-double"
              onClick={() => showMemberMenu(m.userId)}
            />
          </Flex>
        )}
        {menuVisible ? renderMemberMenu(m) : null}
      </Flex>
    );
  }

  if (!members) return null;
  return <Flex flexDirection="column">{members.map(renderMember)}</Flex>;
};

export default KitMembersList;
