/**
 * MultiInput that validates email addresses as user adds them.
 *
 * This validation allows pasting a list of email addresses, and it breaks them into individual
 * items while validating.
 */

import React, { useCallback } from "react";
import { factory, TokenInput, TokenInputToken } from "@thenounproject/lingo-core";

const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;

const extractEmails = (emails: string): string[] => {
  let items = [];
  if (/[,\s]/.test(emails)) {
    // If there are commas or whitespaces, use regex to extract emails.
    // Regex should deal with these formats:
    // john@example.com, jane@example.com
    // john@example.com jane@example.com
    // John <john@example.com>, Jane <jane@example.com>
    // Jane <jane@example.com>
    const extractedEmails = emails.match(EMAIL_REGEX);
    items = extractedEmails ? extractedEmails : [emails];
  } else {
    items = [emails];
  }

  return items;
};

type EmailInputProps = {
  invalidEmails?: string[];
  items: TokenInputToken[];
  inputProps: React.HTMLProps<HTMLInputElement>;
  inputWrapperRef?: React.Ref<HTMLDivElement>;
  onAddToken: (items: TokenInputToken[]) => void;
  onRemoveToken: (index: number) => void;
};

const EmailInput: React.FC<EmailInputProps> = ({ invalidEmails, items, onAddToken, ...rest }) => {
  const _addToken = useCallback(
    (text: string) => {
      const newItems = extractEmails(text).map(email => {
        const item: TokenInputToken = { isValid: true, id: factory.genUUID(), value: email };

        if (!text.includes("@")) {
          item.isValid = false;
          item.invalidReason = "Invalid email address";
        } else if (invalidEmails?.includes(email.toLowerCase())) {
          item.isValid = false;
          item.invalidReason = "Already joined or invited";
        } else if (items.find(i => i.value.toLowerCase() === email.toLowerCase())) {
          item.isValid = false;
          item.invalidReason = "Already entered";
        }
        return item;
      });
      onAddToken(newItems);
    },
    [invalidEmails, items, onAddToken]
  );
  return <TokenInput {...rest} tokens={items} onAddToken={_addToken} />;
};

export default EmailInput;
