import React, { useMemo, useCallback } from "react";
import {
  formatDate,
  Flex,
  Button,
  Space,
  Box,
  ActivityIndicator,
  TableCellText,
  TableCell,
} from "@thenounproject/lingo-core";

import Chart from "../Chart";
import InsightsIntervalPicker from "../InsightsIntervalPicker";
import useFetchAssetUserUsage from "../queries/useUserAssetDownloads";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import useAssetDownloadsByUser, { AssetUserResult } from "../queries/useAssetDownloadsByUser";
import { LoadFailure } from "../ReportElements";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";
import { formatInsightsDate } from "../utils";
import { getSpaceRoleDisplayName } from "../../../constants/displayUserRoles";

type Props = {
  assetId: string;
  space: Space;
};

const InsightsAssetUsageTab = ({ assetId, space }: Props) => {
  const [sort, setSort] = React.useState<string>("-last_downloaded");

  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const { dateOperators } = useInsightsContext();

  const { data: overviewData, error: dataFetchingError } = useFetchAssetUserUsage({
    spaceId: space.id,
    itemId: assetId,
    dateOperators,
  });

  const { result: assetUsageData } = overviewData ?? {};

  const {
    data,
    isLoading: tableIsLoading,
    fetchNextPage,
    error: tableError,
  } = useAssetDownloadsByUser({
    spaceId: space.id,
    sort,
    dateOperators,
    itemId: assetId,
  });

  const { rows: tableData, total } = data ?? {};

  const hasMorePages = tableData?.length < total;

  const { assetUsageLabels, assetUsageMembers, assetUsageVisitors } = useMemo(
    () =>
      (assetUsageData || []).reduce(
        (acc, item, i) => {
          acc.assetUsageLabels[i] = formatInsightsDate(item.date);
          acc.assetUsageMembers[i] = item.memberUses;
          acc.assetUsageVisitors[i] = item.guestUses;
          return acc;
        },
        {
          assetUsageLabels: [],
          assetUsageMembers: [],
          assetUsageVisitors: [],
        }
      ),
    [assetUsageData]
  );

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "user_asset_usage",
      sort,
      dateOperators,
      additionalParams: { ["item_uuid"]: assetId },
    });
  }, [assetId, dateOperators, generateInsightsCSV, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Downloads by User",
      subtitle: "The approved users who downloaded the asset.",
      tooltip:
        "Users include anyone joined to your space, Visitors include anyone not joined to your space.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"user-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<AssetUserResult>[] = [
      {
        sortKey: "name",
        name: "Name",
        minWidth: 340,
        flexGrow: 1,
        renderer: u => {
          return <TableCell space={space} data={u} />;
        },
      },
      {
        sortKey: "role",
        name: "Role",
        minWidth: 160,
        renderer: u => <TableCellText>{getSpaceRoleDisplayName(u.role, space)}</TableCellText>,
      },
      {
        sortKey: "total_downloads",
        name: "Downloads",
        minWidth: 160,
        justify: "flex-end",
        renderer: u => <TableCellText>{u?.totalDownloads}</TableCellText>,
      },
      {
        sortKey: "last_downloaded",
        name: "Last Downloaded",
        minWidth: 160,
        renderer: u => <TableCellText>{formatDate(u.lastDownloaded, "MMM d, yyyy")}</TableCellText>,
      },
    ];
    return cols;
  }, [space]);

  if (dataFetchingError) return <LoadFailure />;
  if (!overviewData) {
    return (
      <Box height="50vh">
        <ActivityIndicator size="large" center />
      </Box>
    );
  }

  return (
    <>
      <Box px="xl" pt="l">
        <Chart
          title="Total Downloads"
          subtitle="The number of times your asset was downloaded by users and visitors."
          tooltip="Users include anyone joined to your space, Visitors include anyone not joined to your space."
          error={!!dataFetchingError}
          headerExtra={<InsightsIntervalPicker id="kits-modal" />}
          labels={assetUsageLabels}
          values={[
            {
              label: "Uses by Users",
              data: assetUsageMembers,
              color: "primaryColorDark",
            },
            {
              label: "Uses by Visitors",
              data: assetUsageVisitors,
              color: "primaryColorLight",
            },
          ]}
          Ylabel="# of Uses"
        />
      </Box>
      <DataTable
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </>
  );
};

export default InsightsAssetUsageTab;
