import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { AssetType, Flex } from "@thenounproject/lingo-core";

import AssetImage from "./AssetImage";
import VideoPlayer from "./VideoPlayer";
import SmartImage from "../SmartImage";
import { ThumbnailContainer } from "./AssetDetail";

import useAssetOriginalUrl from "./queries/useOriginalAssetUrl";

import { Inspectable } from "@constants/Inspector";

const MotionAssetContainer = styled(Flex).attrs({
  textAlign: "center",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
})``;

export type Props = {
  inspectable: Inspectable;
  imageContainer: HTMLDivElement;
};

const MotionAsset = ({ inspectable }: Props) => {
  const { data: url } = useAssetOriginalUrl(inspectable.asset);
  const [thumbnailMode, setThumbnailMode] = useState(true);

  const assetParent = useRef(null);

  useEffect(() => {
    setThumbnailMode(true);
  }, [inspectable.asset.id]);

  function renderThumbnail() {
    return (
      <ThumbnailContainer
        onClick={() => setThumbnailMode(false)}
        cursor="pointer"
        aria-label="Play video asset">
        <AssetImage inspectable={inspectable} isThumbnail={false} />
      </ThumbnailContainer>
    );
  }

  function renderAsset() {
    const type = inspectable.asset.type;

    if (AssetType.videoTypes.has(type)) {
      return (
        <VideoPlayer
          src={url}
          placeholder={inspectable.asset.thumbnails["1232"]}
          dimensions={inspectable.asset.dimensions}
          aria-label="Play video"
        />
      );
    } else if (type === AssetType.gif) {
      return (
        <Flex width="100%" height="100%" maxWidth="616px" maxHeight="616px">
          <SmartImage src={url} spinnerCoverParent={true} displayStyle="contain" />
        </Flex>
      );
    }
  }

  return (
    <MotionAssetContainer ref={assetParent}>
      {thumbnailMode ? renderThumbnail() : renderAsset()}
    </MotionAssetContainer>
  );
};

export default MotionAsset;
