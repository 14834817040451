import { useCallback } from "react";
import { KitPermission, Kit } from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";

export enum AccessStatus {
  canAccess = "canAccess",
  insufficientPlan = "insufficientPlan",
  insufficientRole = "insufficientRole",
}

export default function (permission: KitPermission, kit: Kit) {
  const { showModal } = useShowModal();

  // Because this is a hook and must be called on every render, there are cases where we might not have a kit.Notably in the ManageFilecuts modal.
  const canAccess = kit?.access?.permissions?.includes(permission);
  const requiredPlan = kit?.access?.upgradePermissions?.[permission];

  let accessStatus = AccessStatus.insufficientRole;
  if (canAccess) {
    accessStatus = AccessStatus.canAccess;
  } else if (requiredPlan) {
    accessStatus = AccessStatus.insufficientPlan;
  }

  const openUpgradeModal = useCallback(() => {
    showModal(ModalTypes.SELECT_PLAN, {
      recommendedPlan: requiredPlan,
    });
  }, [requiredPlan, showModal]);

  return {
    openUpgradeModal,
    accessStatus,
  };
}
