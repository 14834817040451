import { API, PrivacyLevel, PublicAccessApprovalMode } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  id: string | number;
  name?: string;
  privacy?: PrivacyLevel;
  password?: string;
  indexable?: boolean;
  downloadsRestricted?: boolean;
  downloadsRestrictedApproveMode?: PublicAccessApprovalMode;
  description?: string;
  avatar?: File | null | undefined;
  coverImage?: File;
  removeCoverImage?: boolean;
  subdomain?: string;
};

const [useUpdatePortal, updatePortal] = createAsyncAction("portals/update", async (args: Args) => {
  let formData: FormData,
    data = {
      name: args.name,
      description: args.description,
      cover_reset: false,
      avatar: undefined,
      privacy: args.privacy,
      password: args.password,
      subdomain: args.subdomain,
      indexable: args.indexable,
      downloads_restricted: args.downloadsRestricted,
      downloads_restricted_approve_mode: args.downloadsRestrictedApproveMode,
    };

  if (args.removeCoverImage) {
    data.cover_reset = true;
  }
  if (args.avatar === null) {
    data.avatar = { reset: true };
  }
  if (args.avatar?.size || args.coverImage?.size) {
    formData = new FormData();
    if (args.coverImage) {
      formData.append("cover", args.coverImage);
    }
    if (args.avatar?.size) {
      formData.append("avatar", args.avatar);
    }
    formData.append("cover", args.coverImage);
    formData.append("json", JSON.stringify(data));
    data = undefined;
  }
  return await API.call<string>({
    endpoint: `/portals/${args.id}`,
    method: "PUT",
    formData,
    data,
    entity: API.Entity.portal,
  });
});

export default useUpdatePortal;
export { updatePortal };
