import React from "react";
import { Flex, Text, Button, useBoolean, PopupMenu } from "@thenounproject/lingo-core";

import AssetLibraryFilterBar from "./AssetLibraryFilterBar";
import InsertAssetMenu from "../../../components/kits/InsertAssetMenu";
import { useSelectSpace } from "@selectors/entities/spaces";
import { HeaderInner, HeaderWrapper } from "./SharedElements";
import { canCreateAssets } from "../utils";
import { useAssetLibraryContext } from "@contexts/AssetLibraryProvider";
import { useSelectSpaceFields } from "@selectors/entities/fields";
import { useGetKits, useGetNavPoint } from "@selectors/getters";

type Props = { children?: React.ReactNode };
const AssetLibraryHeader: React.FC<Props> = ({ children }) => {
  const {
    assetRowSize,
    setAssetRowSize,
    showTableCustomFields,
    setShowTableCustomFields,
    activeView,
    activePreset,
  } = useAssetLibraryContext();
  const space = useSelectSpace();
  const [isMenuOpen, showMenu, hideMenu] = useBoolean(false);
  const [viewMenuOpen, showViewMenu, hideViewMenu] = useBoolean(false);
  const fields = useSelectSpaceFields();

  const navPoint = useGetNavPoint();
  const allKits = useGetKits();

  /**
   * If we're inserting asset(s) into a kit, we want to show the kit name in the header
   */
  const pickerKitName = React.useMemo(() => {
    if (!navPoint?.kitId) return undefined;
    return allKits[navPoint.kitId]?.name;
  }, [allKits, navPoint?.kitId]);

  /**
   * If we're inserting asset(s) into a gallery, we want to show the gallery name in the header
   */
  const pickerGalleryName = React.useMemo(() => {
    if (navPoint?.type !== "Gallery" || !navPoint?.itemId) return undefined;
    return navPoint?.name;
  }, [navPoint?.itemId, navPoint?.type, navPoint?.name]);

  const title = React.useMemo(() => {
    if (pickerGalleryName) return "Add to ".concat(pickerGalleryName);
    if (pickerKitName) return "Add to ".concat(pickerKitName);
    if (activeView) return activeView.name;
    if (activePreset) return activePreset.name;
    return "All assets";
  }, [activePreset, activeView, pickerKitName, pickerGalleryName]);

  const renderActions = () => {
    return (
      <>
        <Button
          ml="s"
          text="Display"
          icon="navigation.chevron-down"
          size="small"
          buttonStyle="secondary"
          onClick={showViewMenu}
          data-popup-source="asset-library-view-menu"
        />
        <PopupMenu
          data-testid="asset-library-view-menu"
          source="asset-library-view-menu"
          vPos="floatBelow"
          hPos="alignRight"
          open={viewMenuOpen}
          close={hideViewMenu}
          stayOpenOnClick={false}
          clickAwayClose={true}>
          <PopupMenu.Item title={"Adjust Row Size"}>
            <PopupMenu.Item
              title={"Small"}
              checked={assetRowSize === "small"}
              onClick={() => setAssetRowSize("small")}
            />
            <PopupMenu.Item
              title={"Medium"}
              checked={assetRowSize === "medium"}
              onClick={() => setAssetRowSize("medium")}
            />
            <PopupMenu.Item
              title={"Large"}
              checked={assetRowSize === "large"}
              onClick={() => setAssetRowSize("large")}
            />
          </PopupMenu.Item>
          {fields?.length > 0 && (
            <PopupMenu.Item
              title={`${showTableCustomFields ? "Hide" : "Show"} Custom Fields`}
              onClick={() => setShowTableCustomFields(!showTableCustomFields)}
            />
          )}
        </PopupMenu>
        {canCreateAssets(space) && (
          <Button
            data-popup-source="asset-insert-menu"
            size="small"
            text="Add"
            icon="action.add"
            onClick={showMenu}
          />
        )}
        {isMenuOpen && (
          <InsertAssetMenu
            close={hideMenu}
            insertPosition={{ spaceId: space.id }}
            sourceId="asset-insert-menu"
          />
        )}
      </>
    );
  };

  return (
    <>
      <HeaderWrapper>
        <HeaderInner data-inspector-clear="true">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            width={"100%"}
            mb="12px"
            gap="12px"
            position="relative"
            data-inspector-clear="true">
            <Text font="ui.pageTitle" truncate>
              {title}
            </Text>
            <Flex gap="12px">{children ?? renderActions()}</Flex>
          </Flex>
          <AssetLibraryFilterBar />
        </HeaderInner>
      </HeaderWrapper>
    </>
  );
};

export default AssetLibraryHeader;
