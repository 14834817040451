import { API, Space } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";
import { selectSpace } from "@redux/selectors/entities/spaces";

function spaceHasPaidSubscription(space: Space) {
  if (space?.subscription) {
    return (
      space?.subscription?.status === "active" &&
      ["tier-0", "trial-1", "trial-2"].includes(space.subscription.plan) === false
    );
  }
  return (
    space?.plan?.status === "active" &&
    ["tier-0", "trial-1", "trial-2"].includes(space.plan.planName) === false
  );
}

type Args = {
  spaceId: number;
  planIdentifier: "starter" | "business" | "enterprise";
  interval: "month" | "year";
  date: number;
  stripeToken: string;
  receiptExtra?: string;
};

const [useUpdateSubscription, updateSubscription] = createAsyncAction(
  "space/updateSubscription",
  async (
    { spaceId, planIdentifier, interval, date, stripeToken, receiptExtra }: Args,
    thunkApi
  ) => {
    const space = selectSpace(thunkApi.getState(), { spaceId }),
      hasPaidSubscription = spaceHasPaidSubscription(space);

    const res = await API.call<number>({
      endpoint: `spaces/${spaceId}/plan/`,
      method: "POST",
      data: {
        plan_identifier: planIdentifier,
        interval,
        proration_date: date,
        stripe_token: stripeToken,
        receipt_extra: receiptExtra,
      },
      entity: API.Entity.space,
    });

    return {
      ...res,
      hasPaidSubscription,
    };
  }
);

export default useUpdateSubscription;
export { updateSubscription };
