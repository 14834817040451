import React, { ComponentProps, useCallback, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import {
  Asset,
  AssetType,
  Box,
  buildURL,
  Flex,
  ImageView,
  Item,
  ItemType,
  Kit,
  Portal,
  Section,
  Space,
  Text,
  useNavigation,
  useWindowSize,
} from "@thenounproject/lingo-core";

import { buildItemUrl } from "@redux/legacy-actions/navPoints";
import SearchResultTextContent from "./SearchResultTextContent";
import FontSample from "../kits/FontSample";
import ColorDisplay from "../kits/ColorDisplay";
import SearchResultActionButtons from "./SearchResultActionButtons";
import SearchBreadcrumb from "./SearchBreadcrumb";
import useShowModal from "@redux/actions/useModals";
import { TabletUp } from "@features/media-queries";
import getAssetBackground from "@helpers/getAssetBackground";
import { noDefaultPreviewImage, getAssetIcon } from "@helpers/getAssetIcon";

export const itemIconMap = {
  [ItemType.asset]: "content.file",
  [ItemType.heading]: "content.heading",
  [ItemType.note]: "content.note",
  [ItemType.codeSnippet]: "content.code",
  [ItemType.guide]: "content.guide",
  [ItemType.gallery]: "content.gallery",
};

type WrapperProps = { isMobile: boolean };
const SearchResultWrapper = styled(Flex).attrs<WrapperProps>({
  borderBottom: "default",
  p: "s",
  pl: "m",
  width: "100%",
  position: "relative",
  variations: {
    "&:last-child": {
      border: "none",
    },
    "@media not (hover: none)": {
      "&:hover, &:focus": {
        background: "grayLighter",
      },
    },
  },
})<WrapperProps>`
  cursor: pointer;

  @media not (hover: none) {
    &:hover .action-button,
    &:focus .action-button {
      opacity: 1;
    }
  }
`;

type Props = {
  item?: Item;
  section?: Section;
  kit?: Kit;
  space: Space;
  portal?: Portal;
};

const SearchResult: React.FC<Props> = ({ item, space, portal, section: _section, kit: _kit }) => {
  const theme = useTheme();
  const section = _section || item?.section,
    kit = _kit || item?.kit || section?.kit;

  const { isMobile } = useWindowSize();

  const { dismissModal } = useShowModal();

  const linkUrl = useMemo(() => {
    if (item) {
      return buildItemUrl(item, {
        space,
        section,
        portal,
      });
    } else if (section) return buildURL(`/s/${section.urlId}`, { space, portal });
    else if (kit) return buildURL(`/k/${kit.urlId}`, { space, portal });
  }, [item, kit, portal, section, space]);

  const navigation = useNavigation();
  const handleClick = useCallback(() => {
    navigation.push(linkUrl);
    dismissModal();
  }, [dismissModal, navigation, linkUrl]);

  function getName() {
    if (!item) return section?.name || kit?.name;
    return (
      item.data?.title ||
      item.data?.viewName ||
      item.data?.name ||
      item.asset?.name ||
      item.asset?.colors?.[0]?.name ||
      item.asset?.meta.font?.displayName ||
      (item.type === ItemType.asset ? "Unnamed Asset" : "")
    );
  }

  function renderColorThumbnail() {
    return (
      <Box
        width="120"
        height="120"
        borderRadius="default"
        position="relative"
        css={{ overflow: "hidden" }}>
        <ColorDisplay asset={item.asset} smallMode />
      </Box>
    );
  }

  function renderImageThumbnail(props: Partial<ComponentProps<typeof ImageView>>) {
    return (
      <ImageView
        iconFill="black"
        iconSize={24}
        icon={props.icon}
        srcFallback={props.srcFallback}
        alt={getName()}
        width="120"
        height="120"
        borderRadius="default"
        backgroundColor="grayLighter"
        {...props}
      />
    );
  }

  function renderAssetImage(asset: Asset) {
    return renderImageThumbnail({
      src: asset?.thumbnails?.[292],
      srcFallback: !noDefaultPreviewImage(asset) && asset?.thumbnailPlaceholders?.[292],
      icon: getAssetIcon(asset),
      ...getAssetBackground(asset, theme),
    });
  }

  function renderImage() {
    if (item?.asset?.type === AssetType.color) return renderColorThumbnail();
    if (item?.asset) {
      if (item.type === ItemType.guide) {
        return renderImageThumbnail({ icon: "content.guide" });
      }
      return renderAssetImage(item.asset);
    }
    if (item?.type) {
      return renderImageThumbnail({ icon: itemIconMap[item.type] });
    }
    if (section) {
      return renderImageThumbnail({ icon: "content.section" });
    }
    if (kit) {
      return renderImageThumbnail({ src: kit.images?.cover, icon: "content.kit" });
    }
  }

  function getTitleColor(title: string) {
    return item?.type === ItemType.guide
      ? title.toUpperCase() === "DO"
        ? "green"
        : "red"
      : "black";
  }

  function renderTitle() {
    if (item?.asset?.type === AssetType.textStyle) {
      return <FontSample asset={item.asset} isThumbnail={false} />;
    } else {
      const title = getName();
      return (
        <Text as="h1" id="result-title" font="ui.regularBold" color={getTitleColor(title)}>
          {title}
        </Text>
      );
    }
  }

  return (
    <SearchResultWrapper onClick={handleClick} isMobile={isMobile}>
      <Box flex="0" mr="m">
        {renderImage()}
      </Box>
      <Flex overflow="hidden" flex="1" justifyContent="center" flexDirection="column">
        {renderTitle()}
        <SearchResultTextContent item={item} />
        <SearchBreadcrumb kit={kit} section={section} item={item} styleOverrides={{ mt: "s" }} />
      </Flex>
      <TabletUp>
        <SearchResultActionButtons item={item} linkUrl={linkUrl} />
      </TabletUp>
    </SearchResultWrapper>
  );
};

export default SearchResult;
