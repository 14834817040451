import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  portalId: string;
};

export type PortalUserResult = {
  avatar?: string;
  lastViewed: string;
  role: string;
  name?: string;
  totalViews: number;
};

type PortalTableData = {
  rows: PortalUserResult[];
  total: number;
};

const [usePortalViewsByUser, fetchPortalViewsByUser] = createQueryAction<Args, PortalTableData>(
  {
    entity: "analytics",
    action: "queryPortalViewsByUser",
    denormalize: false,
    pagingKey: "rows",
  },
  async ({ args, paging }) => {
    const reports = {
      user_portal_usage: {
        id: "user_portal_usage",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        portal_uuid: args.portalId,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ user_portal_usage: PortalTableData }>(args.spaceId, reports))
      .userPortalUsage;
  }
);

export default usePortalViewsByUser;
export { fetchPortalViewsByUser };
