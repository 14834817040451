import React, { useCallback, useState } from "react";
import { Button, Input, Text } from "@thenounproject/lingo-core";
import ModalBody from "../../../ModalBody";
import ModalFooter from "../../../ModalFooter";
import ModalHeader from "../../../ModalHeader";
import useCreateApiToken from "@redux/actions/apiTokens/useCreateApiToken";
import useShowModal from "@redux/actions/useModals";

type Props = {
  spaceId: number;
  spaceName: string;
};

const CreateApiTokenModal = ({ spaceId, spaceName }: Props) => {
  const [createApitoken, { isProcessing, error }] = useCreateApiToken(),
    [name, setName] = useState(""),
    { dismissModal } = useShowModal();

  const submit = useCallback(async () => {
    const res = await createApitoken({ spaceId, name });
    if (res.isSuccess) {
      dismissModal();
    }
  }, [createApitoken, dismissModal, name, spaceId]);

  return (
    <>
      <ModalHeader title="Create API Token" />
      <ModalBody>
        <Text>
          By creating an API token you agree to the{" "}
          <Button
            buttonStyle="tertiary"
            text="API’s terms of use"
            link="/legal/#api-terms-of-use"
            newWindow
          />
        </Text>

        <Input
          styleOverrides={{ mt: "xl" }}
          value={name}
          label="Reference name"
          type="text"
          onChange={e => setName(e.target.value)}
          onSubmit={submit}
          placeholder={`${spaceName} API token`}
          message={error?.message ?? "Token names are only for your reference."}
          inputStyle={error ? "error" : null}
          autoFocus
        />
      </ModalBody>
      <ModalFooter
        primary={{
          disabled: isProcessing,
          text: isProcessing ? "Creating token" : "Create token",
          onClick: submit,
        }}
      />
    </>
  );
};

export default CreateApiTokenModal;
