import React from "react";
import { Flex, Button, Text, AnyObject } from "@thenounproject/lingo-core";
import DatePicker from "react-datepicker";

import { DatePickerWrapper } from "./DatePicker";

type Props = {
  onChange: (dates: Date[]) => void;
  startDate: Date;
  endDate: Date;
  withPortal?: boolean;
  popperProps?: AnyObject;
  openByDefault?: boolean;
};

const DateRangePicker = ({
  onChange,
  startDate,
  endDate,
  withPortal,
  popperProps = {},
  openByDefault,
}: Props) => {
  return (
    <DatePickerWrapper rangePicker={true}>
      <DatePicker
        dateFormatCalendar="MMMM"
        startDate={startDate}
        endDate={endDate}
        selectsRange
        onChange={onChange}
        placeholderText="mm/dd/yyyy - mm/dd/yyyy"
        monthsShown={2}
        withPortal={withPortal}
        popperProps={popperProps}
        autoFocus={openByDefault || undefined}
        renderCustomHeader={({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }) => (
          <Flex alignItems="center" mx="s">
            {customHeaderCount === 0 && (
              <Button
                buttonStyle="primary"
                icon="navigation.chevron-left"
                size="small"
                onClick={decreaseMonth}
                themeOverrides={{
                  primaryColor: "transparent",
                  primaryColorDark: "grayLight",
                  primaryColorTint: "black",
                }}
              />
            )}
            <Flex width="100%" justifyContent="center">
              <Text font="ui.small" my="13" className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </Text>
            </Flex>
            {customHeaderCount === 1 && (
              <Button
                buttonStyle="primary"
                icon="navigation.chevron-right"
                size="small"
                onClick={increaseMonth}
                themeOverrides={{
                  primaryColor: "transparent",
                  primaryColorDark: "grayLight",
                  primaryColorTint: "black",
                }}
              />
            )}
          </Flex>
        )}
      />
    </DatePickerWrapper>
  );
};

export default DateRangePicker;
