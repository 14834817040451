import React from "react";
import styled, { keyframes } from "styled-components";
import { Icon, Box, Text, useNavigation } from "@thenounproject/lingo-core";

import useNotifications, { Level, Notification } from "@actions/useNotifications";
import { useAppSelectorV1 } from "@redux/hooks";

type UseNavigation = ReturnType<typeof useNavigation>;

const NotificationContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: -204px;
  width: 408px;
`;

const showNotification = keyframes`
    0% {
      margin: 0;
      max-height: 0;
    }
    50% {
      max-height: 500px;
      opacity: 0;
      top: 24px;
    }
    100% {
      margin-bottom: 16px;
      max-height: 250px;
      opacity: 1;
      top: 0;
    }
`;

type NotificationItemProps = {
  level: Level;
};

const NotificationItem = styled(Box).attrs<NotificationItemProps>(props => {
  return {
    as: "li",
    boxShadow: "panel",
    borderRadius: "default",
    background: props.level === "error" ? "error" : "info",
    textAlign: "left",
    maxHeight: 0,
    overflow: "hidden",
    position: "relative",
  };
})<NotificationItemProps>`
  animation: ${showNotification} 0.5s 0.5s ease-out forwards;
  opacity: 0;
  & .inner {
    padding: 16px;
    margin-right: 32px;
  }
  & p {
    margin: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding: 0;
  height: 16px;
  width: 16px;
`;

const NotificationLinkText = styled(Text).attrs({ as: "span", font: "ui.small" })`
  color: white !important;
  text-decoration: underline;
  font-weight: bold;
`;

const renderMessage = (
  notification: Notification,
  navigate: UseNavigation,
  dismiss: () => void
) => {
  const { link, message } = notification;
  return (
    <React.Fragment>
      <Text
        as="span"
        color="white"
        font="ui.small"
        mr={link && "4px"}
        style={{ wordBreak: "break-all" }}>
        {String(message)}
      </Text>
      {link && (
        <a
          key={link.text}
          href={link.url}
          style={{ wordBreak: "break-all" }}
          onClick={e => {
            e.preventDefault();
            navigate.push(link.url);
            dismiss();
          }}>
          <NotificationLinkText>{link.text}</NotificationLinkText>
        </a>
      )}
    </React.Fragment>
  );
};

type Notifications = {
  [key: string]: Notification;
};

const Notifications = () => {
  const navigation = useNavigation();
  const notifications = useAppSelectorV1(state => state.notifications);
  const { dismissNotification } = useNotifications();

  if (notifications.length === 0) return null;

  return (
    <NotificationContainer>
      {notifications?.map(notification => {
        return (
          <NotificationItem key={notification.id} level={notification.level} role="status">
            <div className="inner">
              <CloseButton onClick={() => dismissNotification(notification.id)}>
                <Icon iconId="action.close" fill="white" size={16} />
              </CloseButton>
              <div>
                {renderMessage(notification, navigation, () =>
                  dismissNotification(notification.id)
                )}
              </div>
            </div>
          </NotificationItem>
        );
      })}
    </NotificationContainer>
  );
};

export default Notifications;
