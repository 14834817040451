import { Space, OperatorValues } from "@thenounproject/lingo-core";
import { INSIGHTS_PAGE_LIMIT } from "../consts";
import { createQueryAction } from "@actions/actionCreators";
import { fetchAnalytics } from "./analyticsHelpers";

type Args = {
  spaceId: Space["id"];
  sort: string;
  dateOperators?: OperatorValues;
  itemId: string;
  version?: number;
};

export type AssetPortalResult = {
  portalCover: string;
  portalName: string;
  sectionName: string;
  kitName: string;
  lastDownloaded: string;
  dateAdded: string;
  dateUpdated: string;
  totalDownloads: number;
  portalId: string;
};

type PortalTableData = {
  rows: AssetPortalResult[];
  total: number;
};

const [useAssetPortalUsage, fetchAssetPortalUsage] = createQueryAction<Args, PortalTableData>(
  {
    entity: "analytics",
    action: "queryAssetPortalUsage",
    denormalize: false,
    pagingKey: "rows",
  },
  async ({ args, paging }) => {
    const reports = {
      asset_portal_usage: {
        id: "asset_portal_usage",
        limit: INSIGHTS_PAGE_LIMIT,
        sort: args.sort,
        item_uuid: args.itemId,
        version: args.version,
        date_operators: args.dateOperators,
        page: paging.page,
      },
    };

    return (await fetchAnalytics<{ asset_portal_usage: PortalTableData }>(args.spaceId, reports))
      .assetPortalUsage;
  }
);

export default useAssetPortalUsage;
export { fetchAssetPortalUsage };
