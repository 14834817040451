import React from "react";

import {
  AssetType,
  Flex,
  Icon,
  ItemType,
  PrivacyLevel,
  Text,
  Kit,
  Item,
  Section,
  BoxProps,
  FlexProps,
} from "@thenounproject/lingo-core";
import styled from "styled-components";

const privacyIconMap = {
  [PrivacyLevel.public]: "info.globe",
  [PrivacyLevel.private]: "navigation.user-group",
  [PrivacyLevel.password]: "info.globe-lock",
};

const privacyDisplayMap = {
  [PrivacyLevel.public]: "Public",
  [PrivacyLevel.private]: "Private",
  [PrivacyLevel.password]: "Password",
};

const itemDisplayMap = {
  [ItemType.heading]: "Heading",
  [ItemType.note]: "Note",
  [ItemType.codeSnippet]: "Code Snippet",
  [ItemType.guide]: "Guide",
  [ItemType.gallery]: "Gallery",
};

const PathText = styled(Text).attrs({
  font: "ui.small",
})`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  kit: Pick<Kit, "name" | "privacy">;
  section: Pick<Section, "name">;
  item?: Partial<Item>;
  simple?: boolean;
  styleOverrides?: BoxProps & FlexProps;
};

const SearchBreadcrumb = ({ kit, section, item, simple, styleOverrides }: Props) => {
  function getItemType() {
    if (item?.type === ItemType.asset) return AssetType.displayName(item.asset.type);
    else if (item) return itemDisplayMap[item?.type];
    else if (section) return "Section";
    else if (kit) return "Kit";
  }

  function renderContext() {
    let icon: string, copy: string;
    if (!item && !section) {
      icon = privacyIconMap[kit.privacy];
      // For kits: if simple=true OR if we don't have the kit privacy, show nothing
      if (simple || !icon) return null;
      copy = privacyDisplayMap[kit.privacy];
    } else {
      icon = "content.kit";
      copy = kit?.name;
    }

    return (
      <>
        <Icon flexShrink="0" iconId={icon} fill="grayDarker" size="16" mr="xs" />
        <PathText color="grayDarker">{copy}</PathText>
        {renderSection()}
        {renderGallery()}
      </>
    );
  }

  function renderSection() {
    if (!item || !section) return null;
    if (simple && !section?.name) return null;
    return (
      <>
        <PathText color="grayLight" px={"xs"}>
          /
        </PathText>
        <PathText color="grayDarker">{section?.name || "Untitled section"}</PathText>
      </>
    );
  }
  function renderGallery() {
    const galleryName = item?.item?.data?.viewName || item?.item?.data?.name || null;
    if (!galleryName) return null;
    return (
      <>
        <PathText color="grayLight" px={"xs"}>
          /
        </PathText>
        <PathText color="grayDarker">{galleryName}</PathText>
      </>
    );
  }

  return (
    <>
      <Text font={"ui.small"} color="grayDarker" data-testid="search-result-type">
        {getItemType()}
      </Text>
      <Flex data-testid="search-breadcrumb" alignItems="center" {...styleOverrides}>
        {renderContext()}
      </Flex>
    </>
  );
};

export default SearchBreadcrumb;
