import React, { useCallback, useMemo, useState } from "react";

import {
  type Portal,
  Button,
  Flex,
  Text,
  Box,
  Icon,
  TokenInput,
  TokenInputToken,
} from "@thenounproject/lingo-core";

import useSpaceMembers from "@redux/actions/spaceMembers/useSpaceMembers";
import useModals, { ModalTypes } from "@redux/actions/useModals";

import { AvatarLarge } from "../../kits/modals/ShareKitModal/Avatars";
import useAddPortalMembers from "@redux/actions/portalMembers/useAddPortalMembers";

type Suggestion = {
  id: string;
  isValid: boolean;
  value: string;
  name: string;
  email?: string;
  avatar?: string;
  helpText?: string;
  iconId?: string;
};

type Props = {
  portal: Portal;
};
const PortalMemberInput: React.FC<Props> = ({ portal }) => {
  const { showModal } = useModals();
  const [pendingMembers, setPendingMembers] = useState<TokenInputToken[]>([]);
  const [searchText, setSearchText] = useState("");

  const [addMembers, { isProcessing, error, reset }] = useAddPortalMembers();
  const { data: spaceMembers } = useSpaceMembers(
    {
      sort: "alpha",
      role: "limited_member",
      portalId: portal.id,
      spaceId: portal.spaceId,
      name: searchText,
    },
    { refetchOnMount: true, debounce: true }
  );

  const savePendingMembers = useCallback(async () => {
    reset();
    const getUserIds = () => {
      if (pendingMembers[0].id === "add-everyone") {
        return spaceMembers.members.map(user => user.userId);
      }
      return pendingMembers.filter(m => m.isValid).map(member => Number(member.id));
    };
    const userIds = getUserIds();
    const res = await addMembers({
      portalId: portal.id,
      userIds,
    });
    if (res.error) return;
    const updatedPendingMembers = pendingMembers;
    res.response?.result?.members.forEach((member, idx) => {
      const id = userIds[idx];
      const pendingMemberIdx = updatedPendingMembers.findIndex(m => m.id === String(id));
      if (member.error) {
        const data = updatedPendingMembers[pendingMemberIdx];
        updatedPendingMembers[pendingMemberIdx] = {
          ...data,
          isValid: false,
          invalidReason: member.error.message,
        };
      } else {
        updatedPendingMembers.splice(pendingMemberIdx, 1);
      }
      setPendingMembers(updatedPendingMembers);
    });
  }, [addMembers, pendingMembers, portal.id, reset, spaceMembers?.members]);

  const onSuggestionSelected = useCallback(
      (suggestion: Suggestion) => {
        reset();
        switch (suggestion.id) {
          case "add-everyone":
            setPendingMembers([suggestion]);
            break;
          default:
            if (pendingMembers[0]?.id === "add-everyone") {
              setPendingMembers([suggestion]);
            } else {
              setPendingMembers(pendingMembers.concat(suggestion));
            }
        }
        setSearchText("");
      },
      [pendingMembers, reset]
    ),
    onRemoveToken = useCallback(
      (idx: number) => {
        reset();
        const updated = [...pendingMembers];
        updated.splice(idx, 1);
        setPendingMembers(updated);
      },
      [pendingMembers, reset]
    );

  const matchingSuggestions: Suggestion[] = useMemo(() => {
    let suggestions: Suggestion[] =
      spaceMembers?.members?.map(member => ({
        id: String(member.user.id),
        name: member.user.name,
        value: member.user.email,
        email: member.user.email,
        avatar: member.user.avatar,
        isValid: true,
      })) ?? [];
    if (!searchText) {
      suggestions = [
        {
          id: "add-everyone",
          name: "Everyone",
          value: "Everyone",
          helpText: "Add all Members to Kit",
          iconId: "users",
          isValid: true,
        },
        ...suggestions,
      ];
    }

    const pendingIds = new Set(pendingMembers.map(member => member.id));
    return suggestions.filter(suggestion => !pendingIds.has(suggestion.id));
  }, [pendingMembers, searchText, spaceMembers?.members]);

  function renderEmptyAutoComplete() {
    return (
      <Box p="xl" textAlign="center">
        <Icon iconId="users" size={48} fill="grayDark" />
        <Text font="ui.small">Can’t find who you’re looking for?</Text>
        <Button
          onClick={e => {
            e.preventDefault();
            showModal(ModalTypes.INVITE_SPACE_MEMBERS);
          }}
          buttonStyle="tertiary"
          text="Invite a new user to the team"
        />
      </Box>
    );
  }

  return (
    <Flex flexDirection="column">
      <Flex width="100%" flexDirection="row">
        <TokenInput
          inputValue={searchText}
          onChange={setSearchText}
          suggestions={matchingSuggestions}
          tokens={pendingMembers}
          onAddToken={onSuggestionSelected}
          onRemoveToken={onRemoveToken}
          renderEmptySuggestions={renderEmptyAutoComplete}
          SuggestionComponent={SuggestionComponent}
          flex="1"
        />
        <Button
          ml="s"
          disabled={isProcessing || pendingMembers.length === 0}
          onClick={savePendingMembers}
          text="Add"
          buttonStyle="secondary"
        />
      </Flex>
      {error && (
        <Text font="ui.small" color="error">
          {error.message}
        </Text>
      )}
    </Flex>
  );
};

export default PortalMemberInput;

// Duplicated from KitShareModal.tsx
// Consider merging
type SuggestionProps = {
  title: string;
  suggestion: Suggestion;
  isHighlighted: boolean;
};
const SuggestionComponent: React.FC<SuggestionProps> = ({ suggestion, isHighlighted, ...rest }) => {
  function itemContent(title: string, subtext: string) {
    return (
      <Flex flex="1" alignItems="flex-start" flexDirection="column" justifyContent="space-between">
        <Text>{title}</Text>
        <Text font="ui.small" color="grayDarkest">
          {subtext}
        </Text>
      </Flex>
    );
  }
  function renderSuggestion() {
    switch (suggestion.id) {
      case "add-everyone":
        return (
          <>
            <Flex width="40px" height="40px" alignItems="center" justifyContent="center" mr="s">
              <Icon iconId="users" />
            </Flex>
            {itemContent(suggestion.name, suggestion.helpText)}
          </>
        );
      default:
        return (
          <>
            <AvatarLarge src={suggestion.avatar} alt={`Avatar for ${suggestion.name}`} />
            {itemContent(suggestion.name, suggestion.email)}
          </>
        );
    }
  }

  return (
    <Flex
      py="s"
      px="m"
      style={{ cursor: "pointer" }}
      alignItems="center"
      backgroundColor={isHighlighted ? "grayLightest" : "white"}
      {...rest}>
      {renderSuggestion()}
    </Flex>
  );
};
