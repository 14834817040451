import { useEffect, RefObject } from "react";

type KeyHandlers = {
  onEnter?: (event: KeyboardEvent) => void;
  onEscape?: (event: KeyboardEvent) => void;
  onSpace?: (event: KeyboardEvent) => void;
  onTab?: (event: KeyboardEvent) => void;
  onArrowKey?: (event: KeyboardEvent) => void; // Generic arrow key handler
  onArrowUp?: (event: KeyboardEvent) => void;
  onArrowDown?: (event: KeyboardEvent) => void;
  onArrowLeft?: (event: KeyboardEvent) => void;
  onArrowRight?: (event: KeyboardEvent) => void;
};

type UseKeyboardOptions = {
  ref?: RefObject<HTMLElement>; // Optional ref for context-aware logic
};

export const handleKeyEvents = (event: KeyboardEvent, handlers: KeyHandlers) => {
  const arrowKeys: { [key: string]: keyof KeyHandlers } = {
    ArrowUp: "onArrowUp",
    ArrowDown: "onArrowDown",
    ArrowLeft: "onArrowLeft",
    ArrowRight: "onArrowRight",
  };

  // Define keyMap with arrow functions
  const keyMap: { [key: string]: keyof KeyHandlers } = {
    Enter: "onEnter",
    " ": "onSpace",
    Escape: "onEscape",
    Tab: "onTab",
    ...arrowKeys,
  };

  if (event.key in arrowKeys && handlers.onArrowKey) {
    event.preventDefault();
    // Gives precedence to onArrowKey handler if it exists
    handlers.onArrowKey(event);
  }

  const handlerName = keyMap[event.key];
  const handler = handlers[handlerName];

  if (handler) {
    event.preventDefault();
    handler(event);
  }
};

export const useKeyboard = (handlers: KeyHandlers, options: UseKeyboardOptions = {}) => {
  const { ref } = options;

  useEffect(() => {
    const element = ref?.current || window;

    if (!element) return;

    const onKeyDown = (event: KeyboardEvent) => handleKeyEvents(event, handlers);

    element.addEventListener("keydown", onKeyDown);
    return () => element.removeEventListener("keydown", onKeyDown);
  }, [handlers, ref]);
};

type ActionHandlers = {
  default?: () => void;
  shiftAction?: () => void;
  ctrlAction?: () => void;
  altAction?: () => void;
  metaAction?: () => void;
  spaceAction?: () => void;
};

/**
 * Handles events with optional modifier key checks (Shift, Ctrl, Alt).
 */
export const handleWithModifiers = (
  event: React.MouseEvent | KeyboardEvent | MouseEvent,
  handlers: ActionHandlers
) => {
  const {
    shiftAction,
    ctrlAction,
    altAction,
    default: defaultAction,
    metaAction,
    spaceAction,
  } = handlers;

  if (event.shiftKey && shiftAction) {
    shiftAction();
  } else if (event.ctrlKey && ctrlAction) {
    ctrlAction();
  } else if (event.altKey && altAction) {
    altAction();
  } else if (event.metaKey && metaAction) {
    metaAction();
  } else if ((event as KeyboardEvent).key === " " && spaceAction) {
    spaceAction();
  } else {
    defaultAction?.();
  }
};
