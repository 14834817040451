import React, { ComponentProps } from "react";

import { Button, Flex, ImageView, Text } from "@thenounproject/lingo-core";
import { Container, Inner } from "../Elements";
import MarketingText from "../MarketingText";

type Props = {
  title: string;
  subtitle?: string;
  primaryButton?: Partial<ComponentProps<typeof Button>>;
  secondaryButton?: Partial<ComponentProps<typeof Button>>;
  image: { src: string; alt: string };
};

const ShowcaseSection: React.FC<Props> = ({
  title,
  subtitle,
  image,
  primaryButton,
  secondaryButton,
}) => {
  function renderButtons() {
    if (primaryButton || secondaryButton) {
      return (
        <Flex mt="xl" gap="16px">
          {primaryButton && (
            <Button
              themeOverrides={{ outlineStyle: "dark" }}
              buttonStyle="outline"
              {...primaryButton}
            />
          )}
          {secondaryButton && (
            <Button
              themeOverrides={{ outlineStyle: "dark" }}
              buttonStyle="outline"
              {...secondaryButton}
            />
          )}
        </Flex>
      );
    }
  }

  return (
    <Container background="primary" my="none" py="xxl" px="none">
      <Inner
        mt="none"
        px="none"
        maxWidth="100%"
        alignItems="center"
        variations={{
          "mq.m": { flexDirection: "column" },
        }}>
        <Flex
          flexDirection="column"
          flex="1"
          alignItems="center"
          textAlign={"center"}
          maxWidth="960">
          <Text font="marketing.h2" color="white">
            {title}
          </Text>
          {subtitle ? (
            <MarketingText mt="l" font="marketing.regular" color="white">
              {subtitle}
            </MarketingText>
          ) : null}
          {renderButtons()}
        </Flex>
        <ImageView mt="xxl" width="100%" cover aspectRatio={66} flex="1" {...image} />
      </Inner>
    </Container>
  );
};

export default ShowcaseSection;
