import React from "react";
import Helmet from "react-helmet";

import ShareImage from "../../images/twitter/share.jpg";

type Props = {
  title: string;
  description: string;
  lang?: string;
  image?: string;
};

const SEO: React.FC<Props> = ({ title, description, image = ShareImage, lang = "en" }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          property: "og:title",
          content: title,
        },
        {
          property: "og:description",
          content: description,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content: image,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:creator",
          content: "@lingo_app",
        },
        {
          name: "twitter:image",
          content: image,
        },
        {
          name: "twitter:title",
          content: title,
        },
        {
          name: "twitter:description",
          content: description,
        },
      ]}
    />
  );
};
export default SEO;
