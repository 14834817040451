import React, { useMemo, useCallback } from "react";
import { Box, Button, Flex, Space, TableCellText } from "@thenounproject/lingo-core";

import InsightsIntervalPicker from "../InsightsIntervalPicker";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";
import formatBytes from "@helpers/bytesToSize";
import useAssetDirectLinkUsage, {
  AssetDirectLinksResult,
} from "../queries/useAssetDirectLinkUsage";

type Props = {
  assetId: string;
  space: Space;
  version?: number;
};

function InsightsAssetDirectLinksTab({ assetId, version, space }: Props) {
  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const [sort, setSort] = React.useState<string>("-total_requests");

  const { dateOperators } = useInsightsContext();

  const {
    data,
    isLoading: tableIsLoading,
    error: tableError,
    fetchNextPage,
  } = useAssetDirectLinkUsage({
    spaceId: space.id,
    sort,
    dateOperators,
    assetId,
    version,
  });

  const { items: tableData, total } = data ?? {};

  const hasMorePages = tableData?.length < total;

  const tableColumns = useMemo(() => {
    const cols: TableColumn<AssetDirectLinksResult>[] = [
      {
        sortKey: "direct_link_name",
        name: "Name",
        minWidth: 340,
        flexGrow: 1,
        renderer: k => {
          return <TableCellText>{k?.directLinkName || "Default"}</TableCellText>;
        },
      },
      {
        sortKey: "total_requests",
        name: "Total Requests",
        minWidth: 140,
        renderer: k => <TableCellText>{k.totalRequests}</TableCellText>,
      },
      {
        sortKey: "total_bandwidth",
        name: "Bandwidth",
        minWidth: 140,
        renderer: k => <TableCellText>{formatBytes(k.totalBandwidth)}</TableCellText>,
      },
    ];
    return cols;
  }, []);

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "asset_direct_link_usage",
      sort,
      dateOperators,
      additionalParams: { ["item_uuid"]: assetId },
    });
  }, [assetId, dateOperators, generateInsightsCSV, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Direct Links",
      subtitle: "Analytics for direct links to this asset that use the Lingo CDN.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"portal-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  return (
    <Box mt="l">
      <DataTable<AssetDirectLinksResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </Box>
  );
}

export default React.memo(InsightsAssetDirectLinksTab);
