import React, { useState, useCallback, Fragment } from "react";
import { Input, Text, Kit, ErrorCode, buildURL, useNavigation } from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import useModals, { ModalTypes } from "@redux/actions/useModals";
import useDeleteKit from "@actions/kits/useDeleteKit";
import useRemoveSharedKit from "@redux/actions/kits/useRemoveSharedKit";
import useNavPoint from "@hooks/useNavPoint";

type Props = {
  kit: Kit;
};

function DeleteKitModal({ kit: { kitId, spaceId, name: kitName, purchase = null } }: Props) {
  const [error, setError] = useState(null),
    [processing, setProcessing] = useState(false),
    [confirmation, setConfirmation] = useState(""),
    isPurchasedKit = Boolean(purchase);

  const { showModal, dismissModal } = useModals();
  const [deleteKit] = useDeleteKit();
  const [removeSharedKit] = useRemoveSharedKit();
  const { kitId: currentKit, space } = useNavPoint();

  const navigation = useNavigation();

  const handleDelete = useCallback(
    (confirmDelete = false) => {
      return (
        isPurchasedKit ? removeSharedKit({ kitId, spaceId }) : deleteKit({ kitId, confirmDelete })
      ).then(({ error: responseError }) => {
        setProcessing(false);
        if (responseError?.code === ErrorCode.needsAdditionalConfirmation) {
          dismissModal();
          return showModal(ModalTypes.CONFIRMATION, {
            title: "Update Views",
            message: responseError.message,
            buttonText: "Update",
            buttonProcessingText: "Updating...",
            onConfirm: () => handleDelete(true),
          });
        } else if (responseError) {
          setError(responseError.message || "Oops! That didn’t work.");
        } else {
          dismissModal();
          // redirect to space if we are currently viewing the deleted kit
          if (currentKit === kitId) {
            navigation.push(buildURL("/dashboard", { space }));
          }
        }
      });
    },
    [
      currentKit,
      deleteKit,
      dismissModal,
      navigation,
      isPurchasedKit,
      kitId,
      removeSharedKit,
      showModal,
      space,
      spaceId,
    ]
  );

  const submit = useCallback(() => {
    if (confirmation.toLowerCase() !== "delete") {
      setError("Please type “delete” to confirm.");
      return;
    }
    setError(null);
    setProcessing(true);
    return handleDelete(false);
  }, [confirmation, handleDelete]);

  return (
    <Fragment>
      <ModalHeader title={`Delete “${kitName}”?`} />
      <ModalBody>
        <Text>Are you sure? This action cannot be undone.</Text>

        <Input
          id="confirmation-input"
          styleOverrides={{ mt: "l" }}
          value={confirmation}
          label="Confirm by typing “delete”"
          type="text"
          onChange={e => setConfirmation(e.target.value)}
          onSubmit={submit}
          placeholder="Delete"
          message={error}
          inputStyle={error ? "error" : undefined}
          autoFocus
        />
      </ModalBody>

      <ModalFooter
        primary={{
          id: "delete-kit-submit-button",
          text: processing ? "Deleting..." : "Delete Kit",
          onClick: submit,
          disabled: processing,
        }}
      />
    </Fragment>
  );
}

export default DeleteKitModal;
