import { API, base64, Space, OperatorValues } from "@thenounproject/lingo-core";
import { Report, InsightsReport } from "../types";
import { ModalTypes, showModal } from "@redux/actions/useModals";
import { showNotification } from "@actions/useNotifications";
import { createAsyncAction } from "@redux/actions/actionCreators";

type Args = {
  spaceId: Space["id"];
  id: InsightsReport["id"];
  sort: InsightsReport["sort"];
  dateOperators: OperatorValues;
  additionalParams?: Record<string, unknown>;
};

const [useGenerateInsightsCSV, generateInsightsCSV] = createAsyncAction(
  "insights/generateCSV",
  async ({ spaceId, id, sort, dateOperators, additionalParams }: Args, thunkApi) => {
    const report: Report = {
      [id]: {
        id: id,
        sort,
        format: "csv",
        date_operators: dateOperators,
        ...additionalParams,
      },
    };
    try {
      await API.call({
        endpoint: `analytics/space/${spaceId}`,
        method: "GET",
        query: {
          reports: base64.encode(report),
        },
      });
      thunkApi.dispatch(showModal(ModalTypes.INSIGHTS_DOWNLOAD, {}, false));
    } catch (error) {
      thunkApi.dispatch(showNotification({ message: error.message, level: "error" }));
      throw error;
    }
  }
);

export default useGenerateInsightsCSV;
export { generateInsightsCSV };
