import React from "react";
import styled from "styled-components";

import { AssetType, Button, Flex, ItemType, useClipboard, Item } from "@thenounproject/lingo-core";
import AssetDownloadPopup from "../inspector/use/AssetDownloadPopup";
import ColorAssetCopyPopup from "../inspector/use/ColorAssetCopyPopup";

const ActionButtonWrapper = styled(Flex).attrs({
  position: "relative",
  alignSelf: "center",
  gap: "4px",
})``;

const ActionButton = styled(Button).attrs({
  className: "action-button",
  themeOverrides: {
    primaryColor: "black",
    primaryColorTint: "white",
  },
  size: "small",
})`
  opacity: 0;

  &:focus,
  &:highlight {
    opacity: 1;
  }
`;

type Props = {
  item: Item;
  linkUrl?: string;
};

const SearchResultActionButtons = ({ item, linkUrl }: Props) => {
  const { onCopy, hasCopied } = useClipboard(linkUrl);

  function renderDownloadButton() {
    return (
      <AssetDownloadPopup
        inspectable={{ item, asset: item.asset }}
        popupVPos="alignTop"
        popupHPos="alignRight">
        {({ buttonProps }) => (
          <ActionButton
            icon="action.download"
            {...buttonProps}
            onClick={e => {
              e.stopPropagation?.();
              buttonProps.onClick(e);
            }}
          />
        )}
      </AssetDownloadPopup>
    );
  }

  function renderCopyButton() {
    return (
      <ColorAssetCopyPopup
        inspectable={{ item, asset: item.asset }}
        popupVPos="alignTop"
        popupHPos="floatLeft">
        {buttonProps => <ActionButton icon={"action.copy"} {...buttonProps} />}
      </ColorAssetCopyPopup>
    );
  }

  function renderUseButton() {
    if (item?.asset?.type === AssetType.color) return renderCopyButton();
    else if (item?.type === ItemType.asset) return renderDownloadButton();
    else return null;
  }

  function renderLinkButton() {
    if (!linkUrl) return null;

    return (
      <ActionButton
        icon={hasCopied ? "info.checkmark" : "action.link"}
        tintColor={hasCopied ? "success" : undefined}
        onClick={e => {
          e.stopPropagation?.();
          onCopy();
        }}
      />
    );
  }

  return (
    <ActionButtonWrapper>
      {renderUseButton()}
      {renderLinkButton()}
    </ActionButtonWrapper>
  );
};

export default SearchResultActionButtons;
