import React from "react";
import { Flex, Box, SearchFilterInput } from "@thenounproject/lingo-core";

import { FilterCheckbox } from "./SearchResultsFilters";

type Props = {
  filters: SearchFilterInput[];
  selected: Set<string>;
  toggleFilter: (filter: SearchFilterInput) => void;
};

const DualColumnCheckboxes = ({ filters, selected, toggleFilter }: Props) => {
  return (
    <Flex width="100%" justifyContent="space-between">
      <Box width="50%">
        {/* Return even number filters */}
        {filters.map((f, i) => {
          if (i % 2 === 0) {
            return (
              <FilterCheckbox
                key={f.id}
                filter={f}
                isSelected={selected.has(f.id)}
                toggleFilter={toggleFilter}
              />
            );
          } else return null;
        })}
      </Box>
      <Box width="50%">
        {/* Return odd number filters */}
        {filters.map((f, i) => {
          if (i % 2 === 1) {
            return (
              <FilterCheckbox
                key={f.id}
                filter={f}
                isSelected={selected.has(f.id)}
                toggleFilter={toggleFilter}
              />
            );
          } else return null;
        })}
      </Box>
    </Flex>
  );
};

export default DualColumnCheckboxes;
