import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  kitId: string;
  portalAccess: boolean;
};
const [useUpdateKitPortalAccess, updateKitPortalAccess] = createAsyncAction(
  "kits/updatePortalAccess",
  async ({ spaceId, kitId, portalAccess }: Args) => {
    return await API.call<string>({
      endpoint: `kits/${spaceId}/${kitId}`,
      method: "PUT",
      data: {
        portal_access: Boolean(portalAccess),
      },
      entity: API.Entity.kit,
    });
  }
);
export default useUpdateKitPortalAccess;
export { updateKitPortalAccess };
