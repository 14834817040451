import { API, camelize, SpacePlan } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";
import { Platform } from "./useSubscriptionPreview";

type Args = {
  spaceId: number;
};

type SpacePlanResult = {
  plan: SpacePlan;
  legacyPlan: Platform;
};

const [useSpacePlan, fetchSpacePlan] = createQueryAction<Args, SpacePlanResult>(
  {
    entity: "spaces",
    action: "fetchSubscriptionData",
    denormalize: false,
  },
  async ({ args }) => {
    const res = await API.call<{ plan: SpacePlan }>({
      endpoint: `spaces/${args.spaceId}/plan`,
      method: "GET",
    });
    return { result: camelize(res.result) };
  }
);

export default useSpacePlan;
export { fetchSpacePlan };
