import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AssetParent from "./AssetParent";
import { Box } from "@thenounproject/lingo-core";

const VideoContainer = styled(Box).attrs({
  maxWidth: "600px",
  width: "100%",
})``;
type Props = {
  src: string;
  placeholder: string;
  dimensions: string;
};

const VideoPlayer: React.FC<Props> = ({ src, placeholder, dimensions }) => {
  const [sizeProps, setSizeProps] = useState({}),
    videoRef = useRef<HTMLVideoElement>(),
    [maxHeight, setMaxHeight] = useState(0),
    [maxWidth, setMaxWidth] = useState(0),
    playVideo = useCallback(() => {
      videoRef.current?.play().catch(() => {
        videoRef.current.muted = true;
        void videoRef.current.play();
      });
    }, []);

  const [width, height] = dimensions.split("x").map(d => parseInt(d));

  const setMaxSize = node => {
    if (!node) return;
    setMaxWidth(node.offsetWidth);
    setMaxHeight(node.offsetHeight);
  };

  useEffect(() => {
    if (width <= maxWidth) {
      if (height <= maxHeight) {
        setSizeProps({});
      } else {
        setSizeProps({ height: `${maxHeight}px` });
      }
    } else if (height <= maxHeight) {
      setSizeProps({ width: `${maxWidth}px` });
    } else {
      setSizeProps(
        maxWidth / width > maxHeight / height
          ? {
              height: `${maxHeight}px`,
            }
          : {
              width: `${maxWidth}px`,
            }
      );
    }
  }, [height, maxHeight, maxWidth, width]);

  if (!src) return null;

  const extension = src.split(/[#?]/)[0].split(".").slice(-1)[0].replace("mov", "mp4");
  return (
    <VideoContainer>
      <AssetParent className="parent asset-parent" ref={setMaxSize}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          ref={videoRef}
          {...sizeProps}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translateX(-50%) translateY(-50%)",
          }}
          poster={placeholder}
          controls
          autoPlay
          onCanPlay={playVideo}>
          <source src={src} type={`video/${extension}`} />
          <p>Sorry, this video can&apos;t be played in this browser.</p>
        </video>
      </AssetParent>
    </VideoContainer>
  );
};

export default VideoPlayer;
