import React, { useCallback, useState, Fragment } from "react";
import {
  Select,
  Notice,
  Item,
  OptionCard,
  Input,
  Tooltip,
  Box,
  SpacePermission,
} from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalHeader from "../../ModalHeader";
import ModalFooter from "../../ModalFooter";
import useShowModal from "@redux/actions/useModals";
import useNotifications from "@actions/useNotifications";
import { type InsertPosition } from "@actions/uploads";
import useCreateGallery from "@redux/actions/items/useCreateGallery";
import useUpdateItem from "@actions/items/useUpdateItem";
import { useSelectSpace } from "@selectors/entities/spaces";
import useUpsell from "@hooks/useUpsell";
import UpsellTooltip from "../../spaces/settings/UpsellTooltip";
import useAssetViews from "@redux/actions/views/useAssetViews";

type Props = {
  insertPosition?: InsertPosition;
  item?: Item;
  defaultGalleryType?: "manual" | "dynamic";
};

const textMap = {
  create: {
    title: "Add a Gallery",
    buttonText: "Add Gallery",
    processingButtonText: "Adding Gallery...",
    successMessage: "Gallery created successfully",
  },
  edit: {
    title: "Configure Gallery",
    buttonText: "Update Gallery",
    processingButtonText: "Updating Gallery...",
    successMessage: "Gallery updated successfully",
  },
};

const tooltipContent = {
  dynamicGalleryAccess: {
    text: "Dynamic galleries are not available on your current plan.",
  },
  viewsExist: {
    text: "There are no custom views in the library available for creating a gallery. Please create a new custom view.",
  },
};

const CreateGalleryItemModal: React.FC<Props> = ({ insertPosition, item, defaultGalleryType }) => {
  const mode = item ? "edit" : "create";
  const space = useSelectSpace();
  const dynamicGalleryAccess =
    space?.access?.permissions?.includes("create_dynamic_gallery_items") &&
    space?.features?.includes("dynamic_gallery_items");
  const { showNotification } = useNotifications();
  const [_createGallery, createState] = useCreateGallery();
  const [updateItem, updateState] = useUpdateItem();
  const { data: views = [] } = useAssetViews({ spaceId: space?.id });
  const { openUpgradeModal } = useUpsell(SpacePermission.createDynamicGalleryItems);

  const viewsExist = views?.length > 0;
  const [galleryType, setGalleryType] = useState(defaultGalleryType ?? null),
    [selectedView, setSelectedView] = useState(item?.data?.viewId ?? views[0]?.id),
    [galleryName, setGalleryName] = useState(item?.data?.name ?? ""),
    { dismissModal } = useShowModal();

  const error = createState.error || updateState.error;
  const isProcessing = createState.isProcessing || updateState.isProcessing;

  const createGallery = useCallback(async () => {
    const res = await _createGallery({
      insertPosition,
      viewId: galleryType === "dynamic" ? selectedView : undefined,
      name: galleryType === "manual" ? galleryName : undefined,
    });
    if (res.isSuccess) {
      showNotification({
        message: textMap[mode].successMessage,
      });
      dismissModal();
    }
  }, [
    _createGallery,
    insertPosition,
    galleryType,
    selectedView,
    galleryName,
    showNotification,
    mode,
    dismissModal,
  ]);

  const updateGallery = useCallback(async () => {
    if (selectedView === item.data?.viewId) {
      return dismissModal();
    }
    const data =
      galleryType === "dynamic" ? { view_id: selectedView } : { data: { name: galleryName } };
    const res = await updateItem({ itemId: item.id, updates: data });
    if (res.isSuccess) {
      showNotification({
        message: textMap[mode].successMessage,
      });
      dismissModal();
    }
  }, [
    selectedView,
    item?.data?.viewId,
    item?.id,
    updateItem,
    galleryType,
    galleryName,
    dismissModal,
    showNotification,
    mode,
  ]);
  const submit = item ? updateGallery : createGallery;

  const options = views?.map(view => ({
    label: view.name,
    value: view.id,
  }));

  function renderGalleryItemOptions() {
    return (
      <Fragment>
        <ModalHeader title={textMap[mode].title} />
        <ModalBody>
          <OptionCard
            id="manual-gallery"
            title="Manual Gallery"
            detail="Select the assets that will be inserted into the gallery"
            cardStyle="disclosure"
            onClick={() => setGalleryType("manual")}
          />
          <Box data-tooltip-source="dynamic-gallery">
            <OptionCard
              id="dynamic-gallery"
              title="Dynamic Gallery"
              detail="Automatically insert assets from a saved library view"
              cardStyle="disclosure"
              onClick={() => setGalleryType("dynamic")}
              disabled={!viewsExist || !dynamicGalleryAccess}
            />
          </Box>
          {!dynamicGalleryAccess && (
            <UpsellTooltip
              onClick={openUpgradeModal}
              featureName="Dynamic gallery access"
              source="dynamic-gallery"
            />
          )}
          {!viewsExist && dynamicGalleryAccess && (
            <Tooltip source="dynamic-gallery" direction={Tooltip.Direction.Top}>
              {tooltipContent.viewsExist.text}
            </Tooltip>
          )}
        </ModalBody>
      </Fragment>
    );
  }

  function renderManualOptions() {
    return (
      <Fragment>
        <ModalHeader title={textMap[mode].title} />
        <ModalBody>
          <Input
            label="Gallery Name"
            placeholder="Enter a name"
            value={galleryName}
            onChange={e => setGalleryName(e.target.value)}
            disabled={isProcessing}
            onSubmit={submit}
          />
        </ModalBody>
        {error && (
          <Notice textAlign="left" mt="m" mx="xxl" noticeStyle="error" message={error.message} />
        )}
        <ModalFooter
          primary={{
            text: !isProcessing ? textMap[mode].buttonText : textMap[mode].processingButtonText,
            onClick: submit,
            disabled: galleryName.length === 0 || galleryName === item?.data?.name || isProcessing,
          }}
        />
      </Fragment>
    );
  }

  function renderDynamicOptions() {
    return (
      <Fragment>
        <ModalHeader title={textMap[mode].title} />
        <ModalBody>
          <Select
            label="Select Library View"
            buttonStyle="dropdown"
            options={options}
            size="regular"
            value={selectedView}
            onChange={setSelectedView}
            placeholder="No views available"
            width="100%"
            disabled={!viewsExist || isProcessing}
            tooltip={
              !viewsExist && <>There are no views in the library usable for creating a gallery.</>
            }
          />
        </ModalBody>
        {error && (
          <Notice textAlign="left" mt="m" mx="xxl" noticeStyle="error" message={error.message} />
        )}
        <ModalFooter
          primary={{
            text: !isProcessing ? textMap[mode].buttonText : textMap[mode].processingButtonText,
            onClick: item ? updateGallery : createGallery,
            disabled: !selectedView || isProcessing,
          }}
        />
      </Fragment>
    );
  }

  if (galleryType === "manual") {
    return renderManualOptions();
  } else if (galleryType === "dynamic") {
    return renderDynamicOptions();
  } else return renderGalleryItemOptions();
};

export default CreateGalleryItemModal;
