import React, { useMemo, useCallback, useEffect } from "react";
import {
  Flex,
  Button,
  useBoolean,
  PopupMenu,
  Box,
  utils,
  CustomFieldTypes,
  CustomField,
  SearchFilterInput,
  SearchFilterTypes,
  SearchOperators,
  SpacePermission,
} from "@thenounproject/lingo-core";
import styled from "styled-components";

import { useAssetLibraryContext } from "../../../contexts/AssetLibraryProvider";
import { capitalize, kebabCase } from "lodash";
import { humanizedFilterNames } from "../consts";
import AssetLibraryFilterMenu from "./AssetLibraryFilterMenu";
import AssetLibraryFieldFilterMenu from "./AssetLibraryFieldFilterMenu";
import { useSelectSpaceFields } from "@selectors/entities/fields";
import { SearchQueryFilterOption } from "../types";
import AssetLibraryDateFilterOptions from "./AssetLibraryDateFilterOptions";
import { filtersAreEqual } from "./AssetLibraryNav";
import { useShowCreateEditAssetViewModal } from "../modals/CreateEditAssetViewModal";
import { useGetNavPoint } from "@selectors/getters";
import { NavPointTypes } from "@redux/legacy-actions/navPoints";
import UpsellTooltip from "../../../components/spaces/settings/UpsellTooltip";
import useUpsell, { AccessStatus } from "@hooks/useUpsell";

const Wrapper = styled(Flex).attrs({ flexDirection: "column" })``;

const MENU_SOURCE = "asset-library-filter-menu";

const FilterPillWrapper = styled(Flex).attrs({
  borderRadius: "default",
  background: "grayLight",
  overflow: "hidden",
  position: "relative",
  alignItems: "center",
})``;

const FilterPill = styled(Box).attrs({
  flexShrink: 0,
  py: "xxs",
  pl: "s",
})`
  white-space: nowrap;
  ${props => utils.getFont("ui.small", null, props)}
`;

const FilterRemovalButton = styled(Button).attrs({
  icon: "action.close",
  size: "small",
  width: "22px",
  height: "22px",
  p: "none",
  ml: "xxs",
  buttonStyle: "secondary",
  borderRadius: "none",
})``;

export const CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP: { [key: string]: SearchFilterTypes } = {
  [CustomFieldTypes.text]: SearchFilterTypes["field-text"],
  [CustomFieldTypes.date]: SearchFilterTypes["field-date"],
  [CustomFieldTypes.number]: SearchFilterTypes["field-number"],
  [CustomFieldTypes.checklist]: SearchFilterTypes["field-checklist"],
  [CustomFieldTypes.select]: SearchFilterTypes["field-select"],
};

const MENU_WIDTH_MAP = {
  [SearchFilterTypes["field-date"]]: 264,
  exact: 264,
  range: 536,
};

export const mapFieldToFilterOption = (field: CustomField): SearchQueryFilterOption => {
  return {
    type: CUSTOM_FIELD_TYPE_TO_FILTER_TYPE_MAP[field.type],
    prefix: kebabCase(field.name).toLowerCase(),
    fieldName: field.name,
    fieldId: field.id,
  };
};

const MAP_OPERATOR_TO_LABEL: Record<string, string> = {
  eq: ":",
  lt: " less than ",
  gt: " greater than ",
  gte: " greater or equal to ",
  lte: " less or equal to ",
};

const AssetLibraryFilterBar: React.FC = () => {
  const { availableFilters, appliedFilters, addFilter, removeFilter, activeView, activePreset } =
    useAssetLibraryContext();
  const [menuOpen, setMenuOpen, setMenuClosed] = useBoolean(false);

  const [selectedOption, setSelectedOption] = React.useState<SearchQueryFilterOption>(null);
  const [operator, setOperator] = React.useState<SearchOperators>();
  const [dateOption, setDateOption] = React.useState<"exact" | "range">();
  const fields = useSelectSpaceFields();
  const showAssetViewsModal = useShowCreateEditAssetViewModal();
  const navPoint = useGetNavPoint();
  const { openUpgradeModal, accessStatus } = useUpsell(SpacePermission.manageViews);
  const viewsRequireUpgrade = accessStatus !== AccessStatus.canAccess;

  const customFieldFilters: SearchQueryFilterOption[] = useMemo(() => {
    return fields.map(mapFieldToFilterOption);
  }, [fields]);

  useEffect(() => {
    if (!menuOpen && selectedOption) {
      setSelectedOption(null);
    }
    if (!menuOpen && operator) {
      setOperator(undefined);
    }
    if (!menuOpen && dateOption) {
      setDateOption(undefined);
    }
  }, [dateOption, menuOpen, operator, selectedOption]);

  const onSelectFilter = useCallback(
    (filter: SearchFilterInput) => {
      addFilter(filter);
      setMenuClosed();
    },
    [addFilter, setMenuClosed]
  );

  const renderMenuContent = () => {
    if (selectedOption) {
      const isFieldFilter = selectedOption.type.startsWith("field-");
      const sharedProps = {
        onSelectFilter,
        option: selectedOption,
        dateOption,
      };

      if (isFieldFilter) {
        const activeFilterIdsForField: number[] = appliedFilters
          .filter(f => f.field_id === selectedOption.fieldId)
          .map(f => Number(f.value))
          .filter(Boolean);
        return (
          <AssetLibraryFieldFilterMenu
            {...sharedProps}
            activeFilterValues={activeFilterIdsForField}
            field={fields.find(f => f.id === selectedOption.fieldId)}
            operator={operator}
          />
        );
      } else {
        // Find active filter values for default filters
        const activeFilterIdsForType = appliedFilters
          .filter(f => f.type === selectedOption.type)
          .map(f => f.id);
        return (
          <AssetLibraryFilterMenu {...sharedProps} activeFilterValues={activeFilterIdsForType} />
        );
      }
    }

    return (
      <>
        {availableFilters.map((filter, index) => {
          if (filter.type === SearchFilterTypes.date) {
            return (
              <PopupMenu.Item
                key={`filter-fields-${index}`}
                title={"Date Created"}
                onClick={() => {
                  setSelectedOption(filter);
                  setDateOption("exact");
                }}>
                <AssetLibraryDateFilterOptions
                  filter={filter}
                  onSelectFilter={onSelectFilter}
                  onSelectFilterOption={setSelectedOption}
                  onSelectDateInputType={setDateOption}
                />
              </PopupMenu.Item>
            );
          }
          return (
            <PopupMenu.Item
              key={`filter-${index}`}
              title={humanizedFilterNames[filter.type] || capitalize(filter.type)}
              onClick={() => setSelectedOption(filter)}
            />
          );
        })}
        {customFieldFilters.length > 0 && (
          <PopupMenu.Section>
            {customFieldFilters.map((filter, index) => {
              if (filter.type === SearchFilterTypes["field-number"]) {
                return (
                  <PopupMenu.Item
                    key={`filter-fields-${index}`}
                    title={filter.fieldName}
                    onClick={() => {
                      setOperator(SearchOperators.eq);
                      setSelectedOption(filter);
                    }}>
                    <PopupMenu.Item
                      title="Equal to"
                      onClick={() => {
                        setOperator(SearchOperators.eq);
                        setSelectedOption(filter);
                      }}
                    />
                    <PopupMenu.Item
                      title="Greater than"
                      onClick={() => {
                        setOperator(SearchOperators.gt);
                        setSelectedOption(filter);
                      }}
                    />
                    <PopupMenu.Item
                      title="Less than"
                      onClick={() => {
                        setOperator(SearchOperators.lt);
                        setSelectedOption(filter);
                      }}
                    />
                  </PopupMenu.Item>
                );
              }
              if (filter.type === SearchFilterTypes["field-date"]) {
                return (
                  <PopupMenu.Item
                    key={`filter-fields-${index}`}
                    title={filter.fieldName}
                    onClick={() => {
                      setSelectedOption(filter);
                      setDateOption("exact");
                    }}>
                    <AssetLibraryDateFilterOptions
                      filter={filter}
                      onSelectFilter={onSelectFilter}
                      onSelectFilterOption={setSelectedOption}
                      onSelectDateInputType={setDateOption}
                    />
                  </PopupMenu.Item>
                );
              }
              return (
                <PopupMenu.Item
                  key={`filter-fields-${index}`}
                  title={filter.fieldName}
                  onClick={() => setSelectedOption(filter)}
                />
              );
            })}
          </PopupMenu.Section>
        )}
      </>
    );
  };

  const renderFilterPills = () => {
    return appliedFilters.map((f, index) => {
      const type = fields.find(field => field.id === f.field_id)?.name.toLowerCase() || f.type;
      let operator = ":";
      if (f.operator_values && f.type === SearchFilterTypes["field-number"]) {
        operator = MAP_OPERATOR_TO_LABEL[Object.keys(f.operator_values)[0]];
      }
      return (
        <FilterPillWrapper key={`pill=${f.type + index}`}>
          <FilterPill>
            {kebabCase(type)}
            {operator}
            {f.display}
          </FilterPill>
          <FilterRemovalButton onClick={() => removeFilter(index)} />
        </FilterPillWrapper>
      );
    });
  };

  const isSaveButtonDisabled = useMemo(() => {
    if (activePreset || viewsRequireUpgrade) return true;
    if (!activeView) {
      return !appliedFilters.length;
    }
    return filtersAreEqual(activeView.filters, appliedFilters);
  }, [activePreset, activeView, appliedFilters, viewsRequireUpgrade]);

  return (
    <>
      <Wrapper>
        <Flex gap="16px">
          <Button
            buttonStyle="tertiary"
            text="Add filter"
            onClick={setMenuOpen}
            data-popup-source={MENU_SOURCE}
          />
          {navPoint?.type === NavPointTypes.Library && (
            <Box data-tooltip-source={"saved-views"}>
              <Button
                buttonStyle="tertiary"
                text="Save view"
                disabled={isSaveButtonDisabled}
                onClick={() => showAssetViewsModal()}
              />
              {viewsRequireUpgrade && (
                <UpsellTooltip
                  source="saved-views"
                  direction={"right"}
                  featureName="Custom views"
                  onClick={openUpgradeModal}
                  plural
                />
              )}
            </Box>
          )}
        </Flex>
        <Flex flexWrap="wrap" gap="8px" mt="8px">
          {renderFilterPills()}
        </Flex>
      </Wrapper>
      {menuOpen && (
        <PopupMenu
          source={MENU_SOURCE}
          close={setMenuClosed}
          stayOpenOnClick
          maxHeight={440}
          width={MENU_WIDTH_MAP[dateOption] || 220}>
          {renderMenuContent()}
        </PopupMenu>
      )}
    </>
  );
};

export default AssetLibraryFilterBar;
