/**
 * Shows list of kits and allows showing checkboxes and/or role pickers.
 */

import React, { useState, useEffect, useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  PopupMenu,
  Button,
  Checkbox,
  Flex,
  Box,
  Text,
  Kit,
  ImageView,
  Badge,
} from "@thenounproject/lingo-core";
import _uniq from "lodash/uniq";

const KitList = styled(Box)`
  max-height: ${props => props.height || "none"};
  overflow: visible;
`;

type KitItemProps = {
  opacity: number;
};

const KitItem = styled(Flex).attrs<KitItemProps>({
  mb: "s",
  justifyContent: "space-between",
  alignItems: "center",
})<KitItemProps>`
  opacity: ${props => props.opacity};
`;

const KitCover = styled(ImageView).attrs({
  mr: "m",
  icon: "content.kit",
  cover: true,
  height: "35px",
  flexBasis: "35px",
  flexShrink: 0,
  animate: false,
  borderRadius: "default",
  // alignItems: "center",
  // justifyContent: "center",
  background: "grayLighter",
})`
  & svg {
    margin-right: 0 !important;
  }
`;

const KitName = styled(Text).attrs({
  textAlign: "left",
  // flexGrow: 1,
})``;

const getRoleCopy = (
  role: string | string[],
  multiple?: boolean
): { label: string; description?: string } => {
  const canViewCopy = {
    label: "Viewer",
    description: multiple
      ? "Can view, download and share content from these Kits."
      : "Can view, download and share content from this Kit.",
  };
  const ROLES_COPY = {
    collaborator: {
      label: "Editor",
      description: multiple
        ? "Can edit, add or remove content from these Kits, and release new versions."
        : "Can edit, add or remove content from this Kit, and release new versions.",
    },
    guest: canViewCopy,
    resource_member: canViewCopy,
    resource_admin: canViewCopy,
    revoked: {
      label: "No access",
      description: multiple
        ? "Cannot view, edit or access these Kits."
        : "Cannot view, edit or access this Kit.",
    },
  };

  if (role.length === 1) return ROLES_COPY[role[0]] || canViewCopy;
  else return { label: "Mixed Access" };
};

type Props = {
  kits: Kit[];
  selectedKitRoles: Record<string, string[]>;
  selectRole?: (kitIds: string[], role: string) => void;
  setDefaultKitRole?: (kitIds: string[], role: string) => void;
  editable?: boolean;
  revokable?: boolean;
  className?: string;
  defaultRole?: string;
  newUser?: boolean;
};

function KitSelectList({
  kits,
  selectedKitRoles,
  selectRole,
  revokable,
  className,
  defaultRole,
  setDefaultKitRole,
  newUser = false,
}: Props) {
  const [roleMenuVisible, setRoleMenuVisible] = useState(null);
  // const [hovered, setHovered] = useState(null);
  const [selectedKits, setSelectedKits] = useState(new Set<string>());

  const isSelectable = useCallback((k: Kit) => revokable || !k.purchase, [revokable]);
  const selectableKits = useMemo(() => kits.filter(isSelectable), [isSelectable, kits]),
    allSelected = selectedKits.size === selectableKits.length,
    anySelected = selectedKits.size > 0;

  function toggleSelected(memberId) {
    if (selectedKits.has(memberId)) selectedKits.delete(memberId);
    else selectedKits.add(memberId);

    setSelectedKits(new Set(selectedKits));
  }
  const toggleSelectAll = useCallback(() => {
    if (allSelected) setSelectedKits(new Set());
    else setSelectedKits(new Set(selectableKits.map(m => m.kitId)));
  }, [allSelected, selectableKits]);

  useEffect(() => {
    const newSelected = new Set(
      selectableKits.filter(k => selectedKits.has(k.kitId)).map(k => k.kitId)
    );
    if (selectedKits.size !== newSelected.size) {
      setSelectedKits(newSelected);
    }
  }, [selectableKits, selectedKits]);

  useEffect(() => {
    if (defaultRole && setDefaultKitRole) {
      const kitIds = kits.map(k => k.kitId);
      setDefaultKitRole(kitIds, defaultRole);
    }
  }, [defaultRole, kits, setDefaultKitRole]);

  function renderRoleLabel(k: Kit) {
    const uuid = k.kitId,
      role = selectedKitRoles[uuid] || (revokable ? ["revoked"] : ["guest"]),
      isDisabled = !isSelectable(k);

    return (
      <Button
        data-popup-source={`${uuid}-role-menu`}
        buttonStyle="tertiary"
        justifySelf="flex-end"
        flexShrink="0"
        disabled={isDisabled}
        onClick={() => setRoleMenuVisible(uuid)}
        icon="triangle-double"
        text={getRoleCopy(role).label}
        size="small"
      />
    );
  }

  function renderRoleMenu(k: Kit) {
    const uuid = k.kitId;
    if (roleMenuVisible !== uuid) return;

    const currentRole = (selectedKitRoles[uuid] || (revokable ? ["revoked"] : ["guest"])).map(r =>
      r.replace("resource_admin", "collaborator").replace("resource_member", "guest")
    );

    const selectableRoles = (!k.purchase && !newUser ? ["collaborator"] : [])
      .concat(["guest"])
      .concat(revokable ? ["revoked"] : []);

    return (
      <PopupMenu
        source={`${uuid}-role-menu`}
        close={() => setRoleMenuVisible(null)}
        width={280}
        vPos="alignTop">
        {selectableRoles.map(role => {
          const roleCopy = getRoleCopy([role]),
            titleAndDescr = (
              <Box>
                <Text font="ui.regularBold">{roleCopy.label}</Text>
                <Text font="ui.small">{roleCopy.description}</Text>
              </Box>
            );

          return (
            <PopupMenu.Item
              key={role}
              title={titleAndDescr}
              checked={currentRole.includes(role)}
              onClick={() => selectRole([uuid], role)}
              alignItems="start"
            />
          );
        })}
      </PopupMenu>
    );
  }

  function renderMultipleRoleMenu() {
    if (roleMenuVisible !== "multiple") return;

    const currentRoles = _uniq(
      [...selectedKits]
        .map((id: string) =>
          (selectedKitRoles[id] || (revokable ? ["revoked"] : ["guest"])).map(r =>
            r.replace("resource_admin", "collaborator").replace("resource_member", "guest")
          )
        )
        .flat()
    );
    const containsPurchased = kits.some(k => selectedKits.has(k.kitId) && k.purchase);

    const selectableRoles = (!containsPurchased && !newUser ? ["collaborator"] : [])
      .concat(["guest"])
      .concat(revokable ? ["revoked"] : []);

    return (
      <PopupMenu
        source={`all-role-menu`}
        close={() => setRoleMenuVisible(null)}
        width={280}
        vPos="alignTop">
        {selectableRoles.map(role => {
          const roleCopy = getRoleCopy([role]),
            titleAndDescr = (
              <Box>
                <Text font="ui.regularBold">{roleCopy.label}</Text>
                <Text font="ui.small">{roleCopy.description}</Text>
              </Box>
            );

          return (
            <PopupMenu.Item
              key={role}
              title={titleAndDescr}
              checked={currentRoles.includes(role)}
              onClick={() => selectRole([...selectedKits], role)}
              alignItems="start"
            />
          );
        })}
      </PopupMenu>
    );
  }

  function renderCheckbox(kit: Kit) {
    return (
      <Checkbox
        isSelected={selectedKits.has(kit.kitId)}
        onClick={() => toggleSelected(kit.kitId)}
        styleOverrides={{ mr: "m", flexShrink: "0" }}
      />
    );
  }

  function renderKitCover(kit: Kit) {
    return <KitCover src={kit.images.cover} icon="content.kit" />;
  }

  function renderSharedBadge(kit: Kit) {
    if (!kit.purchase) return;
    return <Badge styleOverrides={{ ml: "s" }} text="Shared" />;
  }

  return (
    <div className={className}>
      <Flex mb="m">
        <Checkbox
          isSelected={allSelected}
          onClick={toggleSelectAll}
          styleOverrides={{ mr: "m", flexShrink: "0" }}
        />
        <Text font="ui.regularSemiBold" flex="1">
          {`Select all kits (${selectableKits.length})`}
        </Text>
        {anySelected ? (
          <Button
            data-popup-source={`all-role-menu`}
            buttonStyle="tertiary"
            flexShrink="0"
            onClick={() => setRoleMenuVisible("multiple")}
            icon="triangle-double"
            text="Configure selected"
            size="small"
          />
        ) : null}
      </Flex>
      {renderMultipleRoleMenu()}
      {kits.length ? (
        <KitList>
          {kits.map(k => {
            const uuid = k.kitId;

            return (
              <KitItem key={uuid} opacity={!isSelectable(k) && anySelected ? 0.5 : 1}>
                {renderCheckbox(k)}
                {renderKitCover(k)}
                <KitName>{k.name}</KitName>
                {renderSharedBadge(k)}
                <Box pl="m" flex="1" />
                {renderRoleLabel(k)}
                {renderRoleMenu(k)}
              </KitItem>
            );
          })}
        </KitList>
      ) : null}
    </div>
  );
}

export default KitSelectList;
