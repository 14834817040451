/**
 * Allows user to change member role for join link, and add/remove kits if limited member.
 */

import React, { useState, useCallback, useMemo, Fragment } from "react";
import { Flex, Box, Text, Notice, OptionCard, ToggleBox } from "@thenounproject/lingo-core";

import KitSelectList from "../../../kits/KitSelectList";

import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";
import ModalFooter from "../../../ModalFooter";

import useNotifications from "@actions/useNotifications";
import { useSelectSpace } from "@selectors/entities/spaces";
import useShowModal from "@redux/actions/useModals";
import useUpdateSpaceJoinWith from "@redux/actions/spaces/useUpdateSpaceJoinWith";
import useKits from "@redux/actions/kits/useKits";
import usePortals from "@redux/actions/portals/usePortals";
import PortalSelectList from "../../../portals/PortalSelectList";

const NewUserAccessModal: React.FC = () => {
  const space = useSelectSpace();
  const hasPortalsFeature = space.features.includes("portals");
  const hasContentManagerRole = space.features.includes("content_managers");
  const { showNotification } = useNotifications();
  const { dismissModal } = useShowModal();

  const { data } = useKits({ spaceId: space.id });
  const { kits = [] } = data || {};
  const { data: portalsData, isLoading } = usePortals(
    { spaceId: space.id },
    { skip: !hasPortalsFeature }
  );
  const { portals = [] } = portalsData || {};

  const {
    joinSettings: { joinWith },
  } = space;

  const [error, setError] = useState(null),
    [selectedRole, setSelectedRole] = useState(joinWith.role),
    [selectedKits, setSelectedKits] = useState((joinWith.kits || []).map(k => k.uuid)),
    [selectedPortals, setSelectedPortals] = useState((joinWith.portals || []).map(p => p.uuid));

  const [updateSpaceJoinWith, { isProcessing }] = useUpdateSpaceJoinWith();
  const kitRoles = useMemo(
    () =>
      selectedKits.reduce((res, k) => {
        res[k] = ["guest"];
        return res;
      }, {}),
    [selectedKits]
  );

  const submit = useCallback(async () => {
    setError(null);
    const { error: responseError } = await updateSpaceJoinWith({
      spaceId: space.id,
      role: selectedRole,
      kits: selectedKits,
      portals: hasPortalsFeature ? selectedPortals : null,
    });
    if (responseError) {
      setError(responseError.message);
    } else {
      dismissModal();
      showNotification({ message: "Changes saved.", level: "info" });
    }
  }, [
    dismissModal,
    hasPortalsFeature,
    selectedKits,
    selectedPortals,
    selectedRole,
    showNotification,
    space.id,
    updateSpaceJoinWith,
  ]);

  const toggleKits = useCallback(
    (uuids: string[], role: string) => {
      setSelectedKits(
        role === "revoked"
          ? selectedKits.filter(id => !uuids.includes(id))
          : [...selectedKits, ...uuids]
      );
    },
    [selectedKits]
  );

  const togglePortals = useCallback(
    (uuid: string) => {
      if (selectedPortals.includes(uuid)) {
        setSelectedPortals(selectedPortals.filter(id => id !== uuid));
      } else {
        setSelectedPortals([...selectedPortals, uuid]);
      }
    },
    [selectedPortals]
  );

  function renderLimitedMembersAccess() {
    if (!kits.length || selectedRole !== "limited_member") return null;

    if (!hasPortalsFeature)
      return (
        <Box mt="l">
          <Text font="ui.regularBold" mb="s">
            Choose Kits
          </Text>
          <ToggleBox title="All Kits" isOpen estimatedHeight="initial" control="none">
            <KitSelectList
              kits={kits}
              selectedKitRoles={kitRoles}
              revokable={true}
              selectRole={toggleKits}
              newUser={true}
            />
          </ToggleBox>
        </Box>
      );

    return (
      <Box mt="l">
        <Text font="ui.regularBold" mb="s">
          Portal Access
        </Text>
        <PortalSelectList
          portals={portals}
          isLoading={isLoading}
          selectedPortals={selectedPortals}
          allowPortalKits={false}
          selectPortal={togglePortals}
          selectKitRoles={toggleKits}
          selectedKitRoles={kitRoles}
        />
      </Box>
    );
  }

  function renderForm() {
    return (
      <Flex flexDirection="column" textAlign="left">
        {!hasContentManagerRole ? (
          <>
            <OptionCard
              id="new-user-access-member"
              title="Members"
              detail="Members can access all Kits. They can only edit Kits they are allowed to by an Admin or Owner."
              cardStyle="radio"
              selected={selectedRole === "member"}
              onClick={() => setSelectedRole("member")}
              flexShrink="0"
            />
            <OptionCard
              id="new-user-access-limited-member"
              title="Limited Members"
              detail="Limited members can only access or edit Kits they are allowed to by an Admin or Owner."
              cardStyle="radio"
              selected={selectedRole === "limited_member"}
              onClick={() => setSelectedRole("limited_member")}
              flexShrink="0"
            />
          </>
        ) : null}
        {renderLimitedMembersAccess()}

        {error ? <Notice textAlign="left" mt="l" noticeStyle="error" message={error} /> : null}
      </Flex>
    );
  }

  return (
    <Fragment>
      <ModalHeader title="New User Access" />
      <ModalBody>
        <Text textAlign="left" mb="l">
          New users that join your space by signing up through a join link, with an approved email
          or with SSO, will be added as
          {hasContentManagerRole ? " members with access to selected portals." : ":"}
        </Text>
        {renderForm()}
      </ModalBody>

      <ModalFooter
        primary={{
          id: "save-new-user-access-button",
          text: isProcessing ? "Saving..." : "Save",
          onClick: submit,
          disabled: isProcessing,
        }}
      />
    </Fragment>
  );
};

export default NewUserAccessModal;
