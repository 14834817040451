import React, { Fragment, useState, useCallback } from "react";
import _isEqual from "lodash/isEqual";
import {
  CustomField,
  CustomFieldTypes,
  Input,
  Flex,
  Select,
  SelectOption,
  Text,
  Checkbox,
  Button,
  Tooltip,
  Icon,
  Notice,
  ErrorCode,
} from "@thenounproject/lingo-core";

import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import ModalBody from "../../../components/ModalBody";

import { SelectOptionsForm } from "./CustomFieldsForms";
import { useSelectSpace } from "@selectors/entities/spaces";
import useCreateCustomField from "@redux/actions/fields/useCreateCustomField";
import useUpdateCustomField from "@redux/actions/fields/useUpdateCustomField";
import useNotifications from "@actions/useNotifications";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useDeleteCustomField from "@redux/actions/fields/useDeleteCustomField";
import { cloneDeep } from "lodash";

type Props = {
  field?: CustomField;
};

const buildDefaultField = (): Partial<CustomField> => ({
  name: "",
  type: CustomFieldTypes.select,
  public: true,
  status: "active",
  options: [
    { name: "", status: "active" },
    { name: "", status: "active" },
  ],
});

const buildSelectLabel = (title: string, description: string) => (
  <Flex flexDirection="column">
    <Text>{title}</Text>
    <Text font="ui.small" color="grayDarkest">
      {description}
    </Text>
  </Flex>
);

const fieldSelectOpts: SelectOption<CustomFieldTypes>[] = [
  {
    label: "Single-select",
    value: CustomFieldTypes.select,
    labelNode: buildSelectLabel(
      "Single-select",
      "For selecting a single value from a list of options. E.g. Changing an assets status to “approved”."
    ),
  },
  {
    label: "Multi-select",
    value: CustomFieldTypes.checklist,
    labelNode: buildSelectLabel(
      "Multi-select",
      "For selecting multiple values from a list of options."
    ),
  },
  {
    label: "Date",
    value: CustomFieldTypes.date,
    labelNode: buildSelectLabel("Date", "For setting a date on assets."),
  },
  {
    label: "Number",
    value: CustomFieldTypes.number,
    labelNode: buildSelectLabel("Number", "For adding any numerical value."),
  },
  {
    label: "Text field",
    value: CustomFieldTypes.text,
    labelNode: buildSelectLabel(
      "Text field",
      "For adding additional information, e.g. Campaign name, Product SKU"
    ),
  },
];

const CreateEditCustomFieldModal: React.FC<Props> = ({ field }) => {
  const [fieldData, setFieldData] = useState<Partial<CustomField>>(
    cloneDeep(field) || buildDefaultField()
  );
  const space = useSelectSpace();
  const [createField] = useCreateCustomField();
  const [updateField] = useUpdateCustomField();
  const [deleteField] = useDeleteCustomField();
  const { showNotification } = useNotifications();
  const { showModal, dismissModal } = useShowModal();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const isEditing = !!field;

  React.useEffect(() => {
    setError(null);
  }, [fieldData]);

  const handleCreateField = useCallback(async () => {
    /**
     * Remove options from the field data if the type is not a select or checklist
     */
    const sanitizedField = cloneDeep(fieldData);
    const options = [
      CustomFieldTypes.text,
      CustomFieldTypes.date,
      CustomFieldTypes.number,
    ].includes(fieldData.type)
      ? []
      : sanitizedField.options;
    sanitizedField.options = options;
    const { error } = await createField({ spaceId: space.id, field: sanitizedField });
    if (error) {
      setLoading(false);
      setError(error.message);
    } else {
      showNotification({ message: "Custom field created" });
      setLoading(false);
      dismissModal();
    }
  }, [createField, dismissModal, fieldData, showNotification, space]);

  const handleUpdateField = useCallback(
    async (confirmDelete = false) => {
      const { error } = await updateField({
        spaceId: space.id,
        field: fieldData as CustomField,
        confirmDelete,
      });

      if (error?.code === ErrorCode.needsAdditionalConfirmation) {
        dismissModal();
        return showModal(ModalTypes.CONFIRMATION, {
          title: "Update Views",
          message: error.message,
          buttonText: "Update",
          buttonProcessingText: "Updating...",
          onConfirm: () => handleUpdateField(true),
        });
      } else if (error) {
        setLoading(false);
        setError(error.message);
      } else {
        showNotification({ message: "Custom field updated" });
        setLoading(false);
        dismissModal();
      }
    },
    [dismissModal, fieldData, showModal, showNotification, space.id, updateField]
  );

  const onSubmit = useCallback(
    async (e?: React.FormEvent) => {
      e?.preventDefault();
      if (loading) return;
      setLoading(true);
      if (isEditing) {
        await handleUpdateField();
      } else {
        await handleCreateField();
      }
    },
    [loading, isEditing, handleUpdateField, handleCreateField]
  );

  const togglePrivacy = useCallback(() => {
    setFieldData(prev => ({ ...prev, public: !prev.public }));
  }, []);

  const onDeleteField = useCallback(
    (confirmDelete = false) => {
      // Response is handled in ConfirmationModal
      return deleteField({ spaceId: space.id, field: fieldData as CustomField, confirmDelete });
    },
    [deleteField, fieldData, space]
  );

  const changeFieldType = useCallback((type: CustomFieldTypes) => {
    setFieldData(prev => {
      return { ...prev, type };
    });
  }, []);

  const renderForm = useCallback(() => {
    const formProps = {
      fieldData,
      setFieldData,
    };
    switch (fieldData.type) {
      case CustomFieldTypes.select:
      case CustomFieldTypes.checklist:
        return <SelectOptionsForm {...formProps} />;
      default:
        return null;
    }
  }, [fieldData]);

  const isFieldInvalid = useCallback(() => {
    if (!fieldData.name.trim()) return true;
  }, [fieldData]);

  const buttonText = field ? "Done" : "Add";
  const headerText = field ? "Edit Custom Field" : "Add Custom Field";

  return (
    <Fragment>
      <ModalHeader title={headerText} />
      <ModalBody data-testid={fieldData.type}>
        <Flex alignItems="center" mb="24px">
          <Input
            id="custom-field-name"
            styleOverrides={{ mr: "10px" }}
            label="Name"
            value={fieldData.name}
            placeholder="Name your custom field"
            onChange={e => {
              setFieldData(prev => ({ ...prev, name: e.target.value }));
            }}
          />
          <Select<CustomFieldTypes>
            data-testid="field-type-select"
            size="regular"
            buttonStyle="dropdown"
            label="Type"
            width="150px"
            options={fieldSelectOpts}
            value={fieldData.type}
            disabled={isEditing}
            onChange={value => {
              changeFieldType(value);
            }}
          />
        </Flex>
        {renderForm()}
        <Flex>
          <Checkbox
            isSelected={!fieldData.public}
            onClick={togglePrivacy}
            label={"Hide from public"}
          />
          <Tooltip source="info-icon" direction={Tooltip.Direction.Top} width="220px">
            Only logged-in users can see this custom field when viewing assets.
          </Tooltip>
          <Icon data-tooltip-source="info-icon" iconId="info.info" />
        </Flex>
        {!isEditing && (
          <Flex mt="32px" pt="20px" borderTop="default">
            <Button
              buttonStyle="tertiary"
              text="Which field type should I use?"
              link={"/help/custom-field-types"}
              newWindow
            />
          </Flex>
        )}
        {isEditing && (
          <Flex mt="32px" pt="20px" borderTop="default">
            <Button
              buttonStyle="tertiary"
              text="Delete custom field"
              onClick={() => {
                showModal(
                  ModalTypes.CONFIRMATION,
                  {
                    title: "Delete custom field?",
                    message:
                      "This field will be deleted from your space and removed from all your assets.",
                    buttonText: "Delete",
                    buttonProcessingText: "Deleting...",
                    onConfirm: onDeleteField,

                    secondaryConfirmation: {
                      title: "Update Views",
                      buttonText: "Update",
                      buttonProcessingText: "Updating...",
                      onConfirm: () => onDeleteField(true),
                    },
                    successNotification: { message: "Custom field deleted" },
                  },
                  true
                );
              }}
            />
          </Flex>
        )}
        {error && <Notice noticeStyle="error" message={error} mt="24px" />}
      </ModalBody>
      <ModalFooter
        primary={{
          text: buttonText,
          onClick: onSubmit,
          disabled: isFieldInvalid() || loading || _isEqual(field, fieldData),
        }}
      />
    </Fragment>
  );
};

export default CreateEditCustomFieldModal;
