import React, { useCallback } from "react";
import {
  Flex,
  Select,
  Button,
  formatDate,
  Text,
  SearchFilterInput,
  SearchFilterTypes,
} from "@thenounproject/lingo-core";

import {
  getCustomDateRange,
  getDaysAgoRange,
  getTodayDateRange,
  getYesterdayDateRange,
} from "@helpers/dates";
import DateRangePicker from "@features/library/components/DateRangePicker";

type Props = {
  activeFilters: SearchFilterInput[];
  toggleFilter: (filter: SearchFilterInput) => void;
};

const CreatedDateFilter: React.FC<Props> = ({ toggleFilter, activeFilters }) => {
  const existingFilter = activeFilters.find(f => f.type === SearchFilterTypes["date"]);
  const [customDate, setCustomDate] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();

  const options = [
    { label: "All Time", value: "All Time" },
    { label: "Today", value: "Today" },
    { label: "Yesterday", value: "Yesterday" },
    { label: "Last 7 Days", value: "Last 7 Days" },
    { label: "Last 30 Days", value: "Last 30 Days" },
    { label: "Custom Date", value: "Custom Date" },
  ];

  const handleChange = useCallback(
    (val: string) => {
      if (val === "All Time" && existingFilter) return toggleFilter(existingFilter);
      if (val !== "Custom Date") {
        if (existingFilter) toggleFilter(existingFilter);
        setCustomDate(false);
        toggleFilter({
          type: SearchFilterTypes.date,
          display: val,
          id: `created-date-${val}`,
          operator_values: {
            ...(val === "Today" && getTodayDateRange()),
            ...(val === "Yesterday" && getYesterdayDateRange()),
            ...(val === "Last 7 Days" && getDaysAgoRange(7)),
            ...(val === "Last 30 Days" && getDaysAgoRange(30)),
          },
        });
      } else setCustomDate(true);
    },
    [existingFilter, toggleFilter]
  );

  const submitCustomDate = useCallback(() => {
    if (!startDate || !endDate) return;
    if (existingFilter) toggleFilter(existingFilter);

    toggleFilter({
      type: SearchFilterTypes["date"],
      display: `${formatDate(startDate.toISOString(), "MM/dd/yyyy")} - ${formatDate(
        endDate.toISOString(),
        "MM/dd/yyyy"
      )}`,
      id: `created-date-${`${startDate.toISOString()}_${endDate.toISOString()}`}`,
      operator_values: getCustomDateRange(startDate, endDate),
    });
  }, [endDate, existingFilter, startDate, toggleFilter]);

  const getSelectValue = () => {
    if (customDate || existingFilter?.id.includes("range")) return "Custom Date";
    if (existingFilter) return existingFilter.display;
    return "All Time";
  };

  return (
    <Flex flexDirection="column" mt="16px" gap="4px">
      <Text font="ui.smallBold">Date Created</Text>
      <Select
        value={getSelectValue()}
        options={options}
        size="small"
        buttonStyle="dropdown"
        onChange={handleChange}
        width="100%"
      />
      {customDate && (
        <>
          <DateRangePicker
            startDate={startDate ? new Date(startDate) : undefined}
            endDate={endDate ? new Date(endDate) : undefined}
            onChange={dates => {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
            }}
            popperProps={{ strategy: "fixed" }}
          />
          <Button
            onClick={submitCustomDate}
            buttonStyle="secondary"
            text="Apply"
            size="small"
            fullWidth
            mt="4px"
            disabled={!startDate || !endDate}
          />
        </>
      )}
    </Flex>
  );
};

export default CreatedDateFilter;
