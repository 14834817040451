import React, { useState, useCallback, Fragment } from "react";

import { Input, Text, Portal, buildURL, useNavigation } from "@thenounproject/lingo-core";

import ModalBody from "../../../components/ModalBody";
import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import useShowModal from "@redux/actions/useModals";
import { useGetNavPoint } from "@selectors/getters";
import useDeletePortal from "@actions/portals/useDeletePortal";

type Props = {
  portal: Portal;
};

function DeletePortalModal({ portal: { id, spaceId, name: portalName } }: Props) {
  const [error, setError] = useState(null),
    [confirmation, setConfirmation] = useState("");

  const { dismissModal } = useShowModal();
  const [deletePortal, { isProcessing }] = useDeletePortal();
  const navPoint = useGetNavPoint();

  const navigation = useNavigation();
  const currentPortal = navPoint?.portalId;

  const handleDelete = useCallback(() => {
    return deletePortal({ id }).then(({ error: responseError }) => {
      // This currently won't happen, but leaving it in for when we build filter assets in library by portal
      // if (responseError?.code === ErrorCode.needsAdditionalConfirmation) {
      //   dismissModal();
      //   return showModal(ModalTypes.CONFIRMATION, {
      //     title: "Update Views",
      //     message: responseError.message,
      //     buttonText: "Update",
      //     buttonProcessingText: "Updating...",
      //     onConfirm: () => handleDelete(true),
      //   });
      // } else
      if (responseError) {
        setError(responseError.message);
      } else {
        dismissModal();
        // redirect to dashboard if we are currently viewing the deleted portal
        if (currentPortal === id) {
          navigation.push(buildURL("/dashboard", { space: { id: spaceId } }));
        }
      }
    });
  }, [currentPortal, deletePortal, dismissModal, navigation, id, spaceId]);

  const submit = useCallback(() => {
    if (confirmation.toLowerCase() !== "delete") {
      setError("Please type “delete” to confirm.");
      return;
    }
    setError(null);
    return handleDelete();
  }, [confirmation, handleDelete]);

  return (
    <Fragment>
      <ModalHeader title={`Delete “${portalName}”?`} />
      <ModalBody>
        <Text>Are you sure? This action cannot be undone.</Text>

        <Input
          id="confirmation-input"
          styleOverrides={{ mt: "l" }}
          value={confirmation}
          label="Confirm by typing “delete”"
          type="text"
          onChange={e => setConfirmation(e.target.value)}
          onSubmit={submit}
          placeholder="Delete"
          message={error}
          inputStyle={error ? "error" : undefined}
          autoFocus
        />
      </ModalBody>

      <ModalFooter
        primary={{
          id: "delete-portal-submit-button",
          text: isProcessing ? "Deleting..." : "Delete Portal",
          onClick: submit,
          disabled: isProcessing,
        }}
      />
    </Fragment>
  );
}

export default DeletePortalModal;
