import React from "react";
import { useVirtualizer } from "@tanstack/react-virtual";

import FigmaSearchResult from "./FigmaSearchResult";

type Props = {
  results: FigmaNode[];
  onSelect: (node_id?: string) => void;
  selectedNodes: string[];
};

const FigmaVirtualizedSearchResults: React.FC<Props> = ({ results, onSelect, selectedNodes }) => {
  const virtualScrollRef = React.useRef<HTMLDivElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: results.length,
    getScrollElement: () => virtualScrollRef.current,
    estimateSize: React.useCallback(() => 62, []),
    overscan: 5,
  });

  const innerHeight = React.useMemo(() => `${rowVirtualizer.getTotalSize()}px`, [rowVirtualizer]);

  return (
    <div
      style={{ height: innerHeight, width: "100%", position: "relative" }}
      ref={virtualScrollRef}>
      {rowVirtualizer.getVirtualItems().map(virtualRow => (
        <div
          key={virtualRow.index}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: `${virtualRow.size}px`,
            transform: `translateY(${virtualRow.start}px)`,
          }}>
          <FigmaSearchResult
            data={results[virtualRow.index]}
            onSelect={onSelect}
            selectedNodes={selectedNodes}
          />
        </div>
      ))}
    </div>
  );
};

export default FigmaVirtualizedSearchResults;
