import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 *
 * Marketing site no longer works with the old ScrollToTopOnMount component.
 * We need access to the changing pathname. I didn't refactor the old component
 *  in case it has a chain of effects that would need to be fixed.
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
