import React, { useState, useCallback } from "react";
import styled from "styled-components";
import {
  Button,
  Text,
  Box,
  Flex,
  Textarea,
  Notice,
  ActivityIndicator,
  estimatePlanCosts,
  formatPrice,
  analyzeChargePreview,
} from "@thenounproject/lingo-core";

import CreditCardInput from "./CreditCardInput";
import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import useUpdateSubscription from "@redux/actions/billing/useUpdateSubscription";
import useChargePreview from "@redux/actions/billing/useChargePreview";
import { useSelectSpace } from "@redux/selectors/entities/spaces";
import useBillingData from "@redux/actions/billing/useBillingData";
import useNotifications from "@redux/actions/useNotifications";
import useModals from "@redux/actions/useModals";

type Props = {
  spaceId: number;
  planIdentifier: "starter" | "business" | "enterprise";
  interval: "month" | "year";
};

const ChargeLines = styled(Flex).attrs({
  flexDirection: "column",
  mt: "xl",
})``;

const ChargeLine = styled(Text).attrs<{ color: string }>({ mb: "s", color: "grayDarkest" })``;

const ChangeSubscriptionModal: React.FC<Props> = ({ spaceId, planIdentifier, interval }) => {
  const { showNotification } = useNotifications();
  const { dismissModal } = useModals();
  const space = useSelectSpace(spaceId);
  const { data: accountData } = useBillingData({ spaceId }, { refetchOnMount: true });
  const accountCard = accountData?.card;
  const [error, setError] = useState(null),
    [processing, setProcessing] = useState(false),
    [replaceCard, setReplacingCard] = useState(false),
    [cardComplete, setCardComplete] = useState<boolean>(false),
    [stripeToken, setStripeToken] = useState(null),
    [receiptExtra, setReceiptExtra] = useState(""),
    addingCard = replaceCard || !accountCard;

  const [updateSubscription] = useUpdateSubscription(),
    {
      status: previewStatus,
      data: { chargePreview, plan } = {},
      error: previewError,
    } = useChargePreview({ spaceId: space.id, planIdentifier, interval }, { refetchOnMount: true });

  const toggleCardInput = useCallback(() => {
    setStripeToken(null);
    setReplacingCard(!replaceCard);
    setError(null);
  }, [replaceCard]);

  // Handlers for stripe input
  const onCardChange = useCallback((isComplete: boolean) => {
    setCardComplete(isComplete);
    setError(null);
    setStripeToken(null);
  }, []);

  const onCardError = useCallback(cardError => {
    setError(cardError.message);
  }, []);

  const onTokenCreated = useCallback(token => {
    setError(null);
    setStripeToken(token);
  }, []);

  const submit = useCallback(async () => {
    const card = addingCard ? stripeToken : accountCard;
    if (!card) return;

    setProcessing(true);
    const { error: responseError } = await updateSubscription({
      spaceId: space.id,
      planIdentifier: plan.planIdentifier,
      interval: plan.interval,
      date: chargePreview.prorationDate,
      stripeToken,
      receiptExtra,
    });
    setProcessing(false);
    if (responseError) {
      setError(responseError.message || "Oops! That didn’t work.");
    } else {
      showNotification({ message: "Plan updated successfully." });
      dismissModal();
    }
  }, [
    addingCard,
    stripeToken,
    accountCard,
    updateSubscription,
    space.id,
    plan,
    chargePreview?.prorationDate,
    receiptExtra,
    showNotification,
    dismissModal,
  ]);

  const openHelp = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window?.Intercom("showNewMessage");
  }, []);

  function renderPlanDescription() {
    if (!plan) return null;
    const estimate = estimatePlanCosts(space, plan);
    const interval = plan.interval === "year" ? "Annual" : "Monthly";
    return `${plan.planName} (${interval}) (${formatPrice(estimate.total, { round: true })}/mo)`;
  }

  function renderChargePreview() {
    if (!chargePreview) return;

    const { newCharges, creditApplied, remainingCredit, totalDueToday } =
      analyzeChargePreview(chargePreview);

    return (
      <ChargeLines>
        {newCharges ? (
          <ChargeLine>
            <strong>Amount due:</strong> {formatPrice(newCharges)}
          </ChargeLine>
        ) : null}
        {creditApplied ? (
          <ChargeLine>
            <strong>Credit applied:</strong> {formatPrice(creditApplied)}
          </ChargeLine>
        ) : null}
        <ChargeLine color="black" my="m">
          <strong>Total today:</strong> {formatPrice(totalDueToday)}
        </ChargeLine>
        {remainingCredit ? (
          <ChargeLine font="ui.small">
            <strong>Remaining credit (for next billing cycles):</strong>
            {formatPrice(remainingCredit)}
          </ChargeLine>
        ) : null}
      </ChargeLines>
    );
  }

  function renderCard() {
    return (
      <Box data-testid="saved-card-info" textAlign="left">
        <Text>
          Charge to {accountCard.brand} on file
          <br />
          <span title={`Expiration: ${accountCard.expMonth} / ${accountCard.expYear}`}>
            &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;{" "}
            {accountCard.last4}
          </span>
        </Text>
        <Button
          id="change-card-button"
          buttonStyle="tertiary"
          size="small"
          text="Replace card"
          onClick={toggleCardInput}
        />
      </Box>
    );
  }

  function renderCardInput() {
    return (
      <>
        <CreditCardInput
          onChange={onCardChange}
          onTokenCreated={onTokenCreated}
          onCardError={onCardError}
          error={error}
        />

        <Box textAlign="left">
          <Textarea
            mt="m"
            label="Extra receipt info"
            onChange={e => setReceiptExtra(e.target.value)}
            value={receiptExtra}
            placeholder="VAT No., Address, Job No., etc"
          />
          {accountCard && (
            <Button
              id="cancel-change-card-button"
              mt="s"
              buttonStyle="tertiary"
              size="small"
              text="Cancel and use existing card"
              onClick={toggleCardInput}
            />
          )}
        </Box>
      </>
    );
  }

  function renderBody() {
    if (previewStatus === "pending") {
      return (
        <Box height="150">
          <ActivityIndicator center />
        </Box>
      );
    } else if (previewError) {
      return (
        <Notice
          mb="l"
          message={previewError.message}
          noticeStyle="error"
          button={{
            text: "Contact us",
            onClick: openHelp,
          }}
        />
      );
    } else {
      const showCardOnFile = chargePreview && !addingCard,
        showCardInput = chargePreview && addingCard;
      return (
        <>
          {showCardOnFile ? renderCard() : null}
          {showCardInput ? renderCardInput() : null}
          {!showCardInput && error ? <Notice mt="l" message={error} noticeStyle="error" /> : null}
          {renderChargePreview()}
        </>
      );
    }
  }

  function renderFooter() {
    const canSubmit = !processing && previewStatus === "fulfilled" && (!addingCard || cardComplete);
    let buttonText = "Save plan";
    if (chargePreview?.total > 0) {
      buttonText = "Charge & upgrade";
    } else if (processing) {
      buttonText = "Processing...";
    }
    return (
      <ModalFooter
        primary={{
          id: "submit-button",
          text: buttonText,
          disabled: !canSubmit,
          onClick: submit,
        }}
      />
    );
  }

  return (
    <>
      <ModalHeader
        title="Finish upgrade"
        message={
          <>
            Upgrading <strong>{space.name}</strong> to <strong>{renderPlanDescription()}</strong>
          </>
        }
      />

      <ModalBody>{renderBody()}</ModalBody>
      {renderFooter()}
    </>
  );
};

export default ChangeSubscriptionModal;
