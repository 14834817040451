import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import { exampleLinks } from "./exampleLinks";
import featureCards from "../featureCards";
import Images from "../images";

const data: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Digital brand guidelines platform",
      subtitle: `Say goodbye to stale PDF style guides. With Lingo's digital brand guidelines software, anyone can easily create, share, update, and use a modern living digital style guide. [See an example](${exampleLinks.digitalBrandGuidelines}).`,
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
      image: {
        src: Images.Solutions.brandGuidelines_hero,
        alt: "Brand guidelines",
      },
    },
    {
      type: "MediaSection",
      title: "Your brand guidelines in one place",
      subtitle:
        "Logos, colors, fonts, photos, videos, illustrations and more in a single location.",
      layoutDirection: "image-left",
      body: {
        style: "tabList",
        items: [
          {
            title: "Content and Context. Together.",
            body: "Still attaching a stale style guide PDF whenever someone needs to use your logo? With Lingo people can grab the content they need and learn how to use it in the same spot.",
            image: {
              src: Images.Solutions.brandGuidelines_contentContext,
              alt: "Content and Context. Together.",
            },
          },
          {
            title: "Fully customizable",
            body: "Tell your brand’s unique story by customizing how your assets are categorized and ordered. Better yet, choose a custom font, color, and web link to make your library feel official.",
            image: {
              src: Images.Solutions.brandGuidelines_customBranding,
              alt: "Fully customizable",
            },
          },
          {
            title: "Private and public sharing",
            body: "Keep your brand guideline private so only people with permission can have access, or make your library public so anyone with the link can have access - no account required.",
            image: {
              src: Images.Solutions.brandGuidelines_publicSharing,
              alt: "Advanced custom branding",
            },
          },
          {
            title: "Version your style guide",
            body: "Control how changes to your brand guidelines are released to the rest of your team. Roll back to a previous version if needed.",
            image: {
              src: Images.Solutions.brandGuidelines_versionHistory,
              alt: "Version your style guide",
            },
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More features to manage your brand guidelines",
      footerButton: {
        link: "/product/features",
        text: "View all features",
        buttonStyle: "outline",
      },
      items: [
        featureCards.customBranding,
        featureCards.customDomains,
        featureCards.usageGuidelines,
        featureCards.fileConversions,
        featureCards.tags,
        featureCards.kitVersioning,
      ],
    },
    {
      type: "FaqSection",
      title: "Digital Branding Guidelines FAQ",
      faqs: [
        {
          question: "What are digital brand guidelines?",
          answer:
            "Digital brand guidelines are a set of rules that help shape your brand and its unique identity. Your digital brand guidelines should include the digital assets you use to identify your brand, including logos, fonts, and color palettes. You can even include details about your brand’s tone and voice in your digital brand guidelines. Once you figure out your brand’s identity and create digital brand guidelines, you can share them across teams to keep your branding consistent. Digital branding is an essential part of building a strong brand identity that resonates with people.",
        },
        {
          question: "How does a brand guidelines software work?",
          answer:
            "Brand guidelines software offers an alternative to traditional PDF guidelines. Normally, you’d manually create a static PDF file of all your brand guidelines and share it with each team. Digital brand guidelines software allows you to create dynamic brand guidelines complete with several pages, downloadable assets, images, and videos. When you want to update your brand guidelines, our software makes it easy. You can add, remove, or edit pages with a few simple clicks. When you make a change, your teams will see the latest version of your digital brand guide. You can also use the dropdown menu at the top-left to view previous versions.",
        },
        {
          question: "How do I create a digital brand guide?",
          answer:
            "Creating a digital brand guide starts with understanding your brand. Your digital branding guidelines should include your logo, color palettes, typography, voice and tone, and the key principles and rules that drive your brand. Start by making sure you have all the digital assets you need for your brand. A good digital brand guide should be skimmable. You can create separate pages and sections to make your digital brand guidelines easier to navigate. Make sure each page is clearly named so every team can quickly find what they’re looking for.",
        },
        {
          question: "Why are brand style guides important?",
          answer: `Having a brand style guide helps people better understand your brand’s values, purpose and design language. Armed with this information, people are empowered to create more consistent experiences across all of your brand touchpoints. These consistent and reliable experiences can lead customers to develop loyalty toward your brand. Your brand style guide isn’t just used for your website and products or services. Whether you’re putting out a press release or collaborating with another brand, your brand style guide helps you maintain a consistent voice and tone across every channel. That consistency is a key part of building brand recognition and customer loyalty.`,
        },
        {
          question: "What should I include in my brand style guide?",
          answer:
            "The basic building blocks of a brand style guide are: mission statement or purpose of company, logo guidelines, the brand’s color palette, typography and personality guidelines. Since your brand style guide is designed to ensure consistency across every team and channel, you should include any frequently used brand assets. Access to downloadable files is just as important as the documentation and you can include it all in one place with Lingo. Lingo allows you to create a centralized hub where all your teams can access your brand style guide and the assets to go along with it. With Lingo Voice, your teams can stay connected to collaborate on ads, press releases, website updates, and more.",
        },
        {
          question: "What is a brand style guide?",
          answer:
            "A brand style guide is a digital rulebook that documents the main components of your brand and provides instructions on how those components should be used. You can use this style guide when you’re updating your website, creating a digital ad, or posting to one of your social media accounts. Brand style guides vary in terms of length and content. Your brand style guide may contain a handful of documents and assets, but some major brands have extensive style guides and thousands of brand assets.",
        },
        {
          question: "What is a digital first brand guideline?",
          answer:
            "Digital-first branding is a branding strategy that focuses on digital channels, including social media and email. Nearly everyone has a smartphone or some form of internet access, which means digital marketing channels have become more effective than traditional forms of marketing. A digital-first brand guideline includes your digital assets and focuses on your web presence and digital marketing channels.",
        },
        {
          question: `What do digital brand guidelines cost?`,
          answer: `Creating digital brand guidelines doesn’t cost a fortune. From startups and small businesses to Fortune 500 companies and corporations, Lingo offers affordable solutions for every brand. You can request a quote to learn more about our [pricing](/pricing) or book a demo to see how Lingo can help boost your brand. We also offer a full-featured 30-day trial with no credit card required.`,
        },
      ],
    },
    { type: "CtaSection" },
  ],
};

export default data;
