import React from "react";
import { Text } from "@thenounproject/lingo-core";

import ModalBody from "../../ModalBody";
import ModalFooter from "../../ModalFooter";
import ModalHeader from "../../ModalHeader";

import useShowModal from "@redux/actions/useModals";

const InsightsDownloadModal = () => {
  const { dismissModal } = useShowModal();

  return (
    <>
      <ModalHeader title="Download in Progress" />
      <ModalBody>
        <Text textAlign="center">
          An email containing the requested CSV file will be sent to you shortly.
        </Text>
      </ModalBody>
      <ModalFooter
        primary={{
          text: "Continue",
          onClick: dismissModal,
        }}
      />
    </>
  );
};

export default InsightsDownloadModal;
