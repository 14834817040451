/**
 * Displays the self serve options for joining a space including join link and email domains
 */

import React from "react";
import { Text, Button, Box, Space, pluralize } from "@thenounproject/lingo-core";
import SettingsJoinLink from "../settings/SettingsJoinLink";
import SettingsEmailDomains from "../settings/SettingsEmailDomains";
import ModalBody from "../../ModalBody";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { getSpaceRoleDisplayName } from "@constants/displayUserRoles";

type Props = {
  space: Space;
};

export default function InviteSelfServe({ space }: Props) {
  const { showModal } = useShowModal();
  const {
    joinSettings: {
      joinWith: { kits = [], portals = [], role },
    },
  } = space;
  const _role = role ? getSpaceRoleDisplayName(role, space) : "Member",
    hasPortalAccess = space.features.includes("portals");

  let joinCount: string;
  if (hasPortalAccess) {
    joinCount = `${portals.length} ${pluralize("portal", portals.length)}`;
  } else {
    joinCount = role === "member" ? "all kits" : `${kits.length} ${pluralize("kit", kits.length)}`;
  }

  return (
    <ModalBody>
      <SettingsJoinLink space={space} />
      <SettingsEmailDomains space={space} />
      <Box borderTop="default">
        <Text font="ui.small" mt="m" mb="m">
          These options will add new users as <strong>{_role}s</strong> with access to{" "}
          <strong>{joinCount}</strong> &bull;{" "}
          <Button
            buttonStyle="tertiary"
            size="small"
            text="Update"
            onClick={e => {
              e.preventDefault();
              showModal(ModalTypes.EDIT_NEW_USER_ACCESS, { spaceId: space.id }, false);
            }}
          />
        </Text>

        <Text font="ui.small">
          Please note that you’ll be charged a prorated amount for each new user that joins your
          Lingo space.
        </Text>
      </Box>
    </ModalBody>
  );
}
