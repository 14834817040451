import useNavPoint from "@hooks/useNavPoint";
import usePortalItems from "@redux/actions/portalItems/usePortalItems";
import {
  Box,
  Flex,
  Portal,
  Text,
  Select,
  Button,
  ToggleBox,
  buildURL,
  Notice,
  PrivacyLevel,
  useClipboard,
  PortalPermission,
} from "@thenounproject/lingo-core";
import { uniqBy } from "lodash";
import React, { ComponentProps, useCallback, useMemo, useState } from "react";

import PortalPasswordCheckbox from "@features/access-settings/passwordSettings/PortalPasswordCheckbox";
import PortalSeoSettings from "@features/access-settings/seoSettings/PortalSeoCheckbox";
import PortalDownloadRequestsCheckbox from "@features/access-settings/downloadRequetSettings/PortalDownloadRequestsCheckbox";
import useUpdatePortal from "@redux/actions/portals/useUpdatePortal";
import useNotifications from "@redux/actions/useNotifications";
import { AccessStatus } from "@hooks/useUpsell";
import useModals, { ModalTypes } from "@redux/actions/useModals";
import usePortalUpsell from "@hooks/usePortalUpsell";
import UpsellTooltip from "../../spaces/settings/UpsellTooltip";

type Props = {
  portal: Portal;
  kitId?: string;
};

const PortalLinkSettings: React.FC<Props> = ({ portal, kitId }) => {
  const navPoint = useNavPoint();
  const { showModal } = useModals();
  const { showNotification } = useNotifications();
  const [updatePortal, { isProcessing }] = useUpdatePortal();
  const [selectedKitId, setSelectedKitId] = useState<string>(kitId ?? navPoint.kit?.kitId);
  const { data: items } = usePortalItems({ portalId: portal.id });

  const domainAccess = usePortalUpsell({ portal, permission: PortalPermission.manageCustomDomain });
  const publicAccess = usePortalUpsell({ portal, permission: PortalPermission.managePublicAccess });

  const kits = useMemo(() => {
    const _kits = items?.portalItems?.filter(item => item.type === "kit") ?? [];
    return uniqBy(_kits, kit => kit.kit.kitId);
  }, [items]);

  const selectedKit = useMemo(
    () => selectedKitId && kits.find(kit => kit.kit.kitId === selectedKitId),
    [kits, selectedKitId]
  );
  const options: ComponentProps<typeof Select<string>>["options"] = [
    {
      value: "",
      label: "Portal homepage",
    },
    "-",
    ...kits.map(kit => ({
      value: kit.kit.kitId,
      label: kit.kit.name,
    })),
  ];

  const url = useMemo(() => {
    if (selectedKit) {
      return buildURL(`/k/${selectedKit.kit.urlId}`, {
        space: navPoint.space,
        portal,
        isPublic: true,
      });
    } else {
      return buildURL(`/`, { space: navPoint.space, portal, isPublic: true });
    }
  }, [navPoint.space, portal, selectedKit]);

  const copyShareLink = useClipboard(url);

  const togglePublicAccess = useCallback(async () => {
    const privacy =
      portal.privacy === PrivacyLevel.private ? PrivacyLevel.public : PrivacyLevel.private;
    const res = await updatePortal({ id: portal.id, privacy });
    if (res.error) {
      showNotification({ level: "error", message: res.error.message });
    }
  }, [portal.privacy, portal.id, showNotification, updatePortal]);

  const openDomainModal = useCallback(() => {
    showModal(ModalTypes.PORTAL_DOMAIN, { portalId: portal.id });
  }, [portal.id, showModal]);

  function renderDomainButton() {
    if (domainAccess.accessStatus !== AccessStatus.canAccess) {
      return null;
    }
    return (
      <>
        {" "}
        &bull;{" "}
        <Button
          size="small"
          buttonStyle="tertiary"
          text="Setup a custom domain"
          onClick={openDomainModal}
        />
      </>
    );
  }

  // The link to dropdown to choose a kit
  function renderDropdown() {
    return (
      <Flex flexDirection="column">
        <Text font="ui.smallBold" mb="xs">
          {" "}
          Link to
        </Text>
        <Flex>
          <Select
            value={selectedKitId ?? ""}
            options={options}
            flex="1"
            width="100%"
            pr="s"
            onChange={setSelectedKitId}
          />
          <Button
            text={copyShareLink.hasCopied ? "Copied" : "Copy share link"}
            onClick={copyShareLink.onCopy}
          />
        </Flex>
        <Text mt="xs" font="ui.small">
          {url}
          {renderDomainButton()}
        </Text>
      </Flex>
    );
  }

  // The public access settings
  function renderPublicAccessSettings() {
    const messages = {
      [PrivacyLevel.private]: "Only members of your team can access the portal.",
      [PrivacyLevel.password]:
        "This portal is password protected. Anyone with the password can use this link.",
      [PrivacyLevel.public]: "Anyone with the link can access this portal. No account required.",
    };

    return (
      <>
        {publicAccess.accessStatus === AccessStatus.insufficientPlan && (
          <UpsellTooltip
            source="portal-public-access-togglebox"
            onClick={publicAccess.openUpgradeModal}
            featureName="Public portals"
            plural
          />
        )}
        <Box data-tooltip-source="portal-public-access-togglebox">
          <ToggleBox
            styleOverrides={{ mt: "l" }}
            title="Public access"
            description={messages[portal.privacy]}
            isOpen={
              publicAccess.accessStatus === AccessStatus.canAccess &&
              portal.privacy !== PrivacyLevel.private
            }
            onToggle={togglePublicAccess}
            control={
              publicAccess.accessStatus === AccessStatus.insufficientRole ? "none" : "switch"
            }
            disabled={isProcessing || publicAccess.accessStatus !== AccessStatus.canAccess}>
            <Flex flexDirection="column" alignItems="flex-start" gap="8px">
              <PortalPasswordCheckbox portal={portal} />
              <PortalSeoSettings portal={portal} />
              <PortalDownloadRequestsCheckbox portal={portal} />
            </Flex>
          </ToggleBox>
        </Box>
      </>
    );
  }

  // The notice below public access settings to show the user the access level of the kit
  function renderAccessNotice() {
    if (!selectedKit) return null;
    const kitPrivacy = selectedKit.kit.privacy;
    const portalPrivacy = portal.privacy;
    const textMap = {
      [PrivacyLevel.private]: "private",
      [PrivacyLevel.password]: "password protected",
      [PrivacyLevel.public]: "public",
    };
    const props: ComponentProps<typeof Notice> = {
      message: `You're sharing a ${textMap[kitPrivacy]} kit in a ${textMap[portalPrivacy]} portal.`,
      button: {
        text: "Kit settings",
        onClick: () => {
          showModal(ModalTypes.SHARE_KIT, { kitId: selectedKit.kit.kitId });
        },
      },
    };

    function matches(kit: PrivacyLevel, portal: PrivacyLevel) {
      return kitPrivacy === kit && portalPrivacy === portal;
    }

    if (matches(PrivacyLevel.private, PrivacyLevel.private)) {
      props.message = "The portal is private. Only members of your space can use this link.";
    } else if (matches(PrivacyLevel.private, PrivacyLevel.password)) {
      props.message =
        "This portal requires a password. Anyone with the password can use this link.";
    } else if (matches(PrivacyLevel.private, PrivacyLevel.public)) {
      props.message = "The selected kit is private. Only members of your space can use this link.";
      props.noticeStyle = "warning";
    } else if (matches(PrivacyLevel.password, PrivacyLevel.private)) {
      props.message =
        "The selected kit requires a password. Anyone with the password can use this link.";
    } else if (matches(PrivacyLevel.password, PrivacyLevel.password)) {
      props.message =
        "This portal requires a password. Anyone with the password can use this link.";
    } else if (matches(PrivacyLevel.password, PrivacyLevel.public)) {
      props.message = "The selected kit requires a password.";
      props.noticeStyle = "warning";
    } else if (matches(PrivacyLevel.public, PrivacyLevel.private)) {
      props.message =
        "The selected kit is public. Only members of your space can access the rest of the portal.";
    } else if (matches(PrivacyLevel.public, PrivacyLevel.password)) {
      props.message =
        "The selected kit is public. A password is required to access the rest of the portal.";
    } else if (matches(PrivacyLevel.public, PrivacyLevel.public)) {
      props.message = "This link is public. Anyone can use it.";
    }

    return <Notice mt="m" {...props} />;
  }

  return (
    <Box>
      {renderDropdown()}
      {renderPublicAccessSettings()}
      {renderAccessNotice()}
    </Box>
  );
};

export default PortalLinkSettings;
