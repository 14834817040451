import React, { useEffect, useCallback, useState } from "react";
import { useAppSelectorV1 } from "@redux/hooks";
import { Flex, TabBar, Space, useWindowSize, SearchFilterInput } from "@thenounproject/lingo-core";

import { AssetResultList, JumpToResultList } from "./SearchResultsLists";
import { SearchAssetFilters, SearchJumpToFilters } from "./SearchResultsFilters";
import type { RootState } from "@redux/store";
import SearchFilterDropdown, { DropdownType } from "./SearchFilterDropdown";
import { TabletUp, TabletDown } from "@features/media-queries";
import useSearchItems from "@redux/actions/search/useSearchItems";
import useSearchJumpTo from "@redux/actions/search/useSearchJumpTo";
import getSearchContext from "@redux/actions/search/getSearchContext";
import usePortal from "@redux/actions/portals/usePortal";
import SearchModalFilters from "./SearchModalFilters";

const Tabs = {
  Assets: 0,
  JumpTo: 1,
  Filter: 2,
};

type Props = {
  space: Space;
  submitted: boolean;
  onRemoveFilter: (filter: { filterId: string }) => void;
  filters: SearchFilterInput[];
};

export default function SearchResultsTab({ space, submitted, onRemoveFilter, filters }: Props) {
  const { isMobile } = useWindowSize();

  const {
    sort,
    context,
    filters: activeFilters,
  } = useAppSelectorV1((state: RootState) => state.entities.search.objects);

  const [tabSelected, setTabSelected] = useState(Tabs.Assets),
    selectAssetsTab = useCallback(() => setTabSelected(Tabs.Assets), []),
    selectJumpToTab = useCallback(() => setTabSelected(Tabs.JumpTo), []),
    selectFilterTab = useCallback(() => setTabSelected(Tabs.Filter), []);

  const {
    data: contentData,
    error: contentError,
    fetchNextPage: fetchNextContentPage,
    isLoading: contentLoading,
    refresh: contentRefresh,
  } = useSearchItems({
    spaceId: space.id,
  });

  const {
    data: jumpToData,
    refresh: jumpToRefresh,
    error: jumpToError,
    fetchNextPage: fetchNextJumpPage,
    isLoading: jumpToLoading,
  } = useSearchJumpTo(
    {
      spaceId: space.id,
    },
    { skip: !activeFilters?.length }
  );

  const appliedSearchContext = useAppSelectorV1(getSearchContext);
  const { data: portal } = usePortal({ portalId: appliedSearchContext.portal_uuid });

  useEffect(() => {
    const keywordFilterPresent = activeFilters.filter(item => item.type === "keyword").length;
    if (!keywordFilterPresent || (keywordFilterPresent && submitted)) {
      contentRefresh();
      jumpToRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, context, submitted, activeFilters]);

  function renderTabs() {
    const itemTotal = contentData?.total;
    const jumpTotal = jumpToData?.total;
    return (
      <TabBar size={"regular"} center={false} styleOverrides={{ mb: "-1", flex: "1" }}>
        <TabBar.Item
          text="Assets"
          subtext={
            itemTotal === undefined ? null : `(${itemTotal <= 10000 ? itemTotal : "10000+"})`
          }
          selected={tabSelected === Tabs.Assets}
          onClick={selectAssetsTab}
        />
        <TabBar.Item
          text="Jump to"
          subtext={jumpTotal === undefined ? null : `(${jumpTotal})`}
          selected={tabSelected === Tabs.JumpTo}
          onClick={selectJumpToTab}
        />
        <TabletDown>
          <TabBar.Item
            text="Filter"
            subtext={activeFilters === undefined ? null : `(${activeFilters.length})`}
            selected={tabSelected === Tabs.Filter}
            onClick={selectFilterTab}
          />
        </TabletDown>
      </TabBar>
    );
  }

  function renderHeading() {
    return (
      <Flex flex="0" borderBottom="default" justifyContent="space-between" alignItems="center">
        {renderTabs()}
        <TabletUp>
          <SearchFilterDropdown sortingMenuID="desktop-sort" type={DropdownType.desktop} />
        </TabletUp>
      </Flex>
    );
  }

  function renderResults() {
    const sharedProps = { space, portal };
    switch (tabSelected) {
      case Tabs.Assets:
        return (
          <AssetResultList
            results={contentData}
            fetchNextPage={fetchNextContentPage}
            isLoading={contentLoading}
            error={contentError}
            {...sharedProps}
          />
        );
      case Tabs.JumpTo:
        return (
          <JumpToResultList
            results={jumpToData}
            fetchNextPage={fetchNextJumpPage}
            isLoading={jumpToLoading}
            error={jumpToError}
            {...sharedProps}
          />
        );
      case Tabs.Filter:
        if (!isMobile) return selectAssetsTab();
        return (
          <Flex overflow="scroll">
            <SearchAssetFilters
              aggregations={contentData?.aggregations}
              activeFilters={activeFilters}
            />
          </Flex>
        );
      default:
        return null;
    }
  }

  // Filters for desktop
  function renderFilters() {
    const sharedProps = { activeFilters };
    switch (tabSelected) {
      case Tabs.Assets:
        return <SearchAssetFilters aggregations={contentData?.aggregations} {...sharedProps} />;

      case Tabs.JumpTo:
        return <SearchJumpToFilters aggregations={jumpToData?.aggregations} {...sharedProps} />;
      default:
        return null;
    }
  }

  return (
    <Flex overflow="auto">
      <Flex
        flexDirection="column"
        flex="1"
        width={550}
        minWidth={550}
        variations={{ "mq.s": { width: "100%", minWidth: "100%" } }}>
        <SearchModalFilters onRemoveFilter={onRemoveFilter} context={context} filters={filters} />
        <Flex width="100%" flexDirection="column" overflow="auto">
          <>
            {renderHeading()}
            {renderResults()}
          </>
        </Flex>
      </Flex>
      <TabletUp>{renderFilters()}</TabletUp>
    </Flex>
  );
}
