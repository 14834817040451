/**
 * Invite people to space with Member role.
 */

import React, { Fragment } from "react";
import {
  Box,
  Text,
  Notice,
  Textarea,
  Space,
  Kit,
  TokenInputToken,
  ToggleBox,
} from "@thenounproject/lingo-core";
import EmailInput from "../../forms/EmailInput";
import KitSelectList from "../../kits/KitSelectList";
import ModalBody from "../../ModalBody";
import ModalFooter from "../../ModalFooter";
import SpaceFullMessage from "./SpaceFullMessage";

type Props = {
  space: Space;
  emails: TokenInputToken[];
  addEmails: (email: TokenInputToken[]) => void;
  removeEmail: (index: number) => void;
  note: string;
  onNoteChanged: (note: string) => void;
  error: string;
  processing: boolean;
  sendInvitations: () => void;
  tooManyInvitations: boolean;
  kits: Kit[];
  selectedKitRoles: Record<string, string[]>;
  selectKitRoles: (kitIds: string[], role: string) => void;
  setDefaultKitRole: (kitIds: string[], role: string) => void;
  billingNotice?: string;
};

const InviteMembers = ({
  space,
  kits,
  selectedKitRoles,
  emails,
  addEmails,
  removeEmail,
  selectKitRoles,
  note,
  onNoteChanged,
  error,
  processing,
  sendInvitations,
  tooManyInvitations,
  billingNotice,
}: Props) => {
  const hasInvalidEmails = emails.some(i => i.isValid === false);
  const hasPortalsFeature = space.features.includes("portals");
  const hasContentManagerRole = space.features.includes("content_managers");
  const toggleBoxDescription = hasPortalsFeature
    ? "Members have view access to all kits in all portals."
    : "Members have view access to all kits in this space.";

  function renderLimitWarning() {
    // Show a warning if we input more invitations than we have room for
    // If the space is completely full, a notice is shown in InviteSpaceMembers
    // and we won't be able to get to this view.
    return tooManyInvitations ? (
      <SpaceFullMessage spaceId={space.id} permissions={space.access.permissions} />
    ) : null;
  }

  return (
    <Fragment>
      <ModalBody>
        <Box>
          {billingNotice && <Notice message={billingNotice} noticeStyle="warning" mb="m" />}
          <Text font="ui.regularBold">Invite Content Managers</Text>
          <Text>
            Content managers can access all Kits. They can only edit Kits they are allowed to by an
            Admin or Owner.
          </Text>
        </Box>
        {renderLimitWarning()}

        {/* Email input */}
        <Text as="label" mt="xl" mb="xs">
          Emails
        </Text>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <EmailInput
            onAddToken={addEmails}
            onRemoveToken={removeEmail}
            items={emails}
            inputProps={{ style: { maxWidth: "580px" } }}
          />
        </label>

        {/* Email validation error */}
        {hasInvalidEmails ? (
          <Text ml="xs" mt="xs" font="ui.small" color="error">
            Items in red will not be submitted.
          </Text>
        ) : null}
        {/* Note */}
        <Textarea
          mt="l"
          value={note}
          label="Note (optional)"
          placeholder="Add a personal note"
          onChange={e => onNoteChanged(e.target.value)}
        />

        {kits.length && !hasContentManagerRole ? (
          <React.Fragment>
            <Text as="label" mb="s" mt="l">
              Manage permissions
            </Text>
            <ToggleBox
              title="All Kits"
              description={toggleBoxDescription}
              isOpen={true}
              control="none"
              disabled={true}
              estimatedHeight={"initial"}>
              <KitSelectList
                kits={kits}
                selectedKitRoles={selectedKitRoles}
                editable={true}
                revokable={false}
                selectRole={selectKitRoles}
              />
            </ToggleBox>
          </React.Fragment>
        ) : null}

        {error ? <Notice noticeStyle="error" message={error} /> : null}
      </ModalBody>
      <ModalFooter
        primary={{
          text: processing ? "Sending..." : "Send",
          disabled: emails.length === 0 || processing,
          onClick: sendInvitations,
        }}
      />
    </Fragment>
  );
};

export default InviteMembers;
