import React from "react";
import {
  Flex,
  Text,
  CustomField,
  CustomFieldTypes,
  SearchFilterInput,
} from "@thenounproject/lingo-core";

import { useSelectSpaceFields } from "@selectors/entities/fields";
import {
  NumberInput,
  TextInput,
  SelectInput,
  DateInput,
  ChecklistInput,
} from "./CustomFieldsInputs";

export type Props = {
  activeFilters: SearchFilterInput[];
  toggleFilter: (filter: SearchFilterInput) => void;
};

const CustomFieldsFilters: React.FC<Props> = ({ activeFilters, toggleFilter }) => {
  const fields = useSelectSpaceFields();

  const renderInput = (field: CustomField) => {
    const props = {
      field,
      toggleFilter,
      activeFilters,
    };
    switch (field.type) {
      case CustomFieldTypes.text:
        return <TextInput {...props} />;
      case CustomFieldTypes.number:
        return <NumberInput {...props} />;
      case CustomFieldTypes.select:
        return <SelectInput {...props} />;
      case CustomFieldTypes.date:
        return <DateInput {...props} />;
      case CustomFieldTypes.checklist:
        return <ChecklistInput {...props} />;
    }
  };

  const renderFields = () => {
    return fields.map(field => {
      return (
        <Flex key={field.id} flexDirection="column" mt="16px">
          <Text font="ui.smallBold">{field.name}</Text>
          {renderInput(field)}
        </Flex>
      );
    });
  };
  return <Flex flexDirection="column">{renderFields()}</Flex>;
};

export default CustomFieldsFilters;
