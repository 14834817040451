import React, { Fragment, useState, useCallback, useMemo } from "react";
import _differenceBy from "lodash/differenceBy";

import {
  Button,
  Icon,
  Text,
  PopupMenu,
  Flex,
  Box,
  useBoolean,
  TokenInput,
} from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useAddKitMembers from "@redux/actions/kitMembers/useAddKitMembers";
import { useSelectSpace } from "@selectors/entities/spaces";

import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";
import KitMembersList from "./KitMemberLists";
import { AvatarLarge } from "./Avatars";
import KitLinkSettings from "./KitLinkSettings";
import { useGetKits } from "@selectors/getters";
import { matchSorter } from "match-sorter";
import useKitMembers from "@redux/actions/kitMembers/useKitMembers";

type Props = {
  kitId: string;
};

type Suggestion = {
  id: string;
  isValid: boolean;
  value: string;
  name: string;
  email?: string;
  avatar?: string;
  helpText?: string;
  iconId?: string;
};

const ShareKitModal: React.FC<Props> = ({ kitId }) => {
  const { showModal } = useShowModal();

  const kit = useGetKits()[kitId];
  const space = useSelectSpace();
  const spaceId = kit.spaceId;

  const canViewMembers = space?.access?.permissions?.includes("view_members");

  // This whole system of fetching unjoined space members upfront
  // Should be reworked to use a query to fetch unjoined members
  // This solution is unscalable and requires way too much client
  // side data manipulation
  const { data } = useKitMembers({ spaceId, kitId }, { skip: !canViewMembers });
  const { members = [], unjoinedMembers = [] } = data ?? {};
  const [addKitMembers] = useAddKitMembers();

  const [pendingMembers, setPendingMembers] = useState<Suggestion[]>([]),
    [pendingMembersCanEdit, setPendingMembersCanEdit] = useState(false),
    [canEditMenuVisible, showCanEditMenu, hideCanEditMenu] = useBoolean(false);

  const inviteCandidates = useMemo(() => {
    return unjoinedMembers.map(m => m.user);
  }, [unjoinedMembers]);

  const [inputValue, setInputValue] = useState("");

  function togglePendingMembersCanEdit() {
    setPendingMembersCanEdit(!pendingMembersCanEdit);
  }

  function removePendingMember(index: number) {
    const updated = [...pendingMembers];
    updated.splice(index, 1);
    setPendingMembers(updated);
  }

  const matchingSuggestions: Suggestion[] = useMemo(() => {
    const pending = new Set(pendingMembers.map(user => Number(user.id)));
    const availableToJoin = inviteCandidates
      .filter(c => !pending.has(c.id))
      .map(user => ({
        id: String(user.id),
        name: user.name,
        value: user.email,
        email: user.email,
        avatar: user.avatar,
        isValid: true,
      }));

    if (availableToJoin.length === 0) {
      return [];
    }
    if (!inputValue.length) {
      return [
        {
          id: "add-everyone",
          name: "Everyone",
          value: "Everyone",
          helpText: "Add all Limited Members to Kit",
          iconId: "users",
          isValid: true,
        },
        ...availableToJoin,
      ];
    }
    return matchSorter(availableToJoin, inputValue, { keys: ["name", "email"] });
  }, [inputValue, inviteCandidates, pendingMembers]);

  // When a invite candidate is selected, add them to pendingMembers
  const onSuggestionSelected = useCallback(
    (suggestion: string | Suggestion) => {
      if (typeof suggestion === "string") return;
      switch (suggestion.id) {
        case "add-everyone":
          setPendingMembers([suggestion]);
          break;
        default:
          if (pendingMembers[0]?.id === "add-everyone") {
            setPendingMembers([suggestion]);
          } else {
            setPendingMembers(pendingMembers.concat(suggestion));
          }
      }
      setInputValue("");
    },
    [pendingMembers]
  );

  const savePendingMembers = useCallback(async () => {
    const role = pendingMembersCanEdit ? "collaborator" : "guest";
    let users: number[] = [];

    if (pendingMembers[0].id === "add-everyone") {
      users = inviteCandidates.map(user => Number(user.id));
    } else {
      users = pendingMembers.map(user => Number(user.id));
    }

    const res = await addKitMembers({ users, spaceId, kitId: kit.kitId, role });
    if (res.isSuccess) {
      setPendingMembers([]);
    }
  }, [addKitMembers, inviteCandidates, kit.kitId, pendingMembers, pendingMembersCanEdit, spaceId]);

  function renderTeamAccess() {
    if (space.features.includes("portals")) return null;
    if (!canViewMembers) return null;
    return (
      <Box
        mt="l"
        as="section"
        position="relative"
        background="grayLightest"
        border="default"
        p="m"
        borderRadius="default">
        {renderInviteInput()}
        {canViewMembers && <KitMembersList members={members} kit={kit} />}
      </Box>
    );
  }

  function renderInviteInput() {
    if (!kit.access?.permissions?.includes("change_member_role")) {
      return null;
    }
    return (
      <Fragment>
        <Flex justifyContent="space-between" alignItems="center" mb="s">
          <Text font="ui.regularBold">Team access</Text>
          <Button
            buttonStyle="tertiary"
            size="small"
            onClick={e => {
              e.preventDefault();
              showModal(ModalTypes.INVITE_SPACE_MEMBERS);
            }}
            text="Invite a new user"
          />
        </Flex>
        <Flex mb="l" flexDirection="row">
          <TokenInput
            inputValue={inputValue}
            onChange={setInputValue}
            suggestions={matchingSuggestions}
            tokens={pendingMembers}
            onAddToken={onSuggestionSelected}
            onRemoveToken={removePendingMember}
            renderEmptySuggestions={renderEmptyAutoComplete}
            SuggestionComponent={SuggestionComponent}
            flex="1">
            <Button
              buttonStyle="tertiary"
              size="small"
              mt="xxs"
              data-popup-source="can-edit-menu"
              text={pendingMembersCanEdit ? "Can edit" : "Can view"}
              icon="triangle-double"
              onClick={showCanEditMenu}
            />
          </TokenInput>
          <Button
            ml="s"
            buttonStyle="secondary"
            flexShrink="0"
            disabled={pendingMembers.length === 0}
            onClick={savePendingMembers}
            text="Add"
          />
          {canEditMenuVisible ? (
            <PopupMenu
              zIndex="50000"
              source="can-edit-menu"
              close={hideCanEditMenu}
              width={200}
              vPos="alignTop">
              <PopupMenu.Item
                checked={!pendingMembersCanEdit}
                title="Can view"
                onClick={togglePendingMembersCanEdit}
              />
              <PopupMenu.Item
                checked={pendingMembersCanEdit}
                title="Can edit"
                onClick={togglePendingMembersCanEdit}
              />
            </PopupMenu>
          ) : null}
        </Flex>
      </Fragment>
    );
  }

  function renderEmptyAutoComplete() {
    return (
      <Box p="xl" textAlign="center">
        <Icon iconId="users" size={48} fill="grayDark" />
        <Text font="ui.small">Can’t find who you’re looking for?</Text>
        <Button
          onClick={e => {
            e.preventDefault();
            showModal(ModalTypes.INVITE_SPACE_MEMBERS);
          }}
          buttonStyle="tertiary"
          text="Invite a new user to the team"
        />
      </Box>
    );
  }

  return (
    <Fragment>
      <ModalHeader title={`Share ${kit.name}`} />
      <ModalBody>
        <KitLinkSettings kit={kit} />
        {renderTeamAccess()}
      </ModalBody>
    </Fragment>
  );
};

export default ShareKitModal;

// Duplicated in PortalShareModal.tsx
// Consider merging
type SuggestionProps = {
  title: string;
  suggestion: Suggestion;
  isHighlighted: boolean;
};
const SuggestionComponent: React.FC<SuggestionProps> = ({ suggestion, isHighlighted, ...rest }) => {
  function itemContent(title: string, subtext: string) {
    return (
      <Flex flex="1" alignItems="flex-start" flexDirection="column" justifyContent="space-between">
        <Text>{title}</Text>
        <Text font="ui.small" color="grayDarkest">
          {subtext}
        </Text>
      </Flex>
    );
  }
  function renderSuggestion() {
    switch (suggestion.id) {
      case "add-everyone":
        return (
          <>
            <Flex width="40px" height="40px" alignItems="center" justifyContent="center" mr="s">
              <Icon iconId="users" />
            </Flex>
            {itemContent(suggestion.name, suggestion.helpText)}
          </>
        );
      default:
        return (
          <>
            <AvatarLarge src={suggestion.avatar} alt={`Avatar for ${suggestion.name}`} />
            {itemContent(suggestion.name, suggestion.email)}
          </>
        );
    }
  }

  return (
    <Flex
      py="s"
      px="m"
      style={{ cursor: "pointer" }}
      alignItems="center"
      backgroundColor={isHighlighted ? "grayLightest" : "white"}
      {...rest}>
      {renderSuggestion()}
    </Flex>
  );
};
