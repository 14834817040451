import React, { useCallback, useState } from "react";
import { Input, Flex, DirectLink } from "@thenounproject/lingo-core";
import ModalHeader from "../../ModalHeader";
import ModalBody from "../../ModalBody";
import ModalFooter from "../../ModalFooter";
import useShowModal from "@redux/actions/useModals";

import useUpdateAssetDirectLink from "@redux/actions/assets/useUpdateAssetDirectLink";

type Props = {
  directLink: DirectLink;
};

const UpdateDirectLinkModal: React.FC = ({ directLink }: Props) => {
  const [linkName, setLinkName] = useState(directLink?.name || "Default");
  const [updateAssetDirectLink] = useUpdateAssetDirectLink();
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const { assetId, id } = directLink;
  const { dismissModal } = useShowModal();

  const handleUpdateLink = useCallback(async () => {
    setProcessing(true);

    const res = await updateAssetDirectLink({ assetId, name: linkName, linkId: id });
    if (res.isSuccess) {
      dismissModal();
    } else if (res.error) {
      setError(res.error.message);
    }

    setProcessing(false);
  }, [linkName, updateAssetDirectLink, assetId, id, dismissModal]);

  return (
    <>
      <ModalHeader title="Edit Direct Link" />
      <ModalBody>
        <Flex gap="16px" flexDirection="column">
          <Input
            label="Name"
            placeholder="Enter a link name"
            value={linkName}
            onSubmit={handleUpdateLink}
            onChange={e => setLinkName(e.target.value)}
            inputStyle={error ? "error" : null}
            message={
              error
                ? error
                : "The link name will be visible in both the asset inspector and insights"
            }
          />
        </Flex>
      </ModalBody>
      <ModalFooter
        primary={{
          text: "Save Link",
          onClick: handleUpdateLink,
          disabled: processing,
        }}
      />
    </>
  );
};

export default UpdateDirectLinkModal;
