import React from "react";
import styled from "styled-components";
import { Button, Box, Flex, Icon, Text } from "@thenounproject/lingo-core";

import { Container, Inner } from "./Elements";

const FooterGroup = styled(Box).attrs({
  flex: 1,
  px: "8px",
  py: 12,
})``;

const Wrapper = styled(Flex).attrs({
  width: "100%",
  mx: "-8px",
  variations: {
    "mq.s": {
      flexDirection: "column",
    },
  },
})``;

function debugError(event: React.MouseEvent<SVGSVGElement>) {
  if (event.altKey && event.shiftKey) {
    event.preventDefault();
    throw Error("We're still out of tacos");
  }
}

export default function Footer() {
  function makeButton(text, link) {
    return (
      <Button
        fontStyle="ui.regularSemiBold"
        mb="m"
        text={text}
        link={link}
        themeOverrides={{ primaryColor: "grayLightest", primaryColorDark: "grayLightest" }}
        buttonStyle="tertiary"
      />
    );
  }

  function makeTitle(text) {
    return (
      <Text as="h3" mb="m" color="primaryExtraLight">
        {text}
      </Text>
    );
  }

  return (
    <Container background="primary" mt="none" as="footer">
      <Inner first>
        <Wrapper mt="xxl">
          <FooterGroup>
            <Icon
              iconId="logo.full"
              width="75"
              fill="grayLightest"
              onClick={debugError}
              style={{ cursor: "default" }}
            />
          </FooterGroup>
          <FooterGroup>
            {makeTitle("Lingo")}
            <ul>
              <li>{makeButton("Home", "/")}</li>
              <li>{makeButton("About", "/about")}</li>
              <li>{makeButton("Legal", "/legal")}</li>
              <li>{makeButton("Accessibility", "/accessibility")}</li>
            </ul>
          </FooterGroup>
          <FooterGroup>
            {makeTitle("Product")}
            <ul>
              <li>{makeButton("Kits", "/product/kits")}</li>
              <li>{makeButton("Library", "/product/library")}</li>
              <li>{makeButton("Integrations", "/product/integrations")}</li>
              <li>{makeButton("Custom branding", "/product/custom-branding")}</li>
              <li>{makeButton("Insights", "/product/insights")}</li>
              <li>{makeButton("All features", "/product/features")}</li>
            </ul>
          </FooterGroup>
          <FooterGroup>
            {makeTitle("Solutions")}
            <ul>
              <li>{makeButton("Brand guidelines", "/solutions/brand-guidelines")}</li>
              <li>
                {makeButton("Digital asset management", "/solutions/digital-asset-management")}
              </li>
              <li>{makeButton("Digital brand hub", "/solutions/digital-brand-hub")}</li>
              <li>{makeButton("Brand teams", "/solutions/brand-teams")}</li>
              <li>{makeButton("Agencies", "/solutions/agencies")}</li>
              <li>{makeButton("Enterprise", "/enterprise")}</li>
            </ul>
          </FooterGroup>
          <FooterGroup>
            {makeTitle("Resources")}
            <ul>
              <li>{makeButton("Blog", "/blog")}</li>
              <li>{makeButton("Pricing", "/pricing")}</li>
              <li>{makeButton("Help", "https://help.lingoapp.com/")}</li>
              <li>{makeButton("Status", "https://status.lingoapp.com")}</li>
              <li>{makeButton("Contact us", "/contact")}</li>
            </ul>
          </FooterGroup>
        </Wrapper>
      </Inner>
      {/* </Container>
      <Container> */}
      <Inner>
        <Box mb="xl">
          <Text font="marketing.regular" color="primaryExtraLight">
            &copy; {new Date().getFullYear()} Lingo&trade; Made by{" "}
            <Button
              buttonStyle="tertiary"
              fontStyle="marketing.regular"
              newWindow
              themeOverrides={{
                primaryColor: "primaryExtraLight",
                primaryColorDark: "primaryExtraLight",
              }}
              link="https://thenounproject.com"
              text="Noun Project"
            />
          </Text>
        </Box>
      </Inner>
    </Container>
  );
}
