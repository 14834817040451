import React, { useCallback, useState, Fragment } from "react";
import { Input, buildPath, LingoApiResponse, useNavigation } from "@thenounproject/lingo-core";

import ModalBody from "../../../components/ModalBody";
import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import { useSelectSpace } from "@selectors/entities/spaces";
import useCreateKitCollection from "@actions/kitCollections/useCreateKitCollection";
import useUpdateKitCollection from "@actions/kitCollections/useUpdateKitCollection";
import useShowModal from "@redux/actions/useModals";

type Props = {
  editing?: boolean;
  kitCollectionId?: string;
  preloadedState?: { name: string };
  createKitCollection?: (id: string | number, name: string) => Promise<LingoApiResponse>;
  updateKitCollection?: (id: string | number, data: { name: string }) => Promise<LingoApiResponse>;
};

const CreateEditKitCollectionModal: React.FC<Props> = ({
  editing,
  kitCollectionId,
  preloadedState,
}) => {
  const { dismissModal } = useShowModal(),
    navigate = useNavigation();
  const space = useSelectSpace();
  const [collectionName, setCollectionName] = useState(preloadedState?.name || "");

  const [createKitCollection, { isProcessing: creationProcessing, error: creationError }] =
      useCreateKitCollection(),
    [updateKitCollection, { isProcessing: updateProcessing, error: updateError }] =
      useUpdateKitCollection();

  const error = creationError || updateError;
  const isProcessing = creationProcessing || updateProcessing;

  const createCollection = useCallback(async () => {
    const res = await createKitCollection({
      spaceId: space.id,
      name: collectionName,
    });
    if (res.response) {
      const collectionUrl = buildPath(`/c/${res.response.result}`, { space });
      navigate.push(`${collectionUrl}`);
      dismissModal();
    }
  }, [createKitCollection, space, collectionName, navigate, dismissModal]);

  const editCollection = useCallback(async () => {
    const res = await updateKitCollection({
      id: kitCollectionId,
      name: collectionName,
    });
    if (!res.error) dismissModal();
  }, [updateKitCollection, kitCollectionId, collectionName, dismissModal]);

  const isEditing = Boolean(editing);

  const strings = isEditing
    ? {
        title: "Update Collection",
        loading: "Updating Collection...",
      }
    : {
        title: "Create Collection",
        loading: "Creating Collection...",
      };

  const handler = isEditing ? editCollection : createCollection;

  return (
    <Fragment>
      <ModalHeader title={strings.title} />
      <ModalBody>
        <Input
          id="collection-name-input"
          value={collectionName}
          label="Collection Name"
          onChange={e => setCollectionName(e.target.value)}
          onSubmit={handler}
          placeholder="Branding"
          message={error?.message}
          inputStyle={error ? "error" : undefined}
          autoFocus
        />
      </ModalBody>
      <ModalFooter
        primary={{
          text: isProcessing ? strings.loading : strings.title,
          onClick: handler,
          disabled: isProcessing,
        }}
      />
    </Fragment>
  );
};

export default CreateEditKitCollectionModal;
