import React, { useEffect, useState } from "react";
import { useSelectSpaceFields } from "@selectors/entities/fields";
import styled, { keyframes } from "styled-components";
import {
  Box,
  Button,
  Flex,
  SearchFilterInput,
  SpacePermission,
  utils,
} from "@thenounproject/lingo-core";
import useNavPoint from "@hooks/useNavPoint";
import { SearchContext, SearchContextState } from "@redux/actions/search/useSetSearchContext";
import useClearQueryFilter from "@redux/actions/search/useClearQueryFilter";

const filterAnimation = keyframes`
  0% {
    opacity: 0;
    max-width: 0px;
  }
  100% {
    opacity: 1;
    max-width: 200px;
  }
`;

const FilterPillWrapper = styled(Flex).attrs({
  borderRadius: "default",
  background: "grayLight",
  overflow: "hidden",
  position: "relative",
  alignItems: "center",
})`
  animation: 0.3s ${filterAnimation} ease;
`;

const FiltersWrapper = styled(Flex).attrs({
  maxWith: "550px",
  variations: {
    "mq.s": {
      maxWidth: "100%",
    },
  },
})`
  flex-wrap: wrap;
  gap: 8px;
  flex: 1;
`;

const FilterPill = styled(Box).attrs({
  flexShrink: 0,
  py: "xs",
  pl: "s",
})`
  white-space: nowrap;
  ${props => utils.getFont("ui.small", null, props)}
`;

const FilterRemovalButton = styled(Button).attrs({
  icon: "action.close",
  size: "small",
  width: "22px",
  height: "22px",
  p: "none",
  ml: "xxs",
  buttonStyle: "secondary",
  borderRadius: "none",
})``;

type SearchModalFiltersProps = {
  onRemoveFilter: (filter: { filterId: string }) => void;
  context: SearchContextState;
  filters: SearchFilterInput[];
};

export default function SearchModalFilters({
  onRemoveFilter,
  context,
  filters,
}: SearchModalFiltersProps) {
  const { portal, space } = useNavPoint(),
    fields = useSelectSpaceFields(),
    clearQueryFilter = useClearQueryFilter();
  const [showPortal, setShowPortal] = useState(false);

  useEffect(() => {
    if (context.context === SearchContext.portal) {
      setShowPortal(true);
    }
  }, [context.context]);

  function renderPortalFilter() {
    if (portal && space.access?.permissions.includes(SpacePermission.viewAssetLibrary)) {
      return (
        <FilterPillWrapper>
          <FilterPill>portal: {portal.name}</FilterPill>
          <FilterRemovalButton onClick={() => setShowPortal(false)} />
        </FilterPillWrapper>
      );
    }

    return null;
  }

  const onClearFilters = () => {
    clearQueryFilter();
    setShowPortal(false);
  };

  const renderFilters = () => {
    return filters?.map((f: SearchFilterInput) => {
      const type = fields.find(field => field.id === f.field_id)?.name?.toLowerCase() || f.type;

      return (
        <FilterPillWrapper key={f.id}>
          <FilterPill>
            {type}:{f.display}
          </FilterPill>
          <FilterRemovalButton onClick={() => onRemoveFilter({ filterId: f.id })} />
        </FilterPillWrapper>
      );
    });
  };

  if (showPortal || filters.length)
    return (
      <Flex borderBottom="default" px="m" py="s">
        <FiltersWrapper>
          {showPortal && renderPortalFilter()}
          {renderFilters()}
        </FiltersWrapper>
        <Flex alignItems="center" textAlign="center" ml="l">
          <Button buttonStyle="tertiary" size="small" onClick={onClearFilters} text={"Clear All"} />
        </Flex>
      </Flex>
    );
}
