import React, { useCallback, useEffect, useState } from "react";

import {
  Box,
  BoxProps,
  Button,
  Flex,
  Input,
  Text,
  Textarea,
  Notice,
  Icon,
} from "@thenounproject/lingo-core";
import { Container, Inner } from "../Elements";
import MarketingText from "../MarketingText";
import useCreateSupportRequest from "@redux/actions/billing/useCreateSupportRequest";
import useCurrentUser from "@queries/useCurrentUser";
import WhatConverts from "../../../adapters/whatConverts";

type Form = "sales" | "demo" | "quote" | "trial" | "general";

const formEvents: Record<Form, string> = {
  sales: "Sales Inquiry",
  demo: "Demo Request",
  quote: "Quote Request",
  trial: "Trial Request",
  general: "General Inquiry",
};

type Props = {
  badge: string;
  title: string;
  subtitle: string;
  form: Form;
  style?: "light" | "dark" | "darkGradient" | "lightGradient";
  position?: BoxProps;
};

const FormSection: React.FC<Props> = ({
  title,
  subtitle,
  badge,
  form,
  style = "lightGradient",
  position = {},
}) => {
  const [submitSupportRequest, { result }] = useCreateSupportRequest();

  useEffect(() => {
    WhatConverts.trackPageView(formEvents[form]);
  }, [form]);

  const { user } = useCurrentUser();

  const [processing, setProcessing] = useState(false),
    [emailError, setEmailError] = useState<string>(null),
    [nameError, setNameError] = useState<string>(null),
    [message, setMessage] = useState<string>(""),
    [error, setError] = useState<string>(null),
    [email, setEmail] = useState<string>(user?.email ?? ""),
    [name, setName] = useState<string>(user?.name ?? "");

  const submit = useCallback(async () => {
    if (!email) {
      setEmailError("Provide an email to contact you.");
      return;
    }
    if (!name) {
      setNameError("Please enter your name.");
      return;
    }
    setEmailError(null);
    setNameError(null);
    setError(null);
    setProcessing(true);

    const res = await submitSupportRequest({ email, name, message, requestType: form });
    setProcessing(false);
    if (res.error) {
      setError(res.error.message);
    } else {
      setName("");
      setEmail("");
      setMessage("");
      WhatConverts.trackFormSubmission(formEvents[form], { email, name, message });
    }
  }, [email, name, submitSupportRequest, message, form]);

  // MARK : Layout
  // -------------------------------------------------------------------------------
  const isDark = style?.startsWith("dark");
  const textColor = isDark ? "white" : "black";
  const textAlign = "left";
  const flexDirection = "row";

  let backgroundColor = null;
  // 72 is derived from HEADER_HEIGHT in Header.tsx
  if (style === "darkGradient") {
    backgroundColor =
      "background: linear-gradient(103deg, #FFF 0.78%, #F9FAFF 49.1%, #FFF 97.42%);";
    position.mt = "-72px";
    position.pt = 80 + 72;
  } else if (style === "lightGradient") {
    backgroundColor = "linear-gradient(103deg, #FFF 0.78%, #F9FAFF 49.1%, #FFF 97.42%);";
    position.mt = "-72px";
    position.pt = 80 + 72;
  } else if (style === "dark") {
    backgroundColor = "primary";
    position.mt = "-72px";
    position.pt = 80 + 72;
  }

  function renderEmailInput() {
    return (
      <Input
        label="Your work email"
        type="email"
        value={email}
        placeholder="you@example.com"
        onChange={e => setEmail(e.target.value)}
        onSubmit={submit}
        message={emailError}
        inputStyle={emailError ? "error" : null}
      />
    );
  }
  function renderNameInput() {
    return (
      <Input
        label="Name"
        type="name"
        value={name}
        placeholder="e.g. John Smith"
        onChange={e => setName(e.target.value)}
        onSubmit={submit}
        message={nameError}
        inputStyle={nameError ? "error" : null}
      />
    );
  }
  function renderMessageInput() {
    return (
      <Textarea
        label={form === "general" ? "How can we help you?" : "Why are you interested in Lingo?"}
        value={message}
        placeholder={form === "general" ? "" : "I’m looking to distribute my brand guidelines."}
        onChange={e => setMessage(e.target.value)}
        message={nameError}
        textareaStyle={nameError ? "error" : null}
      />
    );
  }

  function renderSuccessOverlay() {
    // slight kludge here to overlay the success state onto the form maintaining the size of the wrpper and layout.
    return (
      <Flex
        transition="opacity 0.3s"
        style={{
          opacity: result ? 1 : 0,
          pointerEvents: result ? null : "none",
        }}
        position="absolute"
        top="0"
        right="0"
        left="0"
        bottom="0"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        background="white">
        <Icon size="80" fill="success" iconId="info.check-circle" />
        <Text mt="m" font="ui.regular">
          Thank you for your request. Our team will contact you soon.
        </Text>
      </Flex>
    );
  }

  return (
    <Container background={backgroundColor} mt="none" my="none" pt="xxl" {...position}>
      <Inner
        mt="none"
        flexDirection={flexDirection}
        gap="80px"
        alignItems="center"
        variations={{
          "mq.m": { flexDirection: "column" },
        }}>
        <Flex
          flexDirection="column"
          flex="1"
          alignItems="center"
          textAlign={"center"}
          maxWidth="960"
          gap="24px"
          variations={{
            "mq.m_up": {
              textAlign,
              maxWidth: "horizontal",
              alignItems: "flex-start",
            },
          }}>
          {badge && (
            <Box backgroundColor="primaryLightest" borderRadius="default" px="s" py="xs">
              <Text as="h2" font="ui.smallBold" color="primary">
                {badge}
              </Text>
            </Box>
          )}
          <Text as="h1" font="marketing.h1" color={textColor}>
            {title}
          </Text>
          {subtitle ? (
            <MarketingText font="marketing.bodyLarge" color={textColor}>
              {subtitle}
            </MarketingText>
          ) : null}
        </Flex>

        <Flex
          variations={{
            "mq.m": {
              width: "100%",
            },
          }}
          gap="24px"
          flex="1"
          boxShadow="0px 4px 80px 40px rgba(67, 73, 220, 0.04)"
          backgroundColor="white"
          borderRadius="default"
          p="l"
          flexDirection="column">
          {renderNameInput()}
          {renderEmailInput()}
          {renderMessageInput()}
          {error && <Notice mt="l" noticeStyle="error" message={error} />}
          <Button text="Send Inquiry" width="100%" disabled={processing} onClick={submit} />
          {renderSuccessOverlay()}
        </Flex>
      </Inner>
    </Container>
  );
};

export default FormSection;
