import Images from "./images/index";

const featureCards = {
  search: {
    title: "AI enhanced search",
    body: "No more manual tagging. Any photo added to Lingo is instantly searchable thanks to our AI enhanced search functionality.",
    image: {
      src: Images.Features.enhancedSearch,
      alt: "nhandced search",
    },
  },
  customBranding: {
    title: "Custom branding",
    body: "Your brand. Your product. Add a logo, font and an accent color to customize Lingo for your organization. Make things look and feel official.",
    image: {
      src: Images.Features.customBranding,
      alt: "Custom branding",
    },
  },
  fileConversions: {
    title: "Automatic file conversion",
    body: "Have an SVG but need a PNG? Lingo auto-generates alternate file types of every asset you put in. No more time wasted on requests to export assets for different file types.",
    image: {
      src: Images.Features.fileConversions,
      alt: "Custom branding",
    },
  },

  kitVersioning: {
    title: "Kit versioning",
    body: "Control how and when changes to kits are shared with your team, and roll back to a previous version if needed.",
    image: {
      src: Images.Features.versionHistory,
      alt: "Custom branding",
    },
  },
  publicSharing: {
    title: "Public and private kits",
    body: "Control the way you share kits. Privately amongst teammates or publicly with anyone using an access link. You can also add a password to public kits for even more security.",
    image: {
      src: Images.Features.publicSharing,
      alt: "Public and private kits",
    },
  },
  customDomains: {
    title: "Custom Domains",
    body: "Keep brand recognition top of mind with a custom domain. Easier to remember, easier to share.",
    image: {
      src: Images.Features.customDomains,
      alt: "Custom domains",
    },
  },
  unlimitedKits: {
    title: "Unlimited kits",
    body: "A kit is designed to showcase and organize your assets. In Lingo you can create as many kits as you need at no extra charge.",
    image: {
      src: Images.Features.unlimitedKits,
      alt: "Unlimited kits",
    },
  },
  customFields: {
    title: "Custom Fields",
    body: "Add business specific metadata to your assets so they’re easier to organize, manage and use.",
    image: {
      src: Images.Features.customFields,
      alt: "Custom fields",
    },
  },
  tags: {
    title: "Tags",
    body: "Make assets easy to discover through search by tagging them with unique keywords. Manage and edit all your tags from a central location to ensure tag consistency.",
    image: {
      src: Images.Features.tags,
      alt: "Tags",
    },
  },
  brandPortal: {
    title: "Brand Portals",
    body: "Allow anyone to access your workspace by setting up a Brand Portal. Choose which kits to include, add a password and share with a single link.",
    image: {
      src: Images.Features.brandPortal,
      alt: "Tags",
    },
  },
  usageGuidelines: {
    title: "Usage Guidelines",
    body: "Write usage guidelines inline with the digital assets you store in Lingo. Content and context in one place, with Lingo’s digital brand guidelines platform.",
    image: {
      src: Images.Features.guidelines,
      alt: "Usage guidelines",
    },
  },
  integrations: {
    title: "Apps & Integrations",
    body: "Work from the web or your desktop and connect Lingo to the tools you already use so you can supercharge your workflow. [Learn more](/integrations).",
    image: {
      src: Images.Features.integrations,
      alt: "Apps and integrations",
    },
  },
  insights: {
    title: "Insights",
    body: "Measure the performance and impact of your assets with powerful analytics. Optimize your brand and content strategies with effortless summaries.",
    image: {
      src: Images.Features.insights,
      alt: "Insights",
    },
  },
  downloadRequests: {
    title: "Download Requests",
    body: "Monitor who is downloading your assets and protect your brand by approving or denying individual asset downloads. ",
    image: {
      src: Images.Features.downloadRequests,
      alt: "Insights",
    },
  },
};

export default featureCards;
