import React, { useCallback, useState, useMemo } from "react";
import {
  Box,
  Input,
  PopupMenu,
  SearchFilterInput,
  ActivityIndicator,
} from "@thenounproject/lingo-core";

import { useGetNavPoint } from "@selectors/getters";
import useKitSearchSuggestions from "@redux/actions/search/useKitSearchSuggestions";

type KitFilterMenuProps = {
  popupMenuID: string;
  activeKits: Set<string>;
  defaultKitList: SearchFilterInput[];
  isOpen: boolean;
  onClose: () => void;
  toggleFilter: (filter: SearchFilterInput) => void;
};

const KitFilterMenu = ({
  popupMenuID,
  activeKits,
  defaultKitList,
  isOpen,
  onClose,
  toggleFilter,
}: KitFilterMenuProps) => {
  const { spaceId } = useGetNavPoint();
  const [searchText, setSearchText] = useState("");

  const { data, isLoading } = useKitSearchSuggestions({ searchText, spaceId });

  const kitSuggestions = useMemo(() => {
    if (data?.length) {
      return data?.filter(k => !activeKits.has(k.id)) || defaultKitList;
    }
    return [];
  }, [data, activeKits, defaultKitList]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, []);

  const onClick = useCallback((e: React.MouseEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-unused-expressions
    e.stopPropagation?.();
  }, []);

  function renderList() {
    if (isLoading && kitSuggestions.length === 0)
      return (
        <Box p="s" background="grayLightest">
          <ActivityIndicator center />
        </Box>
      );
    return (
      <Box maxHeight="200px" overflow="auto">
        {kitSuggestions.map(f => (
          <KitFilterMenuItem key={f.id} filter={f} toggleFilter={toggleFilter} />
        ))}
      </Box>
    );
  }

  return (
    <PopupMenu
      hPos="alignRight"
      vPos="alignTop"
      width="source"
      source={popupMenuID}
      open={isOpen}
      close={onClose}>
      <Box p="s" background="grayLightest" borderBottom="default">
        <Input
          autoFocus
          onChange={onChange}
          onClick={onClick}
          value={searchText}
          placeholder=""
          type="input"
          size="small"
          id="kit_search"
        />
      </Box>
      {renderList()}
    </PopupMenu>
  );
};

export default KitFilterMenu;

type KitFilterMenuItemProps = {
  filter: SearchFilterInput;
  toggleFilter: (filter) => void;
};

const KitFilterMenuItem = ({ filter, toggleFilter }: KitFilterMenuItemProps) => {
  const onClick = useCallback(() => toggleFilter(filter), [filter, toggleFilter]);
  return (
    <PopupMenu.Item
      title={filter.display}
      onClick={onClick}
      truncate
      image={{
        src: filter.image || "#",
        iconId: "content.kit",
        iconFill: "grayDark",
        iconSize: 24,
        width: "36",
        height: "36",
      }}
    />
  );
};
