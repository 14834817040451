import { createAction, nanoid } from "@reduxjs/toolkit";

export type EditorStatusArgs = {
  memberId: number;
  spaceId: number;
  editorStatus: boolean;
};

export const updateEditorStatus = createAction(
  "editorStatus/update",
  (editorStatus: EditorStatusArgs) => {
    return {
      payload: {
        id: nanoid(),
        ...editorStatus,
      },
    };
  }
);
