import { Space } from "@thenounproject/lingo-core";

export function getSpaceRoleDisplayName(role: string, space: Space) {
  if (role === "owner") {
    return "Owner";
  } else if (role === "admin") {
    return "Admin";
  } else if (role === "member") {
    return space.features.includes("content_managers") ? "Content Manager" : "Member";
  } else if (role === "limited_member") {
    return space.features.includes("content_managers") ? "Member" : "Limited Member";
  } else {
    return "Public Guest";
  }
}
