/**
 * Styled component used as container for assets
 */

import styled, { css } from "styled-components";
import { utils } from "@thenounproject/lingo-core";

// Makes height of asset equal to width
const square = css`
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

type Props = {
  square?: boolean;
};

const AssetParent = styled.div<Props>`
  background-color: ${utils.getColor("grayLighter")};
  background-size: 40px 40px;
  position: relative;
  height: 100%;
  width: 100%;
  user-select: none;
  &:hover .action-button {
    opacity: 1;
  }

  ${props => (props.square === false ? null : square)};
`;

export default AssetParent;
