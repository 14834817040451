import React, { useEffect, useMemo } from "react";
import { Flex, Text, Input, Box, Button } from "@thenounproject/lingo-core";

import ModalHeader from "../../ModalHeader";
import ModalBody from "../../ModalBody";
import ModalFooter from "../../ModalFooter";
import useCreatePublicAccessGuest from "@actions/auth/useCreatePublicAccessGuest";
import { useSelectSpace } from "@selectors/entities/spaces";
import useShowModal from "@redux/actions/useModals";

function InfoPromptModal({ callback }: { callback?: () => void }) {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState<string | undefined>();
  const space = useSelectSpace();
  const { dismissModal } = useShowModal();

  useEffect(() => {
    if (error) setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const [createGuest] = useCreatePublicAccessGuest();

  const onSubmit = React.useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      if (!email || !space) return;
      e?.preventDefault();
      const res = await createGuest({ spaceId: space.id, email });
      if (res.error) {
        setError(res.error.message);
      } else {
        callback?.();
        dismissModal();
      }
    },
    [email, createGuest, space, callback, dismissModal]
  );

  const loginLink = useMemo(() => {
    return `/login/?next=${window.location.toString()}`;
  }, []);

  return (
    <>
      <ModalHeader title="Welcome" />
      <ModalBody>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap="16px"
          as="form"
          onSubmit={onSubmit}>
          <Text>
            The owner of this kit requires you to provide an email address in order to download
            assets.
          </Text>
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="your@email.com"
            label="Email"
            message={error}
            inputStyle={error ? "error" : undefined}
            required
          />
        </Flex>
        <Box width={"100%"} mt="16px">
          <Text>
            Already a member?{" "}
            <Button buttonStyle="tertiary" text="Log in" onClick={dismissModal} link={loginLink} />
          </Text>
        </Box>
      </ModalBody>
      <ModalFooter primary={{ text: "Continue", onClick: onSubmit }} />
    </>
  );
}

export default React.memo(InfoPromptModal);
