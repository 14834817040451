import React, { useMemo } from "react";
import { Flex, Portal, ActivityIndicator, Text, Button } from "@thenounproject/lingo-core";
import PortalToggleBox from "./PortalToggleBox";

type Props = {
  portals: Portal[];
  isLoading?: boolean;
  selectedPortals: string[];
  selectPortal: (id: string | string[]) => void;
  allowPortalKits?: boolean;
  selectKitRoles: (kitIds: string[], role: string) => void;
  multiSelectPortalRoles?: (portalId: string, role: string) => void;
  selectedKitRoles: Record<string, string[]>;
  variedPortals?: string[];
  showEnableAllButton?: boolean;
};

const PortalSelectList = ({
  portals,
  isLoading,
  selectPortal,
  allowPortalKits = true,
  selectedPortals,
  selectKitRoles,
  selectedKitRoles,
  multiSelectPortalRoles,
  variedPortals,
  showEnableAllButton = false,
}: Props) => {
  const allPortalsSelected = useMemo(() => {
    return selectedPortals.length === portals.length;
  }, [portals, selectedPortals]);

  const toggleAllPortals = () => {
    if (allPortalsSelected) {
      selectPortal([]); // Deselect all
    } else {
      selectPortal(portals.map(portal => portal.id)); // Select all
    }
  };

  return (
    <Flex flexDirection="column" gap="8px">
      {!!portals.length && showEnableAllButton && (
        <Flex justifyContent="space-between">
          <Text font="ui.regularBold">Portal Access</Text>
          <Button
            text={`${allPortalsSelected ? "Disable" : "Enable"} all portals`}
            onClick={toggleAllPortals}
            buttonStyle="tertiary"
          />
        </Flex>
      )}
      {isLoading ? (
        <ActivityIndicator center />
      ) : (
        portals?.map(portal => (
          <PortalToggleBox
            key={portal.id}
            portal={portal}
            selectPortal={() => selectPortal(portal.id)}
            isOpen={selectedPortals.includes(portal.id)}
            selectedKitRoles={selectedKitRoles}
            selectKitRoles={selectKitRoles}
            multiSelectPortalRoles={multiSelectPortalRoles}
            allowPortalKits={allowPortalKits}
            variedPortals={variedPortals}
          />
        ))
      )}
    </Flex>
  );
};

export default PortalSelectList;
