import React, { ComponentProps, FormEvent, MouseEvent, useCallback, useState } from "react";
import {
  buildURL,
  Input,
  getCustomDomainComponents,
  ToggleBox,
  Text,
  Space,
  Button,
  openIntercom,
  useClipboard,
  SpacePermission,
  Box,
  slugifySubdomain,
  trimSubdomain,
  LingoError,
} from "@thenounproject/lingo-core";

import UpsellTooltip from "./UpsellTooltip";

import useUpsell, { AccessStatus } from "@hooks/useUpsell";

import { SettingOption, SettingsButton } from "./SettingsElements";
import useUpdateSpaceSubdomain from "@redux/actions/spaces/useUpdateSpaceSubdomain";

type Props = {
  space: Space;
};

const SettingsDomain: React.FC<Props> = ({ space }) => {
  const { openUpgradeModal, accessStatus } = useUpsell(SpacePermission.manageCustomDomain);
  const insufficientPlan = accessStatus === AccessStatus.insufficientPlan;

  const [updateSpaceSubdomain] = useUpdateSpaceSubdomain();
  const spaceUrl = buildURL("/", { space });

  const submitSubdomain = useCallback(
    async (newDomain: string = null) => {
      const res = await updateSpaceSubdomain({
        spaceId: space.id,
        subdomain: newDomain,
      });

      if (res.isSuccess) {
        const {
          result,
          entities: { spaces },
        } = res.response;
        const updatedSpace = spaces[result];
        const domain = getCustomDomainComponents();
        if (domain.subdomain !== updatedSpace.subdomain || domain.host !== updatedSpace.domain) {
          window.location.replace(buildURL("/settings/", { space: updatedSpace }));
        }
      }
      return res;
    },
    [updateSpaceSubdomain, space]
  );

  return (
    <>
      {insufficientPlan && (
        <UpsellTooltip
          source="settings-domain-togglebox"
          onClick={openUpgradeModal}
          featureName="Custom domain"
        />
      )}
      <Box data-test="space-settings-domain" data-tooltip-source="settings-domain-togglebox">
        <DomainToggleBox
          domain={space.domain}
          subdomain={space.subdomain}
          showUpdagdeBadge={insufficientPlan}
          fullUrl={spaceUrl}
          onSave={submitSubdomain}
        />
      </Box>
    </>
  );
};

export default SettingsDomain;

type ToggleBoxProps = {
  domain?: string;
  subdomain?: string;
  fullUrl?: string;
  showUpdagdeBadge?: boolean;
  onSave: (subdomain: string) => Promise<{ error?: LingoError }>;
};
export const DomainToggleBox: React.FC<ToggleBoxProps> = ({
  domain,
  subdomain,
  fullUrl,
  onSave,
  showUpdagdeBadge,
}) => {
  const hasCustomDomain = Boolean(domain);
  const { onCopy, hasCopied, onClickInputToCopy } = useClipboard(fullUrl);

  const [spaceDomainError, setSpaceDomainError] = useState(null);
  const [spaceDomainSuccess, setSpaceDomainSuccess] = useState(false);
  const [spaceDomainOpen, setSpaceDomainOpen] = useState(Boolean(subdomain || domain));
  const [spaceDomainEditing, setSpaceDomainEditing] = useState(false);
  const [spaceDomainEditValue, setSpaceDomainEditValue] = useState("");

  const domainResetState = subdomain && spaceDomainEditing;
  const validDomainState = subdomain && !spaceDomainEditing;
  const validSuccessState = subdomain && spaceDomainSuccess;

  const submitSubdomain = useCallback(
    async (newDomain: string = null) => {
      const res = await onSave(newDomain);

      if (res.error) {
        setSpaceDomainError(res.error?.details?.subdomain ?? res.error?.message);
        if (!newDomain && subdomain) {
          // reopen the box if it fails
          setSpaceDomainOpen(true);
        }
      } else {
        setSpaceDomainError(null);
        setSpaceDomainEditing(false);
        setSpaceDomainEditValue("");
        setSpaceDomainSuccess(true);
      }
    },
    [onSave, subdomain]
  );

  const onSubmit = useCallback(
    async (e: FormEvent = null) => {
      if (e) e.preventDefault();
      await submitSubdomain(trimSubdomain(spaceDomainEditValue));
    },
    [spaceDomainEditValue, submitSubdomain]
  );

  const onSetButtonClicked = useCallback(
    async (e?: MouseEvent) => {
      if (e) e.preventDefault();
      if (subdomain && !spaceDomainEditing) {
        setSpaceDomainEditing(true);
      } else {
        await onSubmit();
      }
    },
    [onSubmit, subdomain, spaceDomainEditing]
  );

  const onToggle = useCallback(async () => {
    if (spaceDomainOpen) {
      setSpaceDomainOpen(false);
      setSpaceDomainEditing(false);
      setSpaceDomainEditValue("");
      if (subdomain) {
        await submitSubdomain(null);
      }
    } else {
      setSpaceDomainOpen(true);
      setSpaceDomainEditing(true);
    }
  }, [subdomain, spaceDomainOpen, submitSubdomain]);

  const onCancel = useCallback(
    (e: MouseEvent) => {
      if (e) e.preventDefault();
      setSpaceDomainEditing(false);
      setSpaceDomainOpen(Boolean(subdomain));
      setSpaceDomainError(null);
    },
    [subdomain]
  );

  function renderDomain() {
    const message = (
      <Text font="ui.small">
        Want to use your own domain? e.g. design.acme.com{" "}
        <Button
          buttonStyle="tertiary"
          onClick={() => openIntercom()}
          text="Contact us "
          size="small"
        />
      </Text>
    );

    return (
      <Input
        id="spaceDomain"
        label="Custom domain"
        readOnly
        value={fullUrl}
        type="text"
        message={message}
        onClick={onClickInputToCopy}>
        <SettingsButton
          text={hasCopied ? "Copied" : "Copy"}
          id="domain-copy-button"
          buttonStyle="primary"
          onClick={onCopy}
        />
      </Input>
    );
  }

  function renderSubdomain() {
    let messageProps = {};
    if (spaceDomainError) {
      messageProps = {
        inputStyle: "error",
        message: spaceDomainError,
      };
    } else if (domainResetState) {
      messageProps = {
        inputStyle: "warning",
        message: "Changing your domain will invalidate any links using the previous domain.",
      };
    } else if (validSuccessState) {
      messageProps = {
        inputStyle: "success",
        message: "Domain successfully set.",
      };
    } else {
      messageProps = {
        message: (
          <Text font="ui.small">
            Want to use your own domain? e.g. design.acme.com.{" "}
            <Button
              buttonStyle="tertiary"
              size="small"
              onClick={() => openIntercom()}
              text="Contact us"
            />
          </Text>
        ),
      };
    }

    return (
      <Input
        placeholder={subdomain ?? slugifySubdomain("Acme Inc.")}
        onChange={e => {
          setSpaceDomainError(null);
          setSpaceDomainEditValue(slugifySubdomain(e.target.value));
        }}
        readOnly={validDomainState}
        value={spaceDomainEditing ? spaceDomainEditValue : subdomain || ""}
        type="text"
        onSubmit={onSubmit}
        {...messageProps}>
        <Text font="ui.regularBold" flexGrow="0" pl="xs">
          .lingoapp.com
        </Text>
        <SettingsButton text={validDomainState ? "Reset" : "Set"} onClick={onSetButtonClicked} />
        {spaceDomainEditing && (
          <SettingsButton
            text="Cancel"
            id="domain-cancel-button"
            buttonStyle="secondary"
            onClick={onCancel}
          />
        )}
      </Input>
    );
  }

  const domainToggleBoxProps: ComponentProps<typeof ToggleBox> = {
    id: "settings-domain-toggle",
    title: "Custom Domain",
    description: "Set up your custom Lingo domain",
    isOpen: spaceDomainOpen,
    disabled: showUpdagdeBadge || hasCustomDomain,
    onToggle: onToggle,
    styleOverrides: { mb: "l" },
    titleBadge: showUpdagdeBadge ? "Upgrade" : null,
  };

  return (
    <ToggleBox {...domainToggleBoxProps}>
      <SettingOption>{hasCustomDomain ? renderDomain() : renderSubdomain()}</SettingOption>
    </ToggleBox>
  );
};
