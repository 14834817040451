/**
 * Allows user to change member role for join link, and add/remove kits if limited member.
 */

import React, { useState, useCallback } from "react";
import {
  Text,
  Button,
  Input,
  ActivityIndicator,
  Flex,
  Icon,
  useBoolean,
  Notice,
} from "@thenounproject/lingo-core";
import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";
import useFigmaIntegrationStatus from "@redux/actions/figma/useFigmaIntegrationStatus";
import useNotifications from "@actions/useNotifications";
import useShowModal from "@redux/actions/useModals";
import { useSelectSpace } from "@selectors/entities/spaces";
import useUpdateFigmaApiKey from "@actions/figma/useUpdateFigmaApiKey";
import useDisableFigmaIntegration from "@actions/figma/useDisableFigmaIntegration";
import useRemoveFigmaTeam from "@actions/figma/useRemoveFigmaTeam";
import useAddFigmaTeam from "@actions/figma/useAddFigmaTeam";

const FigmaIntegrationSetupModal: React.FC = () => {
  const spaceId = useSelectSpace().id;
  const [updateFigmaApiKey] = useUpdateFigmaApiKey(),
    [disableFigmaIntegration] = useDisableFigmaIntegration(),
    [addFigmaTeam] = useAddFigmaTeam(),
    [removeFigmaTeam] = useRemoveFigmaTeam();

  const [isProcessing, setProcessing] = useState(false),
    [editingApiKey, setEditingApiKey] = useState(""),
    [keyError, setKeyError] = useState(null),
    [isAddingTeam, beginAddingTeam, endAddingTeam] = useBoolean(false),
    [newTeamId, setNewTeamId] = useState(""),
    [newTeamError, setNewTeamError] = useState(null);

  const { dismissModal } = useShowModal();
  const { showNotification } = useNotifications();
  const { data: figma } = useFigmaIntegrationStatus({ spaceId }),
    integrationEnabled = ["error", "active"].includes(figma?.auth?.status),
    integrationActive = figma?.auth?.status === "active";

  const submitKey = useCallback(async () => {
    if (!editingApiKey.length) {
      setNewTeamError("API key is required");
      return;
    }
    setProcessing(true);
    setKeyError(null);
    setKeyError(null);
    const { error: responseError } = await updateFigmaApiKey({
      spaceId,
      key: editingApiKey,
    });
    setProcessing(false);
    if (responseError) {
      setKeyError(responseError.message);
    }
  }, [editingApiKey, spaceId, updateFigmaApiKey]);

  const submitTeamId = useCallback(async () => {
    if (!newTeamId.length) {
      setNewTeamError("Team ID is required");
      return;
    }
    setProcessing(true);
    setNewTeamError(null);
    setKeyError(null);
    const { error: responseError } = await addFigmaTeam({ spaceId, teamId: newTeamId });
    setProcessing(false);
    if (responseError) {
      setNewTeamError(responseError.message);
    } else {
      setNewTeamId("");
      endAddingTeam();
    }
  }, [addFigmaTeam, endAddingTeam, newTeamId, spaceId]);

  const removeTeam = useCallback(
    async (teamId: string) => {
      setProcessing(true);
      setNewTeamError(null);
      setKeyError(null);
      const { error: responseError } = await removeFigmaTeam({ spaceId, teamId });
      setProcessing(false);
      if (responseError) {
        showNotification({ message: responseError.message, level: "error" });
      }
    },
    [removeFigmaTeam, showNotification, spaceId]
  );

  const disableIntegration = useCallback(async () => {
    setProcessing(true);
    setKeyError(null);
    setNewTeamError(null);
    const { error: responseError } = await disableFigmaIntegration({ spaceId });
    setProcessing(false);
    if (responseError) {
      showNotification({ message: responseError.message, level: "error" });
    } else {
      showNotification({ message: "Figma integration disabled" });
      dismissModal();
    }
  }, [disableFigmaIntegration, spaceId, showNotification, dismissModal]);

  function renderApiKey() {
    const { auth } = figma;

    if (integrationActive) {
      return (
        <Flex>
          <Icon mr="xs" iconId="checkmark" fill="success" />
          <Text>Connected as {auth.figmaHandle}.</Text>
        </Flex>
      );
    } else {
      return (
        <>
          {auth?.status === "error" && (
            <Notice
              noticeStyle="error"
              message="There was a problem with your API key, please enter a new one."
            />
          )}
          <Input
            label="API Key"
            value={editingApiKey}
            onSubmit={submitKey}
            onChange={e => {
              setEditingApiKey(e.target.value);
            }}
            message={
              keyError ||
              "Your API Key is used to access your Figma files so we can import your assets."
            }
            inputStyle={keyError ? "error" : null}>
            <Button
              disabled={isProcessing}
              flexShrink="0"
              ml="m"
              text="Connect"
              onClick={submitKey}
            />
          </Input>
        </>
      );
    }
  }
  function renderTeams() {
    const { teams } = figma;
    return (
      <>
        {/* Team section header + description */}
        <Flex flexDirection="column" mt="l">
          <Text font="ui.regularBold">Teams</Text>
          <Text font="ui.small">
            Add a Figma team ID for automatic updates when changes are made in team files.
          </Text>
        </Flex>
        {/* List the teams (if any) */}
        {teams.map(t => (
          <Flex key={t.teamId} alignItems="center" mt="s">
            <Icon iconId="checkmark" fill="success" mr="xs" />
            <Text flex="1">Connected to {t.teamName}</Text>
            <Button
              buttonStyle="tertiary"
              text="Remove team"
              icon="action.trash"
              size="small"
              disabled={isProcessing}
              onClick={() => removeTeam(t.teamId)}
            />
          </Flex>
        ))}
        {/* Add team button/input */}
        {isAddingTeam ? (
          <Input
            styleOverrides={{ mt: "m" }}
            label="Add a team"
            value={newTeamId}
            placeholder="Figma team ID"
            onSubmit={submitTeamId}
            onChange={e => setNewTeamId(e.target.value)}
            message={
              newTeamError || (
                <Button
                  buttonStyle="tertiary"
                  size="small"
                  text="Where is my team ID?"
                  link="http://help.lingoapp.com/en/articles/5403605-setting-up-using-the-figma-integration"
                  newWindow
                />
              )
            }
            inputStyle={newTeamError ? "error" : null}>
            <Button
              disabled={isProcessing}
              flexShrink="0"
              ml="m"
              text="Connect"
              onClick={submitTeamId}
            />
            <Button
              disabled={isProcessing}
              buttonStyle="secondary"
              flexShrink="0"
              ml="s"
              text="Cancel"
              onClick={endAddingTeam}
            />
          </Input>
        ) : (
          <Button
            mt="m"
            alignSelf="flex-start"
            buttonStyle="tertiary"
            text="Add team"
            icon="action.add"
            onClick={beginAddingTeam}
          />
        )}
      </>
    );
  }

  function renderDisableButton() {
    return (
      <Flex flexDirection="column" alignItems="center" borderTop="default" mt="m" pt="m">
        <Button
          disabled={isProcessing}
          tintColor="error"
          buttonStyle="tertiary"
          size="small"
          text="Disable the integration"
          onClick={disableIntegration}
        />
      </Flex>
    );
  }

  function renderContent() {
    if (!figma) {
      return <ActivityIndicator center />;
    }
    return (
      <>
        {renderApiKey()}
        {integrationActive && renderTeams()}
        {integrationEnabled && renderDisableButton()}
      </>
    );
  }

  return (
    <>
      <ModalHeader title="Figma Integration" />
      <ModalBody>
        <Text textAlign="left" mb="l">
          Our Figma integration helps you add your Figma assets to Lingo easily. Take a look at our{" "}
          <Button
            text="setup guide"
            newWindow
            link="http://help.lingoapp.com/en/articles/5403605-setting-up-using-the-figma-integration"
            buttonStyle="tertiary"
          />{" "}
          if you need help finding your API Key or Team ID.
        </Text>
        {renderContent()}
      </ModalBody>
    </>
  );
};

export default FigmaIntegrationSetupModal;
