import { createAsyncAction } from "../actionCreators";
import { API, CustomField, Space } from "@thenounproject/lingo-core";

type Args = {
  spaceId: Space["id"];
  field: Partial<CustomField>;
};

const [useCreateCustomField, createCustomField] = createAsyncAction(
  "fields/createCustomField",
  async ({ spaceId, field }: Args) => {
    return await API.call<CustomField>({
      endpoint: `/spaces/${spaceId}/fields`,
      method: "POST",
      data: field,
      entity: API.Entity.customField,
    });
  }
);

export default useCreateCustomField;
export { createCustomField };
