/**
 * Main view of asset with large image & metadata. Allows downloading & opening in Lingo.
 */

import React, { useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import {
  AssetType,
  Box,
  Flex,
  Button,
  AnyObject,
  useBoolean,
  useNavigation,
} from "@thenounproject/lingo-core";

import AssetContent from "./AssetContent";
import AssetEnlarged from "./AssetEnlarged";
import MotionAsset from "./MotionAsset";
import InspectorDetailed from "../inspector/InspectorDetailed";
import KeyCode from "../../constants/keyCodes";
import { InspectorSource, Inspectable } from "@constants/Inspector";
import HeaderAvatar from "../HeaderAvatar";

const ENLARGEABLE_ASSET_TYPES = [
  AssetType.png,
  AssetType.jpg,
  AssetType.svg,
  AssetType.pdf,
  AssetType.color,
  ...AssetType.sketchTypes,
];

const PLAYABLE_ASSET_TYPES = [AssetType.gif, ...AssetType.videoTypes];

const Wrapper = styled(Flex).attrs({
  position: "relative",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  flexDirection: "column",
  variations: {
    "mq.s": {
      height: "unset",
    },
  },
})``;

const Container = styled(Flex).attrs({
  overflow: "auto",
  height: "100%",
  variations: {
    "mq.s": {
      flexDirection: "column",
    },
  },
})``;

const MetaContainer = styled(Box).attrs({
  width: "260px",
  height: "100%",
  overflow: "auto",
  borderLeft: "default",
  variations: {
    "mq.s": {
      height: "unset",
      width: "100%",
      mb: "xxl",
    },
  },
})``;

const ImageContainer = styled(Flex).attrs({
  background: "grayLighter",
  position: "relative",
  justifyContent: "center",
  height: "100%",
  flex: "1 0 auto",
  overflow: "auto",
  variations: {
    "mq.s": {
      height: "100vh",
      width: "100%",
    },
  },
})``;

export const ThumbnailContainer = styled.div<AnyObject>`
  object-fit: contain;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 616px;
  width: 100%;
  cursor: ${props => props.cursor};
`;

const AssetNav = styled(Flex).attrs({
  as: "nav",
  px: "m",
  py: "m",
  background: "toolbarBackground",
  borderBottom: "default",
  alignItems: "center",
  justifyContent: "flex-end",
  top: 0,
})`
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 1;
`;

export interface Props {
  // Passed in
  inspectable: Inspectable;
  canEdit?: boolean;
  shouldLoadNextPage?: boolean;
  nextAssetUrl?: string;
  prevAssetUrl?: string;
  closeUrl?: string;
  fetchNextPage?: () => void;
  displayEnlarged?: boolean;
  kitViewable?: boolean;
}

const AssetDetail: React.FC<Props> = ({
  // Passed in
  inspectable,
  canEdit = false,
  shouldLoadNextPage = false,
  prevAssetUrl,
  nextAssetUrl,
  closeUrl,
  fetchNextPage,
  kitViewable = false,
}) => {
  const imageContainerRef = useRef();
  const navigation = useNavigation();

  const [assetEnlarged, enlargeAsset, minimizeAsset] = useBoolean(false);

  // MARK : Callbacks
  // -------------------------------------------------------------------------------
  const close = useCallback(
    (e = undefined) => {
      if (e) e.preventDefault();
      if (assetEnlarged) minimizeAsset();
      if (
        !(assetEnlarged && ENLARGEABLE_ASSET_TYPES.includes(inspectable.asset.type.toUpperCase()))
      ) {
        navigation.push(closeUrl);
      }
    },
    [assetEnlarged, minimizeAsset, inspectable.asset.type, navigation, closeUrl]
  );

  const loadPrevAsset = useCallback(() => {
    if (!prevAssetUrl) return;
    navigation.push(prevAssetUrl);
  }, [navigation, prevAssetUrl]);

  const loadNextAsset = useCallback(() => {
    if (!nextAssetUrl) return;
    navigation.push(nextAssetUrl);
  }, [navigation, nextAssetUrl]);

  const checkNextPage = useCallback(() => {
    if (shouldLoadNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, shouldLoadNextPage]);

  // MARK : Effects
  // -------------------------------------------------------------------------------
  useEffect(() => {
    const keyListener = e => {
      // Ignore navigation keys if a form field is focussed
      const ignoreTargets = ["INPUT", "TEXTAREA", "SELECT"];
      if (ignoreTargets.includes(e.target.tagName)) return;

      if (e.key === KeyCode.rightArrow) loadNextAsset();
      if (e.key === KeyCode.leftArrow) loadPrevAsset();
      if ([KeyCode.space, KeyCode.escape].includes(e.key) && kitViewable) {
        e.stopPropagation();
        e.preventDefault();
        close();
      }
    };

    document.addEventListener("keydown", keyListener, false);
    checkNextPage();
    return () => {
      document.removeEventListener("keydown", keyListener, false);
    };
  }, [checkNextPage, close, kitViewable, loadNextAsset, loadPrevAsset]);

  useEffect(() => {
    checkNextPage();
  }, [checkNextPage, shouldLoadNextPage]);

  // MARK : Rendering
  // -------------------------------------------------------------------------------

  function renderPrevLink() {
    if (!kitViewable && inspectable.item) return null;
    return (
      <Button
        buttonStyle="secondary"
        icon="arrow-left"
        onClick={loadPrevAsset}
        disabled={!prevAssetUrl}
        ml="s"
        aria-label="Previous asset"
      />
    );
  }

  function renderNextLink() {
    if (!kitViewable && inspectable.item) return null;
    return (
      <Button
        buttonStyle="secondary"
        icon="arrow-right"
        onClick={loadNextAsset}
        disabled={!nextAssetUrl}
        ml="xxs"
        aria-label="Next asset"
      />
    );
  }

  function renderCloseButton() {
    if (!kitViewable && inspectable.item) return null;
    return (
      <Button
        buttonStyle="secondary"
        icon="close"
        onClick={close}
        id="asset-detail-close-button"
        ml="s"
        aria-label="Close"
      />
    );
  }

  function renderEnlargeButton(canEnlarge) {
    return (
      <Button
        buttonStyle="secondary"
        icon={assetEnlarged ? "action.minimize" : "action.maximize"}
        disabled={!canEnlarge}
        onClick={assetEnlarged ? minimizeAsset : enlargeAsset}
        ml="s"
        aria-label="Toggle asset enlarged view"
      />
    );
  }

  const canEnlarge = ENLARGEABLE_ASSET_TYPES.includes(inspectable.asset.type),
    isPlayable = PLAYABLE_ASSET_TYPES.includes(inspectable.asset.type),
    cursor = canEnlarge ? "zoom-in" : "initial";

  function renderNonMotionAsset() {
    return assetEnlarged ? (
      <AssetEnlarged
        inspectable={inspectable}
        hide={minimizeAsset}
        imageContainer={imageContainerRef.current}
      />
    ) : (
      <ThumbnailContainer
        onClick={canEnlarge || isPlayable ? enlargeAsset : null}
        cursor={cursor}
        aria-label="Enlarge asset">
        <AssetContent inspectable={inspectable} thumbnail={false} />
      </ThumbnailContainer>
    );
  }

  return (
    <Wrapper>
      <AssetNav>
        <HeaderAvatar style={{ marginRight: "auto" }} />
        {renderEnlargeButton(canEnlarge)}
        {renderPrevLink()}
        {renderNextLink()}
        {renderCloseButton()}
      </AssetNav>
      <Container>
        <ImageContainer ref={imageContainerRef}>
          {isPlayable ? (
            <MotionAsset inspectable={inspectable} imageContainer={imageContainerRef.current} />
          ) : (
            renderNonMotionAsset()
          )}
        </ImageContainer>
        <MetaContainer>
          <InspectorDetailed
            inspectable={inspectable}
            source={InspectorSource.kit}
            canEdit={canEdit}
          />
        </MetaContainer>
      </Container>
    </Wrapper>
  );
};

export default AssetDetail;
