import React, { useMemo, useCallback, useState } from "react";
import {
  formatDate,
  Flex,
  Button,
  Space,
  Box,
  TableCellText,
  TableCell,
} from "@thenounproject/lingo-core";

import InsightsIntervalPicker from "../InsightsIntervalPicker";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import usePortalAssetPerformance, { PortalAssetResult } from "../queries/usePortalAssetPerformance";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";
import assetIconMap from "../../kits/GalleryAssetContent/assetIconMap";
import getAssetBackground from "@helpers/getAssetBackground";

type Props = {
  portalId: string;
  space: Space;
};

const InsightsPortalAssetsTab = ({ portalId, space }: Props) => {
  const [sort, setSort] = useState<string>("-total_uses");
  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const { dateOperators } = useInsightsContext();

  const {
    data,
    isLoading: tableIsLoading,
    fetchNextPage,
    error: tableError,
  } = usePortalAssetPerformance({
    spaceId: space.id,
    sort,
    dateOperators,
    portalId,
  });

  const { items: tableData, total } = data || {};

  const hasMorePages = tableData?.length < total;

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "assets",
      sort,
      dateOperators,
      additionalParams: { ["portal_uuid"]: portalId },
    });
  }, [dateOperators, generateInsightsCSV, portalId, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Asset Performance",
      subtitle: "Breakdown of how specific assets in the portal were accessed.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"user-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<PortalAssetResult>[] = [
      {
        sortKey: "name",
        name: "Asset Name",
        minWidth: 300,
        flexGrow: 1,
        renderer: i => {
          return (
            <TableCell
              data={i.asset}
              space={space}
              icon={assetIconMap[i.asset.type]}
              background={getAssetBackground(i.asset, space.theme).background}
            />
          );
        },
      },
      {
        name: "Kit",
        minWidth: 160,
        renderer: i => <TableCellText>{i.usage.kitName || "Unnamed kit"}</TableCellText>,
      },
      {
        name: "Section",
        minWidth: 160,
        renderer: i => <TableCellText>{i.usage.sectionName || "Unnamed section"}</TableCellText>,
      },
      {
        name: "Downloads",
        minWidth: 100,
        justify: "flex-end",
        renderer: i => <TableCellText>{i?.usage?.totalUses}</TableCellText>,
      },
      {
        sortKey: "asset_type",
        name: "Type",
        minWidth: 160,
        renderer: i => <TableCellText>{i?.asset.type}</TableCellText>,
      },
      {
        name: "Date Added",
        minWidth: 160,
        renderer: i => <TableCellText>{formatDate(i.dateAdded, "MMM d, yyyy")}</TableCellText>,
      },
      {
        sortKey: "last_downloaded",
        name: "Last Downloaded",
        minWidth: 160,
        renderer: i => (
          <TableCellText>{formatDate(i.usage?.lastDownloaded, "MMM d, yyyy")}</TableCellText>
        ),
      },
    ];
    return cols;
  }, [space]);

  return (
    <Box mt="l">
      <DataTable<PortalAssetResult>
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </Box>
  );
};

export default InsightsPortalAssetsTab;
