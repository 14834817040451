import React, { useMemo, useCallback } from "react";
import {
  Flex,
  Button,
  Space,
  Box,
  ActivityIndicator,
  TableCellText,
} from "@thenounproject/lingo-core";

import Chart from "../Chart";
import InsightsIntervalPicker from "../InsightsIntervalPicker";
import DataTable from "../../table/DataTable";
import { TableColumn } from "../../table";
import { LoadFailure } from "../ReportElements";
import useGenerateInsightsCSV from "../hooks/useGenerateInsightsCSV";
import { useInsightsContext } from "@contexts/InsightsProvider";
import { formatInsightsDate } from "../utils";
import useUniqueDirectLinkReferrers from "../queries/useUniqueDirectLinkReferrers";
import useDirectLinkReferrers, { ReferrersResult } from "../queries/useDirectLinkReferrers";
import formatBytes from "@helpers/bytesToSize";

type Props = {
  directLinkId: number;
  space: Space;
};

const InsightsReferrersTab = ({ directLinkId, space }: Props) => {
  const [sort, setSort] = React.useState<string>("-total_requests");

  const [generateInsightsCSV] = useGenerateInsightsCSV();

  const { dateOperators } = useInsightsContext();

  const { data: overviewData, error: dataFetchingError } = useUniqueDirectLinkReferrers({
    spaceId: space.id,
    directLinkId: directLinkId,
    dateOperators,
  });

  const { result: uniqueReferrersData } = overviewData ?? {};

  const {
    data,
    isLoading: tableIsLoading,
    fetchNextPage,
    error: tableError,
  } = useDirectLinkReferrers({
    spaceId: space.id,
    sort,
    dateOperators,
    directLinkId: directLinkId,
  });

  const { rows: tableData, total } = data ?? {};

  const hasMorePages = tableData?.length < total;

  const { directLinkLabels, uniqueReferrers } = useMemo(
    () =>
      (uniqueReferrersData || []).reduce(
        (acc, item, i) => {
          acc.directLinkLabels[i] = formatInsightsDate(item.date);
          acc.uniqueReferrers[i] = item.totalReferrers;
          return acc;
        },
        {
          directLinkLabels: [],
          uniqueReferrers: [],
        }
      ),
    [uniqueReferrersData]
  );

  const downloadCSV = useCallback(async () => {
    await generateInsightsCSV({
      spaceId: space.id,
      id: "direct_link_referrers",
      sort,
      dateOperators,
      additionalParams: { ["direct_link_id"]: directLinkId },
    });
  }, [directLinkId, dateOperators, generateInsightsCSV, sort, space.id]);

  const tableHeaderProps = useMemo(() => {
    return {
      title: "Top Referring Domains",
      subtitle:
        "A breakdown of which domains have referred the highest number of requests to assets on the CDN.",
      headerExtra: (
        <Flex>
          <InsightsIntervalPicker id={"referring-domains-table"} />
          <Button
            icon="action.download"
            buttonStyle="secondary"
            ml="s"
            onClick={downloadCSV}
            disabled={tableData?.length === 0}
          />
        </Flex>
      ),
    };
  }, [downloadCSV, tableData?.length]);

  const tableColumns = useMemo(() => {
    const cols: TableColumn<ReferrersResult>[] = [
      {
        name: "Referrer",
        minWidth: 340,
        flexGrow: 1,
        renderer: u => {
          return <TableCellText>{u?.referrer || "-"}</TableCellText>;
        },
      },
      {
        sortKey: "total_requests",
        name: "Total Requests",
        minWidth: 160,
        renderer: u => <TableCellText>{u.totalRequests}</TableCellText>,
      },
      {
        sortKey: "total_bandwidth",
        name: "Bandwidth",
        minWidth: 160,
        renderer: u => <TableCellText>{formatBytes(u.totalBandwidth)}</TableCellText>,
      },
    ];
    return cols;
  }, []);

  if (dataFetchingError) return <LoadFailure />;
  if (!overviewData) {
    return (
      <Box height="50vh">
        <ActivityIndicator size="large" center />
      </Box>
    );
  }

  return (
    <>
      <Box px="xl" pt="l">
        <Chart
          title="Unique Referrers"
          subtitle="The number of sources that have referred requests to Lingo assets."
          error={!!dataFetchingError}
          headerExtra={<InsightsIntervalPicker id="kits-modal" />}
          labels={directLinkLabels}
          values={[
            {
              label: "Unique Referrers",
              data: uniqueReferrers,
              color: "primaryColorDark",
            },
          ]}
          Ylabel="# of Unique Referrers"
        />
      </Box>
      <DataTable
        data={tableData}
        columns={tableColumns}
        reportTitleProps={tableHeaderProps}
        isLoading={tableIsLoading}
        hasMorePages={hasMorePages}
        fetchNextPage={fetchNextPage}
        onSort={setSort}
        sort={sort}
        tableError={tableError}
      />
    </>
  );
};

export default InsightsReferrersTab;
