import React from "react";
import { useAppSelectorV1 } from "@redux/hooks";
import { Button, DropdownButton, PopupMenu, useBoolean } from "@thenounproject/lingo-core";

import useChangeSort from "@actions/search/useChangeSort";
import { SectionTitle } from "./SearchResultsFilters";
import type { RootState } from "@redux/store";

const sortOptions = [
  { name: "Most Relevant", value: "relevance" },
  { name: "Most Recent", value: "-recent" },
  { name: "By Type", value: "type" },
  { name: "A-Z", value: "alpha" },
];

export enum DropdownType {
  desktop = "desktop",
  mobile = "mobile",
}

type Props = {
  sortingMenuID: string;
  type: DropdownType;
};

const SearchFilterDropdown = ({ sortingMenuID, type }: Props) => {
  const { sort } = useAppSelectorV1((state: RootState) => state.entities.search.objects);

  const textForSelectedSorting = sortOptions.find(s => s.value === sort)?.name;

  const [sortingMenuShown, showSortingMenu, hideSortingMenu] = useBoolean(false);
  const changeSort = useChangeSort();

  return (
    <>
      {type === DropdownType.desktop && (
        <Button
          mr="s"
          onClick={showSortingMenu}
          buttonStyle="tertiary"
          icon="action.expand"
          size="small"
          text={textForSelectedSorting}
          data-popup-source={sortingMenuID}
        />
      )}
      {type === DropdownType.mobile && (
        <>
          <SectionTitle>Sort By</SectionTitle>
          <DropdownButton
            text={textForSelectedSorting}
            data-popup-source={sortingMenuID}
            onClick={showSortingMenu}
            styleOverrides={{ width: "100%" }}
          />
        </>
      )}
      <PopupMenu
        hPos="alignLeft"
        vPos="alignTop"
        source={sortingMenuID}
        open={sortingMenuShown}
        close={hideSortingMenu}>
        {sortOptions.map(s => (
          <PopupMenu.Item
            checked={s.value === sort}
            key={s.value}
            title={s.name}
            onClick={() => changeSort(s.value)}
          />
        ))}
      </PopupMenu>
    </>
  );
};

export default SearchFilterDropdown;
