/**
 * Asset detail container for use within kits.
 */

import React, { useCallback, useMemo } from "react";

import QueryString from "query-string";
import { type Item, ItemType, KitPermission, buildURL } from "@thenounproject/lingo-core";

import { type NavPoint, buildItemUrl } from "@redux/legacy-actions/navPoints";
import AssetDetail from "./AssetDetail";
import { GalleryContext } from "./Kit";
import useSectionItems from "@redux/actions/items/useSectionItems";
import useRecoveredItems from "@redux/actions/items/useRecoveredItems";
import useNavPoint from "@hooks/useNavPoint";
import useRecentlyDeletedItems from "@redux/actions/items/useRecentlyDeletedItems";
import useGalleryItems from "@redux/actions/items/useGalleryItems";

const KitAssetDetail: React.FC = () => {
  const navPoint = useNavPoint();
  const {
    version,
    spaceId,
    space,
    portal,
    kitId,
    kit,
    kitVersion,
    sectionId,
    section,
    itemId,
    item,
    gallery,
    galleryContext,
  } = navPoint ?? {};

  const sectionItems = useSectionItems({ sectionId, version }, { skip: true });
  const recoveredItems = useRecoveredItems({ spaceId, kitId }, { skip: true });
  const recentlyDeletedItems = useRecentlyDeletedItems({ spaceId, kitId }, { skip: true });
  const galleryItems = useGalleryItems({ itemId: gallery?.id, version }, { skip: true });

  const {
    data: items = [],
    fetchNextPage,
    isLoading,
    total,
  } = useMemo(() => {
    if (galleryContext === GalleryContext.recovered) {
      return {
        ...recoveredItems,
        data: recoveredItems.data,
        total: kitVersion?.counts.recovery,
      };
    } else if (galleryContext === GalleryContext.deleted) {
      return {
        ...recentlyDeletedItems,
        data: recentlyDeletedItems.data,
        total: kitVersion?.counts.recovery,
      };
    } else if (gallery?.items) {
      return {
        ...galleryItems,
        data: galleryItems.data?.items,
        total: gallery?.items.length,
      };
    }

    const assets = sectionItems.data?.items.filter(i => i.type === ItemType.asset) ?? [];
    const items = sectionItems.data?.items ?? [];
    const delta = items.length - assets.length;
    return {
      ...sectionItems,
      data: assets,
      total: section?.counts.items - delta,
    };
  }, [
    gallery?.items,
    galleryContext,
    galleryItems,
    kitVersion?.counts.recovery,
    recentlyDeletedItems,
    recoveredItems,
    section?.counts.items,
    sectionItems,
  ]);

  const assetIndex = items?.findIndex(i => i.id === itemId);
  let prevAsset = null,
    nextAsset = null;
  if (assetIndex > -1) {
    prevAsset = items[assetIndex - 1] || null;
    nextAsset = items[assetIndex + 1] || null;
  }

  const assetsFromEnd = items.length - assetIndex - 1;
  const shouldLoadNextPage = Boolean(
    items?.length && total && !isLoading && items.length < total && assetsFromEnd < 4
  );

  const getItemUrl = useCallback(
    (item: Item) => {
      return buildItemUrl(item, {
        space,
        section,
        portal,
      });
    },
    [portal, section, space]
  );

  const props = {
    isPublicKit: kit.access?.isPublic === true,
    inspectable: { item, asset: item.asset },
    kit,
    kitId,
    section,
    spaceId,
    spaceFeatures: space ? space.features : [],
    canEdit: item?.version === 0 && kit?.access.permissions.includes(KitPermission.editContent),
    kitViewable: kit.access?.permissions?.includes(KitPermission.view),
    prevAssetUrl: getItemUrl(prevAsset),
    nextAssetUrl: getItemUrl(nextAsset),
    shouldLoadNextPage,
    closeUrl: getCloseUrl(navPoint),
  };

  return <AssetDetail {...props} fetchNextPage={fetchNextPage} />;
};

function getKitUrl(navPoint: NavPoint) {
  const { space, portal, kit, section, version } = navPoint,
    kit_token = kit?.shareToken || undefined,
    v = version || undefined;

  const queryString = QueryString.stringify({ kit_token, v });

  return !navPoint.sectionId
    ? buildURL(`/k/${kit.shortId}/?${queryString}`, { space, portal })
    : buildURL(`/s/${section.urlId}/?${queryString}`, { space, portal });
}

function getCloseUrl(navPoint: NavPoint) {
  const { space, portal, galleryContext, item } = navPoint;
  const kitId = navPoint.kitId;
  const params = QueryString.parse(location.search);
  params.asset_token = undefined;
  params.context = undefined;
  /**
   * If there are search params in an asset URL,
   * determine which search origin to send user on exit
   */
  if (galleryContext === GalleryContext.deleted) {
    return buildURL(`/k/${kitId}/deleted`, { space, portal }, params);
  } else if (galleryContext === GalleryContext.recovered) {
    return buildURL(`/k/${kitId}/recovered`, { space, portal }, params);
  } else if (navPoint.gallery) {
    const gallery = navPoint.gallery;
    return buildURL(`/a/${gallery.urlId}`, { space }, params, item.shortId);
  } else {
    const kitUrl = getKitUrl(navPoint);
    return `${kitUrl.split("?")[0]}?${QueryString.stringify(params)}`;
  }
}

export default KitAssetDetail;
