import React from "react";
import styled from "styled-components";
import { Box } from "@thenounproject/lingo-core";
import Notifications from "./Notifications";
import UploadBar from "./UploadBar";

const AlertWrapper = styled(Box).attrs({
  bottom: 0,
  left: "50%",
  width: 0,
  position: "fixed",
  zIndex: "25000",
})``;

export default function Alerts() {
  return (
    <AlertWrapper>
      <Notifications />
      <UploadBar />
    </AlertWrapper>
  );
}
