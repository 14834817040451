import Images from "./images";

const content = {
  title: "Trusted by leading brands",
  brands: [
    { image: Images.CustomerLogos.reddit, alt: "Reddit logo" },
    { image: Images.CustomerLogos.duolingo, alt: "Duolingo logo" },
    { image: Images.CustomerLogos.snap, alt: "Snap logo" },
    { image: Images.CustomerLogos.texasam, alt: "Salvation Army logo" },
    { image: Images.CustomerLogos.matterport, alt: "Matterport logo" },
    { image: Images.CustomerLogos.bumble, alt: "Bumble logo" },
    { image: Images.CustomerLogos.intuit, alt: "Intuit logo" },
    { image: Images.CustomerLogos.tntsports, alt: "TNT Sports logo" },
    { image: Images.CustomerLogos.denison, alt: "Denison logo" },
    { image: Images.CustomerLogos.lime, alt: "Lime logo" },
  ],
};

export default content;
