import React, { useCallback, useState } from "react";
import { AssetType } from "@thenounproject/lingo-core";

import AssetAudio from "./AssetAudio";
import AssetImage from "./AssetImage";
import AssetColor from "./AssetColor";
import AssetFont from "./AssetFont";
import AssetGeneric from "./AssetGeneric";

import { Inspectable } from "@constants/Inspector";

type Props = {
  inspectable: Inspectable;
  thumbnail: boolean;
  downloadButton?: React.ReactNode;
};

function AssetContent({ inspectable, thumbnail: isThumbnail, downloadButton }: Props) {
  const { asset } = inspectable;

  const sharedProps = {
    asset,
    isThumbnail,
  };

  const [useFallback, setUseFallback] = useState(false);
  const onLoad = useCallback(success => {
    setUseFallback(!success);
  }, []);

  if (AssetType.audioTypes.has(asset.type)) {
    return <AssetAudio {...sharedProps} downloadButton={downloadButton} />;
  } else if (asset.type === AssetType.textStyle) {
    return <AssetFont {...sharedProps} downloadButton={downloadButton} />;
  } else if (AssetType.genericTypes.has(asset.type) && (isThumbnail || useFallback)) {
    return <AssetGeneric {...sharedProps} downloadButton={downloadButton} />;
  } else if (asset.type === AssetType.color) {
    return <AssetColor asset={asset} />;
  } else {
    return <AssetImage inspectable={inspectable} isThumbnail={isThumbnail} onLoad={onLoad} />;
  }
}

export default React.memo(AssetContent);
