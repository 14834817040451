import React, { useCallback } from "react";
import { Button, Flex, Text } from "@thenounproject/lingo-core";

type Props = {
  name: string;
  node_id: string;
  expanded: boolean;
  onTogglePageExpanded: (id: string) => void;
  children: React.ReactNode;
};

const parentBoxProps = {
  width: "100%",
  mb: "s",
  mt: "m",
  pb: "s",
  borderBottom: "1px solid grayLight",
};

const FigmaPageWrapper: React.FC<Props> = ({
  name,
  node_id,
  expanded,
  onTogglePageExpanded,
  children,
}) => {
  const _onToggleChildren = useCallback(() => {
    onTogglePageExpanded(node_id);
  }, [node_id, onTogglePageExpanded]);

  const expandIcon = expanded ? "navigation.chevron-down" : "navigation.chevron-right";

  return (
    <>
      <Flex {...parentBoxProps}>
        <Button
          mr="xs"
          size="small"
          buttonStyle="tertiary"
          icon={expandIcon}
          onClick={_onToggleChildren}
          themeOverrides={{ primaryColor: "grayDarkest", primaryColorDark: "grayDarkest" }}
        />
        <Text data-testid={`figma-page-${name}`} font="ui.smallBold" color="grayDarkest">
          {name}
        </Text>
      </Flex>
      {Boolean(expanded) && children}
    </>
  );
};

export default FigmaPageWrapper;
