import _debounce from "lodash/debounce";
import { API, base64, SearchFilterInput } from "@thenounproject/lingo-core";
import { createQueryAction } from "@redux/actions/actionCreators";
import getSearchContext from "./getSearchContext";

type Args = {
  query: SearchFilterInput;
  spaceId: string | number;
};

const [useSearchSuggestions, searchSuggestions] = createQueryAction<
  Args,
  SearchFilterInput[],
  SearchFilterInput[]
>(
  {
    entity: "search",
    action: "fetchSearchSuggestions",
    denormalize: false,
    debounce: true,
    cacheKey: args => {
      return { spaceId: args.spaceId };
    },
  },
  async ({ args: { query, spaceId } }, thunkApi) => {
    const state = thunkApi.getState();
    const _query = { ...query, ...getSearchContext(state) };

    const queryString = `?query=${base64.encode({
      queries: {
        suggestions: _query,
      },
    })}`;
    const res = await API.call<{ filters: []; queries: { suggestions: SearchFilterInput[] } }>({
      endpoint: `search/spaces/${spaceId}${queryString}`,
      method: "GET",
    });
    const suggestions = res.result.queries.suggestions;
    return { result: suggestions, entities: {} };
  }
);

export default useSearchSuggestions;
export { searchSuggestions };
