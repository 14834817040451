import React from "react";
import styled from "styled-components";
import { Box, Flex, Icon } from "@thenounproject/lingo-core";

const Wrapper = styled(Flex).attrs({
  position: "absolute",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  zIndex: "5",
  justifyContent: "center",
  alignItems: "center",
})``;

const BlackOverlay = styled(Box).attrs<Props>(props => ({
  position: "absolute",
  top: "50%",
  height: props.height || "100%",
  left: "50%",
  width: props.width || "100%",
  background: "black",
}))`
  transform: translateX(-50%) translateY(-50%);
  opacity: 0.25;
`;

const PlayIcon = styled(Icon).attrs({
  iconId: "play-video",
  size: 120,
  fill: "white",
})`
  filter: drop-shadow(0px 4px 14px rgba(42, 42, 42, 0.12))
    drop-shadow(0px 1px 4px rgba(42, 42, 42, 0.2));
`;

type Props = {
  width?: string | number;
  height?: string | number;
};

const PlayButtonOverlay = ({ width, height }: Props) => (
  <Wrapper>
    <BlackOverlay width={width} height={height} />
    <PlayIcon data-testid="play-icon" />
  </Wrapper>
);

export default PlayButtonOverlay;
