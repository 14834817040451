import React, { useState, Fragment, useCallback, useMemo } from "react";
import { Flex, Text, Box, Select, Notice, DownloadRequest } from "@thenounproject/lingo-core";
import { addDays } from "date-fns";

import ModalBody from "../../../ModalBody";
import ModalHeader from "../../../ModalHeader";
import ModalFooter from "../../../ModalFooter";

import { useSelectSpace } from "@selectors/entities/spaces";
import DatePickerComponent from "@features/library/components/DatePicker";

import useModals, { ModalTypes } from "@actions/useModals";
import useNotifications from "@actions/useNotifications";
import useUpdateDownloadRequest from "@redux/actions/downloadRequests/updateDownloadRequests";

export const formatPublicRequestDate = (date: string | Date) => {
  const d = new Date(date);
  const utcMilllisecondsSinceEpoch = d.getTime() + d.getTimezoneOffset() * 60 * 1000;
  const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000);
  return utcSecondsSinceEpoch;
};

type Props = {
  request: DownloadRequest;
};

type Expiration = "never" | "specific";

function SpaceRequestEditModal({ request }: Props) {
  const space = useSelectSpace();
  const { showNotification } = useNotifications(),
    { showModal, dismissModal } = useModals();

  const [updateRequest, { error, isProcessing }] = useUpdateDownloadRequest();

  const [expirationSetting, setExpirationSetting] = useState<Expiration>(
    request.expiresAt ? "specific" : "never"
  );
  const [expirationDate, setExpirationDate] = useState<Date>(
    request.expiresAt ? new Date(request.expiresAt) : addDays(new Date(), 30)
  );

  const { showDenyButton, modalTitle, buttonText } = useMemo(() => {
    let showDenyButton = true;
    let modalTitle = "Approve Request";
    let buttonText = "Approve";

    if (request.status === "approved") {
      showDenyButton = false;
      modalTitle = "Update Request";
      buttonText = "Update";
    }
    return { showDenyButton, modalTitle, buttonText };
  }, [request.status]);

  const handleApprove = useCallback(async () => {
    const res = await await updateRequest({
      id: request.id,
      status: "approved",
      expiresAt: expirationSetting === "specific" ? formatPublicRequestDate(expirationDate) : null,
      spaceId: space.id,
    });
    if (res.isSuccess) {
      showNotification({
        message: `Request approved`,
      });
      dismissModal();
    }
  }, [
    dismissModal,
    expirationDate,
    expirationSetting,
    request.id,
    showNotification,
    space.id,
    updateRequest,
  ]);

  const handleDeny = useCallback(() => {
    dismissModal();
    showModal(ModalTypes.SPACE_REQUEST_DENY, { request });
  }, [request, showModal, dismissModal]);

  const values = useMemo(() => {
    const vals: Array<{ label: string; value: string }> = [];
    if (request.email) {
      vals.push({ label: "Email", value: request.email });
    }
    return vals;
  }, [request]);

  return (
    <Fragment>
      <ModalHeader title={modalTitle} />
      <ModalBody>
        <Flex flexDirection="column" borderBottom="default" pb="24px" gap="24px" mb="24px">
          {values.map(v => (
            <Flex flexDirection="column" key={v.label}>
              <Text font="ui.regularBold">{v.label}</Text>
              <Text>{v.value}</Text>
            </Flex>
          ))}
        </Flex>
        <Flex flexDirection="column" gap="24px" mb="24px">
          <Box width={"100%"}>
            <Select<Expiration>
              width={"100%"}
              label="Approval Expiration"
              value={expirationSetting}
              onChange={setExpirationSetting}
              options={[
                { label: "Access will never expire", value: "never" },
                { label: "Access will expire on a specified date", value: "specific" },
              ]}
            />
          </Box>
          {expirationSetting === "specific" && (
            <Box width={240}>
              <DatePickerComponent
                selected={expirationDate}
                onChange={setExpirationDate}
                withPortal
                minDate={new Date()}
                styleOverrides={`
                    input {
                      font-size: 14px;
                      height: 36px;
                      width: 140px;
                      background: #E1E2E4;
                      font-weight: 500;
                      padding-left: 24px
                    }
                  `}
              />
            </Box>
          )}
        </Flex>
        {error && <Notice noticeStyle="error" mt="m" message={error.message} />}
      </ModalBody>
      <ModalFooter
        primary={{
          text: buttonText,
          disabled: isProcessing,
          onClick: handleApprove,
        }}
        secondary={
          showDenyButton
            ? {
                text: "Deny",
                disabled: isProcessing,
                onClick: handleDeny,
              }
            : undefined
        }
      />
    </Fragment>
  );
}

export default React.memo(SpaceRequestEditModal);
