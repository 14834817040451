import { createAsyncAction } from "../actionCreators";
import { API } from "@thenounproject/lingo-core";

type Args = {
  url: string;
  spaceId: number;
};

const [useIdentifyFigmaType, identifyFigmaType] = createAsyncAction(
  "figma/identifyFigmaType",
  async ({ url, spaceId }: Args) => {
    return await API.call<{ importable: boolean; name: string; statusCode: number }>({
      endpoint: `integrations/figma/identify`,
      method: "POST",
      data: { url, space_id: spaceId },
    });
  }
);

export default useIdentifyFigmaType;
export { identifyFigmaType };
