/**
 * Invite people to space with Admin role.
 */

import React, { Fragment } from "react";
import { Box, Text, Notice, Textarea, Space, TokenInputToken } from "@thenounproject/lingo-core";

import EmailInput from "../../forms/EmailInput";
import SpaceFullMessage from "./SpaceFullMessage";
import ModalBody from "../../ModalBody";
import ModalFooter from "../../ModalFooter";

type Props = {
  space: Space;
  emails: TokenInputToken[];
  addEmails: (email: TokenInputToken[]) => void;
  removeEmail: (index: number) => void;
  note: string;
  onNoteChanged: (note: string) => void;
  error: string;
  processing: boolean;
  sendInvitations: () => void;
  tooManyInvitations: boolean;
  billingNotice?: string;
};

const InviteAdmins = ({
  space,
  emails,
  addEmails,
  removeEmail,
  note,
  onNoteChanged,
  error,
  processing,
  sendInvitations,
  tooManyInvitations,
  billingNotice,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const hasInvalidEmails = emails.some(i => i.isValid === false);

  function renderLimitWarning() {
    // Show a warning if we input more invitations than we have room for
    // If the space is completely full, a notice is shown in InviteSpaceMembers
    // and we won't be able to get to this view.
    return tooManyInvitations ? (
      <SpaceFullMessage spaceId={space.id} permissions={space.access.permissions} />
    ) : null;
  }
  return (
    <Fragment>
      <ModalBody>
        <Box>
          {billingNotice && <Notice message={billingNotice} noticeStyle="warning" mb="m" />}
          <Text font="ui.regularBold">Invite Admins</Text>
          <Text font="ui.small">
            Admins can access all kits and manage user permissions. They can also access space
            settings and insights.
          </Text>
        </Box>
        {renderLimitWarning()}
        <Text as="label" mt="xl" mb="xs">
          Emails
        </Text>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <EmailInput
            onAddToken={addEmails}
            onRemoveToken={removeEmail}
            items={emails}
            inputProps={{ style: { maxWidth: "580px" } }}
          />
        </label>
        {hasInvalidEmails ? (
          <Text ml="xs" mt="xs" font="ui.small" color="error">
            Items in red will not be submitted.
          </Text>
        ) : null}
        <Textarea
          mt="l"
          label="Note (optional)"
          value={note}
          placeholder="Add a personal note"
          onChange={e => onNoteChanged(e.target.value)}
        />
        {error ? <Notice mt="xl" noticeStyle="error" message={error} /> : null}
      </ModalBody>
      <ModalFooter
        primary={{
          text: processing ? "Sending..." : "Send",
          disabled: emails.length === 0 || processing,
          onClick: sendInvitations,
        }}
      />
    </Fragment>
  );
};

export default InviteAdmins;
