import React, { useState, Fragment, useMemo, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { without } from "lodash";
import {
  Button,
  ButtonProps,
  Icon,
  Flex,
  Box,
  PopupMenu,
  Text,
  useScrollPosition,
} from "@thenounproject/lingo-core";

import { Container, Inner, ContainerProps } from "./Elements";
import { MarketingButtons } from "./MarketingButtons";

interface WrapperProps extends ContainerProps {
  headerTheme: "light" | "dark";
}

const HEADER_BREAKPOINT = 1140;
const HEADER_HEIGHT = 71;
const MOBILE_NAV_WIDTH = 500;

const Wrapper = styled(Container).attrs((props: WrapperProps) => {
  return {
    background: props.headerTheme === "light" ? "white" : "none",
    as: "header",
    mt: "none",
    my: "none",
    transition: "background 0.4s ease, border-bottom 0.4s ease",
  };
})`
  position: sticky;
  top: 0;
  z-index: 1000;

  ${Inner} {
    height: ${HEADER_HEIGHT}px;
  }
`;

const MobileNav = styled(Flex).attrs<MobileNavProps>(props => {
  return {
    as: "nav",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "16px",
    background: props.headerTheme.startsWith("light") ? "white" : "primary",
    px: "40",
    py: "16",
    position: "fixed",
  };
})<MobileNavProps>`
  overflow: auto;
  transition: transform 0.4s ease;
  top: ${HEADER_HEIGHT}px;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  width: ${MOBILE_NAV_WIDTH}px;
  max-width: 100%;
  z-index: 999999;
  height: calc(100vh - ${HEADER_HEIGHT}px);

  ${props =>
    props.shown &&
    `

  transform: translateX(0);
  `}

  .mobile-nav-buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    a,
    button {
      width: 100%;
    }
  }

  hr {
    width: 100%;
    padding-bottom: 1px;
    border: none;
    background: #ffffff1a;
    margin: 32px 0;
  }

  @media screen and (min-width: ${HEADER_BREAKPOINT}px) {
    .desktop-nav-items {
      display: none;
    }
    .desktop-nav-button {
      display: flex;
    }
  }
  @media screen and (max-width: ${MOBILE_NAV_WIDTH}px) {
    box-shadow: none;
  }
`;

const DesktopNav = styled(Flex).attrs({ as: "nav", alignItems: "center", gap: "32px" })`
  .desktop-nav-items {
    display: flex;
    gap: 20px;
    a {
      align-self: center;
    }
  }

  .desktop-nav-button {
    display: none;
  }
  @media screen and (max-width: ${HEADER_BREAKPOINT}px) {
    .desktop-nav-items {
      display: none;
    }
    .desktop-nav-button {
      display: flex;
    }
  }
`;

const MenuButton = styled(Button)``;

const LogoLink = styled(Link)`
  width: 75px;
  height: 35px;
`;

type HeaderProps = {
  headerTheme: "light" | "dark" | "lightGradient" | "darkGradient";
};

type MobileNavProps = { shown: boolean } & Pick<HeaderProps, "headerTheme">;

type MenuItem = {
  id: string;
  text: string;
  link?: string;
  external?: boolean;
  subMenu?: SubMenu;
};

type SubMenu = {
  sections: {
    title?: {
      text: string;
      color: string;
    };
    withSeparator?: boolean;
    items: MenuItem[];
  }[];
};

type MobileMenuPage = {
  id: string;
  text: string;
  items: MenuItem[];
};

const pages = {
  product: {
    kits: { id: "kits", text: "Kits", link: "/product/kits" },
    library: { id: "library", text: "Library", link: "/product/library" },
    integrations: { id: "integrations", text: "Integrations", link: "/product/integrations" },
    branding: { id: "branding", text: "Custom Branding", link: "/product/custom-branding" },
    insights: { id: "insights", text: "Insights", link: "/product/insights" },
    all: { id: "all", text: "All features", link: "/product/features" },
  },
  solutions: {
    brandGuidelines: { id: "brand", text: "Brand guidelines", link: "/solutions/brand-guidelines" },
    dam: {
      id: "dam",
      text: "Digital asset management",
      link: "/solutions/digital-asset-management",
    },
    brandPortals: {
      id: "brand-portals",
      text: "Digital brand hub",
      link: "/solutions/digital-brand-hub",
    },
    brandTeams: { id: "brand-teams", text: "Brand teams", link: "/solutions/brand-teams" },
    agencies: { id: "agencies", text: "Agencies", link: "/solutions/agencies" },
    enterprise: { id: "enterprise", text: "Enterprise", link: "/enterprise" },
  },
  resources: {
    blog: { id: "blog", text: "Blog", link: "/blog" },
    pricing: { id: "pricing", text: "Pricing", link: "/pricing" },
    help: { id: "help", text: "Help", link: "https://help.lingoapp.com", external: true },
    contact: { id: "contact", text: "Contact us", link: "/contact" },
    dam: {
      id: "dam",
      text: "What is DAM?",
      link: "/blog/what-is-digital-asset-management-dam",
    },
  },
};

const menuItems: MenuItem[] = [
  {
    id: "product",
    text: "Product",
    subMenu: {
      sections: [
        {
          items: [
            pages.product.kits,
            pages.product.library,
            pages.product.integrations,
            pages.product.branding,
            pages.product.insights,
          ],
        },
        {
          withSeparator: true,
          items: [pages.product.all],
        },
      ],
    },
  },
  {
    id: "solutions",
    text: "Solutions",
    subMenu: {
      sections: [
        {
          title: {
            text: "By use case",
            color: "primary",
          },
          items: [
            pages.solutions.brandGuidelines,
            pages.solutions.dam,
            pages.solutions.brandPortals,
          ],
        },
        {
          title: {
            text: "By role",
            color: "secondary",
          },
          items: [pages.solutions.brandTeams, pages.solutions.agencies],
        },
      ],
    },
  },
  pages.solutions.enterprise,
  pages.resources.pricing,
  {
    id: "resources",
    text: "Resources",
    subMenu: {
      sections: [
        {
          items: [
            pages.resources.blog,
            pages.resources.help,
            pages.resources.contact,
            pages.resources.dam,
          ],
        },
      ],
    },
  },
];

const mobileMenuItems: MobileMenuPage[] = [
  {
    id: "product",
    text: "Product",
    items: [
      pages.product.kits,
      pages.product.library,
      pages.product.integrations,
      pages.product.branding,
      pages.product.insights,
      pages.product.all,
    ],
  },
  {
    id: "solutions",
    text: "Solutions",
    items: [
      pages.solutions.brandGuidelines,
      pages.solutions.dam,
      pages.solutions.brandPortals,
      pages.solutions.brandTeams,
      pages.solutions.agencies,
      pages.solutions.enterprise,
    ],
  },
  {
    id: "resources",
    text: "Resources",
    items: [
      pages.resources.blog,
      pages.resources.pricing,
      pages.resources.help,
      pages.resources.contact,
      pages.resources.dam,
    ],
  },
];

export default function Header({ headerTheme = "light" }: HeaderProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState<MenuItem["id"][]>([]);
  const mobileMenuRef = useRef(null);

  const [appliedStyle, setAppliedStyle] = useState<
    "light" | "dark" | "darkGradient" | "lightGradient"
  >(() => headerTheme);
  const [showBorder, setShowBorder] = useState(false);

  useEffect(() => {
    setAppliedStyle(headerTheme);
  }, [headerTheme]);

  useScrollPosition(
    state => {
      const style = state.currPos.y < -20 ? "light" : headerTheme;
      setAppliedStyle(style);
      setShowBorder(style === "light" && state.currPos.y < -20);
    },
    [headerTheme]
  );

  const handleMobileMenuToggle = useCallback(() => {
    setShowMenu(val => !val);
  }, []);

  const navLinkProps = useMemo(() => {
    const themeProps = appliedStyle.startsWith("light")
      ? { themeOverrides: { primaryColor: "grayDarkest" } }
      : { themeOverrides: { primaryColor: "white", primaryColorDark: "grayLight" } };

    return {
      buttonStyle: "tertiary",
      fontStyle: "marketing.navLink",
      size: "small",
      ...themeProps,
    } as Partial<ButtonProps>;
  }, [appliedStyle]);

  function renderCTAButtons(isMobile: boolean) {
    const outlineStyle = appliedStyle.startsWith("dark") ? "dark" : "light";
    return (
      <>
        <Button
          buttonStyle="outline"
          fontStyle="ui.regularSemiBold"
          className="menu-link-button"
          text="Login"
          link="/space"
          themeOverrides={{ outlineStyle }}
          height={isMobile ? "48px" : null}
        />
        <Button
          buttonStyle={appliedStyle.startsWith("dark") ? "secondary" : "primary"}
          className="menu-link-button"
          height={isMobile ? "48px" : null}
          {...MarketingButtons.getStarted}
        />
      </>
    );
  }

  function renderMobileNavItems() {
    return (
      <Flex flexDirection="column" gap="16px" textAlign="left">
        {mobileMenuItems.map(item => {
          return (
            <Fragment key={item.id}>
              <Text
                font="ui.regularSemiBold"
                color={appliedStyle === "dark" ? "primaryExtraLight" : "primary"}>
                {item.text}
              </Text>
              <Flex flexDirection="column" textAlign="left" gap="16px">
                {item.items.map(page => (
                  <Button
                    key={page.id}
                    buttonStyle="tertiary"
                    fontStyle="ui.regular"
                    className="menu-link-button"
                    text={page.text}
                    link={page.link}
                    {...navLinkProps}
                  />
                ))}
              </Flex>
              <hr />
            </Fragment>
          );
        })}
      </Flex>
    );
  }

  function renderDesktopNavItems() {
    return menuItems.map(item => {
      if (item.subMenu) {
        const dropdownSource = `header-dropdown-${item.id}`;
        return (
          <Fragment key={item.id}>
            <PopupMenu
              hPos="alignRight"
              source={dropdownSource}
              open={openSubMenus.includes(item.id)}
              close={() => setOpenSubMenus(prev => without(prev, item.id))}>
              {item.subMenu.sections.map((section, sectionIndex) => (
                <Fragment key={sectionIndex}>
                  {section.withSeparator && <Box width={"100%"} pb="1px" background="grayLight" />}
                  {section.title && (
                    <PopupMenu.Dialog
                      text={section.title.text}
                      color={section.title.color}
                      borderBottom="none"
                    />
                  )}
                  {section.items.map((subItem, subItemIndex) => (
                    <PopupMenu.Item
                      key={subItemIndex}
                      title={subItem.text}
                      link={subItem.link || undefined}
                    />
                  ))}
                </Fragment>
              ))}
            </PopupMenu>
            <Button
              data-popup-source={dropdownSource}
              onClick={() => setOpenSubMenus(prev => [...prev, item.id])}
              text={item.text}
              link={item.link as string}
              icon={"navigation.chevron-down"}
              {...navLinkProps}
            />
          </Fragment>
        );
      }
      return <Button key={item.id} text={item.text} link={item.link as string} {...navLinkProps} />;
    });
  }
  return (
    <Fragment>
      <Wrapper
        {...{ headerTheme: appliedStyle }}
        borderBottom={showBorder ? "default" : "1px solid transparent"}>
        <Inner flexDirection="row" mt="none" alignItems="center" justifyContent="space-between">
          <LogoLink title="Home" to="/">
            <Icon
              iconId="logo.full"
              fill={appliedStyle.startsWith("light") ? "primary" : "white"}
            />
          </LogoLink>
          <DesktopNav>
            <div className="desktop-nav-items">
              {renderDesktopNavItems()}
              {renderCTAButtons(false)}
            </div>
            <div className="desktop-nav-button">
              <MenuButton
                aria-label="Open menu"
                aria-pressed={showMenu}
                className="menu-button"
                buttonStyle="secondary"
                icon={showMenu ? "action.close" : "navigation.menu"}
                p="none"
                background="transparent"
                onClick={handleMobileMenuToggle}
                {...navLinkProps}
                size="regular"
              />
            </div>
          </DesktopNav>
        </Inner>
      </Wrapper>
      <MobileNav ref={mobileMenuRef} role="navigation" shown={showMenu} headerTheme={appliedStyle}>
        <Box className="mobile-nav-buttons">{renderCTAButtons(true)}</Box>
        <Box mt="32px" className="mobile-nav-items">
          {renderMobileNavItems()}
        </Box>
      </MobileNav>
    </Fragment>
  );
}
