import { API, PublicAccessApprovalMode } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type EditKitPublicAccessInput = {
  spaceId: number;
  kitId: string;
  downloadsRestricted?: boolean;
  downloadsRestrictedApproveMode?: PublicAccessApprovalMode;
};

const [useUpdateKitPublicAccess, updateKitPublicAccess] = createAsyncAction(
  "kits/updatePublicAccess",
  async ({
    spaceId,
    kitId,
    downloadsRestricted,
    downloadsRestrictedApproveMode,
  }: EditKitPublicAccessInput) => {
    const data = {
      downloads_restricted: downloadsRestricted,
      downloads_restricted_approve_mode: downloadsRestrictedApproveMode,
    };
    Object.keys(data).forEach(key => data[key] === undefined && delete data[key]);

    return await API.call<string>({
      endpoint: `kits/${spaceId}/${kitId}`,
      method: "PUT",
      data,
      entity: API.Entity.kit,
    });
  }
);
export default useUpdateKitPublicAccess;
export { updateKitPublicAccess };
